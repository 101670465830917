import React, { FC } from 'react';
import styled from 'styled-components';
import { MediumText } from '../../utils/typography';
import colors from '../../utils/colors';
import MaterialIcon from './icons/MaterialIcon';
import { darkenColor } from '../../utils/colorUtils';

type Props = {
  onClick: () => void;
  className?: string;
  style?: object;
  color?: string;
  icon?: string;
};

const TextLink: FC<Props> = ({ icon, onClick, className, style = {}, children, color = colors.primaryPurple }) => (
  <Text className={className} onClick={() => onClick()} style={style} color={color}>
    {icon && <MaterialIcon style={{ marginRight: '4px' }} name={icon} size={16} color={color} />} {children}
  </Text>
);

export default TextLink;

const Text = styled(MediumText)`
  display: flex;
  align-items: center;
  color: ${props => props.color};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => darkenColor(theme.primary, 20)};
  }

  &:active {
    color: ${props => props.color};
  }
`;
