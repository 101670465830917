import React from 'react';
import t from './../../i18n/t';
import styled from 'styled-components';
import { ReactComponent as ShovelLogoWithTitle } from '../../assets/images/shovel-logo-title.svg';
import colors from '../../utils/colors';
import ShovelLaptop from '../../assets/images/shovel-laptop.png';
import { ExtraLargeText, LargeText, RegularText } from '../../utils/typography';
import { Column } from '../../components/common/layoutUtils';
import { useLocation } from 'react-router-dom';
import { RESEND_ACTIVATION_EMAIL, SIGN_UP } from '../../utils/constants/routes';

type Props = {
  history: any;
};

export default function MobileSignupInstructions({ history }: Props) {
  const location = useLocation();

  // @ts-ignore
  const email = location.state?.email;

  return (
    <Container>
      <Section>
        <Logo />
        <ExtraLargeText color={colors.white}>{t.INSTRUCTIONS_SENT_TO}:</ExtraLargeText>
        <LargeText>{email || ''}</LargeText>
      </Section>
      <div>
        <img src={ShovelLaptop} style={{ height: '170px' }} />
      </div>
      <Section>
        <ExtraLargeText>{t.MOBILE_SIGNUP_INSTRUCTIONS_TEXT}</ExtraLargeText>
      </Section>
      <Section style={{ position: 'absolute', bottom: 0 }}>
        <Link onClick={() => history.push(RESEND_ACTIVATION_EMAIL)}>{t.RESEND_ACTIVATION_EMAIL}</Link>
        <Link onClick={() => history.push(SIGN_UP)}>{t.SIGN_UP_WITH_DIFFERENT_EMAIL}</Link>
      </Section>
    </Container>
  );
}

const Container = styled(Column)`
  padding: 50px 30px 0 30px;
  flex: 1;
  overflow: auto;
  background-color: ${colors.primaryPurple};
  align-items: center;
`;

const Section = styled(Column)`
  margin-bottom: 20px;
  align-items: center;

  span {
    color: ${colors.white};
    text-align: center;
  }
`;

const Logo = styled(ShovelLogoWithTitle)`
  width: 80px;
  height: 65px;
  margin-bottom: 30px;

  path {
    fill: ${colors.white};
  }
`;

const Link = styled(RegularText)`
  color: ${colors.white};
  text-decoration: underline;
  margin-bottom: 10px;
`;
