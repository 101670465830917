import { network } from './network';
import { CalendarEvent, ThinActivityDto } from 'shovel-lib/types';

// todo: remove after updating shovel-lib
async function getDatesContainingTasksMini(semesterId: number, startDate: string, endDate: string) {
  return network.get(`/semesters/${semesterId}/calendar/dates-containing-tasks-ranged-mini`, { startDate, endDate });
}

async function duplicatePlannedTask(semesterId: number, id: number) {
  return network.post<CalendarEvent & { notes: string }>(`/semesters/${semesterId}/planner/duplicate/${id}`);
}

const createDefaultActivities = (semesterId: number) =>
  network.post<ThinActivityDto[]>(`/semesters/${semesterId}/activities/create-default`);

const copyActivities = (semesterId: number, includeSchedule: boolean) =>
  network.post<ThinActivityDto[]>(`/semesters/${semesterId}/activities/copy`, undefined, {
    params: { schedule: includeSchedule }
  });

const changeEventDefaultColor = (semesterId: number, color: string) =>
  network.post<void>(`/semesters/${semesterId}/event-color`, color);

export default {
  getDatesContainingTasksMini,
  duplicatePlannedTask,
  changeEventDefaultColor,
  createDefaultActivities,
  copyActivities
};
