import { actionCreator, asyncActionsCreator } from '../common/utils';

const asyncActions = asyncActionsCreator('@@ics');
const action = actionCreator('@@ics');

export const closeIcsUpdatesDialog = action<void>('CLOSE_ICS_UPDATES_DIALOG');

export const parseIcsFeed = asyncActions<string, { [key: string]: any[] }, string>('PARSE_ICS_FEED');
export const clearIcsData = action<void>('CLEAR_ICS_DATA');
export const checkForIcsUpdates = action<void>('CHECK_FOR_ICS_UPDATES');
export const triggerIcsUpdates = asyncActions<void, { [key: string]: any[] }, string>('TRIGGER_ICS_UPDATES');
export const unsyncCourseFromLMS = asyncActions<
  { id: number; unlinkCalendarId?: string; correlationId?: string; platforms?: string[] },
  { id: number; unlinkCalendarId?: string; correlationId?: string; platforms?: string[] },
  string
>('UNSYNC_COURSE_FROM_LMS');
