import { Formats, PileFilterType, PileStateOptions } from 'shovel-lib/types';
import colors from '../../../utils/colors';
import t from '../../../i18n/t';
import MaterialIcon from '../../common/icons/MaterialIcon';
import React from 'react';
import { sectionDueDate } from '../../../utils/taskUtils';
import { DueDate } from '../../task/TaskCard.styles';
import { PileSections, SortOption } from '../../../state/pile/types';
import { formatMomentDateAs, toMomentDate } from 'shovel-lib/utils/timeUtils';
import { MediumText } from '../../../utils/typography';
import { timeUtils } from 'shovel-lib';

export const isSortActive = (sort, activeTab) => {
  const isCompleted = activeTab === PileStateOptions.COMPLETED;
  if (isCompleted) return sort.completed.sortField !== SortOption.DATE_COMPLETED || !sort.completed.desc;
  return sort.todo.sortField !== SortOption.DUE_DATE || sort.todo.desc;
};

export const isFilterActive = (
  {
    types,
    courseIds,
    negativeCushionMarked,
    hidePlannedMarked,
    examMarked,
    last30Days,
    lastWeek,
    thisWeek,
    next7Days,
    nextWeek,
    priorities
  }: PileFilterType & any,
  activeTab: PileStateOptions
) => {
  const isCompleted = activeTab === PileStateOptions.COMPLETED;
  const isUpcoming = activeTab === PileStateOptions.UPCOMING;
  return (
    (courseIds && courseIds.length > 0) ||
    (priorities && priorities.length > 0) ||
    thisWeek ||
    (types && types.length > 0) ||
    (isCompleted && (last30Days || lastWeek)) ||
    (!isCompleted && (isUpcoming || next7Days || nextWeek || hidePlannedMarked || negativeCushionMarked || examMarked))
  );
};

export const numberOfSelectedFilters = ({ types, hidePlannedMarked, courseIds, negativeCushionMarked }: PileFilterType) =>
  courseIds.length + types.length + Number(hidePlannedMarked) + Number(negativeCushionMarked);

export const getTodoFilterDateRange = ({ thisWeek, next7Days, nextWeek }: any) => {
  const today = timeUtils.now().startOf('day');
  let from;
  let to;
  if (thisWeek || nextWeek || next7Days) {
    from = today.clone();
    if (thisWeek) {
      from = from.startOf('week').toDate();
    } else if (next7Days) {
      from = from.toDate();
    } else if (nextWeek) {
      from = from
        .startOf('week')
        .add(7, 'days')
        .toDate();
    }
    if (nextWeek) {
      to = today
        .endOf('week')
        .add(7, 'days')
        .toDate();
    } else if (next7Days) {
      to = today
        .add(7, 'days')
        .endOf('day')
        .toDate();
    } else if (thisWeek) {
      to = today.endOf('week').toDate();
    }
  }
  return { from, to };
};

export const getCompletedFilterDateRange = ({ last30Days, lastWeek, thisWeek }: any) => {
  const today = timeUtils.now().startOf('day');
  let from;
  let to;
  if (last30Days || lastWeek || thisWeek) {
    to = today.clone();
    if (thisWeek) {
      to = to.endOf('week').toDate();
    } else if (last30Days) {
      to = to.endOf('day').toDate();
    } else if (lastWeek) {
      to = to.startOf('week').toDate();
    }

    if (last30Days) {
      from = today.subtract(30, 'days').toDate();
    } else if (lastWeek) {
      from = today
        .startOf('week')
        .subtract(7, 'days')
        .toDate();
    } else if (thisWeek) {
      from = today.startOf('week').toDate();
    }
  }
  return { from, to };
};

export const getSectionLabel = (section, size, isCompleted, theme) => {
  if (!section) return { label: undefined, labelColor: undefined };

  switch (section) {
    case PileSections.NO_DUE_DATE:
      const Wrapper = isCompleted ? MediumText : React.Fragment;
      return {
        labelColor: isCompleted ? theme.textColor : theme.textStrongColor,
        label: (
          <Wrapper>
            {!isCompleted && (
              <MaterialIcon name={'calendar_today'} size={16} color={theme.textStrongColor} style={{ marginRight: '5px' }} />
            )}
            {`${t.NO_DUE_DATE} ${size ? `(${size})` : ''}`}
          </Wrapper>
        )
      };
    case PileSections.OVERDUE:
      return { labelColor: colors.negative, label: `${t.OVERDUE} (${size})` };
    case PileSections.RECENTLY_CREATED:
      return { labelColor: colors.positive, label: t.RECENTLY_CREATED };
    default:
      return {
        label: (
          <DueDate>
            {`${t.DUE}: `}
            <span>
              {isCompleted
                ? `${formatMomentDateAs(toMomentDate(section), Formats.MONTH_DAY_FORMAT)}`
                : `${sectionDueDate(section)}`}
            </span>
          </DueDate>
        )
      };
  }
};
