import React from 'react';
import { gapi } from 'gapi-script';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';
import { googleClientid } from '../../../config/configConstants';
import { googleCalendarActions } from '../../../state/googleCalendar';
import { getGoogleCalendars } from '../../../state/calendar/selectors';
import AddCalendarButton from './AddCalendarButton';

const SCOPES = ['https://www.googleapis.com/auth/calendar.events', 'https://www.googleapis.com/auth/calendar.readonly'].join(
  ' '
);

const CLIENT_ID = `${googleClientid}.apps.googleusercontent.com`;

gapi.load('client', () => {
  initGapiClient(1);
});

function initGapiClient(attempt: number) {
  if (attempt === 4) {
    return;
  }
  console.log(`init gapi client - ${attempt}. attempt`);
  if (!gapi.client) {
    return setTimeout(() => initGapiClient(attempt + 1), 3000);
  }
  return gapi.client
    .init({
      clientId: CLIENT_ID,
      scope: SCOPES
    })
    .then(() => {
      console.log('gapi client successfully initiated');
    })
    .catch(e => {
      console.log(e);
      console.log('error initiating gapi client');
    });
}

export function useGoogleApi() {
  const dispatch = useDispatch();
  const calendars = useSelector(getGoogleCalendars);

  const getAuthorizationCode = async () => {
    const { code } = await gapi.auth2.getAuthInstance().grantOfflineAccess();
    return code;
  };

  const approveAccessToGoogle = async (force: boolean = false, reconnect?: boolean) => {
    try {
      const isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
      if (force || !isSignedIn) {
        const code = await getAuthorizationCode();
        dispatch({
          type: getType(googleCalendarActions.getGoogleAccess.request),
          payload: {
            code,
            reconnect
          }
        });
      } else if (!calendars.length) {
        dispatch({
          type: getType(googleCalendarActions.getSavedCalendars.request)
        });
      }
      return { ok: true };
    } catch (e) {
      console.log(e);
      // todo if not allowed by user we can pop up the error to them
      return { ok: false };
    }
  };

  return { approveAccessToGoogle };
}

export const googleAuth2SignOut = () => {
  gapi.auth2.getAuthInstance?.().disconnect();
  gapi.auth2.getAuthInstance?.().signOut();
};

export const reconnect = () => {
  return <AddCalendarButton reconnect />;
};
