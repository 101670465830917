import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as courseActions from '../../state/course/actions';
import { getImportCoursePreview } from '../../state/calendar/selectors';
import { RootState } from '../../state/rootReducer';
import { ExportCourseResponse } from '../../api/lambdaApi';
import ModalDialog, { DialogActions } from '../common/ModalDialog';
import t from '../../i18n/t';
import Button from '../common/buttons/Button';
import { Column, FlexColumn, Row, textOverflowStyle } from '../common/layoutUtils';
import { LargeText, MediumText, RegularText } from '../../utils/typography';
import { DATE_FORMAT, formatMomentDateAs, formatStringDateAs, toMomentDate } from 'shovel-lib/utils/timeUtils';
import { Formats, GradeWeightType } from 'shovel-lib/types';
import ButtonWithLoader from '../common/buttons/ButtonWithLoader';
import CheckBox from '../common/CheckBox';
import { secondsToDurationInMinutes } from '../../utils/filters';
import colors from '../../utils/colors';
import TippyTooltip from '../common/tooltip/TippyTooltip';

type PropsFromState = {
  opened: boolean;
  courseId?: number;
  courseCorrelationId?: string;
  courseName?: string;
  courseColorHex?: string;
  data?: ExportCourseResponse;
};

type PropsFromDispatch = {
  importCourse: typeof courseActions.importCourse.request;
  closeImportCourseDialog: typeof courseActions.closeImportCourseDialog;
};

type Props = PropsFromState & PropsFromDispatch;

const ImportCourseConfirmationDialog: FC<Props> = props => {
  const [data, setData] = useState<ExportCourseResponse | null>(null);

  const onClose = useCallback(() => {
    setData(null);
    props.closeImportCourseDialog();
  }, []);

  const { opened, courseName } = props;

  return (
    <ModalDialog
      open={opened}
      onClose={() => onClose()}
      size={'lg'}
      title={`${t.IMPORT_SYLLABUS} to "${courseName}"`}
      contentStyle={{ paddingLeft: '100px', paddingRight: '100px' }}
      fullWidth
    >
      {!!data ? <ImportPreview {...props} data={data} onClose={onClose} /> : renderFilePicker(setData)}
    </ModalDialog>
  );
};

function renderFilePicker(setData: Function) {
  return (
    <Column>
      <RegularText>{t.IMPORT_COURSE_DIALOG_TEXT}</RegularText>
      <ButtonWrapper>
        <Button
          onClick={async () => {
            const file = await pickCourseExportFile();
            const fr = new FileReader();
            // @ts-ignore
            fr.readAsText(file);
            fr.onload = () => {
              // @ts-ignore
              const course = JSON.parse(fr.result);
              setData(course);
            };
            fr.onerror = () => {
              console.log(fr.error);
            };
          }}
          filled
        >
          {t.CHOOSE_FILE}
        </Button>
        <MediumText>{t.IMPORT_COURSE_DIALOG_EXTENSION_TEXT}</MediumText>
      </ButtonWrapper>
    </Column>
  );
}

function pickCourseExportFile() {
  return new Promise(resolve => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.shovel';

    input.onchange = () => {
      // @ts-ignore
      const files = Array.from(input.files);
      resolve(files[0]);
    };

    input.click();
  });
}

function ImportPreview(props: Props & { data: ExportCourseResponse; onClose: Function }) {
  const [includeSchedule, setIncludeSchedule] = useState<boolean>(true);
  const [includeTeachers, setIncludeTeachers] = useState<boolean>(true);

  const { courseId, courseCorrelationId, courseName, courseColorHex, importCourse, data, onClose } = props;

  if (!courseId || !courseCorrelationId || !courseName || !courseColorHex) return null;

  const course = data.info;

  return (
    <>
      <Row style={{ marginBottom: '30px', justifyContent: 'center' }}>
        <LargeText strong>You can adjust everything after import.</LargeText>
      </Row>

      <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
        <TippyTooltip
          target={
            <div>
              <CheckBox checked={includeSchedule} onChange={() => setIncludeSchedule(!includeSchedule)} label={t.SCHEDULE} />
            </div>
          }
          content={t.INCLUDE_SCHEDULE_TOOLTIP}
        />
      </Row>
      <Column>{data.events.map((e, i) => renderCourseOccurrence(e, i))}</Column>

      {(course.taskCategories.length > 0 || course.readingSources.length) > 0 && (
        <Row style={{ alignItems: 'flex-start' }}>
          <FlexColumn>
            <SectionTitle strong>{t.TASK_CATEGORIES}</SectionTitle>
            <Column>{course.taskCategories.map((tc, i) => renderTaskCategory(tc, i))}</Column>
          </FlexColumn>
          <FlexColumn>
            <SectionTitle strong>{t.READING_SOURCES}</SectionTitle>
            <Column>{course.readingSources.map((rs, i) => renderReadingSource(rs, i))}</Column>
          </FlexColumn>
        </Row>
      )}

      {course.tasks.length > 0 && (
        <>
          <SectionTitle strong>{t.TASKS}</SectionTitle>
          <Column>{course.tasks.map((t, i) => renderTask(t, i, course.taskCategories, course.readingSources))}</Column>
        </>
      )}

      {course.gradeUnits.length > 0 && (
        <>
          <SectionTitle strong>{t.GRADING_RUBRIC}</SectionTitle>
          <Column>
            {course.gradeUnits.map((gu, i) =>
              renderGradeUnit(gu, i, course.weightType === GradeWeightType.PERCENTAGES ? '%' : 'Pts')
            )}
          </Column>
        </>
      )}

      {course.teachers.length > 0 && (
        <>
          <TippyTooltip
            target={
              <div style={{ marginBottom: 10 }}>
                <CheckBox
                  checked={includeTeachers}
                  onChange={() => setIncludeTeachers(!includeTeachers)}
                  label={t.TEACHERS}
                />
              </div>
            }
            content={t.INCLUDE_TEACHERS_TOOLTIP}
          />
          <Column>{course.teachers.map((t, i) => renderTeacher(t, i))}</Column>
        </>
      )}

      <DialogActions position={'center'}>
        <Button onClick={() => onClose()} withoutBorder>
          {t.CANCEL}
        </Button>
        <ButtonWithLoader
          onClick={() =>
            importCourse({
              courseId,
              courseCorrelationId,
              courseName,
              courseColorHex,
              includeSchedule,
              includeTeachers,
              course: data
            })
          }
          action={courseActions.importCourse}
          callback={() => onClose()}
          text={t.IMPORT}
          filled
        />
      </DialogActions>
    </>
  );
}

function renderTaskCategory(taskCategory: any, index: number) {
  return (
    <Column key={index} style={{ marginBottom: '4px' }}>
      <Item>
        {taskCategory.emoji} {taskCategory.name}
      </Item>
    </Column>
  );
}

function renderReadingSource(readingSource: any, index: number) {
  return (
    <Row key={index} style={{ marginBottom: '4px' }}>
      <Item width={160}>{readingSource.name}</Item>
      <Item width={100}>{secondsToDurationInMinutes(readingSource.secondsPerPage)}</Item>
    </Row>
  );
}

function renderTask(task: any, index: number, taskCategories: any[], readingSources: any[]) {
  return (
    <Row key={index} style={{ marginBottom: '4px' }}>
      <Item width={200}>{task.title}</Item>
      <Item width={200}>{task.taskCategoryId ? renderTaskCategoryInTask(task.taskCategoryId, taskCategories) : '-'}</Item>
      <Item width={200}>{task.readingSourceId ? readingSources.find(rs => task.readingSourceId === rs.id).name : '-'}</Item>
      <Item width={160}>{task.dueDate ? formatStringDateAs(task.dueDate, Formats.DATE_TIME_FORMAT) : '-'}</Item>
    </Row>
  );
}

function renderTaskCategoryInTask(id, taskCategories: any[]) {
  const taskCategory = taskCategories.find(tc => id === tc.id);
  return `${taskCategory.emoji} ${taskCategory.name}`;
}

function renderGradeUnit(gradeUnit: any, index: number, weightTypeLabel: string) {
  return (
    <Column style={{ marginBottom: '10px' }}>
      <GradeUnitTitle key={index}>
        <Item width={160}>{gradeUnit.name}</Item>
        <Item width={160}>
          {gradeUnit.weight} {weightTypeLabel}
        </Item>
      </GradeUnitTitle>
      {gradeUnit.grades.map(grade => (
        <Row key={index} style={{ marginBottom: '4px', paddingLeft: '40px' }}>
          <Item width={160}>{grade.name || '-'}</Item>
          <Item width={160}>{grade.date ? formatStringDateAs(grade.date, DATE_FORMAT) : '-'}</Item>
        </Row>
      ))}
    </Column>
  );
}

function renderTeacher(teacher: any, index: number) {
  return (
    <Row key={index} style={{ marginBottom: '4px' }}>
      <Item width={200}>{teacher.fullName || '-'}</Item>
      <Item width={200}>{teacher.email || '-'}</Item>
      <Item width={200}>{teacher.details || '-'}</Item>
    </Row>
  );
}

function renderCourseOccurrence(event: any, index: number) {
  return <Row key={index}>{courseOccurrenceText(event)}</Row>;
}

function courseOccurrenceText(event: any) {
  const start = toMomentDate(event.start);
  const end = toMomentDate(event.start).add(event.durationInMinutes, 'minutes');
  if (!event.isRecurring) {
    return (
      <RegularText style={{ marginBottom: '4px' }}>
        {formatMomentDateAs(start, Formats.DATE_TIME_FORMAT)} - {formatMomentDateAs(end, Formats.DATE_TIME_FORMAT)}
      </RegularText>
    );
  }
  const recurrence = event.recurrenceText.split(' until')[0];
  return (
    <RegularText style={{ marginBottom: '4px' }}>
      {formatMomentDateAs(start, Formats.TIME)} - {formatMomentDateAs(end, Formats.TIME)} {recurrence}
    </RegularText>
  );
}

const mapStateToProps = (state: RootState) => getImportCoursePreview(state);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      importCourse: courseActions.importCourse.request,
      closeImportCourseDialog: courseActions.closeImportCourseDialog
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ImportCourseConfirmationDialog);

const ButtonWrapper = styled(Column)`
  align-items: center;
  margin-top: 100px;

  ${MediumText} {
    margin-top: 16px;
  }
`;

const SectionTitle = styled(RegularText)`
  display: block;
  margin: 10px 0;
`;

const Item = styled(RegularText)<{ width?: number }>`
  ${props => props.width && `width: ${props.width}px;`}
  ${textOverflowStyle}
`;

const GradeUnitTitle = styled(Row)`
  margin-bottom: 4px;
`;
