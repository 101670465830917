import { Moment } from 'moment';
import { actionCreator, asyncActionsCreator } from '../common/utils';
import { DatesContainingTasksMiniResponse } from './types';

const asyncActions = asyncActionsCreator('@@calendar');
const action = actionCreator('@@calendar');

export const getDatesContainingTasksMini = asyncActions<
  { startDate: Moment | Date | string; endDate: Moment | Date | string },
  DatesContainingTasksMiniResponse,
  string
>('DATES_CONTAINING_TASKS_MINI');

export const startWeekTaskUpdateListener = action<undefined>('START_WEEK_TASK_UPDATE_LISTENER');
export const stopWeekTaskUpdateListener = action<undefined>('STOP_WEEK_TASK_UPDATE_LISTENER');
