import {
  CourseGradeResponse,
  CreateGradeRequest,
  GradeCalculationsDto,
  UpdateGradeInfoRequest,
  UpdateGradeRequest
} from 'shovel-lib/types';
import { asyncActionsCreator } from '../../common/utils';

const asyncActions = asyncActionsCreator('@@grade');

export const getGrades = asyncActions<void, CourseGradeResponse, string>('GET_GRADES');

export const createGrade = asyncActions<CreateGradeRequest, CreateGradeRequest & GradeCalculationsDto, string>(
  'CREATE_GRADE'
);

export const updateGrade = asyncActions<UpdateGradeRequest, UpdateGradeRequest & { overallGrade: number }, string>(
  'UPDATE_GRADE'
);

export const updateGradeInfo = asyncActions<UpdateGradeInfoRequest, UpdateGradeInfoRequest, string>('UPDATE_GRADE_INFO');

export const toggleGradeDrop = asyncActions<number, GradeCalculationsDto, string>('GRADE_DROP_TOGGLE');

export const deleteGrade = asyncActions<number, GradeCalculationsDto, string>('DELETE_GRADE');
