import { isDev, sentryDsn, release } from './configConstants';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

if (!isDev) {
  Sentry.init({
    dsn: sentryDsn,
    release,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.0
  });
}

const setUserContext = ({ email }: { email: string }) => {
  if (isDev) return;

  Sentry.configureScope(function(scope) {
    scope.setUser({ email: email });
  });
};

export default {
  setUserContext
};
