import { actionCreator, asyncActionsCreator } from '../common/utils';
import { NotificationT } from 'shovel-lib/types';

const asyncActions = asyncActionsCreator('@@notifications');
const action = actionCreator('@@notifications');

export const getNotifications = asyncActions<void, Notification[], string>('GET_NOTIFICATIONS');

export const countUnseenNotifications = asyncActions<void, number, string>('COUNT_UNSEEN_NOTIFICATIONS');

export const clearNotifications = asyncActions<void, void, string>('CLEAR_NOTIFICATIONS');

export const deleteNotification = asyncActions<number, number, string>('DELETE_NOTIFICATION');

export const openNotification = action<NotificationT>('OPEN_NOTIFICATION');

export const notificationReceived = action<void>('NOTIFICATION_RECEIVED');

export const startNotificationsListener = action<void>('START_NOTIFICATIONS_LISTENER');
