import { Column, FlexColumn, Row } from '../../common/layoutUtils';
import { ExtraLargeText, RegularText } from '../../../utils/typography';
import TextLink from '../../common/TextLink';
import Button from '../../common/buttons/Button';
import React from 'react';
import styled from 'styled-components';
import t from '../../../i18n/t';
import { screenSizes } from '../../../utils/screenUtils';
import storage from '../../../utils/storage';
import { PARENT_PAYMENT } from '../../../utils/constants/routes';
import colors from '../../../utils/colors';
import { alert, NotificationMode } from '../../common/snackbarNotifications/Notifications';

const ParentLink = () => {
  const userId = storage.getUserId();

  const link = `https://dig.shovelapp.io${PARENT_PAYMENT}/${userId}`;

  return (
    <Container>
      <FlexColumn>
        <ExtraLargeText strong color={colors.pink}>
          {t.HAVE_PARENTS_PAY}
        </ExtraLargeText>
        <Text strong>{t.PARENT_LINK_TEXT}</Text>
        <CustomTextLink onClick={() => window.open(link)}>{link}</CustomTextLink>
        <Link id={'parent-link'} value={link} readOnly />
      </FlexColumn>
      <Column>
        <Button
          size={'lg'}
          onClick={() => {
            const link = document.getElementById('parent-link') as HTMLInputElement;
            if (!link) return;
            link.select();
            document.execCommand('copy');
            link.setSelectionRange(0, 0);
            alert(t.PARENT_LINK_COPIED, NotificationMode.SUCCESS);
          }}
        >
          {t.COPY_PARENT_LINK}
        </Button>
      </Column>
    </Container>
  );
};

export default ParentLink;

export const BottomSection = styled(Row)`
  margin: 20px 10px 0;
  padding: 20px 30px;
  background-color: ${props => props.theme.backgroundDark};
  justify-content: space-between;
  transition: background-color 0.2s ease;
  align-items: flex-start;
  .payment-method {
    max-width: 240px;
    .payment-option-title {
      margin-bottom: 10px;
    }
    .payment-option-responsive-title {
      display: none;
    }
  }
  @media (max-width: ${screenSizes.xsmall}) {
    margin: 0;
    padding: 15px;
    box-sizing: border-box;
    background: transparent;
    justify-content: center;
    .payment-method {
      width: 100%;
      max-width: unset !important;
      button {
        margin-top: 20px;
      }
      .payment-option-title {
        display: none;
      }
      .payment-option-responsive-title {
        display: block;
        margin-bottom: 20px;
      }
    }
    .coupon-extra-text {
      display: none;
    }
  }
  @media (max-width: 320px) {
    padding: 10px 20px;
  }
`;

const Container = styled(BottomSection)`
  @media (max-width: ${screenSizes.xsmall}) {
    display: none;
  }
  align-items: flex-end;
  > * {
    max-width: 500px;
  }
`;

const Text = styled(RegularText)`
  margin: 5px 0;
`;

const Link = styled.textarea`
  height: 0;
  width: 0;
  z-index: -1;
`;

const CustomTextLink = styled(TextLink)`
  width: fit-content;
`;
