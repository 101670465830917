import React, { PureComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import t from '../../../i18n/t';
import { ManageDueDate } from '../manage/partials';
import { FormFieldName } from '../../../utils/enums';
import { DaysAheadCell, NameCell, PageRangesCell, TimeDurationCell } from '../../course/workload/WorkloadCells';
import TippyTooltip from '../../common/tooltip/TippyTooltip';
import colors from '../../../utils/colors';
import { TextOverflowDiv } from '../../common/layoutUtils';
import { commonPopupCSS } from '../../../utils/popupUtils';
import { isTouchScreen } from '../../../utils/screenUtils';
import { DeleteButton } from '../../common/buttons';
import MaterialIcon from '@components/common/icons/MaterialIcon';

type Props = {
  task: any;
  index: number;
  theme: any;
  deleteTask: any;
  insertTask: any;
  touched: any;
  error: any;
  setFieldValue: any;
  getEstimateForReadingSource: any;
  // only for positioning copy icon
  visibleHint?: boolean;
};

class RepeatTaskRow extends PureComponent<Props> {
  render() {
    const { task, index, setFieldValue, error, touched, theme, insertTask, deleteTask, visibleHint } = this.props;
    return (
      // removed className={'tr'} as "width: fit-content;" set in CourseWorkload affected this row's width
      <TaskRow selected={false}>
        <div tabIndex={index} className={`td`} style={{ position: 'relative' }}>
          <ManageDueDate
            workload
            courseId={task[FormFieldName.COURSE]}
            dueDate={task[FormFieldName.DUE_DATE]}
            save={data => {
              setFieldValue(`tasks.${index}.${FormFieldName.DUE_DATE}`, data.dueDate);
            }}
            calendarClassName={'workload-due-date'}
            taskType={task.type}
          />
          {task.holiday && <TippyTooltip target={<HolidayIcon>{'⛱️'}</HolidayIcon>} content={task.holiday} />}
        </div>
        <div tabIndex={index} className={`td`}>
          <NameCell
            key={index}
            value={task[FormFieldName.NAME]}
            save={data => {
              if (data.title !== undefined) {
                setFieldValue(`tasks.${index}.${FormFieldName.NAME}`, data.title);
              } else {
                setFieldValue(`tasks.${index}.${FormFieldName.SUBTASKS}`, data.subtasks);
              }
            }}
            isRepeatTasks
            theme={theme}
            touched={touched}
            error={error}
            {...task}
          />
        </div>
        {/*<div tabIndex={index} className={`td`}>*/}
        {/*  <DescriptionCell*/}
        {/*    key={index}*/}
        {/*    value={task[FormFieldName.DESCRIPTION]}*/}
        {/*    save={data => {*/}
        {/*      if (data.subtitle !== undefined) {*/}
        {/*        setFieldValue(`tasks.${index}.${FormFieldName.DESCRIPTION}`, data.subtitle);*/}
        {/*      } else {*/}
        {/*        setFieldValue(`tasks.${index}.${FormFieldName.SUBTASKS}`, data.subtasks);*/}
        {/*      }*/}
        {/*    }}*/}
        {/*    theme={theme}*/}
        {/*    {...task}*/}
        {/*  />*/}
        {/*</div>*/}

        <div tabIndex={index} className={`td`}>
          <DaysAheadCell
            value={task[FormFieldName.START_AHEAD]}
            {...task}
            save={data => setFieldValue(`tasks.${index}.${FormFieldName.START_AHEAD}`, data.daysToStartAhead)}
          />
        </div>
        {task[FormFieldName.READING_SOURCE] && (
          <div tabIndex={index} className={`td`}>
            <PageRangesCell
              {...task}
              save={data => {
                setFieldValue(`tasks.${index}.${FormFieldName.PAGE_RANGES}`, data.pageRanges);
                const estimate = this.props.getEstimateForReadingSource(data.pageRanges);
                setFieldValue(`tasks.${index}.${FormFieldName.ESTIMATE}`, estimate);
              }}
            />
          </div>
        )}
        <div tabIndex={index} className={`td ${task[FormFieldName.AUTO_ESTIMATE] ? 'disabled-cell' : ''}`}>
          <TimeDurationCell
            fieldName={FormFieldName.ESTIMATE}
            value={task[FormFieldName.ESTIMATE]}
            {...task}
            save={data => setFieldValue(`tasks.${index}.${FormFieldName.ESTIMATE}`, data.timeNeed)}
            isDisabled={task[FormFieldName.AUTO_ESTIMATE]}
            tooltip={
              task[FormFieldName.PAGE_RANGES] && task[FormFieldName.PAGE_RANGES].length > 0
                ? t.ESTIMATE_TASK_DISABLED_FILLED
                : task[FormFieldName.AUTO_ESTIMATE]
                ? t.ESTIMATE_TASK_DISABLED
                : undefined
            }
          />
        </div>
        <Delete key={index} onClick={() => deleteTask(index)} />
        <TippyTooltip
          target={
            <CopyIconWrapper visibleHint={visibleHint}>
              <CopyIcon
                className={'duplicate_icon'}
                name={'content_copy'}
                size={16}
                onClick={() => insertTask(index + 1, task)}
                outline
              />
            </CopyIconWrapper>
          }
          content={t.DUPLICATE}
        />
      </TaskRow>
    );
  }
}

export default withTheme(RepeatTaskRow);

const CopyIconWrapper = styled.div<{ visibleHint?: boolean }>`
  position: absolute;
  left: 10px;
  top: ${props => (props.visibleHint ? 10 : 8)}px;
`;

const CopyIcon = styled(MaterialIcon)`
  width: 20px;
  margin-top: -2px;
  &:hover {
    color: ${props => props.theme.primary};
  }
`;

const HolidayIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 5px;
`;

const Delete = styled(DeleteButton)`
  visibility: hidden;
  position: absolute;
  right: -5px;
  top: 6px;
  > i {
    padding: 0px;
  }
`;

const TaskRow = styled.div<{ selected?: boolean }>`
  display: flex;
  position: relative;
  padding: 0px 20px 0px 25px !important;
  box-sizing: border-box;
  &:last-child {
    .td {
      border-bottom: 1px solid ${props => props.theme.borderColor};
    }
  }
  &:hover {
    cursor: pointer;
    ${Delete} {
      visibility: visible;
    }
    i:not(.hidden-check-icon) {
      visibility: visible !important;
    }
    .td {
      border-top-color: ${props => props.theme.textColor};
    }
    &:last-child .td {
      border-bottom-color: ${props => props.theme.textColor};
    }
    & + .tr .td {
      border-top-color: ${props => props.theme.textColor};
    }
  }
  .td:first-child {
    display: flex;
    align-items: center;
  }
  .td {
    flex: 1;
    line-height: 1.5;
    min-width: 0;
    border: 1px solid ${props => props.theme.borderColor};
    border-bottom-color: transparent;
    border-right-color: transparent;
    font-size: 12px;
    font-weight: bold;
    color: ${props => props.theme.textStrongColor};
    &:not(.disabled-cell):focus,
    &:not(.disabled-cell):focus-within {
      border-color: ${colors.primaryPurple} !important;
      border-bottom-color: ${colors.primaryPurple} !important;
    }

    color: ${props => props.theme.textStrongColor};
    position: relative;
    vertical-align: top;
    ${props => props.selected && `background: ${props.theme.backgroundLight};`}
    transition: border-color 0.2s ease;
    box-sizing: border-box;
    &:first-child {
      border-left: 1px inset transparent;
    }
    ${TextOverflowDiv} {
      padding: 7px 9px;
      height: 100%;
      box-sizing: border-box;
    }
    .cell-popup-content {
      ${commonPopupCSS}
      top: 0 !important;
      left: 0 !important;
      min-height: 100%;
      min-width: 100%;
    }
    .react-datepicker-wrapper {
      .react-datepicker__input-container input {
        box-shadow: none;
        width: 100% !important;
        font-size: 12px !important;
        font-weight: bold !important;
        padding: 7px 9px;
      }
    }
  }

  .td.delete {
    .popup-content span {
      white-space: nowrap;
    }
    i:not(.task-completed-icon):not(.hidden-check-icon) {
      ${!isTouchScreen && 'visibility: hidden;'}
    }
    .delete-button {
      padding: 0;
    }
  }
  .td.details {
    padding: 0;
  }
`;
