import {
  ThinCourseDto,
  PileCompletedTask,
  PileDoLaterTask,
  PileToDoTask,
  TasksByDay,
  PileFilterType,
  AllToDoType,
  MoveToDoDialogType,
  PileTask
} from 'shovel-lib/types';

type LoadingAndError = {
  loading: boolean;
  lastRefresh?: Date;
  error: string;
};

export type PileToDoState = LoadingAndError & {
  tasksByDay: TasksByDay;
  drop: number;
  allTasksFetched: boolean;
};

export type PileDoLaterCompletedState = LoadingAndError & {
  tasks: PileDoLaterTask[] | PileCompletedTask[];
};

export type PileUpcomingState = LoadingAndError & {
  tasks: PileToDoTask[];
};

export enum PileSections {
  RECENTLY_CREATED = 'recentlyCreated',
  OVERDUE = 'overdue',
  NO_DUE_DATE = 'noDueDate'
}

export enum SortOption {
  DUE_DATE = 'dueDate',
  TOTAL_ESTIMATE = '(estimate)',
  STILL_NEED = 'timeNeed',
  LEFT_TO_PLAN = '(unplanned)',
  DATE_COMPLETED = 'completedOn',
  TIME_SPENT = 'timeSpent',
  PRIORITY = 'priority'
}

export type PileState = {
  activeTab: PileStateOptions;
  courses: ThinCourseDto[];
  filter: PileFilterType;
  sort: {
    todo: {
      sortField: SortOption;
      desc: boolean;
    };
    completed: {
      sortField: SortOption;
      desc: boolean;
    };
  };
  search: string;
  allToDo: AllToDoType;
  withinStartDate: { tasks: any };
  completed: { tasks: any };
  [PileSections.OVERDUE]: { tasks: any; expanded?: boolean };
  [PileSections.NO_DUE_DATE]: { tasks: any; expanded?: boolean };
  moveToDoDialog: {
    open: boolean;
    data: MoveToDoDialogType;
  };
  [PileSections.RECENTLY_CREATED]: { tasks: any; expanded?: boolean };
};

export type ApplyFilter = {
  activeTab: PileStateOptions;
  filter: PileFilterType;
  search?: string;
  sort: any;
  isRefresh?: boolean;
};

export enum PileStateOptions {
  UPCOMING = 'UPCOMING',
  ACTIVE = 'ACTIVE',
  DO_LATER = 'DO_LATER',
  COMPLETED = 'COMPLETED'
}

export type CalculateCushionRequest = {
  until: Date;
};

export type CalculateCushionResponse = {
  [key: number]: number;
};

export type TaskForVirtualization = {
  task: PileTask;
  height: number;
  section: string;
  isLast?: boolean;
  isCompleted?: boolean;
};

export type VirtualizationSection = Omit<TaskForVirtualization, 'task'> & {
  task?: undefined;
  size?: number;
  expanded?: boolean;
};
