import { createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { NotificationState } from './types';

const initialState: NotificationState = {
  notifications: [],
  unseenCount: 0
};

const reducer = createReducer(initialState)
  .handleAction(actions.getNotifications.success, (state, action) => ({
    // when notifications are fetched, they are automatically seen
    unseenCount: 0,
    notifications: action.payload
  }))
  .handleAction(actions.countUnseenNotifications.success, (state, action) => ({
    ...state,
    unseenCount: action.payload
  }))
  .handleAction(actions.clearNotifications.success, () => ({
    notifications: [],
    unseenCount: 0
  }))
  .handleAction(actions.deleteNotification.success, (state, action) => ({
    notifications: state.notifications.filter(n => n.id !== action.payload),
    unseenCount: 0
  }))
  .handleAction(actions.notificationReceived, (state, action) => ({
    ...state,
    unseenCount: state.unseenCount + 1
  }));

export default reducer;
