import { all } from 'redux-saga/effects';
import { Dispatch } from 'redux';

import authSaga from './auth/operations';
import timerSaga from './timer/operations';
import semesterSaga from './semester/operations';
import calendarSaga from './calendar/operations';
import pileSaga from './pile/operations';
import taskSaga from './task/operations';
import termSaga from './terms/operations';
import courseSaga from './course/operations';
import gradeSaga from './course/grade/operations';
import teacherSaga from './course/teacher/operations';
import eventSaga from './events/operations';
import commonSaga from './common/operations';
import settingsSaga from './settings/operations';
import gpaCalculatorSaga from './gpaCalculator/operations';
import notificationsSaga from './notifications/operations';
import googleCalendarSaga from './googleCalendar/operations';
import mobileBridgeOperations from './mobile/operations';
import workloadOperations from './workload/operations';
import icsOperations from './ics/operations';
import weekCalendarOperations from './weekCalendar/operations';

export default function*(dispatch: Dispatch) {
  yield all([
    authSaga(),
    semesterSaga(),
    calendarSaga(),
    timerSaga(dispatch),
    pileSaga(),
    taskSaga(),
    termSaga(),
    courseSaga(),
    gradeSaga(),
    teacherSaga(),
    eventSaga(),
    commonSaga(),
    settingsSaga(),
    gpaCalculatorSaga(),
    notificationsSaga(dispatch),
    googleCalendarSaga(),
    mobileBridgeOperations(),
    workloadOperations(),
    icsOperations(),
    weekCalendarOperations()
  ]);
}
