import React, { FC } from 'react';
import styled, { withTheme } from 'styled-components';
import { Column } from './layoutUtils';
import colors from '../../utils/colors';
import { MediumText, MiniText } from '../../utils/typography';
import MaterialIcon from './icons/MaterialIcon';
import TippyTooltip from './tooltip/TippyTooltip';
import t from '../../i18n/t';

type Props = {
  message: string;
  hintNumber?: number;
  close?: any;
  className?: any;
  theme: any;
  hint?: boolean;
};
const InfoBox: FC<Props> = ({ hintNumber, message, close, theme, className, hint }) => {
  return (
    <Container isHint={!!hintNumber} className={className}>
      {hintNumber && <MiniText>{`💡 Hint #${hintNumber}`}</MiniText>}
      {hint && <MiniText>{`💡 Hint`}</MiniText>}
      <MediumText color={theme.textStrongColor}>{message}</MediumText>
      {close && (
        <TippyTooltip
          target={
            <CloseButton onClick={close}>
              <MaterialIcon name={'close'} size={14} color={theme.textStrongColor} />
            </CloseButton>
          }
          content={t.CLOSE_INFO_BOX_TOOLTIP}
          position={'right'}
        />
      )}
    </Container>
  );
};

const Container = styled(Column)<{ isHint?: boolean }>`
  flex-shrink: 0;
  border: 1px solid ${colors.positive};
  background: ${props => props.theme.hintColor};
  padding: 8px;
  margin-top: 10px;
  margin-bottom: ${props => (props.isHint ? '15px' : '17px')};
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  cursor: default;
  > span {
    white-space: pre-line;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  height: 12px;
  width: 12px;
  display: flex;
`;

export default withTheme(InfoBox);
