import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import colors from '../../../../utils/colors';
import { MediumText, MiniText } from '../../../../utils/typography';
import { getPageRanges, savePageRanges } from '../../../../state/task/actions';
import styled from 'styled-components';
import { Row } from '../../../common/layoutUtils';
import { PageRange } from 'shovel-lib/types';
import { TextInput } from '../../../common/inputs';
import { isPositiveInteger, isPositiveIntegerNotEmpty } from '../../../../utils/validators';
import t from '../../../../i18n/t';
import MaterialIcon from '../../../common/icons/MaterialIcon';
import InputWrapper from '../../../common/inputs/InputWrapper';
import { ERROR_TIMEOUT } from '../../../../utils/taskUtils';
import { INPUT_LABEL_HEIGHT, INPUT_VERTICAL_PADDING } from '@components/common/inputs/Input.styles';

type OwnProps = {
  autofocus?: boolean;
  pageRanges?: PageRange[];
  taskId?: number;
  save?: (data: { pageRanges: PageRange[] }) => void;
  className?: string;
  field?: any;
  form?: any;
  noInput?: boolean;
  maxWidth?: number;
  small?: boolean;
};

type PropsFromDispatch = {
  getPageRanges: typeof getPageRanges.request;
  savePageRanges: typeof savePageRanges.request;
};

type State = {
  pageRangeInput: string;
  error: boolean;
};

type Props = OwnProps & PropsFromDispatch;

const pagesRegExp = /^[0-9]+-[0-9]*$/;

class ManagePageRanges extends React.Component<Props, State> {
  readonly state = {
    pageRangeInput: '',
    error: false
  };

  componentDidMount(): void {
    const { pageRanges, taskId, getPageRanges } = this.props;
    if (!pageRanges && !!taskId) getPageRanges(taskId);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const prevPages = prevProps.field?.value || prevProps.pageRanges || [];
    const pages = this.props.field?.value || this.props.pageRanges || [];
    if (pages.length !== prevPages.length) {
      this.setState({ pageRangeInput: '', error: false });
    }
  }

  showError = () => {
    this.setState({ error: true });
    setTimeout(() => {
      this.setState({ error: false });
    }, ERROR_TIMEOUT);
  };

  removePageRange = (e: any, index: number) => {
    const { pageRanges, field, form, taskId, savePageRanges, save } = this.props;
    const pages = [...(field?.value || pageRanges)];
    e.stopPropagation();
    if (pages) {
      pages.splice(index, 1);
      if (taskId) {
        savePageRanges({ taskId, pageRanges: pages });
      } else if (field?.name && form?.setFieldValue) {
        form.setFieldValue(field.name, pages);
      } else if (save) {
        save({ pageRanges: pages });
      }
    }
  };

  onChange = (e: any) => {
    if (!isPositiveInteger(e.target.value) && !pagesRegExp.test(e.target.value)) {
      e.preventDefault();
      return;
    }
    this.setState({ pageRangeInput: e.target.value, error: false });
  };

  addPageRange = (e: { key: string } = { key: 'Enter' }) => {
    if (!this.state.pageRangeInput) return;
    const rangeParts = this.state.pageRangeInput.split('-');
    if (e.key === 'Enter') {
      if (rangeParts.length > 2 || !rangeParts.reduce((acc, part) => acc && isPositiveIntegerNotEmpty(part), true)) {
        this.showError();
        return;
      }
      const { pageRanges, field, form, taskId, savePageRanges, save } = this.props;
      const pages = field?.value || pageRanges || [];
      const range: any = { from: undefined, to: undefined, pages: 0 };
      if (rangeParts.length === 1) {
        range.pages = parseInt(rangeParts[0]);
      } else if (rangeParts.length === 2) {
        range.from = parseInt(rangeParts[0]);
        range.to = parseInt(rangeParts[1]);
        range.pages = range.to - range.from + 1;
        if (range.pages <= 0) {
          this.showError();
          return;
        }
      }
      if (taskId) {
        savePageRanges({ taskId, pageRanges: [...pages, range] });
      } else if (field?.name && form?.setFieldValue) {
        form.setFieldValue(field.name, [...pages, range]);
      } else if (save) {
        save({ pageRanges: [...pages, range] });
      }
    }
  };

  render() {
    const { pageRanges, field, autofocus, className, noInput, maxWidth, small } = this.props;
    const { pageRangeInput } = this.state;
    const pages = field?.value || pageRanges || [];
    const limitedPages = maxWidth ? pages.slice(0, maxWidth) : pages;
    const rest = maxWidth ? pages.length - maxWidth : pages.length;
    const content = (
      <PageRanges className={className} wrapped={!!field}>
        {limitedPages.map((range: any, index: number) => (
          <PageRangeWrapper key={index} small={small}>
            <MediumText strong>{range.from && range.to ? `${range.from} - ${range.to}` : range.pages}</MediumText>
            <CloseIcon noInput={noInput} name={'close'} onClick={e => this.removePageRange(e, index)} size={12} />
          </PageRangeWrapper>
        ))}
        {!noInput && (
          <PageRangeWrapper input hasError={this.state.error} small={small}>
            <RangeInput
              wrapped={!!field}
              name={field?.name}
              bare
              inline
              autofocus={autofocus}
              placeholder={`${t.EG} 10 ${t.OR} 15-20`}
              value={pageRangeInput}
              onKeyUp={this.addPageRange}
              onBlur={() => this.addPageRange({ key: 'Enter' })}
              onChange={this.onChange}
            />
          </PageRangeWrapper>
        )}
        {maxWidth && rest > 0 && (
          <NumberOfPages small={small}>
            <MiniText color={colors.white}>{`+${rest}`}</MiniText>
          </NumberOfPages>
        )}
      </PageRanges>
    );
    return !!field ? (
      <StyledInputWrapper label={t.PAGES} hasError={this.state.error} touched>
        {content}
      </StyledInputWrapper>
    ) : (
      content
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPageRanges: getPageRanges.request,
      savePageRanges: savePageRanges.request
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ManagePageRanges);

const CloseIcon = styled(MaterialIcon)<{ noInput?: boolean }>`
  position: absolute;
  visibility: hidden;
  background: ${colors.negative};
  border-radius: 50%;
  color: ${colors.white};
  top: ${props => (props.noInput ? -3 : 0)}px;
  right: -3px;
`;

const PageRangeWrapper = styled(Row)<{ input?: boolean; hasError?: boolean; small?: boolean }>`
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;
  ${props => props.hasError && `input { color: ${colors.negative}; -webkit-text-fill-color: ${colors.negative}; }`};

  ${MediumText} {
    background: ${props => (props.input ? props.theme.background : props.theme.borderColor)};
    padding: 0 5px;
    border-radius: 5px;
  }
  ${props => (props.input ? 'flex: 1;' : 'cursor: pointer;')};
  ${props => !props.input && 'padding: 5px 0 0 5px; z-index: 2;'};
  ${props => props.small && `padding-top: 0px !important`};
  ${MediumText} {
    flex: 1;
    text-align: center;
  }
  &:hover {
    ${CloseIcon} {
      visibility: visible;
    }
  }
`;

const PageRanges = styled.div<{ wrapped?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  ${props => !props.wrapped && `margin-top:-3px`};
  ${props =>
    props.wrapped &&
    `padding-bottom: ${INPUT_VERTICAL_PADDING}px;
     padding-top: ${INPUT_LABEL_HEIGHT + INPUT_VERTICAL_PADDING - 5}px;\
     input { margin-top: -${INPUT_LABEL_HEIGHT + INPUT_VERTICAL_PADDING - 5}px}`}
`;

const RangeInput = styled(TextInput)<{ wrapped?: boolean }>`
  width: 100%;
  min-width: 60px;
  margin-bottom: -${INPUT_VERTICAL_PADDING}px;
  input {
    width: 100%;
    font-size: 12px;
    ${props =>
      !props.wrapped &&
      `
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 10px;`};
  }
`;

const StyledInputWrapper = styled(InputWrapper)<{ hasError: boolean }>`
  width: 100%;
  ${props => props.hasError && `input { color: ${colors.negative}; -webkit-text-fill-color: ${colors.negative}; }`}
`;

const NumberOfPages = styled(Row)<{ small?: boolean }>`
  width: 18px;
  height: 18px;
  background: ${props => props.theme.textColor};
  border-radius: 50%;
  ${props => !props.small && `margin-top: 5px`};
  box-sizing: border-box;
  justify-content: center;
`;
