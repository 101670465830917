import { ApiResponse } from 'apisauce';
import { network } from './network';
import { CalendarEventType, GradeWeightType } from 'shovel-lib/types';

export interface LambdaApi {
  exportCourse: (request: ExportCourseRequest) => Promise<ApiResponse<ExportCourseResponse>>;
  importCourse: (request: ImportCourseRequest) => Promise<ApiResponse<void>>;
}

const exportCourse = (request: ExportCourseRequest) =>
  network.get<ExportCourseResponse>(`/semesters/${request.semesterId}/courses/${request.courseId}/export`);
// lambdaNetwork.get<ExportCourseResponse>(`/course-share/export`, request);

const importCourse = (request: ImportCourseRequest) => {
  return network.post<void>(`/semesters/${request.semesterId}/courses/${request.courseId}/import`, request);
};
// lambdaNetwork.post<void>(`/course-share/import`, request);

export const lambdaApi: LambdaApi = {
  exportCourse,
  importCourse
};

export type ExportCourseRequest = {
  courseId: number;
  semesterId: number;
  courseCorrelationId: string;
};

export type ExportCourseDto = {
  name: string;
  colorHex: string;
  weightType: GradeWeightType;
  correlationId: string;
  notes: string;
  taskCategories: {
    id: number;
    name: string;
    emoji: string;
    ordinalNumber: number;
  }[];
  readingSources: {
    name: string;
    secondsPerPage: number;
    ordinalNumber: number;
  }[];
  gradeUnits: {
    name: string;
    weight: number;
    ordinalNumber: number;
    grades: {
      name: string;
      date: string;
    }[];
  }[];
  teachers: {
    fullName: string;
    email: string;
    details: string;
  }[];
  tasks: {
    title: string;
    subtitle: string;
    dueDate: string;
    daysToStartAhead: number;
    completedOn: string;
    timeNeed: number;
    timeSpent: number;
    pages: number;
    pageRanges: {
      from: number;
      to: number;
    }[];
    subtasks: {
      content: string;
      completed: boolean;
    }[];
    readingSourceId: number;
    taskCategoryId: number;
  }[];
};

export type ExportCourseResponse = {
  info: ExportCourseDto;
  events: CalendarEventRRule[];
};

export type ImportCourseRequest = ExportCourseRequest &
  ExportCourseResponse & { courseName: string; courseColorHex: string; includeSchedule: boolean; includeTeachers: boolean };

export type CalendarEventRRule = {
  semesterId: number;
  id: string;
  title: string;
  correlationId: string;
  colorHex: string;
  start: string;
  end: string;
  durationInMinutes: number;
  isRecurring: boolean;
  RRuleSet: string;
  type: CalendarEventType;
  busy: boolean;
  allDay: boolean;
  recurrenceText: string;
};
