import React, { FunctionComponent, PureComponent, SVGProps } from 'react';
import Tooltip from '../../common/tooltip/Tooltip';
import MaterialIcon from '../../common/icons/MaterialIcon';
import colors from '../../../utils/colors';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { RegularText } from '../../../utils/typography';

type Props = {
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  icon?: string;
  tooltip?: string;
  link: string;
  style?: object;
  onClick?: any;
  locationState?: any;
  label?: string;
  className?: any;
};

class NavigationItem extends PureComponent<Props> {
  render() {
    const { tooltip, Icon, icon, link, onClick, style = {}, locationState, label, className } = this.props;
    const item = (
      <Item
        to={{ pathname: link, state: locationState }}
        onClick={onClick}
        activeClassName={'active'}
        style={style}
        className={className}
      >
        {Icon && (
          <Icon
            className={'custom-navigation-icon'}
            style={{
              width: '18px',
              height: '18px',
              transition: 'all 0.2s ease'
            }}
          />
        )}
        {icon && <MaterialIcon className={'navigation-icon'} name={icon} size={20} />}
        {label && <RegularText>{label}</RegularText>}
      </Item>
    );
    if (!tooltip) {
      return item;
    }
    return <Tooltip target={item} position={'right center'} content={tooltip} width={'max-content'} />;
  }
}

export default NavigationItem;

const Item = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: inherit;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  border-radius: 5px;
  padding: 7px;
  margin-top: 10px;
  max-height: 32px;

  .custom-navigation-icon > path {
    fill: ${({ theme }) => theme.textColor};
  }

  .navigation-icon {
    color: ${({ theme }) => theme.textColor};
  }

  &:hover {
    background: ${({ theme }) => theme.backgroundDark};
    .navigation-icon {
      color: ${({ theme }) => theme.primary};
    }
    .custom-navigation-icon > path {
      fill: ${({ theme }) => theme.primary};
    }
  }

  &.active {
    background-color: ${colors.primaryPurple};

    .custom-navigation-icon > path {
      fill: ${colors.white};
    }

    .navigation-icon {
      color: ${colors.white};
    }
  }
`;
