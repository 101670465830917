import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModalDialog, { DialogActions } from '../ModalDialog';
import t from '../../../i18n/t';
import CheckBox from '../CheckBox';
import Button from '../buttons/Button';
import { GoogleCalendar } from '../../../state/googleCalendar/types';
import { useSelector } from 'react-redux';
import { isConnectedWithGoogle } from '../../../state/googleCalendar/selectors';
import { ThinActivityDto, ThinCourseDto } from 'shovel-lib/types';
import { useTheme } from 'styled-components';
import RadioButton from '../radioButton/RadioButton';
import { RootState } from '../../../state/rootReducer';
import { useGoogleApi } from '../../common/googleCalendar/useGoogleApi';
import Skeleton from '../../common/Skeleton';

type Props = {
  courses: ThinCourseDto[];
  activities: ThinActivityDto[];
  connectedCalendarIds: string[];
  courseId?: number;
  visible?: boolean;
  multiselect?: boolean;
  onClickImport: (calendars: GoogleCalendar[], courseId?: number) => void;
  onHide: () => void;
};

function LinkGoogleCalendarModal({
  courses,
  activities,
  courseId,
  connectedCalendarIds,
  visible = false,
  multiselect = false,
  onClickImport,
  onHide
}: Props) {
  const [course, setCourse] = useState<ThinCourseDto | undefined>();
  const { approveAccessToGoogle } = useGoogleApi();

  const [loading, setLoading] = useState(false);

  const isConnected = useSelector(isConnectedWithGoogle);
  const calendars = useSelector((state: RootState) => state.calendar.googleCalendars);

  const theme: any = useTheme();

  const [data, setData] = useState<GoogleCalendar[]>([]);

  const noUnconnectedCalendars = connectedCalendarIds.length === calendars.length;

  useEffect(() => {
    if (!visible) return;

    (async () => {
      setLoading(true);
      const { ok } = await approveAccessToGoogle();
      setLoading(false);
      if (!ok) return;
    })();
  }, [visible]);

  useEffect(() => {
    const course = courses.find(c => c.id === courseId) ?? activities.find(c => c.id === courseId);
    setCourse(course);
  }, [courseId, courses, activities]);

  useEffect(() => {
    if (calendars.length) {
      const newData = calendars.map(c => ({ ...c, selected: connectedCalendarIds.includes(c.googleCalendarId) }));
      setData(newData);
    }
  }, [isConnected, calendars, connectedCalendarIds]);

  const toggleCalendar = useCallback(
    ({ id }) => {
      const newCalendars = data.map(c => {
        if (c.id === id) {
          c.selected = !c.selected;
        } else {
          c.selected = !multiselect && !connectedCalendarIds.includes(c.googleCalendarId) ? false : c.selected;
        }
        return c;
      });
      setData(newCalendars);
    },
    [multiselect, data]
  );

  const onClick = useCallback(() => {
    const newlySelected = data.filter(c => c.selected && !connectedCalendarIds.includes(c.googleCalendarId));
    onClickImport(newlySelected, courseId);
    setData([]);
    onHide();
  }, [courseId, data, connectedCalendarIds]);

  const disableButton = data.filter(c => c.selected && !connectedCalendarIds.includes(c.googleCalendarId)).length === 0;

  return (
    <ModalDialog
      closeOnEscape={false}
      title={
        multiselect
          ? t.GOOGLE_CALENDAR_SELECT_COURSES
          : !courseId
          ? t.LINK_GOOGLE_CALENDAR_WITH_THIS_COURSE
          : t.LINK_GOOGLE_CALENDAR_WITH_COURSE + '' + course?.name
      }
      open={visible}
      onCloseClick={onHide}
    >
      {loading ? (
        <Skeleton amount={3} height={'140px'} />
      ) : (
        data.map(c => {
          const isCalendarConnected = connectedCalendarIds.includes(c.googleCalendarId);
          return (
            <div key={c.id} style={{ padding: '5px 0' }}>
              {multiselect ? (
                <CheckBox
                  disabled={isCalendarConnected}
                  clickData={{ id: c.id }}
                  color={theme.primary}
                  checked={c.selected}
                  onChange={toggleCalendar}
                  label={c.name}
                  iconTextColor={c.textColor}
                />
              ) : (
                <RadioButton
                  disabled={isCalendarConnected}
                  clickData={{ id: c.id }}
                  checked={c.selected}
                  onChange={toggleCalendar}
                  label={c.name}
                />
              )}
            </div>
          );
        })
      )}
      <DialogActions position={'center'}>
        <Button onClick={onHide} withoutBorder>
          {t.CANCEL}
        </Button>
        <Button disabled={noUnconnectedCalendars || disableButton} onClick={onClick} filled>
          {t.SYNC}
        </Button>
      </DialogActions>
    </ModalDialog>
  );
}

export default LinkGoogleCalendarModal;
