import styled from 'styled-components';
import { RegularText } from '@utils/typography';
import { checkSubstep } from '@state/settings/actions';
import TimeNeededStepDialog from './steps/TimeNeededStepDialog';
import StartDateStepDialog from './steps/StartDateStepDialog';
import CushionStepDialog from './steps/CushionStepDialog';
import t from '@i18n/t';
import StepDialog from './StepDialog';

export type ComponentProps = {
  onClose: () => void;
  completed: boolean;
  checkSubstep: typeof checkSubstep;
  stepName: BadgeStep;
  step: any;
  userProgress?: any;
};

export enum badgeNames {
  ROCKSTAR_ROOKIE = 'ROCKSTAR_ROOKIE',
  SCHEDULE_ALCHEMIST = 'SCHEDULE_ALCHEMIST',
  TASK_NINJA = 'TASK_NINJA',
  GOD_OF_TIME = 'GOD_OF_TIME',
  PLANNING_GRADUATE = 'PLANNING_GRADUATE',
  ACE_OF_INITIATIVE = 'ACE_OF_INITIATIVE',
  PRECISION_UNICORN = 'PRECISION_UNICORN'
}

export enum BadgeStep {
  QUICK_SETUP_STEP = 'QUICK_SETUP_STEP',
  SCHEDULE_STEP = 'SCHEDULE_STEP',
  TIME_NEEDED_STEP = 'TIME_NEEDED_STEP',
  START_AHEAD_STEP = 'START_AHEAD_STEP',
  CREATE_TASK_STEP = 'CREATE_TASK_STEP',
  PLAN_TASK_STEP = 'PLAN_TASK_STEP',
  TRACK_PROGRESS_STEP = 'TRACK_PROGRESS_STEP',
  CUSHION_STEP = 'CUSHION_STEP',
  STUDY_TIME_STEP = 'STUDY_TIME_STEP',
  AWAKE_TIME_STEP = 'AWAKE_TIME_STEP'
}

export enum Substeps {
  QUICK_SETUP = 'QUICK_SETUP',
  SCHEDULE_VIDEO = 'SCHEDULE_VIDEO',
  SCHEDULE_CLASS = 'SCHEDULE_CLASS',
  SCHEDULE_ACTIVITY = 'SCHEDULE_ACTIVITY',
  TIME_NEEDED_VIDEO = 'TIME_NEEDED_VIDEO',
  TIME_NEEDED_QUIZ = 'TIME_NEEDED_QUIZ',
  START_AHEAD_VIDEO = 'START_AHEAD_VIDEO',
  START_AHEAD_QUIZ = 'START_AHEAD_QUIZ',
  CREATE_TASK_VIDEO = 'CREATE_TASK_VIDEO',
  CREATE_TASK_CATEGORY = 'CREATE_TASK_CATEGORY',
  CREATE_TASK = 'CREATE_TASK',
  PLAN_TASK_VIDEO = 'PLAN_TASK_VIDEO',
  PLAN_TASK = 'PLAN_TASK',
  PROGRESS_VIDEO = 'PROGRESS_VIDEO',
  ADD_PROGRESS = 'ADD_PROGRESS',
  COMPLETE_TASK = 'COMPLETE_TASK',
  CUSHION_VIDEO = 'CUSHION_VIDEO',
  CUSHION_QUIZ = 'CUSHION_QUIZ',
  STUDY_TIME_VIDEO = 'STUDY_TIME_VIDEO',
  SET_STUDY_TIME = 'SET_STUDY_TIME',
  AWAKE_TIME_VIDEO = 'AWAKE_TIME_VIDEO',
  SET_AWAKE_TIME = 'SET_AWAKE_TIME'
}

export const stepString = (steps: string[]) => {
  let stepString = '';
  steps.forEach((step, i) => {
    stepString += `${i !== 0 && i === steps.length - 1 ? ` ${t.AND}` : ''} ${allSteps[step].number}${
      steps.length - i > 2 ? ',' : ''
    }`;
  });
  return `${steps.length > 1 ? t.STEPS : t.STEP}${stepString}`;
};

export const badgeTooltip = (name: string, steps: string[], freeTrial: boolean) => {
  if (!steps || steps.length === 0) return '';

  let tooltip = t.BADGE_TOOLTIP;
  tooltip = tooltip.replace('${steps}', stepString(steps));
  tooltip = tooltip.replace('${name}', name);

  return `${tooltip}${freeTrial ? t.BADGE_TOOLTIP_EXTRA_DAY : ''}`;
};

export const allBadges = [
  {
    key: badgeNames.ROCKSTAR_ROOKIE,
    name: t[badgeNames.ROCKSTAR_ROOKIE],
    icon: '🤘',
    color: '#EAF1F3',
    steps: [BadgeStep.QUICK_SETUP_STEP],
    givesFreeTrial: false,
    quote: '' // t[`${badgeNames.ROCKSTAR_ROOKIE}_QUOTE`],
  },
  {
    key: badgeNames.SCHEDULE_ALCHEMIST,
    name: t[badgeNames.SCHEDULE_ALCHEMIST],
    icon: '✨',
    color: '#5873FF',
    steps: [BadgeStep.SCHEDULE_STEP],
    givesFreeTrial: false,
    quote: t[`${badgeNames.SCHEDULE_ALCHEMIST}_QUOTE`]
  },
  {
    key: badgeNames.TASK_NINJA,
    name: t[badgeNames.TASK_NINJA],
    icon: '⚔️',
    color: '#272E46',
    steps: [BadgeStep.CREATE_TASK_STEP, BadgeStep.TIME_NEEDED_STEP, BadgeStep.START_AHEAD_STEP],
    givesFreeTrial: true,
    quote: t[`${badgeNames.TASK_NINJA}_QUOTE`]
  },
  {
    key: badgeNames.PLANNING_GRADUATE,
    name: t[badgeNames.PLANNING_GRADUATE],
    icon: '🎓',
    color: '#90F1F8',
    steps: [BadgeStep.PLAN_TASK_STEP],
    givesFreeTrial: true,
    quote: t[`${badgeNames.PLANNING_GRADUATE}_QUOTE`]
  },
  {
    key: badgeNames.ACE_OF_INITIATIVE,
    name: t[badgeNames.ACE_OF_INITIATIVE],
    icon: '♠️',
    color: '#B6EBCB',
    steps: [BadgeStep.TRACK_PROGRESS_STEP],
    givesFreeTrial: true,
    quote: t[`${badgeNames.ACE_OF_INITIATIVE}_QUOTE`]
  },
  {
    key: badgeNames.PRECISION_UNICORN,
    name: t[badgeNames.PRECISION_UNICORN],
    icon: '🦄',
    color: '#F9D9FC',
    steps: [BadgeStep.AWAKE_TIME_STEP, BadgeStep.STUDY_TIME_STEP],
    givesFreeTrial: false,
    quote: t[`${badgeNames.PRECISION_UNICORN}_QUOTE`]
  },
  {
    key: badgeNames.GOD_OF_TIME,
    name: t[badgeNames.GOD_OF_TIME],
    icon: '⏳',
    color: '#FAF69D',
    steps: [BadgeStep.CUSHION_STEP],
    givesFreeTrial: false,
    quote: t[`${badgeNames.GOD_OF_TIME}_QUOTE`]
  }
];

export const allSteps = {
  QUICK_SETUP_STEP: {
    number: '0',
    title: t.QUICK_SETUP_STEP_TITLE,
    component: StepDialog,
    video: undefined,
    substeps: {
      [Substeps.QUICK_SETUP]: t.QUICK_SETUP_STEP_DESCRIPTION
    }
  },
  SCHEDULE_STEP: {
    number: '1',
    title: t.SCHEDULE_STEP_TITLE,
    component: StepDialog,
    video: { id: 'wC9v0dEz_Ew' },
    substeps: {
      [Substeps.SCHEDULE_CLASS]: t.SCHEDULE_STEP_CLASS,
      [Substeps.SCHEDULE_ACTIVITY]: t.SCHEDULE_STEP_ACTIVITY
    }
  },
  CREATE_TASK_STEP: {
    number: '2',
    title: t.CREATE_TASK_STEP_TITLE,
    component: StepDialog,
    video: { id: 'fohs5PXkBcw' },
    substeps: {
      [Substeps.CREATE_TASK]: t.CREATE_TASK_STEP_TASK
    }
  },
  PLAN_TASK_STEP: {
    number: '3',
    title: t.PLAN_TASK_STEP_TITLE,
    component: StepDialog,
    video: { id: 'kyY19Y6HtsE' },
    substeps: {
      [Substeps.PLAN_TASK]: t.PLAN_TASK_STEP_DESCRIPTION
    }
  },
  TRACK_PROGRESS_STEP: {
    number: '4',
    title: t.TRACK_PROGRESS_STEP_TITLE,
    component: StepDialog,
    video: { id: 'a63IMhAOyCs' },
    substeps: {
      [Substeps.ADD_PROGRESS]: t.TRACK_PROGRESS_STEP_PROGRESS,
      [Substeps.COMPLETE_TASK]: t.TRACK_PROGRESS_STEP_COMPLETE
    }
  },
  AWAKE_TIME_STEP: {
    number: '5',
    title: t.AWAKE_TIME_STEP_TITLE,
    component: StepDialog,
    video: { id: 'NerGR-VzaMg' },
    substeps: {
      [Substeps.SET_AWAKE_TIME]: t.AWAKE_TIME_STEP_DESCRIPTION
    }
  },
  STUDY_TIME_STEP: {
    number: '6',
    title: t.STUDY_TIME_STEP_TITLE,
    component: StepDialog,
    video: { id: '4qPJItIb_14' },
    substeps: {
      [Substeps.SET_STUDY_TIME]: t.STUDY_TIME_STEP_DESCRIPTION
    }
  },
  TIME_NEEDED_STEP: {
    number: '7',
    title: t.TIME_NEEDED_STEP_TITLE,
    component: TimeNeededStepDialog,
    video: { id: 'a3jsJ-ja8aE' },
    substeps: {
      [Substeps.TIME_NEEDED_QUIZ]: t.TIME_NEEDED_STEP_DESCRIPTION
    }
  },
  START_AHEAD_STEP: {
    number: '8',
    title: t.START_AHEAD_STEP_TITLE,
    component: StartDateStepDialog,
    video: { id: 'O-Xpr93bzuw' },
    substeps: {
      [Substeps.START_AHEAD_QUIZ]: t.START_AHEAD_STEP_DESCRIPTION
    }
  },
  CUSHION_STEP: {
    number: '9',
    title: t.CUSHION_STEP_TITLE,
    component: CushionStepDialog,
    video: { id: '0k9DLACT5p4' },
    substeps: {
      [Substeps.CUSHION_QUIZ]: t.CUSHION_STEP_QUIZ
    }
  }
};

export const stepComponents = {
  [BadgeStep.TIME_NEEDED_STEP]: TimeNeededStepDialog,
  [BadgeStep.START_AHEAD_STEP]: StartDateStepDialog,
  [BadgeStep.CUSHION_STEP]: CushionStepDialog
};

export const submitStepAnswer = (substep, step, setSubmitting, correct, checkSubstep) => {
  setSubmitting(true);
  setTimeout(() => {
    setSubmitting(false);
    if (correct) {
      checkSubstep({ step, substep });
    }
  }, 800);
};

export const Description = styled(RegularText)`
  // margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const QuizQuestion = styled(RegularText)`
  margin-bottom: 5px;
  color: ${props => props.theme.textStrongColor};
`;

export const FAQExamples = [
  {
    label: 'Lorem ipsum dolor sit amet?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus adipiscing pharetra ullamcorper sodales euismod Vestibulum, in in mollis erat see more'
  },
  {
    label: 'Lorem ipsum dolor sit ametp haretra ullamcorper?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus adipiscing pharetra ullamcorper sodales euismod Vestibulum, in in mollis erat see more'
  },
  {
    label: 'Lorem ipsum?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus adipiscing pharetra ullamcorper sodales euismod Vestibulum, in in mollis erat see more'
  }
];

export const CONFETTI_CONFIG: object = {
  angle: 90,
  spread: 50,
  startVelocity: 50,
  elementCount: 200,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: '9px',
  height: '9px',
  colors: [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#795548'
  ]
};

/**
 * rounds number to provided number of decimals
 */
export const roundNumber = (n, d) => Math.round(n * Math.pow(10, d)) / Math.pow(10, d);
