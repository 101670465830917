import { createReducer } from 'typesafe-actions';
import { TimerState } from './types';
import { TimerStatus } from 'shovel-lib/types';
import * as actions from './actions';
import { secondsPassedFrom } from '../../utils/timer';

const initialState: TimerState = {
  loading: false,
  error: '',
  status: TimerStatus.INITIAL,
  ticking: false,
  startedInUtc: null,
  timePassedInSeconds: 0,
  totalTimePassed: 0
};

const reducer = createReducer(initialState)
  .handleAction(actions.start.request, state => ({ ...state, loading: true, error: '' }))
  .handleAction(actions.start.success, (state, action) => {
    const { timerDto, taskId } = action.payload;
    return {
      ...state,
      loading: false,
      startedInUtc: timerDto.startedInUtc,
      status: timerDto.state,
      title: timerDto.title,
      plannedTaskId: timerDto.uid,
      masterTaskId: taskId,
      totalTimePassed: timerDto.timePassedInSeconds || 0,
      timePassedInSeconds: timerDto.timePassedInSeconds || 0
    };
  })
  .handleAction(actions.start.failure, (state, action) => ({ ...state, loading: false, error: action.payload }))
  .handleAction(actions.toggle.request, state => ({ ...state, loading: true, error: '' }))
  .handleAction(actions.toggle.success, (state, action) => ({
    ...state,
    loading: false,
    startedInUtc: action.payload.startedInUtc,
    status: action.payload.state,
    timePassedInSeconds: action.payload.timePassedInSeconds
  }))
  .handleAction(actions.stopCurrentTimer, (state, action) => {
    return {
      ...state,
      loading: false,
      startedInUtc: action.payload.startedInUtc,
      status: action.payload.state,
      timePassedInSeconds: action.payload.timePassedInSeconds,
      totalTimePassed: 0,
      type: action.payload.entryType,
      uid: action.payload.uid,
      plannedTaskId: undefined,
      masterTaskId: undefined
    };
  })
  .handleAction(actions.toggle.failure, (state, action) => ({ ...state, loading: false, error: action.payload }))
  .handleAction(actions.cancel.request, state => ({ ...state, loading: true, error: '' }))
  .handleAction(actions.cancel.success, (state, action) => ({
    ...state,
    loading: false,
    startedInUtc: action.payload.response.startedInUtc,
    status: action.payload.response.state,
    timePassedInSeconds: action.payload.response.timePassedInSeconds,
    totalTimePassed: 0
  }))
  .handleAction(actions.cancel.failure, (state, action) => ({ ...state, loading: false, error: action.payload }))
  .handleAction(actions.startTicking, state => ({ ...state, ticking: true }))
  .handleAction(actions.stopTicking, state => ({ ...state, ticking: false }))
  .handleAction(actions.tick, state => {
    return {
      ...state,
      totalTimePassed: state.timePassedInSeconds + secondsPassedFrom(new Date(state.startedInUtc!))
    };
  })
  .handleAction(actions.getCurrentTimer.request, state => ({ ...state, loading: true, error: '' }))
  .handleAction(actions.getCurrentTimer.success, (state, action) => {
    const { timePassedInSeconds, startedInUtc, title } = action.payload;
    const totalTimePassed = timePassedInSeconds + (startedInUtc ? secondsPassedFrom(new Date(startedInUtc)) : 0);
    return {
      ...state,
      totalTimePassed,
      title,
      loading: false,
      startedInUtc: startedInUtc,
      timePassedInSeconds: timePassedInSeconds,
      status: action.payload.state,
      masterTaskId: Number(action.payload.metadata) || undefined,
      plannedTaskId: action.payload.uid
    };
  })
  .handleAction(actions.getCurrentTimer.failure, state => ({ ...state, loading: false }))
  .handleAction(actions.updateTimerForTask.success, (state, action) => {
    const { timePassedInSeconds, startedInUtc } = action.payload;
    const totalTimePassed = timePassedInSeconds + (startedInUtc ? secondsPassedFrom(new Date(startedInUtc)) : 0);
    return {
      ...state,
      startedInUtc,
      timePassedInSeconds,
      totalTimePassed,
      loading: false
    };
  })
  .handleAction(actions.clearSession, () => initialState);

export default reducer;
