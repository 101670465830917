import React, { PureComponent } from 'react';
import { MediumText, MiniText } from '../../../utils/typography';
import styled from 'styled-components';
import { Column, FlexColumn, Row } from '../../common/layoutUtils';
import TippyTooltip from '../../common/tooltip/TippyTooltip';
import { TaskDto } from 'shovel-lib/types';
import { taskApi } from 'shovel-lib';
import CalendarTask from '../CalendarTask';
import { RootState } from '../../../state/rootReducer';
import { getDraggingTask } from '../../../state/calendar/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import { startDraggingTask, stopDraggingTask } from '../../../state/calendar/actions';
import { connect } from 'react-redux';
import { DraggingTask } from '../../../state/calendar/types';
import t from '../../../i18n/t';
import { EmojiPreview } from '@components/common/emoji/EmojiPreview';
import ScrollGradient from '@components/common/ScrollGradient';
import DialogCloseButton from '@components/common/dialog/DialogCloseButton';

type Props = {
  isOpen: boolean;
  close: any;
  tasksIds: number[];
  semesterId: number;
  onTaskClick: any;
  draggingTask: DraggingTask;
  startDraggingTask: typeof startDraggingTask;
  stopDraggingTask: typeof stopDraggingTask;
};

type State = {
  tasks: TaskDto[];
  loading: boolean;
};

class GroupedTasksEventsPopup extends PureComponent<Props, State> {
  state: State = {
    tasks: [],
    loading: false
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.getTasks();
    }
  }

  getTasks = () => {
    const { tasksIds, semesterId } = this.props;
    this.setState({ ...this.state, loading: true });
    taskApi.getTasksByIds(semesterId, tasksIds).then(response => {
      if (response.ok) {
        this.setState({
          tasks: response.data!,
          loading: false
        });
      } else {
        this.setState({
          tasks: [],
          loading: false
        });
      }
    });
  };

  render() {
    const { isOpen, tasksIds, close, onTaskClick, startDraggingTask, stopDraggingTask, draggingTask } = this.props;
    const noOfTasks = tasksIds ? tasksIds.length : 1;

    return (
      <TippyTooltip
        target={
          <Container>
            <Line />
            <FlagWrapper>
              <MediumText className={'task-due-flag'}>
                {`${noOfTasks} `}
                <EmojiPreview emoji={'🏁'} />
              </MediumText>
            </FlagWrapper>
          </Container>
        }
        content={
          <TasksContainer flex>
            <MiniText style={{ textAlign: 'center' }}>{t.DRAG_TO_PLAN}</MiniText>
            <DialogCloseButton onClick={close} />
            {this.state.tasks &&
              this.state.tasks.map(task => (
                <CalendarTask
                  key={task.taskId}
                  task={{ ...task, due: task.dueDate }}
                  openManageTask={onTaskClick}
                  closePopup={close}
                  startDraggingTask={startDraggingTask}
                  stopDraggingTask={stopDraggingTask}
                  draggingTask={draggingTask}
                />
              ))}
          </TasksContainer>
        }
        lightTheme
        arrow={false}
        position={'auto'}
        controlled
        visible={isOpen}
        onClickOutside={(instance, e) => {
          e.stopPropagation();
          close();
        }}
        noPadding
        interactive
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  draggingTask: getDraggingTask(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      startDraggingTask: startDraggingTask,
      stopDraggingTask: stopDraggingTask
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GroupedTasksEventsPopup);

const TasksContainer = styled(ScrollGradient)`
  padding: 15px 20px;
  max-height: 400px;
`;

const Container = styled(Row)`
  align-items: flex-start;
  &:hover > * {
    background: ${props => props.theme.borderColor}BF;
    border-color: ${props => props.theme.textStrongColor};
  }
`;

const Line = styled(FlexColumn)`
  border-top: 1px solid ${props => props.theme.borderColor};
  box-shadow: 0 1px 2px ${props => props.theme.boxShadow};
`;

const FlagWrapper = styled(Row)`
  flex-shrink: 0;
  box-shadow: 0 2px 2px ${props => props.theme.boxShadow}, 0 0 2px ${props => props.theme.boxShadow} !important;
  background: ${props => props.theme.borderColor};
  border-radius: 3px;
  bottom: unset;
  margin-top: -7px;
  border: 1px solid ${props => props.theme.borderColor};
  padding: 2px 0 0 4px;
  .task-due-flag {
    display: flex;
    line-height: 1;
    color: ${props => props.theme.textStrongColor};
    .emoji-mart-emoji {
      margin-left: 2px;
    }
  }
`;
