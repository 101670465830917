import { all, put, takeLatest } from '@redux-saga/core/effects';
import { getType } from 'typesafe-actions';
import { apiCall } from '../common/operations';
import * as actions from './actions';
import { gpaCalculatorApi } from '../../api';

function* readSemesterGpas() {
  const { ok, ...response } = yield apiCall(gpaCalculatorApi.readSemesterGpas);

  if (ok) {
    yield put(actions.readSemesterGpas.success(response.data));
  } else {
    yield put(actions.readSemesterGpas.failure(response));
  }
}

function* createSemesterGpa() {
  const { ok, ...response } = yield apiCall(gpaCalculatorApi.createSemesterGpa);

  if (ok) {
    yield put(actions.createSemesterGpa.success(response.data));
  } else {
    yield put(actions.createSemesterGpa.failure(response));
  }
}

function* updateSemesterGpa(action: ReturnType<typeof actions.updateSemesterGpa.request>) {
  const { ok, ...response } = yield apiCall(gpaCalculatorApi.updateSemesterGpa, action.payload);

  if (ok) {
    yield put(
      actions.updateSemesterGpa.success({
        ...response.data,
        id: action.payload.id,
        name: action.payload.name
      })
    );
  } else {
    yield put(actions.updateSemesterGpa.failure(response));
  }
}

function* deleteSemesterGpa(action: ReturnType<typeof actions.deleteSemesterGpa.request>) {
  const { ok, ...response } = yield apiCall(gpaCalculatorApi.deleteSemesterGpa, action.payload);

  if (ok) {
    yield put(actions.deleteSemesterGpa.success({ ...response.data, id: action.payload.id }));
  } else {
    yield put(actions.deleteSemesterGpa.failure(response));
  }
}

function* createCourseGpa(action: ReturnType<typeof actions.createCourseGpa.request>) {
  const { ok, ...response } = yield apiCall(gpaCalculatorApi.createCourseGpa, action.payload);

  if (ok) {
    yield put(actions.createCourseGpa.success({ ...action.payload, ...response.data }));
  } else {
    yield put(actions.createCourseGpa.failure(response));
  }
}

function* updateCourseGpa(action: ReturnType<typeof actions.updateCourseGpa.request>) {
  const { ok, ...response } = yield apiCall(gpaCalculatorApi.updateCourseGpa, action.payload);

  if (ok) {
    yield put(actions.updateCourseGpa.success({ ...action.payload, ...response.data }));
  } else {
    yield put(actions.updateCourseGpa.failure(response));
  }
}

function* deleteCourseGpa(action: ReturnType<typeof actions.deleteCourseGpa.request>) {
  const { ok, ...response } = yield apiCall(gpaCalculatorApi.deleteCourseGpa, { id: action.payload.id });

  if (ok) {
    yield put(actions.deleteCourseGpa.success({ ...response.data, ...action.payload }));
  } else {
    yield put(actions.deleteCourseGpa.failure(response));
  }
}

function* toggleGpaScale() {
  const { ok, ...response } = yield apiCall(gpaCalculatorApi.toggleGpaScale);

  if (ok) {
    yield put(actions.toggleGpaScale.success(response.data));
  } else {
    yield put(actions.toggleGpaScale.failure(response));
  }
}

export default function*() {
  yield all([
    takeLatest(getType(actions.readSemesterGpas.request), readSemesterGpas),
    takeLatest(getType(actions.createSemesterGpa.request), createSemesterGpa),
    takeLatest(getType(actions.updateSemesterGpa.request), updateSemesterGpa),
    takeLatest(getType(actions.deleteSemesterGpa.request), deleteSemesterGpa),
    takeLatest(getType(actions.createCourseGpa.request), createCourseGpa),
    takeLatest(getType(actions.updateCourseGpa.request), updateCourseGpa),
    takeLatest(getType(actions.deleteCourseGpa.request), deleteCourseGpa),
    takeLatest(getType(actions.toggleGpaScale.request), toggleGpaScale)
  ]);
}
