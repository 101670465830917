import React, { FC, useState } from 'react';
import styled from 'styled-components';
import t from '@i18n/t';
import { Column, Row } from '../../common/layoutUtils';
import RadioButton from '../../common/radioButton/RadioButton';
import { ReactComponent as CustomThemeIcon } from '@assets/icons/theme.svg';
import storage from '@utils/storage';
import MaterialIcon from '../../common/icons/MaterialIcon';
import colors from '@utils/colors';
import { appThemes, setAppTheme, setCustomAppTheme } from '@utils/theme/appTheme';
import ColorPickerTippy from '../../common/colorPicker/ColorPickerTippy';
import { hideAllTippies } from '@utils/calendar/calendarUtils';
import { darkenColor, increaseColorBrightness } from '@utils/colorUtils';

const createCustomTheme = (value, setTheme, setCustomColor, setCustomAppTheme) => {
  setCustomColor(value);
  const theme = {
    primary: value,
    primaryLight: increaseColorBrightness(value),
    primaryDark: darkenColor(value, -15),
    name: t.CUSTOM
  };
  setTheme(theme);
  setCustomAppTheme?.(theme);
};

const Preference: FC = () => {
  const currentTheme = storage.getAppTheme();
  const [theme, setTheme] = useState(currentTheme);
  // set predefined custom color only if theme name is custom
  const [customColor, setCustomColor] = useState(currentTheme.name === t.CUSTOM ? currentTheme.primary : '');

  return (
    <ThemesWrapper>
      {appThemes.map((item, index) => {
        return (
          <Theme key={index}>
            <ThemeIcon className={'theme-icon'} color={item.primary} />
            <RadioButton
              checked={item.name === theme.name}
              onChange={() => setAppTheme(item.name)}
              className={'radio-button'}
              color={colors.lightgray}
            />
          </Theme>
        );
      })}
      <Theme>
        <ThemeIcon color={customColor} className={'theme-icon'} />
        <ColorPickerTippy
          target={color => (
            <CustomColorWrapper color={customColor}>
              <MaterialIcon name={'format_color_fill'} color={colors.darkGray} />
            </CustomColorWrapper>
          )}
          onSave={value => {
            createCustomTheme(value, setTheme, setCustomColor, setCustomAppTheme);
            hideAllTippies();
          }}
          onChange={value => {
            createCustomTheme(value!, setTheme, setCustomColor, undefined);
          }}
          selectedColor={customColor}
          themeColorPicker
        />
      </Theme>
    </ThemesWrapper>
  );
};

export default Preference;

const ThemesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Theme = styled(Column)`
  margin: 0px 10px 0px 0px;
  box-sizing: border-box;
  flex: 1;
  max-width: 80px;
  position: relative;
  > svg {
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
  }
  .radio-button {
    position: absolute;
    top: 0px;
    left: 56px;
    > i {
      font-size: 24px;
    }
  }
`;

const ThemeIcon = styled(CustomThemeIcon)<{ color?: string }>`
  circle {
    fill: ${props => props.theme.themeIconCircle};
  }
  *[class='body'] {
    fill: ${props => `${props.color}` || '#C8D1D9'} !important;
  }
  > *[class='check-box'],
  *[class='border'] {
    stroke: ${props => props.color || '#C8D1D9'} !important;
  }
  > *[class='row'],
  *[class='header'] {
    fill: ${props => props.color || '#C8D1D9'} !important;
  }
`;

const CustomColorWrapper = styled(Row)<{ color?: string }>`
  background: ${props => props.color || '#C8D1D9'};
  position: absolute;
  border-radius: 50%;
  top: 2px;
  left: 58px;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
  i {
    font-size: 12px;
  }
`;
