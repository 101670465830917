import React, { FC } from 'react';
import { formatStringDateAs } from 'shovel-lib/utils/timeUtils';

import colors from '../../../utils/colors';
import ValueWithLabel from '../../common/ValueWithLabel';
import { Row } from '../../common/layoutUtils';
import MaterialIcon from '../../common/icons/MaterialIcon';

const CompletedOnTag: FC<{ completedOn: string }> = ({ completedOn }) => (
  <Row>
    <MaterialIcon style={{ marginRight: 2 }} name={'check'} color={colors.positive} size={14} />
    <ValueWithLabel
      font={10}
      labelStyle={{ color: colors.positive }}
      valueStyle={{ color: colors.positive }}
      value={completedOn ? formatStringDateAs(completedOn, 'MMM DD') : 'N/A'}
    />
  </Row>
);

export default CompletedOnTag;
