import React from 'react';
import styled from 'styled-components';
import { MINUTES_IN_HOUR } from 'shovel-lib/utils/timeUtils';
import { isPositiveInteger } from '@utils/validators';
import {
  AutosizeFormInput,
  BareWrapper,
  INLINE_INPUT_VERTICAL_PADDING,
  INPUT_HORIZONTAL_PADDING,
  INPUT_VERTICAL_PADDING
} from '../Input.styles';
import { AlignType } from '@state/common/utils';
import { Row } from '../../layoutUtils';
import InputWrapper from '../InputWrapper';
import InlineInputWrapper from '../InlineInputWrapper';
import { RegularText } from '@utils/typography';
import Tooltip from '../../tooltip/Tooltip';
import { TooltipPosition } from '@utils/popupUtils';

const getMinutes = (duration?: number) => (duration === 0 ? 0 : Math.floor(duration! % MINUTES_IN_HOUR) || '');

const getHours = (duration?: number) => Math.floor(duration! / MINUTES_IN_HOUR) || '';

export const validate = (hours: string, minutes: string, oldValue?: number): number | undefined => {
  if (!isPositiveInteger(hours) || !isPositiveInteger(minutes) || parseInt(minutes!) >= MINUTES_IN_HOUR) return oldValue;

  if (isNaN(parseInt(minutes)) && isNaN(parseInt(hours))) return undefined;

  return parseInt(hours || '0') * MINUTES_IN_HOUR + parseInt(minutes || '0');
};

type Props = {
  value?: number;
  name?: string;
  onChange?: (durationInMinutes?: number) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  error?: string;
  touched?: boolean;
  onBlur?: (durationInMinutes?: number) => void;
  className?: string;
  label?: string;
  align?: AlignType;
  inline?: boolean;
  autosize?: boolean;
  autofocus?: boolean;
  bare?: boolean;
  inSeconds?: boolean;
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
  required?: boolean;
  disabled?: boolean;
  big?: boolean;
  noErrorMargin?: boolean;
  noPadding?: boolean;
};

type State = { value?: number };

class DurationInput extends React.PureComponent<Props, State> {
  readonly state = { value: this.props.value };
  hoursInput: null | HTMLInputElement = null;

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      this.setState({ value: this.props.value });
    }
  }

  handleHoursBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.props.onBlur &&
      //@ts-ignore
      e.relatedTarget?.name !== 'duration-input-minutes' &&
      this.props.onBlur(validate(e.target.value, getMinutes(this.state.value).toString(), this.state.value));
  };

  handleMinutesBlur = (e: React.FocusEvent<HTMLInputElement>) =>
    this.props.onBlur &&
    //@ts-ignore
    e.relatedTarget?.name !== 'duration-input-hours' &&
    this.props.onBlur(validate(getHours(this.state.value).toString(), e.target.value, this.state.value));

  handleHoursChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    const { value: currentValue } = this.state;
    const value = validate(e.target.value, getMinutes(currentValue).toString(), currentValue);
    this.setState({ value });
    if (onChange) {
      onChange(value);
    }
  };

  handleMinutesChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    const { value: currentValue } = this.state;
    const value = validate(getHours(currentValue).toString(), e.target.value, currentValue);
    this.setState({ value });
    if (onChange) {
      onChange(value);
    }
  };

  render() {
    const {
      error,
      label,
      touched,
      className,
      inline,
      autosize,
      noErrorMargin,
      align,
      onKeyUp,
      bare = false,
      inSeconds = false,
      required = true,
      tooltip,
      autofocus,
      tooltipPosition,
      disabled,
      big,
      noPadding
    } = this.props;
    const { value } = this.state;

    const Container: any = bare ? StyledBareWrapper : inline ? StyledInlineWrapper : InputWrapper;
    const hours = getHours(value);
    const minutes = getMinutes(value);
    const smallerPadding = big ? 14 : 11;
    const biggerPadding = big ? 16 : 14;
    const children = (
      <Container
        tooltip={tooltip}
        tooltipPosition={tooltipPosition}
        label={label}
        error={error}
        align={align}
        noErrorMargin={noErrorMargin}
        className={className}
        autosize={autosize}
        touched={touched}
        required={required}
        disabled={disabled}
      >
        <Wrapper
          inline={inline}
          align={align}
          big={big}
          bare={bare}
          hasLabel={!!label}
          onClick={() => this.hoursInput && this.hoursInput.focus()}
          noPadding={noPadding}
        >
          <InputWithLabel>
            <Input
              name={'duration-input-hours'}
              padding={inSeconds ? biggerPadding : smallerPadding}
              onChange={this.handleHoursChange}
              onBlur={this.handleHoursBlur}
              placeholder={'00'}
              value={hours}
              maxLength={3}
              onKeyUp={onKeyUp}
              data-cy={'hours'}
              autoFocus={autofocus}
              inputRef={element => (this.hoursInput = element)}
              disabled={disabled}
              big={big ? 'true' : ''}
            />
            <Label disabled={disabled}>{inSeconds ? 'm' : 'h'}</Label>
          </InputWithLabel>
          <InputWithLabel>
            <Input
              name={'duration-input-minutes'}
              padding={inSeconds ? smallerPadding : biggerPadding}
              onClick={(e: any) => e.stopPropagation()}
              onChange={this.handleMinutesChange}
              onBlur={this.handleMinutesBlur}
              placeholder={'00'}
              value={minutes}
              maxLength={2}
              onKeyUp={onKeyUp}
              data-cy={'minutes'}
              disabled={disabled}
              big={big ? 'true' : ''}
            />
            <Label disabled={disabled}>{inSeconds ? 's' : 'm'}</Label>
          </InputWithLabel>
        </Wrapper>
      </Container>
    );
    if (tooltip && (bare || inline)) {
      return (
        <Tooltip
          target={inline ? <StyledBareWrapper>{children}</StyledBareWrapper> : children}
          content={tooltip}
          position={'top center'}
        />
      );
    }
    return children;
  }
}

const Input = styled(AutosizeFormInput)<{ padding: number; big?: any }>`
  z-index: 1;
  input {
    ${props => (props.big ? 'font-weight: bold; font-size: 16px;' : '')};
    border: none;
    max-width: 50px;
    text-align: right;
    color: ${({ theme }) => theme.textStrongColor}};
    outline: none;
    padding-right: ${props => props.padding}px;
    background-color: transparent;
  }
`;

const Label = styled.div<{ disabled?: boolean }>`
  position: absolute;
  right: 0;
  width: 1em;
  text-align: center;
  color: ${props => (props.disabled ? props.theme.captures : props.theme.textColor)};
`;

const Wrapper = styled(Row)<{
  bare?: boolean;
  inline?: boolean;
  align?: AlignType;
  big?: boolean;
  hasLabel?: boolean;
  noPadding?: boolean;
}>`
  width: 100%;
  ${props => !props.bare && `padding: 0 ${props.inline ? INLINE_INPUT_VERTICAL_PADDING : INPUT_HORIZONTAL_PADDING}px`};
  ${props => !props.bare && !props.inline && props.hasLabel && `padding-top: 14px`};
  ${Input} {
    input {
      font-weight: ${props => (props.inline ? 'bold' : 'inherit')};
      padding: ${props => (props.inline ? INLINE_INPUT_VERTICAL_PADDING : INPUT_VERTICAL_PADDING)}px 0 !important;
      ${props => props.noPadding && 'padding: 0 !important'}
    }
  }
  ${Label} {
    font-weight: ${props => (props.big ? 'bold' : props.inline ? 'normal' : 'inherit')};
    line-height: initial;
  }
  ${props => props.align === 'center' && 'justify-content: center;'}
`;

const InputWithLabel = styled(RegularText)`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
`;

const StyledBareWrapper = styled(BareWrapper)`
  width: max-content;
`;

const StyledInlineWrapper = styled(InlineInputWrapper)`
  width: max-content;
`;

export default DurationInput;
