import styled from 'styled-components';
import { DeleteButton } from '../../common/buttons';
import { Row, Column, FlexColumn } from '../../common/layoutUtils';
import { screenSizes } from '../../../utils/screenUtils';
import DialogCloseButton from '../../common/dialog/DialogCloseButton';
import { TextareaInput } from '../../common/inputs';
import ScrollGradient from '../../common/ScrollGradient';
import { DropdownInputsWrapper } from '../createTask/CreateTaskDefault';
import Skeleton from '../../common/Skeleton';
import Popup from 'reactjs-popup';
import AddTaskButtons from '@components/pile/components/AddTaskButtons';

export const InputWrapper = styled(FlexColumn)`
  position: relative;
`;

export const InputPairContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  & > ${InputWrapper} {
    &:not(:last-child) {
      margin-right: 10px;
    }
    > div {
      margin: 0;
    }
  }
`;

export const HoverDelete = styled(DeleteButton)`
  opacity: 0;
  transition: opacity 0.2s ease;
  display: block;
  background: transparent;
  &:hover {
    opacity: 1;
  }
`;

export const Dialog = styled(Popup)`
  &-content {
    background: ${({ theme }) => theme.background} !important;
    overflow: hidden;
  }
  &-overlay {
    background: ${({ theme }) => theme.modalOverlayColor} !important;
  }
`;

export const Container = styled(Column)`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  .gradient-children-wrapper {
    height: 100%;
  }
  @media (max-width: ${screenSizes.medium}) {
    padding: 60px 0px;
    .gradient-children-wrapper {
      height: auto;
    }
  }
  > div:first-child {
    width: 100%;
    @media (min-width: ${screenSizes.medium}) {
      overflow: visible;
      height: 100%;
    }
  }
`;

export const CloseButton = styled(DialogCloseButton)<{ top?: number }>`
  width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.backgroundDark};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  box-sizing: border-box;
  top: ${props => (props.top !== undefined ? props.top : 10)}px;
  right: 10px;
`;

export const Content = styled(Row)`
  width: 100%;
  height: 100%;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: ${screenSizes.medium}) {
    display: block;
  }
`;

export const ContentScroll = styled(ScrollGradient)`
  height: 100%;
  @media (min-width: calc(${screenSizes.medium} + 1px)) {
    overflow: visible;
  }
`;

export const ContentWrapper = styled(ScrollGradient)<{ taskTable?: boolean }>`
  padding: 15px 20px;
  ${props => props.taskTable && 'padding-left: 0px'};
  height: 100%;
  ${DropdownInputsWrapper} > * {
    &:last-child {
      margin-right: 0;
    }
    max-width: calc(30% - 15px);
  }

  .name-input * {
    font-size: 21px;
    font-weight: 700;
  }
  .gradient-children-wrapper {
    height: auto !important;
    min-height: 100%;
  }
  ${props =>
    props.taskTable &&
    `
  > .gradient-children-wrapper {
    display: flex;
  }
  `};

  @media (max-width: ${screenSizes.medium}) {
    overflow: visible;
    padding-left: 20px;
  }
`;

export const ContentColumn = styled(FlexColumn)`
  height: 100%;
  width: 100%;
  padding-bottom: 60px;
  box-sizing: border-box;
  position: relative;
  &:first-child {
    border-right: 1px solid ${props => props.theme.borderColor};
    ${ContentWrapper} {
      margin-left: 20px;
    }
  }
  @media (max-width: ${screenSizes.medium}) {
    height: auto;
    min-height: auto;
    position: unset;
    padding-bottom: 0px;
  }
`;

export const PlannedBar = styled(Row)`
  //padding: 0 20px;
  height: 60px;
  flex-shrink: 0;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  position: relative;
  + div {
    flex: 1;
  }
  .task-progress-bar {
    position: absolute;
    bottom: -6px;
  }
`;

export const PagesWrapper = styled.div`
  padding: 5px 10px 10px;
  background: ${props => props.theme.backgroundDark};
  margin-bottom: 20px;
  border-radius: 5px;
  input {
    box-shadow: none !important;
  }
`;

export const DescriptionInput = styled(TextareaInput)`
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
`;

export const SkeletonSecondSection = styled(Skeleton)`
  margin: 20px 0px 10px 0;
`;

export const SkeletonLastSection = styled(Skeleton)`
  margin: 10px 0px 5px 0px;
`;

export const DialogActions = styled.div`
  position: absolute;
  bottom: 20px;
  left: calc(-100% + 20px);
  right: 20px;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${screenSizes.medium}) {
    left: 20px;
  }
`;

export const GoogleTaskInfo = styled(Column)`
  padding: 20px 10px;
  border-top: 1px solid ${props => props.theme.borderColor};
  border-bottom: 1px solid ${props => props.theme.borderColor};
  a {
    word-break: break-word;
  }
`;

export const GoogleTaskDescription = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
`;

export const TaskButtons = styled(AddTaskButtons)`
  margin: 5px 0;
`;

export const TaskPriorityAndButtons = styled(Row)`
  margin: -5px 0 5px;
`;
