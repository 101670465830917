import React, { FC } from 'react';
import styled from 'styled-components';
import { Column, FlexColumn, FlexRow } from '../../common/layoutUtils';
import Membership from './Membership';
import Tooltip from '../../common/tooltip/Tooltip';
import t from '../../../i18n/t';
import { ReactComponent as ShovelLogo } from '../../../assets/images/shovel-logo.svg';
import TextLink from '../../common/TextLink';
import { deletingReasons } from '../../../utils/settingsUtils';
import { bindActionCreators, Dispatch } from 'redux';
import { deleteAccount, openHelpUsDialog } from '../../../state/settings/actions';
import { openConfirmationDialog } from '../../../state/common/actions';
import { connect } from 'react-redux';
import HelpUsDialog from '../HelpUsDialog';
import ConfirmationDialog from '../../common/dialog/ConfirmationDialog';
import LearningCenter from '../../common/learningCenter/LearningCenter';
import { screenSizes } from '../../../utils/screenUtils';
import LogoutIcon from '@components/navigation/partials/LogoutIcon';
import DarkMode from '@components/navigation/partials/DarkMode';

type PropsFromState = {};

type PropsFromDispatch = {
  deleteAccount: typeof deleteAccount.request;
  openConfirmationDialog: typeof openConfirmationDialog;
  openHelpUsDialog: typeof openHelpUsDialog;
};

type Props = PropsFromDispatch &
  PropsFromState & {
    darkMode: boolean;
    toggleMode: (darkMode: boolean) => void;
  };

const confirmDeactivateAccount = (props: Props) => {
  const { openConfirmationDialog, deleteAccount, openHelpUsDialog } = props;

  openConfirmationDialog({
    action: () =>
      openHelpUsDialog({
        confirmAction: deleteAccount,
        options: deletingReasons,
        message: t.DELETE_ACCOUNT_MSG
      }),
    title: t.DELETE_ACCOUNT,
    message: `${t.ABOUT_TO_DELETE_ACCOUNT} ${t.SUBSCRIPTION_WILL_BE_CANCELLED}`,
    understandCheck: true,
    warning: true
  });
};

const ExpiredMembershipPage: FC<Props> = props => {
  const { darkMode, toggleMode } = props;

  return (
    <Container>
      <NavBar>
        <LogoWrapper>
          <Tooltip
            target={<ShovelLogo width={34} height={34} style={{ cursor: 'pointer' }} />}
            content={t.LOGO_TOOLTIP_TEXT}
            position={'right center'}
            width={'max-content'}
          />
        </LogoWrapper>
        <IconsWrapper>
          <DarkMode isDarkMode={darkMode} toggleMode={toggleMode} />
          <LearningCenter />
          <LogoutIcon />
        </IconsWrapper>
      </NavBar>
      <Content>
        <Membership />
        <DeleteLink onClick={() => confirmDeactivateAccount(props)}>{t.DELETE_ACCOUNT_LABEL}</DeleteLink>
      </Content>
      <HelpUsDialog />
      <ConfirmationDialog />
    </Container>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteAccount: deleteAccount.request,
      openConfirmationDialog: openConfirmationDialog,
      openHelpUsDialog: openHelpUsDialog
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ExpiredMembershipPage);

const Container = styled(FlexRow)`
  align-items: stretch;
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  height: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.background};
  transition: background-color 0.2s ease;
  justify-content: space-between;
  padding: 10px 0 20px 0;
  margin-right: 2px;
`;

const LogoWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.background};
  transition: background-color 0.2s ease;
`;

const DeleteLink = styled(TextLink)`
  margin: 20px;
  @media (max-width: ${screenSizes.xsmall}) {
    margin: 10px auto;
  }
`;

export const IconsWrapper = styled(Column)`
  align-items: center;
`;
