import { actionCreator, asyncActionsCreator } from '../common/utils';
import {
  CourseExtendedDto,
  CreateGradeUnitRequest,
  CreateOrUpdateTaskCategoryRequest,
  CreateReadingSourceRequest,
  GradeUnitInfoDto,
  GradeUnitType,
  IdWrapper,
  ReadingSourceInfoDto,
  ReadingSourceType,
  ReorderCategoriesInsideCourseType,
  TaskCategoryDto,
  UpdateGradeUnitRequest,
  UpdateReadingSourceRequest
} from 'shovel-lib/types';
import { ExportCourseDto, ExportCourseResponse } from '../../api/lambdaApi';

const asyncActions = asyncActionsCreator('@@course');
const action = actionCreator('@@course');

export const getCourse = asyncActions<number, CourseExtendedDto, string>('GET_COURSE');

export const createGradeUnit = asyncActions<CreateGradeUnitRequest, GradeUnitInfoDto, string>('CREATE_GRADE_UNIT');

export const updateGradeUnit = asyncActions<IdWrapper & UpdateGradeUnitRequest, GradeUnitInfoDto, string>(
  'UPDATE_GRADE_UNIT'
);

export const deleteGradeUnit = asyncActions<number | undefined, number | undefined, string>('DELETE_GRADE_UNIT');

export const createReadingSource = asyncActions<
  CreateReadingSourceRequest & { courseId?: number; onChange?: any },
  ReadingSourceType,
  string
>('CREATE_READING_SOURCE');

export const updateReadingSource = asyncActions<
  IdWrapper & UpdateReadingSourceRequest & { courseId?: number },
  ReadingSourceInfoDto,
  string
>('UPDATE_READING_SOURCE');

export const deleteReadingSource = asyncActions<
  { id: number; courseId?: number },
  { id?: number; courseId?: number },
  string
>('DELETE_READING_SOURCE');

export const createTaskCategory = asyncActions<
  CreateOrUpdateTaskCategoryRequest & { courseId?: number; onChange?: any; isTaskList?: boolean },
  TaskCategoryDto,
  string
>('CREATE_TASK_CATEGORY');

export const updateTaskCategory = asyncActions<
  IdWrapper & CreateOrUpdateTaskCategoryRequest & { courseId?: number },
  TaskCategoryDto,
  string
>('UPDATE_TASK_CATEGORY');

export const deleteTaskCategory = asyncActions<
  { id: number; courseId?: number },
  { id?: number; courseId?: number },
  string
>('DELETE_TASK_CATEGORY');

export const addGradeUnit = action<GradeUnitType>('ADD_GRADE_UNIT');

export const addReadingSource = action<ReadingSourceType>('ADD_READING_SOURCE');

export const addUngradedTaskCategory = action<undefined>('ADD_UNGRADED_TASK_CATEGORY');

export const toggleGradeWeightType = asyncActions<undefined, undefined, string>('TOGGLE_GRADE_WEIGHT_TYPE');

export const exportCourse = asyncActions<{ id: number; correlationId: string; courseName: string }, ExportCourseDto, string>(
  'EXPORT_COURSE'
);

export const importCourse = asyncActions<
  {
    courseId: number;
    courseCorrelationId: string;
    courseName: string;
    courseColorHex: string;
    includeSchedule: boolean;
    includeTeachers: boolean;
    course: ExportCourseResponse;
  },
  undefined,
  string
>('IMPORT_COURSE');

export const openImportCourseDialog = action<{
  courseId: number;
  courseColorHex: string;
  courseCorrelationId: string;
}>('OPEN_IMPORT_COURSE_DIALOG');

export const closeImportCourseDialog = action<undefined>('CLOSE_IMPORT_COURSE_DIALOG');

export const reorderCategoriesInsideCourse = asyncActions<ReorderCategoriesInsideCourseType, any, string>(
  'REORDER_CATEGORIES_INSIDE_COURSE'
);

export const openCourseDetailsDialog = action<number>('OPEN_COURSE_DETAILS_DIALOG');

export const closeCourseDetailsDialog = action<undefined>('CLOSE_COURSE_DETAILS_DIALOG');

export const getCategoriesByCourse = asyncActions<number, TaskCategoryDto[], string>('GET_CATEGORIES_BY_COURSE');

export const getReadingSourcesByCourse = asyncActions<number, ReadingSourceInfoDto[], string>(
  'GET_READING_SOURCES_BY_COURSE'
);

export const updateCalendarId = asyncActions<
  { id: number; googleCalendarId?: string; unlinkCalendarId?: string },
  { id: number; googleCalendarId?: string; unlinkCalendarId?: string },
  string
>('UPDATE_CALENDAR_ID');
