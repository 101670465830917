import { toMomentDate } from 'shovel-lib/utils/timeUtils';

const toToDoTask = (task: any): any => {
  const {
    taskId,
    title,
    subtitle,
    type,
    courseId,
    courseName,
    colorHex,
    timeNeed,
    timeHave,
    timeSpent,
    cushion,
    timePlanned,
    daysToStartAhead,
    dueDate,
    duration,
    categoryId,
    categoryName,
    emoji,
    priority,
    readingSourceName,
    readingSourceId
  } = task;
  return {
    taskId,
    title,
    subtitle,
    type,
    courseId,
    courseName,
    colorHex,
    priority,
    timeNeed,
    timeHave,
    timeSpent,
    cushion,
    timePlanned,
    daysToStartAhead,
    duration,
    categoryId,
    categoryName,
    emoji,
    readingSourceName,
    readingSourceId,
    due: toMomentDate(dueDate)
  };
};

const toDoLaterTask = (task: any): any => {
  const {
    taskId,
    title,
    subtitle,
    dueDate,
    type,
    courseId,
    courseName,
    colorHex,
    timeNeed,
    timeSpent,
    timePlanned,
    daysToStartAhead,
    duration,
    priority,
    categoryId,
    categoryName,
    emoji,
    readingSourceId,
    readingSourceName
  } = task;
  return {
    taskId,
    title,
    subtitle,
    type,
    priority,
    courseId,
    courseName,
    colorHex,
    timeNeed,
    timeSpent,
    timePlanned,
    daysToStartAhead,
    duration,
    categoryId,
    categoryName,
    emoji,
    readingSourceId,
    readingSourceName,
    due: toMomentDate(dueDate)
  };
};

const toCompletedTask = (task: any): any => {
  const {
    taskId,
    title,
    subtitle,
    type,
    dueDate,
    courseId,
    courseName,
    colorHex,
    timeSpent,
    completedOn,
    daysToStartAhead,
    duration,
    priority,
    categoryId,
    categoryName,
    emoji,
    readingSourceId,
    readingSourceName
  } = task;
  return {
    taskId,
    title,
    subtitle,
    type,
    priority,
    courseId,
    courseName,
    colorHex,
    timeSpent,
    completedOn,
    duration,
    categoryName,
    categoryId,
    emoji,
    readingSourceName,
    readingSourceId,
    due: dueDate ? toMomentDate(dueDate) : null,
    daysToStartAhead
  };
};

const toNoDueDateTask = (task: any): any => {
  const {
    taskId,
    title,
    subtitle,
    type,
    courseId,
    courseName,
    colorHex,
    timeSpent,
    daysToStartAhead,
    duration,
    categoryName,
    categoryId,
    emoji,
    priority,
    timeNeed,
    timeHave,
    timePlanned,
    readingSourceId,
    readingSourceName
  } = task;
  return {
    taskId,
    title,
    subtitle,
    type,
    priority,
    courseId,
    courseName,
    colorHex,
    timeNeed,
    timeHave,
    timeSpent,
    timePlanned,
    daysToStartAhead,
    duration,
    due: null,
    categoryId,
    categoryName,
    emoji,
    readingSourceId,
    readingSourceName
  };
};

const toRecentlyCreatedTask = (task: any): any => {
  const {
    taskId,
    title,
    subtitle,
    type,
    courseId,
    courseName,
    colorHex,
    timeNeed,
    timeHave,
    timeSpent,
    cushion,
    priority,
    timePlanned,
    daysToStartAhead,
    dueDate,
    duration,
    categoryId,
    categoryName,
    emoji,
    readingSourceName,
    readingSourceId,
    createdAt
  } = task;
  return {
    taskId,
    title,
    subtitle,
    type,
    priority,
    courseId,
    courseName,
    colorHex,
    timeNeed,
    timeHave,
    timeSpent,
    cushion,
    timePlanned,
    daysToStartAhead,
    duration,
    categoryId,
    categoryName,
    emoji,
    readingSourceName,
    readingSourceId,
    due: dueDate ? toMomentDate(dueDate) : null,
    createdAt
  };
};

export { toToDoTask, toDoLaterTask, toCompletedTask, toNoDueDateTask, toRecentlyCreatedTask };
