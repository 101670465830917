import React, { PureComponent } from 'react';
import { TaskState } from 'shovel-lib/types';
import { ObviousMike, ObviousMikeAnimation } from '@utils/taskUtils';
import ButtonWithAnimation from '@components/common/buttons/ButtonWithAnimation';
import { taskActions } from '@state/task';
import t from '@i18n/t';
import colors from '@utils/colors';
import gaAnalytics, { AnalyticsEvent } from '@config/gaAnalytics';
import { now, toMomentDate } from 'shovel-lib/utils/timeUtils';
import styled from 'styled-components';
import { Row } from '@components/common/layoutUtils';
import { screenSizes } from '@utils/screenUtils';
import { moveToTodo, updateTaskStatus } from '@state/task/actions';
import { bindActionCreators, Dispatch } from 'redux';
import { openMoveToDoDialog } from '@state/pile/actions';
import { connect } from 'react-redux';

type PropsFromState = {
  updateState: typeof updateTaskStatus.request;
  moveToTodo: typeof moveToTodo.request;
};

type Props = PropsFromState & { task: any };

class UpdateTaskState extends PureComponent<Props> {
  showObviousMike = task => {
    if (task.state === TaskState.COMPLETED) return false;
    if (!!task.reading) {
      const pageRangesCount = task.pageRanges?.length || 0;
      return task.timeNeed === 0 && pageRangesCount > 0;
    }
    return task.timeSpent > 0 && task.timeNeed === 0;
  };

  updateState = (state: TaskState) => {
    const { task, updateState } = this.props;
    if (task.state === state) return;
    updateState({ taskId: task.taskId, data: { state } });

    if (state === TaskState.COMPLETED) {
      const timePlanned = task.plannedTasks.reduce((r, c) => r + c.plannedMinutes, 0);
      gaAnalytics.track(
        AnalyticsEvent.CompleteTask.name,
        AnalyticsEvent.CompleteTask.params({
          completedOn: now().toISOString(),
          due: task.dueDate,
          timePlanned,
          timeStillNeed: task.timeNeed,
          estimatedTime: task.timeSpent + task.timeNeed
        })
      );
    }
  };

  moveToDo = () => {
    const {
      task: { taskId, dueDate, daysToStartAhead },
      moveToTodo
    } = this.props;
    moveToTodo({ taskId, data: { dueDate, daysAhead: daysToStartAhead } });
  };

  render() {
    const { task } = this.props;
    const completed = task.state === TaskState.COMPLETED;

    return (
      <ToDoCompleteWrapper>
        <ObviousMike>
          {this.showObviousMike(task) && <ObviousMikeAnimation left={15}>👇</ObviousMikeAnimation>}
          <ButtonWithAnimation
            onClick={() => (completed ? this.moveToDo() : this.updateState(TaskState.COMPLETED))}
            action={taskActions.updateTaskStatus}
            successText={t.COMPLETED}
            successIcon={'thumb_up'}
            loadingText={t.YOU_ROCK}
            text={completed ? t.COMPLETED : t.MARK_COMPLETE}
            icon={completed ? 'thumb_up' : undefined}
            color={colors.positive}
            filledBeforeAction={completed}
            filledAfterAction={completed}
            size={'sm'}
            progressIndicator={!completed}
          />
        </ObviousMike>
      </ToDoCompleteWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      moveToTodo: moveToTodo.request,
      openMoveToDoDialog: openMoveToDoDialog,
      updateState: updateTaskStatus.request
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(UpdateTaskState);

//this is in order to not have jumping after mark complete action
//112px is MarkComplete button + 10px margin
const ToDoCompleteWrapper = styled(Row)`
  min-width: 122px;
  margin-right: 50px;
  @media (max-width: ${screenSizes.medium}) {
    margin-right: 15px;
  }
`;
