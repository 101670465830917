import t from '@i18n/t';
import React, { PureComponent } from 'react';
import TippyTooltip from '../tooltip/TippyTooltip';
import ColorPicker from '../colorPicker/ColorPicker';
import { alignStyle, editorFormats, headerStyle } from '@utils/editorHelpers';
import { ReactComponent as AlignLeft } from '@assets/icons/editor/align-left.svg';
import { ReactComponent as AlignCenter } from '@assets/icons/editor/align-center.svg';
import { ReactComponent as AlignRight } from '@assets/icons/editor/align-right.svg';
import { ReactComponent as AlignJustify } from '@assets/icons/editor/align-justify.svg';
import { ReactComponent as BackgroundIcon } from '@assets/icons/editor/background.svg';
import { ReactComponent as ColorIcon } from '@assets/icons/editor/color.svg';
import { ColorPickerIcon, DropDownWrapper, LinkPopup, DropDownItem } from './Editor.styles';
import TextInput from '../inputs/text/TextInput';

const headerOptions = [
  { value: headerStyle.HEADER1, label: t.HEADING1, size: '2em' },
  { value: headerStyle.HEADER2, label: t.HEADING2, size: '1.5em' },
  { value: headerStyle.HEADER3, label: t.HEADING3, size: '1.17em' },
  { value: headerStyle.NORMAL, label: t.NORMAL }
];

const alignOptions = [
  { value: alignStyle.LEFT, icon: <AlignLeft /> },
  { value: alignStyle.CENTER, icon: <AlignCenter /> },
  { value: alignStyle.RIGHT, icon: <AlignRight /> },
  { value: alignStyle.JUSTIFY, icon: <AlignJustify /> }
];

type Props = {
  isOpen: boolean;
  renderTarget: any;
  isAlign?: boolean;
  setFormat: any;
  toggle: any;
};

type State = {
  format: string;
};

export class CustomToolbarDropdownComponent extends PureComponent<Props, State> {
  state: State = {
    format: ''
  };

  //it is used in parent component (Editor.tsx)
  setSelectedFormat = value => {
    this.setState({ format: value });
  };

  getSelectedOption = defaultOption => {
    const { format } = this.state;
    if (this.props.isAlign) {
      // @ts-ignore
      return alignOptions.find(o => o.value === format) || defaultOption;
    }
    return headerOptions.find(o => o.value === format) || defaultOption;
  };

  renderHeaderOptions = () => {
    const { toggle, setFormat } = this.props;
    return (
      <DropDownWrapper>
        {headerOptions.map(op => (
          <DropDownItem
            key={op.value}
            onClick={() => {
              setFormat(editorFormats.HEADER, op.value);
              toggle(editorFormats.HEADER, false);
            }}
          >
            <span style={{ fontSize: op.size }}>{op.label}</span>
          </DropDownItem>
        ))}
      </DropDownWrapper>
    );
  };

  renderAlignOptions = () => {
    const { toggle, setFormat } = this.props;
    return (
      <DropDownWrapper>
        {alignOptions.map(op => (
          <DropDownItem
            key={op.value}
            align
            onClick={() => {
              setFormat(editorFormats.ALIGN, op.value);
              toggle(editorFormats.ALIGN, false);
            }}
          >
            {op.icon}
          </DropDownItem>
        ))}
      </DropDownWrapper>
    );
  };

  render() {
    const { isOpen, isAlign, renderTarget, toggle } = this.props;
    const defaultOption = isAlign ? alignOptions[0] : headerOptions[3];
    const selectedOption = this.getSelectedOption(defaultOption);
    const isDefaultOption = selectedOption.value === defaultOption.value;
    return (
      <TippyTooltip
        target={renderTarget(selectedOption, isDefaultOption)}
        content={isAlign ? this.renderAlignOptions() : this.renderHeaderOptions()}
        position={'bottom-start'}
        interactive
        arrow={false}
        lightTheme
        noPadding
        controlled
        visible={isOpen}
        onClickOutside={() => toggle(isAlign ? editorFormats.ALIGN : editorFormats.HEADER, false)}
      />
    );
  }
}

type PProps = {
  onClick: any;
  isBgColor?: boolean;
  theme: any;
  open: boolean;
  toggle: any;
  disabled?: boolean;
};

type PState = {
  color: string;
};

export class CustomToolbarColorPicker extends PureComponent<PProps, PState> {
  state: PState = {
    color: ''
  };

  //it is used in parent component (Editor.tsx)
  setColor = value => {
    this.setState({ color: value });
  };

  renderTarget = () => {
    const { isBgColor, toggle, open, disabled } = this.props;
    const target = (
      <ColorPickerIcon color={this.state.color} isBgColor={isBgColor} disabled={disabled}>
        {isBgColor ? <BackgroundIcon /> : <ColorIcon />}
      </ColorPickerIcon>
    );
    return (
      <button onClick={() => toggle(isBgColor ? editorFormats.BACKGROUND : editorFormats.COLOR, !open)} disabled={disabled}>
        {disabled ? target : <TippyTooltip target={target} content={isBgColor ? t.TXT_BG : t.FONT_COLOR} />}
      </button>
    );
  };

  handleChange = (selectedColor?: string, noCustomPicker?: boolean) => {
    const { isBgColor, theme, onClick } = this.props;
    const format = isBgColor ? editorFormats.BACKGROUND : editorFormats.COLOR;
    const defaultColor = isBgColor ? theme.background : theme.textStrongColor;
    //this is for reset text and bg color on default (empty string is reset format)
    const color = selectedColor === defaultColor ? '' : selectedColor;
    //noCustom picker is for not losing selection with custom picker handling
    onClick(format, color, !noCustomPicker);
  };

  onSave = selectedColor => {
    const { isBgColor, toggle } = this.props;
    this.handleChange(selectedColor, true);
    toggle(isBgColor ? editorFormats.BACKGROUND : editorFormats.COLOR, false);
  };

  render() {
    const { color } = this.state;
    const { open, toggle, isBgColor } = this.props;
    return (
      <TippyTooltip
        target={this.renderTarget()}
        content={
          <ColorPicker
            onSave={this.onSave}
            onChange={this.handleChange}
            selectedColor={color}
            isEditor
            isBgColorPalette={this.props.isBgColor}
          />
        }
        position={'bottom-start'}
        interactive
        arrow={false}
        lightTheme
        colorPicker
        visible={open}
        controlled
        onClickOutside={() => toggle(isBgColor ? editorFormats.BACKGROUND : editorFormats.COLOR, false, true)}
      />
    );
  }
}

type LinkProps = {
  setFormat: any;
  disabled?: boolean;
};

type LinkState = {
  position: any;
  link: string;
};

export class CustomLinkPopup extends PureComponent<LinkProps, LinkState> {
  state: LinkState = {
    position: null,
    link: ''
  };

  //it is used in parent component (Editor.tsx)
  setPosition = position => {
    this.setState({ position });
  };

  handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.props.setFormat(editorFormats.LINK, this.state.link);
      this.close();
    }
  };
  close = () => this.setState({ position: null });

  render() {
    if (!this.state.position) return null;
    const { left, top } = this.state.position;
    return (
      <LinkPopup left={left} top={top} onClick={e => e.stopPropagation()} tabIndex={1} onBlur={this.close}>
        <TextInput
          placeholder={t.LINK_PLACEHOLDER}
          autofocus
          onKeyUp={this.handleKey}
          onChange={e => this.setState({ link: e.target.value })}
        />
      </LinkPopup>
    );
  }
}
