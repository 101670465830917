import { Moment } from 'moment';
import { CalendarEvent, UntilOption } from 'shovel-lib/types';

export enum SourceEventType {
  EVENT = 'EVENT',
  CLASS = 'CLASS',
  ACTIVITY = 'ACTIVITY',
  MEAL = 'MEAL',
  ME_TIME = 'ME_TIME',
  STUDY_TIME = 'STUDY_TIME',
  EXTRA_TIME = 'EXTRA_TIME',
  PLANNED_STUDY_TIME = 'PLANNED_STUDY_TIME',
  TASK = 'TASK',
  PLANNED_TASK = 'PLANNED_TASK',
  COMMUTE = 'COMMUTE',
  AWAKE_TIME = 'AWAKE_TIME',
  PRE_AWAKE_TIME = 'PRE_AWAKE_TIME',
  COMMUTE_BEFORE = 'COMMUTE_BEFORE',
  COMMUTE_AFTER = 'COMMUTE_AFTER',
  HOLIDAY = 'HOLIDAY'
}

export type SourceEvent = {
  semesterId: number;
  id?: string;
  title: string;
  description?: string | null;
  correlationId: string | null;
  colorHex: string;
  start: string;
  end: string;
  durationInMinutes?: number;
  notes?: string;
  commuteBefore?: number | null;
  commuteAfter?: number | null;
  parentId?: string;
  originalStartDate?: string;
  isRecurring?: boolean;
  RRuleSet?: string;
  type: SourceEventType;
  untilOption?: UntilOption;
  location?: string | null;
  repeatCorrelationId?: string;
  busy?: boolean;
  allDay?: boolean;
  lmsUid?: string;
  lmsType?: string;
};

export type EventsState = {
  events: { [key: string]: CalendarEvent[] };
  sourceEvents: SourceEvent[];
  /**
   * start date of unpacked events
   */
  start: Moment | null;
  /**
   * end date of unpacked events
   */
  end: Moment | null;
};
