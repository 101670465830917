import React, { FC } from 'react';
import styled from 'styled-components';
import colors from '../../utils/colors';
import { Row } from './layoutUtils';
import { MediumText } from '../../utils/typography';

type Props = {
  onChange: () => void;
  active: boolean;
  label?: string;
  iconPath?: string;
  className?: string;
  reverse?: boolean;
  disabled?: boolean;
  alignBaseline?: boolean;
  customLabel?: any;
};

const Toggle: FC<Props> = ({
  onChange,
  active,
  label,
  iconPath,
  className,
  reverse,
  disabled,
  alignBaseline = false,
  customLabel
}) => (
  <Container className={className} onClick={() => !disabled && onChange()} baseline={alignBaseline}>
    {iconPath && <Icon path={iconPath} />}
    {reverse ? (
      <>
        <ToggleWrapper>
          <Handle active={active} />
          <Area active={active} />
        </ToggleWrapper>
        {customLabel || <Label strong={active}>{label}</Label>}
      </>
    ) : (
      <>
        {customLabel || <Label strong={active}>{label}</Label>}
        <ToggleWrapper>
          <Handle active={active} />
          <Area active={active} />
        </ToggleWrapper>
      </>
    )}
  </Container>
);

export default Toggle;

const Container = styled(Row)<{ baseline?: boolean }>`
  cursor: pointer;
  ${props => props.baseline && `align-items: baseline;`};
`;

const Icon = styled.div<{ path: string }>`
  width: 14px;
  height: 14px;
  background: url("${props => props.path}") no-repeat center;
  background-size: cover;
`;

const Label = styled(MediumText)`
  font-size: 12px;
  font-weight: bold;
  transition: color 0.2s ease;
  margin: 0 6px;
  white-space: pre;
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const Handle = styled.div<{ active: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.background};
  position: absolute;
  top: 2px;
  transition: all 0.2s ease;
  ${props => (props.active ? 'right: 2px;' : 'right: 12px;')}
`;

const Area = styled.div<{ active: boolean }>`
  width: 20px;
  height: 10px;
  border-radius: 10px;
  background-color: ${props => (props.active ? colors.primaryPurple : props.theme.textColor)};
`;
