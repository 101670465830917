import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { formatMomentDateAs, toMomentUtcDate } from 'shovel-lib/utils/timeUtils';

import { FlexColumn, Row } from '../../layoutUtils';
import { RegularText } from '@utils/typography';
import MaterialIcon from '../../icons/MaterialIcon';

class DatePickerCustomHeader extends PureComponent<any> {
  render() {
    const { date, prevMonthButtonDisabled, decreaseMonth, nextMonthButtonDisabled, increaseMonth } = this.props;
    if (!date) return null;
    const utcDate = toMomentUtcDate(date);
    utcDate.subtract(date.getTimezoneOffset(), 'minutes');
    return (
      <CustomHeader>
        <MonthHandle onClick={!prevMonthButtonDisabled ? decreaseMonth : undefined}>
          {!prevMonthButtonDisabled && <MaterialIcon name={'navigate_before'} />}
        </MonthHandle>
        <FlexColumn>
          <RegularText strong>{formatMomentDateAs(utcDate, 'MMMM YYYY')}</RegularText>
        </FlexColumn>
        <MonthHandle onClick={!nextMonthButtonDisabled ? increaseMonth : undefined}>
          {!nextMonthButtonDisabled && <MaterialIcon name={'navigate_next'} />}
        </MonthHandle>
      </CustomHeader>
    );
  }
}

export default DatePickerCustomHeader;

const CustomHeader = styled(Row)`
  padding: 20px 20px 10px;
  background: ${props => props.theme.background};
`;

const MonthHandle = styled.div`
  width: 20px;
  height: 20px;

  > span {
    cursor: pointer;
    color: ${({ theme }) => theme.textColor};
  }
`;
