import React, { FC } from 'react';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import { FlexRow } from '../layoutUtils';

type Props = {
  leftComponent?: string | React.ReactNode;
  rightComponent?: string | React.ReactNode;
  percentage: string;
  percentageIncludingSession: string;
  textUnder?: boolean;
  className?: string;
  progressColor?: string;
  trackColor?: string;
  //onboarding simple bar without left and right component
  simple?: boolean;
  noBorderRadius?: boolean;
};

const ProgressBar: FC<Props> = ({
  leftComponent,
  rightComponent,
  percentage,
  percentageIncludingSession,
  textUnder,
  className,
  progressColor = colors.primaryPurple,
  trackColor = colors.lightgray,
  simple,
  noBorderRadius
}) => {
  const barColor = parseInt(percentage) === 100 ? colors.aqua : progressColor || colors.primaryPurple;
  const progressBarElement = (
    <EmptyProgressBar color={trackColor} noBorderRadius={noBorderRadius}>
      {/*NOTE - filters stack on each other, they are not starting from the same spot*/}
      <Filler color={barColor} percentage={percentage} noBorderRadius={noBorderRadius} />
      <Filler color={colors.aqua} percentage={percentageIncludingSession} noBorderRadius={noBorderRadius} />
    </EmptyProgressBar>
  );
  const leftAndRightComponent = (
    <TextWrapper>
      {leftComponent}
      {rightComponent}
    </TextWrapper>
  );

  if (simple) return progressBarElement;
  return (
    <Container className={className}>
      {textUnder ? (
        <>
          {progressBarElement}
          {leftAndRightComponent}
        </>
      ) : (
        <>
          {leftAndRightComponent}
          {progressBarElement}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
`;

const TextWrapper = styled(FlexRow)`
  justify-content: space-between;
`;

const EmptyProgressBar = styled.div<{ color: string; noBorderRadius?: boolean }>`
  display: flex;
  height: 6px;
  background: ${props => props.color};
  border-radius: ${props => (props.noBorderRadius ? 0 : 20)}px;
  flex-direction: row;
`;

const Filler = styled.div<{ percentage: string; color: string; noBorderRadius?: boolean }>`
  height: 100%;
  flex-basis: ${props => props.percentage}%;
  border-radius: ${props => (props.noBorderRadius ? 0 : 20)}px;
  background: ${props => props.color};
`;

export default ProgressBar;
