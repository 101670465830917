import t from '../../../i18n/t';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import Button from '../../common/buttons/Button';
import { membershipApi } from 'shovel-lib';

function ManageStripeBilling() {
  const manageBilling = useCallback(async () => {
    const response = await membershipApi.stripeCreateCustomerPortalSession();
    if (response.ok) {
      window.location.href = response.data!.url;
    }
  }, []);

  return (
    <ManageBillingButton size={'lg'} onClick={() => manageBilling()}>
      {t.MANAGE_BILLING}
    </ManageBillingButton>
  );
}

export default ManageStripeBilling;

const ManageBillingButton = styled(Button)`
  margin-top: 20px;
  width: 240px;
  padding: 14px 30px;
`;
