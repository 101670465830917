import React, { Component } from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
// set multiple refs on the same component/element.
import composeRefs from '@seznam/compose-react-refs';
import { Column } from './layoutUtils';

type Props = {
  children: any;
  className?: string;
  gradientHeight?: number;
  flex?: boolean;
  passScrollRef?: boolean;
  innerRef?: any;
  style?: any;
};

type State = {
  isEndVisible: boolean;
  containerLoaded: boolean;
};

class ScrollGradient extends Component<Props, State> {
  scrollContainer = React.createRef<HTMLDivElement>();
  readonly state = { isEndVisible: false, containerLoaded: false };

  componentDidMount(): void {
    this.setState({ containerLoaded: !!this.scrollContainer.current });
  }

  render() {
    const { isEndVisible, containerLoaded } = this.state;
    const { children, className, gradientHeight = 30, flex, innerRef, style } = this.props;

    return (
      <Container flex={flex} style={style}>
        <Wrapper ref={composeRefs(this.scrollContainer, innerRef)} className={className}>
          {containerLoaded && (
            <VisibilitySensor
              scrollCheck
              resizeCheck
              containment={this.scrollContainer.current}
              onChange={isEndVisible => this.setState({ isEndVisible })}
              //@ts-ignore
              partialVisibility={'bottom'}
            >
              <div className={'gradient-children-wrapper'}>{children}</div>
            </VisibilitySensor>
          )}
        </Wrapper>
        <Gradient hasGradient={!isEndVisible} gradientHeight={gradientHeight} />
      </Container>
    );
  }
}

export default ScrollGradient;

const Container = styled(Column)<{ flex?: boolean }>`
  overflow: hidden;
  ${props => props.flex && 'flex: 1'};
  width: 100%;
`;

const Gradient = styled.div<{ hasGradient: boolean; gradientHeight: number }>`
  z-index: 1;
  box-shadow: ${props => (props.hasGradient ? `0 0 30px ${props.gradientHeight}px ${props.theme.background}` : 'none')};
`;

const Wrapper = styled.div`
  overflow: auto;
`;
