export {};

// E.g. how to create polyfill - https://basarat.gitbook.io/typescript/type-system/lib.d.ts#example-string-redux
declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage(content: string);
    };
    dataLayer: any[];
  }

  interface Array<T> {
    mapAndReplaceById(id: number, newObject: object): Array<T>;

    findById(id: number): T | undefined;

    filterNonEmpty(): Array<T>;
  }
}

if (!Array.prototype.mapAndReplaceById) {
  Array.prototype.mapAndReplaceById = function(id: number, fn: (current: object) => object) {
    return this.map(item => (item?.id === id ? fn(item) : item));
  };
}

if (!Array.prototype.findById) {
  Array.prototype.findById = function(id: number) {
    return this.find(item => item?.id === id);
  };
}

if (!Array.prototype.filterNonEmpty) {
  Array.prototype.filterNonEmpty = function() {
    return this.filter(item => !!item);
  };
}
