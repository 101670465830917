import React, { FC } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { deviceApi } from 'shovel-lib';

import TippyTooltip from '@components/common/tooltip/TippyTooltip';
import storage from '@utils/storage';
import t from '@i18n/t';
import { RegularText } from '@utils/typography';
import MaterialIcon from '@components/common/icons/MaterialIcon';
import { authActions } from '@state/auth';
import { openConfirmationDialog } from '@state/common/actions';
import styled from 'styled-components';
import { Row } from '@components/common/layoutUtils';

type PropsFromDispatch = {
  logout: typeof authActions.logout;
  openConfirmationDialog: typeof openConfirmationDialog;
};

type Props = PropsFromDispatch & { isSettings?: boolean };

const handleClick = (openConfirmationDialog, logout) => {
  openConfirmationDialog({
    action: async () => {
      const fcmToken = storage.getFcmToken();
      if (fcmToken) {
        await deviceApi.deleteDevice(fcmToken);
      }
      logout();
    },
    title: t.LOGOUT_TITLE,
    message: <RegularText dangerouslySetInnerHTML={{ __html: t.LOGOUT_TEXT }} />,
    understandCheck: false,
    okLabel: t.LOGOUT
  });
};

const LogoutIcon: FC<Props> = ({ logout, openConfirmationDialog, isSettings }) => {
  if (isSettings) {
    return (
      <TextWrapper onClick={() => handleClick(openConfirmationDialog, logout)}>
        <MaterialIcon name={'logout'} className={'logout-icon'} size={20} />
        {t.LOG_OUT}
      </TextWrapper>
    );
  }
  return (
    <TippyTooltip
      target={
        <NavIconWrapper onClick={() => handleClick(openConfirmationDialog, logout)}>
          <MaterialIcon name={'logout'} className={'navigation-icon'} size={22} />
        </NavIconWrapper>
      }
      position={'right'}
      content={t.LOG_OUT}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logout: authActions.logout,
      openConfirmationDialog: openConfirmationDialog
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(LogoutIcon);

export const NavIconWrapper = styled(Row)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  justify-content: center;
  margin-top: 10px;
  border-radius: 5px;
  &:hover {
    background: ${props => props.theme.backgroundDark};
    .navigation-icon {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

const TextWrapper = styled(RegularText)`
  display: flex;
  align-items: center;
  cursor: pointer;
  .logout-icon {
    margin-right: 5px;
  }
`;
