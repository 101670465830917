import React, { FC } from 'react';
import styled from 'styled-components';

import InputWrapper from '../InputWrapper';
import DatePicker from './DatePicker';
import InlineInputWrapper from '../InlineInputWrapper';
import { BareWrapper } from '../Input.styles';

type Props = {
  value: any;
  onChange: (value: string) => void;
  minDate?: Date | string;
  maxDate?: Date | string;
  required?: boolean;
  name?: string;
  disabled?: boolean;
  className?: string;
  label?: string;
  inline?: boolean;
  placeholder?: string;
  error?: string;
  touched?: boolean;
  bare?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  dateFormatFn?: (showYear?: boolean) => string;
  autosizeFormat?: (showYear?: boolean) => string;
  footer?: any;
  popperContainerId?: string;
  noErrorMargin?: boolean;
};

const DateInput: FC<Props> = ({
  value,
  name,
  onChange,
  minDate,
  maxDate,
  required = true,
  disabled,
  className,
  label,
  inline,
  placeholder,
  error,
  bare,
  touched,
  onBlur,
  dateFormatFn,
  autosizeFormat,
  footer,
  noErrorMargin,
  popperContainerId
}) => {
  const Wrapper = bare ? BareWrapper : inline ? InlineInputWrapper : InputWrapper;
  return (
    <Wrapper
      icon={!inline && 'calendar_today'}
      className={className}
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      error={error}
      noErrorMargin={noErrorMargin}
      touched={touched}
      required={required}
    >
      <PickerWrapper>
        <DatePicker
          name={name}
          value={value}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          required={required}
          disabled={disabled}
          inlineInput={inline}
          onBlur={onBlur}
          dateFormatFn={dateFormatFn}
          autosizeFormat={autosizeFormat}
          footer={footer}
          popperContainerId={popperContainerId}
        />
      </PickerWrapper>
    </Wrapper>
  );
};

export default DateInput;

const PickerWrapper = styled.div`
  line-height: 17px;
  flex: 1;
  z-index: 1;
`;
