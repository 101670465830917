import React, { FC } from 'react';
import styled, { withTheme } from 'styled-components';
import Button from '../buttons/Button';

type Props = {
  onClick?: () => void;
  theme: any;
  className?: any;
};

const DialogCloseButton: FC<Props> = ({ onClick, theme, className }) => (
  <CloseButton className={className} onClick={onClick} icon={'close'} color={theme.textColor} withoutBorder />
);

export default withTheme(DialogCloseButton);

const CloseButton = styled(Button)`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  cursor: pointer;
  z-index: 1;
  width: 20px;
  height: 20px;
  min-width: 0 !important;

  span {
    font-size: 12px;
  }

  & span:hover {
    color: ${({ theme }) => theme.textStrongColor};
  }
`;
