import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';
import { getSemesterEventsAndAwakes, getCommuteTimes, getPlannedEvents, getTaskEvents } from '../calendar/selectors';
import { calendarData } from '../calendar/actions';
import { sourceEvents } from '../events/actions';
import { now, toMomentDate } from 'shovel-lib/utils/timeUtils';
import {
  constructStudyOrExtraTime,
  extractFreeTimeBlocks,
  minutesPopulatedWithEvents
} from 'shovel-lib/utils/calendarFreeBlocksUtils';
import { extractActionNameWithoutSuffix } from '../common/utils';
import { CalendarEventType } from 'shovel-lib/types';
import { CalendarView } from '@state/calendar/types';

const getDatesContainingTasksMini = ({ weekCalendar: { datesContainingTasksMini } }: RootState) => {
  return {
    datesContainingTasksMini
  };
};

const getDateRange = ({ calendar: { startDate, endDate } }: RootState) => {
  return {
    startDate,
    endDate
  };
};

const getFreeTimeEventsWeek = createSelector(
  [
    (state: RootState) => state.calendar.semesterInfo,
    (state: RootState) => state.common.loaders[extractActionNameWithoutSuffix(calendarData.request)],
    (state: RootState) => state.common.loaders[extractActionNameWithoutSuffix(sourceEvents.request)],
    (state: RootState) => state.calendar.googleEvents,
    (state: RootState, props: any) => getSemesterEventsAndAwakes?.(state, props),
    (state: RootState, props: any) => getPlannedEvents?.(state, props),
    (state: RootState, props: any) => getCommuteTimes?.(state, props),
    (state: RootState, props: any) => getTaskEvents?.(state, props),
    (state: RootState) => state.calendar.startDate,
    (state: RootState) => state.calendar.endDate,
    (state: RootState) => state.calendar.view === CalendarView.MONTH
  ],
  (
    semesterInfo,
    calendarDataLoading,
    eventsListLoading,
    googleEvents,
    stateEvents,
    plannedEvents,
    commuteTimes,
    taskEvents,
    startDate,
    endDate,
    isMonthView
  ) => {
    if (
      calendarDataLoading ||
      eventsListLoading ||
      !semesterInfo ||
      stateEvents.awakeTimeEvents.length === 0 ||
      !startDate ||
      !endDate ||
      isMonthView
    )
      return [];

    const start = toMomentDate(startDate);
    const end = toMomentDate(endDate);

    const calendarStart = start.isBefore(semesterInfo.calendarMinDate) ? semesterInfo.calendarMinDate : start;

    const calendarEnd = end.isAfter(semesterInfo.calendarMaxDate) ? semesterInfo.calendarMaxDate : end;

    if (calendarEnd.isBefore(calendarStart)) return [];

    const populatedMinutes = minutesPopulatedWithEvents(
      [
        ...stateEvents.events,
        ...plannedEvents,
        ...commuteTimes,
        ...googleEvents,
        ...taskEvents.filter(e => e.type !== CalendarEventType.TASK)
      ],
      stateEvents.awakeTimeEvents,
      calendarStart,
      calendarEnd
    );

    return extractFreeTimeBlocks(populatedMinutes).map(block =>
      constructStudyOrExtraTime(block, semesterInfo, calendarStart.toDate(), now())
    );
  }
);

export { getDatesContainingTasksMini, getDateRange, getFreeTimeEventsWeek };
