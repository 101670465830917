import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { Formik, FormikProps } from 'formik';
import { number as yupNumber, object as yupObject } from 'yup';

import colors from '../../../../utils/colors';
import t from '../../../../i18n/t';
import { addTimeEntryModal } from '../../../../utils/popupUtils';
import { RegularText } from '../../../../utils/typography';
import { ConfirmAndCancelButtons } from '../../../common/layoutUtils';
import { NumberInput } from '../../../common/inputs';
import { DAYS_TO_START_AHEAD_MAX } from '../../../../utils/constants/task';
import Button from '../../../common/buttons/Button';
import ButtonWithLoader from '../../../common/buttons/ButtonWithLoader';

type Props = {
  value: number;
  label: string | React.ReactElement;
  saveDaysAhead: (data: { daysToStartAhead: number }) => void;
  disabled?: boolean;
  loadingAction?: any;
};

const validationSchema = yupObject().shape({
  daysStartAhead: yupNumber()
    .required(t.REQUIRED)
    .min(0, `${t.MIN_VALUE_ERROR} 0`)
    .max(DAYS_TO_START_AHEAD_MAX, `${t.MAX_VALUE_ERROR} ${DAYS_TO_START_AHEAD_MAX}`)
});

class ManageStartAhead extends PureComponent<Props> {
  save = (value: number) => {
    // if (this.props.value !== value) {
    this.props.saveDaysAhead({ daysToStartAhead: value });
    // }
  };

  render() {
    const { value, label, disabled, loadingAction } = this.props;
    return (
      <>
        {disabled ? (
          <RegularText>{label}</RegularText>
        ) : (
          <Popup
            className={'popup-wrapper'}
            trigger={typeof label === 'string' ? <ClickableLabel strong>{label}</ClickableLabel> : label}
            position={'bottom left'}
            on="click"
            contentStyle={addTimeEntryModal}
            arrowStyle={{ display: 'none' }}
          >
            {close => (
              <Formik
                initialValues={{ daysStartAhead: value }}
                validationSchema={validationSchema}
                enableReinitialize={false}
                onSubmit={values => {
                  this.save(values.daysStartAhead);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit
                }: FormikProps<{ daysStartAhead: number }>) => (
                  <>
                    <NumberInput
                      name="daysStartAhead"
                      value={values.daysStartAhead}
                      min={0}
                      max={DAYS_TO_START_AHEAD_MAX}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={t.START_DAYS_AHEAD}
                      error={errors.daysStartAhead}
                      touched={touched.daysStartAhead}
                    />
                    <ConfirmAndCancelButtons>
                      <Button size={'sm'} withoutBorder onClick={close} color={colors.primaryPurple}>
                        {t.CANCEL}
                      </Button>
                      <ButtonWithLoader
                        onClick={handleSubmit}
                        action={loadingAction}
                        callback={close}
                        filled
                        size={'sm'}
                        text={t.SAVE}
                      />
                    </ConfirmAndCancelButtons>
                  </>
                )}
              </Formik>
            )}
          </Popup>
        )}
      </>
    );
  }
}

export default ManageStartAhead;

const ClickableLabel = styled(RegularText)`
  cursor: pointer;
  padding: 3px 6px;
  border-radius: 5px;

  &:hover {
    background: ${props => props.theme.backgroundDark};
  }
`;
