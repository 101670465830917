import React, { FC, useEffect, useState } from 'react';
import MaterialIcon from '../../common/icons/MaterialIcon';
import styled, { withTheme } from 'styled-components';
import { ConfirmAndCancelButtons, Row } from '../../common/layoutUtils';
import TippyTooltip from '../../common/tooltip/TippyTooltip';
import { TooltipPosition } from '@utils/popupUtils';
import { Field, Formik, FormikProps } from 'formik';
import t from '@i18n/t';
import colors from '@utils/colors';
import Button from '../../common/buttons/Button';
import CheckBox from '../../common/CheckBox';
import { MediumText } from '@utils/typography';
import { FormFieldName } from '@utils/enums';

type ReadingSourceInfoType = {
  numberOfPages?: number;
  timePerPage?: number;
};

type Props = {
  buttonRef?: any;
  icon?: string;
  tooltip?: string;
  onClick?: any;
  noClassMaterialIcon?: boolean;
  renderActiveComponent?: any;
  wrappedActiveComponent?: any;
  disabled?: boolean;
  size?: 'mini' | 'small' | 'normal';
  content?: any;
  popup?: {
    content: any;
    placeholder: string;
    initialValues: any;
    field: string;
    submit: any;
    appendTo?: 'parent' | Element | ((ref: Element) => Element);
    setField?: boolean;
    position?: TooltipPosition;
    readingSourceInfo?: ReadingSourceInfoType;
    contentProps?: any;
    info?: string;
    openPersonalizationDialog?: any;
    closeOnClickOutside?: boolean;
  };
  clear?: any;
  clearTooltip?: string;
  theme: any;
};

export const getColor = (isDisabled, isActive, theme) => {
  if (isDisabled) return theme.borderColor;

  if (isActive) return theme.primary;

  return theme.textColor;
};

const renderContent = props => {
  const { tooltip, wrappedActiveComponent, renderActiveComponent, disabled } = props;
  if (renderActiveComponent) {
    const activeComponent = renderActiveComponent();
    if (activeComponent) {
      return activeComponent;
    } else {
      const el = renderButton(props, null);
      return tooltip ? <TippyTooltip target={el} content={tooltip} preformattedText /> : el;
    }
  }

  const activeComponent = wrappedActiveComponent && wrappedActiveComponent();
  if (activeComponent) {
    const el = renderButton(props, activeComponent);
    return tooltip && disabled ? <TippyTooltip target={el} content={tooltip} preformattedText /> : el;
  }
  const el = renderButton(props, null);
  return tooltip ? <TippyTooltip target={el} content={tooltip} preformattedText /> : el;
};

const getButtonSize = size => {
  switch (size) {
    case 'mini':
      return 28;
    case 'small':
      return 40;
    default:
      return 60;
  }
};

const renderButton = (props, activeComponent?: any) => {
  const {
    disabled,
    icon,
    noClassMaterialIcon,
    onClick,
    children,
    theme,
    clear,
    content,
    clearTooltip,
    size = 'normal'
  } = props;
  const color = getColor(disabled, activeComponent, theme);
  return (
    <ButtonWrapper
      btnSize={getButtonSize(size)}
      isDisabled={disabled}
      onClick={() => {
        if (!disabled && onClick) onClick();
      }}
      isActive={activeComponent}
      color={color}
    >
      {icon && <MaterialIcon name={icon} noClass={noClassMaterialIcon} size={size === 'mini' ? 16 : 20} />}
      {children}
      {content}
      {activeComponent && (
        <>
          {activeComponent}
          {clear && (
            <TippyTooltip
              target={
                <ClearIcon
                  onClick={e => {
                    clear();
                    e.stopPropagation();
                  }}
                >
                  <MaterialIcon name={'cancel'} size={14} />
                </ClearIcon>
              }
              content={clearTooltip || t.CLEAR}
            />
          )}
        </>
      )}
    </ButtonWrapper>
  );
};

const updateDaysAhead = (settingsValues, setFieldValue) => {
  setFieldValue(FormFieldName.START_AHEAD, settingsValues.daysAhead);
};

const renderExtraContent = (props: Props, isSelected, setSelected, setField) => {
  const {
    // @ts-ignore
    popup: { readingSourceInfo, field, openPersonalizationDialog }
  } = props;

  if (readingSourceInfo) {
    const value = (readingSourceInfo.timePerPage / 60) * readingSourceInfo.numberOfPages;
    const label = `${t.PAGE_BASED_EST} ${
      readingSourceInfo
        ? `(${readingSourceInfo?.numberOfPages} ${readingSourceInfo?.numberOfPages === 1 ? t.PAGE : t.PAGES})`
        : ''
    }`;
    return (
      <PageBasedEstCheckbox
        checked={isSelected}
        label={label}
        onChange={() => {
          if (!isSelected) {
            setField(field, value);
          }
          setSelected(!isSelected);
        }}
        labelStyle={{
          color: isSelected ? props.theme.textStrongColor : props.theme.textColor,
          fontSize: '12px'
        }}
      />
    );
  }
  if (openPersonalizationDialog) {
    return (
      <DefaultLabel onClick={() => openPersonalizationDialog(values => updateDaysAhead(values, setField))}>
        {t.SET_DEFAULT}
      </DefaultLabel>
    );
  }
  return null;
};

const ActionButton: FC<Props> = props => {
  const [isSelected, setSelected] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setSelected(!!props.popup?.readingSourceInfo);
  }, [props.popup?.readingSourceInfo]);

  if (!props.popup && props.content) {
    renderContent(props);
  }
  if (props.popup) {
    const {
      initialValues,
      content,
      placeholder,
      field,
      submit,
      setField,
      readingSourceInfo,
      contentProps,
      info,
      appendTo,
      closeOnClickOutside = true
    } = props.popup;
    if (readingSourceInfo) {
      // @ts-ignore
      initialValues[field] = (readingSourceInfo.timePerPage / 60) * readingSourceInfo.numberOfPages;
    }
    return (
      <Container data-cy={'menu'}>
        <TippyTooltip
          target={
            <div ref={props.buttonRef} onClick={() => setOpen(true)}>
              {renderContent(props)}
            </div>
          }
          disabled={props.disabled}
          content={
            <div>
              <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={values => {
                  if (readingSourceInfo) {
                    submit(values[field], isSelected);
                  } else {
                    submit(values[field]);
                  }
                  setOpen(false);
                }}
              >
                {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }: FormikProps<any>) => (
                  <Container>
                    <FieldWrapper>
                      <Field
                        name={field}
                        component={content}
                        value={values[field]}
                        touched={touched[field]}
                        error={errors[field]}
                        label={placeholder}
                        onChange={!setField ? handleChange : (value: any) => setFieldValue(field, value)}
                        disabled={readingSourceInfo && isSelected}
                        autofocus
                        required={false}
                        positive
                        {...contentProps}
                      />
                      {info && (
                        <TippyTooltip
                          target={
                            <div style={{ marginBottom: '10px' }}>
                              <MaterialIcon name={'help'} color={colors.primaryPurple} />
                            </div>
                          }
                          content={info}
                          preformattedText
                          position={'right'}
                        />
                      )}
                    </FieldWrapper>
                    {renderExtraContent(props, isSelected, setSelected, setFieldValue)}
                    <ConfirmAndCancelButtons>
                      <Button size={'sm'} withoutBorder onClick={() => setOpen(false)} color={colors.primaryPurple}>
                        {t.CANCEL}
                      </Button>
                      <Button size={'sm'} filled onClick={() => handleSubmit()}>
                        {t.ADD}
                      </Button>
                    </ConfirmAndCancelButtons>
                  </Container>
                )}
              </Formik>
            </div>
          }
          lightTheme
          arrow={false}
          position={'bottom'}
          controlled
          interactive
          visible={isOpen}
          appendTo={appendTo}
          onClickOutside={() => {
            if (closeOnClickOutside) setOpen(false);
          }}
          lazy
          minWidth={contentProps?.minWidth}
          width={readingSourceInfo ? '260px' : '216px'}
        />
      </Container>
    );
  }
  return renderContent(props);
};

export default withTheme(ActionButton);

export const ClearIcon = styled(Row)`
  visibility: hidden;
  margin-left: 5px;
  margin-right: -5px;
  z-index: 1;
  &:hover {
    > i {
      color: ${colors.negative};
    }
  }
`;

export const ButtonWrapper = styled(Row)<{ isDisabled?: boolean; isActive?: boolean; color?: string; btnSize: number }>`
  height: ${props => props.btnSize}px;
  ${props => !props.isActive && `width: ${props.btnSize}px;`};
  border-radius: 50%;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  justify-content: center;
  border: 1px ${props => (props.isActive ? 'solid' : 'dashed')} ${props => props.color || props.theme.textColor};
  box-sizing: border-box;
  position: relative;
  > svg {
    width: ${props => (props.btnSize === 28 ? 14 : 18)}px;
    height: ${props => (props.btnSize === 28 ? 14 : 18)}px;
  }
  ${props =>
    props.isActive &&
    `border-radius: 30px;
    padding: 0 15px;`}
  > i {
    color: ${props => props.color || props.theme.textColor};
  }
  > svg {
    path {
      fill: ${props => props.color || props.theme.textColor};
    }
  }
  &:hover {
    ${props =>
      !props.isDisabled &&
      `border-color: ${props.theme.primary}${props.isActive ? 'BF' : ''} ;
        > i {
          color: ${props.theme.primary}${props.isActive ? 'BF' : ''} !important;
        }
        > span, input:not(:focus) {
          color: ${props.theme.primary} !important;
        }
        > svg {
          path {
            fill: ${props.theme.primary}${props.isActive ? 'BF' : ''}  !important;
          }
        }`}

    ${props =>
      props.isActive &&
      `
      ${ClearIcon} { visibility: visible;}
    `}
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const FieldWrapper = styled(Row)`
  width: 100%;
  justify-content: space-between;

  > div:first-child {
    flex: 1;
    margin-right: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
`;

const PageBasedEstCheckbox = styled(CheckBox)`
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

const DefaultLabel = styled(MediumText)`
  color: ${props => props.theme.primary};
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
`;
