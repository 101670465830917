import React from 'react';
import styled from 'styled-components';

import colors from '../../utils/colors';
import { SmallText } from '../../utils/typography';

type InputType = {
  label?: string;
  value: string;
  big?: boolean;
  labelStyle?: object;
  valueStyle?: object;
  font?: number;
  className?: string;
};

type StyleType = {
  fontSize: number;
};

const ValueWithLabel = ({ label, value, big = false, labelStyle, valueStyle, font, className }: InputType) => {
  const fontSize = font || (big ? 14 : 12);
  return (
    <Container className={className}>
      {label && (
        <Label {...{ fontSize }} style={labelStyle}>
          {label}:&nbsp;
        </Label>
      )}
      <Value {...{ fontSize }} style={valueStyle}>
        {value}
      </Value>
    </Container>
  );
};

export default ValueWithLabel;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled(SmallText)<StyleType>`
  color: ${({ theme }) => theme.textColor};
  font-size: ${props => props.fontSize}px;
`;

const Value = styled(SmallText)<StyleType>`
  color: ${({ theme }) => theme.textStrongColor};
  font-size: ${props => props.fontSize}px;
`;
