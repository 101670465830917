export const TERM_HINT_1_CLASS = 'term-hint-1';
export const AWAKE_TIME_HINT_1_CLASS = 'awake-time-hint-1';
export const EVENT_REPEATING_OPTIONS_HINT_1_CLASS = 'event-repeating-options-hint-1';
export const SCHEDULE_HINT_1_CLASS = 'schedule-hint-1';
export const SCHEDULE_HINT_2_CLASS = 'schedule-hint-2';
export const SCHEDULE_HINT_3_CLASS = 'schedule-hint-3';
export const STUDY_TIME_HINT_1_CLASS = 'study-time-hint-1';
export const COURSE_HINT_1_CLASS = 'course-hint-1';
export const COURSE_HINT_2_CLASS = 'course-hint-2';
export const COURSE_HINT_3_CLASS = 'course-hint-3';
export const TASK_LIST_VIEW_HINT_1_CLASS = 'task-list-view-hint-1';
export const TASK_DIALOG_HINT_1_CLASS = 'task-dialog-hint-1';
export const PLANNER_NAVIGATION_HINT_1_CLASS = 'planner-navigation-hint-1';
export const PLANNER_HINT_1_CLASS = 'planner-hint-1';
export const PLANNER_DIALOG_HINT_1_CLASS = 'planner-dialog-hint-1';
export const PLANNER_BAR_HINT_1_CLASS = 'planner-bar-hint-1';
export const COURSES_WEBSITE_LINK_HINT_1_CLASS = 'courses-website-hint-1';

export const ONBOARDING_TIMEOUT = 1800;
