import { Formats, TaskState } from 'shovel-lib/types';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import TaskCard from '../task/TaskCard';
import { dueDatePreview } from '../../utils/taskUtils';
import TaskStatus from '../task/TaskStatus';
import { formatMomentDateAs, toMomentDate } from 'shovel-lib/utils/timeUtils';
import { taskActions } from '../../state/task';
import styled, { withTheme } from 'styled-components';
import PileTask from '../pile/components/PileTask';
import { DraggingTask } from '../../state/calendar/types';
import { startDraggingTask, stopDraggingTask } from '../../state/calendar/actions';
import { RootState } from '@state/rootReducer';
import { settingsSelectors } from '@state/settings';
import { calendarStylingOptions } from '@utils/calendar/calendarUtils';
import { bindActionCreators, Dispatch } from 'redux';

type PropsFromDispatch = {
  setTasksPriority: typeof taskActions.setTasksPriority.request;
};
type Props = {
  userSettings: any;
  theme: any;
  task: any;
  openManageTask: typeof taskActions.openManageTaskDialog;
  closePopup: Function;
  className?: any;
  startDraggingTask: typeof startDraggingTask;
  stopDraggingTask: typeof stopDraggingTask;
  draggingTask?: DraggingTask;
};

const CalendarTask: FC<Props & PropsFromDispatch> = ({
  task,
  openManageTask,
  closePopup,
  className,
  stopDraggingTask,
  startDraggingTask,
  draggingTask,
  theme,
  setTasksPriority,
  userSettings
}) => {
  const {
    taskId,
    due,
    type,
    courseName,
    title,
    subtitle,
    colorHex,
    state,
    timePlanned,
    timeNeed,
    duration,
    daysToStartAhead,
    categoryName,
    emoji,
    priority,
    readingSourceName
  } = task;
  const duePreview = dueDatePreview(due, state);
  const start =
    due && daysToStartAhead > 0
      ? formatMomentDateAs(
          toMomentDate(due)
            .clone()
            .subtract(daysToStartAhead, 'day'),
          Formats.MONTH_DAY_FORMAT
        )
      : '';

  const hasSidebar = userSettings.plannedTaskEventStyle.sidebar;
  const backgroundColor = calendarStylingOptions(theme)[userSettings.plannedTaskEventStyle.style].backgroundFunction(
    colorHex || theme.randomTaskColor
  );

  const changeTaskPriority = value => setTasksPriority({ taskIds: [taskId], priority: value });

  return state !== TaskState.COMPLETED ? (
    <StyledPileCard
      key={taskId}
      task={{ ...task, due: toMomentDate(task.due) }}
      state={state}
      onPress={() => {
        openManageTask(task);
        closePopup();
      }}
      startDraggingTask={data => {
        startDraggingTask(data);
        closePopup();
      }}
      stopDraggingTask={stopDraggingTask}
      dueDateFormat={Formats.DATE_TIME_FORMAT}
      draggingTask={draggingTask}
      isPile={false}
      changePriority={changeTaskPriority}
    />
  ) : (
    <CustomCard
      hasSidebar={hasSidebar}
      backgroundColor={backgroundColor}
      className={className}
      due={duePreview}
      start={start}
      colorHex={colorHex}
      type={type}
      duration={duration}
      cushionComponent={<TaskStatus {...{ state, timeNeed, due }} />}
      course={courseName}
      title={title}
      subtitle={subtitle}
      onClick={() => {
        openManageTask(task);
        closePopup();
      }}
      isCompleted={state === TaskState.COMPLETED}
      styles={{ cursor: 'pointer' }}
      timeNeed={timeNeed}
      timePlanned={timePlanned}
      categoryName={categoryName}
      categoryEmoji={emoji}
      readingSourceName={readingSourceName}
      hasNoActions
      priority={priority || 0}
      changePriority={changeTaskPriority}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setTasksPriority: taskActions.setTasksPriority.request }, dispatch);

const mapStateToProps = (state: RootState) => ({ userSettings: settingsSelectors.getUserSettings(state) });

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(CalendarTask));

const CustomCard = styled(TaskCard)`
  min-width: 260px;
  box-sizing: border-box;
  margin: 5px 0;
  &:hover {
    box-shadow: 0px 2px 2px ${props => props.theme.boxShadow}, 0px 0px 2px ${props => props.theme.boxShadow};
  }
`;

const StyledPileCard = styled(PileTask)`
  min-width: 260px;
  > *:first-child {
    margin: 5px 0;
    &:hover {
      box-shadow: 0px 2px 2px ${props => props.theme.boxShadow}, 0px 0px 2px ${props => props.theme.boxShadow};
    }
  }
`;
