import { ApiResponse } from 'apisauce';
import { eventsNetwork, network } from './network';

const basePath = (semesterId: number) => `/semesters/${semesterId}/ics`;

export interface IcsApi {
  checkForIcsUpdates: (semesterId: number, defaultDaysAhead: number) => Promise<ApiResponse<{ [key: string]: any[] }>>;
  parseIcsFeed: (
    semesterId: number,
    data: { url: string; timezone: string }
  ) => Promise<ApiResponse<{ [key: string]: any[] }>>;
  unsyncCourseFromLMS: (semesterId: number, courseId: number) => Promise<ApiResponse<any>>;
}

// NOTE: This hits evnet-service only - watch out NOT to use basePath
export const parseIcsFeed = (semesterId: number, data: { url: string; timezone: string }) =>
  eventsNetwork.get<{ [key: string]: any[] }>(`/ics/${semesterId}/parse`, data);

export const checkForIcsUpdates = (semesterId: number, defaultDaysAhead: number) =>
  network.get<{ [key: string]: any[] }>(`${basePath(semesterId)}/check-updates`, { defaultDaysAhead });

export const unsyncCourseFromLMS = (semesterId: number, courseId: number) =>
  network.delete<any>(`semesters/${semesterId}/courses/${courseId}/unsync-lms`);

export const icsApi: IcsApi = {
  checkForIcsUpdates,
  parseIcsFeed,
  unsyncCourseFromLMS
};
