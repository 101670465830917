import React from 'react';
import { operatingSystem, OS } from '../screenUtils';

const ScrollerStyle = React.lazy(() => import('./ScrollerStyle'));

const ConditionalLoading: React.FC = ({ children }) => (
  <>
    <React.Suspense fallback={() => null}>{operatingSystem !== OS.MAC && <ScrollerStyle />}</React.Suspense>
    {children}
  </>
);

export default ConditionalLoading;
