import styled, { css } from 'styled-components';
import { textOverflowStyle } from '../../components/common/layoutUtils';
import { fontSizes } from '../typography';
import colors from '../colors';
import t from '../../i18n/t';
import { isTouchScreen } from '../screenUtils';

export const PassedOverlay = styled.div`
  position: absolute;
  top: -1px;
  bottom: -3px;
  left: -8px;
  right: -3px;
  opacity: 0.5;
  background: ${props => props.theme.backgroundDark};
`;

const calendarToolbarStyles = css<{
  now: string;
  nextButtonLabel?: string;
  previousButtonLabel?: string;
  cyclicDayCount: number;
}>`
  .fc-toolbar {

    &.fc-header-toolbar {
      margin-bottom: 0 !important;
      height: 40px;
    }

    .fc-toolbar-chunk {
      z-index: 10;
      display: flex;
      flex-direction: row;
      align-items: center;

      .fc-button {
        color: ${({ theme }) => theme.textStrongColor} !important;
        margin: 0;
        border: 0;
        padding: 3px;
        background: transparent !important;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        box-shadow: none !important;

        &:disabled {
            color: ${({ theme }) => theme.captures} !important;
        }

        &:not([disabled]):hover {
          background: ${({ theme }) => theme.backgroundDark} !important;
        }

        &.fc-prev-button,
        &.fc-next-button {
          border: 0 !important;
          color: ${({ theme }) => theme.textStrongColor} !important;
          padding: 2px !important;
        }

        .fc-icon {
          font-size: 16px;
          font-weight: bold;
        }
      }

      h2 {
        font-size: 12px;
        font-weight: bold;
        min-width: 120px;
        color: ${({ theme }) => theme.textColor};
        margin-left: 36px;
      }
    }
  }

  &:not(.mobile-calendar) {

    .fc-toolbar {

      .fc-toolbar-chunk {

        .fc-prev-button,
        .fc-next-button,
        .fc-today-button {
          position: relative;

          &:hover {
            &:after {
              width: max-content;
              position: absolute;
              bottom: calc(-100% - 26px);
              display: block;
              padding: 8px 16px;
              background: ${({ theme }) => theme.textStrongColor};
              border-radius: 5px;
              color: ${({ theme }) => theme.tooltipTextColor};
              font-size: 11px;
            }

            &:before {
              content: '';
              height: 10px;
              width: 10px;
              position: absolute;
              background: ${({ theme }) => theme.textStrongColor};
              transform: rotate(135deg);
              margin: -5px;
              z-index: -1;
              box-shadow: none;
              border-radius: 1px;
              bottom: calc(-100% + 5px);
              left: 50%;
            }
          }
        }

        .fc-today-button:hover:after {
          content: '${t.TODAY}, ${props => props.now}';
          right: 0;
        }
      }
    }

    &.timeGridWeek-view {
      .fc-prev-button:hover:after {
        content: '${t.PREVIOUS} ${t.WEEK.toLowerCase()}';
      }

      .fc-next-button:hover:after {
        content: '${t.NEXT} ${t.WEEK.toLowerCase()}';
      }
    }

    &.cyclicWeek-view {
      .fc-prev-button:hover:after {
        content: '${props => props.previousButtonLabel || t.PREVIOUS}';
      }

      .fc-next-button:hover:after {
        content: '${props => props.nextButtonLabel || t.NEXT}';
      }
    }

    &.dayGridMonth-view {
      .fc-prev-button:hover:after {
        content: '${t.PREVIOUS} ${t.MONTH.toLowerCase()}';
      }

      .fc-next-button:hover:after {
        content: '${t.NEXT} ${t.MONTH.toLowerCase()}';
      }
    }
  }

  &.listDay-view {
    .fc-prev-button:hover:after {
      content: '${t.PREVIOUS} ${t.DAY}';
    }
    .fc-next-button:hover:after {
      content: '${t.NEXT} ${t.DAY}';
    }

    @media print {
      height: unset;
      min-height: unset;
      flex: unset;
    }
  }
`;

const calendarGeneralStyles = css`
  height: 100%;

  .fc-media-screen .fc-timegrid-cols > table {
    height: 100%;
  }

  .fc-scrollgrid {
    border: 0 !important;
  }

  .fc-timegrid-slots {
    td.fc-timegrid-slot.fc-timegrid-slot-label {
      vertical-align: top;
      border-top: none;
    }
    tr:not(:first-child) div.fc-timegrid-slot-label-frame {
      margin-top: -10px;
    }
  }

  .fc-timegrid-slot-label-cushion {
    font-size: 12px !important;
    font-weight: bold;
    color: ${({ theme }) => theme.textColor};
  }

  .fc-timeGridWeek-view,
  .fc-cyclicWeek-view {
    .fc-daygrid-day.fc-day-today,
    .fc-timegrid-col.fc-day-today {
      background-color: transparent !important;
    }
    .fc-timegrid-col.fc-day {
      border-right-width: 0;
      &.fc-day-today,
      &.fc-day-today + .fc-timegrid-col.fc-day {
        border-color: ${colors.primaryPurple} !important;
      }
    }
  }

  &.dayGridMonth-view,
  &.timeGridWeek-view,
  &.listDay-view,
  &.cyclicWeek-view {
    padding: 0 16px;
  }

  &:not(.dayGridMonth-view) .fc-daygrid-event {
    margin: 1px 0px;
  }

  .fc-daygrid-more-link {
    font-size: 10px !important;
    font-family: 'Quicksand', sans-serif;
    color: ${colors.primaryPurple};
    font-weight: bold;
  }

  .more-options {
    background-color: inherit;
    .fc-daygrid-more-link {
      color: inherit;
    }
  }

  .fc-popover {
    border-radius: 5px;
    border: none;

    .fc-popover-header {
      background-color: transparent;
      padding: 10px;

      .fc-popover-title {
        font-size: 12px;
        font-weight: bold;
        color: ${({ theme }) => theme.textStrongColor};
      }

      .fc-popover-close.fc-icon.fc-icon-x {
        font-weight: bold;
        font-size: 13px;
        color: ${({ theme }) => theme.textStrongColor};
      }
    }

    .fc-popover-body {
      padding-top: 0 !important;

      .fc-daygrid-event {
        margin-top: 2px;
      }
    }
  }

  .fc-daygrid-day-bottom {
    text-align: center;
  }

  .fc-timegrid-now-indicator-line {
    border-color: ${colors.negative} !important;
  }

  &.awake-mode .fc-timegrid-body .fc-timegrid-slot-lane {
    border-color: rgba(63, 78, 97, 0.05) !important;
    background-color: rgba(63, 78, 97, 0.4) !important;
  }
`;

const calendarHeaderStyles = css`
  .fc-col-header {
    .fc-col-header-cell.fc-day {
      font-size: 12px;
      border-bottom: 0;
      border-color: ${({ theme }) => theme.borderColor};
      padding: 4px;
      background-color: ${({ theme }) => theme.backgroundDark};
      border-right-width: 0;
      &:nth-child(2):not(.fc-day-today) {
        border-left-width: 0;
      }

      &.fc-day-today,
      &.fc-day-today + .fc-col-header-cell.fc-day {
        border-color: ${colors.primaryPurple};
      }
    }

    .fc-timegrid-axis {
      border: 0;
    }
  }

  .fc-timeGridWeek-view,
  .fc-cyclicWeek-view {
    .fc-timegrid-slot.fc-timegrid-slot-label {
      border-right: none;
    }
    .fc-timegrid-slot.fc-timegrid-slot-lane {
      border-left: none;
    }

    .fc-timegrid-col.fc-timegrid-axis,
    .fc-timegrid-axis.fc-scrollgrid-shrink {
      border-right-width: 0;
    }
    .fc-daygrid-day.fc-day {
      padding-top: 20px;
      &.fc-day-today,
      &.fc-day-today + .fc-daygrid-day.fc-day {
        border-color: ${colors.primaryPurple} !important;
      }
      border-right-width: 0;
      &:nth-child(2):not(.fc-day-today) {
        border-left-width: 0;
      }
      .fc-daygrid-event-harness {
        margin-top: 5px !important;
      }
    }
  }

  .task-event,
  .custom-event,
  .google-event {
    cursor: pointer;
  }

  .fc-dayGridMonth-view {
    .fc-daygrid-day-number {
      color: ${props => props.theme.textStrongColor};
      display: block;
      margin: 2px auto;
      text-align: center;
      padding: 2px;
      width: 20px;
      border-radius: 50%;
      font-size: 13px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 600;
      float: none !important;
    }

    .fc-daygrid-day.fc-day-today {
      background-color: transparent !important;
    }

    .fc-day-today .fc-daygrid-day-number {
      background-color: ${colors.primaryPurple};
      color: white !important;
    }

    .fc-daygrid-day-top {
      justify-content: center;
    }

    .planned-task-event {
      padding: 0 !important;
      border: none;
      .fc-event-main {
        padding: 1px 3px;
        border-style: solid;
        border-radius: 5px;
        border-color: inherit;
      }
    }
  }

  .fc {
    .fc-daygrid-body-natural .fc-daygrid-day-events {
      margin-bottom: 0;
    }

    .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
      min-height: 0;
    }

    .fc-timegrid-divider {
      padding: 0;
    }
  }

  .fc-day-today a.fc-col-header-cell-cushion {
    color: ${colors.primaryPurple} !important;
  }
  .fc-day:not(.fc-day-today) a.fc-col-header-cell-cushion {
    color: ${props => props.theme.textStrongColor} !important;
  }
`;

const studyTimeModeStyled = css`
  .study_time-mode .commitment-event,
  .study_time-mode .commute-before,
  .study_time-mode .commute-after,
  .study_time-mode .custom-event {
    border-color: ${({ theme }) => theme.textColor} !important;
    color: ${({ theme }) => theme.textColor} !important;
  }

  &.study_time-mode .commitment-event:not(.edit-event),
  &.study_time-mode .commute-before:not(.edit-event),
  &.study_time-mode .commute-after:not(.edit-event),
  &.study_time-mode .custom-event:not(.edit-event) {
    background-color: ${({ theme }) => theme.calendarGrayColor} !important;
    * {
      color: ${({ theme }) => theme.textColor} !important;
    }
  }
  &.study_time-mode .planned-task-event * {
    color: ${({ theme }) => theme.textColor} !important;
  }

  &.study_time-mode .commitment-event:not(.edit-event),
  &.study_time-mode .commute-before:not(.edit-event):not(.custom-event-commute),
  &.study_time-mode .commute-after:not(.edit-event):not(.custom-event-commute),
  &.study_time-mode .planned-task-event {
    border-color: ${({ theme }) => theme.textColor} !important;
  }

  &.study_time-mode .commute-before.custom-event-commute:not(.edit-event),
  &.study_time-mode .commute-after.custom-event-commute:not(.edit-event),
  &.study_time-mode .custom-event:not(.edit-event) {
    border-color: ${({ theme }) => theme.calendarGrayColor} !important;
  }
`;

const weekEventStyles = css`
  .fc-timeGridWeek-view,
  .fc-cyclicWeek-view {
    .fc-timegrid-event .fc-event-main {
      padding: 0;
    }

    .commitment-event,
    .custom-event,
    .awake-time-event,
    .planned-task-event,
    .google-event {
      padding: 1px 3px 3px 5px;
    }

    .planned-task-event {
      &:hover .fc-event-time + .priority-flag {
        display: none;
      }
    }

    .fc-daygrid-event {
      padding: 1px 3px 1px 5px;
      overflow: hidden;
      .fc-event-title {
        display: block;
        text-overflow: ellipsis;
      }
      .fc-event-main-frame {
        width: 100%;
      }
    }

    .fc-event-main-frame {
      overflow: hidden;
    }

    .task-event,
    .planned-task-event {
      .fc-event-course {
        color: ${props => props.theme.textStrongColor};
        font-size: ${fontSizes.calendarEventTitle};
        font-weight: bold;
        word-break: break-word;
        max-height: 100%;
        overflow: hidden;
      }
      .fc-event-title {
        font-size: ${fontSizes.calendarEventTitle};
        display: flex;
        flex-shrink: 0;
      }
    }

    .commitment-event .fc-event-title,
    .custom-event .fc-event-title,
    .awake-time-event .fc-event-title,
    .google-event .fc-event-title {
      font-size: ${fontSizes.calendarEventTitle};
      font-weight: bold;
      display: flex;
      flex-shrink: 0;
    }

    .fc-timegrid-event {
      &.has-commute-before:not(.fc-daygrid-event) {
        border-radius: 0 0 4px 4px;
        border-top: 0;
        margin-top: -1px;
        box-shadow: 0 2px 2px ${props => props.theme.boxShadow}, 0 2px 2px ${props => props.theme.boxShadow} !important;
      }

      &.has-commute-after:not(.fc-daygrid-event) {
        border-radius: 4px 4px 0 0;
        border-bottom: 0;
      }

      &.has-commute-before.has-commute-after:not(.fc-daygrid-event) {
        border-radius: 0;
      }
      &.fc-timegrid-event-short .fc-event-title {
        font-size: ${fontSizes.calendarEventTitle};
        line-height: 13px;
      }
    }

    .fc-bg-event.sleep-time-event {
      opacity: 0.95;
      .fc-v-event {
        background-color: transparent;
        border-color: transparent;
        // adjust awake time link
        .fc-daygrid-more-link {
          visibility: hidden;
          text-decoration: none;
          font-style: normal;
        }
        &:hover .fc-daygrid-more-link {
          visibility: visible;
        }
        .fc-event-title {
          text-align: center;
        }
      }
    }

    .task-event.exam-event {
      padding: 2px 0 0 4px;
      border-bottom-width: 14px;

      .fc-event-time {
        flex: 1;
      }

      &:hover .fc-event-time + .priority-flag {
        display: none;
      }

      .exam-wrapper {
        max-height: 14px;
        overflow: hidden;
        justify-content: space-between;
        position: absolute;
        bottom: -14px;
        left: 0;
        right: 2px;
        .exam-text {
          flex: 1;
          min-width: 0;
          word-break: keep-all;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        i {
          flex-shrink: 0;
        }
      }

      &.fc-timegrid-event-short {
        border-bottom-width: 1px;
        border-right-width: 14px;
        .exam-wrapper {
          bottom: 4px;
          top: 0;
          left: auto;
          right: -12px;
          max-height: initial;
          max-width: 14px;
        }
        .exam-text {
          display: none;
        }
      }
    }

    .task-event:not(.exam-event) {
      background-color: transparent !important;
      position: relative;
      box-shadow: none !important;
      border: none;
      .fc-event-main {
        padding: 0;
      }
    }

    .fc-timegrid-event-harness.resizing {
      transition: bottom 0.2s ease;
    }

    .study-time-event {
      display: flex;
      opacity: 1 !important;
      border: 0;

      .fc-event-main-frame {
        display: flex;
        flex: 1;
        min-width: 0;
        justify-content: center;
        align-items: center;
      }

      .fc-event-title-container {
        height: 100%;
        justify-content: center;
      }

      .fc-event-title {
        font-size: ${fontSizes.calendarEventTitle};
        font-weight: bold;
        font-style: normal;
        top: 0;
        bottom: 0;
      }

      &:not(.fc-event-past) {
        .fc-event-title span {
          color: ${colors.primaryPurple};
        }
      }

      &.fc-event-past {
        .fc-event-title span {
          color: ${({ theme }) => theme.borderColor};
        }
      }
    }
  }
`;

const calendarEventsStyles = css`
  .fc-event-title-container {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
  }
  .fc-event-past {
    opacity: 0.8;
  }

  .study-time-past-due-title {
    display: none;
  }

  &.drag-mode {
    .fc-event .more-options {
      visibility: hidden !important;
    }
    .fc-timeGridWeek-view,
    .fc-cyclicWeek-view {
      .study-time-event.plannable {
        border: 2px solid ${colors.primaryPurple}BF !important;
        border-radius: 5px;
        background: transparent !important;
      }
      .study-time-event.plannable-overdue {
        border: 2px solid ${({ theme }) => theme.textStrongColor}BF !important;
        border-radius: 5px;
        background: transparent !important;

        .study-time-past-due-title {
          display: flex;
          color: ${({ theme }) => theme.textStrongColor} !important;
          justify-content: center;
          align-items: center;
        }
        .study-time-title {
          display: none;
        }
      }
    }
  }

  .fc-event-time {
    font-size: ${fontSizes.calendarEventTime} !important;
    font-weight: bold;
    flex-shrink: 0;
  }

  .holiday-event {
    opacity: 1 !important;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;

    .fc-event-title {
      font-size: ${fontSizes.calendarEventTitle};
      font-weight: 600;
      line-height: 1.5;
    }

    .fc-event-main {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .commitment-event,
  .custom-event,
  .planned-task-event,
  .awake-time-event,
  .google-event {
    min-height: 16px;
  }

  .fc-event:not(.custom-event):not(.custom-event-commute) .fc-event-title-container {
    color: ${props => props.theme.textStrongColor};
  }

  .commitment-event,
  .commute-before,
  .commute-after,
  .custom-event,
  .task-event,
  .planned-task-event,
  .awake-time-event,
  .google-event {
    transition-property: color, background-color, border-color, border-width, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    border-radius: 3px;
  }

  .fc-event-main {
    transition: color 0.2s ease;
  }

  .custom-event,
  .custom-event-commute {
    &:hover {
      filter: saturate(150%) brightness(95%);
    }
  }
  .commitment-event,
  .planned-task-event,
  .exam-event,
  .google-event {
    &:hover {
      filter: saturate(170%) brightness(95%);
    }
  }

  .google-event.grayed,
  .custom-event.grayed,
  .commitment-event.grayed,
  .commute-before.grayed,
  .commute-after.grayed {
    border-color: ${({ theme }) => theme.textColor} !important;
    color: ${({ theme }) => theme.textColor} !important;
  }

  .planned-task-event.grayed > div {
    border-color: ${({ theme }) => theme.textColor} !important;
  }

  .commute-before.grayed rect,
  .commute-after.grayed rect {
    fill: ${({ theme }) => theme.textColor};
  }

  .commute-before {
    border-radius: 4px 4px 0 0 !important;
    border-bottom: 0;
    box-shadow: 1px -1px 3px -1px ${props => props.theme.boxShadow}, -1px -1px 1px -1px ${props => props.theme.boxShadow} !important;
    .fc-event-main .fc-event-time {
      font-size: ${fontSizes.calendarEventTime};
    }
  }

  .commute-after {
    border-radius: 0 0 4px 4px !important;
    margin-top: -1px;
    border-top: 0;
    box-shadow: 1px 1px 2px 0 ${props => props.theme.boxShadow}, -1px 1px 2px 0 ${props => props.theme.boxShadow} !important;
    .fc-event-main .fc-event-time {
      font-size: ${fontSizes.calendarEventTime};
    }
  }

  .fc-location {
    width: 100%;
    ${textOverflowStyle};
    font-weight: 500;
    font-size: 11px;
    line-height: 1;
    margin-bottom: 2px;
    transition: background-color 0.2s ease;
    > i {
      transition: background-color 0.2s ease;
    }

    i {
      vertical-align: middle;
      margin-right: 2px;
    }
    a {
      color: inherit;
    }
  }

  .fc-description {
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    display: block;
    overflow: hidden;
    flex: 1;
    min-width: 0;

    a {
      color: inherit;
      word-break: break-all;
    }
  }

  .fc-timegrid-event-short,
  .fc-daygrid-event {
    .fc-location,
    .fc-description {
      display: none;
    }
  }

  ${!isTouchScreen &&
    `
    .fc-event {
      .more-options {
        transition: opacity 0.2s ease;
        opacity: 0;
      }

      &:hover {
        .more-options {
          opacity: 1;
        }
      }
    }
  `};

  .planned-task-event.fc-timegrid-event-short {
    padding-right: 0 !important;
    .icon-wrapper {
      height: auto;
      border-radius: 3px 0 0 3px !important;
      bottom: -4px;
    }
  }

  .planned-task-event,
  .exam-event {
    .fc-event-course {
      width: 100%;
      ${textOverflowStyle};
    }
  }

  .emoji-mart-emoji {
    display: flex;
    margin-right: 3px;
  }

  .fc-daygrid-block-event .fc-event-time,
  .fc-daygrid-block-event .fc-event-title {
    padding: 0;
  }

  .fc-daygrid-event {
    .fc-event-main {
      display: flex;
    }

    &.commitment-event,
    &.planned-task-event,
    &.study-time-event,
    &.custom-event,
    &.task-event,
    &.google-event {
      padding: 1px 3px 1px 5px;
      overflow: hidden;
      .fc-event-title {
        font-size: 11px;
        font-weight: bold;
      }
      .fc-event-resizer {
        align-items: center;
        margin: 0;
        &:after {
          width: 2px;
          height: 10px;
        }
        &.fc-event-resizer-start {
          margin-left: 2px;
        }
        &.fc-event-resizer-end {
          margin-right: 2px;
        }
      }
    }

    &.custom-event {
      padding-left: 8px;
    }

    .fc-event-time {
      font-size: ${fontSizes.calendarEventTime} !important;
    }

    &.fc-dragging {
      font-size: 11px;
      font-family: 'Quicksand', sans-serif;
      font-weight: bold;
    }
  }

  .awake-time-event .fc-event-resizer,
  .awake-time-event:hover .fc-event-resizer {
    display: flex;
  }

  .commute-before,
  .commute-after {
    padding: 0;
    &:not(.custom-event-commute) .fc-event-time {
      color: ${props => props.theme.textStrongColor};
    }

    .fc-event-main {
      width: 100%;

      .fc-event-main-frame {
        padding: 3px 5px;
      }
    }
  }

  .fc-dayGridMonth-view {
    .fc-daygrid-event-harness {
      margin-top: 0 !important;
    }
    .task-event,
    .planned-task-event,
    .commitment-event,
    .custom-event,
    .google-event {
      .fc-event-main-frame {
        width: 100%;
      }
      .fc-event-title {
        line-height: 14px;
        font-size: ${fontSizes.calendarEventTitle};
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .task-event,
    .planned-task-event,
    .commitment-event {
      .fc-event-time {
        color: ${props => props.theme.textStrongColor};
      }
    }
  }

  .fc-dragging {
    cursor: grabbing !important;
  }

  .commute-before,
  .commute-after {
    display: flex !important;
    align-items: center;
  }

  .study-time-event,
  .extra-time-event {
    &.grayed div span {
      color: ${({ theme }) => theme.captures} !important;
    }

    .fc-event-title {
      text-align: center !important;
    }
  }

  .planned-task-event,
  .custom-event,
  .commitment-event,
  .google-event,
  .commute-before,
  .commute-after {
    &.grayed {
      div,
      span,
      i {
        color: ${({ theme }) => theme.textColor} !important;
      }
    }
  }

  .fc-bg-event.extra-time-event {
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1 !important;
    border: 0;
    background-color: ${props => props.theme.extraTimeColor} !important;
    color: ${props => props.theme.extraTimeTextColor} !important;

    &::after {
      opacity: 0.7 !important;
    }

    div {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;

      span {
        font-size: 10px;
        font-weight: bold;
      }
    }

    &.fc-event-past,
    &.grayed {
      background-color: ${props => props.theme.extraTimeColor}80 !important;
      color: ${({ theme }) => theme.captures} !important;
    }
  }

  .fc-event-main-col .fc-event-container {
    width: 98% !important;
  }
`;

export const calendarStyles = css<{
  now: string;
  nextButtonLabel?: string;
  previousButtonLabel?: string;
  cyclicDayCount: number;
}>`
  ${calendarGeneralStyles};

  ${calendarHeaderStyles};

  ${calendarToolbarStyles};

  ${studyTimeModeStyled};

  ${calendarEventsStyles};

  ${weekEventStyles};

  .fc-popover {
    background-color: ${({ theme }) => theme.background};
    box-shadow: 0 2px 2px ${props => props.theme.boxShadow}, 0 0 2px ${props => props.theme.boxShadow} !important;
    .task-event,
    .planned-task-event,
    .commitment-event {
      .fc-event-title {
        color: ${props => props.theme.textStrongColor};
      }
    }
  }

  .fc-popover .fc-header .fc-event-title {
    color: ${props => props.theme.textColor};
  }

  td {
    border-color: ${({ theme }) => theme.calendarGrayColor} !important;
  }

  @media print {
    td {
      border-color: transparent !important;
    }
  }

  .fc-day.fc-widget-content.fc-disabled-day {
    background-color: ${props => props.theme.borderColor} !important;
    opacity: 0.5;
  }

  .fc-day-grid .fc-day:not(.fc-disabled-day) {
    background-color: ${({ theme }) => theme.background};
  }

  .fc-row.fc-widget-header {
    border-bottom: 1px solid ${({ theme }) => theme.calendarGrayColor} !important;
  }

  .fc-row.fc-week {
    border-bottom: 1px solid ${({ theme }) => theme.calendarGrayColor} !important;
  }
  .fc-widget-header {
    border-color: ${({ theme }) => theme.calendarGrayColor} !important;
  }

  .study-time-event:not(.grayed):not(.fc-event-past) {
    background-color: ${props => props.theme.studyTimeColor} !important;
  }

  .study-time-event.grayed,
  .study-time-event.fc-event-past {
    background-color: ${props => props.theme.background} !important;
    &.fc-daygrid-event {
      border-color: ${props => props.theme.captures} !important;
    }
  }

  .planned-task-event.fc-event-past {
    color: ${({ theme }) => theme.captures} !important;
  }

  .custom-event.grayed:not(.edit-event),
  .commitment-event.grayed:not(.edit-event),
  .commute-before.grayed:not(.edit-event),
  .commute-after.grayed:not(.edit-event),
  .google-event.grayed:not(.edit-event) {
    background-color: ${({ theme }) => theme.calendarGrayColor} !important;
    * {
      color: ${({ theme }) => theme.textColor} !important;
    }
  }

  .commitment-event.grayed:not(.edit-event),
  .commute-before.grayed:not(.edit-event):not(.custom-event-commute),
  .commute-after.grayed:not(.edit-event):not(.custom-event-commute) {
    border-color: ${({ theme }) => theme.textColor} !important;
  }

  .custom-event.grayed:not(.edit-event),
  .commute-before.custom-event-commute.grayed:not(.edit-event),
  .commute-after.custom-event-commute.grayed:not(.edit-event) {
    border-color: ${({ theme }) => theme.calendarGrayColor} !important;
  }

  .google-event.grayed > div {
    background-color: ${({ theme }) => theme.calendarGrayColor} !important;
  }

  .fc-daygrid-event:not(.task-event),
  .fc-timegrid-event:not(.commute-after):not(.commute-before) {
    min-width: 20px;
    box-shadow: 0 2px 2px ${props => props.theme.boxShadow}, 0 0 2px ${props => props.theme.boxShadow};
  }
`;

export const eventResizerCss = css`
  ${!isTouchScreen
    ? `.fc-event {
      .fc-event-resizer {
        text-align: center;
        margin-bottom: -1px;
        margin-top: 5px;
        cursor: row-resize;
        justify-content: center;
        &:after {
          content: "";
          opacity: 0.8;
          height: 2px;
          width: 30px;
          border-radius: 5px;
          background: currentColor;
          display: block;
        }
     }
      &:hover .fc-event-resizer {
        display: flex;
      }
    }
  `
    : `.fc-event-resizer-start {
          left: 95% !important;
       }
       .fc-event-resizer-end {
          left: 5% !important;
       }
   `}
`;
