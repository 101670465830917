import styled, { css } from 'styled-components';
import AutosizeInput from 'react-input-autosize';

import colors from '../../../utils/colors';
import { RegularTextInput, SmallText } from '../../../utils/typography';
import { AlignType } from '../../../state/common/utils';
import { Row } from '../layoutUtils';

export const INPUT_BOTTOM_MARGIN = '15px';
export const INLINE_INPUT_BOTTOM_MARGIN = '11px';

export const INPUT_HORIZONTAL_PADDING = 9;
export const INPUT_VERTICAL_PADDING = 9;
export const NO_LABEL_INPUT_VERTICAL_PADDING = 7;
export const INLINE_INPUT_VERTICAL_PADDING = 6;
export const INLINE_INPUT_HORIZONTAL_PADDING = 8;
export const INPUT_LABEL_HEIGHT = 14;

export const INLINE_INPUT_PADDING = '4px 8px';
export const INLINE_DATE_INPUT_PADDING = '3px 5px';
export const INLINE_TEXT_AREA_PADDING = '8px 10px';
export const INPUT_LABEL_PADDING = '10px';
export const DATE_INPUT_PADDING = '9px 10px 8px 10px';
export const DATE_INPUT_LABEL_PADDING = '22px 35px 10px 10px';

export const Error = styled(SmallText)<{ align?: AlignType; inline?: boolean }>`
  position: absolute;
  ${props => !props.inline && `left: ${INPUT_HORIZONTAL_PADDING}px`};
  z-index: 1;
  top: 100%;
  margin: 0;
  min-height: 18px;
  width: 100%;
  white-space: nowrap;
  color: ${colors.negative};
  ${props => props.align && `text-align: ${props.align};`}
  ${props =>
    props.inline &&
    `margin-top: 0;
    line-height: 1;
    min-height: 0;
`};
`;

const commonInputStyle = css<any>`
  outline: none;
  border: 0;
  display: flex;
  flex: 1;
  padding: 0;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  box-sizing: border-box;
  -webkit-text-fill-color: ${props => props.color || props.theme.textStrongColor};
  color: ${props => props.color || props.theme.textStrongColor};
  &::placeholder,
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.placeholder} !important;
    -webkit-text-fill-color: ${({ theme }) => theme.placeholder};
    line-height: 1.35 !important;
    opacity: 1;
  }
  &:-webkit-autofill {
    -webkit-background-clip: text;
  }
  &:disabled {
    ${props =>
      !props.discardDisabledStyles &&
      `
    opacity: 1;
    cursor: not-allowed;
    color: ${props.theme.disabledTextColor} !important;
    -webkit-text-fill-color: ${props.theme.disabledTextColor};
    &::placeholder,
    &::-webkit-input-placeholder {
      color: ${props.theme.captures} !important;
      -webkit-text-fill-color: ${props.theme.captures} !important;
    }
    `};
  }
`;

export const FormInput = styled(RegularTextInput)<{ align?: AlignType; discardDisabledStyles?: boolean; color?: string }>`
  ${commonInputStyle};
  text-align: ${props => props.align || AlignType.LEFT};
  line-height: 1;
  z-index: 1;
  background-color: transparent;
  transition: all 0.2s ease;

  &:disabled {
    ${props =>
      !props.discardDisabledStyles &&
      `
    cursor: not-allowed;
    &::placeholder {
      color: ${props.theme.placeholder};
      -webkit-text-fill-color: ${props.theme.placeholder};
    }`};
  }
`;

export const AutosizeFormInput = styled(AutosizeInput)<{ align?: AlignType; disabled?: boolean }>`
  input {
    ${commonInputStyle};
    font-family: Quicksand;
    font-weight: 600;
    font-size: 13px;
    max-width: 100%;
    background: transparent;
    text-align: ${props => props.align || AlignType.LEFT};
    text-overflow: ellipsis;
    line-height: 1;
    z-index: 1;
  }
`;

export const BareWrapper = styled(Row)<any>`
  position: relative;
  ${props => props.inline && 'display: inline-flex'};
  cursor: default;
`;

export const DatePickerStyles = css`
  .react-datepicker {
    background-color: ${({ theme }) => theme.background} !important;
    box-shadow: 0 6px 20px ${({ theme }) => theme.boxShadow};
  }
  .react-datepicker__input-container > input,
  .react-datepicker-time__header {
    color: ${({ theme }) => theme.textStrongColor};
  }
  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.captures};
  }
  .react-datepicker__header {
    font-family: 'Quicksand', sans-serif;
    background-color: ${({ theme }) => theme.background} !important;
    padding: 0;
    border: 0 !important;
  }
  .react-datepicker__triangle,
  .react-datepicker__triangle::before {
    border-bottom-color: ${({ theme }) => theme.background} !important;
    border-top-color: ${({ theme }) => theme.background} !important;
  }
  .react-datepicker__time {
    color: ${({ theme }) => theme.textStrongColor} !important;
    background-color: ${({ theme }) => theme.background};
  }
  .react-datepicker__day-name {
    color: ${({ theme }) => theme.textColor} !important;
  }
  .react-datepicker__day:not(.react-datepicker__day--selected):not(.react-datepicker__day--highlighted):not(.react-datepicker__day--disabled) {
    color: ${({ theme }) => theme.textStrongColor};
  }
  .react-datepicker__time-container {
    background-color: ${({ theme }) => theme.background};
    border-left: 1px solid ${({ theme }) => theme.borderColor};
  }

  .react-datepicker__time-list-item:not(.react-datepicker__time-list-item--selected):hover,
  .react-datepicker__day:not(.react-datepicker__day--selected):not(.react-datepicker__day--highlighted):not(.react-datepicker__day--disabled):hover {
    background-color: ${({ theme }) => theme.planOverlayColor} !important;
    color: ${colors.primaryPurple} !important;
  }

  .react-datepicker__time-list-item--selected {
    background-color: ${colors.primaryPurple} !important;
    color: ${colors.white} !important;
  }
`;
