import React, { PureComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';

import { RootState } from '../../state/rootReducer';
import { getHelpUsDialogState } from '../../state/settings/selectors';
import { closeHelpUsDialog } from '../../state/settings/actions';
import { commonPopupCSS, helpUsModalStyle } from '../../utils/popupUtils';
import { Column, ConfirmAndCancelButtons } from '../common/layoutUtils';
import styled from 'styled-components';
import { LargeText, RegularText } from '../../utils/typography';
import t from '../../i18n/t';
import Button from '../common/buttons/Button';
import TextareaInput from '../common/inputs/text/TextareaInput';
import { DETAILS_MAX_LENGTH } from '../../utils/constants/validations';
import ButtonWithLoader from '../common/buttons/ButtonWithLoader';

type PropsFromState = {
  open: boolean;
  confirmAction: any;
  options: any;
  message?: string;
  loadingAction?: any;
};

type PropsFromDispatch = {
  closeHelpUsDialog: typeof closeHelpUsDialog;
};

type Props = PropsFromState & PropsFromDispatch;

type State = {
  reasons: string[];
  experience: string;
};

class HelpUsDialog extends PureComponent<Props, State> {
  state: State = {
    reasons: [],
    experience: ''
  };

  selectReason = (value: string) => {
    const reason = this.state.reasons.find(r => r === value);
    let list;
    if (reason) {
      list = this.state.reasons.filter(r => r !== value);
    } else {
      // @ts-ignore
      list = this.state.reasons.concat(value);
    }
    this.setState({ reasons: list });
  };

  submit = () => {
    const { confirmAction, closeHelpUsDialog, message, loadingAction } = this.props;

    const data = {
      // @ts-ignore
      [message]: this.state.reasons.join(';'),
      experience: this.state.experience
    };

    confirmAction && confirmAction(JSON.stringify(data));
    this.setState({ reasons: [], experience: '' });
    if (!loadingAction) {
      closeHelpUsDialog();
    }
  };

  closeDialog = () => {
    this.setState({ reasons: [], experience: '' });
    this.props.closeHelpUsDialog();
  };

  render() {
    const { open, options, closeHelpUsDialog, message, loadingAction } = this.props;

    if (!open || !options) return null;

    const submitDisabled = this.state.experience.length === 0;

    return (
      <Wrapper>
        <Popup open={open} modal onClose={closeHelpUsDialog} contentStyle={helpUsModalStyle}>
          <Container>
            <Content>
              <Title strong>
                {t.HELP_US_IMPROVE_TITLE}
                <br />
                {t.HELP_US_IMPROVE_SUBTITLE}
              </Title>
              <div style={{ marginBottom: '20px' }}>
                <RegularText>{t.HELP_US_IMPROVE_DESCRIPTION}</RegularText>
              </div>
              <TextareaInput
                minRows={5}
                value={this.state.experience}
                onChange={e => this.setState({ experience: e.target.value })}
                placeholder={t.HELP_US_IMPROVE_PLACEHOLDER}
                maxLength={DETAILS_MAX_LENGTH}
                required={false}
              />
            </Content>
            <ConfirmAndCancelButtons>
              <Button withoutBorder onClick={this.closeDialog}>
                {t.CANCEL}
              </Button>
              {loadingAction ? (
                <ButtonWithLoader
                  onClick={this.submit}
                  action={loadingAction}
                  callback={this.closeDialog}
                  filled
                  disabled={submitDisabled}
                  text={t.SUBMIT}
                />
              ) : (
                <Button disabled={submitDisabled} onClick={() => this.submit()} filled>
                  {t.SUBMIT}
                </Button>
              )}
            </ConfirmAndCancelButtons>
          </Container>
        </Popup>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: RootState) => getHelpUsDialogState(state);

const mapDispatchProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeHelpUsDialog: closeHelpUsDialog
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchProps)(HelpUsDialog);

const Wrapper = styled(Column)`
  .popup-content {
    ${commonPopupCSS}
  }
  .popup-overlay {
    background: ${({ theme }) => theme.modalOverlayColor} !important;
  }
`;

const Container = styled(Column)`
  width: 100%;
  flex: 1;
  justify-content: space-between;
`;

const Content = styled(Column)`
  padding: 0 60px;
`;

const Title = styled(LargeText)`
  display: block;
  margin-bottom: 20px;
  text-align: center;
`;
