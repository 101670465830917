import React, { PureComponent } from 'react';
import t from '../../../i18n/t';
import EmojiPicker from '../../common/emoji/EmojiPicker.loader';
import * as courseActions from '../../../state/course/actions';
import DropDownInputEditable from '../../common/inputs/dropdown/DropDownInputEditable';
import { DEFAULT_TASK_CATEGORY_EMOJI } from '../../../utils/constants/task';
import { TaskCategoryDto } from 'shovel-lib/types';
import { bindActionCreators, Dispatch } from 'redux';
import { commonActions } from '../../../state/common';
import { connect } from 'react-redux';
import { getDDPosition } from '../../../utils/taskUtils';

type OwnProps = {
  categories: TaskCategoryDto[];
  value?: number;
  onChange: any;
  disabled?: boolean;
  remove?: any;
  courseId: number;
  className?: string;
  placeholder?: string;
  customTarget?: React.ReactElement;
  small?: boolean;
  isManageDialog?: boolean;
};

type PropsFromDispatch = {
  createCategory: typeof courseActions.createTaskCategory.request;
  updateTaskCategory: typeof courseActions.updateTaskCategory.request;
  deleteTaskCategory: typeof courseActions.deleteTaskCategory.request;
  openConfirmationDialog: typeof commonActions.openConfirmationDialog;
};

type Props = OwnProps & PropsFromDispatch;

type State = {
  editIndex: number;
  visibleEmojiPicker: number;
};

class TaskCategorySection extends PureComponent<Props, State> {
  state: State = {
    editIndex: -1,
    visibleEmojiPicker: -1
  };

  getEditOptionsForCategory = category => {
    return [
      {
        name: t.RENAME,
        icon: 'create',
        action: () => this.setState({ editIndex: category.value })
      },
      {
        name: t.CHANGE_EMOJI,
        icon: 'sentiment_satisfied_alt',
        outlinedIcon: true,
        action: () => this.setState({ visibleEmojiPicker: category.value })
      },
      {
        name: t.DELETE,
        icon: 'delete',
        outlinedIcon: true,
        action: () =>
          this.props.openConfirmationDialog({
            title: t.DELETE_TASK_CATEGORY,
            message: (
              <div>
                <div>
                  {t.DELETE_TASK_CATEGORY_CONFIRMATION} {category.icon} {category.label}!
                </div>
                <div>{t.DELETE_TASK_CATEGORY_WARNING}</div>
              </div>
            ),
            action: () => this.props.deleteTaskCategory({ id: category.value, courseId: this.props.courseId }),
            understandCheck: true,
            warning: true,
            loadingAction: courseActions.deleteTaskCategory
          })
      }
    ];
  };

  updateCategory = (value, category, data, isEmojiUpdate?: boolean) => {
    let updateValues;
    if (!isEmojiUpdate) {
      updateValues = { id: category.value, name: value, emoji: category.icon, courseId: data.courseId };
    } else {
      updateValues = { id: category.value, name: category.label, emoji: value, courseId: data.courseId };
    }
    this.props.updateTaskCategory(updateValues);
    this.setState({ editIndex: -1 });
  };

  createCategory = (name: string, data: any, onChange: any) => {
    this.props.createCategory({ name, emoji: DEFAULT_TASK_CATEGORY_EMOJI, ...data, onChange });
  };

  mapCategoryToDropdownOptions = () => {
    return this.props.categories.map((cat: TaskCategoryDto) => ({
      label: cat.name,
      value: cat.id,
      icon: cat.emoji || DEFAULT_TASK_CATEGORY_EMOJI
    }));
  };

  closeEmojiPicker = () => {
    if (this.state.visibleEmojiPicker !== -1) {
      this.setState({ visibleEmojiPicker: -1 });
    }
  };

  renderPopup = (target, category, onSelect, emoji) => {
    const { courseId, isManageDialog } = this.props;
    return (
      <EmojiPicker
        key={category.value}
        target={target}
        visible={this.state.visibleEmojiPicker === category.value}
        onSelect={onSelect}
        onClickOutside={() => {
          if (emoji !== DEFAULT_TASK_CATEGORY_EMOJI && emoji !== category.icon) {
            this.props.updateTaskCategory({ id: category.value, name: category.label, emoji, courseId });
          }
          this.closeEmojiPicker();
        }}
        controlled
        isManageDialog={isManageDialog}
      />
    );
  };

  render() {
    const {
      courseId,
      value,
      onChange,
      remove,
      disabled,
      className,
      placeholder = t.SELECT_CATEGORY,
      customTarget,
      small
    } = this.props;
    return (
      <DropDownInputEditable
        className={className}
        initialValue={value}
        placeholder={placeholder}
        customTarget={customTarget}
        options={this.mapCategoryToDropdownOptions()}
        onChange={(value, item) => {
          onChange(value, item);
          this.closeEmojiPicker();
        }}
        add={this.createCategory}
        addPlaceholder={t.CREATE_NEW_CATEGORY}
        addData={{ courseId }}
        disabled={disabled}
        remove={remove}
        editOptions={category => this.getEditOptionsForCategory(category)}
        edit={this.updateCategory}
        editIndex={this.state.editIndex}
        editTooltip={t.EDIT_TASK_CATEGORY}
        renderPopup={this.renderPopup}
        onClose={() => this.setState({ editIndex: -1 })}
        tooltip={!courseId ? t.SELECT_COURSE_OR_ACTIVITY_TOOLTIP : undefined}
        addAction={courseActions.createTaskCategory}
        small={small}
        dropdownPosition={small ? getDDPosition('catPopup') : undefined}
      />
    );
  }
}

const mapStateToDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createCategory: courseActions.createTaskCategory.request,
      updateTaskCategory: courseActions.updateTaskCategory.request,
      deleteTaskCategory: courseActions.deleteTaskCategory.request,
      openConfirmationDialog: commonActions.openConfirmationDialog
    },
    dispatch
  );

export default connect(null, mapStateToDispatch)(TaskCategorySection);
