import { createReducer } from 'typesafe-actions';

import * as actions from './actions';
import { termActions } from '../terms';

export enum CourseSource {
  CANVAS = 'CANVAS',
  // BLACKBOARD = 'BLACKBOARD',
  BRIGHTSPACE = 'BRIGHTSPACE',
  MOODLE = 'MOODLE',
  GOOGLE = 'GOOGLE',
  // SHOVEL = 'Shovel',
  MANUAL = 'Manual'
}

export type IcsUpdates = {
  timestamp?: string;
  deletedCourses: { parsedCourseName: string; shovelCourseName: string; lmsType: string; colorHex: string }[];
  addedCourses: { name: string; events: any[]; lms: string }[];
  changes: {
    parsedCourseName: string;
    shovelCourseName: string;
    colorHex: string;
    lmsType: string;
    deletedItemIds: string[];
    addedItems: any[];
    changedItems: any[];
  }[];
};

const initialState: {
  parsedIcsData?: { [key: string]: any[] };
  icsUpdatesDialog: IcsUpdates & {
    open: boolean;
    big: boolean;
  };
} = {
  parsedIcsData: undefined,
  icsUpdatesDialog: {
    open: false,
    big: false,
    deletedCourses: [],
    addedCourses: [],
    changes: []
  }
};
const reducer = createReducer(initialState)
  .handleAction([actions.parseIcsFeed.success, actions.clearIcsData], (state, action) => ({
    ...state,
    parsedIcsData: action.payload
  }))
  .handleAction(actions.closeIcsUpdatesDialog, state => ({
    ...state,
    icsUpdatesDialog: {
      ...state.icsUpdatesDialog,
      open: false,
      big: false,
      deletedCourses: [],
      addedCourses: [],
      changes: []
    }
  }))
  .handleAction(actions.triggerIcsUpdates.success, (state, { payload }) => {
    const deletedCourses = state.icsUpdatesDialog.deletedCourses.concat(payload.deletedCourses);
    const addedCourses = state.icsUpdatesDialog.addedCourses.concat(payload.addedCourses);
    const { changes } = payload;
    if (state.icsUpdatesDialog.changes.length > 0) {
      state.icsUpdatesDialog.changes.forEach(oldChanges => {
        const newChanges = changes.find(c => c.parsedCourseName === oldChanges.parsedCourseName);
        if (!newChanges) {
          changes.push(oldChanges);
        } else {
          newChanges.deletedItemIds.push(...oldChanges.deletedItemIds);
          newChanges.addedItems.push(...oldChanges.addedItems);
          newChanges.changedItems.push(...oldChanges.changedItems);
        }
      });
    }

    const hasDeleted = deletedCourses.length > 0;
    const hasAdded = addedCourses.length > 0;
    const hasChanges = changes.length > 0;

    return {
      ...state,
      icsUpdatesDialog: {
        deletedCourses,
        addedCourses,
        changes,
        timestamp: payload.timestamp || state.icsUpdatesDialog.timestamp,
        open: hasDeleted || hasAdded || hasChanges,
        big: hasDeleted || hasAdded
      }
    };
  })
  .handleAction(termActions.createBatchCourses.success, (state, action) => {
    return {
      ...state,
      icsUpdatesDialog: {
        ...state.icsUpdatesDialog,
        timestamp: new Date().toISOString()
      }
    };
  });

export default reducer;
