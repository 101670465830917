import { CalendarEvent } from 'shovel-lib/types';

// type xOutput is sent to WebView
// type xInput comes from WebView

export enum OutputType {
  PLAN_THIS_BLOCK_REQUEST = 'PLAN_THIS_BLOCK_REQUEST',
  ADD_EDIT_EVENT_REQUEST = 'ADD_EDIT_EVENT_REQUEST',
  OPEN_TASK_REQUEST = 'OPEN_TASK_REQUEST',
  SHOW_TASKS_ON_DAY_REQUEST = 'SHOW_TASKS_ON_DAY_REQUEST',
  SHOW_TASKS_BY_IDS_REQUEST = 'SHOW_TASKS_BY_IDS_REQUEST',
  PLANNED_TASK_CLICK = 'PLANNED_TASK_CLICK',
  UPDATE_RECURRING_EVENT_REQUEST = 'UPDATE_RECURRING_EVENT_REQUEST',
  SIGNED_UP = 'SIGNED_UP',
  QUICK_SETUP_COMPLETED = 'QUICK_SETUP_COMPLETED'
}

export enum InputType {
  CREATE_PLANNED_TASKS_SUCCESS = 'CREATE_PLANNED_TASKS_SUCCESS',
  CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS',
  UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS',
  DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS',
  SAVE_MANAGE_TASK_SUCCESS = 'SAVE_MANAGE_TASK_SUCCESS',
  CALENDAR_GO_TO_DATE = 'CALENDAR_GO_TO_DATE',
  DELETE_PLANNED_TASK_SUCCESS = 'DELETE_PLANNED_TASK_SUCCESS',
  CANCEL_UPDATE_RECURRING_EVENT = 'CANCEL_UPDATE_RECURRING_EVENT',
  CREATE_TASKS_SUCCESS = 'CREATE_TASKS_SUCCESS',
  DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS',
  RESIZE_PLANNED_TASK_SUCCESS = 'RESIZE_PLANNED_TASK_SUCCESS',
  MOVE_PLANNED_TASK_SUCCESS = 'MOVE_PLANNED_TASK_SUCCESS',
  MARK_AS_USED_PLANNED_TASK_SUCCESS = 'MARK_AS_USED_PLANNED_TASK_SUCCESS',
  MARK_TASK_AS_COMPLETED_SUCCESS = 'MARK_TASK_AS_COMPLETED_SUCCESS',
  MOVE_TASK_TO_DO_SUCCESS = 'MOVE_TASK_TO_DO_SUCCESS'
}

export type PlanThisBlockOutput = {
  type: OutputType.PLAN_THIS_BLOCK_REQUEST;
  payload: {
    eventInfo: {
      plannedEventId: string;
      from: string | Date;
      to: string | Date;
    };
  };
};

export type AddEditEventOutput = {
  type: OutputType.ADD_EDIT_EVENT_REQUEST;
  payload: {
    eventInfo: CalendarEvent;
  };
};

export type OpenTaskOutput = {
  type: OutputType.OPEN_TASK_REQUEST;
  payload: {
    eventInfo: {
      taskId: number;
    };
  };
};

export type ShowTasksOnDayOutput = {
  type: OutputType.SHOW_TASKS_ON_DAY_REQUEST;
  payload: {
    eventInfo: {
      date: string;
    };
  };
};

export type ShowTasksByIdsOutput = {
  type: OutputType.SHOW_TASKS_BY_IDS_REQUEST;
  payload: {
    eventInfo: {
      taskIds: number[];
    };
  };
};

export type PlannedTaskClickOutput = {
  type: OutputType.PLANNED_TASK_CLICK;
  payload: {
    eventInfo: {
      id: number;
      taskId: number;
      isTaskCompleted?: boolean;
    };
  };
};

export type UpdateRecurringEventOutput = {
  type: OutputType.UPDATE_RECURRING_EVENT_REQUEST;
  payload: {
    eventInfo: {
      event: CalendarEvent;
      originalEventStart: Date;
      revert: string;
    };
  };
};

export type SignedUpEventOutput = {
  type: OutputType.SIGNED_UP;
  payload: {
    userId: number;
    accessToken: string;
    refreshToken: string;
  };
};

export type QuickSetupCompleted = {
  type: OutputType.QUICK_SETUP_COMPLETED;
};

type OutputEvent =
  | PlanThisBlockOutput
  | AddEditEventOutput
  | OpenTaskOutput
  | ShowTasksOnDayOutput
  | ShowTasksByIdsOutput
  | PlannedTaskClickOutput
  | UpdateRecurringEventOutput
  | SignedUpEventOutput
  | QuickSetupCompleted;

const sendMessage = (props: OutputEvent) => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify(props));
};

export default {
  sendMessage
};
