import React, { PureComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { FieldArray, Formik, FormikProps, getIn } from 'formik';
import styled, { withTheme } from 'styled-components';
import { RootState } from '../../../state/rootReducer';
import { fullScreenModalStyle } from '../../../utils/popupUtils';
import { Column, ConfirmAndCancelButtons, FlexColumn, FlexRow, Row } from '../../common/layoutUtils';
import { ExtraLargeText, LargeText, RegularText, SmallText } from '../../../utils/typography';
import { CloseButton } from '../../task/manage/ManageTaskDialog.styles';
import { getIcsUpdatesDialogData } from '../../../state/ics/selectors';
import { IcsUpdates } from '../../../state/ics/reducers';
import { closeIcsUpdatesDialog } from '../../../state/ics/actions';
import TippyTooltip from '../../common/tooltip/TippyTooltip';
import Button from '../..//common/buttons/Button';
import t from '../../../i18n/t';
import RadioButton from '../../common/radioButton/RadioButton';
import ColorPickerTippy from '../../common/colorPicker/ColorPickerTippy';
import { ColorItem } from '../../common/colorPicker/ColorPicker';
import { hideAllTippies } from '../../../utils/calendar/calendarUtils';
import { NameInput, NameInputWrapper } from '../../leftSidebar/InputItem';
import { getRandomPickerColor } from '../../../utils/colorUtils';
import colors from '../../../utils/colors';
import DropDownInputEditable from '../../common/inputs/dropdown/DropDownInputEditable';
import { getCommitments } from '../../../state/calendar/selectors';
import { createBatchCourses } from '../../../state/terms/actions';
import { EmojiPreview } from '@components/common/emoji/EmojiPreview';

type PropsFromDispatch = {
  close: typeof closeIcsUpdatesDialog;
  save: typeof createBatchCourses.request;
};

type PropsFromState = {
  dialog: IcsUpdates & {
    open: boolean;
    big: boolean;
  };
  courses: any[];
};

type Props = PropsFromState & PropsFromDispatch & { theme: any };

class IcsUpdatesDialog extends PureComponent<Props> {
  state = { big: false };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (!prevProps.dialog.open && this.props.dialog.open) {
      this.setState({ big: this.props.dialog.big });
    }
  }

  renderCourseItem = (index: number, course: any, arrayHelpers: any, formProps: any) => {
    const { theme } = this.props;
    const { values, setFieldValue, setFieldTouched, handleChange, touched, errors, isValid } = formProps;

    return (
      <FlexRow key={index} style={{ marginTop: index > 0 ? 20 : 0, justifyContent: 'space-between' }}>
        <Row>
          <RadioOption
            label={''}
            checked={course.selected}
            onChange={() => setFieldValue(`courses.${index}.selected`, true)}
          />
          <RadioOption
            label={''}
            checked={course.selected === false}
            onChange={() => setFieldValue(`courses.${index}.selected`, false)}
          />
          <ColorPickerTippy
            target={color => (
              <Row style={{ margin: '0 8px' }}>
                <ColorItem color={color || course.colorHex} size={14} used={false} selected={false} borderRadius={4} />
              </Row>
            )}
            onSave={value => {
              setFieldValue(`courses.${index}.colorHex`, value);
              hideAllTippies();
            }}
            onChange={value => {
              setFieldValue(`courses.${index}.colorHex`, value);
            }}
            selectedColor={course.colorHex}
            additionalContent={color => (
              <Row style={{ width: '250px', marginRight: 10 }}>
                <NameInputWrapper
                  backgroundColor={theme.courseColorFunction(color || course.colorHex)}
                  borderColor={color || course.colorHex}
                  showLeftBorder={true}
                >
                  <NameInput
                    name={`courses.${index}.name`}
                    color={color || course.colorHex}
                    autoFocus={index === values.courses.length - 1}
                    value={course.name}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched(`courses.${index}.name`)}
                    placeholder={course.parsedName}
                    onKeyDown={e => {
                      if (isValid && index === values.courses.length - 1 && e.key === 'Enter') {
                        arrayHelpers.push({ colorHex: getRandomPickerColor(), name: '' });
                        e.preventDefault();
                      }
                    }}
                  />
                </NameInputWrapper>
                <Error>
                  {getIn(touched, `courses.${index}.name`) &&
                    getIn(errors, `courses.${index}.name`) &&
                    (values.courses.length === 1 ? t.CREATE_ONE_COURSE : getIn(errors, `courses.${index}.name`))}
                </Error>
              </Row>
            )}
          />
          <RegularText>
            {course.events.length} {t.ITEMS}
          </RegularText>
        </Row>
        {(values.courses.length > 1 || this.props.courses.length > 0) && (
          <AddToCourseDropdown
            initialValue={course.mergedWith?.id || course.mergedWith?.parsedName}
            placeholder={t.ADD_TO_EXISTING_COURSE}
            options={[
              ...this.props.courses.map(c => ({ label: c.name, value: c.id, color: c.colorHex, id: c.id })),
              ...values.courses
                .filter(c => c.parsedName !== course.parsedName && !c.mergedWith)
                .map(c => ({ label: c.name, value: c.parsedName, color: c.colorHex }))
            ]}
            remove={() => setFieldValue(`courses.${index}.mergedWith`, undefined)}
            onChange={(value, item) => {
              setFieldValue(`courses.${index}.mergedWith`, { id: item.id, parsedName: value });
              setFieldValue(`courses.${index}.selected`, true);
              values.courses.forEach((c, i) => {
                if (c.mergedWith?.parsedName && c.mergedWith?.parsedName === course.parsedName) {
                  setFieldValue(`courses.${i}.mergedWith`, { id: item.id, parsedName: value });
                }
                if (c.parsedName === value) {
                  setFieldValue(`courses.${i}.selected`, true);
                }
              });
            }}
          />
        )}
      </FlexRow>
    );
  };

  render() {
    const { dialog, close } = this.props;
    const { open, deletedCourses, addedCourses, changes } = dialog;

    const { big } = this.state;

    if (!open) return null;

    if (!big)
      return (
        //@ts-ignore
        <SmallDialog
          reference={document.body}
          arrow={false}
          noPadding
          interactive
          controlled
          position={'bottom-start'}
          zIndex={9998}
          visible={open}
          lightTheme
          content={
            <Column>
              <LargeText strong>{t.NEW_ICS_CHANGES}</LargeText>
              <RegularText>{t.ICS_CHANGES_TEXT}</RegularText>
              <ConfirmAndCancelButtons>
                <Button withoutBorder onClick={() => close()}>
                  {t.CLOSE}
                </Button>
                <Button filled onClick={() => this.setState({ big: true })}>
                  {t.VIEW_CHANGES}
                </Button>
              </ConfirmAndCancelButtons>
            </Column>
          }
          withoutDelay
        />
      );

    const actionRequired = addedCourses.length > 0;

    return (
      <Dialog
        open={open}
        contentStyle={fullScreenModalStyle}
        overlayStyle={{ overflow: 'scroll' }}
        onClose={close}
        lockScroll={false}
        closeOnEscape={!actionRequired}
        closeOnDocumentClick={!actionRequired}
      >
        <Formik
          //@ts-ignore
          initialValues={{
            courses: addedCourses.map(c => ({
              ...c,
              parsedName: c.name,
              colorHex: getRandomPickerColor(),
              selected: undefined
            }))
          }}
          enableReinitialize={true}
          onSubmit={values => {
            const courses = [...this.props.courses];
            const newCourses = values.courses.filter(c => c.selected);

            if (newCourses.length === 0) {
              this.props.close();
              return;
            }

            newCourses
              .filter((c: any) => !c.mergedWith)
              .forEach((c: any) => {
                courses.push({
                  name: c.name,
                  colorHex: c.colorHex,
                  parsedCourses: [{ parsedName: c.parsedName, type: c.lms, url: c.url }],
                  events: c.events,
                  ordinalNumber: courses.length + 1
                });
              });
            newCourses
              .filter((c: any) => !!c.mergedWith)
              .forEach((c: any) => {
                if (c.mergedWith.id) {
                  const course = courses.find(course => c.mergedWith.id === course.id);
                  course.parsedCourses.push({ parsedName: c.parsedName, type: c.lms, url: c.url });
                  course.events = (course.events || []).concat(c.events);
                } else {
                  const course: any = courses.find(course =>
                    course.parsedCourses.find(pc => !pc.id && pc.parsedName === c.mergedWith.parsedName)
                  );
                  if (course) {
                    course.parsedCourses.push({ parsedName: c.parsedName, type: c.lms, url: c.url });
                    course.events = (course.events || []).concat(c.events);
                  }
                }
              });
            this.props.save({ courses, triggerUpdate: false });
          }}
        >
          {({
            values,
            errors,
            isValid,
            touched,
            handleChange,
            setFieldTouched,
            setFieldValue,
            handleSubmit
          }: FormikProps<any>) => (
            <Wrapper>
              {!actionRequired && <CloseButton onClick={close} />}
              <Title>
                <ExtraLargeText strong style={{ marginBottom: 30 }}>
                  {t.NEW_ICS_CHANGES}
                </ExtraLargeText>
              </Title>
              <Container>
                {addedCourses.length > 0 && (
                  <Section>
                    <SectionTitle strong>{t.CONNECT_NEW_COURSES}</SectionTitle>
                    <RegularText>{t.CONNECT_NEW_COURSES_TEXT}</RegularText>
                    <OptionLabels>
                      <RegularText>{t.YES}</RegularText>
                      <RegularText>{t.NO}</RegularText>
                    </OptionLabels>
                    <FieldArray
                      name="courses"
                      render={arrayHelpers =>
                        values.courses.map((course, index) =>
                          this.renderCourseItem(index, course, arrayHelpers, {
                            values,
                            setFieldValue,
                            setFieldTouched,
                            handleChange,
                            touched,
                            errors,
                            isValid
                          })
                        )
                      }
                    />
                  </Section>
                )}
                {deletedCourses.length > 0 && (
                  <Section>
                    <SectionTitle strong>{t.ICS_DELETED_COURSES}</SectionTitle>
                    {deletedCourses.map(c => (
                      <Row style={{ marginBottom: 10, alignItems: 'flex-start' }}>
                        <EmojiPreview emoji={'⚠️'} />
                        <RegularText>
                          {c.parsedCourseName}
                          <br />
                          {t.ICS_DELETED_COURSES_WARNING} <span style={{ color: c.colorHex }}>{c.shovelCourseName}</span>.
                        </RegularText>
                      </Row>
                    ))}
                  </Section>
                )}
                {changes.length > 0 && (
                  <Section>
                    <SectionTitle strong>{t.ICS_UPDATED_ITEMS}</SectionTitle>
                    {changes.map(c => {
                      const nameChanges = c.changedItems.filter(e => e.titleChanged);
                      const descriptionChanges = c.changedItems.filter(e => e.descriptionChanged);
                      const dateChanges = c.changedItems.filter(e => e.dateChanged);
                      return (
                        <CourseChanges>
                          <RegularText>
                            <RegularText color={c.colorHex}>{c.shovelCourseName}</RegularText> ({c.parsedCourseName})
                          </RegularText>
                          {nameChanges.length > 0 && (
                            <Row>
                              <RegularText>
                                {t.NAMES_CHANGED}: {nameChanges.length} {t.ITEMS}
                              </RegularText>
                            </Row>
                          )}
                          {descriptionChanges.length > 0 && (
                            <Row>
                              <RegularText>
                                {t.DESCRIPTIONS_CHANGED}: {descriptionChanges.length} {t.ITEMS}
                              </RegularText>
                            </Row>
                          )}
                          {dateChanges.length > 0 && (
                            <Row>
                              <RegularText>
                                {t.DEADLINES_DATES_CHANGED}: {dateChanges.length} {t.ITEMS}
                              </RegularText>
                            </Row>
                          )}
                          {c.addedItems.length > 0 && (
                            <Row>
                              <RegularText>
                                {t.NEW_ITEMS}: {c.addedItems.length} {t.ITEMS}
                              </RegularText>
                            </Row>
                          )}
                          {c.deletedItemIds.length > 0 && (
                            <Row>
                              <RegularText>
                                {t.DELETED_ITEMS}: {c.deletedItemIds.length} {t.ITEMS}
                              </RegularText>
                            </Row>
                          )}
                        </CourseChanges>
                      );
                    })}
                  </Section>
                )}
              </Container>
              {!!values.courses.find(c => c.selected === undefined) ? (
                <TippyTooltip
                  target={
                    <div>
                      <DoneButton filled disabled>
                        {t.DONE}
                      </DoneButton>
                    </div>
                  }
                  content={t.COURSES_NOT_CONNECTED_TOOLTIP}
                  width={'200px'}
                />
              ) : (
                <DoneButton filled onClick={handleSubmit}>
                  {t.DONE}
                </DoneButton>
              )}
            </Wrapper>
          )}
        </Formik>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  dialog: getIcsUpdatesDialogData(state),
  ...getCommitments(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ close: closeIcsUpdatesDialog, save: createBatchCourses.request }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(IcsUpdatesDialog));

const Dialog = styled(Popup)`
  &-content {
    background: ${({ theme }) => theme.background} !important;
  }

  &-overlay {
    background: ${({ theme }) => theme.modalOverlayColor} !important;
  }
`;

const Wrapper = styled(Column)`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 30px 30px;
`;

const Title = styled(Row)`
  justify-content: center;
  white-space: pre;
`;

const Container = styled(FlexColumn)`
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
`;

const CourseChanges = styled(Column)`
  margin-bottom: 20px;
  > *:nth-child(2n) {
    background: ${props => props.theme.backgroundLight};
  }
`;

const Section = styled(Column)`
  padding: 15px 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }
`;

const SectionTitle = styled(LargeText)`
  margin-bottom: 15px;
`;

const SmallDialog = styled(TippyTooltip)`
  bottom: 200px;
  left: 20px;
  padding: 10px;
  max-width: 400px !important;
  ${Column} {
    > ${LargeText},> ${RegularText} {
      margin: 10px 30px;
    }
  }
`;

const Error = styled(SmallText)`
  color: ${colors.negative};
  position: absolute;
  bottom: 0;
  left: 50px;
  line-height: 1;
`;

const AddToCourseDropdown = styled(DropDownInputEditable)`
  max-width: 200px;
  > div {
    margin: 0;
  }
`;

const RadioOption = styled(RadioButton)`
  margin: 0 5px;
`;

const OptionLabels = styled(Row)`
  margin-top: 20px;
  ${RegularText} {
    margin: 0 10px 0 5px;
  }
`;

const DoneButton = styled(Button)`
  width: max-content;
  margin: 0 auto;
`;
