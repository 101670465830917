import { asyncActionsCreator } from '../../common/utils';
import { IdWrapper, CreateOrUpdateTeacherRequest, TeacherDto } from 'shovel-lib/types';

const asyncActions = asyncActionsCreator('@@teacher');

export const getTeachers = asyncActions<void, TeacherDto, string>('GET_TEACHERS');

export const createTeacher = asyncActions<CreateOrUpdateTeacherRequest, TeacherDto, string>('CREATE_TEACHER');

export const updateTeacher = asyncActions<CreateOrUpdateTeacherRequest & IdWrapper, TeacherDto, string>('UPDATE_TEACHER');

export const deleteTeacher = asyncActions<number, number, string>('DELETE_TEACHER');
