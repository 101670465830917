import React, { createRef, PureComponent, RefObject } from 'react';
import t from '../../../i18n/t';
import { TITLE_MAX_LENGTH } from '../../../utils/constants/task';
import styled from 'styled-components';
import { FlexColumn, Row } from '../../common/layoutUtils';
import { TextareaInput } from '../../common/inputs';
import { PageRange, Subtask, ThinActivityDto, ThinCourseDto } from 'shovel-lib/types';
import { Field, Formik, FormikProps } from 'formik';
import ScrollGradient from '../../common/ScrollGradient';
import { ManageSubtasks } from '../manage/partials';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormFieldName } from '../../../utils/enums';
import { taskActions } from '../../../state/task';
import { number as yupNumber, object as yupObject, string as yupString } from 'yup';
import Button from '../../common/buttons/Button';
import { ObviousMike, ObviousMikeAnimation } from '../../../utils/taskUtils';
import Editor from '../../common/editor/Editor';
import AddTaskButtons from '@components/pile/components/AddTaskButtons';
import ButtonWithLoader from '@components/common/buttons/ButtonWithLoader';
import TaskPriority from '@components/pile/components/TaskPriority';

type OwnProps = {
  clearRepeatOptions: any;
  isRepeatSet: boolean;
  courses: ThinCourseDto[];
  activities: ThinActivityDto[];
  generateTasks: any;
  openCreatePatternDialog: any;
  close: any;
  initial: any;
};

type PropsFromDispatch = {
  createTask: typeof taskActions.createTasks.request;
};

type Props = PropsFromDispatch & OwnProps;

type State = {
  scroll: boolean;
  subtasksSize: number;
};

export type FormValues = {
  [FormFieldName.NAME]: string;
  [FormFieldName.DESCRIPTION]: string;
  [FormFieldName.COURSE]: any;
  [FormFieldName.SUBTASKS]: Subtask[];
  [FormFieldName.CATEGORY]: any;
  [FormFieldName.READING_SOURCE]: any;
  [FormFieldName.DUE_DATE]?: Date;
  [FormFieldName.START_AHEAD]: number;
  [FormFieldName.ESTIMATE]: number;
  [FormFieldName.PAGE_RANGES]: PageRange[];
  [FormFieldName.AUTO_ESTIMATE]: boolean;
};

export const initialTaskValues = {
  [FormFieldName.NAME]: '',
  [FormFieldName.DESCRIPTION]: '',
  [FormFieldName.COURSE]: null,
  [FormFieldName.SUBTASKS]: [],
  [FormFieldName.CATEGORY]: null,
  [FormFieldName.READING_SOURCE]: null,
  [FormFieldName.DUE_DATE]: undefined,
  [FormFieldName.START_AHEAD]: 0,
  [FormFieldName.ESTIMATE]: 0,
  [FormFieldName.PAGE_RANGES]: [],
  [FormFieldName.AUTO_ESTIMATE]: false
};

export const createTaskValidationSchema = yupObject().shape({
  name: yupString().required(t.TASK_NAME_REQUIRED),
  courseId: yupNumber().transform((cv, ov) => (ov ? cv : undefined))
});

class CreateTaskDefault extends PureComponent<Props, State> {
  containerEnd: RefObject<HTMLDivElement> = React.createRef();
  scrollRef: RefObject<HTMLDivElement> = createRef();

  state: State = {
    scroll: false,
    subtasksSize: 0
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevState.subtasksSize !== this.state.subtasksSize) {
      this.setState({ scroll: true });
    }
    if (this.state.scroll && this.containerEnd.current) {
      this.containerEnd.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      this.setState({ scroll: false });
    }
  }

  setSubtasksSize = (value: number) => this.setState({ subtasksSize: value });

  render() {
    const {
      openCreatePatternDialog,
      clearRepeatOptions,
      isRepeatSet,
      generateTasks,
      close,
      initial,
      courses,
      activities
    } = this.props;
    return (
      <Formik
        initialValues={initial}
        validationSchema={createTaskValidationSchema}
        enableReinitialize={false}
        onSubmit={values => {
          const data = {
            ...values,
            subtasks: values.subtasks.map((s, index) => ({
              content: s.content,
              completed: s.completed,
              ordinalNumber: index + 1
            }))
          };
          if (isRepeatSet) {
            generateTasks(data);
          } else {
            const course = [...courses, ...activities].find(c => c.id === values[FormFieldName.COURSE]);
            this.props.createTask({
              tasks: [data],
              colorHex: course?.colorHex,
              courseCorrelationId: course?.correlationId,
              title: course?.name
            });
          }
        }}
      >
        {({ setFieldValue, values, errors, touched, handleSubmit }: FormikProps<FormValues>) => {
          return (
            <Container>
              <TaskTitleAndPriority>
                <Field
                  name={FormFieldName.NAME}
                  component={NameInput}
                  value={values[FormFieldName.NAME]}
                  touched={touched[FormFieldName.NAME]}
                  error={errors[FormFieldName.NAME]}
                  placeholder={t.TASK_NAME}
                  minRows={1}
                  inline
                  autofocus
                  oneLine
                  maxLength={TITLE_MAX_LENGTH}
                  onBlur={name => setFieldValue(FormFieldName.NAME, name)}
                  fontStyle={{ fontSize: '21px', fontWeight: '700' }}
                  disableLink
                />
                <TaskPriority
                  padding={8}
                  fontSize={28}
                  priority={values[FormFieldName.PRIORITY]}
                  onChange={value => setFieldValue(FormFieldName.PRIORITY, value)}
                  right
                />
              </TaskTitleAndPriority>
              <Content flex>
                <ButtonsWrapper
                  values={values}
                  setFieldValue={setFieldValue}
                  initialValues={initialTaskValues}
                  courses={this.props.courses}
                  activities={this.props.activities}
                  repeat={{ isRepeatSet, openCreatePatternDialog, clearRepeatOptions }}
                  leftAligned
                  examStyles={{ padding: '10px 15px', marginRight: 10, marginTop: 0 }}
                />
                <Field
                  name={FormFieldName.DESCRIPTION}
                  placeholder={t.DESCRIPTION}
                  value={values[FormFieldName.DESCRIPTION]}
                  component={CustomEditor}
                  onChange={html => setFieldValue(FormFieldName.DESCRIPTION, html.toString())}
                  scrollToBottom={() => {
                    setTimeout(() => {
                      this.scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
                    }, 500);
                  }}
                />
                <div ref={this.scrollRef} />
                <ManageSubtasks
                  customAddOrUpdate={(data, oldData) => {
                    const subtaskIndex = values[FormFieldName.SUBTASKS].findIndex(s => s === oldData);
                    setFieldValue(
                      `${FormFieldName.SUBTASKS}.${
                        subtaskIndex !== -1 ? subtaskIndex : values[FormFieldName.SUBTASKS].length
                      }`,
                      data
                    );
                    if (subtaskIndex === -1) {
                      this.setSubtasksSize(this.state.subtasksSize + 1);
                    }
                  }}
                  customDelete={oldData => {
                    const subtasks = values[FormFieldName.SUBTASKS].filter(s => s !== oldData);
                    setFieldValue(FormFieldName.SUBTASKS, subtasks);
                  }}
                  subtasks={values[FormFieldName.SUBTASKS]}
                  noMaxHeight
                  reorder
                />
                <div ref={this.containerEnd} />
              </Content>
              <BottomContent>
                <DialogButtons>
                  <Button onClick={close} size={'lg'} withoutBorder>
                    {t.CANCEL}
                  </Button>
                  {isRepeatSet ? (
                    <ObviousMike>
                      <CustomObviousMikeAnimation left={20}>
                        <span style={{ fontSize: '36px' }}>👇</span>
                      </CustomObviousMikeAnimation>
                      <NextButton
                        onClick={handleSubmit}
                        size={'lg'}
                        icon={isRepeatSet ? 'navigate_next' : ''}
                        iconAfter
                        filled
                      >
                        {t.NEXT}
                      </NextButton>
                    </ObviousMike>
                  ) : (
                    <ButtonWithLoader
                      onClick={handleSubmit}
                      size={'lg'}
                      action={taskActions.createTasks}
                      callback={this.props.close}
                      filled
                      text={t.CREATE}
                      minWidth={103}
                      style={{ marginLeft: 5 }}
                    />
                  )}
                </DialogButtons>
              </BottomContent>
            </Container>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createTask: taskActions.createTasks.request
    },
    dispatch
  );

export default connect(null, mapStateToDispatch)(CreateTaskDefault);

const Container = styled(FlexColumn)`
  width: 100%;
  padding-left: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  justify-content: flex-start;
  position: relative;
`;

export const DropdownInputsWrapper = styled(Row)`
  flex-wrap: wrap;
  padding-left: 10px;
  > *:not(last-child) {
    margin-right: 30px;
  }
`;

const Content = styled(ScrollGradient)`
  max-height: 100%;
  box-sizing: border-box;
  margin-top: 10px;
`;

const BottomContent = styled(Row)`
  align-items: flex-start;
  height: 140px;
`;

export const DialogButtons = styled(Row)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const NextButton = styled(Button)`
  margin-left: 5px;
`;

const CustomObviousMikeAnimation = styled(ObviousMikeAnimation)`
  display: flex;
  align-items: center;
  bottom: 65px;
  @keyframes obviousMike {
    0% {
      top: -20px;
    }
    50% {
      top: -30px;
    }
    100% {
      top: -20px;
    }
  }
`;

const CustomEditor = styled(Editor)`
  #toolbar {
    top: 0 !important;
  }
`;

const ButtonsWrapper = styled(AddTaskButtons)`
  margin-bottom: 20px !important;
`;

const NameInput = styled(TextareaInput)`
  margin-bottom: 0;
  flex: 1;
  margin-right: 10px;
`;

const TaskTitleAndPriority = styled(Row)`
  justify-content: space-between;
  margin-bottom: 10px;
`;
