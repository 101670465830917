import Popup from 'reactjs-popup';
import React, { PureComponent } from 'react';
import DialogCloseButton from './dialog/DialogCloseButton';
import styled from 'styled-components';
import { LargeText, SmallText } from '../../utils/typography';
import { FlexColumn } from './layoutUtils';
import { commonPopupCSS, largeModalStyle, mediumModalStyle, smallModalStyle } from '../../utils/popupUtils';
import colors from '../../utils/colors';
import ScrollGradient from './ScrollGradient';
import { screenSizes } from '../../utils/screenUtils';

type Props = {
  open: boolean;
  title?: any;
  onClose?: () => void;
  onCloseClick?: () => void;
  contentStyle?: any;
  overlayStyle?: any;
  children: any;
  fullWidth?: boolean;
  closeOnEscape?: boolean;
  lockScroll?: boolean;
  closeOnDocumentClick?: boolean;
  size?: 'lg' | 'md' | 'sm';
  error?: string;
  manageDialog?: boolean;
  hideCloseButton?: boolean;
  scrollRef?: any;
  noErrorPlaceholder?: boolean;
  verticalCenter?: boolean;
  transparent?: boolean;
  zIndex?: number;
};

const dialogStyles = (size: string) => {
  if (size === 'lg') return largeModalStyle;
  if (size === 'md') return mediumModalStyle;
  if (size === 'sm') return smallModalStyle;
  return {};
};

class ModalDialog extends PureComponent<Props> {
  render() {
    const {
      open,
      size = 'lg',
      title,
      onClose,
      contentStyle,
      overlayStyle,
      children,
      fullWidth = size !== 'lg',
      lockScroll,
      closeOnEscape,
      closeOnDocumentClick,
      error,
      onCloseClick,
      hideCloseButton = false,
      scrollRef,
      noErrorPlaceholder,
      verticalCenter,
      transparent,
      zIndex
    } = this.props;
    return (
      <Modal
        modal
        open={open}
        contentStyle={{ ...dialogStyles(size), ...contentStyle }}
        overlayStyle={{ ...overlayStyle, overflow: 'scroll' }}
        onClose={onClose}
        lockScroll={lockScroll}
        closeOnEscape={closeOnEscape}
        closeOnDocumentClick={closeOnDocumentClick}
        size={size}
        transparent={transparent}
        zIndex={zIndex}
      >
        <>
          {!hideCloseButton && <DialogCloseButton onClick={onCloseClick || onClose} />}
          <Container>
            {title && <DialogTitle strong>{title}</DialogTitle>}
            {!noErrorPlaceholder && <Error color={colors.negative}>{error || ''}</Error>}
            <DialogContent fullWidth={fullWidth} flex innerRef={scrollRef} verticalCenter={verticalCenter}>
              {children}
            </DialogContent>
          </Container>
        </>
      </Modal>
    );
  }
}

export default ModalDialog;

const Modal = styled(Popup)<{ size: string; transparent?: boolean; zIndex?: number }>`
  &-content {
    ${commonPopupCSS};
    @media (max-width: ${screenSizes.small}) {
      width: 700px !important;
      min-width: 700px !important;
    }
  }
  &-overlay {
    background: ${props => (props.transparent ? 'transparent' : props.theme.modalOverlayColor)} !important;
    ${props => props.zIndex && `z-index: ${props.zIndex} !important`};
  }
`;

const Container = styled(FlexColumn)`
  position: relative;
  margin-bottom: 56px;
  width: 100%;
`;

const Error = styled(SmallText)`
  height: 11px;
  line-height: 1;
  margin-bottom: 10px;
  text-align: center;
`;

const DialogTitle = styled(LargeText)`
  display: block;
  text-align: center;
  margin: 30px 0 8px;
`;

const DialogContent = styled(ScrollGradient)<{ fullWidth?: boolean; verticalCenter?: boolean }>`
  flex: 1;
  min-height: 0;
  ${props => !props.fullWidth && `padding: 0 calc((100% - 380px) / 2);`};
  overflow: hidden auto;
  ${props => props.verticalCenter && 'display: flex; flex-direction: column; justify-content: center;'}
`;

export const DialogActions = styled.div<{
  position?: 'center' | 'flex-end' | 'space-between';
  sm?: boolean;
  noTopPadding?: boolean;
}>`
  position: absolute;
  bottom: ${props => (props.noTopPadding ? -40 : -56)}px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: ${props => props.position || 'space-between'};
  align-items: center;

  button:not(:first-child) {
    margin-left: 10px;
  }
`;
