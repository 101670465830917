export const urls = {
  backend: process.env.REACT_APP_BACKEND_API_URL || 'http://localhost:8080/api',
  eventService: process.env.REACT_APP_EVENT_SERVICE_API_URL || 'http://localhost:8081/api/',
  lambda: process.env.REACT_APP_LAMBDA_URL || 'http://localhost:3000/api'
};

export const isDev = process.env.NODE_ENV === 'development';

export const stripeApiKey: string = process.env.REACT_APP_STRIPE_API_KEY!;

export const googleRecaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;

export const helpCrunchId = process.env.REACT_APP_HC_ID;
export const helpCrunchSecret = process.env.REACT_APP_HC_SECRET;

//in order to threeDotsOptions works on calendar set useReactotron to false
export const useReactotron = isDev;

export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

export const googleClientid = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const release = process.env.REACT_APP_VERSION;

export const gtmId = process.env.REACT_APP_GTM_CODE;
