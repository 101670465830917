import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { RootState } from '../../state/rootReducer';
import { getSemesterState } from '../../state/semester/selectors';
import { ReactComponent as ShovelLogo } from '../../assets/images/shovel-logo.svg';

import t from '../../i18n/t';
import { SemesterState } from '../../state/semester/types';
import NavigationItem from './partials/NavigationItem';
import { isMobile } from '../../utils/screenUtils';
import { Column, displayNoneDuringPrint } from '../common/layoutUtils';
import NotificationsMenu from './partials/NotificationsMenu';
import { ReactComponent as HomeIcon } from '../../assets/icons/navigation/home.svg';
import Tooltip from '../common/tooltip/Tooltip';
import { SemesterStatus } from 'shovel-lib/types';
import OnboardingBar from '../common/onboardingBar/OnboardingBar';
import { goToHomeLink } from './navigationHelpers';
import LearningCenter from '../common/learningCenter/LearningCenter';
import { resetNavigation } from '../../state/calendar/actions';
import { getCalendarStartDate } from '../../state/calendar/selectors';
import { DEMO } from '../../utils/constants/routes';
import Menu from '@components/navigation/partials/Menu';
import * as routes from '../../utils/constants/routes';
import Accessibility from '@components/navigation/partials/Accessibility';
import styled from 'styled-components';
import DarkMode from '@components/navigation/partials/DarkMode';
import { IconsWrapper } from '@components/settings/membership/ExpiredMembershipPage';
import CoursesWebsiteLink from '@components/navigation/partials/CoursesWebsiteLink';

type Props = {
  resetNavigation: typeof resetNavigation;
  semester?: SemesterState;
  calendarStartDate: any;
  history: any;
  darkMode: boolean;
  toggleMode: (darkMode: boolean) => void;
};

const logoNavigation = (
  history: any,
  termCreated: boolean,
  semesterId: number,
  resetNavigation: any,
  calendarStartDate?: any
) => {
  if (termCreated) {
    history.push(`/${semesterId}/term`);
  } else {
    history.push({
      pathname: goToHomeLink(semesterId),
      state: { calendarStartDate: calendarStartDate?.toDate?.() || calendarStartDate }
    });
  }
  resetNavigation();
};

const MainNavigation = ({ semester, history, darkMode, toggleMode, resetNavigation, calendarStartDate }: Props) => {
  if (!semester || !semester.info || isMobile) return null;

  const {
    info: { id, state }
  } = semester;

  const { pathname } = history.location;
  const isDemoPage = pathname.endsWith(DEMO);

  const disableNavigation =
    isDemoPage ||
    (state !== SemesterStatus.TASK_CREATED &&
      state !== SemesterStatus.ACTIVITY_SETUP_DONE &&
      state !== SemesterStatus.QUICK_SETUP_DONE &&
      state !== SemesterStatus.COURSE_CREATED);

  const hideOnboardingBar = pathname.endsWith('/term') || isDemoPage;

  return (
    <Container>
      <LogoWrapper
        onClick={() => logoNavigation(history, disableNavigation, id, resetNavigation, calendarStartDate || undefined)}
      >
        <Tooltip
          target={<ShovelLogo width={34} height={34} style={{ cursor: 'pointer' }} />}
          content={t.LOGO_TOOLTIP_TEXT}
          position={'right center'}
          width={'max-content'}
        />
      </LogoWrapper>
      <Content>
        <NavigationItemsWrapper>
          <NavigationItems disabled={disableNavigation}>
            <NavigationItem
              tooltip={t.HOME_TAB_TEXT}
              link={goToHomeLink(id)}
              locationState={{ calendarStartDate: calendarStartDate?.toDate?.() || calendarStartDate }}
              onClick={() => resetNavigation()}
              Icon={HomeIcon}
            />
            <NavigationItem tooltip={t.CHARTS} link={`/${id}/charts/analytics`} icon={'bar_chart'} />
            <NotificationsMenu />
            <Menu
              icon={'tune'}
              tooltip={`${t.ADJUST}...`}
              options={[
                {
                  linkTo: { pathname: `/${id}/study-time`, state: { calendarDate: null } },
                  title: `${t.ADJUST} ${t.STUDY_TIME}`
                },
                { linkTo: `/${id}/awake-time`, title: t.ADJUST_AWAKE_TIME }
              ]}
            />
          </NavigationItems>
          <CoursesWebsiteLink />
        </NavigationItemsWrapper>
        <IconsWrapper>
          <DarkMode isDarkMode={darkMode} toggleMode={toggleMode} />
          <Menu
            icon={'pending'}
            iconSize={22}
            outlineIcon
            tooltip={t.MORE}
            options={[
              { linkTo: routes.BADGES, title: t.BADGES },
              { linkTo: routes.GPA_CALCULATOR, title: t.GPA_CALCULATOR }
            ]}
          />
          <LearningCenter />
          <Accessibility />
          <NavigationItem link={routes.SETTINGS} icon={'settings'} tooltip={t.SETTINGS} />
        </IconsWrapper>
      </Content>
      <OnboardingBar visible={!hideOnboardingBar} />
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  semester: getSemesterState(state),
  calendarStartDate: getCalendarStartDate(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ resetNavigation }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigation);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50px;
  height: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.background};
  justify-content: space-between;
  padding: 10px 0 20px 0;
  ${displayNoneDuringPrint};
`;

const Content = styled(Column)`
  flex: 1;
  padding: 9px;
  justify-content: space-between;
`;

const LogoWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavigationItems = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    a,
    div {
      pointer-events: none;
      * {
        color: ${props.theme.captures} !important;
        fill: ${props.theme.captures} !important;
      }
    }

  `}
`;

const NavigationItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
