import { Semester, SemesterListDto, CushionDto } from 'shovel-lib/types';

export type SemesterState = {
  info?: Semester;
  userSemesters: SemesterListDto[];
  cushion: CushionDto & { timelineColorMode: TimelineColorMode };
  loading: boolean;
  error: string;
  cushionSidebarCollapsed: boolean;
  countSemesters: number;
};

export enum TimelineColorMode {
  CUSHION = 'CUSHION',
  COURSE = 'COURSE'
}
