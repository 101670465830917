import { helpCrunchId, helpCrunchSecret } from '../config/configConstants';

class HelpCrunchWrapper {
  initialized = false;

  applicationId = helpCrunchId;
  applicationSecret = helpCrunchSecret;

  /**
   * hideChat is used not to show chat if user signs up through mobile app
   */
  authenticate = (id: number, email: string, name?: string, hideChat?: boolean) => {
    if (this.initialized) return;

    const { applicationId, applicationSecret } = this;
    // @ts-ignore
    window.HelpCrunch('init', 'howtostudyincollege', {
      applicationId,
      applicationSecret,
      user: {
        email,
        name,
        user_id: String(id)
      }
    });
    if (!hideChat) {
      this.showChat();
    }
  };

  // https://docs.helpcrunch.com/developers/displaying-the-chat-widget-on-your-site
  showChat = () => {
    if (!this.initialized) {
      // @ts-ignore
      window.HelpCrunch('showChatWidget');
      this.initialized = true;
    }
  };

  logout() {
    // @ts-ignore
    window.HelpCrunch('logout');
  }
}

const instance = new HelpCrunchWrapper();

export default instance;
