import { actionCreator, asyncActionsCreator } from '../common/utils';
import { GoogleCalendar, PlatformEvent } from './types';

const asyncActions = asyncActionsCreator('@@googleCalendar');
const action = actionCreator('@@googleCalendar');

export const getGoogleAccess = asyncActions<{ code: string; reconnect?: boolean }, void, string>('GET_GOOGLE_ACCESS');

export const getSavedCalendars = asyncActions<void, GoogleCalendar[], string>('GET_SAVED_CALENDARS');

export const refreshGoogleCalendars = asyncActions<void, GoogleCalendar[], string>('REFRESH_GOOGLE_CALENDARS');

export const toggleGoogleCalendar = asyncActions<number, number, string>('TOGGLE_GOOGLE_CALENDAR');

export const unsyncGoogleCalendar = asyncActions<void, void, string>('UNSYNC_GOOGLE_CALENDAR');

export const getGoogleEvents = asyncActions<void, PlatformEvent[], string>('GET_GOOGLE_EVENTS');
