import React, { PureComponent } from 'react';
import t from '../../../i18n/t';
import * as courseActions from '../../../state/course/actions';
import { Formik, FormikProps } from 'formik';
import DropDownInputEditable from '../../common/inputs/dropdown/DropDownInputEditable';
import { DEFAULT_SECONDS_PER_PAGE } from '../../../utils/constants/task';
import { ReadingSourceInfoDto } from 'shovel-lib/types';
import { bindActionCreators, Dispatch } from 'redux';
import { commonActions } from '../../../state/common';
import { connect } from 'react-redux';
import { secondsToDurationInMinutes } from '../../../utils/filters';
import { object as yupObject, string as yupString } from 'yup';
import { Column, ConfirmAndCancelButtons } from '../../common/layoutUtils';
import DurationInput from '../../common/inputs/duration/DurationInput';
import colors from '../../../utils/colors';
import styled from 'styled-components';
import TippyTooltip from '../../common/tooltip/TippyTooltip';
import Button from '../../common/buttons/Button';
import { getDDPosition } from '../../../utils/taskUtils';

type OwnProps = {
  readingSources: ReadingSourceInfoDto[];
  value?: number;
  onChange: any;
  disabled: boolean;
  remove?: any;
  courseId: number;
  updateTimePerPage?: any;
  className?: string;
  placeholder?: string;
  customTarget?: React.ReactElement;
  small?: boolean;
  isManageDialog?: boolean;
};

type PropsFromDispatch = {
  createReadingSource: typeof courseActions.createReadingSource.request;
  updateReadingSource: typeof courseActions.updateReadingSource.request;
  deleteReadingSource: typeof courseActions.deleteReadingSource.request;
  openConfirmationDialog: typeof commonActions.openConfirmationDialog;
};

type Props = OwnProps & PropsFromDispatch;

type State = {
  editIndex: number;
  visibleTimePerPagePopup: number;
};

class ReadingSourceSection extends PureComponent<Props, State> {
  state: State = {
    editIndex: -1,
    visibleTimePerPagePopup: -1
  };

  mapReadingSourceToDropdownOptions = () => {
    return this.props.readingSources.map(rs => ({
      label: rs.name,
      value: rs.id,
      labelExtraContent: secondsToDurationInMinutes(rs.secondsPerPage!)
    }));
  };

  createReadingSource = (name: string, data: any, onChange?: any) => {
    this.props.createReadingSource({ name, secondsPerPage: DEFAULT_SECONDS_PER_PAGE, ...data, onChange });
  };

  getEditOptionsForReadingSource = readingSource => {
    return [
      {
        name: t.RENAME,
        icon: 'create',
        action: () => this.setState({ editIndex: readingSource.value })
      },
      {
        name: t.TIME_PER_PAGE,
        icon: 'schedule',
        outlinedIcon: true,
        action: () => this.setState({ visibleTimePerPagePopup: readingSource.value })
      },
      {
        name: t.DELETE,
        icon: 'delete',
        outlinedIcon: true,
        action: () =>
          this.props.openConfirmationDialog({
            title: t.DELETE_READING_SOURCE,
            message: (
              <div>
                <div>
                  {t.DELETE_READING_SOURCE_CONFIRMATION} {readingSource.label}!
                </div>
                <div>{t.DELETE_READING_SOURCE_WARNING}</div>
              </div>
            ),
            action: () => {
              this.props.deleteReadingSource({ id: readingSource.value, courseId: this.props.courseId });
              if (this.props.value === readingSource.value) {
                this.props.remove();
              }
            },
            understandCheck: true,
            warning: true,
            loadingAction: courseActions.deleteReadingSource
          })
      }
    ];
  };

  updateReadingSource = (value, readingSource, data) => {
    const source = this.props.readingSources.find(rs => rs.id === readingSource.value);
    this.props.updateReadingSource({
      id: readingSource.value,
      name: value,
      secondsPerPage: source?.secondsPerPage!,
      courseId: data.courseId
    });
    this.setState({ editIndex: -1 });
  };

  closeTimePerPagePopup = () => {
    if (this.state.visibleTimePerPagePopup !== -1) {
      this.setState({ visibleTimePerPagePopup: -1 });
    }
  };

  renderPopup = (target, readingSource) => {
    const { courseId, readingSources, isManageDialog } = this.props;
    const selectedSource = readingSources.find(rs => rs.id === readingSource.value);
    const manageDialogEl = document.getElementById('manage-task-dialog');
    return (
      <TimePerPagePopup
        key={readingSource.value}
        lazy
        target={target}
        trigger={'click'}
        width={'180px'}
        arrow={false}
        interactive
        lightTheme
        position={'right-start'}
        onClickOutside={() => this.closeTimePerPagePopup()}
        visible={this.state.visibleTimePerPagePopup === readingSource.value}
        controlled
        appendTo={isManageDialog ? manageDialogEl! : undefined}
        content={
          <Formik
            initialValues={{ secondsPerPage: selectedSource?.secondsPerPage! }}
            validationSchema={timePerPageValidationSchema}
            enableReinitialize={false}
            onSubmit={values => {
              this.props.updateReadingSource({
                id: readingSource.value,
                name: readingSource.label,
                secondsPerPage: values.secondsPerPage,
                courseId
              });
              if (this.props.updateTimePerPage) {
                this.props.updateTimePerPage({
                  readingSourceId: readingSource.value,
                  secondsPerPage: values.secondsPerPage
                });
              }
              this.closeTimePerPagePopup();
            }}
          >
            {({ values, errors, touched, dirty, setFieldValue, handleSubmit }: FormikProps<{ secondsPerPage: number }>) => (
              <Column style={{ width: '100%' }}>
                <DurationInput
                  value={values.secondsPerPage}
                  label={t.TIME_PER_PAGE}
                  onChange={(v?: number) => setFieldValue('secondsPerPage', v)}
                  error={errors.secondsPerPage}
                  touched={touched.secondsPerPage}
                  required={true}
                  inSeconds
                  autofocus
                />
                <ConfirmAndCancelButtons>
                  <Button
                    size={'sm'}
                    withoutBorder
                    onClick={() => this.closeTimePerPagePopup()}
                    color={colors.primaryPurple}
                  >
                    {t.CANCEL}
                  </Button>
                  <Button size={'sm'} filled onClick={() => handleSubmit()} disabled={!dirty}>
                    {t.CHANGE}
                  </Button>
                </ConfirmAndCancelButtons>
              </Column>
            )}
          </Formik>
        }
      />
    );
  };

  render() {
    const {
      courseId,
      value,
      onChange,
      remove,
      disabled,
      className,
      placeholder = t.SELECT_READING_SOURCE,
      customTarget,
      small
    } = this.props;
    return (
      <DropDownInputEditable
        className={className}
        initialValue={value}
        placeholder={placeholder}
        options={this.mapReadingSourceToDropdownOptions()}
        customTarget={customTarget}
        onChange={(value, item) => {
          onChange(value, item);
          this.closeTimePerPagePopup();
        }}
        add={this.createReadingSource}
        addPlaceholder={t.ADD_SOURCE}
        addData={{ courseId }}
        disabled={disabled}
        remove={remove}
        editOptions={readingSource => this.getEditOptionsForReadingSource(readingSource)}
        edit={this.updateReadingSource}
        editIndex={this.state.editIndex}
        editTooltip={t.EDIT_READING_SOURCE}
        renderPopup={this.renderPopup}
        onClose={() => this.setState({ editIndex: -1 })}
        tooltip={!courseId ? t.SELECT_COURSE_OR_ACTIVITY_TOOLTIP : undefined}
        addAction={courseActions.createReadingSource}
        small={small}
        dropdownPosition={small ? getDDPosition('rsPopup') : undefined}
      />
    );
  }
}

const mapStateToDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createReadingSource: courseActions.createReadingSource.request,
      updateReadingSource: courseActions.updateReadingSource.request,
      deleteReadingSource: courseActions.deleteReadingSource.request,
      openConfirmationDialog: commonActions.openConfirmationDialog
    },
    dispatch
  );

export default connect(null, mapStateToDispatch)(ReadingSourceSection);

export const timePerPageValidationSchema = yupObject().shape({
  secondsPerPage: yupString().required(t.FIELD_REQUIRED)
});

export const TimePerPagePopup = styled(TippyTooltip)`
  padding: 10px 11px !important;
  > .tippy-content {
    padding: 0px !important;
    > div:first-child {
      display: flex;
      width: 100%;
    }
  }
`;
