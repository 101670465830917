import React, { FC, useEffect, useState } from 'react';
import MaterialIcon from '../icons/MaterialIcon';
import Confetti from 'react-dom-confetti';
import colors from '../../../utils/colors';
import { CONFETTI_CONFIG } from '../onboardingBar/helpers';

type Props = {
  size?: number;
  onClick: any;
  onComplete?: any;
  isChecked: boolean;
  color?: any;
  animationDuration?: number;
};

const handleClick = (onClick, setConfetti, setChecked, duration, onComplete) => {
  setConfetti(true);
  onClick();
  //wait for animation to finish and then set completed checkbox icon
  setTimeout(() => {
    setChecked(true);
  }, duration);
  //in order to completed checkbox be seen before task moves to completed section
  // setTimeout(() => {
  //   onComplete();
  // }, duration + 300);
};

const CheckBoxConfetti: FC<Props> = ({
  size,
  onClick,
  isChecked,
  color = colors.positive,
  animationDuration = 1000,
  onComplete
}) => {
  const [confetti, setConfetti] = useState(false);
  const [checked, setChecked] = useState(isChecked);
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  if (checked) {
    return <MaterialIcon className={'task-completed-icon'} color={color} name={'check_circle'} onClick={onClick} />;
  }
  return (
    <>
      <MaterialIcon
        className={'mark-complete-icon'}
        name={'check_circle_outline'}
        onClick={() => handleClick(onClick, setConfetti, setChecked, animationDuration, onComplete)}
      />
      <div style={{ zIndex: 1000 }}>
        <Confetti
          active={confetti}
          config={{ ...CONFETTI_CONFIG, width: '5px', height: '5px', duration: animationDuration }}
        />
      </div>
    </>
  );
};

export default CheckBoxConfetti;
