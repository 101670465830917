import { all, call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import * as actions from './actions';
import { getErrorMessage } from '../../api/network';
import { apiCall } from '../common/operations';
import { courseActions } from '../course/index';
import { taskApi } from 'shovel-lib';
import { calendarActions } from '../calendar';
import { icsActions } from '../ics';
import { termActions } from '../terms';
import storage from '@utils/storage';
import { GroupByField } from '@components/course/types';
import { getSelectedItemInfo } from '@state/workload/selectors';
import { SidebarItemType } from '@state/workload/types';

function* getWorkload(action: ReturnType<typeof actions.getWorkload.request>) {
  const { ok, ...response } = yield apiCall(taskApi.getWorkload, action.payload);
  if (ok) {
    yield put(actions.getWorkload.success(response.data));
  } else {
    yield put(actions.getWorkload.failure(getErrorMessage(response)));
  }
}

function* selectCommitment() {
  yield delay(200); // force skeleton
  yield put(actions.selectCommitmentSuccess());
}

function* onStartCourseWorkloadListener() {
  while (true) {
    const { type, payload } = yield take([
      courseActions.deleteTaskCategory.success,
      courseActions.deleteReadingSource.success,
      courseActions.updateReadingSource.success,
      courseActions.updateTaskCategory.success,
      calendarActions.deleteCourse.success,
      calendarActions.deleteActivity.success,
      courseActions.updateCalendarId.success,
      courseActions.importCourse.success,
      icsActions.triggerIcsUpdates.success,
      icsActions.unsyncCourseFromLMS.success,
      termActions.createBatchCourses.success,
      courseActions.reorderCategoriesInsideCourse.success,
      actions.stopCourseWorkloadListener
    ]);

    if (type === getType(actions.stopCourseWorkloadListener)) {
      break;
    }

    if (
      // don't refresh if course/activity is deleted and mode is not ViewEverything
      (type === getType(calendarActions.deleteCourse.success) ||
        type === getType(calendarActions.deleteActivity.success) ||
        type === getType(courseActions.deleteTaskCategory.success)) &&
      !payload.refreshTaskList
    ) {
      continue;
    }

    const groupBy = yield call(storage.getGroupWorkloadBy);
    // don't refresh after reorder of task categories if group by is not by category
    // or selected course is not the one of reordered categories
    if (type === getType(courseActions.reorderCategoriesInsideCourse.success)) {
      const { courseId } = payload;
      const { selectedItemId, selectedItemType } = yield select(getSelectedItemInfo);
      if (
        groupBy !== GroupByField.CATEGORY ||
        !(courseId === selectedItemId && selectedItemType === SidebarItemType.COURSE)
      ) {
        continue;
      }
    }

    yield put(actions.getWorkload.request(undefined));
  }
}

export default function*() {
  yield all([
    takeLatest(actions.getWorkload.request, getWorkload),
    takeLatest(actions.selectCommitment, selectCommitment),
    takeLatest(actions.startCourseWorkloadListener, onStartCourseWorkloadListener)
  ]);
}
