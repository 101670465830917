import { createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { SettingsState, initialMonthFilterState, calendarStylingType } from './types';
import { DateFormat, DayOfWeek, OnboardingStatusType, OnboardingStep, PileStateOptions } from 'shovel-lib/types';
import { now } from 'shovel-lib/utils/timeUtils';
import { CALENDAR_DEFAULT_SCROLL_TIME } from '../../utils/calendar/calendarConstants';
import { setupTerm } from '../terms/actions';
import storage from '../../utils/storage';

export const initialOnboardingStatus = {
  [OnboardingStep.HINTS]: false,
  [OnboardingStep.AWAKE_TIME]: false,
  [OnboardingStep.STUDY_TIME]: false,
  [OnboardingStep.SCHEDULE]: false,
  [OnboardingStep.COURSE]: false,
  [OnboardingStep.TASK_LIST_VIEW]: false,
  [OnboardingStep.PLANNER_NAVIGATION]: false,
  [OnboardingStep.PLANNER_DIALOG]: false,
  [OnboardingStep.PLANNER]: false,
  [OnboardingStep.TASK_DIALOG]: false,
  [OnboardingStep.PLANNER_BAR]: false,
  [OnboardingStep.EVENT_REPEATING_OPTIONS]: false
};

export const initialUserSettings = (() => {
  const startOfDay = now()
    .hours(parseInt(CALENDAR_DEFAULT_SCROLL_TIME.split(':')[0]))
    .minutes(parseInt(CALENDAR_DEFAULT_SCROLL_TIME.split(':')[1]))
    .seconds(parseInt(CALENDAR_DEFAULT_SCROLL_TIME.split(':')[2]))
    .toDate();
  return {
    monthFilter: initialMonthFilterState,
    time24Format: false,
    firstDayOfWeek: DayOfWeek.MONDAY,
    dateFormat: DateFormat.MONTH_FIRST,
    startOfDay,
    onboardingStatus: initialOnboardingStatus,
    pileState: PileStateOptions.ACTIVE,
    daysAhead: 3,
    showFlags: true,
    courseEventStyle: {
      style: calendarStylingType.LIGHT,
      sidebar: false
    },
    activityEventStyle: {
      style: calendarStylingType.GRAY,
      sidebar: false
    },
    googleEventStyle: {
      style: calendarStylingType.LIGHT,
      sidebar: false
    },
    plannedTaskEventStyle: {
      style: calendarStylingType.LIGHT,
      sidebar: true
    }
  };
})();

const initialState: SettingsState = {
  userSettings: undefined,
  helpUsDialog: {
    open: false,
    confirmAction: null,
    options: [],
    message: '',
    loadingAction: null
  },
  badgesInfo: null,
  badgesObviousMike: storage.getBadgeStepObviousMike(),
  showOnboardingBar: storage.getShowOnboardingBar(),
  stepDialogInfo: {
    open: false
  },
  personalizationDialog: {
    open: false,
    callback: undefined
  }
};

const reducer = createReducer(initialState)
  .handleAction(
    [actions.getUserSubscription.success, actions.cancelSubscription.success, actions.reactivateSubscription.success],
    (state, action) => {
      return {
        ...state,
        subscription: {
          ...action.payload,
          // tier is fetched only on getUserSubscription, this prevents overriding it with null value on
          // subscription cancel or reactivate
          tier: action.payload.tier || state.subscription?.tier
        }
      };
    }
  )
  .handleAction(actions.applyMonthFilter, (state, action) => ({
    ...state,
    userSettings: {
      ...(state.userSettings || initialUserSettings),
      monthFilter: action.payload
    }
  }))
  .handleAction([actions.getUserSettings.success, actions.saveUserSettings.success], (state, action) => {
    if (!action.payload) return state;
    const startOfDay = action.payload.startOfDay
      ? new Date(action.payload.startOfDay)
      : (state.userSettings || initialUserSettings).startOfDay;

    if (!action.payload.courseEventStyle.style) {
      const { courseEventStyle, activityEventStyle, googleEventStyle, plannedTaskEventStyle } = action.payload;
      action.payload.courseEventStyle = { style: courseEventStyle };
      action.payload.activityEventStyle = { style: activityEventStyle };
      action.payload.googleEventStyle = { style: googleEventStyle };
      action.payload.plannedTaskEventStyle = { style: plannedTaskEventStyle };
    }

    return {
      ...state,
      userSettings: {
        ...state.userSettings,
        ...action.payload,
        monthFilter: {
          ...initialMonthFilterState,
          ...state.userSettings?.monthFilter,
          ...action.payload.monthFilter
        },
        startOfDay
      }
    };
  })
  // .handleAction(actions.changeOnboardingStatus, (state, action) => {
  //   const userSettings = state.userSettings || initialUserSettings;
  //   return {
  //     ...state,
  //     userSettings: {
  //       ...userSettings,
  //       onboardingStatus: {
  //         ...userSettings.onboardingStatus!,
  //         [action.payload]: true
  //       }
  //     }
  //   };
  // })
  .handleAction(actions.resetOnboarding, state => {
    if (!state.userSettings) return state;
    return {
      ...state,
      userSettings: {
        ...state.userSettings,
        onboardingStatus: initialOnboardingStatus
      }
    };
  })
  .handleAction(actions.skipOnboarding, state => {
    //@ts-ignore
    const onboardingStatus: OnboardingStatusType = Object.entries(initialOnboardingStatus).reduce(
      (newStatus, [key, _]) => ({
        ...newStatus,
        [key]: true
      }),
      {}
    );
    return {
      ...state,
      userSettings: {
        ...(state.userSettings || initialUserSettings),
        onboardingStatus
      }
    };
  })
  .handleAction(actions.openHelpUsDialog, (state, action) => {
    const { confirmAction, options, message, loadingAction } = action.payload;
    return {
      ...state,
      helpUsDialog: {
        open: true,
        confirmAction,
        options,
        message,
        loadingAction
      }
    };
  })
  .handleAction(actions.closeHelpUsDialog, state => {
    return {
      ...state,
      helpUsDialog: initialState.helpUsDialog
    };
  })
  .handleAction(actions.getBadgesInfo.success, (state, action) => {
    let badgesObviousMike = state.badgesObviousMike;
    if (action.payload && action.payload.steps.length === 0) {
      storage.setBadgeStepObviousMike();
      badgesObviousMike = true;
    }
    return {
      ...state,
      badgesInfo: action.payload,
      badgesObviousMike
    };
  })
  .handleAction(actions.updateBadgesInfo.success, (state, action) => {
    return {
      ...state,
      badgesInfo: action.payload
    };
  })
  .handleAction(actions.openStepDialog, (state, action) => {
    return {
      ...state,
      stepDialogInfo: { open: true, step: action.payload }
    };
  })
  .handleAction(actions.closeStepDialog, state => {
    return {
      ...state,
      stepDialogInfo: { open: false }
    };
  })
  .handleAction(actions.addFreeTrialDay.success, (state, action) => {
    if (!action.payload) return state;
    return {
      ...state,
      subscription: {
        ...action.payload,
        // tier is fetched only on getUserSubscription, this prevents overriding it with null value on
        // subscription cancel or reactivate
        tier: action.payload.tier || state.subscription?.tier
      }
    };
  })
  .handleAction(setupTerm.success, state => {
    if (state.badgesInfo) return state;
    storage.setBadgeStepObviousMike();
    return {
      ...state,
      badgesInfo: {
        badges: [],
        steps: [],
        substeps: [],
        completed: false
      },
      badgesObviousMike: true
    };
  })
  .handleAction(actions.cancelBadgesObviousMike, state => {
    storage.clearBadgeStepObviousMike();
    return {
      ...state,
      badgesObviousMike: false
    };
  })
  .handleAction(actions.toggleOnboardingBar, (state, action) => {
    const showOnboardingBar = action.payload !== undefined ? action.payload : !state.showOnboardingBar;
    storage.setShowOnboardingBar(showOnboardingBar);
    return {
      ...state,
      showOnboardingBar
    };
  })
  .handleAction(actions.openPersonalizationDialog, (state, action) => {
    return {
      ...state,
      personalizationDialog: {
        open: true,
        callback: action.payload
      }
    };
  })
  .handleAction(actions.closePersonalizationDialog, (state, action) => {
    return {
      ...state,
      personalizationDialog: initialState.personalizationDialog
    };
  });
export default reducer;
