import * as actions from './actions';
import { createReducer } from 'typesafe-actions';
import { AuthState } from './types';

const initialState: AuthState = {
  refreshingToken: false
};

const reducer = createReducer(initialState)
  .handleAction(actions.refreshToken.request, state => {
    return { ...state, refreshingToken: true };
  })
  .handleAction(actions.refreshToken.success, state => {
    return { ...state, refreshingToken: false };
  })
  .handleAction(actions.refreshToken.failure, state => {
    return { ...state, refreshingToken: false };
  })
  .handleAction(actions.forgotPassword.success, (state, action) => {
    return { ...state, actionResult: { email: action.payload, success: true } };
  })
  .handleAction(actions.resetPassword.success, state => {
    return { ...state, actionResult: { ...state.actionResult, success: true } };
  })
  .handleAction(actions.setPassword.success, state => {
    return state;
  })
  .handleAction(actions.clearAuthActionResult, state => {
    return { ...state, actionResult: undefined };
  })
  .handleAction(actions.setOrganizationRegistrationLink, (state, action) => {
    return { ...state, organizationRegistrationLink: action.payload };
  });

export default reducer;
