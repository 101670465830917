import React, { FC } from 'react';
import styled, { withTheme } from 'styled-components';
import colors from '../../../utils/colors';
import t from '../../../i18n/t';
import { MiniText } from '../../../utils/typography';
import { minutesToDurationInHoursNoNegative } from '../../../utils/filters';
import { Row } from '../../common/layoutUtils';
import MaterialIcon from '../../common/icons/MaterialIcon';
import TippyTooltip from '../../common/tooltip/TippyTooltip';

type Props = {
  timeNeed: number;
  timePlanned: number;
  font?: number;
  theme: any;
  parentTheme?: any;
  tooltip?: string;
  bare?: boolean;
  isTaskCard?: boolean;
  noTooltip?: boolean;
  manageDialog?: boolean;
  noIcon?: boolean;
};

export const isTaskPlanned = (timeNeed: number, timePlanned: number) => timeNeed - timePlanned <= 0;

const PlannedTag: FC<Props> = ({
  noTooltip,
  timeNeed,
  timePlanned,
  font,
  theme: ownTheme,
  parentTheme,
  tooltip,
  isTaskCard,
  bare,
  manageDialog,
  noIcon
}) => {
  let values: string[] = [];
  const theme = ownTheme || parentTheme;

  if (timePlanned > 0 && isTaskPlanned(timeNeed, timePlanned)) {
    values = ['event_available', colors.positive, theme?.plannedTagColor(colors.positive), t.PLANNED, undefined];
  } else {
    const timePlannedValue =
      timePlanned || timeNeed ? minutesToDurationInHoursNoNegative(timeNeed - timePlanned) : undefined;
    let tooltipText;
    if (!noTooltip) {
      if (!isTaskCard) {
        tooltipText = tooltip;
      } else if (timePlannedValue === undefined) {
        tooltipText = t.NOT_PLANNED;
      } else {
        tooltipText = t.LEFT_TO_PLAN;
      }
    }
    values = [
      'event_busy',
      colors.negative,
      theme?.plannedTagColor(colors.negative),
      bare ? '' : timePlannedValue === undefined ? t.NOT_PLANNED : t.LEFT_TO_PLAN.toLowerCase(),
      timePlannedValue,
      tooltipText
    ];
  }
  const [icon, color, backgroundColor, labelText, additionalText, tooltipText] = values;

  const tag = (
    <PlannedContainer backgroundColor={backgroundColor} bare={bare || manageDialog}>
      {!noIcon && <Icon color={color} name={icon} size={font ? font + 3 : undefined} />}
      <PlannedLabel {...{ color, font }}>
        {additionalText && <span color={colors.negative}>{additionalText}&nbsp;</span>}
        {labelText}
      </PlannedLabel>
      {labelText === t.NOT_PLANNED && <PlannedTimeOverlay percents={Math.floor((timePlanned * 100) / timeNeed)} />}
    </PlannedContainer>
  );
  return tooltipText ? <TippyTooltip target={tag} content={tooltipText} /> : tag;
};

export default withTheme(PlannedTag);

export const PlannedContainer = styled(Row)<{ backgroundColor: string; bare?: boolean }>`
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  align-items: center;
  ${props =>
    !props.bare &&
    `
  background-color: ${props.backgroundColor};
  padding: 3px 10px 3px 5px;
  `};
  z-index: 1;
  cursor: default;
  i {
    cursor: default;
  }
`;

const PlannedLabel = styled(MiniText)<{ font?: number }>`
  display: flex;
  color: ${props => props.color};
  ${props => (props.font ? `font-size: ${props.font}px` : '')};
`;

const Icon = styled(MaterialIcon)`
  margin-right: 2px;
`;

const PlannedTimeOverlay = styled.div<{ percents: number }>`
  background-color: ${({ theme }) => theme.plannedProgressColor(colors.negative)};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${props => props.percents}%;
  z-index: -1;
`;
