import React, { Component } from 'react';
import styled from 'styled-components';

import t from '../../../i18n/t';
import colors from '../../../utils/colors';
import { RegularText } from '../../../utils/typography';
import MaterialIcon from '../icons/MaterialIcon';
import TippyTooltip from '../tooltip/TippyTooltip';
import { Placement } from 'tippy.js';

type Props = {
  label?: string;
  onClick?: (clickData: React.MouseEvent<HTMLButtonElement> | any) => void;
  clickData?: any;
  size?: number;
  className?: string;
  tooltip?: string;
  tooltipPosition?: Placement;
  style?: object;
  color?: string;
  disabled?: boolean;
};

class DeleteButton extends Component<Props> {
  render() {
    const {
      label,
      onClick,
      color,
      clickData,
      size = 20,
      className = '',
      tooltip,
      tooltipPosition,
      style = {},
      disabled = false
    } = this.props;
    const content = (
      <Container
        onClick={onClick ? e => onClick(clickData || e) : undefined}
        type="button"
        className={`delete-button ${className || ''}`}
        style={style}
      >
        <MaterialIcon size={size} color={color} className="delete-button-icon" name={'delete_outline'} disabled={disabled} />
        {label && <Label color={color}>{label || t.DELETE}</Label>}
      </Container>
    );
    return tooltip ? (
      <TippyTooltip target={content} content={tooltip} width={'max-content'} position={tooltipPosition} />
    ) : (
      content
    );
  }
}

export default DeleteButton;

const Label = styled(RegularText)<{ color?: string }>`
  color: ${props => props.color || props.theme.textColor};
  transition: color 0.2s ease;
  padding: 1px;
`;

const Container = styled.button`
  background-color: transparent;
  display: flex;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 6px;
  position: relative;

  & .delete-button-icon {
    transition: color 0.2s ease;
  }

  &:hover .delete-button-icon {
    color: ${colors.negative} !important;
  }

  &:hover ${Label} {
    color: ${colors.negative};
  }

  &:active ${Label} {
    color: #f73144;
  }
`;
