import React from 'react';
import styled from 'styled-components';
import { ManageTask } from 'shovel-lib/types';
import { ReactComponent as StartAheadIcon } from '../../../../assets/icons/startAhead.svg';
import { commonPopupCSS } from '../../../../utils/popupUtils';
import { Column, Row } from '../../../common/layoutUtils';
import { ManageDueDate } from './index';
import { ButtonWrapper } from '../../../pile/components/ActionButton';
import MaterialIcon from '../../../common/icons/MaterialIcon';
import { screenSizes } from '../../../../utils/screenUtils';
import t from '../../../../i18n/t';
import { MediumText, SmallText } from '../../../../utils/typography';
import colors from '../../../../utils/colors';
import { minutesToDurationInHours, toDaysAheadDiff } from '../../../../utils/filters';
import CheckBox from '@components/common/CheckBox';
import ActionButton from '@components/pile/components/ActionButton';
import DurationInput from '@components/common/inputs/duration/DurationInput';
import StartAhead from '@components/task/manage/partials/StartAhead';

type Props = {
  task: ManageTask;
  saveManage: (data: any) => void;
};

class ManageDialogActionBar extends React.PureComponent<Props> {
  readonly state = { isExam: this.props.task.duration > 0 };
  examRef = React.createRef<any>();

  render() {
    const { task, saveManage } = this.props;
    const { isExam } = this.state;

    const startLabel = isExam ? t.START_STUDYING : t.START;
    const dueDateLabel = isExam ? t.EXAM_DATE : t.DUE;
    return (
      <ActionBar data-cy={'dialogActionBar'}>
        <Row>
          <ActionButtonWrapper>
            <ManageDueDate
              popperContainerId={'manage-task-dialog'}
              courseId={task.courseId}
              dueDate={task.dueDate}
              save={saveManage}
              isManage
              emptyComponent={
                <ButtonWrapper btnSize={40}>
                  <MaterialIcon name={'event'} />
                </ButtonWrapper>
              }
              emptyTooltip={t.SET_DUE_DATE}
              activeComponent={
                <>
                  <MediumText style={{ position: 'absolute' }}>{dueDateLabel.toUpperCase()}</MediumText>
                </>
              }
              taskType={task.type}
            />
          </ActionButtonWrapper>
          <ActionButtonWrapper>
            <StartAhead
              popperContainerId={'manage-task-dialog'}
              calendarClassName={'manage-due-date'}
              dueDate={task.dueDate}
              startAhead={task.daysToStartAhead}
              save={saveManage}
              emptyComponent={
                <ButtonWrapper btnSize={40}>
                  <StartAheadIcon />
                </ButtonWrapper>
              }
              activeComponent={
                <Column style={{ cursor: 'pointer' }}>
                  <MediumText>{startLabel.toUpperCase()}</MediumText>
                  <Row>
                    <MediumText strong>{toDaysAheadDiff(task.daysToStartAhead!, task.dueDate!).label}</MediumText>
                    <MaterialIcon
                      className={'close-icon'}
                      onClick={e => {
                        e.stopPropagation();
                        saveManage({ daysToStartAhead: undefined });
                      }}
                      name={'cancel'}
                      size={12}
                    />
                  </Row>
                </Column>
              }
            />
          </ActionButtonWrapper>
        </Row>
        <ExamWrapper checked={isExam}>
          <CheckBox
            label={t.EXAM}
            checked={isExam}
            onChange={() => {
              this.setState({ isExam: !isExam });
              if (isExam) {
                saveManage({ duration: 0 });
              } else if (this.examRef.current) {
                this.examRef.current.click();
              }
            }}
          />
          <ActionButton
            buttonRef={this.examRef}
            icon={'timer'}
            size={'mini'}
            tooltip={!isExam ? t.SET_DURATION_NOT_EXAM : t.SET_EXAM_DURATION}
            //@ts-ignore
            popup={{
              content: DurationInput,
              placeholder: `${t.EXAM} ${t.DURATION.toLowerCase()}`,
              position: 'top right',
              initialValues: { duration: task.duration },
              field: 'duration',
              submit: value => saveManage({ duration: value }),
              setField: true,
              appendTo: 'parent'
            }}
            wrappedActiveComponent={() =>
              task.duration ? (
                <SmallText strong style={{ marginLeft: 5 }}>
                  {minutesToDurationInHours(task.duration)}
                </SmallText>
              ) : null
            }
            clear={() => {
              this.setState({ isExam: false });
              saveManage({ duration: 0 });
            }}
            disabled={!isExam}
          />
        </ExamWrapper>
      </ActionBar>
    );
  }
}

export default ManageDialogActionBar;

const ActionBar = styled(Row)`
  //z-index: 12;
  padding: 10px 20px 10px 30px;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  justify-content: space-between;
  .popup-content {
    ${commonPopupCSS}
  }
  @media (max-width: ${screenSizes.medium}) {
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
  }
`;

const ActionButtonWrapper = styled(Column)`
  justify-content: center;
  padding: 0 10px;
  min-height: 40px;
  i.close-icon {
    margin-left: 5px;
    z-index: 5;
    visibility: hidden;
    &:hover {
      color: ${colors.negative};
    }
  }
  &:hover i {
    visibility: visible;
  }
`;

const ExamWrapper = styled(Row)<{ checked?: boolean }>`
  border-radius: 5px;
  padding: 5px 15px;
  background-color: ${props => (props.checked ? `${colors.primaryPurple}1A` : props.theme.backgroundDark)};
  > *:first-child {
    margin-right: 15px;
  }
  @media (max-width: ${screenSizes.medium}) {
    margin-right: 30px;
  }
`;
