import { network } from './network';

// todo: remove after updating shovel-lib
async function getDatesContainingTasks(semesterId: number, startDate: string, endDate: string) {
  return network.get(`/semesters/${semesterId}/calendar/dates-containing-tasks-ranged`, { startDate, endDate });
}

export default {
  getDatesContainingTasks
};
