import { createReducer } from 'typesafe-actions';

import { WeekCalendarState } from './types';
import * as actions from './actions';

const initialState: WeekCalendarState = {
  startDate: null,
  endDate: null,
  datesContainingTasksMini: {}
};

const reducer = createReducer(initialState).handleAction(
  actions.getDatesContainingTasksMini.success,
  (state, { payload: { markers, startDate, endDate } }) => ({
    ...state,
    startDate,
    endDate,
    datesContainingTasksMini: markers
  })
);

export default reducer;
