import t from '@i18n/t';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Tour from 'reactour';

import TippyTooltip from '@components/common/tooltip/TippyTooltip';
import MaterialIcon from '@components/common/icons/MaterialIcon';
import studentApi from '@api/studentApi';
import { getCurrentUser } from '@state/semester/selectors';
import { COURSES_WEBSITE_LINK_HINT_1_CLASS } from '@utils/onboarding';
import OnboardingHint from '@components/common/onboardingTourHints/OnboardingHint';
import storage from '@utils/storage';

function CoursesWebsiteLink() {
  const user = useSelector(getCurrentUser);
  const [showHint, setShowHint] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (user?.hasAccessToCoursesWebsite && !storage.isCoursesWebsiteLinkShown()) {
      setTimeout(() => {
        setShowHint(true);
      }, 5000);
    }
  }, [user]);

  const goToShovelCourses = async () => {
    const res = await studentApi.getCoursesWebsiteJwt();
    if (res.ok) {
      window.open(`https://courses.shovelapp.io/wp-json/simple-jwt-login/v1/autologin?JWT=${res.data}`, '_blank');
    }
  };

  // @ts-ignore
  if (!user?.hasAccessToCoursesWebsite) {
    return null;
  }

  return (
    <>
      <TippyTooltip
        target={
          <Item className={COURSES_WEBSITE_LINK_HINT_1_CLASS} onClick={() => goToShovelCourses()}>
            <MaterialIcon className={'navigation-icon'} name={'video_library'} size={20} />
          </Item>
        }
        position={'right'}
        content={t.SHOVEL_COURSES}
      />
      {showHint && (
        <StyledTour
          steps={[
            {
              selector: `.${COURSES_WEBSITE_LINK_HINT_1_CLASS}`,
              content: () => (
                <OnboardingHint
                  pointLeft
                  title={t.COURSES_WEBSITE_LINK_HINT_1_TITLE}
                  text={t.COURSES_WEBSITE_LINK_HINT_1_TEXT}
                  onClick={() => {
                    storage.setCoursesWebsiteLinkShown();
                    setShowHint(false);
                  }}
                />
              ),
              position: 'right'
            }
          ]}
          isOpen={true}
          closeWithMask={false}
          disableDotsNavigation
          disableInteraction
          disableKeyboardNavigation
          showNavigation={false}
          showNumber={false}
          showNavigationNumber={false}
          showCloseButton={false}
          showButtons={false}
          rounded={5}
        />
      )}
    </>
  );
}

export default CoursesWebsiteLink;

const Item = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  background-color: inherit;

  &:hover {
    background: ${({ theme }) => theme.backgroundDark};

    .navigation-icon {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

const StyledTour = styled(Tour)`
  padding: 0 !important;
`;
