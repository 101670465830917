import React, { ChangeEvent, PureComponent } from 'react';
import InputWrapper from '../InputWrapper';
import InlineInputWrapper from '../InlineInputWrapper';
import { AutosizeFormInput, BareWrapper, FormInput } from '../Input.styles';
import { getIn } from 'formik';
import { TooltipPosition } from '@utils/popupUtils';
import colors from '@utils/colors';
import MaterialIcon from '../../icons/MaterialIcon';
import { AlignType } from '@state/common/utils';

export type TextInputProps = {
  value?: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (value: any) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  align?: AlignType;
  label?: string;
  name?: string;
  icon?: any;
  placeholder?: string;
  className?: string;
  error?: string;
  autosize?: boolean;
  autofocus?: boolean;
  maxLength?: number;
  disabled?: boolean;
  inline?: boolean;
  touched?: boolean;
  bare?: boolean;
  type?: string;
  field?: any;
  form?: any;
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
  required?: boolean;
  isSearchInput?: boolean;
  iconOnClick?: any;
  noErrorMargin?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
};

type State = {
  inputValue: string;
};

class TextInput extends PureComponent<TextInputProps, State> {
  state: State = {
    inputValue: (this.props.field ? this.props.field.value : this.props.value) || ''
  };

  componentDidUpdate(prevProps: Readonly<TextInputProps>): void {
    if (
      prevProps.value !== this.props.value ||
      (this.props.field && prevProps.field && this.props.field.value !== prevProps.field.value)
    ) {
      this.setState({ inputValue: (this.props.field ? this.props.field.value : this.props.value) || '' });
    }
  }

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const onChange = this.props.field ? this.props.field.onChange : this.props.onChange;
    if (onChange) {
      onChange(e);
    }

    if (!e.isDefaultPrevented()) {
      this.setState({ inputValue: e.target.value || '' });
    }
  };

  handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const { field } = this.props;

    const onBlur = field ? field.onBlur : this.props.onBlur;

    if (onBlur) {
      onBlur(field ? e : this.state.inputValue);
    }
  };

  render() {
    const {
      name: inputName,
      label = '',
      placeholder = '',
      icon,
      error,
      align,
      className,
      autosize,
      autofocus,
      maxLength,
      onKeyUp,
      disabled,
      inline,
      touched,
      bare = false,
      required = true,
      type = 'text',
      field,
      form,
      tooltip,
      tooltipPosition,
      isSearchInput,
      iconOnClick,
      inputRef,
      noErrorMargin
    } = this.props;

    const Wrapper = bare ? BareWrapper : inline ? InlineInputWrapper : InputWrapper;
    const Input: any = autosize ? AutosizeFormInput : FormInput;
    const name = field ? field.name : inputName;

    return (
      <Wrapper
        icon={isSearchInput ? 'search' : icon}
        searchInput={isSearchInput}
        autosize={autosize}
        label={label}
        placeholder={placeholder}
        className={className}
        disabled={disabled}
        inline={inline}
        align={align}
        error={form ? getIn(form.errors, name) : error}
        touched={form ? getIn(form.touched, name) : touched}
        tooltip={tooltip}
        tooltipPosition={tooltipPosition}
        required={required}
        value={this.state.inputValue}
        iconOnClick={iconOnClick}
        noErrorMargin={noErrorMargin || isSearchInput}
      >
        <Input
          ref={inputRef}
          name={field ? field.name : name}
          value={this.state.inputValue}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          placeholder={placeholder}
          autoFocus={autofocus}
          maxLength={maxLength}
          onKeyUp={onKeyUp}
          disabled={disabled}
          type={type}
          align={align}
        />
        {isSearchInput && this.state.inputValue && (
          <MaterialIcon
            size={14}
            name={'cancel'}
            disabled={disabled}
            onClick={iconOnClick}
            style={{ position: 'absolute', right: inline ? 3 : 10, color: colors.primaryPurple, zIndex: 2 }}
          />
        )}
      </Wrapper>
    );
  }
}

export default TextInput;
