import { all, call, fork, put, select, takeLatest, delay } from 'redux-saga/effects';
import * as actions from './actions';
import { taskApi } from '../../api';
import { semesterSelectors } from '../semester';
import { apiCall, callApi, handleError } from '../common/operations';
import { getErrorMessage } from '../../api/network';
import {
  CreateTaskDto,
  DeleteSubtaskRequest,
  GetManageTask,
  MoveToTodoRequest,
  SavePageRangesRequest,
  SemesterStatus,
  TaskState,
  TaskStatusRequest
} from 'shovel-lib/types';
import { getActiveTimer } from '../timer/selectors';
import { timerActions } from '../timer';
import { calendarData, getPlannedTasks } from '../calendar/actions';
import { possibleTermStateChange } from '../terms/actions';
import { alert, NotificationMode } from '@components/common/snackbarNotifications/Notifications';
import t from '../../i18n/t';
import gaAnalytics, { AnalyticsEvent } from '../../config/gaAnalytics';
import { MARK_COMPLETE_BTN_DURATION } from '../../utils/constants/task';
import { plannerApi } from 'shovel-lib';
import { calendarActions } from '@state/calendar';

function* getManageTask({ payload }: { payload: GetManageTask }) {
  const semesterId = yield select(semesterSelectors.getId);
  const { taskId } = payload;
  const apiCall = call(taskApi.getManageTask, semesterId, taskId);
  const { ok, ...response } = yield call(callApi, apiCall);
  yield delay(200); // force skeleton
  if (ok) {
    yield put(actions.getManageTask.success(response.data));
  } else {
    yield put(actions.getManageTask.failure(getErrorMessage(response)));
  }
}

function* saveManageTask({ payload }: ReturnType<typeof actions.saveManageTask.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { task, request } = payload;
  // @ts-ignore
  const apiCall = call(taskApi.saveManageTask, semesterId, task.taskId, { ...task, ...request });
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.saveManageTask.success({ request, taskId: task.taskId, response: response.data, task }));
  } else {
    yield put(actions.saveManageTask.failure(getErrorMessage(response)));
  }
}

function* getPageRanges({ payload }: { payload: number }) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(taskApi.getPageRanges, semesterId, payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getPageRanges.success({ taskId: payload, pageRanges: response.data }));
  } else {
    yield put(actions.getPageRanges.failure(getErrorMessage(response)));
  }
}

function* savePageRanges({ payload }: { payload: SavePageRangesRequest }) {
  const semesterId = yield select(semesterSelectors.getId);
  const { taskId, pageRanges } = payload;
  const apiCall = call(taskApi.savePageRanges, semesterId, taskId, pageRanges);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.savePageRanges.success({ taskId, data: response.data }));
  } else {
    yield handleError(actions.savePageRanges.failure, response);
  }
}

function* addOrUpdateSubtask({ payload }: ReturnType<typeof actions.addOrUpdateSubtask.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { taskId, data } = payload;
  const apiCall = call(taskApi.updateSubtask, semesterId, taskId, data);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.addOrUpdateSubtask.success({ data: response.data, taskId }));
  } else {
    yield put(actions.addOrUpdateSubtask.failure(getErrorMessage(response)));
  }
}

function* deleteSubtask({ payload }: { payload: DeleteSubtaskRequest }) {
  const semesterId = yield select(semesterSelectors.getId);
  const { taskId, id } = payload;
  if (id) {
    const apiCall = call(taskApi.deleteSubtask, semesterId, taskId, id);
    const { ok, ...response } = yield call(callApi, apiCall);
    if (ok) {
      yield put(actions.deleteSubtask.success(payload));
    } else {
      yield put(actions.deleteSubtask.failure(getErrorMessage(response)));
    }
  }
}

function* updateTaskStatus({ payload }: ReturnType<typeof actions.updateTaskStatus.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { taskId, data, callback } = payload;
  const apiCall = call(taskApi.updateTaskStatus, semesterId, taskId, data);
  const { ok, ...response } = yield call(callApi, apiCall);
  // measured time diff between click and animation complete is about 1400ms
  yield delay(MARK_COMPLETE_BTN_DURATION + 850);
  if (ok) {
    callback?.();
    yield put(actions.updateTaskStatus.success({ ...data, ...response, taskId }));
    if (data.state === TaskState.COMPLETED) {
      // fetch planned events (some planned tasks for this task are deleted)
      yield put(getPlannedTasks.request());
      const activeTimer = yield select(getActiveTimer);
      if (activeTimer && activeTimer.masterTaskId === taskId) {
        yield put(timerActions.clearSession());
      }
    }
  } else {
    yield put(actions.updateTaskStatus.failure(getErrorMessage(response)));
  }
}

function* moveToTodo({ payload }: { payload: { taskId: number; data: MoveToTodoRequest } }) {
  const semesterId = yield select(semesterSelectors.getId);
  const { taskId, data } = payload;
  const apiCall = call(taskApi.moveToTodo, semesterId, taskId, data);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.moveToTodo.success({ ...data, ...response.data, taskId }));
  } else {
    yield put(actions.moveToTodo.failure(getErrorMessage(response)));
  }
}

function* deleteTask({ payload }: ReturnType<typeof actions.deleteTask.request>) {
  const semesterId = yield select(semesterSelectors.getId);

  const apiCall = call(taskApi.deleteTask, semesterId, payload.id, { deleteForever: payload.deleteForever });
  const { ok, ...response } = yield call(callApi, apiCall);

  if (ok) {
    yield put(actions.deleteTask.success(payload));
    alert(`1 ${t.TASK} ${t.MOVED_TO_TRASH.toLowerCase()}`, NotificationMode.SUCCESS);
    yield put(calendarData.request());
    yield put(actions.closeManageTaskDialog());

    const activeTimer = yield select(getActiveTimer);
    if (activeTimer && activeTimer.masterTaskId === payload.id) {
      yield put(timerActions.clearSession());
    }
  } else {
    yield put(actions.deleteTask.failure(getErrorMessage(response)));
  }
}

function* restoreTasks({ payload }: ReturnType<typeof actions.restoreTasks.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(taskApi.restoreTasks, semesterId, payload);
  const { ok, ...response } = yield call(callApi, apiCall);

  if (ok) {
    yield put(actions.restoreTasks.success(payload));
    const alertMessage = `${payload.length} ${payload.length === 1 ? t.TASK : t.TASKS} ${t.RESTORED.toLowerCase()}`;
    alert(alertMessage, NotificationMode.SUCCESS);
  } else {
    yield put(actions.restoreTasks.failure(getErrorMessage(response)));
  }
}

function* deleteTasks({ payload }: ReturnType<typeof actions.deleteTasks.request>) {
  const semesterId = yield select(semesterSelectors.getId);

  const apiCall = call(taskApi.deleteTasks, semesterId, payload.ids, payload.deleteForever);
  const { ok, ...response } = yield call(callApi, apiCall);

  if (ok) {
    yield put(actions.deleteTasks.success(payload));

    const alertMessage = `${payload.ids.length} ${payload.ids.length === 1 ? t.TASK : t.TASKS} ${
      payload.deleteForever ? t.DELETED.toLowerCase() : t.MOVED_TO_TRASH.toLowerCase()
    }`;
    alert(alertMessage, NotificationMode.SUCCESS);

    const activeTimer = yield select(getActiveTimer);
    if (activeTimer && payload.ids.includes(activeTimer.masterTaskId)) {
      yield put(timerActions.clearSession());
    }
  } else {
    yield put(actions.deleteTasks.failure(getErrorMessage(response)));
  }
}

function* completeTasks({ payload }: ReturnType<typeof actions.completeTasks.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(taskApi.completeTasks, semesterId, payload);
  const { ok, ...response } = yield call(callApi, apiCall);

  if (ok) {
    yield put(actions.completeTasks.success(payload));
    const activeTimer = yield select(getActiveTimer);
    if (activeTimer && payload.includes(activeTimer.masterTaskId)) {
      yield put(timerActions.clearSession());
    }
  } else {
    yield put(actions.completeTasks.failure(getErrorMessage(response)));
  }
}

function* moveTasksToTodo({ payload }: ReturnType<typeof actions.moveTasksToTodo.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(taskApi.moveTasksToTodo, semesterId, payload);
  const { ok, ...response } = yield call(callApi, apiCall);

  if (ok) {
    yield put(actions.moveTasksToTodo.success(payload));
  } else {
    yield put(actions.moveTasksToTodo.failure(getErrorMessage(response)));
  }
}

function* setTasksStartAhead({ payload }: ReturnType<typeof actions.setTasksStartAhead.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(taskApi.setTasksStartAhead, semesterId, payload);
  const { ok, ...response } = yield call(callApi, apiCall);

  if (ok) {
    yield put(actions.setTasksStartAhead.success(payload));
  } else {
    yield put(actions.setTasksStartAhead.failure(getErrorMessage(response)));
  }
}

function* setTasksPriority({ payload }: ReturnType<typeof actions.setTasksPriority.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(taskApi.setTasksPriority, semesterId, payload);
  const { ok, ...response } = yield call(callApi, apiCall);

  if (ok) {
    yield put(actions.setTasksPriority.success(payload));
  } else {
    yield put(actions.setTasksPriority.failure(getErrorMessage(response)));
  }
}

function* pushTasksDueDate({ payload }: ReturnType<typeof actions.pushTasksDueDate.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(taskApi.pushTasksDueDate, semesterId, payload);
  const { ok, ...response } = yield call(callApi, apiCall);

  if (ok) {
    yield put(actions.pushTasksDueDate.success(response.data));
  } else {
    const errorMessage = getErrorMessage(response);
    if (errorMessage === 'one_or_more_tasks_are_missing_due_date') {
      alert(t.ONE_OR_MORE_TASKS_ARE_MISSING_DUE_DATE, NotificationMode.ERROR);
    }
    yield put(actions.pushTasksDueDate.failure(errorMessage));
  }
}

function* setTimeNeeded({ payload }: ReturnType<typeof actions.setTimeNeeded.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(taskApi.setTimeNeeded, semesterId, payload);
  const { ok, ...response } = yield call(callApi, apiCall);

  if (ok) {
    yield put(actions.setTimeNeeded.success(payload));
  } else {
    yield put(actions.setTimeNeeded.failure(getErrorMessage(response)));
  }
}

function* addPageRangeForTasks({ payload }: ReturnType<typeof actions.addPageRangeForTasks.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(taskApi.addPageRangesForTasks, semesterId, payload);
  const { ok, ...response } = yield call(callApi, apiCall);

  if (ok) {
    yield put(actions.addPageRangeForTasks.success(response.data));
  } else {
    yield put(actions.addPageRangeForTasks.failure(getErrorMessage(response)));
  }
}

function* createTasks(action: ReturnType<typeof actions.createTasks.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const {
    taskCategoryId,
    readingSourceId,
    tasks,
    title,
    colorHex,
    courseCorrelationId,
    workloadTable,
    isFromSidebar,
    planTaskEvent,
    callback
  } = action.payload;
  const apiCall = call(taskApi.createTasks, semesterId, tasks as CreateTaskDto[]);

  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(
      actions.createTasks.success({
        tasks: response.data,
        taskCategoryId,
        readingSourceId,
        title,
        colorHex,
        courseCorrelationId,
        workloadTable,
        isFromSidebar
      })
    );

    if (planTaskEvent && response.data.length > 0) {
      yield put(
        calendarActions.planTasks.request([
          { starts: planTaskEvent.start, ends: planTaskEvent.end, taskId: response.data[0].taskId }
        ])
      );
    } else if (callback) {
      callback();
    }

    yield put(
      possibleTermStateChange({
        fromState: SemesterStatus.COURSE_CREATED,
        toState: SemesterStatus.TASK_CREATED
      })
    );
    yield put(
      possibleTermStateChange({
        fromState: SemesterStatus.QUICK_SETUP_DONE,
        toState: SemesterStatus.TASK_CREATED
      })
    );
    yield put(
      possibleTermStateChange({ fromState: SemesterStatus.ACTIVITY_SETUP_DONE, toState: SemesterStatus.TASK_CREATED })
    );
    const numberOfTasks = response.data.length;
    alert(`${numberOfTasks} ${numberOfTasks > 1 ? t.TASKS_CREATED : t.TASK_CREATED}`, NotificationMode.SUCCESS);
    yield fork(gaAnalytics.track, AnalyticsEvent.CreateTask.name, AnalyticsEvent.CreateTask.params(numberOfTasks));
  } else {
    yield put(actions.createTasks.failure(getErrorMessage(response)));
  }
}

function* changeTaskCategory(action: ReturnType<typeof actions.changeTaskCategory.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  //@ts-ignore
  const apiCall = call(taskApi.changeTaskCategory, semesterId, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.changeTaskCategory.success({ taskIds: action.payload.taskIds, category: response.data }));
  } else {
    yield put(actions.changeTaskCategory.failure(getErrorMessage(response)));
  }
}

function* changeReadingSource(action: ReturnType<typeof actions.changeReadingSource.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  //@ts-ignore
  const apiCall = call(taskApi.changeReadingSource, semesterId, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.changeReadingSource.success(response.data));
  } else {
    yield put(actions.changeReadingSource.failure(getErrorMessage(response)));
  }
}

function* changeCourse(action: ReturnType<typeof actions.changeCourse.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { courseName, ...payload } = action.payload;
  //@ts-ignore
  const apiCall = call(taskApi.changeCourse, semesterId, payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.changeCourse.success(response.data));
    if (courseName) {
      alert(t.CHANGE_COURSE_NOTIFICATION(payload.taskIds.length, courseName), NotificationMode.SUCCESS);
    }
  } else {
    yield put(actions.changeCourse.failure(getErrorMessage(response)));
  }
}

function* reorderSubtasks(action: ReturnType<typeof actions.reorderSubtasks.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { id, subtasks } = action.payload;
  const { ok, ...response } = yield apiCall(taskApi.reorderSubtasks, semesterId, id, {
    items: subtasks.map(s => {
      return { id: s.id!, ordinalNumber: s.ordinalNumber! };
    })
  });
  if (ok) {
    yield put(actions.reorderSubtasks.success({ id, subtasks }));
  } else {
    yield put(actions.reorderSubtasks.failure(getErrorMessage(response)));
  }
}

function* addPlannedTask(action: ReturnType<typeof actions.addPlannedTask.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(plannerApi.addPlannedTask, semesterId, action.payload.taskId);
  if (ok) {
    yield put(actions.addPlannedTask.success({ ...response.data, ...action.payload }));
  } else {
    yield put(actions.addPlannedTask.failure(getErrorMessage(response)));
  }
}

// function* getCourses() {
//   const semesterId = yield select(semesterSelectors.getId);
//   const apiCall = call(courseApi.getAllNames, semesterId);
//   const { ok, ...response } = yield call(callApi, apiCall);
//   if (ok) {
//     const courses = response.data;
//     yield put(actions.getCourses.success(courses));
//   } else {
//     yield put(actions.getCourses.failure(getErrorMessage(response)));
//   }
// }
//
// function* getGradeUnits({ payload }: { payload: GetGradeUnitsRequest }) {
//   const semesterId = yield select(semesterSelectors.getId);
//   const apiCall = call(courseApi.getGradeUnitNamesByType, semesterId, payload.courseId, payload.type);
//   const { ok, ...response } = yield call(callApi, apiCall);
//   if (ok) {
//     yield put(actions.getGradeUnits.success(response.data));
//   } else {
//     yield put(actions.getGradeUnits.failure(getErrorMessage(response)));
//   }
// }
//
// function* getReadingSources({ payload }: { payload: GetReadingSourcesRequest }) {
//   const semesterId = yield select(semesterSelectors.getId);
//   const apiCall = call(courseApi.getReadingSourceNames, semesterId, payload.courseId);
//   const { ok, ...response } = yield call(callApi, apiCall);
//   if (ok) {
//     yield put(actions.getReadingSources.success(response.data));
//   } else {
//     yield put(actions.getReadingSources.failure(getErrorMessage(response)));
//   }
// }
//
// function* getClassAppearancesOnDate({ payload }: { payload: ClassAppearancesRequest }) {
//   const semesterId = yield select(semesterSelectors.getId);
//   const apiCall = call(taskApi.getClassAppearancesOnDate, semesterId, payload);
//   const { ok, ...response } = yield call(callApi, apiCall);
//   if (ok) {
//     yield put(actions.getClassAppearancesOnDate.success(response.data));
//   } else {
//     yield put(actions.getClassAppearancesOnDate.failure(getErrorMessage(response)));
//   }
// }

export default function*() {
  yield all([
    takeLatest(actions.saveManageTask.request, saveManageTask),
    takeLatest(actions.getPageRanges.request, getPageRanges),
    takeLatest(actions.savePageRanges.request, savePageRanges),
    takeLatest(actions.addOrUpdateSubtask.request, addOrUpdateSubtask),
    takeLatest(actions.deleteSubtask.request, deleteSubtask),
    takeLatest(actions.updateTaskStatus.request, updateTaskStatus),
    takeLatest(actions.moveToTodo.request, moveToTodo),
    takeLatest(actions.deleteTask.request, deleteTask),
    takeLatest(actions.createTasks.request, createTasks),
    takeLatest(actions.getManageTask.request, getManageTask),
    takeLatest(actions.deleteTasks.request, deleteTasks),
    takeLatest(actions.completeTasks.request, completeTasks),
    takeLatest(actions.moveTasksToTodo.request, moveTasksToTodo),
    takeLatest(actions.setTasksStartAhead.request, setTasksStartAhead),
    takeLatest(actions.setTasksPriority.request, setTasksPriority),
    takeLatest(actions.pushTasksDueDate.request, pushTasksDueDate),
    takeLatest(actions.setTimeNeeded.request, setTimeNeeded),
    takeLatest(actions.addPageRangeForTasks.request, addPageRangeForTasks),
    takeLatest(actions.changeCourse.request, changeCourse),
    takeLatest(actions.changeTaskCategory.request, changeTaskCategory),
    takeLatest(actions.changeReadingSource.request, changeReadingSource),
    takeLatest(actions.reorderSubtasks.request, reorderSubtasks),
    takeLatest(actions.restoreTasks.request, restoreTasks),
    takeLatest(actions.addPlannedTask.request, addPlannedTask)

    // takeLatest(actions.getGradeUnitTask.request, getGradeUnitTask),
    // takeLatest(actions.getReadingTask.request, getReadingTask),
    // takeLatest(actions.getExtraTask.request, getExtraTask),
    // takeLatest(actions.getCourses.request, getCourses),
    // takeLatest(actions.getGradeUnits.request, getGradeUnits),
    // takeLatest(actions.getReadingSources.request, getReadingSources),
    // takeLatest(actions.getClassAppearancesOnDate.request, getClassAppearancesOnDate),
  ]);
}
