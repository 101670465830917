import styled from 'styled-components';
import { Column, Row } from '../common/layoutUtils';
import colors from '../../utils/colors';
import image from '../../assets/images/login.jpg';
import { LargeText, MediumText } from '../../utils/typography';
import TextLink from '../common/TextLink';

export const AuthContainer = styled.div<{ mobile?: boolean }>`
  display: flex;
  flex: 1;
  background-position: center;
  background-color: ${props => props.theme.background};
  ${props => !props.mobile && props.theme.displayAuthImage && `background-image: url(${image})`};
  background-size: cover;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: ${props => (props.mobile ? 30 : 25)}vh;
`;

export const AuthForm = styled.div<{ width?: number }>`
  width: ${props => props.width || 240}px;
`;

export const AuthLinks = styled(Column)<{ mobile?: boolean }>`
  width: ${props => (props.mobile ? 240 : 400)}px;
  border-top: 1px solid ${colors.lightgray};
  padding-top: 6px;
  text-align: left;
  margin-bottom: 30px;
`;

export const AuthText = styled(Column)<{ mobile?: boolean }>`
  align-items: flex-start;
  width: ${props => (props.mobile ? 240 : 400)}px;
  box-sizing: border-box;

  > ${LargeText}, > ${MediumText} {
    width: 100%;
    text-align: center;
  }

  > div,
  > span {
    margin-bottom: 20px;
  }
`;

export const AuthFooter = styled(Row)<{ mobile?: boolean }>`
  ${props =>
    props.mobile
      ? `
    position: absolute;
    bottom: 0;
    margin-bottom: 40px;
 `
      : ''}
`;

export const AuthMobileLink = styled(TextLink)<{ mobile?: boolean }>`
  text-align: right;
  ${props => props.mobile && `margin-bottom: 10px`};
`;
