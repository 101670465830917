import { actionCreator, asyncActionsCreator } from '../common/utils';
import { TaskCategoryDto, WorkloadTask } from 'shovel-lib/types';
import { SidebarItemType } from './types';

const asyncActions = asyncActionsCreator('@@workload');
const action = actionCreator('@@workload');

export const getWorkload = asyncActions<number | undefined, WorkloadTask[], string>('GET_WORKLOAD');

export const resetAddedWorkloadTasks = action<void>('RESET_ADDED_WORKLOAD_TASKS');

export const startCourseWorkloadListener = action<undefined>('START_COURSE_WORKLOAD_LISTENER');

export const stopCourseWorkloadListener = action<undefined>('STOP_COURSE_WORKLOAD_LISTENER');

export const selectCommitment = action<{
  id: string | number;
  type: SidebarItemType;
  name: string;
  colorHex?: string;
} | void>('SELECT_COMMITMENT');

// action to stop the forced skeleton
export const selectCommitmentSuccess = action<void>('SELECT_COMMITMENT_SUCCESS');

export const toggleCourseSection = action<{ id: string | number; expand: boolean }>('TOGGLE_COURSE_SECTION');
