import { create, ApisauceInstance, ApiErrorResponse } from 'apisauce';
import Reactotron from 'reactotron-react-js';

import { urls, useReactotron } from '../config/configConstants';
import { initApi } from 'shovel-lib';

const AUTHORIZATION_HEADER_NAME = 'Authorization';
/**
 * Network instance that wraps the request/response interaction with our backend.
 *
 * Important flow to understand:
 * When user logs into the application, the authorization header will be set via {@link setAccessToken} method.
 * Every following request will hold this header until either the access token expires or user logs out.
 * - When access token expires, the {@link refreshToken} operation will again set the header.
 *
 * This may look odd in situation where if multiple requests are executed concurrently, one may think that
 * if token expires in between, all requests will reuse the expired access token and fail,
 * however the responsibility of operations {@link refreshToken} and {@link callApi} will be
 * to synchronize the refreshing of the access token, calling the {@link setAccessToken} only once
 * and re-trying the requests afterwards.
 **/
export const network: ApisauceInstance = create({
  baseURL: urls.backend
});

export const eventsNetwork: ApisauceInstance = create({
  baseURL: urls.eventService
});
export const lambdaNetwork: ApisauceInstance = create({
  baseURL: urls.lambda
});

export const serverIssueStatusCodes = [null, 502, 503, 504];

/**
 * Redirect to server error page in case response status is any of these: null, 502, 503, 504
 */
network.addMonitor(response => {
  if (serverIssueStatusCodes.includes(response.status ?? null)) {
    window.location.href = 'https://dig.shovelapp.io/server-error';
  }
});

export const setAccessToken = (accessToken: string) => {
  network.setHeader(AUTHORIZATION_HEADER_NAME, `Bearer ${accessToken}`);
  eventsNetwork.setHeader(AUTHORIZATION_HEADER_NAME, `Bearer ${accessToken}`);
  lambdaNetwork.setHeader(AUTHORIZATION_HEADER_NAME, `Bearer ${accessToken}`);
};

export const removeAccessToken = () => {
  network.deleteHeader(AUTHORIZATION_HEADER_NAME);
  eventsNetwork.deleteHeader(AUTHORIZATION_HEADER_NAME);
  lambdaNetwork.deleteHeader(AUTHORIZATION_HEADER_NAME);
};

/**
 * helper method to prevent app crashing when backend is down (response.data is null in that case)
 */
export const getErrorMessage = (response: ApiErrorResponse<any>) => response.data && response.data.message;

export const getErrorCode = (response: ApiErrorResponse<any>) =>
  response.data && response.data.errorCodes && response.data.errorCodes[0];

if (useReactotron) {
  // @ts-ignore
  network.addMonitor(Reactotron.apisauce);
  // @ts-ignore
  eventsNetwork.addMonitor(Reactotron.apisauce);
  // @ts-ignore
  lambdaNetwork.addMonitor(Reactotron.apisauce);
}

initApi(network, eventsNetwork);
