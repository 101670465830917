import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import colors from '@utils/colors';
import { MiniText, RegularText } from '@utils/typography';
import Popup from 'reactjs-popup';
import { FormInput } from '../Input.styles';
import { Column } from '../../layoutUtils';
import { hasValue } from '@utils/validators';
import InputWrapper from '@components/common/inputs/InputWrapper';
import { DropDownArrow } from '@utils/popupUtils';

export type DropDownInputItemType = {
  label: string;
  value: any;
  color?: string;
  backgroundColor?: string;
  meta?: any;
  category?: any;
};

export type DropDownInputCategoryType = { label: string; value: any; options: DropDownInputItemType[] };

type Props = {
  onChange: (value: any, item: DropDownInputItemType) => void;
  disabled?: boolean;
  initialValue?: any;
  label?: string;
  name?: string;
  className?: string;
  placeholder?: string;
  required?: boolean;
  noErrorMargin?: boolean;
  theme?: {
    minWidth?: string;
    maxWidth?: string;
    maxHeight?: string;
  };
  onBlur?: (value: any) => void;
  error?: string;
  touched?: boolean;
  field?: any;
  form?: any;
  smallOptions?: boolean;
  maxHeight?: string;
} & (
  | { categorized?: false; options: Array<DropDownInputItemType> }
  | { categorized: true; options: Array<DropDownInputCategoryType> }
);

const initialOption = (
  initial: any,
  options: DropDownInputItemType[] | DropDownInputCategoryType[],
  categorized?: boolean
) => {
  if (!hasValue(initial)) return undefined;
  if (categorized) {
    let initialOption;
    for (const category of options as DropDownInputCategoryType[]) {
      initialOption = category.options.find(option => option.value === initial);
      if (initialOption) break;
    }
    return initialOption;
  }
  return (options as DropDownInputItemType[]).find(option => option.value === initial);
};

const DropDownInput: FC<Props> = ({
  options,
  initialValue,
  name,
  onChange,
  label,
  placeholder,
  theme,
  disabled,
  className,
  required = true,
  error,
  touched,
  categorized,
  smallOptions,
  noErrorMargin,
  maxHeight
}) => {
  const [selectedOption, setSelectedOption] = useState(initialOption(initialValue, options, categorized));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelectedOption(initialOption(initialValue, options, categorized));
  }, [initialValue, options]);

  const handleChange = (item: DropDownInputItemType, close: () => void) => {
    onChange(item.value, item);
    setSelectedOption(item);
    close();
  };

  const renderTrigger = () => {
    return (
      <div>
        <Wrapper
          open={open}
          error={error}
          noErrorMargin={noErrorMargin}
          disabled={disabled}
          icon={<DropDownArrow name={'keyboard_arrow_down'} open={open} disabled={disabled} color={selectedOption?.color} />}
          label={label}
          placeholder={placeholder}
          touched={touched}
          required={required}
        >
          <FormInput
            color={selectedOption?.color}
            value={selectedOption?.label || ''}
            placeholder={placeholder}
            disabled={disabled}
            discardDisabledStyles={!disabled}
            readOnly
          />
        </Wrapper>
      </div>
    );
  };

  const renderTarget = close => {
    return (
      <OptionsWrapper data-cy={'options'} maxHeight={maxHeight || theme?.maxHeight}>
        {!categorized
          ? (options as DropDownInputItemType[]).map(item => (
              <DropDownItem
                smallOptions={smallOptions}
                fontSize={label ? 13 : 12}
                key={item.value}
                onClick={() => handleChange(item, close)}
                selected={!!selectedOption && selectedOption.value === item.value}
                hoverColor={item.color}
              >
                {item.label}
              </DropDownItem>
            ))
          : (options as DropDownInputCategoryType[]).map(category => (
              <Category key={category.label}>
                <MiniText>{category.label}</MiniText>
                {category.options.map(item => (
                  <DropDownItem
                    fontSize={label ? 13 : 12}
                    key={item.value}
                    onClick={() => handleChange({ ...item, category: category.value }, close)}
                    selected={!!selectedOption && selectedOption.value === item.value}
                    hoverColor={item.color}
                  >
                    {item.label}
                  </DropDownItem>
                ))}
              </Category>
            ))}
      </OptionsWrapper>
    );
  };

  return (
    <Container
      minWidth={theme && theme.minWidth}
      maxWidth={theme && theme.maxWidth}
      data-cy={name}
      disabled={disabled}
      className={`${open ? 'open ' : ''}${className || ''}`}
    >
      <Popup
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        trigger={renderTrigger()}
        position={'bottom center'}
        on="click"
        contentStyle={{
          width: '100%',
          margin: 0,
          borderRadius: '5px',
          border: 'none',
          padding: 0,
          overflow: 'hidden'
        }}
        arrowStyle={{ display: 'none' }}
        disabled={disabled}
      >
        {renderTarget}
      </Popup>
    </Container>
  );
};

const Wrapper = styled(InputWrapper)<{ open?: boolean; disabled?: boolean }>`
  ${props => props.open && `border-color: ${colors.primaryPurple}`};

  input {
    text-overflow: ellipsis;
    ${props => !props.disabled && `cursor: pointer !important;`};
  }
  > span:first-child {
    // label
    ${props => props.open && `color: ${colors.primaryPurple}`};
  }
`;

const Container = styled.div<{ minWidth?: string; maxWidth?: string; open?: boolean; disabled?: boolean }>`
  width: 100%;
  position: relative;
  ${props =>
    props.minWidth &&
    `
    min-width: ${props.minWidth};
    ${Wrapper} { min-width: ${props.minWidth}; }
  `};
  ${props =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth};
    ${Wrapper} { max-width: ${props.maxWidth}; }
  `};

  .popup-content {
    background: ${props => props.theme.background} !important;
    box-shadow: 0px 6px 25px ${({ theme }) => theme.boxShadow} !important;
    z-index: 3 !important;
  }
`;

const DropDownItem = styled(RegularText)<{
  fontSize: number;
  selected: boolean;
  hoverColor?: string;
  smallOptions?: boolean;
}>`
  text-align: left;
  padding: ${props => (props.smallOptions ? '5px 10px' : '14px 16px')};
  cursor: pointer;
  font-size: ${props => props.fontSize}px;
  min-height: ${props => props.fontSize + 4}px;
  color: ${({ theme }) => theme.textStrongColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${({ theme }) => theme.background};

  &:hover {
    background-color: ${({ theme }) => theme.backgroundDark};
    ${props => props.hoverColor && `color: ${props.hoverColor}`};
  }

  ${props => props.selected && 'font-weight: bold'};
`;

const OptionsWrapper = styled.div<{ maxHeight?: string }>`
  display: flex;
  flex-direction: column;
  max-height: ${props => props.maxHeight || '360px'};
  overflow: auto;
  padding: 5px 0;
`;

const Category = styled(Column)`
  padding: 5px 0;
  flex-shrink: 0;
  ${MiniText} {
    padding: 0 10px;
    text-align: left;
  }
  ${DropDownItem} {
    padding: 5px 10px 5px 24px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid lightgray;
  }
`;

export default DropDownInput;
