import {
  PileAllToDoRequest,
  PileAllToDoResponse,
  PileFilterType,
  PileTaskRequest,
  TaskDto,
  TaskState,
  ThinCourseDto
} from 'shovel-lib/types';
import { actionCreator, asyncActionsCreator } from '../common/utils';
import { ApplyFilter, CalculateCushionResponse, PileSections } from './types';

const asyncActions = asyncActionsCreator('@@pile');
const action = actionCreator('@@pile');

export const getCourses = asyncActions<void, ThinCourseDto[], string>('PILE_GET_COURSES');

export const refresh = action<TaskState>('REFRESH');

export const applyFilter = action<ApplyFilter>('APPLY_FILTER');

export const applySearch = action<string>('APPLY_SEARCH');

export const startPileListener = action<undefined>('START_PILE_LISTENER');

export const stopPileListener = action<undefined>('STOP_PILE_LISTENER');

export const clearState = action<undefined>('CLEAR_STATE');

export const clearStateButKeepFilterAndSort = action<undefined>('CLEAR_STATE_BUT_KEEP_FILTER_AND_SORT');

export const getAllToDoTasks = asyncActions<
  PileAllToDoRequest & { isRefresh?: boolean },
  PileAllToDoResponse & { page: number; isRefresh?: boolean },
  string
>('GET_ALL_TO_DO_TASKS');

export const getWithinStartDateTasks = asyncActions<PileTaskRequest, TaskDto[], string>('GET_WITHIN_START_DATE_TASKS');

export const getOverdueTasks = asyncActions<PileTaskRequest, TaskDto[], string>('GET_OVERDUE_TASKS');

export const getCompletedTasks = asyncActions<PileTaskRequest, TaskDto[], string>('GET_COMPLETED_TASKS');

export const openMoveToDoDialog = action<any>('OPEN_MOVE_TODO_DIALOG');

export const closeMoveToDoDialog = action<void>('CLOSE_MOVE_TODO_DIALOG');

export const getNoDueDateTasks = asyncActions<PileTaskRequest, TaskDto[], string>('GET_NO_DUE_DATE_TASKS');

export const calculateCushion = asyncActions<undefined, CalculateCushionResponse, string>('CALCULATE_CUSHION');

export const clearCushion = action<void>('CLEAR_CUSHION');

export const getRecentlyCreated = asyncActions<undefined, TaskDto[], string>('GET_RECENTLY_CREATED');

export const toggleExpandPileSection = action<PileSections>('TOGGLE_EXPAND_PILE_SECTION');
