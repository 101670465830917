import React from 'react';
import styled, { withTheme } from 'styled-components';

import colors from '../../utils/colors';

type Props = {
  color?: string;
  highlightColor?: string;
  width?: string;
  height?: string;
  amount?: number;
  className?: string;
  theme: any;
  style?: any;
};
const Skeleton = ({ color, highlightColor, height, width, amount = 1, className, theme, style }: Props) => {
  const skeletons = [...Array(amount)].map((e, i) => (
    <SkeletonLine
      key={i}
      color={color || theme.backgroundDark}
      highlightColor={highlightColor || theme.background}
      width={width}
      height={height}
      className={className}
      style={style}
    />
  ));

  return <>{skeletons}</>;
};

export default withTheme(Skeleton);

const SkeletonLine = styled.div<{
  translucent?: boolean;
  color: string;
  highlightColor: string;
  width?: string;
  height?: string;
}>`
  display: inline-block;
  height: ${props => props.height || '100%'};
  width: ${props => props.width || '100%'};
  border-radius: 5px;
  background: linear-gradient(
    -90deg,
    ${props => props.color} 0%,
    ${props => props.highlightColor} 50%,
    ${props => props.color} 100%
  );
  background-size: 400% 400%;
  animation: pulse 3s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;
