import { all, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { getType } from 'typesafe-actions';
import { apiCall } from '../../common/operations';
import * as semesterSelectors from '../../semester/selectors';
import { gradeApi } from 'shovel-lib';
import { getErrorMessage } from '../../../api/network';
import { getOpenedCourseId } from '../selectors';

function* getGrades() {
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(getOpenedCourseId);
  const { ok, ...response } = yield apiCall(gradeApi.getByCourseId, semesterId, courseId);
  if (ok) {
    yield put(actions.getGrades.success(response.data));
  } else {
    yield put(actions.getGrades.failure(getErrorMessage(response)));
  }
}

function* createGrade(action: ReturnType<typeof actions.createGrade.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(getOpenedCourseId);
  const { ok, ...response } = yield apiCall(gradeApi.create, semesterId, courseId, action.payload);
  if (ok) {
    yield put(actions.createGrade.success({ ...action.payload, ...response.data }));
  } else {
    yield put(actions.createGrade.failure(getErrorMessage(response)));
  }
}

function* updateGrade(action: ReturnType<typeof actions.updateGrade.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(getOpenedCourseId);
  const { ok, ...response } = yield apiCall(gradeApi.update, semesterId, courseId, action.payload);
  if (ok) {
    yield put(actions.updateGrade.success({ ...action.payload, overallGrade: response.data.overallGrade }));
  } else {
    yield put(actions.updateGrade.failure(getErrorMessage(response)));
  }
}

function* updateGradeInfo(action: ReturnType<typeof actions.updateGradeInfo.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(getOpenedCourseId);
  const { ok, ...response } = yield apiCall(gradeApi.updateInfo, semesterId, courseId, action.payload);
  if (ok) {
    yield put(actions.updateGradeInfo.success({ ...action.payload }));
  } else {
    yield put(actions.updateGradeInfo.failure(getErrorMessage(response)));
  }
}

function* toggleGradeDrop(action: ReturnType<typeof actions.toggleGradeDrop.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(getOpenedCourseId);
  const { ok, ...response } = yield apiCall(gradeApi.toggleDrop, semesterId, courseId, action.payload);
  if (ok) {
    yield put(actions.toggleGradeDrop.success(response.data));
  } else {
    yield put(actions.toggleGradeDrop.failure(getErrorMessage(response)));
  }
}

function* deleteGrade(action: ReturnType<typeof actions.deleteGrade.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(getOpenedCourseId);
  const { ok, ...response } = yield apiCall(gradeApi.deleteGrade, semesterId, courseId, action.payload);
  if (ok) {
    yield put(actions.deleteGrade.success(response.data));
  } else {
    yield put(actions.deleteGrade.failure(getErrorMessage(response)));
  }
}

export default function*() {
  yield all([takeLatest(getType(actions.getGrades.request), getGrades)]);
  yield all([takeLatest(getType(actions.createGrade.request), createGrade)]);
  yield all([takeLatest(getType(actions.updateGrade.request), updateGrade)]);
  yield all([takeLatest(getType(actions.updateGradeInfo.request), updateGradeInfo)]);
  yield all([takeLatest(getType(actions.toggleGradeDrop.request), toggleGradeDrop)]);
  yield all([takeLatest(getType(actions.deleteGrade.request), deleteGrade)]);
}
