import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import styled, { withTheme } from 'styled-components';

import { TooltipPosition } from '@utils/popupUtils';
import { SmallText } from '@utils/typography';
import { AlignType } from '@state/common/utils';

type Props = {
  target: React.ReactElement;
  content: string;
  position?: TooltipPosition;
  width?: string;
  align?: AlignType;
  theme: any;
  preformattedText?: boolean;
  withoutArrow?: boolean;
};

const Tooltip: FC<Props> = ({
  target,
  content,
  position = 'bottom center',
  width,
  align,
  theme,
  preformattedText,
  withoutArrow = false
}) => {
  return (
    <Popup
      trigger={target}
      position={position}
      on="hover"
      arrowStyle={
        withoutArrow
          ? { display: 'none' }
          : {
              boxShadow: 'none',
              borderRadius: '1px',
              backgroundColor: theme.textStrongColor
            }
      }
      contentStyle={{
        border: 0,
        borderRadius: '5px',
        backgroundColor: theme.textStrongColor,
        width: width || 'fit-content',
        maxWidth: width || '140px',
        padding: '8px 16px',
        fontSize: '11px',
        lineHeight: '12px',
        zIndex: 999,
        ...(align ? { textAlign: align } : undefined),
        ...(preformattedText ? { whiteSpace: 'pre' } : undefined)
      }}
    >
      <Content>{content}</Content>
    </Popup>
  );
};

export default withTheme(Tooltip);

const Content = styled(SmallText)`
  color: ${({ theme }) => theme.tooltipTextColor};
  text-transform: none;
  white-space: break-spaces;
  word-break: keep-all;
`;
