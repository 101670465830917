export const rawColors = {
  purple: '#786FFF',
  purpleLight: '#E4E2FF',
  darkPurple: '#6059D5',
  pink1: '#F6789D',
  pink2: '#D26AF8',
  turquoise1: '#4fb2aa',
  blue1: '#1976D2',
  orange1: '#FE8773',
  gray1: '#6d8198',
  gray2: '#2c2f34',
  red1: '#c54d67',

  flamingo: '#FF63B6',
  sapphire: '#5DE3D9',
  tangerine: '#FE8773'
};
