import React, { PureComponent } from 'react';
import InlineDropDownInput, { renderItemIcon } from '@components/common/inputs/dropdown/InlineDropDownInput';
import t from '@i18n/t';
import MaterialIcon from '@components/common/icons/MaterialIcon';
import styled from 'styled-components';
import { FlexRow, Row } from '@components/common/layoutUtils';
import TippyTooltip from '@components/common/tooltip/TippyTooltip';

export const priorityColors = {
  1: '#79D0F5',
  2: '#FFD75C',
  3: '#D14C37'
};

const noPriority = { label: t.NONE, value: 0, icon: 'outlined_flag' };
export const priorityOptions = [
  { label: t.HIGH, value: 3, icon: <MaterialIcon name={'flag'} color={priorityColors['3']} /> },
  { label: t.MEDIUM, value: 2, icon: <MaterialIcon name={'flag'} color={priorityColors['2']} /> },
  { label: t.LOW, value: 1, icon: <MaterialIcon name={'flag'} color={priorityColors['1']} /> },
  noPriority
];

type Props = {
  right?: boolean;
  priority: number | null;
  onChange?: (priority: number, e: any) => void;
  padding?: number;
  fontSize?: number;
  className?: string;
  triggerStyle?: any;
  noCheck?: boolean;
  theme?: any;
  tippy?: boolean;
};

class TaskPriority extends PureComponent<Props> {
  render() {
    const {
      priority = 0,
      right,
      onChange,
      padding = 2,
      fontSize,
      className,
      triggerStyle,
      noCheck,
      theme,
      tippy = true
    } = this.props;
    const Wrapper = !onChange ? Row : FlexRow;
    const trigger = item => (
      <Wrapper>
        <TippyTooltip
          target={
            <TriggerWrapper padding={padding} fontSize={fontSize} onClick={e => e.preventDefault()} style={triggerStyle}>
              {renderItemIcon(item?.icon, theme) || <MaterialIcon name={'outlined_flag'} color={theme?.textColor} />}
            </TriggerWrapper>
          }
          content={item?.value ? `${item!.label} ${t.PRIORITY.toLowerCase()}` : t.SET_PRIORITY}
        />
      </Wrapper>
    );

    if (!onChange) {
      const selected = priorityOptions.find(o => o.value === priority) || noPriority;
      return trigger(selected);
    }

    return (
      <InlineDropDownInput
        onChange={onChange}
        className={className}
        options={priorityOptions}
        initialValue={priority}
        right={right}
        tippy={tippy}
        noCheck={noCheck}
        customTrigger={trigger}
      />
    );
  }
}

export default TaskPriority;

const TriggerWrapper = styled(Row)<{ padding: number; fontSize?: number }>`
  padding: ${props => props.padding}px;
  ${props => props.fontSize && `i { font-size: ${props.fontSize}px;}`};
  cursor: pointer !important;
`;
