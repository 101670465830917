import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import styled, { withTheme } from 'styled-components';

import { PileFilterType, PileStateOptions, ThinActivityDto, ThinCourseDto } from 'shovel-lib/types';
import CheckBox from '../../common/CheckBox';
import t from '../../../i18n/t';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../../state/rootReducer';
import { getPileFilterState } from '../../../state/pile/selectors';
import { initialFilterState } from '../../../state/pile/reducers';
import { applyFilter, getCourses } from '../../../state/pile/actions';
import { connect } from 'react-redux';
import { Column, FlexRow, Row } from '../../common/layoutUtils';
import { MediumText, RegularText } from '../../../utils/typography';
import colors from '../../../utils/colors';
import MaterialIcon from '../../common/icons/MaterialIcon';
import { isFilterActive } from './utils';
import { commonArrowCSS, commonPopupCSS, filterColumnDropdownStyle, filterShadow } from '../../../utils/popupUtils';
import ScrollGradient from '../../common/ScrollGradient';
import TippyTooltip from '../../common/tooltip/TippyTooltip';
import Switch, { SwitchOptionType } from '@components/common/Switch';
import RadioButton from '@components/common/radioButton/RadioButton';
import { priorityOptions } from '@components/pile/components/TaskPriority';

type PropsFromState = {
  filter: PileFilterType;
  courses: ThinCourseDto[];
  activities: ThinActivityDto[];
  activeTab: PileStateOptions;
  search: string;
  sort: any;
};

type PropsFromDispatch = {
  getCourses: typeof getCourses.request;
  applyFilter: typeof applyFilter;
};

type OwnProps = {
  theme?: any;
  handleTabChange: (value: PileStateOptions, initialFilter?: boolean) => void;
  disabled?: any;
};

type Props = PropsFromState & PropsFromDispatch & OwnProps;

type State = { filter: PileFilterType; activitiesCollapsed: boolean; coursesCollapsed: boolean };
const taskOptions: Array<SwitchOptionType> = [
  { label: t.ALL_TODO, value: PileStateOptions.ACTIVE, color: colors.primaryPurple },
  { label: t.COMPLETED, value: PileStateOptions.COMPLETED, color: colors.positive }
];

class PileFilter extends Component<Props, State> {
  state: State = { filter: this.props.filter, activitiesCollapsed: true, coursesCollapsed: true };

  componentDidMount() {
    const { courses, getCourses } = this.props;
    if (courses.length === 0) {
      getCourses();
    }
  }

  toggleActivitiesCollapsed = (e: any, value?: boolean) =>
    this.setState({ activitiesCollapsed: value !== undefined ? value : !this.state.activitiesCollapsed });

  toggleCoursesCollapsed = (e: any, value?: boolean) =>
    this.setState({ coursesCollapsed: value !== undefined ? value : !this.state.coursesCollapsed });

  resetFilter = () => {
    if (this.props.activeTab === PileStateOptions.UPCOMING) {
      this.props.handleTabChange(PileStateOptions.ACTIVE, true);
    } else {
      // for some reason initialFilterState for priorities returns non empty array
      this.onSubmit({ ...initialFilterState, priorities: [] });
    }
  };

  onSubmit = (filter: PileFilterType) => {
    const { activeTab, applyFilter, search, sort } = this.props;
    applyFilter({ activeTab, filter, search, sort });
  };

  onNegativeCushionToggle = () =>
    this.onSubmit({ ...this.props.filter, negativeCushionMarked: !this.props.filter.negativeCushionMarked });

  onHidePlannedTasksToggle = () =>
    this.onSubmit({ ...this.props.filter, hidePlannedMarked: !this.props.filter.hidePlannedMarked });

  onClassToggle = (courseId: number | string) => {
    const courseIds = this.handleArrayItemToggle(this.props.filter.courseIds.slice(0), courseId);
    this.onSubmit({ ...this.props.filter, courseIds });
  };

  handleArrayItemToggle = (array: any[], item: any) => {
    const newArray = array.slice(0);
    const index = newArray.indexOf(item);
    if (index !== -1) {
      newArray.splice(index, 1);
    } else {
      newArray.push(item);
    }
    return newArray;
  };

  isItemInArray = (array: any[], item: any) => {
    return array.indexOf(item) !== -1;
  };

  clearItems = (listName: 'courses' | 'activities') => {
    const { filter } = this.props;
    const list = this.props[listName];
    const ids = list.map(item => item.id);
    const courseIds = filter.courseIds.filter(id => !this.isItemInArray(ids, id));
    this.onSubmit({ ...filter, courseIds });
  };

  static getTaskStyle = (checked: boolean) => {
    return { opacity: checked ? 1 : 0.4 };
  };

  renderHidePlannedAndNegativeCushionSection = () => {
    const {
      filter: { negativeCushionMarked, hidePlannedMarked }
    } = this.props;

    const negativeCushionEl = (
      <Item>
        <CheckBox
          label={t.NEGATIVE_CUSHION}
          checked={negativeCushionMarked}
          onChange={() => this.onNegativeCushionToggle()}
        />
      </Item>
    );

    return (
      <Section>
        <Item>
          <CheckBox
            label={t.HIDE_PLANNED_TASKS}
            checked={hidePlannedMarked}
            onChange={() => this.onHidePlannedTasksToggle()}
          />
        </Item>
      </Section>
    );
  };

  onPriorityChanged = (priorities: number[], priority: number) => {
    const index = priorities.indexOf(priority);
    const updatedPriorities = priorities;
    if (index !== -1) {
      updatedPriorities.splice(index, 1);
    } else {
      updatedPriorities.push(priority);
    }
    this.onSubmit({ ...this.props.filter, priorities: updatedPriorities });
  };

  render() {
    const {
      activeTab,
      courses,
      activities,
      filter: { courseIds, examMarked, last30Days, lastWeek, thisWeek, next7Days, nextWeek, priorities = [] },
      handleTabChange,
      disabled
    } = this.props;
    const { coursesCollapsed, activitiesCollapsed } = this.state;

    const isCompletedTab = activeTab === PileStateOptions.COMPLETED;
    const isActive = isFilterActive(this.props.filter, activeTab);

    const classesSelected = courses.reduce((acc, c) => acc + (this.isItemInArray(courseIds, c.id) ? 1 : 0), 0);
    const activitiesSelected = activities.reduce((acc, a) => acc + (this.isItemInArray(courseIds, a.id) ? 1 : 0), 0);

    const withinStartDate = activeTab === PileStateOptions.UPCOMING;

    return (
      <FilterWrapper>
        <Popup
          className={'pile-filter'}
          onClose={() => this.setState({ activitiesCollapsed: true, coursesCollapsed: true })}
          trigger={open => {
            const content = disabled ? t.FILTER_EMPTY : t.FILTER_TASKS;
            return (
              <PileFilterToggler style={{ cursor: 'pointer' }}>
                <Filter color={isCompletedTab ? colors.positive : undefined}>
                  <TippyTooltip
                    target={
                      <Column>
                        <MaterialIcon name={'filter_alt'} color={colors.white} size={16} />
                      </Column>
                    }
                    content={content}
                  />
                  {isActive && (
                    <TippyTooltip
                      target={
                        <IconWrapper>
                          <ClearIcon
                            name={'clear'}
                            color={colors.white}
                            size={13}
                            onClick={e => {
                              this.resetFilter();
                              e.stopPropagation();
                            }}
                          />
                        </IconWrapper>
                      }
                      content={t.CLEAR_ALL_FILTERS}
                    />
                  )}
                </Filter>
              </PileFilterToggler>
            );
          }}
          closeOnDocumentClick
          on={'click'}
          position={'bottom right'}
          contentStyle={{ ...filterColumnDropdownStyle, width: '288px' }}
          disabled={disabled}
        >
          <Container>
            <Switch
              options={taskOptions}
              active={activeTab === PileStateOptions.UPCOMING ? PileStateOptions.ACTIVE : activeTab}
              onChange={handleTabChange}
              wrapped
            />
            <Heading>
              <ClearAllButton disabled={!isActive} onClick={isActive ? this.resetFilter : undefined}>
                <Row>
                  {t.CLEAR_ALL}
                  <MaterialIcon
                    name={'clear'}
                    disabled={!isActive}
                    color={colors.negative}
                    size={16}
                    style={{ marginLeft: 2 }}
                  />
                </Row>
              </ClearAllButton>
            </Heading>
            {!isCompletedTab && (
              <Section>
                <SectionHeading>
                  <MediumText>{t.TASKS}</MediumText>
                </SectionHeading>
                <CoursesSection>
                  <Item>
                    <RadioButton
                      label={t.ALL}
                      checked={!examMarked}
                      onChange={() => this.onSubmit({ ...this.props.filter, examMarked: false })}
                    />
                  </Item>
                  <Item>
                    <RadioButton
                      label={t.EXAMS}
                      checked={examMarked}
                      onChange={() => this.onSubmit({ ...this.props.filter, examMarked: true })}
                    />
                  </Item>
                </CoursesSection>
              </Section>
            )}
            {!isCompletedTab && this.renderHidePlannedAndNegativeCushionSection()}
            <Section>
              <SectionHeading>
                <MediumText>{isCompletedTab ? t.DATE_COMPLETED : t.DUE}</MediumText>
              </SectionHeading>
              <CoursesSection>
                {isCompletedTab && (
                  <>
                    <Item>
                      <CheckBox
                        label={t.LAST_30_DAYS}
                        checked={last30Days}
                        onChange={() => this.onSubmit({ ...this.props.filter, last30Days: !last30Days })}
                      />
                    </Item>
                    <Item>
                      <CheckBox
                        label={t.LAST_WEEK}
                        checked={lastWeek}
                        onChange={() => this.onSubmit({ ...this.props.filter, lastWeek: !lastWeek })}
                      />
                    </Item>
                  </>
                )}
                <Item>
                  <CheckBox
                    label={t.THIS_WEEK}
                    checked={thisWeek}
                    onChange={() => this.onSubmit({ ...this.props.filter, thisWeek: !thisWeek })}
                  />
                </Item>
                {!isCompletedTab && (
                  <>
                    <Item>
                      <CheckBox
                        label={t.NEXT_7_DAYS}
                        checked={next7Days}
                        onChange={() => this.onSubmit({ ...this.props.filter, next7Days: !next7Days })}
                      />
                    </Item>
                    <Item>
                      <CheckBox
                        label={t.NEXT_WEEK}
                        checked={nextWeek}
                        onChange={() => this.onSubmit({ ...this.props.filter, nextWeek: !nextWeek })}
                      />
                    </Item>
                    <Item>
                      <CheckBox
                        label={t.WITHIN_START_DATE}
                        checked={withinStartDate}
                        onChange={() =>
                          handleTabChange(withinStartDate ? PileStateOptions.ACTIVE : PileStateOptions.UPCOMING)
                        }
                      />
                    </Item>
                  </>
                )}
              </CoursesSection>
            </Section>
            {!isCompletedTab && (
              <Section>
                <SectionHeading>
                  <MediumText>{t.PRIORITY}</MediumText>
                </SectionHeading>
                <Row>
                  {priorityOptions.map(priority => (
                    <Item key={priority.value} style={{ paddingRight: 0, flex: 'none' }}>
                      <CustomCheckbox
                        label={priority.label}
                        checked={priorities.indexOf(priority.value) !== -1}
                        onChange={() => this.onPriorityChanged(priorities, priority.value)}
                      />
                    </Item>
                  ))}
                </Row>
              </Section>
            )}
            <Section>
              <SectionHeading style={{ paddingLeft: 0 }}>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Row style={{ cursor: 'pointer' }} onClick={this.toggleCoursesCollapsed}>
                    <Arrow
                      name={'keyboard_arrow_down'}
                      collapsed={coursesCollapsed}
                      onClick={this.toggleCoursesCollapsed}
                      style={{ marginRight: 5 }}
                    />
                    <MediumText>{t.COURSES}</MediumText>
                  </Row>
                  {coursesCollapsed && classesSelected > 0 && (
                    <NumOfItems>
                      <MediumText color={colors.primaryPurple}>{classesSelected}</MediumText>
                      <MaterialIcon
                        name={'clear'}
                        color={colors.negative}
                        size={16}
                        style={{ marginLeft: 2 }}
                        onClick={() => this.clearItems('courses')}
                      />
                    </NumOfItems>
                  )}
                </Row>
              </SectionHeading>
              <CoursesSection collapsed={coursesCollapsed}>
                {courses.map(c => (
                  <Item key={c.id}>
                    <CheckBox
                      label={c.name}
                      checked={this.isItemInArray(courseIds, c.id)}
                      onChange={() => this.onClassToggle(c.id)}
                    />
                  </Item>
                ))}
              </CoursesSection>
            </Section>
            <Section>
              <SectionHeading style={{ paddingLeft: 0 }}>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Row style={{ cursor: 'pointer' }} onClick={this.toggleActivitiesCollapsed}>
                    <Arrow name={'keyboard_arrow_down'} collapsed={activitiesCollapsed} style={{ marginRight: 5 }} />
                    <MediumText>{t.ACTIVITIES}</MediumText>
                  </Row>
                  {activitiesCollapsed && activitiesSelected > 0 && (
                    <NumOfItems>
                      <MediumText color={colors.primaryPurple}>{activitiesSelected}</MediumText>
                      <MaterialIcon
                        name={'clear'}
                        color={colors.negative}
                        size={16}
                        style={{ marginLeft: 2 }}
                        onClick={() => this.clearItems('activities')}
                      />
                    </NumOfItems>
                  )}
                </Row>
              </SectionHeading>
              <CoursesSection collapsed={activitiesCollapsed}>
                {activities.map(a => (
                  <Item key={a.id}>
                    <CheckBox
                      label={a.name}
                      checked={this.isItemInArray(courseIds, a.id)}
                      onChange={() => this.onClassToggle(a.id)}
                    />
                  </Item>
                ))}
              </CoursesSection>
            </Section>
          </Container>
        </Popup>
      </FilterWrapper>
    );
  }
}

const mapStateToProps = (state: RootState) => getPileFilterState(state);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getCourses: getCourses.request, applyFilter: applyFilter }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(PileFilter));

const Section = styled.div`
  padding: 10px 0;
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  overflow: hidden;
  border-radius: 4px;
  padding: 10px 15px 0;

  ${Section}:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  }
`;

const Heading = styled(Row)`
  justify-content: flex-end;
  margin-top: 5px;
  + ${Section} {
    padding-top: 0;
  }
`;

export const ClearAllButton = styled(MediumText)<{ disabled: boolean }>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  ${props => props.disabled && `color: ${props.theme.captures};`};
  &:hover {
    ${props => !props.disabled && `opacity: 75%;`};
  }
  transition: all 0.2s ease;
`;

export const CoursesSection = styled(ScrollGradient)<{ collapsed?: boolean }>`
  max-height: ${props => (props.collapsed ? 0 : 170)}px;
  margin-top: ${props => (props.collapsed ? 0 : 5)}px;
  overflow: auto;
  transition: max-height 0.2s ease;
`;

const SectionHeading = styled.div`
  padding: 0px 10px;
  text-transform: uppercase;
`;

const Item = styled(FlexRow)`
  padding: 5px 10px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  height: 28px;

  span {
    font-size: 12px;
  }

  &:hover {
    background: ${props => props.theme.backgroundDark} !important;
  }
  transition: all 0.2s ease;
`;

export const Filter = styled.div<{ color?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.color || colors.primaryPurple};
  border-radius: 5px;
  padding: 6px;
  &:hover {
    background: ${props => props.color || colors.primaryPurple}BF;
  }
  transition: all 0.2s ease;
`;

export const ClearIcon = styled(MaterialIcon)`
  opacity: 0;
`;

export const FilterWrapper = styled.div`
  &:hover ${ClearIcon} {
    opacity: 1;
  }
  .pile-filter-arrow {
    ${commonArrowCSS}
  }
  .pile-filter-content {
    ${commonPopupCSS}
    ${filterShadow}
  }
`;

export const PileFilterToggler = styled(Row)`
  align-items: center;
  ${RegularText} {
    margin-right: 5px;
  }
`;

export const NoOfFilters = styled.div`
  min-width: 16px;
  border-radius: 5px;
  background: ${colors.white};
  > span {
    color: ${colors.primaryPurple};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
`;

export const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: -4px;
  right: -4px;
  border: 1px solid ${colors.white};
  border-radius: 50%;
  background: ${colors.negative};
`;

export const Arrow = styled(MaterialIcon)<{ collapsed?: boolean }>`
  transform: rotate(${props => (props.collapsed ? -90 : 0)}deg);
  transition: transform 0.2s ease;
  border-radius: 3px;
  &:hover {
    background: ${props => props.theme.backgroundDark};
  }
`;

const NumOfItems = styled(Row)`
  border: 1px solid ${colors.primaryPurple};
  border-radius: 5px;
  padding: 3px 2px 3px 5px;
  margin-top: -3px;
  margin-bottom: -3px;
`;

const CustomCheckbox = styled(CheckBox)`
  .checkbox-label {
    margin-left: 5px;
  }
`;
