import React, { FC, useState } from 'react';
import LottiePlayer from 'react-lottie-player';
import { AnimationSegment } from 'lottie-web';
import styled from 'styled-components';

import DarkModeAnimation from '@assets/lottie-animations/dark-mode2.json';
import TippyTooltip from '@components/common/tooltip/TippyTooltip';
import t from '@i18n/t';

type Props = {
  isDarkMode: boolean;
  toggleMode: (darkMode: boolean) => void;
};

const DarkMode: FC<Props> = ({ isDarkMode, toggleMode }) => {
  const [darkMode, setDark] = useState(isDarkMode);
  const [isReadyToAnimate, setReadyToAnimate] = useState(false); // Disable animation of toggle until first click
  const segmentsToPlay: AnimationSegment = !darkMode ? [0, 50] : [45, 100];
  const segmentToGoTo = darkMode ? 90 : 0;

  return (
    <TippyTooltip
      target={
        <Wrapper
          onClick={() => {
            if (!isReadyToAnimate) {
              setReadyToAnimate(true);
            }
            setDark(!darkMode);
          }}
        >
          <LottiePlayer
            play={isReadyToAnimate}
            animationData={DarkModeAnimation}
            loop={false}
            segments={segmentsToPlay}
            goTo={segmentToGoTo}
            // using onComplete we have very visible lag after animation is complete and theme changes
            onComplete={() => toggleMode(darkMode)}
            // onSegmentStart={() => setTimeout(() => toggleMode(darkMode), 60)}
          />
        </Wrapper>
      }
      position={'right'}
      content={t.DARK_MODE_TOOLTIP}
    />
  );
};

export default DarkMode;

const Wrapper = styled.div`
  //transform: rotate(-90deg);
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  > div {
    height: 100% !important;
  }
`;
