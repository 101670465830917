import React from 'react';
import Linkify from 'linkifyjs/react';
import styled, { css } from 'styled-components';
import { WeekdayStr } from 'rrule';
import { CalendarEvent, CalendarEventType, SemesterCalendarInfo } from 'shovel-lib/types';
import {
  DATE_FORMAT,
  formatDuration,
  isSameDate,
  now,
  timeDiffInMinutes,
  toMomentDate,
  toMomentUtcDate
} from 'shovel-lib/utils/timeUtils';
import { Moment } from 'moment';
import { NavLink } from 'react-router-dom';

import colors from '../colors';
import { CalendarView } from '../../state/calendar/types';
import t from '../../i18n/t';
import {
  calculationPrecondition,
  constructStudyOrExtraTime,
  extractFreeTimeBlocks,
  minutesPopulatedWithEvents
} from 'shovel-lib/utils/calendarFreeBlocksUtils';
import {
  IS_PLANNABLE,
  IS_PLANNABLE_OVERDUE,
  PLANNABLE_EVENT_CLASS_NAME,
  PLANNABLE_EVENT_GROUP_ID,
  PLANNABLE_OVERDUE_EVENT_CLASS_NAME
} from './calendarConstants';
import { minutesToDurationInHours } from '../filters';
import { hideAll } from 'tippy.js';
import { Column, EventTitle, FlexRow, getEventTitleStyle, Row, TextOverflowDiv } from '../../components/common/layoutUtils';
import MaterialIcon from '../../components/common/icons/MaterialIcon';
import MoreOptionDropdown, { OptionType } from '../../components/common/MoreOptionDropdown';
import { isMobileApp, isTouchScreen } from '../screenUtils';
import { PassedOverlay } from './calendarStyles';
import TippyTooltip from '@components/common/tooltip/TippyTooltip';
import { goToAwakeLink } from '../../components/navigation/navigationHelpers';
import { MORE_OPTIONS_CLASS_NAME } from '../../state/common/utils';
import Button from '../../components/common/buttons/Button';
import GroupedTasksEventsPopup from '../../components/calendar/dialogs/GroupedTasksEventsPopup';
import { capitalize } from '../textUtils';
import { CalendarMode } from '../../state/common/types';
import { Draggable } from '@fullcalendar/interaction';
import { CourseSource } from '../../state/ics/reducers';
import { EmojiPreview } from '@components/common/emoji/EmojiPreview';
import { MiniText } from '@utils/typography';
import { calendarStylingType } from '@state/settings/types';
import { priorityColors } from '@components/pile/components/TaskPriority';

const LOCAL_WEEK_FORMAT = 'YYYY-w';

export type PlannedTaskMarkers = {
  [key: string]: number;
};

const toPlannableMonthDay = (date: Moment) => ({
  start: date.startOf('day').toDate(),
  end: date.add(1, 'days').toDate(),
  groupId: PLANNABLE_EVENT_GROUP_ID,
  display: 'background'
});

export const reduceEventsToDaysMap = (acc: any, event: any) => {
  const startDate = toMomentDate(event.start);
  if (!acc[startDate.format(DATE_FORMAT)]) {
    acc[startDate.format(DATE_FORMAT)] = toPlannableMonthDay(startDate);
  }
  const endDate = toMomentDate(event.end);
  if (!isSameDate(startDate, endDate)) {
    acc[endDate.format(DATE_FORMAT)] = toPlannableMonthDay(endDate);
  }
  return acc;
};

export const eventRender = (
  info: any,
  userSettings: any,
  theme: any,
  getOptions: any,
  semesterId = 0,
  tasksGroupIdPopup: number = -1,
  closeTasksGroupPopup?: any,
  onTaskClick?: any,
  calendarZoom?: number,
  mode?: any
) => {
  const {
    event,
    view: { type: view }
  } = info;
  let { timeText } = info;

  // accessing properties that we set on event
  const { type } = event.extendedProps;
  const isMonthView = view === CalendarView.MONTH;
  const titleBgColor = mode === CalendarMode.STUDY_TIME ? 'inherit' : event.backgroundColor || theme.backgroundColor;
  const titleStyle = getEventTitleStyle(titleBgColor);
  if (timeText.endsWith('m') && timeText.split(' - ').length > 1) {
    const [start, end] = timeText.split(' - ');
    const startMeridiem = start.slice(-2);
    if (startMeridiem === end.slice(-2)) {
      timeText = `${start.replace(startMeridiem, '')} - ${end}`;
    }
  }

  switch (type) {
    case CourseSource.CANVAS:
    case CourseSource.BRIGHTSPACE:
    case CourseSource.MOODLE:
    case CalendarEventType.COURSE:
    case CalendarEventType.ACTIVITY:
    case CalendarEventType.EVENT: {
      const isEvent = type === CalendarEventType.EVENT;
      const isActivity = type === CalendarEventType.ACTIVITY;
      const hasSidebar = isActivity
        ? userSettings.activityEventStyle.sidebar
        : !isEvent
        ? userSettings.courseEventStyle.sidebar
        : false;

      if (isMonthView) {
        return (
          <Row style={{ flex: 1, minWidth: 0, justifyContent: 'space-between' }}>
            <Row style={{ flex: 1, minWidth: 0 }}>
              <div className="fc-event-time">{timeText}</div>
              <TextOverflowDiv className="fc-event-title">
                <span>{`${!event.allDay ? ' - ' : ''}${event.title}`}</span>
              </TextOverflowDiv>
            </Row>
            <MoreOptionDropdown options={getOptions(event)} triggerColor={event.textColor} />
            {event.extendedProps.passed || info.isPast || (now().isAfter(event.end) && <PassedOverlay />)}
          </Row>
        );
      } else {
        return (
          <div className="fc-event-main-frame">
            <div className="fc-event-time">{timeText}</div>
            <div className="fc-event-title-container">
              {!isEvent ? (
                <EventTitle className="fc-event-title fc-sticky" bgColor={titleBgColor}>
                  <span>{event.title}</span>
                </EventTitle>
              ) : (
                <div className="fc-event-title fc-sticky" style={titleStyle}>
                  <span>{event.title}</span>
                </div>
              )}
              {!isMobileApp && (
                <MoreOptionDropdown options={getOptions(event)} triggerColor={event.textColor} placeToTopRight />
              )}
              <TextOverflowDiv className="fc-location">
                {event.extendedProps.location && (
                  <>
                    <MaterialIcon name={'place'} outline size={10} color={event.textColor} />
                    <Linkify options={{ target: { url: '_blank' } }}>{event.extendedProps.location}</Linkify>
                  </>
                )}
              </TextOverflowDiv>
              <span className="fc-description">
                <Linkify options={{ target: { url: '_blank' } }}>{event.extendedProps.description || ''}</Linkify>
              </span>
            </div>
            {info.isPast && <PassedOverlay />}
            {!event.allDay && event.extendedProps.overlapsWithSleepTime && (
              <TippyTooltip
                target={
                  <WarningIcon>
                    <MaterialIcon name={'priority_high'} size={12} />
                  </WarningIcon>
                }
                content={t.EVENT_AWAKE_TIME_WARNING_TEXT}
                width={'210px'}
              />
            )}
            {hasSidebar && (
              <BorderBar noTopRadius={event.extendedProps.commuteBefore} noBottomRadius={event.extendedProps.commuteAfter} />
            )}
          </div>
        );
      }
    }
    case CalendarEventType.AWAKE_TIME: {
      const duration = timeDiffInMinutes(event.start, event.end);
      return (
        <div className="fc-event-main-frame">
          <div className="fc-event-time">{timeText}</div>
          <div className="fc-event-title-container">
            <div className="fc-event-title fc-sticky">
              {event.title} {minutesToDurationInHours(duration)}
            </div>
          </div>
        </div>
      );
    }
    case CalendarEventType.COMMUTE_BEFORE:
    case CalendarEventType.COMMUTE_AFTER: {
      const duration = timeDiffInMinutes(event.start, event.end);
      const isBefore = type === CalendarEventType.COMMUTE_BEFORE;
      return (
        <div
          className="fc-event-main-frame"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23${(
              event.textColor || colors.white
            ).substring(
              1
            )}' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M0 12L12 0H6L0 6M12 12V6L6 12'/%3E%3C/g%3E%3C/svg%3E")`
          }}
        >
          <div className="fc-event-time">{duration >= 15 ? `${minutesToDurationInHours(duration)} commute` : ''}</div>
          <div className="fc-event-title-container">
            <div className="fc-event-title fc-sticky">{event.title}</div>
          </div>
          {info.isPast && <PassedOverlay />}
          {event.extendedProps.hasSidebar && <BorderBar isCommuteBefore={isBefore} isCommuteAfter={!isBefore} />}
        </div>
      );
    }
    case CalendarEventType.STUDY_TIME: {
      const passed = event.extendedProps.passed || info.isPast || now().isAfter(event.end);
      const textColor = passed ? theme.captures : colors.primaryPurple;
      if (isMonthView) {
        return (
          <Row className="fc-event-main-frame" style={{ flex: 1, minWidth: 0 }}>
            <div className="fc-event-time">{timeText}</div>
            <div className="fc-event-title-container">
              <div className="fc-event-title fc-sticky">
                <Row style={{ justifyContent: 'flex-end' }}>
                  <span style={{ color: textColor, minWidth: 0 }}>
                    {formatDuration(timeDiffInMinutes(event.start, event.end))}
                  </span>
                  <MoreOptionDropdown
                    options={getOptions(event)}
                    triggerColor={textColor}
                    backgroundColor={theme.studyTimeColor}
                  />
                </Row>
              </div>
            </div>
          </Row>
        );
      } else {
        const text = passed ? t.TIME_HAS_PASSED : formatDuration(timeDiffInMinutes(event.start, event.end));
        return (
          <div className="fc-event-main-frame">
            <div className="fc-event-title-container">
              <div className="fc-event-title fc-sticky">
                <Column style={{ alignItems: 'center' }}>
                  {isMobileApp ? (
                    <span>{text}</span>
                  ) : (
                    <>
                      {/*in order to show past due text instead of study time for planning overdue tasks*/}
                      <span className={'study-time-title'}>{text}</span>
                      <span className={'study-time-past-due-title'}>{t.PAST_DUE}</span>
                    </>
                  )}
                  <Button
                    onClick={getOptions(event)?.[0]?.onClick}
                    className={`${MORE_OPTIONS_CLASS_NAME} fc-more-link`}
                    color={passed ? theme.passedStudyColor : colors.primaryPurple}
                    size={isMobileApp ? 'md' : 'sm'}
                    style={{ marginTop: 5, padding: 3 }}
                    noMinWidth={isMobileApp || isTouchScreen}
                  >
                    {t.PLAN}
                  </Button>
                </Column>
              </div>
            </div>
          </div>
        );
      }
    }
    case CalendarEventType.EXTRA_TIME: {
      const text = event.extendedProps.passed
        ? t.TIME_HAS_PASSED
        : `${formatDuration(timeDiffInMinutes(event.start, event.end))} ${t.EXTRA}`;

      return (
        <div className="fc-event-main-frame">
          <div className="custom-event-content">
            <span style={{ textAlign: 'center' }}>{text}</span>
          </div>
        </div>
      );
    }
    case CalendarEventType.PLANNED_TASK: {
      const timeLabel = timeText.split('-')[0];
      if (isMonthView) {
        return (
          <Row className="fc-event-main-frame">
            <div className="fc-event-time" style={{ display: 'flex', alignItems: 'center' }}>
              {event.extendedProps.isUsed && (
                <>
                  <MaterialIcon name={'done'} size={12} color={colors.darkGray} />
                  &nbsp;
                </>
              )}
              {timeLabel}
            </div>
            <div className="fc-event-title-container">
              <Row className="fc-event-title fc-sticky" style={titleStyle}></Row>
            </div>
            <MoreOptionDropdown
              options={getOptions(event)}
              triggerColor={info.isPast ? theme.disabledTextColor : colors.primaryPurple}
              backgroundColor={event.backgroundColor}
            />
            {event.extendedProps.passed || info.isPast || (now().isAfter(event.end) && <PassedOverlay />)}
          </Row>
        );
      } else {
        const duration = timeDiffInMinutes(info.event.start, info.event.end);
        const minSizeToDisplayCourse = getEventMinSize(calendarZoom!);
        return (
          <div className="fc-event-main-frame">
            <Row>
              <div
                className="fc-event-time"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flex: 1,
                  marginLeft: event.extendedProps.isTaskCompleted || event.extendedProps.isUsed ? 14 : 0
                }}
              >
                {timeText}
              </div>
              {!isMobileApp && !!event.extendedProps.priority && (
                <Column className={'priority-flag'}>
                  <MaterialIcon size={12} name={'flag'} color={priorityColors[event.extendedProps.priority]} />
                </Column>
              )}
            </Row>
            <div className="fc-event-title-container">
              {duration >= minSizeToDisplayCourse && (
                <span
                  className="fc-event-course"
                  style={{ textDecoration: `${event.extendedProps.isTaskCompleted ? 'line-through' : 'none'}` }}
                >
                  {event.extendedProps.courseName}
                </span>
              )}
              <div
                className="fc-event-title fc-sticky"
                style={{
                  ...titleStyle,
                  textDecoration: `${event.extendedProps.isTaskCompleted ? 'line-through' : 'none'}`
                }}
              >
                <span>{event.title}</span>
              </div>
              <span className="fc-description">{event.extendedProps.description}</span>
              {!isMobileApp && (
                <MoreOptionDropdown
                  options={getOptions(event)}
                  triggerColor={info.isPast ? theme.disabledTextColor : colors.primaryPurple}
                  placeToTopRight
                  backgroundColor={event.backgroundColor}
                />
              )}
            </div>
            {info.isPast && <PassedOverlay />}
            {!event.allDay && event.extendedProps.overlapsWithSleepTime && (
              <TippyTooltip
                target={
                  <WarningIcon>
                    <MaterialIcon name={'priority_high'} size={12} />
                  </WarningIcon>
                }
                content={t.EVENT_AWAKE_TIME_WARNING_TEXT}
                width={'210px'}
              />
            )}
            {(event.extendedProps.isTaskCompleted || event.extendedProps.isUsed) && (
              <LockIcon
                className={'icon-wrapper'}
                placeToBottomRight={!event.allDay}
                size={16}
                style={{
                  top: -2,
                  left: -4,
                  width: 16,
                  borderRadius: '0 0 3px',
                  paddingLeft: 5,
                  backgroundColor: event.extendedProps.isTaskCompleted ? colors.positive : colors.aqua
                }}
              >
                <MaterialIcon name={'check_circle'} size={14} color={colors.white} />
                &nbsp;
              </LockIcon>
            )}
            <div />
            {userSettings.plannedTaskEventStyle.sidebar && <BorderBar />}
          </div>
        );
      }
    }
    case CalendarEventType.GOOGLE_EVENT: {
      const courseTitle = event.extendedProps.courseTitle;
      const title = courseTitle || event.title;
      const lockIconComponent = (
        <TippyTooltip
          target={
            <LockIcon placeToBottomRight={!isMonthView && !event.allDay}>
              <MaterialIcon name={'lock'} size={9} strong />
            </LockIcon>
          }
          content={t.GOOGLE_EVENT_TOOLTIP('Google')}
          width={'210px'}
        />
      );

      if (isMonthView) {
        if (!event.allDay) {
          return (
            <Row className="fc-event-main-frame">
              <div className="fc-event-time">{timeText}</div>
              <div className="fc-event-title-container">
                <div className="fc-event-title fc-sticky"> - {title}</div>
              </div>
              {lockIconComponent}
            </Row>
          );
        } else {
          return (
            <Row className="fc-event-main-frame" style={{ flex: 1, justifyContent: 'space-between', minWidth: 0 }}>
              <div className="fc-event-title-container">
                <div className="fc-event-title fc-sticky">{title}</div>
              </div>
              {lockIconComponent}
            </Row>
          );
        }
      } else {
        return (
          <div className="fc-event-main-frame">
            <div className="fc-event-time">{timeText}</div>
            <div className="fc-event-title-container">
              <div className="fc-event-title fc-sticky">{title}</div>
              <Linkify options={{ target: { url: '_blank' } }}>
                <TextOverflowDiv className={'fc-location'}>
                  {event.extendedProps.location && <MaterialIcon name={'place'} outline size={10} />}
                  {event.extendedProps.location}
                </TextOverflowDiv>
              </Linkify>
              <span className="fc-description">
                <Linkify options={{ target: { url: '_blank' } }}>{event.extendedProps.innerText}</Linkify>
              </span>
              {lockIconComponent}
            </div>
            {userSettings.googleEventStyle.sidebar && <BorderBar />}
          </div>
        );
      }
    }
    case CalendarEventType.SLEEP_TIME: {
      return (
        <div className="fc-v-event" style={{ height: '100%' }}>
          <div className="fc-event-main-frame">
            <div className="fc-event-time">{timeText}</div>
            <div className="fc-event-title-container">
              <div className="fc-event-title fc-sticky">
                {!isMobileApp && (
                  <NavLink
                    to={{
                      pathname: goToAwakeLink(semesterId),
                      state: { calendarDate: info.view.activeStart }
                    }}
                    className={'fc-daygrid-more-link fc-more-link'}
                  >
                    {event.title}
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
    case CalendarEventType.TASK: {
      // additional condition is required because month view is updated before event extended props
      if (!isMonthView) {
        // controlled tippy, visible only if event id is same as tasksGroupIdPopup (logic is in Calendar onEventClick)
        return (
          <GroupedTasksEventsPopup
            isOpen={event.id === tasksGroupIdPopup}
            close={closeTasksGroupPopup}
            tasksIds={(event.extendedProps.tasks || []).map(t => t.id)}
            semesterId={semesterId}
            onTaskClick={onTaskClick}
          />
        );
      }
      return (
        <Row style={{ flex: 1, justifyContent: 'space-between', minWidth: 0 }}>
          <EmojiPreview emoji={'🏁'} />
          <div className="fc-event-time">{timeText}</div>
          <TextOverflowDiv className="fc-event-title">
            <span>{event.title}</span>
          </TextOverflowDiv>
        </Row>
      );
    }
    case 'EXAM': {
      if (isMonthView) {
        return (
          <Row style={{ flex: 1, justifyContent: 'space-between', minWidth: 0 }}>
            <EmojiPreview emoji={'🏁'} />
            <div className="fc-event-time">{timeText}</div>
            <TextOverflowDiv className="fc-event-title">
              <span>{event.title}</span>
            </TextOverflowDiv>
          </Row>
        );
      }
      return (
        <div className="fc-event-main-frame">
          <Row>
            <div className="fc-event-time">{timeText}</div>
            {!isMobileApp && !!event.extendedProps.priority && (
              <Column className={'priority-flag'}>
                <MaterialIcon size={12} name={'flag'} color={priorityColors[event.extendedProps.priority]} />
              </Column>
            )}
          </Row>
          <div className="fc-event-title-container">
            <span className="fc-event-course">{event.extendedProps.task.courseName || t.RANDOM}</span>
            <div className="fc-event-title fc-sticky">
              <span>{event.title}</span>
            </div>
          </div>
          <Row className={'exam-wrapper'}>
            <MiniText className={'exam-text'} color={colors.white} style={{ fontWeight: 500 }}>
              {t.EXAM}
            </MiniText>
            <MaterialIcon color={colors.white} name={'done'} size={10} style={{ fontWeight: 700 }} />
          </Row>
        </div>
      );
      break;
    }
    default: {
      return (
        <div className="fc-event-main-frame">
          <div className="fc-event-time">{timeText}</div>
          <div className="fc-event-title-container">
            <div className="fc-event-title fc-sticky">
              <span>{event.title}</span>
            </div>
          </div>
        </div>
      );
    }
  }
};

export const weeksBetweenDays = (start: Moment, end: Moment) => {
  if (start.isAfter(end)) return [];

  const weeks: string[] = [];
  // todo check should this be isoWeek
  // todo maybe WEEK_FORMAT (YYYY-W) should be replaced with YYYY-w
  const date = start.clone().startOf('week');
  while (!date.isAfter(end)) {
    const week = date.format(LOCAL_WEEK_FORMAT);
    if (!weeks.includes(week)) {
      weeks.push(week);
    }
    date.add(1, 'week');
  }
  return weeks;
};

export const datesWithinRange = (start: Moment, end: Moment) => {
  if (start.isAfter(end)) return [];

  const dates: string[] = [];
  const date = start.clone().startOf('day');
  while (!date.isAfter(end)) {
    dates.push(date.format(DATE_FORMAT));
    date.add(1, 'day');
  }
  return dates;
};

export const toDateInRequest = (date: string | Date) =>
  toMomentDate(date)
    .clone()
    .format(DATE_FORMAT);

export const isImportedEvent = (type: any) =>
  [CourseSource.MOODLE, CourseSource.BRIGHTSPACE, CourseSource.CANVAS].includes(type);

export const isCommitmentOrEvent = (type: CalendarEventType) =>
  isImportedEvent(type) || [CalendarEventType.COURSE, CalendarEventType.ACTIVITY, CalendarEventType.EVENT].includes(type);

export const isCommitmentOrEventOrAwake = (type: CalendarEventType) =>
  isCommitmentOrEvent(type) || type === CalendarEventType.AWAKE_TIME;

export const isCommute = (type: CalendarEventType) =>
  [CalendarEventType.COMMUTE_BEFORE, CalendarEventType.COMMUTE_AFTER].includes(type);

export const calculateStudyAndExtraTimeEvents = (
  calendarInfo: { calendarStartDate: Moment; calendarEndDate: Moment },
  semesterCalendarInfo: SemesterCalendarInfo,
  events: CalendarEvent[],
  awakeTimeEvents: CalendarEvent[]
) => {
  const { calendarStartDate, calendarEndDate } = calendarInfo;
  const { calendarMinDate, calendarMaxDate } = semesterCalendarInfo;

  const from = calendarStartDate.isAfter(calendarMinDate) ? calendarStartDate : calendarMinDate;
  const to = calendarEndDate.isBefore(calendarMaxDate) ? calendarEndDate : calendarMaxDate;

  if (!calculationPrecondition(from, to, awakeTimeEvents)) return [];

  const populatedMinutes = minutesPopulatedWithEvents(events, awakeTimeEvents, from, to);

  return extractFreeTimeBlocks(populatedMinutes).map(block =>
    constructStudyOrExtraTime(block, semesterCalendarInfo, from.toDate(), now())
  );
};

export const createDateRangeFilter = (from: Moment, to: Moment) => (e: CalendarEvent) => {
  const eventStart = toMomentDate(e.start);
  const eventEnd = toMomentDate(e.end);
  return (
    eventStart.isBetween(from, to, undefined, '[]') ||
    eventEnd.isBetween(from, to, undefined, '[]') ||
    from.isBetween(eventStart, eventEnd, undefined, '[]')
  );
};

export const awakeTimesFilter = (e: CalendarEvent) => e.type === CalendarEventType.AWAKE_TIME;

export const studyTimeFilter = (e: CalendarEvent) => e.type === CalendarEventType.STUDY_TIME;

export const extraTimeFilter = (e: CalendarEvent) => e.type === CalendarEventType.EXTRA_TIME;

/**
 * Plannable study block is study time event which meets these conditions:
 * - is in future and is visible on calendar
 * - is before dragged task due date
 * - doesn't have dragged task already planned
 *
 * @param from    - start date of the current calendar view or now (latest between these two)
 * @param to      - due date of the task
 */
export const createPlannableStudyBlocksFilter = (from: Moment, to: Moment) => (e: CalendarEvent) => {
  // if (!to.isAfter(from)) return false;

  const isStudy = e.type === CalendarEventType.STUDY_TIME;

  const eventStart = toMomentUtcDate(e.start);
  const eventEnd = toMomentUtcDate(e.end);
  // if task does not have dueDate than study block should be colored purple
  const isInsideGivenRange = to
    ? eventStart.isBetween(from, to, undefined, '[]') ||
      eventEnd.isBetween(from, to, undefined, '[]') ||
      to.isBetween(eventStart, eventEnd, undefined, '[]')
    : true;

  if (isStudy && eventEnd.isAfter(from)) {
    return isInsideGivenRange ? IS_PLANNABLE : IS_PLANNABLE_OVERDUE;
  }
  return null;
};

export const toUpdateEventDto = (calendarInfo: any, newEnd?: any) => {
  const {
    id,
    start,
    end,
    title,
    allDay,
    extendedProps: {
      colorHex,
      description,
      commuteBefore,
      commuteAfter,
      location,
      type,
      isRecurring,
      originalEventStart,
      repeat
    }
  } = calendarInfo;

  return {
    id,
    title,
    start,
    end: newEnd || end,
    description,
    commuteAfter,
    commuteBefore,
    location,
    type,
    allDay,
    repeat,
    isRecurring,
    originalEventStart,
    colorHex
  };
};

export const shouldResetSliders = (event: CalendarEvent, eventFromState: CalendarEvent) => {
  const duration = timeDiffInMinutes(event.start, event.end);
  const totalPlanned = eventFromState.plannedTasks!.map(t => t.plannedMinutes).reduce((a: number, b: number) => a + b, 0);
  return duration < totalPlanned;
};

export const getFirstTwoDayLetters = (date: Date | string) =>
  toMomentDate(date)
    .format('dd')
    .toUpperCase() as WeekdayStr;

export const draggableStyle = css`
  cursor: grab;
  transition: box-shadow 0.2s ease;

  &:active {
    cursor: grabbing;
  }
  &:hover {
    box-shadow: 0px 2px 2px ${props => props.theme.boxShadow}, 0px 0px 2px ${props => props.theme.boxShadow};
  }
`;

export const PlannedTimeOverlay = styled.div<{ percent: number }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${props => props.percent}%;
`;

const LockIcon = styled.div<{ placeToBottomRight?: boolean; size?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: ${props => props.size || 9}px;
  height: ${props => props.size || 9}px;
  position: absolute;
  right: -2px;
  bottom: ${props => (props.placeToBottomRight ? -2 : 3)}px;
  svg {
    height: 100%;
  }
`;

const WarningIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 2px;
  bottom: 5px;

  i {
    color: ${colors.negative};
  }
`;

export const calendarViewsHeaderDateFormat = new Map([
  [CalendarView.MONTH, 'ddd'],
  [CalendarView.LIST, 'ddd DD'],
  [CalendarView.WEEK, 'ddd DD'],
  [CalendarView.CYCLIC, 'ddd DD']
]);

export const mapToPlanBlockEvent = (event: any) => {
  const {
    id,
    start,
    end,
    extendedProps: { type, plannedTasks, passed, isRecurring, isLms, dayOfWeek, repeat }
  } = event;
  return {
    id: id !== '' ? id : undefined,
    start,
    end,
    type,
    isLms,
    plannedTasks: plannedTasks || [],
    passed,
    isRecurring,
    dayOfWeek,
    repeat
  };
};

export const getEventContextMenuOptions = (
  calendarEvent: any,
  { openPlanBlockDialog, deletePlannedTask, deleteEvent, duplicatePlannedTask }
) => {
  const event = mapToPlanBlockEvent(calendarEvent);
  let options: OptionType[] = [];
  switch (event.type) {
    case CalendarEventType.PLANNED_TASK: {
      options = [
        {
          value: t.DUPLICATE,
          label: t.DUPLICATE,
          icon: 'content_copy',
          onClick: () => duplicatePlannedTask(Number(event.id))
        },
        {
          value: t.UNPLAN,
          label: t.UNPLAN,
          icon: 'event_busy',
          onClick: () => deletePlannedTask(Number(event.id))
        }
      ];
      break;
    }
    case CalendarEventType.STUDY_TIME: {
      options = [
        {
          value: t.PLAN_THIS_BLOCK,
          label: t.PLAN_THIS_BLOCK,
          icon: 'calendar_today',
          onClick: () => openPlanBlockDialog({ event })
        }
      ];
      break;
    }
    default: {
      options = [{ value: t.DELETE, label: t.DELETE, icon: 'delete_outline', onClick: () => deleteEvent(event) }];
      break;
    }
  }
  return options;
};

export const hideAllTippies = () => hideAll({ duration: 0 });

export const removeDraggingClasses = event => {
  event.groupId = '';
  const classIndex = event.classNames.indexOf(PLANNABLE_EVENT_CLASS_NAME);
  const classIndexOverdue = event.classNames.indexOf(PLANNABLE_OVERDUE_EVENT_CLASS_NAME);

  if (classIndex !== -1) {
    event.classNames.splice(classIndex, 1);
  }
  if (classIndexOverdue !== -1) {
    event.classNames.splice(classIndexOverdue, 1);
  }
};

export const addDraggingClass = (event, groupId, className) => {
  event.groupId = groupId;
  event.classNames = [...(event.classNames || []), className];
};

export const getRecurrenceText = options => {
  if (!options) return null;
  if (options.interval === 0) return t.ON_SELECTED_DAYS;

  let text =
    !options.interval || options.interval === 1
      ? t.WEEKLY
      : `${t.REPEATS_EVERY} ${options.interval} ${t.WEEKS.toLowerCase()}`;
  if (options.onDays) {
    const locale = toMomentDate().localeData();
    const allWeekdays = [...locale.weekdaysMin()];
    const shiftedWeekdays = allWeekdays.splice(0, locale.firstDayOfWeek());
    const weekdays = allWeekdays.concat(shiftedWeekdays).filter(d => options.onDays.map(capitalize).includes(d));

    if (weekdays.length > 0) {
      text += ` ${t.ON} ${weekdays.join(', ')}`;
    }
  }
  return text;
};

/**
 * Repeat pattern has changed if there wasn't a pattern and was added, or there was a pattern but was removed
 * or there was a pattern but some of the values were changed
 * No need to check if old and new are both undefined
 */
export const hasRepeatPatternChanged = (oldRepeat, newRepeat) =>
  !!oldRepeat !== !!newRepeat ||
  (oldRepeat &&
    newRepeat &&
    (oldRepeat.interval !== newRepeat.interval ||
      oldRepeat.onDays?.length !== newRepeat.onDays?.length ||
      //@ts-ignore
      (oldRepeat.onDays && !oldRepeat.onDays.every(d => newRepeat.onDays?.includes(d))) ||
      oldRepeat.includeDays?.length !== newRepeat.includeDays?.length ||
      //@ts-ignore
      (oldRepeat.includeDays && !oldRepeat.includeDays.every(d => newRepeat.includeDays?.includes(d))) ||
      oldRepeat.untilOption !== newRepeat.untilOption ||
      !toMomentDate(oldRepeat.until).isSame(newRepeat.until, 'date')));

const getEventMinSize = slatHeight => {
  if (slatHeight < 1) return 65 + 1 - slatHeight;
  if (slatHeight < 1.45) return 65;
  if (slatHeight < 1.55) return 60;
  if (slatHeight < 1.75) return 55;
  if (slatHeight < 1.95) return 50;
  if (slatHeight < 2.2) return 45;
  if (slatHeight < 2.45) return 40;
  if (slatHeight < 2.85) return 35;
  if (slatHeight < 3.4) return 30;
  if (slatHeight < 4.1) return 25;
  return 20;
};

export const extendDraggableItem = (draggableItem: Draggable) => {
  draggableItem.dragging.autoScroller.edgeThreshold = 80;
  draggableItem.dragging.autoScroller.maxVelocity = 1200;
};

export const calendarStylingOptions = theme => ({
  [calendarStylingType.WHITE]: {
    name: t.WHITE,
    backgroundFunction: color => theme.background
  },
  [calendarStylingType.GRAY]: {
    name: t.GRAY,
    backgroundFunction: color => theme.activitiesColor
  },
  [calendarStylingType.LIGHT]: {
    name: '10%',
    backgroundFunction: theme.commitmentColorFunction
  },
  [calendarStylingType.FULL]: {
    name: '20%',
    backgroundFunction: theme.courseColorFunction
  }
});

const BorderBar = styled.div<{
  noTopRadius?: boolean;
  noBottomRadius?: boolean;
  isCommuteBefore?: boolean;
  isCommuteAfter?: boolean;
}>`
  border-color: inherit;
  border-width: 1.5px;
  position: absolute;
  top: ${props => (props.isCommuteBefore || props.isCommuteAfter ? -1 : -2)}px;
  bottom: ${props => (props.isCommuteBefore ? 1 : props.isCommuteAfter ? -1 : -4)}px;
  left: ${props => (props.isCommuteBefore || props.isCommuteAfter ? -2 : -7)}px;
  border-style: solid;
  background-color: currentColor;
  border-radius: ${props =>
    `${props.noTopRadius || props.isCommuteAfter ? 0 : 3}px 0 0 ${props.noBottomRadius || props.isCommuteBefore ? 0 : 3}px`};
`;
