import React, { FC } from 'react';
import styled from 'styled-components';

import { MediumText } from '@utils/typography';
import { Column, Row } from '../layoutUtils';
import Button from '../buttons/Button';
import t from '@i18n/t';

type Props = {
  title: string;
  text: string;
  // quick hack to point to the left
  pointLeft?: boolean;
  gif?: string;
  onClick: () => void;
  skip?: () => void;
};

const OnboardingHint: FC<Props> = ({ text, title, pointLeft, gif, onClick, skip }) => {
  return (
    <Container>
      <Title strong>
        {pointLeft ? t.EMOJI_POINTING_LEFT : t.EMOJI_POINTING}&nbsp;
        {title.toUpperCase()}
      </Title>
      <Text>{text}</Text>
      {gif && <Gif src={gif} />}
      <ButtonWrapper>
        {/*{!!skip && <TextLink onClick={skip}>{t.SKIP_ONBOARDING}</TextLink>}*/}
        <Button onClick={onClick} size={'sm'}>
          {t.GOT_IT}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default OnboardingHint;

const Container = styled(Column)`
  background: ${props => props.theme.background};
  width: 260px;
  padding: 24px 30px;
  padding-right: 40px;
  border-radius: 5px;
`;

const Title = styled(MediumText)`
  margin-bottom: 10px;
`;

const Text = styled(MediumText)`
  margin-bottom: 10px;
`;

const ButtonWrapper = styled(Row)`
  padding-top: 10px;
  justify-content: space-between;
`;

const Gif = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 10px;
`;
