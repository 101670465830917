import React, { PureComponent, RefObject } from 'react';
import styled, { withTheme } from 'styled-components';
// don't remove this import - hack to make fullcalendar work
import ParticleEffectButton from 'react-particle-effect-button';

import FullCalendar from '@fullcalendar/react';
import { Draggable } from '@fullcalendar/interaction';
import {
  CalendarEventType,
  Formats,
  PersonalizationSettings,
  PileOverdueTask,
  PileToDoTask,
  TaskState
} from 'shovel-lib/types';
import { formatMomentDateAs, now } from 'shovel-lib/utils/timeUtils';
import TaskCard from '../../task/TaskCard';
import PlannedTag, { PlannedContainer } from '../../task/partials/PlannedTag';
import { calculateDatesDiffPreview, dueDatePreview, formatTaskCardDate } from '../../../utils/taskUtils';
import { stopDraggingTask } from '../../../state/calendar/actions';
import { DraggingTask } from '../../../state/calendar/types';
import { calendarStylingOptions, draggableStyle, extendDraggableItem } from '../../../utils/calendar/calendarUtils';
import { DraggableTask } from '../../common/layoutUtils';
import { Container, DeleteTaskIcon, MarkCompleteBtn } from '../../task/TaskCard.styles';
import MaterialIcon from '../../common/icons/MaterialIcon';
import { SmallText } from '../../../utils/typography';
import t from '../../../i18n/t';
import TippyTooltip from '../../common/tooltip/TippyTooltip';
import { taskActions } from '../../../state/task';
import TaskStatus from '../../task/TaskStatus';
import colors from '../../../utils/colors';
import { BUTTON_ANIMATION_PROPS_TWO, MARK_COMPLETE_BTN_DURATION } from '../../../utils/constants/task';
import { RootState } from '@state/rootReducer';
import { settingsSelectors } from '@state/settings';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

// don't remove this - hack to guarantee that fullcalendar core import will stay
const name = FullCalendar.name;

type Props = {
  userSettings: any;
  task: any;
  state: TaskState.COMPLETED | TaskState.ACTIVE | TaskState.DO_LATER;
  startDraggingTask?: any;
  stopDraggingTask?: typeof stopDraggingTask;
  draggingTask?: DraggingTask;
  dueDateFormat?: string;
  className?: string;
  onPress: (clickData: any) => void;
  onMarkAsToDo?: any;
  onMarkAsComplete?: any;
  settings?: PersonalizationSettings;
  openConfirmationDialog?: any;
  deleteTask?: any;
  openMoveToDoDialog?: any;
  isMonth?: boolean;
  theme: any;
  isPile?: boolean;
  //for demo purpose
  isDisabledDragging?: boolean;
  changePriority?: any;
};

type State = {
  animationActive: boolean;
};

class PileTask extends PureComponent<Props, State> {
  state: State = {
    animationActive: false
  };

  draggableRef: RefObject<any> = React.createRef();
  draggableTask: Draggable | null = null;
  animateTimeout?: any = undefined;

  isDraggable = () => {
    return !this.props.isMonth && (this.props.state === TaskState.ACTIVE || this.props.state === TaskState.DO_LATER);
  };

  canBindDraggableTask = () =>
    !this.props.isDisabledDragging &&
    this.isDraggable() &&
    this.props.startDraggingTask &&
    this.props.stopDraggingTask &&
    !this.draggableTask;

  componentDidMount() {
    if (this.canBindDraggableTask()) {
      this.bindDraggableTask();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isDisabledDragging && this.canBindDraggableTask()) {
      this.bindDraggableTask();
    }
    const { due, timePlanned, timeNeed } = this.props.task as PileOverdueTask | PileToDoTask;
    const { due: prevDue, timePlanned: prevPlanned, timeNeed: prevNeed } = prevProps.task as PileOverdueTask | PileToDoTask;
    if (this.isDraggable()) {
      if (due && !due.isSame(prevDue) && this.props.startDraggingTask) {
        this.updateDraggableTaskHandler();
        return;
      }
      if (
        this.draggableTask &&
        (timePlanned !== prevPlanned ||
          timeNeed !== prevNeed ||
          this.props.isMonth !== prevProps.isMonth ||
          this.props.theme !== prevProps.theme)
      ) {
        this.draggableTask.destroy();
        this.bindDraggableTask();
      }
    }
    if (timePlanned !== prevPlanned || (timeNeed !== prevNeed && this.draggableRef.current)) {
      this.draggableRef.current?.classList?.add?.('animating');
      if (!!this.animateTimeout) {
        clearTimeout(this.animateTimeout);
      }
      this.animateTimeout = setTimeout(() => {
        if (this.draggableRef.current) {
          this.draggableRef.current.classList.remove('animating');
        }
      }, 3000);
    }
  }

  bindDraggableTask = () => {
    const { startDraggingTask, stopDraggingTask, isMonth, theme, userSettings } = this.props;
    const { taskId, colorHex, type, due, title, timeNeed, timePlanned, timeSpent, courseName } = this.props.task as
      | PileOverdueTask
      | PileToDoTask;

    const timeUnplanned = timeNeed - timePlanned;
    const noEstimation = timeNeed + timeSpent === 0;
    const duration = noEstimation || timeUnplanned <= 0 || timeUnplanned > 60 ? 60 : timeUnplanned;
    const courseColor = colorHex || colors.randomTaskColor;
    const backgroundColor = calendarStylingOptions(theme)[userSettings.plannedTaskEventStyle.style].backgroundFunction(
      courseColor
    );
    this.draggableTask = new Draggable(this.draggableRef.current, {
      eventData: eventEl => {
        return {
          taskId: taskId,
          type: CalendarEventType.PLANNED_TASK,
          taskType: type,
          taskColor: colorHex,
          due,
          title,
          duration: isMonth ? undefined : duration * 60000, // duration in milliseconds
          textColor: courseColor,
          colorHex,
          borderColor: theme.commitmentBorderFunction(courseColor),
          backgroundColor,
          courseColor,
          courseName: courseName || t.RANDOM,
          classNames: ['planned-task-event']
        };
      },
      longPressDelay: 200
    });
    extendDraggableItem(this.draggableTask);
    // TODO: check this implementation
    // @ts-ignore
    const draggableTaskHandlers = this.draggableTask.dragging.emitter.handlers;

    draggableTaskHandlers.dragstart.push(() => {
      startDraggingTask!({ taskId, dueDate: due });
    });

    draggableTaskHandlers.dragend.push(() => {
      stopDraggingTask!();
    });
  };

  updateDraggableTaskHandler = () => {
    const {
      task: { taskId, due },
      startDraggingTask
    } = this.props;

    // @ts-ignore
    const draggableTaskHandlers = this.draggableTask!.dragging.emitter.handlers;

    draggableTaskHandlers.dragstart.pop();

    draggableTaskHandlers.dragstart.push(() => {
      startDraggingTask!({ taskId, dueDate: due });
      return true;
    });
  };

  render() {
    const { isDisabledDragging } = this.props;

    return this.isDraggable() && !isDisabledDragging ? (
      <DraggableTaskWrapper ref={this.draggableRef} className={this.props.className} draggable>
        {this.renderTask()}
      </DraggableTaskWrapper>
    ) : (
      this.renderTask()
    );
  }

  renderTask = () => {
    const {
      onPress,
      task,
      state,
      settings,
      isPile = true,
      isDisabledDragging,
      className,
      userSettings,
      theme,
      changePriority
    } = this.props;
    const {
      taskId,
      due,
      type,
      timeNeed,
      cushion,
      completedOn,
      courseName,
      title,
      subtitle,
      colorHex,
      duration,
      categoryName,
      emoji,
      readingSourceName,
      timePlanned,
      timeSpent,
      priority,
      daysToStartAhead
    } = task;

    let duePreview;
    if (due) {
      if (this.props.dueDateFormat) {
        duePreview = formatMomentDateAs(due, this.props.dueDateFormat!);
      } else if (settings) {
        formatTaskCardDate(due, settings);
      } else {
        dueDatePreview(due, state);
      }
    }

    const startPreview =
      due && daysToStartAhead > 0 && state !== TaskState.COMPLETED
        ? formatMomentDateAs(due.clone().subtract(daysToStartAhead, 'day'), Formats.MONTH_DAY_FORMAT)
        : '';

    let isDragging = false;
    if (this.isDraggable()) {
      const { draggingTask } = this.props;
      isDragging = !!draggingTask && draggingTask.taskId === taskId;
    }

    const hasSidebar = userSettings.plannedTaskEventStyle.sidebar;
    const backgroundColor = calendarStylingOptions(theme)[userSettings.plannedTaskEventStyle.style].backgroundFunction(
      colorHex || colors.randomTaskColor
    );

    const Card = this.isDraggable() ? DraggableTaskCard : TaskCard;
    const animationProps = { ...BUTTON_ANIMATION_PROPS_TWO, duration: MARK_COMPLETE_BTN_DURATION };
    //this is added because of demo task card animation
    if (isDisabledDragging) {
      return (
        <TaskWrapper className={className}>
          <DemoTaskCard
            hasSidebar={hasSidebar}
            backgroundColor={backgroundColor}
            className={`pile-task`}
            due={duePreview}
            start={startPreview}
            colorHex={colorHex}
            type={type}
            duration={duration}
            cushionComponent={<TaskStatus {...{ state, timeNeed, cushion, completedOn, isPile, due }} />}
            timeNeed={timeNeed}
            timePlanned={timePlanned}
            timeSpent={timeSpent}
            course={courseName}
            title={title}
            subtitle={subtitle}
            onClick={onPress}
            isPile={isPile}
            isCompleted={state === TaskState.COMPLETED}
            renderMarkBtn={this.renderMarkBtn}
            renderDeleteBtn={this.renderDeleteBtn}
            categoryName={categoryName}
            categoryEmoji={emoji}
            readingSourceName={readingSourceName}
            hasNoActions={!isPile}
          />
        </TaskWrapper>
      );
    }
    const taskElement = (
      <Card
        hasSidebar={hasSidebar}
        backgroundColor={backgroundColor}
        className={`pile-task ${isDragging ? 'dragged-pile-task' : ''}`}
        due={duePreview}
        start={startPreview}
        colorHex={colorHex || colors.randomTaskColor}
        type={type}
        duration={duration}
        cushionComponent={<TaskStatus {...{ state, timeNeed, cushion, completedOn, isPile, due }} />}
        timeNeed={timeNeed}
        timePlanned={timePlanned}
        timeSpent={timeSpent}
        course={courseName || t.RANDOM}
        title={title}
        subtitle={subtitle}
        onClick={onPress}
        isPile={isPile}
        isCompleted={state === TaskState.COMPLETED}
        renderMarkBtn={this.renderMarkBtn}
        renderDeleteBtn={this.renderDeleteBtn}
        categoryName={categoryName}
        categoryEmoji={emoji}
        readingSourceName={readingSourceName}
        hasNoActions={!isPile}
        dragDisabled={!this.isDraggable()}
        priority={priority || 0}
        changePriority={changePriority}
      />
    );
    return isPile && state !== TaskState.COMPLETED ? (
      <CardWrapper color={colorHex} hidden={this.state.animationActive} {...animationProps}>
        {taskElement}
      </CardWrapper>
    ) : (
      taskElement
    );
  };

  renderPlannedTag = () => {
    if (this.props.state === TaskState.COMPLETED) return null;

    const task = this.props.task as PileToDoTask;
    const { timeNeed, timePlanned } = task;

    return <PlannedTag {...{ timeNeed, timePlanned }} tooltip={t.NOT_PLANNED} bare font={10} />;
  };

  onMarkToDoClick = () => {
    const { task, onMarkAsToDo, openMoveToDoDialog, state } = this.props;
    if (state !== TaskState.COMPLETED) return;
    if (now().isAfter(this.props.task.due)) {
      openMoveToDoDialog({ dueDate: task.due, daysAhead: task.daysToStartAhead, taskId: task.taskId });
    } else {
      const data = { dueDate: task.due && task.due.toISOString(), daysAhead: task.daysToStartAhead };
      onMarkAsToDo({ taskId: task.taskId, data });
    }
  };

  onMarkComplete = () => {
    this.setState({ animationActive: true });
    const { state, onMarkAsComplete, task } = this.props;
    if (state === TaskState.COMPLETED) return;
    onMarkAsComplete({ taskId: task.taskId, data: { state: TaskState.COMPLETED } });
  };

  renderMarkBtn = () => {
    const { state } = this.props;
    if (state !== TaskState.COMPLETED) {
      return (
        <TippyTooltip
          target={
            <MarkCompleteBtn
              onClick={e => {
                this.onMarkComplete();
                e.stopPropagation();
              }}
            >
              <MaterialIcon name={'done'} size={16} style={{ marginRight: '2px' }} />
              <SmallText>{t.MARK_COMPLETE}</SmallText>
            </MarkCompleteBtn>
          }
          content={t.MARK_COMPLETE_TOOLTIP}
          width="170px"
        />
      );
    }

    return (
      <MarkCompleteBtn
        onClick={e => {
          this.onMarkToDoClick();
          e.stopPropagation();
        }}
      >
        <MaterialIcon name={'undo'} size={16} style={{ marginRight: '2px' }} />
        <SmallText>{t.MARK_TO_DO}</SmallText>
      </MarkCompleteBtn>
    );
  };

  onDeleteTask = () => {
    const dummyAction = taskActions.deleteTask; // don't remove - impacts the modules creation order and app crashes

    const { deleteTask, task } = this.props;
    deleteTask({ id: task.taskId });
  };

  renderDeleteBtn = () => {
    return (
      <TippyTooltip
        target={
          <div style={{ height: '16px' }}>
            <DeleteTaskIcon
              name={'delete_outline'}
              size={16}
              onClick={e => {
                this.onDeleteTask();
                e.stopPropagation();
              }}
            />
          </div>
        }
        content={t.DELETE_TASK}
      />
    );
  };
}

const mapStateToProps = (state: RootState) => ({ userSettings: settingsSelectors.getUserSettings(state) });

export default connect(mapStateToProps)(withTheme(PileTask));

const DraggableTaskWrapper = styled.div`
  &.animating ${PlannedContainer} {
    animation: pulse-planned 1s ease-out infinite;
    @keyframes pulse-planned {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.25);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  & ${DraggableTask} {
    display: none;
  }

  .pile-task {
    transition: opacity 0.2s ease;
  }

  &.agenda-dragging.agenda-drop-not-allowed .pile-task {
    opacity: 0.9;
  }

  &.fc-dragging .pile-task {
    display: none;
  }

  &.agenda-dragging.agenda-drop-allowed .pile-task {
    opacity: 0;
  }

  &.agenda-dragging ${DraggableTask}, &.fc-dragging ${DraggableTask} {
    display: flex;
  }
`;

const TaskWrapper = styled.div``;

const DraggableTaskCard = styled(TaskCard)`
  ${draggableStyle}
`;

const DemoTaskCard = styled(TaskCard)`
  cursor: default !important;
`;

const CardWrapper = styled(ParticleEffectButton)`
  width: 100%;
  > div {
    overflow: ${props => (props.hidden ? 'hidden' : 'unset')};
    width: 100%;
  }
`;
