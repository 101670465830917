import React, { lazy, Suspense } from 'react';

/**
 * usage example:
 * * export default loadable(() => import(\/\* webpackChunkName: "LoginPage" \*\/ './LoginPage'));
 */
export default (importCallback, loader = null) => {
  const TargetComponent = lazy(importCallback);
  return props => (
    <Suspense fallback={loader}>
      <TargetComponent {...props} />
    </Suspense>
  );
};
