import { isDev } from './configConstants';

let s: HTMLElementTagNameMap['script'];

const includeScript = () =>
  (function() {
    if (isDev) {
      console.log('accessiBe - include script');
      return;
    }
    s = document.createElement('script');
    const e: HTMLElement | null = !document.body ? document.querySelector('head') : document.body;
    s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
    s.async = true;
    s.onload = function() {
      // @ts-ignore
      acsbJS.init({
        statementLink: '',
        footerHtml: '',
        hideMobile: false,
        hideTrigger: false,
        language: 'en',
        position: 'left',
        leadColor: '#786FFF',
        triggerColor: '#647080',
        triggerRadius: '50%',
        triggerPositionX: 'left',
        triggerPositionY: 'bottom',
        triggerIcon: 'people',
        triggerSize: 'small',
        triggerOffsetX: 15,
        // padding bottom is 20px, 32px is height of settings button
        triggerOffsetY: 77,
        mobile: {
          triggerSize: 'small',
          triggerPositionX: 'left',
          triggerPositionY: 'bottom',
          triggerOffsetX: 15,
          triggerOffsetY: 77,
          triggerRadius: '50%'
        }
      });
    };
    e?.appendChild(s);
  })();

const removeScript = () => {
  if (isDev) {
    console.log('accessiBe - remove script');
    return;
  }
  // @ts-ignore
  acsbJS?.destroy();
  s?.remove();
};

export default {
  includeScript,
  removeScript
};
