import styled, { css } from 'styled-components';
import ReactQuill from 'react-quill';
import colors from '@utils/colors';
import { SmallText } from '@utils/typography';
import { Column, Row } from '../layoutUtils';
import { isMobile } from '@utils/screenUtils';

//cannot use props theme because is used as prop of ReactQuill
export const CustomEditor = styled(ReactQuill)`
  .ql-container {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: 'Quicksand', sans-serif;
    font-size: ${isMobile ? '16px' : '13px'};
    font-weight: 500;
  }
`;

const hoverAndActiveSvgStyle = css`
  .ql-line,
  .ql-stroke {
    stroke: ${props => props.theme.primary};
  }
  .ql-fill {
    fill: ${props => props.theme.primary};
  }
  color: ${props => props.theme.primary};
`;

const regularSvgStyle = css`
  .ql-line,
  .ql-stroke {
    stroke: ${props => props.theme.textStrongColor};
  }
  .ql-fill {
    fill: ${props => props.theme.textStrongColor};
  }
`;

export const Container = styled.div<{ error?: boolean; isFocused?: boolean }>`
  margin-bottom: 20px;
  position: relative;
  #toolbar {
    position: sticky;
    top: -15px;
    z-index: 1;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom: none;
    padding: 4px 5px !important;
    box-sizing: border-box;
    border-color: ${props => (props.isFocused ? props.theme.primary : props.theme.borderColor)} !important;
    background: ${props => props.theme.background} !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    button {
      width: ${isMobile ? '30' : '25'}px !important;
    }
    button,
    span {
      ${regularSvgStyle};
      &:disabled {
        svg {
          opacity: 0.3;
        }
        cursor: default;
      }
      svg {
        transition: opacity 0.1s ease;
      }
      &:not(:disabled):not(.align-items):not(.ql-align):not(.ql-expanded):not(.ql-picker-options):not(.header-label):hover {
        ${hoverAndActiveSvgStyle};
      }
    }
    .ql-active {
      ${hoverAndActiveSvgStyle};
    }
    .ql-picker {
      color: ${props => props.theme.textStrongColor};
    }
    .ql-picker-options {
      background-color: ${props => props.theme.background} !important;
      border-color: ${props => props.theme.borderColor} !important;
      border-radius: 5px;
      margin-top: 3px;
    }
    .ql-picker.ql-expanded .ql-picker-label {
      border-radius: 5px;
    }
    .ql-picker-item:hover,
    .ql-picker-label:hover {
      color: ${props => props.theme.primary};
    }
    .ql-picker-item.ql-selected {
      color: ${props => props.theme.primary};
      &:hover {
        opacity: 0.75;
      }
    }
    .ql-formats {
      margin-right: 0px;
    }
    .ql-color,
    .ql-background,
    .ql-align {
      float: none;
    }
    .emoji-picker {
      padding: 0px;
      float: none;

      &:disabled:hover {
        opacity: 0.3;
      }
      &:not(:disabled):hover {
        transform: scale(1.2);
      }
      transition: transform 0.2s ease;
    }
    .header-btn {
      width: unset !important;
      transition: opacity 0.1s ease;
      &:disabled {
        span,
        i {
          cursor: default;
          opacity: 0.3;
        }
      }
    }
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=''])::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=''])::before {
      content: attr(data-label);
    }
  }
  ${CustomEditor} {
    .ql-container {
      border-color: ${props =>
        props.error ? colors.negative : props.isFocused ? props.theme.primary : props.theme.borderColor} !important;
      border-top-color: ${props => props.theme.borderColor} !important;
      color: ${props => props.theme.textStrongColor} !important;
      min-height: 75px;
      display: flex;
      flex-direction: column;
      .ql-editor {
        color: ${props => props.theme.textStrongColor} !important;
        padding: 10px !important;
        flex: 1;
        ul,
        ol {
          padding-left: 0 !important;
          .ql-indent-1 {
            padding-left: 2.5em !important;
          }
          .ql-indent-2 {
            padding-left: 5.5em !important;
          }
          .ql-indent-3 {
            padding-left: 8.5em !important;
          }
          .ql-indent-4 {
            padding-left: 11.5em !important;
          }
          .ql-indent-5 {
            padding-left: 14.5em !important;
          }
          .ql-indent-6 {
            padding-left: 17.5em !important;
          }
          .ql-indent-7 {
            padding-left: 20.5em !important;
          }
          .ql-indent-8 {
            padding-left: 23.5em !important;
          }
        }
      }
      //placeholder
      .ql-editor.ql-blank::before {
        color: ${props => props.theme.placeholder} !important;
        font-style: normal !important;
      }
      .ql-tooltip {
        display: none !important;
      }
      a:link,
      a:visited {
        color: ${props => props.theme.primary};
        cursor: pointer;
      }
    }
  }
  &:hover {
    #toolbar {
      ${props => !props.error && !props.isFocused && `border-color: ${props.theme.textColor}`} !important;
    }
    .ql-container {
      ${props =>
        !props.error &&
        !props.isFocused &&
        `border-color: ${props.theme.textColor} !important; border-top-color: ${props.theme.borderColor} !important;`};
    }
  }
`;

export const Error = styled(SmallText)`
  position: absolute;
  bottom: -20px;
  color: ${colors.negative};
`;

export const DropDownWrapper = styled(Column)`
  justify-content: center;
  box-sizing: border-box;
  min-height: 50px;
  padding: 5px 10px;
`;

export const DropDownItem = styled(Row)<{ align?: boolean }>`
  padding: 5px 0px;
  cursor: pointer;
  ${props => props.align && `min-width: 18px`};
  span,
  i {
    color: ${props => props.theme.textStrongColor};
  }
  svg {
    line {
      stroke: ${props => props.theme.textStrongColor};
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
    }
  }
  &:hover {
    span,
    i {
      color: ${props => props.theme.primary};
    }
    svg {
      line {
        stroke: ${props => props.theme.primary};
      }
    }
  }
`;

export const SelectedOptionWrapper = styled(Row)<{ align?: boolean; isDefaultOption?: boolean; disabled?: boolean }>`
  align-items: end;
  justify-content: space-between;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  ${props => props.align && 'min-width: 18px'};
  span {
    min-width: 65px;
    text-align: left;
  }
  span,
  i {
    color: ${props => (!props.isDefaultOption ? props.theme.primary : props.theme.textStrongColor)};
  }
  svg {
    line {
      stroke: ${props => (!props.isDefaultOption ? props.theme.primary : props.theme.textStrongColor)} !important;
    }
  }
  &:hover {
    span,
    i {
      color: ${props => !props.disabled && props.theme.primary};
    }
    svg {
      line {
        stroke: ${props => !props.disabled && props.theme.primary} !important;
      }
    }
  }
`;

export const ColorPickerIcon = styled.div<{ color?: string; isBgColor?: boolean; disabled?: boolean }>`
  min-width: 18px;
  svg {
    .ql-color-label {
      ${props =>
        !props.isBgColor &&
        `stroke: ${props.color} !important;
         opacity: 1 !important;`}
    }

    .ql-fill {
      fill: ${props => props.color} !important;
    }
  }
  &:hover {
    svg {
      .ql-stroke:not(.ql-color-label) {
        stroke: ${props => !props.disabled && props.theme.primary} !important;
      }
    }
  }
`;

export const LINK_POPUP_WIDTH = 200;
export const LinkPopup = styled(Row)<{ left: number; top: number }>`
  position: absolute;
  z-index: 2;
  width: ${LINK_POPUP_WIDTH}px;
  background: ${props => props.theme.background};
  box-shadow: ${props => props.theme.boxShadow} 0px 1px 3px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.borderColor};
  box-sizing: border-box;
  left: ${props => props.left}px;
  top: ${props => props.top + 15}px;
  div:first-child {
    width: 100%;
    margin-bottom: 0px;
    border: none;
  }
`;
