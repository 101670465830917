import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as semesterSelectors from '../semester/selectors';
import * as courseSelectors from './selectors';
import { getErrorMessage } from '../../api/network';
import { courseApi, gradeUnitApi, readingSourceApi, taskCategoryApi } from '../../api';
import { apiCall } from '../common/operations';
import { getGrades } from './grade/actions';
import { calendarData } from '../calendar/actions';
import { calendarActions } from '../calendar';
import { lambdaApi } from '../../api/lambdaApi';
import { getEventsList } from '../events/actions';
import { alert, NotificationMode } from '@components/common/snackbarNotifications/Notifications';
import { refreshGoogleEventsAndTasks } from '../googleCalendar/operations';
import t from '../../i18n/t';
import { selectCommitment } from '@state/workload/actions';
import { SidebarItemType } from '@state/workload/types';

function* getCourse(action: ReturnType<typeof actions.getCourse.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(courseApi.getCourse, semesterId, action.payload);
  if (ok) {
    yield put(actions.getCourse.success(response.data));
  } else {
    yield put(actions.getCourse.failure(getErrorMessage(response)));
  }
}

function* createGradeUnit(action: ReturnType<typeof actions.createGradeUnit.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(courseSelectors.getCourseId);
  const { ok, ...response } = yield apiCall(gradeUnitApi.createGradeUnit, semesterId, courseId, action.payload);
  if (ok) {
    yield put(actions.createGradeUnit.success(response.data));
    yield call(refreshGradesIfNeeded);
  } else {
    yield put(actions.createGradeUnit.failure(getErrorMessage(response)));
  }
}

function* updateGradeUnit(action: ReturnType<typeof actions.updateGradeUnit.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(courseSelectors.getCourseId);
  const { id, ...request } = action.payload;
  const { ok, ...response } = yield apiCall(gradeUnitApi.updateGradeUnit, semesterId, courseId, id, request);
  if (ok) {
    yield put(actions.updateGradeUnit.success(response.data));
    yield call(refreshGradesIfNeeded);
  } else {
    yield put(actions.updateGradeUnit.failure(getErrorMessage(response)));
  }
}

function* updateCalendarId(action: ReturnType<typeof actions.updateCalendarId.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { id, googleCalendarId, unlinkCalendarId } = action.payload;
  const { ok, ...response } = yield apiCall(courseApi.updateCalendarId, semesterId, id, { googleCalendarId });
  if (ok) {
    yield call(refreshGoogleEventsAndTasks);
    yield put(
      actions.updateCalendarId.success({
        id,
        googleCalendarId,
        unlinkCalendarId
      })
    );
    if (!googleCalendarId) {
      alert(t.UNSELECT_GOOGLE_CALENDAR_NOTIFICATION, NotificationMode.SUCCESS);
    } else {
      alert(t.SELECT_GOOGLE_CALENDAR_NOTIFICATION, NotificationMode.SUCCESS);
    }
  } else {
    yield put(actions.updateCalendarId.failure(getErrorMessage(response)));
  }
}

function* deleteGradeUnit(action: ReturnType<typeof actions.deleteGradeUnit.request>) {
  const id = action.payload;
  if (!id) {
    yield put(actions.deleteGradeUnit.success(id));
    return;
  }
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(courseSelectors.getCourseId);
  const { ok, ...response } = yield apiCall(gradeUnitApi.deleteGradeUnit, semesterId, courseId, action.payload!);
  if (ok) {
    yield put(actions.deleteGradeUnit.success(response.data.id));
    yield call(refreshGradesIfNeeded);
    yield put(calendarData.request());
  } else {
    yield put(actions.deleteGradeUnit.failure(getErrorMessage(response)));
  }
}

function* createReadingSource(action: ReturnType<typeof actions.createReadingSource.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { courseId: course, name, secondsPerPage, onChange } = action.payload;
  const courseId = course || (yield select(courseSelectors.getCourseId));
  const { ok, ...response } = yield apiCall(readingSourceApi.createReadingSource, semesterId, courseId, {
    name,
    secondsPerPage
  });
  if (ok) {
    yield put(actions.createReadingSource.success({ ...response.data, courseId }));
    if (onChange) {
      onChange(response.data.id, response.data);
    }
  } else {
    yield put(actions.createReadingSource.failure(getErrorMessage(response)));
  }
}

function* updateReadingSource(action: ReturnType<typeof actions.updateReadingSource.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { id, courseId, ...request } = action.payload;
  const course = courseId || (yield select(courseSelectors.getCourseId));
  const { ok, ...response } = yield apiCall(readingSourceApi.updateReadingSource, semesterId, course, id, request);
  if (ok) {
    yield put(actions.updateReadingSource.success({ ...response.data, courseId: course }));
  } else {
    yield put(actions.updateReadingSource.failure(getErrorMessage(response)));
  }
}

function* deleteReadingSource(action: ReturnType<typeof actions.deleteReadingSource.request>) {
  const { id, courseId } = action.payload;
  const course = courseId || (yield select(courseSelectors.getCourseId));

  if (!id) {
    yield put(actions.deleteReadingSource.success({ id, courseId: course }));
    return;
  }
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(readingSourceApi.deleteReadingSource, semesterId, course, id);
  if (ok) {
    yield put(actions.deleteReadingSource.success({ id: response.data.id, courseId: course }));
    yield put(calendarData.request());
  } else {
    yield put(actions.deleteReadingSource.failure(getErrorMessage(response)));
  }
}

function* createTaskCategory(action: ReturnType<typeof actions.createTaskCategory.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { courseId: course, name, emoji, onChange, isTaskList } = action.payload;
  const courseId = course || (yield select(courseSelectors.getCourseId));
  const { ok, ...response } = yield apiCall(taskCategoryApi.createTaskCategory, semesterId, courseId, { name, emoji });
  if (ok) {
    yield put(actions.createTaskCategory.success({ ...response.data, courseId }));
    if (onChange) {
      onChange(response.data.id, response.data);
    }
    if (isTaskList) {
      yield put(
        selectCommitment({
          id: response.data.id,
          type: SidebarItemType.TASK_CATEGORY,
          name: response.data.name,
          // @ts-ignore
          courseId: courseId!
        })
      );
    }
  } else {
    yield put(actions.createTaskCategory.failure(getErrorMessage(response)));
  }
}

function* updateTaskCategory(action: ReturnType<typeof actions.updateTaskCategory.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { id, courseId, ...request } = action.payload;
  const course = courseId || (yield select(courseSelectors.getCourseId));
  const { ok, ...response } = yield apiCall(taskCategoryApi.updateTaskCategory, semesterId, course, id, request);
  if (ok) {
    yield put(actions.updateTaskCategory.success({ ...response.data, courseId: course }));
  } else {
    yield put(actions.updateTaskCategory.failure(getErrorMessage(response)));
  }
}

function* deleteTaskCategory(action: ReturnType<typeof actions.deleteTaskCategory.request>) {
  const { id, courseId } = action.payload;
  const course = courseId || (yield select(courseSelectors.getCourseId));

  if (!id) {
    yield put(actions.deleteTaskCategory.success({ id, courseId: course }));
    return;
  }
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(taskCategoryApi.deleteTaskCategory, semesterId, course, id);
  if (ok) {
    yield put(actions.deleteTaskCategory.success({ id: response.data.id, courseId: course }));
    yield put(calendarData.request());
  } else {
    yield put(actions.deleteTaskCategory.failure(getErrorMessage(response)));
  }
}

function* toggleGradeWeightType() {
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(courseSelectors.getCourseId);
  const { ok, ...response } = yield apiCall(courseApi.toggleGradeWeightType, semesterId, courseId);
  if (ok) {
    yield put(actions.toggleGradeWeightType.success());
    yield put(getGrades.request());
  } else {
    yield put(actions.toggleGradeWeightType.failure(getErrorMessage(response)));
  }
}

export function* refreshGradesIfNeeded(payloadCourseCorrelationId?: string) {
  const courseCorrelationId = yield select(courseSelectors.getCourseCorrelationId);
  if (!courseCorrelationId) return;

  if (!payloadCourseCorrelationId || payloadCourseCorrelationId === courseCorrelationId) {
    yield put(getGrades.request());
  }
}

function* exportCourse(action: ReturnType<typeof actions.exportCourse.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(lambdaApi.exportCourse, {
    semesterId,
    courseId: action.payload.id,
    courseCorrelationId: action.payload.correlationId
  });
  if (ok) {
    yield put(actions.exportCourse.success(response.data));

    const json = JSON.stringify(response.data);

    //Convert JSON string to BLOB.
    const blob = new Blob([json], { type: 'text/plain;charset=utf-8' });

    const url = window.URL || window.webkitURL;
    const link = url.createObjectURL(blob);
    const a = document.createElement('a');
    a.download = `${action.payload.courseName}.shovel`;
    a.href = link;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    yield put(actions.exportCourse.failure(getErrorMessage(response)));
  }
}

function* importCourse(action: ReturnType<typeof actions.importCourse.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const {
    courseId,
    courseCorrelationId,
    courseName,
    courseColorHex,
    includeSchedule,
    includeTeachers,
    course
  } = action.payload;
  const { ok, ...response } = yield apiCall(lambdaApi.importCourse, {
    semesterId,
    courseId,
    courseCorrelationId,
    courseName,
    courseColorHex,
    includeSchedule,
    includeTeachers,
    ...course
  });
  if (ok) {
    yield put(actions.importCourse.success());
    yield put(calendarActions.commitmentsData.request());
    yield put(calendarActions.calendarData.request());
    yield put(getEventsList.request({}));
  } else {
    yield put(actions.importCourse.failure(response));
    alert();
  }
}

function* reorderCategoriesInsideCourse(action: ReturnType<typeof actions.reorderCategoriesInsideCourse.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { data, courseId } = action.payload;
  const request = {
    items: data.map(c => {
      return {
        id: c.id,
        ordinalNumber: c.ordinalNumber!
      };
    })
  };
  const { ok, ...response } = yield apiCall(taskCategoryApi.reorderCategoriesInsideCourse, semesterId, courseId, request);
  if (ok) {
    yield put(actions.reorderCategoriesInsideCourse.success({ courseId, taskCategories: data }));
  } else {
    yield put(actions.reorderCategoriesInsideCourse.failure(getErrorMessage(response)));
  }
}

function* getCategoriesByCourse(action: ReturnType<typeof actions.getCategoriesByCourse.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(taskCategoryApi.getByCourse, semesterId, action.payload);
  if (ok) {
    yield put(actions.getCategoriesByCourse.success(response.data));
  } else {
    yield put(actions.getCategoriesByCourse.failure(getErrorMessage(response)));
  }
}

function* getReadingSourcesByCourse(action: ReturnType<typeof actions.getReadingSourcesByCourse.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(readingSourceApi.getByCourse, semesterId, action.payload);
  if (ok) {
    yield put(actions.getReadingSourcesByCourse.success(response.data));
  } else {
    yield put(actions.getReadingSourcesByCourse.failure(getErrorMessage(response)));
  }
}

export default function*() {
  yield all([
    takeLatest(actions.getCourse.request, getCourse),
    takeLatest(actions.createGradeUnit.request, createGradeUnit),
    takeLatest(actions.updateGradeUnit.request, updateGradeUnit),
    takeLatest(actions.deleteGradeUnit.request, deleteGradeUnit),
    takeLatest(actions.createReadingSource.request, createReadingSource),
    takeLatest(actions.updateReadingSource.request, updateReadingSource),
    takeLatest(actions.deleteReadingSource.request, deleteReadingSource),
    takeLatest(actions.createTaskCategory.request, createTaskCategory),
    takeLatest(actions.updateTaskCategory.request, updateTaskCategory),
    takeLatest(actions.deleteTaskCategory.request, deleteTaskCategory),
    takeLatest(actions.toggleGradeWeightType.request, toggleGradeWeightType),
    takeLatest(actions.exportCourse.request, exportCourse),
    takeLatest(actions.importCourse.request, importCourse),
    takeLatest(actions.reorderCategoriesInsideCourse.request, reorderCategoriesInsideCourse),
    takeLatest(actions.getCategoriesByCourse.request, getCategoriesByCourse),
    takeLatest(actions.getReadingSourcesByCourse.request, getReadingSourcesByCourse),
    takeLatest(actions.updateCalendarId.request, updateCalendarId)
  ]);
}
