import React, { FC } from 'react';
import styled, { withTheme } from 'styled-components';
import {
  commonPopupCSS,
  DropDownArrow,
  filterShadow,
  filterTimelineDropdownStyle,
  getTextComponent
} from '@utils/popupUtils';
import Popup from 'reactjs-popup';
import { Column, FlexRow } from '../../layoutUtils';
import MaterialIcon from '../../icons/MaterialIcon';
import { RegularText, SmallText } from '@utils/typography';
import t from '@i18n/t';
import colors from '@utils/colors';
import { INLINE_INPUT_HORIZONTAL_PADDING, INLINE_INPUT_VERTICAL_PADDING } from '@components/common/inputs/Input.styles';
import TippyTooltip from '@components/common/tooltip/TippyTooltip';
import { hideAllTippies } from '@utils/calendar/calendarUtils';

export type OptionType = {
  label: string;
  value: any;
  defaultValue?: boolean;
  icon?: string | React.ReactElement;
};

type Props = {
  initialValue?: any;
  disabled?: boolean;
  options: Array<OptionType>;
  theme: any;
  onChange: Function;
  label?: string;
  fontSize?: number;
  header?: string;
  className?: string;
  right?: boolean;
  customTrigger?: (item?: OptionType) => React.ReactElement;
  tippy?: boolean;
  noCheck?: boolean;
};

export const renderItemIcon = (icon?: string | React.ReactElement, theme?: any) => {
  if (!icon) return undefined;
  return typeof icon === 'string' ? <MaterialIcon name={icon} color={theme?.textColor} /> : icon;
};

const renderDropDownItem = (
  item: OptionType,
  close: Function,
  handleClick: Function,
  fontSize: number,
  selectedOption: any
) => {
  const icon = renderItemIcon(item.icon);
  return (
    <DropDownItem
      fontSize={fontSize}
      key={item.value}
      onClick={e => {
        handleClick(item.value, e);
        close();
      }}
    >
      <FlexRow>
        {icon && <Column style={{ marginRight: 10 }}>{icon}</Column>}
        <span>{item.label}</span>
        {item.defaultValue && <DefaultOption>{` (${t.DEFAULT})`}</DefaultOption>}
      </FlexRow>
      {selectedOption === item && (
        <MaterialIcon style={{ marginLeft: 5 }} name={'done'} size={16} color={colors.primaryPurple} />
      )}
    </DropDownItem>
  );
};

const InlineDropDownInput: FC<Props> = ({
  initialValue,
  options,
  theme,
  onChange,
  label,
  disabled = false,
  fontSize = 13,
  header,
  className,
  customTrigger,
  right,
  tippy,
  noCheck
}) => {
  const color = disabled ? theme.captures : theme.textStrongColor;
  const TextComponent = getTextComponent(fontSize);
  const initialOption = options.find(o => o.value === initialValue);
  if (tippy && customTrigger) {
    return (
      <Container className={className}>
        <TippyTooltip
          target={customTrigger(initialOption)}
          position={`bottom-${right ? 'end' : 'start'}`}
          trigger={'click'}
          lightTheme
          interactive
          minWidth={150}
          arrow={false}
          disabled={disabled}
          noPadding
          content={
            <OptionsWrapper data-cy={'options'} tippy>
              {header && (
                <Header>
                  <TextComponent style={{ fontWeight: 'bold' }}>{header.toUpperCase()}</TextComponent>
                </Header>
              )}
              {options.map(item =>
                renderDropDownItem(item, hideAllTippies, onChange, fontSize, noCheck ? undefined : initialOption)
              )}
            </OptionsWrapper>
          }
        />
      </Container>
    );
  }

  return (
    <Container className={className}>
      <Popup
        trigger={open => {
          const iconColor = disabled ? theme.captures : colors.primaryPurple;
          return customTrigger ? (
            customTrigger(initialOption)
          ) : (
            <MenuWrapper>
              {label && <TextComponent style={{ color: theme.textColor, marginRight: 5 }}>{`${label}:`}</TextComponent>}
              <TextComponent style={{ color: color, minWidth: 50 }} className={'initial-option'}>
                {initialOption?.label}
              </TextComponent>
              <DropDownArrow name={'keyboard_arrow_down'} size={16} open={open} color={iconColor} />
            </MenuWrapper>
          );
        }}
        position={`bottom ${right ? 'right' : 'left'}`}
        on="click"
        contentStyle={{ ...filterTimelineDropdownStyle, width: 'max-content', minWidth: 146 }}
        arrowStyle={{ display: 'none' }}
        disabled={disabled}
      >
        {close => (
          <>
            <OptionsWrapper data-cy={'options'}>
              {header && (
                <Header>
                  <TextComponent style={{ fontWeight: 'bold' }}>{header.toUpperCase()}</TextComponent>
                </Header>
              )}
              {options.map(item => renderDropDownItem(item, close, onChange, fontSize, noCheck ? undefined : initialOption))}
            </OptionsWrapper>
          </>
        )}
      </Popup>
    </Container>
  );
};

export default withTheme(InlineDropDownInput);

const Container = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  border-radius: 5px;
  background: ${props => props.theme.backgroundDark};

  &:hover {
    background: ${props => props.theme.borderColor};
  }

  .popup-content {
    ${commonPopupCSS}
    ${filterShadow}
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: ${INLINE_INPUT_VERTICAL_PADDING}px ${INLINE_INPUT_HORIZONTAL_PADDING}px;
  padding-right: ${INLINE_INPUT_HORIZONTAL_PADDING / 2}px;
`;

const OptionsWrapper = styled.div<{ tippy?: boolean }>`
  display: flex;
  flex-direction: column;
  ${props => props.tippy && 'padding: 5px 0;'};
`;

const DropDownItem = styled(RegularText)<{ fontSize: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 8px 10px;
  cursor: pointer;
  font-size: ${props => props.fontSize}px;
  color: ${props => props.theme.textStrongColor};
  ${props => props.fontSize < 13 && `font-weight: bold`};
  &:hover {
    background-color: ${props => props.theme.backgroundDark};
    color: ${props => props.theme.textStrongColor};
  }
`;

const DefaultOption = styled(SmallText)`
  white-space: pre;
`;

const Header = styled(FlexRow)`
  padding: 9px 10px;
`;
