import React from 'react';
import { bindActionCreators } from 'redux';
import { openConfirmationDialog } from '@state/common/actions';
import { connect } from 'react-redux';
import t from '@i18n/t';

type State = { hasError: boolean };
type Props = {
  children: any;
  openConfirmationDialog: typeof openConfirmationDialog;
};

class FirefoxErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    if (error instanceof RangeError && error.message === 'Invalid time value') {
      this.props.openConfirmationDialog({
        action: () => {},
        title: t.FIREFOX_TIME_ISSUE_TITLE,
        message: t.FIREFOX_TIME_ISSUE_MESSAGE,
        hideCancel: true
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openConfirmationDialog
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(FirefoxErrorBoundary);
