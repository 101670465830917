import styled from 'styled-components';

import { Column, FlexColumn, FlexRow, Row, textOverflowStyle } from '../common/layoutUtils';
import colors from '../../utils/colors';
import MaterialIcon from '../common/icons/MaterialIcon';
import { MediumText, MiniText, RegularText, SmallText } from '../../utils/typography';

export const HiddenRow = styled.div<{ borderColor: string; backgroundColor?: string; hasSidebar?: boolean }>`
  padding: 5px;
  box-sizing: border-box;
  position: absolute;
  bottom: -27px;
  background-color: ${props => props.backgroundColor};
  right: -1px;
  border: 1px solid ${props => props.theme.courseColorFunction(props.borderColor)};
  //left: -1px;

  ${props =>
    props.hasSidebar && props.borderColor
      ? `
    left: -1px;
    padding-left: 7px;
     &:before {
      content: '';
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      border-bottom-left-radius: 5px;
      width: 3px;
      background: ${props.borderColor};
    }
  `
      : `
    left: -1px;
    1px solid ${props.theme.textColor}
  `};
  display: none;
  justify-content: space-between;
  z-index: 100;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 2px 2px ${props => props.theme.boxShadow}, 0px 0px 2px ${props => props.theme.boxShadow};
`;

// overlay for purple border
// export const Overlay = styled.div`
//   border: 2px solid ${colors.primaryPurple};
//   position: absolute;
//   left: -4px;
//   right: 0
//   top: 0;
//   bottom: -39px;
//   border-radius: 3px;
//   visibility: hidden;
//   z-index: 10;
// `;

export const Container = styled(Column)<{
  colorHex: string;
  hasSidebar?: boolean;
  backgroundColor?: string;
  isDraggable?: boolean;
  hasNoActions?: boolean;
  cardTheme?: any;
}>`
  position: relative;
  margin-top: 10px;
  padding: 6px 8px;
  background-color: ${props => props.backgroundColor};
  border: 1px solid ${props => props.cardTheme.courseColorFunction(props.colorHex)};
  border-radius: 5px;
  transition: all 0.2s ease;
  cursor: ${props => (props.isDraggable ? 'grab' : 'pointer')};
  ${props =>
    props.hasSidebar &&
    props.colorHex &&
    `
    padding-left: 10px;
    &:before {
      content: '';
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      border-radius: 5px 0 0 5px;
      width: 3px;
      background: ${props.colorHex};
    }
    `};

  ${props =>
    props.isDraggable &&
    `&:active {
      cursor: grabbing;
    }`}

  ${props =>
    !props.hasNoActions &&
    `
    &:hover {
      box-shadow: 0px 2px 2px ${props => props.cardTheme.boxShadow}, 0px 0px 2px ${props => props.cardTheme.boxShadow};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      ${HiddenRow} {
        display: flex;
      }
  }
  `}
`;

export const DueAndPlannedWrapper = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 5px;
  //> *:first-child {
  //  flex: 1;
  //  min-width: 0;
  //  margin-right: 5px;
  //  span {
  //    white-space: nowrap;
  //    text-overflow: ellipsis;
  //    overflow: hidden;
  //  }
  //}
  //> *:last-child {
  //  flex-shrink: 0;
  //}
`;

export const MarkCompleteBtn = styled.div<{ isOverdueSection?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    > span {
      color: ${colors.positive} !important;
    }
    > i {
      color: ${colors.positive} !important;
    }
  }
  ${props => props.isOverdueSection && 'margin-top: 2px;'};
`;

export const DeleteTaskIcon = styled(MaterialIcon)`
  &:hover {
    color: ${colors.negative} !important;
  }
`;

export const TaskWrapper = styled(FlexColumn)`
  background: ${props => props.theme.backgroundDark};
  border: 1px solid ${props => props.theme.borderColor};
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;
  align-items: flex-start;
`;

export const DueDate = styled(MediumText)`
  //checkbox width 20px + checkbox margin 10px
  // margin-left: 30px;
  width: 270px;
  > span {
    color: ${props => props.theme.textStrongColor};
  }
`;

export const CourseRow = styled(FlexRow)<{ color?: string }>`
  ${MiniText}, ${SmallText}, ${MediumText} {
    flex: 1;
    max-width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:not(:first-child) {
      color: ${props => props.color};
      &:before {
        color: ${props => props.color};
        content: '|';
        margin: 0 2px;
      }
    }
    cursor: default;
  }
  .task-category {
    display: flex;
    align-items: center;
    .emoji-mart-emoji {
      display: flex;
    }
  }
`;

export const TitleWrapper = styled(RegularText)`
  margin-top: 5px;
  font-weight: bold;
  flex: 1;
  ${textOverflowStyle}
`;

export const CushionIcon = styled(MaterialIcon)<{
  color?: string;
  backgroundColor?: string;
  clickable?: boolean;
  big?: boolean;
}>`
  color: ${props => props.color || colors.white};
  background-color: ${props =>
    props.backgroundColor || (props.color ? props.theme.courseColorFunction(props.color) : colors.primaryPurple)};
  border-radius: 5px;
  padding: ${props => (props.big ? '4px' : '2px')};
  ${props =>
    props.clickable
      ? `
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  `
      : `
    cursor: default;
  `};
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};
`;
