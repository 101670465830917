import React, { FC } from 'react';
import styled from 'styled-components';

import { TIME_INTERVAL } from './DatetimePicker';
import { FlexRow } from '../../layoutUtils';
import InputWrapper from '../InputWrapper';
import { DatepickerFormats, Formats } from 'shovel-lib/types';
import { toMomentDate } from 'shovel-lib/utils/timeUtils';
import DatetimeInput from './DatetimeInput';
import InlineInputWrapper from '../InlineInputWrapper';
import { RegularText } from '@utils/typography';

type Props = {
  nameFrom?: string;
  nameTo?: string;
  valueFrom: Date;
  valueTo: Date;
  onChangeFrom: (value: Date | string, end?: Date | string) => void;
  onChangeTo: (value: Date | string) => void;
  onBlurTo?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlurFrom?: (event: React.FocusEvent<HTMLInputElement>) => void;
  minDate?: Date;
  maxDate?: Date;
  required?: boolean;
  placeholder?: string;
  inline?: boolean;
  className?: string;
  disabled?: boolean;
  label?: string;
  errorFrom?: string;
  errorTo?: string;
  popperContainerId?: string;
  timeIntervals?: number;
  noErrorMargin?: boolean;
};

const DatetimeRangeInput: FC<Props> = ({
  nameFrom,
  nameTo,
  valueFrom,
  valueTo,
  onChangeFrom,
  onChangeTo,
  onBlurFrom,
  onBlurTo,
  minDate,
  maxDate,
  required = true,
  placeholder,
  disabled,
  className,
  label,
  errorFrom,
  errorTo,
  inline,
  noErrorMargin,
  popperContainerId,
  timeIntervals = TIME_INTERVAL
}) => {
  const sameDate = toMomentDate(valueFrom).isSame(valueTo, 'day');

  const changeFromDate = value => {
    const from = toMomentDate(value);
    let to: any = toMomentDate(valueTo);
    if (sameDate) {
      to.date(from.date());
      to.month(from.month());
      to.year(from.year());
    }

    if (from.isSameOrAfter(to)) {
      to = from.add(5, 'minutes').toDate();
    } else if (sameDate) {
      to = to.toDate();
    } else {
      to = undefined;
    }
    onChangeFrom(value, to);
  };

  const changeFromTime = value => {
    const dateTime = toMomentDate(value);
    const dateFrom = toMomentDate(valueFrom);
    dateTime.date(dateFrom.date());
    dateTime.month(dateFrom.month());
    dateTime.year(dateFrom.year());
    changeFromDate(dateTime.toDate());
  };

  const changeToTime = value => {
    const dateTime = toMomentDate(value);
    const dateTo = toMomentDate(valueTo);
    dateTime.date(dateTo.date());
    dateTime.month(dateTo.month());
    dateTime.year(dateTo.year());

    if (!sameDate) {
      onChangeTo(dateTime.toDate());
      return;
    }
    if (dateTime.isBefore(valueFrom)) {
      dateTime.add(1, 'day');
    }
    onChangeTo(dateTime.toDate());
  };

  const startMinutes = Math.abs(
    toMomentDate(valueFrom)
      .startOf('day')
      .diff(valueFrom, 'minute')
  );
  let startTimesFromIndex = Math.ceil(startMinutes / timeIntervals);
  let firstTimeDiff = timeIntervals - (startMinutes % timeIntervals || timeIntervals);
  if (firstTimeDiff === 0) {
    startTimesFromIndex += 1;
    firstTimeDiff = timeIntervals;
  }

  const Wrapper = inline ? InlineInputWrapper : InputWrapper;
  return (
    <Wrapper
      placeholder={placeholder}
      disabled
      className={className}
      label={label}
      required={required}
      noErrorMargin={noErrorMargin}
    >
      <DatetimeRangeWrapper disabled={disabled}>
        <DatetimeInput
          noErrorMargin
          name={nameFrom}
          value={valueFrom}
          onChange={changeFromDate}
          onBlur={onBlurFrom}
          minDate={minDate}
          maxDate={maxDate}
          required={required}
          disabled={disabled}
          inline={inline}
          bare={!inline}
          hideTime
          popperContainerId={popperContainerId}
          autosizeFormat={showYear => `ddd, ${Formats.MONTH_DAY_FORMAT}${showYear ? " 'YY" : ''}`}
          dateFormatFn={showYear => `EEE, ${DatepickerFormats.SHORT}${showYear ? " ''yy" : ''}`}
          touched={!!errorFrom}
          error={errorFrom}
        />
        &nbsp;
        <DatetimeInput
          noErrorMargin
          name={nameFrom}
          value={valueFrom}
          onChange={changeFromTime}
          onBlur={onBlurFrom}
          minDate={minDate}
          maxDate={maxDate}
          required={required}
          disabled={disabled}
          inline={inline}
          bare={!inline}
          popperContainerId={popperContainerId}
          showTimeOnly
          timeIntervals={timeIntervals}
          autosizeFormat={() => Formats.LOWERCASE_TIME}
        />
        <Separator>{'-'}</Separator>
        <DatetimeInput
          noErrorMargin
          name={nameTo}
          value={valueTo}
          onChange={changeToTime}
          onBlur={onBlurTo}
          maxDate={maxDate}
          required={required}
          disabled={disabled}
          inline={inline}
          bare={!inline}
          showTimeOnly
          timeIntervals={timeIntervals}
          popperContainerId={popperContainerId}
          autosizeFormat={() => Formats.LOWERCASE_TIME}
          startTimesFromIndex={sameDate ? startTimesFromIndex : undefined}
          firstTimeDiff={firstTimeDiff}
          touched={!!errorTo}
          error={errorTo}
        />
        {!sameDate && (
          <DatetimeInput
            noErrorMargin
            name={nameTo}
            value={valueTo}
            onChange={value => {
              const start = toMomentDate(valueFrom);
              onChangeTo(start.isSameOrAfter(value) ? start.add(5, 'minutes').toDate() : value);
            }}
            onBlur={onBlurTo}
            minDate={valueFrom}
            maxDate={maxDate}
            required={required}
            disabled={disabled}
            inline={inline}
            bare={!inline}
            hideTime
            popperContainerId={popperContainerId}
            autosizeFormat={showYear => `ddd, ${Formats.MONTH_DAY_FORMAT}${showYear ? " 'YY" : ''}`}
            dateFormatFn={showYear => `EEE, ${DatepickerFormats.SHORT}${showYear ? " ''yy" : ''}`}
          />
        )}
      </DatetimeRangeWrapper>
    </Wrapper>
  );
};

export default DatetimeRangeInput;

const DatetimeRangeWrapper = styled(FlexRow)<{ disabled?: boolean }>`
  line-height: 17px;
  ${props => props.disabled && `${Separator} { color: ${props.theme.disabledTextColor}}`}
`;

const Separator = styled(RegularText)`
  margin: 0 5px;
`;
