import { WorkloadTask } from 'shovel-lib/types';

export type WorkloadState = {
  readonly selectedItemId?: string | number;
  readonly selectedItemType: SidebarItemType;
  readonly selectedItemName?: string;
  readonly selectedItemColor?: string;
  readonly selectedItemCourseId?: string | number;
  readonly tasks: WorkloadTask[];
  readonly groupWorkloadBy: string;
  readonly showCompletedWorkload: boolean;
  readonly newWorkloadTasks?: { [key: string]: WorkloadTask[] };
  readonly loading: boolean; // forced skeleton when selection in the sidebar is changed
  readonly expandedItemType?: ExpandItemType;
  readonly expandedItemId?: number;
};

export enum SidebarItemType {
  SECTION = 'SECTION',
  COURSE = 'CLASS',
  ACTIVITY = 'ACTIVITY',
  TASK_CATEGORY = 'TASK_CATEGORY'
}

export enum ExpandItemType {
  COURSE = 'CLASS'
}

export enum SidebarItemName {
  RANDOM = 'RANDOM',
  COURSE = 'CLASS',
  ACTIVITY = 'ACTIVITY',
  TRASH = 'TRASH'
}
