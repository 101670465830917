import React, { FC } from 'react';
import styled, { withTheme } from 'styled-components';
import { TooltipPosition } from '../../utils/popupUtils';
import MaterialIcon from './icons/MaterialIcon';
import t from '../../i18n/t';
import { FlexRow } from './layoutUtils';
import { MediumText } from '../../utils/typography';
import Toggle from './Toggle';
import TippyTooltip from './tooltip/TippyTooltip';
import { Placement } from 'tippy.js';
import { MORE_OPTIONS_CLASS_NAME } from '../../state/common/utils';
import { hideAllTippies } from '../../utils/calendar/calendarUtils';

export type OptionType = {
  value: any;
  label: string;
  icon?: any;
  outlineIcon?: boolean;
  onClick?: any;
};

type Props = {
  options?: OptionType[];
  theme: any;
  tooltipPosition?: Placement;
  dropdownPosition?: TooltipPosition;
  className?: any;
  toggle?: boolean;
  horizontal?: boolean;
  header?: string;
  width?: any;
  tooltipText?: string;
  iconSize?: number;
  placeToTopRight?: boolean;
  triggerColor?: string;
  backgroundColor?: string;
};

const renderDropDownItem = (item: OptionType) => {
  return (
    <Item
      key={item.value}
      onClick={() => {
        if (item.onClick) item.onClick();
        hideAllTippies();
      }}
    >
      {item.icon && <MaterialIcon name={item.icon} outline={item.outlineIcon} size={15} />}
      <Label>{item.label}</Label>
    </Item>
  );
};

const renderToggleItem = (item: OptionType) => {
  return (
    <Item key={item.value}>
      <Toggle onChange={item.onClick} active={item.value} label={item.label} reverse alignBaseline />
    </Item>
  );
};

const MoreOptionDropdown: FC<Props> = ({
  options,
  tooltipPosition,
  className,
  theme,
  horizontal,
  toggle,
  header,
  tooltipText,
  iconSize = 15,
  placeToTopRight,
  triggerColor,
  backgroundColor
}) => {
  const iconName = horizontal ? 'more_horiz' : 'more_vert';
  if (!options) return null;

  if (options.length === 1 && !toggle && options[0].icon) {
    const item = options[0];
    return (
      <Wrapper className={`${className} ${MORE_OPTIONS_CLASS_NAME}`} placeToTopRight={placeToTopRight} oneOption>
        <div className={'fc-daygrid-more-link fc-more-link'} style={{ backgroundColor }}>
          <TippyTooltip
            target={
              <Container data-cy={'menu'} onClick={item.onClick ? () => item.onClick() : undefined}>
                <MaterialIcon name={item.icon} size={12} color={triggerColor || theme.textColor} />
              </Container>
            }
            content={item.label}
            position={tooltipPosition}
            width={'max-content'}
          />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={`${className} ${MORE_OPTIONS_CLASS_NAME}`} placeToTopRight={placeToTopRight}>
      <Menu
        target={
          <div className={'fc-daygrid-more-link fc-more-link'}>
            <TippyTooltip
              target={
                <Container data-cy={'menu'}>
                  <MaterialIcon name={iconName} size={iconSize} color={triggerColor || theme.textColor} />
                </Container>
              }
              content={tooltipText || t.MORE_OPTIONS}
              position={tooltipPosition}
              width={'max-content'}
              // withoutArrow
            />
          </div>
        }
        content={
          <OptionsWrapper data-cy={'options'}>
            {header && (
              <Header>
                <MediumText>{header.toUpperCase()}</MediumText>
              </Header>
            )}
            {options && (!toggle ? options.map(renderDropDownItem) : options.map(renderToggleItem))}
          </OptionsWrapper>
        }
        interactive
        lightTheme
        arrow={false}
        position={'bottom-start'}
        trigger={'click'}
      />
    </Wrapper>
  );
};

export default withTheme(MoreOptionDropdown);

const Wrapper = styled.div<{ placeToTopRight?: boolean; oneOption?: boolean }>`
  cursor: pointer;

  ${props =>
    props.oneOption &&
    `
    i { font-weight: normal !important; }
  `}

  ${props =>
    props.placeToTopRight &&
    `
    position: absolute;
    top: 0;
    right: 0;
  `}
`;

const Container = styled.div<{ color?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 9999;

  > i {
    font-weight: bold;
    font-size: 16px;
    background: transparent;
  }

  ${props =>
    props.color &&
    `
    &:hover {
      > i {
        color: ${props => props.theme.textStrongColor};
      }
    }
    `}
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px 0px;
  box-sizing: border-box;
`;

const Item = styled(FlexRow)`
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.backgroundLight};
    > i {
      color: ${props => props.theme.textStrongColor};
    }
    > span {
      color: ${props => props.theme.textStrongColor};
    }
  }
`;

const Label = styled(MediumText)`
  margin-left: 10px;
`;

const Header = styled(FlexRow)`
  padding: 7px 10px;
`;

const Menu = styled(TippyTooltip)`
  padding: 0;
  .tippy-content {
    padding: 0;
    width: 180px;
  }
`;
