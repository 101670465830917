import { createSelector } from 'reselect';
import { DetailedSemesterInfo, Semester, SemesterListDto, SemesterRangeInfo } from 'shovel-lib/types';
import { SemesterState } from './types';
import { RootState } from '../rootReducer';
import TimeZoneMapper from './timeZoneMapper';
import { EventToTermHoliday } from '../../utils/calendar/calendarEventConverters';
import { getType } from 'typesafe-actions';
import { getDetailedSemester, getUserSemesters } from './actions';

export const getId = ({ semester }: { semester: SemesterState }) => {
  if (!semester.info) {
    return undefined;
  }
  return semester.info.id;
};

export const getHolidaysCorrelationId = ({ semester }: { semester: SemesterState }) => {
  if (!semester.info) {
    return undefined;
  }
  return semester.info.holidaysCorrelationId;
};

export const getSemesterState = (state: RootState) => state.semester;

export const getSemesterRangeInfo = createSelector(
  [(state: RootState) => state.semester.info],
  (info?: Semester): SemesterRangeInfo | null => {
    if (!info) return null;
    const { utcOffsetInSeconds, details } = info;
    if (!details) {
      return null;
    }
    const { starts, ends: classesEnds, examPeriodEnds: ends } = details;
    return { starts, ends, utcOffsetInSeconds, classesEnds };
  }
);

export const getUserSemesterList = ({ semester }: RootState): SemesterListDto[] => semester.userSemesters;

export const isSemesterListLoading = ({ common }: RootState): boolean => {
  const getCurrentRequest = getType(getUserSemesters.request).split('_REQUEST')[0];
  return common.loaders[getCurrentRequest];
};

export const isSemesterDetailsLoading = ({ common }: RootState): boolean => {
  const getCurrentRequest = getType(getDetailedSemester.request).split('_REQUEST')[0];
  return common.loaders[getCurrentRequest];
};

export const getSemesterDetailedInfo = ({ semester }: { semester: SemesterState }): DetailedSemesterInfo | null => {
  const { detailedInfo, id } = semester.info as Semester;
  if (!detailedInfo) {
    return null;
  }
  const {
    starts: { date: starts },
    ends: { date: ends },
    examPeriodEnds: { date: examPeriodEnds },
    timeZone: { id: timezoneId, countryId }
  } = detailedInfo;
  return {
    id,
    starts: `${starts}T00:00:00`,
    ends: `${ends}T23:59:59`,
    examPeriodEnds: `${examPeriodEnds}T23:59:59`,
    timezoneId,
    countryId
  };
};

export const getSemesterHolidays = ({ semester }: RootState) => {
  const { holidays } = semester.info as Semester;
  return (holidays || []).map(EventToTermHoliday);
};

export const getSemesterAuth = (state: RootState) => {
  const { info } = state.semester;
  return info ? info.user : undefined;
};

export const getTimeZone = ({ semester }: { semester: SemesterState }) =>
  TimeZoneMapper.toJsTimeZone(semester.info?.details?.timeZone || 'UTC');

export const getCushionTasks = (state: RootState) => {
  return { data: state.semester.cushion.tasks, loading: state.semester.loading };
};

export const getSemesterStatus = ({ semester }: RootState) => {
  if (!semester.info) return null;
  return semester.info.state;
};

export const getTimelineColorMode = (state: RootState) => {
  return state.semester.cushion.timelineColorMode;
};

export const getCushionSidebarCollapsed = (state: RootState) => {
  return state.semester.cushionSidebarCollapsed;
};

export const getFirstName = (state: RootState) => {
  return state.semester.info?.user?.firstName;
};

export const getCurrentUser = (state: RootState) => {
  return state.semester.info?.user;
};

export const getCountryCode = (state: RootState) => {
  //@ts-ignore
  return state.semester.info?.details?.countryCode;
};

export const getDemoInfo = (state: RootState) => {
  return {
    semesterId: state.semester.info?.id,
    firstName: state.semester.info?.user?.firstName,
    semesterState: state.semester.info?.state
  };
};

export const isFirstSemester = (state: RootState) => state.semester.countSemesters === 1;
