import { all, put, select, take, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import moment, { Moment } from 'moment';
import { DATE_FORMAT } from 'shovel-lib/utils/timeUtils';
import { semesterSelectors } from '../semester';
import { taskActions } from '../task';
import { calendarActions, calendarSelectors } from '../calendar';
import { apiCall } from '../common/operations';

import * as actions from './actions';
import * as selectors from './selectors';
import { WeekCalendarState } from './types';
import monthApi from '@api/monthApi';
import { DatesContainingTasks } from 'shovel-lib/types';

function* getDatesContainingTasksMini(action: ReturnType<typeof actions.getDatesContainingTasksMini.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { startDate: start, endDate: end } = action.payload;

  const startDate = moment(start);
  const endDate = moment(end);

  const { ok, ...response } = yield apiCall(
    monthApi.getDatesContainingTasks,
    semesterId,
    startDate.format(DATE_FORMAT),
    endDate.format(DATE_FORMAT)
  );

  if (ok) {
    yield put(actions.getDatesContainingTasksMini.success({ ...response.data, startDate, endDate }));
  } else {
    yield put(actions.getDatesContainingTasksMini.failure(response));
  }
}

function* onStartWeekTaskUpdateListener() {
  while (true) {
    const { type, payload } = yield take([
      actions.stopWeekTaskUpdateListener,
      taskActions.createTasks.success,
      taskActions.saveManageTask.success,
      taskActions.deleteTask.success,
      calendarActions.deleteCourse.success
      // calendarActions.updateCourse.success - only if colors are shown
    ]);

    if (type === getType(actions.stopWeekTaskUpdateListener)) {
      break;
    }

    const { startDate, endDate }: WeekCalendarState = yield select(selectors.getDateRange);

    if (!startDate || !endDate) {
      continue;
    }

    const isTaskInRange = ({ dueDate }: { dueDate?: Moment | Date | string | null }) => {
      if (!dueDate) {
        return false;
      }

      const momentDue = moment(dueDate);

      return momentDue.isSameOrAfter(startDate) && momentDue.isSameOrBefore(endDate);
    };

    let shouldRefetch = false;

    switch (type) {
      case getType(taskActions.createTasks.success): {
        shouldRefetch = payload.tasks.some(isTaskInRange);
        break;
      }
      case getType(taskActions.saveManageTask.success): {
        shouldRefetch =
          !moment(payload.response.dueDate).isSame(moment(payload.task.dueDate)) &&
          // if new value in range
          (isTaskInRange(payload.response) ||
            // if old value in range
            isTaskInRange(payload.task));
        break;
      }
      case getType(taskActions.deleteTask.success): {
        const datesContainingTasks: DatesContainingTasks = yield select(calendarSelectors.getDatesContainingTasks);
        shouldRefetch = Object.entries(datesContainingTasks)
          .flatMap(([, val]) => val)
          .some(({ taskId }) => taskId === payload.id);
        break;
      }
      case getType(calendarActions.deleteCourse.success): {
        const datesContainingTasks: DatesContainingTasks = yield select(calendarSelectors.getDatesContainingTasks);
        shouldRefetch = Object.entries(datesContainingTasks)
          .flatMap(([, val]) => val)
          //@ts-ignore
          .some(({ courseCorrelationId }) => courseCorrelationId === payload.correlationId);
        break;
      }
    }

    if (shouldRefetch) {
      yield put(calendarActions.getDatesContainingTasks.request({ startDate, endDate }));
    }
  }
}

export default function*() {
  yield all([
    takeLatest(actions.getDatesContainingTasksMini.request, getDatesContainingTasksMini),
    takeLatest(actions.startWeekTaskUpdateListener, onStartWeekTaskUpdateListener)
  ]);
}
