import styled from 'styled-components';

// TEXT

export const BaseText = styled.span<{ color?: string; strong?: boolean }>`
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  color: ${props => props.color || (props.strong ? props.theme.textStrongColor : props.theme.textColor)};
  word-break: break-word;
  line-height: 1.5;
`;

export const MiniText = styled(BaseText)`
  font-size: 10px;
  font-weight: bold;
`;

export const SmallText = styled(BaseText)`
  font-size: 11px;
  font-weight: bold;
`;

export const MediumText = styled(BaseText)`
  font-size: 12px;
  font-weight: bold;
`;

export const RegularText = styled(BaseText)`
  font-size: 13px;
  font-weight: 600;
`;

export const LargeText = styled(BaseText)`
  font-size: 16px;
  font-weight: bold;
`;

export const ExtraLargeText = styled(BaseText)`
  font-size: 21px;
  font-weight: bold;
`;

// INPUTS

export const BaseTextInput = styled.input`
  font-family: 'Quicksand', sans-serif;
  color: ${({ theme }) => theme.textStrongColor};
  background-color: ${({ theme }) => theme.background};
  outline: none;
  padding: 10px 8px;
  box-sizing: border-box;
  transition: border-color 0.2s ease;
  font-style: normal;
  font-weight: normal;
  max-width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.placeholder};
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.placeholder};
  }
`;

export const RegularTextInput = styled(BaseTextInput)`
  font-size: 13px;
  line-height: 1.5;
  font-weight: 600;
`;

export const Title = styled(RegularText)`
  margin: 10px 0 20px 0;
  text-align: center;
  color: ${({ theme }) => theme.textStrongColor};
`;

export const SectionTitle = styled(RegularText)<{ grayed?: boolean }>`
  text-transform: uppercase;
  margin: 10px 0;
  ${props => props.grayed && `color: ${props.theme.disabledTextColor}`};
`;

export const fontSizes = {
  calendarEventTime: '11px',
  calendarEventTitle: '11px'
};
