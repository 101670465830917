import React from 'react';
import styled from 'styled-components';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import { MediumText, RegularText } from '@utils/typography';
import { Column, Row, textOverflowStyle } from '../layoutUtils';
import colors from '@utils/colors';
import { isMobile } from '@utils/screenUtils';
import MaterialIcon from '../icons/MaterialIcon';
import storage from '@utils/storage';
import { darkTheme, lightTheme } from '@assets/themes';

export enum NotificationMode {
  ERROR = 'error',
  ALERT = 'alert',
  SUCCESS = 'success',
  INFO = 'info'
}

const snackbarIcons = {
  [NotificationMode.ERROR]: 'error_outline',
  [NotificationMode.ALERT]: 'warning',
  [NotificationMode.SUCCESS]: 'check_circle_outline',
  [NotificationMode.INFO]: 'info'
};

const snackbarColor = {
  [NotificationMode.ERROR]: colors.negative,
  [NotificationMode.ALERT]: colors.warning,
  [NotificationMode.SUCCESS]: '#F5F7FA',
  [NotificationMode.INFO]: colors.primaryPurple
};

const snackbarBorderColor = {
  [NotificationMode.ERROR]: colors.negative,
  [NotificationMode.ALERT]: colors.warning,
  [NotificationMode.SUCCESS]: colors.primaryPurple,
  [NotificationMode.INFO]: colors.white
};

const getDefaultMessage = (mode: NotificationMode) => {
  if (mode === NotificationMode.ERROR) return 'Something went wrong, please reload and try again.';
  if (mode === NotificationMode.SUCCESS) return 'Success!';
  return undefined;
};

export const alert = (
  message?: string,
  mode: NotificationMode = NotificationMode.ERROR,
  duration: number | null = 3000,
  closable: boolean = true
) => {
  const text = message || getDefaultMessage(mode);
  const color = mode === NotificationMode.SUCCESS ? colors.primaryPurple : colors.white;
  text &&
    toast.notify(
      ({ onClose }) => (
        <Snackbar background={snackbarColor[mode]} border={snackbarBorderColor[mode]} mobile={isMobile}>
          <Message>
            {mode !== NotificationMode.INFO && <NotificationIcon name={snackbarIcons[mode]} color={color} />}
            <RegularText color={color}>{text}</RegularText>
          </Message>
          {closable && <CloseIcon name={'close'} onClick={onClose} color={color} />}
        </Snackbar>
      ),
      { duration }
    );
};

export const notify = ({
  mode = NotificationMode.SUCCESS,
  title,
  body,
  onClick
}: {
  mode?: NotificationMode;
  title: string;
  body: string;
  onClick: Function;
}) => {
  const theme = storage.getTheme() === 'light' ? lightTheme : darkTheme;
  toast.notify(
    ({ onClose }) => (
      <NotificationSnackbar
        background={snackbarColor[mode]}
        border={snackbarBorderColor[mode]}
        theme={theme}
        onClick={() => {
          onClick();
          onClose();
        }}
      >
        <NotificationTitle strong>{title}</NotificationTitle>
        <NotificationBody strong>{body}</NotificationBody>
      </NotificationSnackbar>
    ),
    { duration: 10000, position: 'top-right' }
  );
};

const Snackbar = styled(Row)<{ background: string; border: string; mobile?: boolean }>`
  border-radius: 5px;
  background: ${props => props.background};
  border: 1px solid ${props => props.border};
  min-width: ${props => (props.mobile ? 200 : 300)}px;
  max-width: ${props => (props.mobile ? 600 : 800)}px;
  padding: 15px;
  justify-content: space-between;
  color: ${colors.white};
`;

const NotificationSnackbar = styled(Column)<{ theme: any; background: string; border: string }>`
  border-radius: 5px;
  background: ${props => props.background} !important;
  border: 1px solid ${props => props.border} !important;
  min-width: 200px;
  max-width: 300px;
  padding: 15px 25px;
  cursor: pointer;
  box-shadow: 0px 6px 25px ${({ theme }) => theme.boxShadow} !important;

  ${MediumText} {
    color: ${({ theme }) => theme.textStrongColor};
  }
`;

const Message = styled(Row)``;

const CloseIcon = styled(MaterialIcon)`
  font-size: 16px;
`;

const NotificationIcon = styled(CloseIcon)`
  margin-right: 10px;
`;

const NotificationTitle = styled(MediumText)`
  ${textOverflowStyle};
`;

const NotificationBody = styled(MediumText)`
  ${textOverflowStyle};
  white-space: pre;
`;
