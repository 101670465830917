import colors from './colors';
import t from '../i18n/t';
import { TaskType } from 'shovel-lib/types';
import * as timeUtils from 'shovel-lib/utils/timeUtils';

const NO_VALUE_FOR_DURATION_IN_HOURS = '0h';
const NO_VALUE_FOR_DURATION_IN_MINUTES = '0m 00s';

const secondsToDurationInMinutes = (totalSeconds: number): string => {
  if (!totalSeconds) return NO_VALUE_FOR_DURATION_IN_MINUTES;

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const minutesPart = minutes !== 0 ? `${minutes}${seconds === 0 ? ' min' : 'm'}` : '';

  const secondsPart = seconds !== 0 ? `${seconds}${minutes === 0 ? ' sec' : 's'}` : '';

  return [minutesPart, secondsPart].join(' ').trim();
};

const minutesToDurationInHours = (totalMinutes: number, fullFormat: boolean = false): string => {
  if (!totalMinutes) return NO_VALUE_FOR_DURATION_IN_HOURS;

  const hours = totalMinutes >= 0 ? Math.floor(totalMinutes / 60) : -Math.ceil(totalMinutes / 60);
  const minutes = totalMinutes >= 0 ? Math.floor(totalMinutes % 60) : -Math.floor(totalMinutes % 60);

  const formattedMinutes = `0${minutes}`.slice(-2);

  const hoursPart = fullFormat || hours !== 0 ? `${hours}h` : '';
  const minutesPart = fullFormat || minutes !== 0 ? `${formattedMinutes}m` : '';
  const sign = totalMinutes < 0 ? '-' : '';

  return sign + [hoursPart, minutesPart].join(' ').trim();
};

/**
 * Currently we have many values being negative where they should actually show 0,
 * so this will handle those cases temporarily
 * */
const minutesToDurationInHoursNoNegative = (totalMinutes: number, fullFormat: boolean = false) => {
  const total = totalMinutes < 0 ? 0 : totalMinutes;
  return minutesToDurationInHours(total, fullFormat);
};

const cushionToColor = (cushion: number): string | undefined => {
  if (cushion < 0) {
    return colors.negative;
  }
  return undefined;
};

const toDaysAheadString = (days: number): string => {
  return `${days || 0} ${days === 1 ? t.DAY : t.DAYS} ${t.AHEAD}`;
};

const toDaysAheadDiff = (daysAhead: number, dueDate: Date | string) => {
  const today = timeUtils.now().startOf('day');
  const startStudying = timeUtils
    .toMomentDate(dueDate)
    .subtract(daysAhead, 'days')
    .startOf('day');
  const diff = startStudying.diff(today, 'days');

  let label = t.TODAY;
  let emoji = t.EMOJI_SURPRISED;

  if (diff < 0) {
    label = `${Math.abs(diff)} ${diff === -1 ? t.DAY : t.DAYS} ${t.AGO}`;
  }
  if (diff > 0) {
    label = `${t.IN} ${diff} ${diff === 1 ? t.DAY : t.DAYS}`;
    emoji = t.EMOJI_WINKING;
  }
  return { emoji, label };
};

export {
  secondsToDurationInMinutes,
  minutesToDurationInHoursNoNegative,
  minutesToDurationInHours,
  cushionToColor,
  toDaysAheadString,
  toDaysAheadDiff
};
