import React, { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as ShovelLogo } from '../../../assets/images/shovel-logo.svg';
import { ExtraLargeText, LargeText, MediumText } from '../../../utils/typography';
import { Column, Row } from '../../common/layoutUtils';
import colors from '../../../utils/colors';

type Props = {
  title?: string;
  subtitle?: string;
  logoText?: string;
  additionalLogo?: any;
  renderTitle?: any;
};

const AuthHeading: FC<Props> = ({ title, subtitle, logoText, additionalLogo, renderTitle }) => (
  <Container>
    <Row>
      <Logo />
      {additionalLogo && <img src={additionalLogo} height={30} style={{ marginLeft: 10 }} />}
    </Row>
    {logoText && <LargeText color={colors.primaryPurple}>{logoText}</LargeText>}
    <Content>
      <Title>{title || renderTitle()}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Content>
  </Container>
);

export default AuthHeading;

const Container = styled(Column)`
  align-items: center;
  margin-bottom: 20px;
`;

const Logo = styled(ShovelLogo)`
  height: 50px;
  width: 50px;
`;

const Content = styled(Column)`
  margin-top: 20px;
  align-items: center;
`;

const Title = styled(ExtraLargeText)`
  text-align: center;
  color: ${props => props.theme.textStrongColor};
  display: flex;
  align-items: center;
  .emoji-mart-emoji {
    display: flex;
  }
`;

const Subtitle = styled(MediumText)`
  color: #ff63b6;
  margin-top: 5px;
  display: block;
`;
