import { actionCreator, asyncActionsCreator } from '../common/utils';
import {
  AddOrUpdateTaskRequest,
  AddPageRangesForSelection,
  ChangeCategoryOrReadingRequest,
  ChangeReadingResponseItem,
  CreateTasksRequest,
  CreateTasksResponse,
  DeleteSubtaskRequest,
  GetManageTask,
  ManageTask,
  MoveToTodoRequest,
  OpenManageTaskDialog,
  PageRange,
  PileTask,
  PushDueDateForSelection,
  ReadingSourceInfoDto,
  SavePageRangesRequest,
  SetStartAheadForSelection,
  SetTimeNeededRequest,
  Subtask,
  Task,
  TaskCategoryDto,
  TaskDto,
  TaskStatusRequest,
  TaskStatusResponse,
  TimeEntry,
  UpdateTaskDetailsRequest,
  ViewPlannedTask
} from 'shovel-lib/types';
import { CreateRepeatPatternDialogType } from './types';
import { WorkloadTask } from 'shovel-lib/types/task';

const asyncActions = asyncActionsCreator('@@task');
const action = actionCreator('@@task');

export const openManageTaskDialog = action<OpenManageTaskDialog | PileTask | WorkloadTask>('OPEN_MANAGE_TASK_DIALOG');
export const closeManageTaskDialog = action<void>('CLOSE_MANAGE_TASK_DIALOG');

export const getManageTask = asyncActions<GetManageTask, ManageTask, string>('GET_MANAGE_TASK');
export const saveManageTask = asyncActions<
  { task: WorkloadTask | ManageTask; request: UpdateTaskDetailsRequest },
  { taskId: number } & {
    request: UpdateTaskDetailsRequest;
    response: UpdateTaskDetailsRequest & { timeEntries: TimeEntry[] };
    task: any;
  },
  string
>('SAVE_MANAGE_TASK');
export const getPageRanges = asyncActions<number, { taskId: number; pageRanges: PageRange[] }, string>('GET_PAGE_RANGES');
export const savePageRanges = asyncActions<SavePageRangesRequest, { taskId: number; data: Task }, string>(
  'SAVE_PAGE_RANGES'
);

export const addOrUpdateSubtask = asyncActions<AddOrUpdateTaskRequest, AddOrUpdateTaskRequest, string>(
  'ADD_OR_UPDATE_SUBTASK'
);
export const deleteSubtask = asyncActions<DeleteSubtaskRequest, DeleteSubtaskRequest, string>('DELETE_SUBTASK');
export const updateTaskStatus = asyncActions<
  { taskId: number; data: TaskStatusRequest & { plannedTaskId?: number }; callback?: any },
  TaskStatusRequest & TaskStatusResponse & { taskId: number; plannedTaskId?: number },
  string
>('UPDATE_TASK_STATUS');
export const moveToTodo = asyncActions<
  { taskId: number; data: MoveToTodoRequest },
  MoveToTodoRequest & TaskStatusResponse & { taskId: number },
  string
>('MOVE_TO_TODO');
export const deleteTask = asyncActions<
  { id: number; deleteForever?: boolean },
  { id: number; deleteForever?: boolean },
  string
>('DELETE_TASK');

type WithCategoryAndSource<T> = T & {
  readingSourceId?: number;
  taskCategoryId?: number;
  workloadTable?: string;
};
export const createTasks = asyncActions<
  WithCategoryAndSource<CreateTasksRequest> & {
    isFromSidebar?: boolean;
    planTaskEvent?: { start: any; end: any } & any;
    callback?: any;
  },
  WithCategoryAndSource<CreateTasksResponse> & { isFromSidebar?: boolean },
  string
>('CREATE_TASKS');

export const openTasksModal = action<TaskDto[]>('OPEN_TASKS_MODAL');
export const closeTasksModal = action<void>('CLOSE_TASKS_MODAL');

export const deleteTasks = asyncActions<
  { ids: number[]; deleteForever?: boolean },
  { ids: number[]; deleteForever?: boolean },
  string
>('DELETE_TASKS');
export const completeTasks = asyncActions<number[], number[], string>('COMPLETE_TASKS');
export const moveTasksToTodo = asyncActions<number[], number[], string>('MOVE_TASKS_TO_TODO');
export const setTasksStartAhead = asyncActions<SetStartAheadForSelection, SetStartAheadForSelection, string>(
  'SET_TASKS_START_AHEAD'
);
export const setTasksPriority = asyncActions<
  { taskIds: number[]; priority: number },
  { taskIds: number[]; priority: number },
  string
>('SET_TASKS_PRIORITY');
export const pushTasksDueDate = asyncActions<PushDueDateForSelection, Task[], string>('PUSH_TASKS_DUE_DATE');
export const setTimeNeeded = asyncActions<SetTimeNeededRequest, SetTimeNeededRequest, string>('ADD_MORE_TIME');
export const addPageRangeForTasks = asyncActions<AddPageRangesForSelection, Task[], string>('ADD_PAGE_RANGE_FOR_TASKS');

export const openNewCreateTaskDialog = action<
  { courseId: number; taskCategoryId?: number; readingSourceId?: number } | undefined | void
>('OPEN_NEW_CREATE_TASK_DIALOG');
export const closeNewCreateTaskDialog = action<void>('CLOSE_NEW_CREATE_TASK_DIALOG');

export const openRepeatPatternDialog = action<CreateRepeatPatternDialogType>('OPEN_REPEAT_PATTERN_DIALOG');
export const closeRepeatPatternDialog = action<void>('CLOSE_REPEAT_PATTERN_DIALOG');

export const changeTaskCategory = asyncActions<
  ChangeCategoryOrReadingRequest,
  { taskIds: number[]; category: TaskCategoryDto },
  string
>('CHANGE_TASK_CATEGORY');

export const changeReadingSource = asyncActions<ChangeCategoryOrReadingRequest, ChangeReadingResponseItem[], string>(
  'CHANGE_READING_SOURCE'
);

export const changeCourse = asyncActions<ChangeCategoryOrReadingRequest & { courseName?: string }, Task[], string>(
  'CHANGE_COURSE'
);

export const reorderSubtasks = asyncActions<
  { id: number; subtasks: Subtask[] },
  { id: number; subtasks: Subtask[] },
  string
>('REORDER_SUBTASKS');

export const restoreTasks = asyncActions<number[], number[], string>('RESTORE_TASKS');

export const addPlannedTask = asyncActions<
  { taskId: number; courseName?: string; textColor?: string; title: string },
  ViewPlannedTask & { taskId: number; courseName?: string; textColor?: string; title: string },
  string
>('ADD_PLANNED_TASK');

export const openNewEstimateDialog = action<{
  taskId: number;
  reading: ReadingSourceInfoDto;
  newTimePerPage: number;
  courseId: number;
}>('OPEN_NEW_EST_DIALOG');

export const closeNewEstimateDialog = action<void>('CLOSE_NEW_EST_DIALOG');

// export const getCourses = createAsyncAction(
//   TaskGetCoursesActionTypes.TASK_GET_COURSES,
//   TaskGetCoursesActionTypes.TASK_GET_COURSES_SUCCESS,
//   TaskGetCoursesActionTypes.TASK_GET_COURSES_FAILURE
// )<void, NameDto[], string>();
//
// export const getGradeUnits = createAsyncAction(
//   TaskGetGradeUnitsActionTypes.TASK_GET_GRADE_UNITS,
//   TaskGetGradeUnitsActionTypes.TASK_GET_GRADE_UNITS_SUCCESS,
//   TaskGetGradeUnitsActionTypes.TASK_GET_GRADE_UNITS_FAILURE
// )<GetGradeUnitsRequest, NameDto[], string>();
//
// export const getReadingSources = createAsyncAction(
//   TaskGetReadingSourcesActionTypes.TASK_GET_READING_SOURCES,
//   TaskGetReadingSourcesActionTypes.TASK_GET_READING_SOURCES_SUCCESS,
//   TaskGetReadingSourcesActionTypes.TASK_GET_READING_SOURCES_FAILURE
// )<GetReadingSourcesRequest, ReadingSourceInfoDto[], string>();
//
// export const getClassAppearancesOnDate = createAsyncAction(
//   GetClassAppearancesOnDateActionTypes.GET_CLASS_APPEARANCES_ON_DATE,
//   GetClassAppearancesOnDateActionTypes.GET_CLASS_APPEARANCES_ON_DATE_SUCCESS,
//   GetClassAppearancesOnDateActionTypes.GET_CLASS_APPEARANCES_ON_DATE_FAILURE
// )<ClassAppearancesRequest, ClassAppearance[], string>();
