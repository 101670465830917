import { all, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { getType } from 'typesafe-actions';
import { apiCall } from '../../common/operations';
import * as semesterSelectors from '../../semester/selectors';
import { teacherApi } from '../../../api';
import { getErrorMessage } from '../../../api/network';
import { getOpenedCourseId } from '../selectors';

function* getTeachers() {
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(getOpenedCourseId);
  const { ok, ...response } = yield apiCall(teacherApi.getTeachers, semesterId, courseId);
  if (ok) {
    yield put(actions.getTeachers.success(response.data));
  } else {
    yield put(actions.getTeachers.failure(getErrorMessage(response)));
  }
}

function* createTeacher(action: ReturnType<typeof actions.createTeacher.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(getOpenedCourseId);
  const { ok, ...response } = yield apiCall(teacherApi.create, semesterId, courseId, action.payload);
  if (ok) {
    yield put(actions.createTeacher.success(response.data));
  } else {
    yield put(actions.createTeacher.failure(getErrorMessage(response)));
  }
}

function* updateTeacher(action: ReturnType<typeof actions.updateTeacher.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(getOpenedCourseId);
  const { id, ...request } = action.payload;
  const { ok, ...response } = yield apiCall(teacherApi.update, semesterId, courseId, id, request);
  if (ok) {
    yield put(actions.updateTeacher.success(response.data));
  } else {
    yield put(actions.updateTeacher.failure(getErrorMessage(response)));
  }
}

function* deleteTeacher(action: ReturnType<typeof actions.deleteTeacher.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const courseId = yield select(getOpenedCourseId);
  const { ok, ...response } = yield apiCall(teacherApi.deleteTeacher, semesterId, courseId, action.payload);
  if (ok) {
    yield put(actions.deleteTeacher.success(response.data.id));
  } else {
    yield put(actions.deleteTeacher.failure(getErrorMessage(response)));
  }
}

export default function*() {
  yield all([takeLatest(getType(actions.getTeachers.request), getTeachers)]);
  yield all([takeLatest(getType(actions.createTeacher.request), createTeacher)]);
  yield all([takeLatest(getType(actions.updateTeacher.request), updateTeacher)]);
  yield all([takeLatest(getType(actions.deleteTeacher.request), deleteTeacher)]);
}
