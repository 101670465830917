import React, { FC } from 'react';
import styled from 'styled-components';

import InputWrapper from '../InputWrapper';
import DatetimePicker from './DatetimePicker';
import InlineInputWrapper from '../InlineInputWrapper';

type Props = {
  value?: Date | string | null;
  onChange: (value: Date, timeClicked?: boolean) => void;
  minDate?: Date | string;
  maxDate?: Date | string;
  required?: boolean;
  name?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClose?: () => void;
  onOpen?: () => void;
  error?: string;
  bare?: boolean;
  touched?: boolean;
  label?: string;
  placeholder?: string;
  inline?: boolean;
  className?: string;
  disabled?: boolean;
  showTimeOnly?: boolean;
  dateFormatFn?: (showYear?: boolean) => string;
  timeIntervals?: number;
  disableTyping?: boolean;
  openToDate?: Date;
  clearable?: boolean;
  hideTime?: boolean;
  startTimesFromIndex?: number;
  autosizeFormat?: (showYear?: boolean) => string;
  firstTimeDiff?: number;
  popperContainerId?: string;
  noErrorMargin?: boolean;
};

const DatetimeInput: FC<Props> = ({
  name,
  value,
  onChange,
  onBlur,
  onClose,
  onOpen,
  minDate,
  maxDate,
  label,
  error,
  touched,
  required = true,
  placeholder,
  inline,
  disabled,
  className,
  showTimeOnly,
  dateFormatFn,
  timeIntervals,
  openToDate,
  disableTyping,
  clearable,
  hideTime,
  noErrorMargin,
  startTimesFromIndex,
  autosizeFormat,
  firstTimeDiff,
  bare,
  popperContainerId
}) => {
  const Wrapper = bare ? React.Fragment : inline ? InlineInputWrapper : InputWrapper;

  return (
    <Wrapper
      icon={!inline && (showTimeOnly ? 'schedule' : 'calendar_today')}
      iconOutline={showTimeOnly}
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      error={error}
      noErrorMargin={noErrorMargin}
      className={className}
      touched={touched}
      required={required}
      autosize={!!autosizeFormat}
    >
      <PickerWrapper>
        <DatetimePicker
          name={name}
          className={className}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onClose={onClose}
          onOpen={onOpen}
          openToDate={openToDate}
          placeholder={placeholder}
          minDate={minDate}
          maxDate={maxDate}
          required={required}
          disabled={disabled}
          inlineInput={inline}
          showTimeOnly={showTimeOnly}
          dateFormatFn={dateFormatFn}
          timeIntervals={timeIntervals}
          disableTyping={disableTyping}
          clearable={clearable}
          hideTime={hideTime}
          startTimesFromIndex={startTimesFromIndex}
          firstTimeDiff={firstTimeDiff}
          autosizeFormat={autosizeFormat}
          popperContainerId={popperContainerId}
        />
      </PickerWrapper>
    </Wrapper>
  );
};

export default DatetimeInput;

const PickerWrapper = styled.div`
  line-height: 17px;
  flex: 1;
  z-index: 1;
  > div {
    width: 100%;
  }
`;
