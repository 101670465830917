import React, { Component } from 'react';
import styled from 'styled-components';
import { Column, Row } from '../layoutUtils';
import { DialogActions } from '../ModalDialog';
import colors from '@utils/colors';
import { AccordionOption } from '../Accordion';
import { ComponentProps, Description } from './helpers';
import { RegularText } from '@utils/typography';
import t from '@i18n/t';
import MaterialIcon from '../icons/MaterialIcon';
import Button from '../buttons/Button';
import TextLink from '../TextLink';
import YouTube from 'react-youtube';
import ButtonWithLoader from '@components/common/buttons/ButtonWithLoader';

type Props = ComponentProps & {
  renderDescription?: () => React.ReactNode;
  FAQData?: AccordionOption[];
  completed: boolean;
  quiz?: boolean;
  submitting?: boolean;
  correct?: boolean;
  action?: () => void;
  onClose: () => void;
  quizCompleted?: boolean;
};

type State = {
  videoDuration: number;
};

const SECONDS_IN_MINUTE = 60;

class StepDialog extends Component<Props, State> {
  player: any = undefined;

  state: State = {
    videoDuration: 0
  };

  renderVideo = () => {
    const { video } = this.props.step;
    const { videoDuration } = this.state;
    let durationText;
    if (videoDuration < SECONDS_IN_MINUTE) {
      durationText = `${videoDuration} ${t.SECONDS}`;
    } else if (videoDuration < 2 * SECONDS_IN_MINUTE) {
      durationText = `1 ${t.MINUTE}`;
    } else {
      durationText = 'this short';
    }
    if (!video) return null;
    return (
      <>
        {this.props.quiz ? (
          <RegularText strong>{`${t.WATCH} ${durationText} ${t.VIDEO.toLowerCase()} ${t.BEFORE_ANSWERING}`}</RegularText>
        ) : (
          <RegularText strong>{`${t.WATCH} ${durationText} ${t.VIDEO.toLowerCase()}`}</RegularText>
        )}
        <VideoContainer>
          <YouTube
            videoId={video.id}
            id={video.id}
            onReady={event => {
              this.player = event.target;
              this.setState({ videoDuration: event.target.getDuration() });
            }}
          />
        </VideoContainer>
      </>
    );
  };

  renderDescription = () => {
    const { renderDescription, step, userProgress, completed } = this.props;
    return (
      <Description strong>
        {!renderDescription ? (
          <>
            <Row>
              {t.TO_COMPLETE_STEP}
              {completed && <span style={{ color: colors.positive }}>&nbsp;({t.COMPLETED})</span>}
            </Row>
            {Object.entries(step.substeps).map(([substep, text]: any) => {
              const substepCompleted = completed || (userProgress && userProgress.substeps.includes(substep));
              return (
                <SubstepRow key={substep}>
                  {substepCompleted ? (
                    <MaterialIcon color={colors.positive} name={'check'} />
                  ) : (
                    <MaterialIcon name={'arrow_right'} />
                  )}
                  {text}
                </SubstepRow>
              );
            })}
          </>
        ) : (
          renderDescription()
        )}
      </Description>
    );
  };

  render() {
    const { quiz, quizCompleted, correct, action, onClose, submitting } = this.props;

    return (
      <Container>
        <ContentWrapper>
          <Content>
            {this.renderVideo()}
            {this.renderDescription()}
          </Content>
        </ContentWrapper>
        <DialogActions position={'center'}>
          {/*<NeedHelp onClick={console.log}>{t.NEED_HELP}</NeedHelp>*/}
          {!quiz || quizCompleted ? (
            <Button filled onClick={onClose}>
              {t.GOT_IT}
            </Button>
          ) : (
            <ButtonWithLoader filled text={t.SUBMIT} onClick={action || onClose} loading={submitting} error={!correct} />
          )}
        </DialogActions>
      </Container>
    );
  }
}

export default StepDialog;

const Container = styled.div`
  min-height: 430px;
`;

const ContentWrapper = styled(Row)`
  display: flex;
  overflow: hidden;
  align-items: flex-start;
`;

const Content = styled(Column)<{ tabNum?: number }>`
  padding: 0 80px;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  transition: transform 0.2s ease;
  transform: translate(-${props => props.tabNum}00%, 0);
`;

const NeedHelp = styled(TextLink)`
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
`;

const VideoContainer = styled.div`
  position: relative;
  margin: 10px 0 15px;
  width: 517px;
  padding-bottom: 50.25%;
  background: ${props => props.theme.borderColor};
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 517px;
    height: 100%;
  }
`;

const SubstepRow = styled(Row)`
  white-space: pre-wrap;
  align-items: flex-start;
`;
