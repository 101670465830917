import React, { PureComponent } from 'react';
import TippyTooltip from '../tooltip/TippyTooltip';
import ColorPicker from './ColorPicker';
import styled from 'styled-components';
import { Row } from '../layoutUtils';

type Props = {
  target: any;
  additionalContent?: any;
  selectedColor?: any;
  defaultColor?: string;
  onSave?: any;
  visible?: boolean;
  onClickOutside?: any;
  controlled?: boolean;
  onChange?: (color?: string) => any;
  themeColorPicker?: boolean;
  defaultSelected?: boolean;
  onShow?: any;
  onHidden?: any;
  tippyAppendTo?: 'parent' | Element | ((ref: Element) => Element);
};

type State = { color?: string };

class ColorPickerTippy extends PureComponent<Props, State> {
  readonly state: State = { color: undefined };

  changeColor = color => {
    if (this.props.onChange) {
      this.props.onChange(color);
    }
    this.setState({ color });
  };

  handleClickSave = (color, isDefault) => {
    if (this.props.onSave) {
      this.props.onSave(color, isDefault);
    }
    if (color) {
      this.setState({ color: undefined });
    }
  };

  handleClickOutside = () => {
    if (this.state.color) {
      this.props.onSave?.(this.state.color);
      this.setState({ color: undefined });
    }
  };

  render() {
    const {
      target,
      selectedColor,
      visible,
      controlled,
      additionalContent,
      themeColorPicker,
      defaultColor,
      defaultSelected,
      onShow,
      onHidden
    } = this.props;
    const { color } = this.state;
    return (
      <>
        <Container themeColorPicker={themeColorPicker}>
          {target && target(color)}
          <TippyTooltip
            onShow={onShow}
            onHidden={onHidden}
            target={<Target />}
            content={
              <ColorPicker
                onChange={this.changeColor}
                selectedColor={selectedColor}
                onSave={this.handleClickSave}
                defaultColor={defaultColor}
                defaultSelected={defaultSelected}
              />
            }
            appendTo={this.props.tippyAppendTo}
            interactive
            lightTheme
            arrow={false}
            colorPicker
            position={'auto-end'}
            visible={visible}
            trigger={controlled ? undefined : 'click'}
            controlled={controlled}
            onClickOutside={this.handleClickOutside}
            lazy
          />
        </Container>
        {additionalContent && additionalContent(color)}
      </>
    );
  }
}

export default ColorPickerTippy;
const Target = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Container = styled(Row)<{ themeColorPicker?: boolean }>`
  ${props => !props.themeColorPicker && 'position: relative'};
  &:hover {
    ${props =>
      props.themeColorPicker
        ? 'opacity: 0.75'
        : `color: ${({ theme }) => theme.textStrongColor}; background-color: ${({ theme }) => theme.backgroundLight};`}
`;
