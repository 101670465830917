import {
  CalendarEvent,
  CalendarEvents,
  DatesContainingTasks,
  DatesContainingTasksItem,
  ManageTask,
  TaskDto,
  TaskInfoDto,
  TasksByDate,
  TasksByDay,
  WorkloadTask
} from 'shovel-lib/types';
import { createCommuteAfter, createCommuteBefore } from './calendar/calendarEventConverters';
import { DATE_FORMAT, formatStringDateAs } from 'shovel-lib/utils/timeUtils';
import { lightTheme } from '@assets/themes';

type TasksByDateType = TasksByDate | TasksByDay | DatesContainingTasks;
type TasksByDateItemType = (TaskDto | TaskInfoDto | DatesContainingTasksItem) & { dueDate: Date | string };

//TODO: revisit again and refactor
export const updateTasksByDate = (tasksByDate: TasksByDateType, taskId: number, data: any, info?: any) => {
  let date;
  let tasks;
  const changedDueDate = Object.keys(data).find(key => key === 'dueDate');
  // used when updating due date
  let taskWithChangedDate: TasksByDateItemType;
  for (const key in tasksByDate) {
    // @ts-ignore
    const taskIndex = tasksByDate[key].map(t => t.taskId).indexOf(taskId);
    if (taskIndex !== -1) {
      date = key;
      tasks = [...tasksByDate[key]];
      if (changedDueDate) {
        taskWithChangedDate = tasks[taskIndex];
        taskWithChangedDate.dueDate = data.dueDate;
        tasks.splice(taskIndex, 1);
      } else {
        tasks[taskIndex] = {
          ...tasks[taskIndex],
          ...data
        };
      }
      break;
    }
  }

  if (changedDueDate) {
    const changedTaskDate = formatStringDateAs(data.dueDate, DATE_FORMAT);
    //if due date is setup for previously noDueDate task
    if (!tasks || !date) {
      const result = { ...tasksByDate };
      const { colorHex, courseId, dueDate, taskId, title, type } = info;
      if (!result[changedTaskDate]) {
        result[changedTaskDate] = [];
      }
      // @ts-ignore
      result[changedTaskDate].push({ colorHex, courseId, dueDate, taskId, title, type, courseCorrelationId: null });
      return result;
    }

    const result = {
      ...tasksByDate,
      [date]: tasks
    };

    if (result[date].length === 0) {
      delete result[date];
    }

    if (!result[changedTaskDate]) {
      result[changedTaskDate] = [];
    }
    result[changedTaskDate].push(taskWithChangedDate!);

    return result;
  }

  if (!tasks || !date) {
    return tasksByDate;
  }

  return {
    ...tasksByDate,
    [date]: tasks
  };
};

export const applyOnTasksByDate = (tasksByDate: TasksByDateType, func: any) =>
  Object.entries(tasksByDate)
    .map(([day, tasks]) => [day, tasks.map(func)])
    .reduce((obj, [day, tasks]) => ({ ...obj, [day as string]: tasks }), {});

export const removeFromTasksByDate = (tasksByDate: TasksByDateType, taskId: number) =>
  Object.entries(tasksByDate)
    .map(([day, tasks]) => [day, tasks.filter((task: TasksByDateItemType) => task.taskId !== taskId)])
    .reduce((obj, [day, tasks]) => (tasks.length > 0 ? { ...obj, [day.toString()]: tasks } : obj), {});

export const createCommuteEvents = (events: CalendarEvent[], settings: any, theme: any = lightTheme) => [
  ...events.filter((e: CalendarEvent) => !e.allDay && e.commuteBefore).map(e => createCommuteBefore(e, settings, theme)),
  ...events.filter((e: CalendarEvent) => !e.allDay && e.commuteAfter).map(e => createCommuteAfter(e, settings, theme))
];

export const reduceCalendarEventsToObject = (array: CalendarEvent[]) =>
  array.reduce((acc: CalendarEvents, e: CalendarEvent) => ({ ...acc, [`${e.id}`]: e }), {});

export const reduceCalendarEventsToListObject = (array: CalendarEvent[]) =>
  array.reduce((acc: { [key: string]: CalendarEvent[] }, e: CalendarEvent) => {
    if (!e.id) return acc;
    return { ...acc, [e.id]: acc[e.id] ? [...acc[e.id], e] : [e] };
  }, {});

export const sortByIdAsc = (array: any[]) => array.sort((a, b) => (a.id > b.id ? 1 : -1));

export const reduceArrayToObjectById = (array: any[]) => reduceArrayToObject(array);

export const reduceArrayToObject = (array: any[], key: string = 'id') =>
  array.reduce((acc, e) => ({ ...acc, [e[key]]: e }), {});

export const sortByOrdinalNumberAsc = (array: any[]) => array.sort((a, b) => (a.ordinalNumber > b.ordinalNumber ? 1 : -1));

export const sortByNullableDueDateAndTitle = (a, b) => {
  const diff = new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
  if ((!a.dueDate && !b.dueDate) || diff === 0) return a.title.localeCompare(b.title);
  if (!a.dueDate) return -1;
  if (!b.dueDate) return 1;
  return diff;
};
