export const AUTH_ROUTE_PREFIX = '/-';
export const LOGIN = `${AUTH_ROUTE_PREFIX}/login`;
export const FORGOT_PASSWORD = `${AUTH_ROUTE_PREFIX}/forgot-password`;
export const RESET_PASSWORD = `${AUTH_ROUTE_PREFIX}/reset-password/:resetPasswordCode`;
export const SET_PASSWORD = `${AUTH_ROUTE_PREFIX}/set-password/:resetPasswordCode`;
export const LINK_EXPIRED = `${AUTH_ROUTE_PREFIX}/link-expired`;
export const VERIFY_EMAIL = `/verify-email`;

export const SIGN_UP = `${AUTH_ROUTE_PREFIX}/signup`;
export const SIGN_UP_ORGANIZATION = `${SIGN_UP}/:organizationRegistrationLink`;
export const SETTINGS = '/settings';
export const TERM_SETTINGS = `${SETTINGS}/terms`;
export const PROFILE_SETTINGS = `${SETTINGS}/profile`;
export const PERSONALIZATION_SETTINGS = `${SETTINGS}/personalization`;
export const PRICING_SETTINGS = `${SETTINGS}/subscription`;
export const RESEND_ACTIVATION_EMAIL = `${AUTH_ROUTE_PREFIX}/resend-activation-email`;
export const PREFERENCES = `${SETTINGS}/my-preferences`;
export const NOTIFICATIONS = '/notifications';
export const NOTIFICATION_SETTINGS = `${SETTINGS}/notifications`;
export const BADGES = `/badges`;
export const DEMO = `/demo`;

export const CALENDAR = '/:semesterId/home';
export const TASKS = '/:semesterId/tasks';
export const CHARTS = '/:semesterId/charts';
export const ANALYTICS = `${CHARTS}/analytics`;
export const CUSHION = `${CHARTS}/cushion`;
export const TIMELINE = `${CHARTS}/timeline`;

export const TERM = '/:semesterId/term';
export const AWAKE_TIME = '/:semesterId/awake-time';
export const STUDY_TIME = '/:semesterId/study-time';

export const CONGRATS_MOBILE = '/congrats-mobile';
export const GPA_CALCULATOR = '/gpa-calculator';
export const BROWSER_NOT_SUPPORTED = `/not-supported`;
export const MAINTENANCE = `/maintenance`;
export const SERVER_ERROR = `/server-error`;

export const EXPIRED_SUBSCRIPTION = `/expired-subscription`;

export const MOBILE_ONLY_ROUTE_PREFIX = '/mobile';
export const MOBILE_CALENDAR = `${MOBILE_ONLY_ROUTE_PREFIX}/:semesterId/home`;
export const MOBILE_LOGIN = `/mobile-login`;
export const MOBILE_FORGOT_PASSWORD = `${MOBILE_ONLY_ROUTE_PREFIX}${FORGOT_PASSWORD}`;

export const EDITOR = '/editor';
export const SIDEBAR = '/sidebar';

export const GET_SHOVEL = 'https://howtostudyincollege.com/get-shovel/';
export const SHOVEL_ANDROID =
  'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.howtostudyincollege.shovel';
export const SHOVEL_IOS = 'https://apps.apple.com/us/app/shovel-study-planner/id1467742357';

export const authSections = [LOGIN, FORGOT_PASSWORD, `${AUTH_ROUTE_PREFIX}/reset-password/`, LINK_EXPIRED, SIGN_UP];

export const PARENT_PAYMENT = '/parent-payment';
export const PARENT_LINK = `${PARENT_PAYMENT}/:userId`;
export const PAYMENT_SUCCESS = `/payment-success`;

export const MOBILE_SIGNUP_INSTRUCTIONS = '/mobile-signup';
