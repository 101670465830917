import { ApiResponse } from 'apisauce';
import { network } from './network';
import { BadgesInfo } from '../state/settings/types';
import { SubscriptionInfo } from 'shovel-lib/types';

const basePath = `/settings`;

export interface BadgesApi {
  getUserBadges: () => Promise<ApiResponse<BadgesInfo | null>>;
  saveUserBadges: (badges: BadgesInfo) => Promise<ApiResponse<BadgesInfo>>;
  addFreeTrialDay: () => Promise<ApiResponse<SubscriptionInfo>>;
}

const getUserBadges = () => network.get<BadgesInfo>(`${basePath}/badges`);

const saveUserBadges = (badges: BadgesInfo) => network.post<BadgesInfo>(`${basePath}/badges`, badges);

const addFreeTrialDay = () => network.post<SubscriptionInfo>('/membership/add-trial-day');

export const badgesApi: BadgesApi = {
  getUserBadges,
  saveUserBadges,
  addFreeTrialDay
};
