import React, { FC } from 'react';

import {
  Error,
  INLINE_INPUT_BOTTOM_MARGIN,
  INLINE_INPUT_HORIZONTAL_PADDING,
  INLINE_INPUT_VERTICAL_PADDING
} from './Input.styles';
import { RegularText } from '../../../utils/typography';
import colors from '../../../utils/colors';
import styled from 'styled-components';
import { Row } from '../layoutUtils';
import { AlignType } from '../../../state/common/utils';
import MaterialIcon from '../icons/MaterialIcon';

type Props = {
  label?: string;
  icon?: any;
  iconOutline?: boolean;
  error?: string;
  className?: string;
  autosize?: boolean;
  align?: AlignType;
  searchInput?: boolean;
  disabled?: boolean;
  placeholder?: string;
  touched?: boolean;
  tooltip?: string;
  required?: boolean;
  children?: any;
  value?: any;
  iconOnClick?: any;
  title?: string;
  noErrorMargin?: boolean;
};

const InlineInputWrapper = React.forwardRef(
  (
    {
      label = '',
      title,
      icon,
      iconOutline,
      error,
      children,
      autosize,
      align,
      className,
      disabled,
      touched,
      required,
      value,
      noErrorMargin,
      searchInput = false
    }: Props,
    ref
  ) => {
    return (
      <Container
        // @ts-ignore
        ref={ref}
        className={className}
        error={error}
        autosize={autosize}
        withLabel={!!label}
        disabled={disabled}
        icon={!!icon}
        title={title}
        noErrorMargin={noErrorMargin}
      >
        {label && <Label disabled={disabled}>{`${label}:${required ? ' *' : ''}`}</Label>}
        <StyledInput
          searchInput={searchInput}
          disabled={disabled}
          autosize={autosize}
          error={!!error && touched}
          icon={!!icon}
        >
          {searchInput && icon && <InputIcon searchInput icon={icon} disabled={disabled} iconOutline={iconOutline} />}
          {children}
          {error && touched && <Error inline>{error}</Error>}
        </StyledInput>
        {!searchInput && icon && <InputIcon icon={icon} disabled={disabled} iconOutline={iconOutline} />}
      </Container>
    );
  }
);

const InputIcon: FC<{
  icon: string;
  searchInput?: boolean;
  disabled?: boolean;
  onClick?: any;
  size?: any;
  iconOutline?: boolean;
}> = ({ icon, searchInput, disabled, onClick, size, iconOutline }) => (
  <Icon searchInput={searchInput} name={icon} disabled={disabled} onClick={onClick} size={size} outline={iconOutline} />
);

export default InlineInputWrapper;

const Icon = styled(MaterialIcon)<{ searchInput?: boolean; disabled?: boolean }>`
  transition: color 0.2s ease;
  margin-left: 2px;
  color: ${props => (props.disabled ? props.theme.disabledTextColor : props.theme.textColor)};
  ${props => props.searchInput && `position: absolute; left: ${INLINE_INPUT_HORIZONTAL_PADDING / 2}px;`};
`;

const StyledInput = styled(Row)<{
  searchInput: boolean;
  disabled?: boolean;
  autosize?: boolean;
  error?: boolean;
  icon: boolean;
}>`
  border-radius: 5px;
  transition: all 0.2s ease !important;
  align-items: center;
  position: relative;
  ${props => props.searchInput && 'flex: 1'};

  input {
    border-radius: 5px;
    padding: ${INLINE_INPUT_VERTICAL_PADDING}px ${INLINE_INPUT_HORIZONTAL_PADDING}px;
    ${props =>
      props.icon &&
      props.searchInput &&
      `
    padding-left: ${INLINE_INPUT_VERTICAL_PADDING + 20}px;
    padding-right: ${INLINE_INPUT_VERTICAL_PADDING + 15}px;
    `}
  }

  ${props =>
    !props.disabled &&
    !props.error &&
    `
    &:hover, &:active, &:focus, &:focus-within {
      &, .textareaPreview { background-color: ${props.theme.borderColor}; }
    }`}

  ${props =>
    !props.disabled && `background-color: ${props.error ? `${colors.lightNegative}80` : props.theme.backgroundDark}`};
`;

const Container = styled(Row)<{
  autosize?: boolean;
  withLabel?: boolean;
  error?: string;
  disabled?: boolean;
  icon?: boolean;
  noErrorMargin?: boolean;
}>`
  position: relative;
  align-items: center;
  cursor: default;
  ${props => props.disabled && `& ${Label} { color: ${props.theme.disabledTextColor}}`};
  ${props => !props.noErrorMargin && `margin-bottom: ${INLINE_INPUT_BOTTOM_MARGIN}`};
  .size-placeholder {
    line-height: 1.3;
    padding: ${INLINE_INPUT_VERTICAL_PADDING}px ${INLINE_INPUT_HORIZONTAL_PADDING}px; // span for autosize datepicker width
  }

  &:focus-within {
    & ${Icon} {
      color: ${colors.primaryPurple};
    }
  }
`;

const Label = styled(RegularText)<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.disabledTextColor : theme.textColor)};
  margin-right: 6px;
`;
