import * as React from 'react';
import styled, { css } from 'styled-components';
import { LargeText, MediumText, RegularText, SmallText } from './typography';
import MaterialIcon from '../components/common/icons/MaterialIcon';
import colors from '@utils/colors';

export type TooltipPosition =
  | 'top left'
  | 'top center'
  | 'top right'
  | 'right top'
  | 'right center'
  | 'right bottom'
  | 'bottom left'
  | 'bottom center'
  | 'bottom right'
  | 'left top'
  | 'left center'
  | 'left bottom'
  | 'center center';

export enum PopoverType {
  INFO = 'info',
  WARNING = 'warning',
  TOOLTIP = 'tooltip'
}

export const LARGER_MODAL_WIDTH = 970;
//TODO: by desing is 720px but it is too big for smaller screen
export const LARGER_MODAL_MIN_HEIGHT = 600;
export const LARGE_MODAL_WIDTH = 800;
export const LARGE_MODAL_MIN_HEIGHT = 550;
export const MEDIUM_MODAL_WIDTH = 600;
export const MEDIUM_MODAL_MIN_HEIGHT = 450;
export const SMALL_MODAL_WIDTH = 400;
export const SMALL_MODAL_MIN_HEIGHT = 330;
export const CONFIRMATION_MODAL_MIN_HEIGHT = 200;
// zIndex for planned task dialog is 100
export const MANAGE_DIALOG_Z_INDEX = 101;
export const NEW_ESTIMATE_DIALOG_Z_INDEX = MANAGE_DIALOG_Z_INDEX + 1;
export const CREATE_PLAN_TASK_MODAL_WIDTH = 450;

export const commonArrowCSS = css`
  background-color: ${({ theme }) => theme.background} !important;
  border: 0 !important;
  box-shadow: ${({ theme }) => theme.boxShadow} 1px 1px 1px !important;
`;

export const popupContentStyle: React.CSSProperties = {
  borderRadius: '5px',
  border: 0,
  padding: 0,
  boxSizing: 'border-box'
};

export const commonPopupCSS = css`
  background-color: ${({ theme }) => theme.background} !important;
  box-shadow: 0px 6px 25px ${({ theme }) => theme.boxShadow} !important;
`;

const modalStyle: React.CSSProperties = {
  ...popupContentStyle,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '90%'
};

export const createRepeatTaskOverlayStyle: React.CSSProperties = {
  ...modalStyle,
  width: LARGER_MODAL_WIDTH,
  minWidth: LARGER_MODAL_WIDTH,
  padding: '0 20px 20px 10px',
  height: LARGER_MODAL_MIN_HEIGHT
};

export const largeModalStyle: React.CSSProperties = {
  ...modalStyle,
  width: LARGE_MODAL_WIDTH,
  minWidth: LARGE_MODAL_WIDTH,
  padding: 30,
  paddingTop: 0,
  minHeight: LARGE_MODAL_MIN_HEIGHT
};

export const mediumModalStyle: React.CSSProperties = {
  ...modalStyle,
  width: MEDIUM_MODAL_WIDTH,
  minWidth: MEDIUM_MODAL_WIDTH,
  padding: 30,
  paddingTop: 0,
  minHeight: MEDIUM_MODAL_MIN_HEIGHT
};

export const smallModalStyle: React.CSSProperties = {
  ...modalStyle,
  width: SMALL_MODAL_WIDTH,
  minWidth: SMALL_MODAL_WIDTH,
  padding: 20,
  paddingTop: 0,
  minHeight: SMALL_MODAL_MIN_HEIGHT
};

export const confirmationModalStyle: React.CSSProperties = {
  ...modalStyle,
  width: SMALL_MODAL_WIDTH,
  padding: 20,
  paddingTop: 30,
  minHeight: CONFIRMATION_MODAL_MIN_HEIGHT
};

export const plannedTasksStyle: React.CSSProperties = {
  ...popupContentStyle,
  width: SMALL_MODAL_WIDTH,
  padding: '16px 30px',
  maxHeight: 300,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column'
};

export const filterShadow = css`
  box-shadow: 0px 2px 2px ${({ theme }) => theme.boxShadow}, 0px 0px 2px ${({ theme }) => theme.boxShadow};
`;

export const addTimeEntryModal: React.CSSProperties = {
  ...popupContentStyle,
  width: 200,
  padding: '10px 12px'
};

export const filterColumnDropdownStyle: React.CSSProperties = {
  ...popupContentStyle,
  width: 130,
  margin: 0,
  padding: 0,
  overflow: 'hidden',
  zIndex: 110
};

export const filterTableDropdownStyle: React.CSSProperties = {
  ...popupContentStyle,
  width: 'fit-content',
  paddingTop: 4,
  paddingBottom: 8,
  overflow: 'hidden',
  zIndex: 110
};

export const notificationsDropdownStyle: React.CSSProperties = {
  ...popupContentStyle,
  // width: 220,
  display: 'flex',
  backgroundColor: 'transparent',
  width: 300,
  marginTop: 10,
  marginLeft: 10,
  overflow: 'hidden',
  zIndex: 120,
  minHeight: 300,
  maxHeight: 540
};

export const termsPopoverIconStyle = {
  position: 'absolute',
  right: '0',
  top: '7px'
} as React.CSSProperties;

export const helpUsModalStyle: React.CSSProperties = {
  ...modalStyle,
  width: MEDIUM_MODAL_WIDTH,
  padding: 30,
  paddingTop: 40,
  minHeight: MEDIUM_MODAL_MIN_HEIGHT
};

export const menuPopupStyle = {
  ...popupContentStyle,
  width: 140,
  marginLeft: 10,
  // display: 'flex',
  // backgroundColor: 'transparent',
  // width: 300,
  // marginTop: 10,
  // marginLeft: 10,
  overflow: 'hidden',
  zIndex: 100
  // minHeight: 300,
  // maxHeight: 540
};

export const contextMenuPopupStyle = {
  ...popupContentStyle,
  marginTop: 7,
  marginLeft: 5
  // display: 'flex',
  // backgroundColor: 'transparent',
  // width: 300,
  // marginTop: 10,
  // marginLeft: 10,
  // overflow: 'hidden'
  // zIndex: 1000,
  // minHeight: 300,
  // maxHeight: 540
};

export const filterTimelineDropdownStyle: React.CSSProperties = {
  ...popupContentStyle,
  width: 146,
  padding: '5px 0px',
  overflow: 'hidden',
  zIndex: 10
};

export const moreOptionDropdown: React.CSSProperties = {
  ...popupContentStyle,
  width: 180,
  margin: 0,
  padding: 0,
  overflow: 'hidden',
  zIndex: 999
};

export const fullScreenModalStyle: React.CSSProperties = {
  ...modalStyle,
  width: 'calc( 100% - 60px)',
  minWidth: 'calc( 100% - 60px)',
  minHeight: 'calc( 100% - 60px)',
  margin: 30,
  position: 'relative',
  boxSizing: 'border-box'
};

export const getTextComponent = (size: number) => {
  switch (size) {
    case 12:
      return MediumText;
    case 11:
      return SmallText;
    case 16:
      return LargeText;
    default:
      return RegularText;
  }
};

export const dropdownIconColor = (theme: any, open: boolean, disabled?: boolean) => {
  if (disabled) {
    return theme.captures;
  } else if (open) {
    return colors.primaryPurple;
  } else {
    return theme.textColor;
  }
};

export const DropDownArrow = styled(MaterialIcon)<{ open: boolean; color?: string; disabled?: boolean }>`
  color: ${props =>
    !props.open || !props.color ? dropdownIconColor(props.theme, props.open, props.disabled) : props.color};
  transform: rotate(${props => (props.open ? 180 : 0)}deg);
  transition: transform 0.2s ease;
`;

export const dropDownInputStyle: React.CSSProperties = {
  ...popupContentStyle,
  width: 210,
  padding: '5px 0px',
  overflow: 'hidden',
  zIndex: 10
};

export const actionButtonPopup: React.CSSProperties = {
  ...popupContentStyle,
  width: 216,
  padding: '10px 12px'
};

export const actionButtonEstimatePopup: React.CSSProperties = {
  ...popupContentStyle,
  width: 260,
  padding: '10px 12px'
};

export const createTaskOverlayStyle: React.CSSProperties = {
  ...modalStyle,
  width: LARGE_MODAL_WIDTH,
  minWidth: LARGE_MODAL_WIDTH,
  padding: '0 20px 20px 10px',
  height: LARGER_MODAL_MIN_HEIGHT
};

export const RepeatPatternDialogStyle: React.CSSProperties = {
  ...modalStyle,
  width: 520,
  padding: 0,
  height: 375
};

export const newEstimateModalStyle: React.CSSProperties = {
  ...modalStyle,
  width: SMALL_MODAL_WIDTH,
  padding: '20px 45px',
  minHeight: CONFIRMATION_MODAL_MIN_HEIGHT,
  zIndex: NEW_ESTIMATE_DIALOG_Z_INDEX
};
