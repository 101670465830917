import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import styled, { withTheme } from 'styled-components';

import { MonthFilterT, resetMonthFilterState } from '../../state/settings/types';
import CheckBox from '../common/CheckBox';
import RadioButton from '../common/radioButton/RadioButton';
import t from '../../i18n/t';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../state/rootReducer';
import { connect } from 'react-redux';
import { Column, FlexRow, Row } from '../common/layoutUtils';
import { getMonthFilterState } from '../../state/calendar/selectors';
import MaterialIcon from '../common/icons/MaterialIcon';
import { filterColumnDropdownStyle } from '../../utils/popupUtils';
import { settingsActions } from '../../state/settings';
import colors from '../../utils/colors';
import {
  Arrow,
  ClearAllButton,
  ClearIcon,
  CoursesSection,
  Filter,
  FilterWrapper,
  IconWrapper,
  PileFilterToggler
} from '../pile/components/PileFilter';
import storage from '@utils/storage';
import TippyTooltip from '../common/tooltip/TippyTooltip';

export const isMonthFilterActive = ({
  tasks,
  courseCorrelationIds,
  customEvents,
  activityCorrelationIds,
  googleEvents
}: MonthFilterT) =>
  tasks ||
  customEvents ||
  googleEvents ||
  (courseCorrelationIds && courseCorrelationIds.length > 0) ||
  (activityCorrelationIds && activityCorrelationIds.length > 0);

type PropsFromState = {
  filter: MonthFilterT;
  activities: any[];
  courses: any[];
  theme: any;
};

type PropsFromDispatch = {
  applyFilter: typeof settingsActions.applyMonthFilter;
};

type Props = PropsFromState & PropsFromDispatch;

type State = { activitiesCollapsed: boolean; coursesCollapsed: boolean };

class MonthViewFilter extends Component<Props, State> {
  state: State = { activitiesCollapsed: true, coursesCollapsed: true };

  resetFilter = () => {
    this.onChange(resetMonthFilterState);
  };

  onChange = (filter: MonthFilterT) => {
    const { applyFilter } = this.props;
    applyFilter(filter);
    storage.saveMonthFilter(filter);
  };

  onCustomEventToggle = () => this.onChange({ ...this.props.filter, customEvents: !this.props.filter.customEvents });

  onGoogleEventToggle = () => this.onChange({ ...this.props.filter, googleEvents: !this.props.filter.googleEvents });

  onCoursesToggle = () => {
    const { filter, courses } = this.props;
    if (!courses) return;

    let courseCorrelationIds;
    if (filter.courseCorrelationIds.length > 0) {
      courseCorrelationIds = [];
    } else {
      courseCorrelationIds = courses.map(a => a.correlationId);
      this.toggleCoursesCollapsed(undefined, false);
    }
    this.onChange({ ...filter, courseCorrelationIds });
  };

  onCourseToggle = (correlationId: string) => {
    const courseCorrelationIds = this.handleArrayItemToggle(this.props.filter.courseCorrelationIds.slice(0), correlationId);
    this.onChange({ ...this.props.filter, courseCorrelationIds });
  };

  onActivitiesToggle = () => {
    const { filter, activities } = this.props;
    if (!activities) return;

    let activityCorrelationIds;
    if (filter.activityCorrelationIds.length > 0) {
      activityCorrelationIds = [];
    } else {
      activityCorrelationIds = activities.map(a => a.correlationId);
      this.toggleActivitiesCollapsed(undefined, false);
    }
    this.onChange({ ...filter, activityCorrelationIds });
  };

  onActivityToggle = (correlationId: string) => {
    const activityCorrelationIds = this.handleArrayItemToggle(
      this.props.filter.activityCorrelationIds.slice(0),
      correlationId
    );
    this.onChange({ ...this.props.filter, activityCorrelationIds });
  };

  handleArrayItemToggle = (array: any[], item: any) => {
    const newArray = array.slice(0);
    const index = newArray.indexOf(item);
    if (index !== -1) {
      newArray.splice(index, 1);
    } else {
      newArray.push(item);
    }
    return newArray;
  };

  isItemInArray = (array: any[], item: any) => {
    return array.indexOf(item) !== -1;
  };

  static getTaskStyle = (checked: boolean) => {
    return { opacity: checked ? 1 : 0.4 };
  };

  toggleActivitiesCollapsed = (e: any, value?: boolean) =>
    this.setState({ activitiesCollapsed: value !== undefined ? value : !this.state.activitiesCollapsed });

  toggleCoursesCollapsed = (e: any, value?: boolean) =>
    this.setState({ coursesCollapsed: value !== undefined ? value : !this.state.coursesCollapsed });

  render() {
    const {
      activities,
      courses,
      filter: { tasks, customEvents, activityCorrelationIds, courseCorrelationIds, exams, googleEvents }
    } = this.props;
    const { activitiesCollapsed, coursesCollapsed } = this.state;

    const clearDisabled = !isMonthFilterActive(this.props.filter);

    return (
      <FilterWrapper>
        <Popup
          className={'pile-filter'}
          trigger={open => {
            const isActive = isMonthFilterActive(this.props.filter);

            return (
              <PileFilterToggler style={{ cursor: 'pointer' }}>
                <Filter>
                  <TippyTooltip
                    target={
                      <Column>
                        <MaterialIcon name={'filter_alt'} color={colors.white} size={16} />
                      </Column>
                    }
                    content={t.FILTER_CALENDAR}
                  />
                  {isActive && (
                    <TippyTooltip
                      target={
                        <IconWrapper>
                          <ClearIcon
                            name={'clear'}
                            color={colors.white}
                            size={13}
                            onClick={e => {
                              this.resetFilter();
                              e.stopPropagation();
                            }}
                          />
                        </IconWrapper>
                      }
                      content={t.CLEAR_ALL_FILTERS}
                    />
                  )}
                </Filter>
              </PileFilterToggler>
            );
          }}
          closeOnDocumentClick
          onClose={() => this.setState({ activitiesCollapsed: true, coursesCollapsed: true })}
          on={'click'}
          position={'bottom left'}
          contentStyle={{ ...filterColumnDropdownStyle, width: '180px' }}
        >
          <Container>
            <Heading>
              <ClearAllButton disabled={clearDisabled} onClick={!clearDisabled ? this.resetFilter : undefined}>
                <Row>
                  {t.CLEAR_ALL}
                  <MaterialIcon
                    name={'clear'}
                    disabled={clearDisabled}
                    color={colors.negative}
                    size={16}
                    style={{ marginLeft: 2 }}
                  />
                </Row>
              </ClearAllButton>
            </Heading>
            <Section>
              <Item>
                <CheckBox
                  label={t.TASKS}
                  checked={tasks}
                  onChange={() => this.onChange({ ...this.props.filter, tasks: !tasks, exams: false })}
                />
              </Item>
              <RadioItem>
                <RadioButton
                  disabled={!tasks}
                  label={t.ALL_DEADLINES}
                  checked={tasks && !exams}
                  onChange={() => this.onChange({ ...this.props.filter, exams: false })}
                />
              </RadioItem>
              <RadioItem>
                <RadioButton
                  disabled={!tasks}
                  label={t.EXAMS}
                  checked={tasks && exams}
                  onChange={() => this.onChange({ ...this.props.filter, exams: true })}
                />
              </RadioItem>
            </Section>
            <Section>
              <Item>
                <CheckBox label={t.CUSTOM_EVENTS} checked={customEvents} onChange={this.onCustomEventToggle} />
              </Item>
              <Item>
                <CheckBox label={t.GOOGLE_EVENTS} checked={googleEvents} onChange={this.onGoogleEventToggle} />
              </Item>
            </Section>
            <Section>
              <SectionHeading>
                <Row style={{ justifyContent: 'space-between', marginBottom: 8 }}>
                  <CheckBox
                    label={`${t.COURSE.toUpperCase()} ${t.EVENTS.toUpperCase()}`}
                    checked={courseCorrelationIds.length === courses?.length}
                    indeterminate={courseCorrelationIds.length > 0}
                    onChange={this.onCoursesToggle}
                  />
                  <Arrow
                    name={'keyboard_arrow_down'}
                    collapsed={coursesCollapsed}
                    onClick={this.toggleCoursesCollapsed}
                    style={{ marginLeft: 5 }}
                  />
                </Row>
              </SectionHeading>
              <CoursesSection collapsed={coursesCollapsed} style={{ marginLeft: 10 }}>
                {courses!.map(c => (
                  <Item key={c.id} style={{ marginTop: 0 }}>
                    <CheckBox
                      label={c.name}
                      checked={this.isItemInArray(courseCorrelationIds!, c.correlationId)}
                      onChange={() => this.onCourseToggle(c.correlationId)}
                    />
                  </Item>
                ))}
              </CoursesSection>
            </Section>
            <Section>
              <SectionHeading>
                <Row style={{ justifyContent: 'space-between' }}>
                  <CheckBox
                    label={`${t.ACTIVITY.toUpperCase()} ${t.EVENTS.toUpperCase()}`}
                    checked={activityCorrelationIds.length === activities?.length}
                    indeterminate={activityCorrelationIds.length > 0}
                    onChange={this.onActivitiesToggle}
                  />
                  <Arrow
                    name={'keyboard_arrow_down'}
                    collapsed={activitiesCollapsed}
                    onClick={this.toggleActivitiesCollapsed}
                    style={{ marginLeft: 5 }}
                  />
                </Row>
              </SectionHeading>
              <CoursesSection collapsed={activitiesCollapsed}>
                {activities!.map(a => (
                  <Item key={a.id}>
                    <CheckBox
                      label={a.name}
                      checked={this.isItemInArray(activityCorrelationIds!, a.correlationId)}
                      onChange={() => this.onActivityToggle(a.correlationId)}
                    />
                  </Item>
                ))}
              </CoursesSection>
            </Section>
          </Container>
        </Popup>
      </FilterWrapper>
    );
  }
}

const mapStateToProps = (state: RootState) => getMonthFilterState(state);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ applyFilter: settingsActions.applyMonthFilter }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(MonthViewFilter));

const Section = styled.div`
  padding: 0 10px;
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  overflow: hidden;
  border-radius: 4px;
  padding: 9px 0;

  ${Section}:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  }
`;

const Heading = styled(Row)`
  justify-content: flex-end;
  padding-right: 10px;
`;

const SectionHeading = styled.div`
  padding-top: 8px;
`;

const Item = styled(FlexRow)`
  margin: 8px 0;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 12px;
  }
`;

const RadioItem = styled(Item)`
  margin-left: 10px;
  height: 18px;
`;
