import firebase from 'firebase/app';
import 'firebase/messaging';
import storage from '../utils/storage';
import { deviceApi } from '../api';
import { DeviceType } from 'shovel-lib/types/device';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_MEASUREMENT_ID
} = process.env;

const config = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
firebase.initializeApp(config);

export const initFcmMessaging = () => {
  const messaging = firebase.messaging();

  messaging
    .getToken()
    .then(token => {
      storage.setFcmToken(token);
      deviceApi.createDevice({ token, deviceType: DeviceType.BROWSER });
    })
    .catch(error => {
      console.log(error);
    });

  messaging.onTokenRefresh(() => {
    messaging
      .getToken()
      .then(token => {
        console.log('Token refreshed.');
        deviceApi.deleteDevice(storage.getFcmToken()!);
        deviceApi.createDevice({ token, deviceType: DeviceType.BROWSER });
        storage.setFcmToken(token);
      })
      .catch(err => {
        console.log('Unable to retrieve refreshed token ', err);
        // showToken('Unable to retrieve refreshed token ', err);
      });
  });

  return messaging;
};

export const isFcmMessagingSupported = firebase.messaging.isSupported();
