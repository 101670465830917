import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { calendarActions } from '../calendar';
import mobileBridgeApi, { InputType, OutputType, PlanThisBlockOutput } from '../../api/mobileBridgeApi';
import { eventChannel } from 'redux-saga';
import { authActions } from '../auth';
import { getType } from 'typesafe-actions';
import * as actions from '../calendar/actions';
import { eventActions } from '../events';
import storage from '../../utils/storage';
import { taskActions } from '../task';
import { isMobileApp } from '../../utils/screenUtils';
import * as selectors from '@state/events/selectors';

function* onMobileOpenPlanThisBlock(action: ReturnType<typeof calendarActions.openPlanBlockDialog>) {
  const { event } = action.payload;
  if (isMobileApp) {
    const req: PlanThisBlockOutput = {
      type: OutputType.PLAN_THIS_BLOCK_REQUEST,
      payload: {
        eventInfo: {
          plannedEventId: event.id!,
          from: event.start,
          to: event.end
        }
      }
    };
    mobileBridgeApi.sendMessage(req);
  }
}

function* watchMobileEvents() {
  if (!isMobileApp) {
    return;
  }

  const deviceType = yield call(storage.getDeviceType);

  const channel = eventChannel(emitter => {
    const parent = deviceType === 'ios' ? window : document;
    parent.addEventListener('message', onNextState(emitter));
    return () => {
      parent.removeEventListener('message', onNextState(emitter));
    };
  });

  // todo handle logout
  while (true) {
    const { data, logout } = yield race({
      data: take(channel),
      logout: take(getType(authActions.logout))
    });

    if (logout) {
      channel.close();
      break;
    } else {
      try {
        const { type, payload } = JSON.parse(data.event);
        switch (type) {
          case InputType.CREATE_PLANNED_TASKS_SUCCESS:
            yield put(actions.planTasks.success(payload));
            break;
          case InputType.CREATE_EVENT_SUCCESS:
            const sourceEvents = [payload];
            const events = yield select(selectors.unpackEventsInRange, { nonStateEvents: sourceEvents });
            yield put(eventActions.createEvent.success({ sourceEvents, events, type: payload.type }));
            break;
          case InputType.UPDATE_EVENT_SUCCESS:
            yield put(eventActions.updateEvent.success(payload));
            yield put(eventActions.getEventsList.request({}));
            break;
          case InputType.DELETE_EVENT_SUCCESS:
            yield put(eventActions.deleteEvent.success(payload));
            yield put(eventActions.getEventsList.request({}));
            break;
          case InputType.SAVE_MANAGE_TASK_SUCCESS:
            yield put(taskActions.saveManageTask.success(payload));
            break;
          case InputType.CALENDAR_GO_TO_DATE:
            yield put(calendarActions.navigateFromMobile(payload));
            break;
          case InputType.DELETE_PLANNED_TASK_SUCCESS:
            // @ts-ignore
            yield put(calendarActions.deletePlannedTask.success({ event: payload.event, data: payload.data }));
            break;
          case InputType.CANCEL_UPDATE_RECURRING_EVENT:
            yield put(calendarActions.revertLastAction());
            break;
          case InputType.CREATE_TASKS_SUCCESS:
            yield put(taskActions.createTasks.success(payload));
            break;
          case InputType.DELETE_TASK_SUCCESS:
            yield put(taskActions.deleteTask.success({ id: payload }));
            break;
          case InputType.RESIZE_PLANNED_TASK_SUCCESS:
            yield put(calendarActions.resizePlannedTask.success(payload));
            break;
          case InputType.MOVE_PLANNED_TASK_SUCCESS:
            yield put(calendarActions.movePlannedTask.success(payload));
            break;
          case InputType.MARK_AS_USED_PLANNED_TASK_SUCCESS:
            yield put(calendarActions.markAsUsed.success(payload));
            break;
          case InputType.MARK_TASK_AS_COMPLETED_SUCCESS:
            yield put(taskActions.updateTaskStatus.success(payload));
            yield put(calendarActions.getPlannedTasks.request());
            break;
          case InputType.MOVE_TASK_TO_DO_SUCCESS:
            yield put(taskActions.moveToTodo.success(payload));
            break;
        }
      } catch (e) {}
    }
  }
}

type Emitter = (input: any) => void;

const onNextState = (emitter: Emitter) => (event: any) => {
  emitter({ event: event.data });
};

export default function*() {
  yield all([watchMobileEvents(), takeLatest(calendarActions.openPlanBlockDialog, onMobileOpenPlanThisBlock)]);
}
