import React, { FC } from 'react';
import styled from 'styled-components';

import { Column } from '../common/layoutUtils';
import colors from '../../utils/colors';
import { ReactComponent as ShovelLogo } from '../../assets/images/shovel-logo.svg';
import ShovelLaptop from '../../assets/images/shovel-laptop.png';
import { ExtraLargeText } from '../../utils/typography';

type Props = {};

const SignUpMobileInfo: FC<Props> = () => {
  return (
    <Container>
      <Section bgColor={colors.primaryPurple}>
        <Logo />
        <ExtraLargeText color={colors.white}>Not a Member Yet?</ExtraLargeText>
        <img src={ShovelLaptop} style={{ height: '150px', margin: '20px 0' }} />
        <ExtraLargeText color={colors.white} style={{ marginBottom: '20px' }}>
          We apologize, but we don’t allow account creation on the mobile app at the moment.
        </ExtraLargeText>
        <ExtraLargeText color={colors.white} style={{ marginBottom: '20px' }}>
          Setting up an entire semester isn’t practical on a mobile device. That’s why we require setup on a computer.
        </ExtraLargeText>
        <ExtraLargeText color={colors.white}>Learn more on our website.</ExtraLargeText>
      </Section>
    </Container>
  );
};

export default SignUpMobileInfo;

const Container = styled(Column)`
  flex: 1;
  height: 100%;
  padding: 30px 40px;
  background: ${colors.primaryPurple};
  overflow-y: scroll;
`;

const Section = styled(Column)<{ bgColor?: string }>`
  color: white;
  align-items: center;
  text-align: center;
`;

const Logo = styled(ShovelLogo)`
  color: white;
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  path {
    fill: ${colors.white} !important;
  }
`;
