import { createReducer } from 'typesafe-actions';

import { TaskState, WorkloadTask } from 'shovel-lib/types';
import * as actions from './actions';
import { ExpandItemType, SidebarItemType, WorkloadState } from './types';
import { sortByNullableDueDateAndTitle } from '../../utils/reducerHelpers';
import { calendarActions } from '../calendar';
import { taskActions } from '../task';
import { now } from 'shovel-lib/utils/timeUtils';
import { GroupByField } from '../../components/course/types';
import { timerActions } from '../timer';

const initialState: WorkloadState = {
  tasks: [],
  selectedItemType: SidebarItemType.SECTION,
  groupWorkloadBy: GroupByField.DUE_DATE,
  showCompletedWorkload: true,
  newWorkloadTasks: undefined,
  loading: false
};

const resetNewTasks = (state: WorkloadState, tasks?: WorkloadTask[]) =>
  state.newWorkloadTasks
    ? (Object.values(state.newWorkloadTasks) as WorkloadTask[][]).reduce(
        (tasks, groupTasks: WorkloadTask[]) => tasks.concat(groupTasks),
        tasks || state.tasks
      )
    : tasks || state.tasks;

const reducer = createReducer(initialState)
  .handleAction(actions.getWorkload.success, (state, action) => ({
    ...state,
    tasks: action.payload,
    newWorkloadTasks: undefined
  }))
  .handleAction([calendarActions.updateCourse.success, calendarActions.updateActivity.success], (state, action) => {
    return {
      ...((state.selectedItemType === SidebarItemType.COURSE || state.selectedItemType === SidebarItemType.ACTIVITY) &&
      state.selectedItemId === action.payload.id
        ? {
            ...state,
            selectedItemName: action.payload.name,
            selectedItemColor: action.payload.colorHex
          }
        : state),
      tasks: resetNewTasks(state).map(t =>
        t.courseId === action.payload.id
          ? {
              ...t,
              courseName: action.payload.name,
              colorHex: action.payload.colorHex
            }
          : t
      ),
      newWorkloadTasks: undefined
    };
  })
  .handleAction(taskActions.createTasks.success, (state, action) => {
    const { workloadTable } = action.payload;

    const tasks = workloadTable
      ? state.tasks
      : resetNewTasks(state, [...state.tasks, ...action.payload.tasks]).sort(sortByNullableDueDateAndTitle);

    let newWorkloadTasks: any = undefined;
    if (workloadTable) {
      newWorkloadTasks = state.newWorkloadTasks
        ? {
            ...state.newWorkloadTasks,
            [workloadTable]: [...(state.newWorkloadTasks[workloadTable] || []), ...action.payload.tasks]
          }
        : { [workloadTable]: action.payload.tasks };
    }

    return {
      ...state,
      tasks,
      newWorkloadTasks
    };
  })
  .handleAction(actions.resetAddedWorkloadTasks, state => {
    return {
      ...state,
      tasks: resetNewTasks(state),
      newWorkloadTasks: undefined
    };
  })
  .handleAction(calendarActions.deleteCourse.success, (state, action) => {
    if (state.selectedItemType !== SidebarItemType.COURSE || state.selectedItemId !== action.payload.id) return state;
    return initialState;
  })
  .handleAction(calendarActions.deleteActivity.success, (state, action) => {
    if (state.selectedItemType !== SidebarItemType.ACTIVITY || state.selectedItemId !== action.payload.id) return state;
    return initialState;
  })
  .handleAction(actions.selectCommitmentSuccess, state => ({ ...state, loading: false }))
  .handleAction(actions.selectCommitment, (state, action) => ({
    ...state,
    loading: true,
    selectedItemId: action.payload?.id,
    selectedItemType: action.payload?.type || SidebarItemType.SECTION,
    selectedItemName: action.payload?.name,
    selectedItemColor: action.payload?.colorHex,
    selectedItemCourseId: action.payload?.courseId || undefined
  }))
  .handleAction(taskActions.deleteTasks.success, (state, action) => {
    const tasks = resetNewTasks(state);
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: action.payload.deleteForever
        ? tasks.filter(t => !action.payload.ids.includes(t.id))
        : tasks.map(t => (action.payload.ids.includes(t.id) ? { ...t, deleted: true } : t))
    };
  })
  .handleAction(taskActions.restoreTasks.success, (state, action) => {
    return {
      ...state,
      tasks: state.tasks.map(t => (action.payload.includes(t.id) ? { ...t, deleted: false } : t))
    };
  })
  .handleAction(taskActions.completeTasks.success, (state, action) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(t => (action.payload.includes(t.id) ? { ...t, state: TaskState.COMPLETED } : t))
    };
  })
  .handleAction(taskActions.moveTasksToTodo.success, (state, action) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(t => (action.payload.includes(t.id) ? { ...t, state: TaskState.ACTIVE } : t))
    };
  })
  .handleAction(taskActions.setTasksStartAhead.success, (state, { payload }) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(t =>
        payload.taskIds.includes(t.id) ? { ...t, daysToStartAhead: payload.daysToStartAhead } : t
      )
    };
  })
  .handleAction(taskActions.setTasksPriority.success, (state, { payload }) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(t => (payload.taskIds.includes(t.id) ? { ...t, priority: payload.priority } : t))
    };
  })
  .handleAction(taskActions.pushTasksDueDate.success, (state, { payload }) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state)
        .map(task => payload.find(t => t.id === task.id) || task)
        .sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1))
    };
  })
  .handleAction(taskActions.setTimeNeeded.success, (state, { payload }) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(task =>
        payload.taskIds.includes(task.id) ? { ...task, timeNeed: payload.timeNeeded } : task
      )
    };
  })
  .handleAction(taskActions.addPageRangeForTasks.success, (state, { payload }) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(task => payload.find(t => t.id === task.id) || task)
    };
  })
  .handleAction(taskActions.savePageRanges.success, (state, { payload }) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(task => (payload.data.taskId === task.id ? payload.data : task))
    };
  })
  .handleAction([timerActions.start.success, calendarActions.markAsUsed.success], (state, { payload }) => {
    if (!payload.plannedTaskDto) return state;
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(task =>
        payload.taskId === task.id
          ? { ...task, timeNeed: payload.timeNeed, timeSpent: payload.timeSpent, pages: payload.pages }
          : task
      )
    };
  })
  .handleAction(calendarActions.updatePlannedTask.success, (state, { payload }) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(task =>
        payload.data.taskId === task.id
          ? { ...task, timeNeed: payload.data.timeNeed, timeSpent: payload.data.timeSpent }
          : task
      )
    };
  })
  .handleAction(calendarActions.deletePlannedTask.success, (state, { payload }) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(task =>
        payload.event.taskId === task.id
          ? { ...task, timeNeed: payload.data.timeNeed, timeSpent: payload.data.timeSpent }
          : task
      )
    };
  })
  .handleAction(taskActions.moveToTodo.success, (state, { payload }) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(task =>
        payload.taskId === task.id
          ? {
              ...task,
              state: now().isAfter(task.dueDate) ? TaskState.DO_LATER : TaskState.ACTIVE,
              completedOn: undefined
            }
          : task
      )
    };
  })
  .handleAction(taskActions.updateTaskStatus.success, (state, { payload }) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(task =>
        payload.taskId === task.id ? { ...task, state: payload.state, completedOn: payload.completedOn } : task
      )
    };
  })
  .handleAction(taskActions.deleteTask.success, (state, { payload }) => {
    const tasks = payload.deleteForever
      ? resetNewTasks(state).filter(t => t.id !== payload.id)
      : resetNewTasks(state).map(t => (t.taskId === payload.id ? { ...t, deleted: true } : t));
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks
    };
  })
  .handleAction(taskActions.addOrUpdateSubtask.success, (state, { payload }) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(t => {
        if (t.id !== payload.taskId) return t;
        const subtasks = [...t.subtasks];
        const subtaskIndex = subtasks.findIndex(s => s.id === payload.data.id);
        if (subtaskIndex !== -1) {
          subtasks[subtaskIndex] = payload.data;
        } else {
          subtasks.push(payload.data);
        }
        return { ...t, subtasks };
      })
    };
  })
  .handleAction(taskActions.deleteSubtask.success, (state, { payload }) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(t => {
        if (t.id !== payload.taskId) return t;
        return { ...t, subtasks: t.subtasks.filter(s => s.id !== payload.id) };
      })
    };
  })
  .handleAction(taskActions.saveManageTask.success, (state, { payload }) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(t => {
        if (t.taskId !== payload.response.taskId) return t;
        return { ...t, ...payload.response };
      })
    };
  })
  .handleAction(taskActions.changeTaskCategory.success, (state, action) => {
    const { taskIds, category } = action.payload;
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(task => {
        if (!taskIds.includes(task.id)) return task;
        return { ...task, taskCategory: category };
      })
    };
  })
  .handleAction(taskActions.changeReadingSource.success, (state, action) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(t => {
        const updatedTask = action.payload.find(task => task.taskId === t.id);
        if (!updatedTask) return t;
        return {
          ...t,
          ...updatedTask,
          pageRanges: updatedTask.pageRanges || t.pageRanges
        };
      })
    };
  })
  .handleAction(taskActions.changeCourse.success, (state, action) => {
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(t => {
        const updatedTask = action.payload.find(task => task.taskId === t.id);
        if (!updatedTask) return t;
        return {
          ...t,
          ...updatedTask
        };
      })
    };
  })
  .handleAction(taskActions.reorderSubtasks.success, (state, action) => {
    const { id, subtasks } = action.payload;
    return {
      ...state,
      newWorkloadTasks: undefined,
      tasks: resetNewTasks(state).map(t => {
        if (t.taskId !== id) return t;
        return {
          ...t,
          subtasks
        };
      })
    };
  })
  .handleAction(actions.toggleCourseSection, (state, action) => ({
    ...state,
    expandedItemType: action.payload.expand ? ExpandItemType.COURSE : undefined,
    expandedItemId: action.payload.expand ? action.payload.id : undefined
  }))
  .handleAction(calendarActions.addTaskCategory, (state, action) => {
    if (state.expandedItemId === action.payload) {
      return state;
    }
    return {
      ...state,
      expandedItemType: ExpandItemType.COURSE,
      expandedItemId: action.payload
    };
  });

export default reducer;
