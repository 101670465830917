import React, { lazy, Suspense, useCallback } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';

import { getCurrentManagedTask } from '../../../state/task/selectors';
import { RootState } from '../../../state/rootReducer';
import { fullScreenModalStyle, MANAGE_DIALOG_Z_INDEX } from '../../../utils/popupUtils';
import { closeManageTaskDialog } from '../../../state/task/actions';
import colors from '../../../utils/colors';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { Dialog } from '@components/task/manage/ManageTaskDialog.styles';
const ManageTaskDialogContent = lazy(() => import('./ManageTaskDialogContent'));

type PropsFromState = {
  manage: {
    task?: any;
    isOpen: boolean;
  };
};

type PropsFromDispatch = {
  dismiss: typeof closeManageTaskDialog;
};

type Props = PropsFromState & PropsFromDispatch;

function ManageTaskDialog({ manage, dismiss }: Props) {
  const handleClose = useCallback(() => dismiss(), []);

  return (
    <Dialog
      open={manage.isOpen}
      contentStyle={fullScreenModalStyle}
      overlayStyle={{ overflow: 'scroll', zIndex: MANAGE_DIALOG_Z_INDEX }}
      onClose={handleClose}
      lockScroll
      closeOnEscape={true}
      closeOnDocumentClick={false}
    >
      <Suspense
        // todo: replace with skeleton since it's not animated for some reason
        fallback={
          <LoaderWrapper>
            <Loader type="ThreeDots" color={colors.primaryPurple} height={50} width={50} />
          </LoaderWrapper>
        }
      >
        <ManageTaskDialogContent manage={manage} handleClose={handleClose} id={'manage-task-dialog'} />
      </Suspense>
    </Dialog>
  );
}

const mapStateToProps = (state: RootState) => ({
  manage: getCurrentManagedTask(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ dismiss: closeManageTaskDialog }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManageTaskDialog);

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
