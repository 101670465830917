import * as React from 'react';
import { FC } from 'react';
import Button from '../../common/buttons/Button';
import colors from '../../../utils/colors';
import styled from 'styled-components';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
};

const AuthButton: FC<Props> = ({ onClick, disabled, type, children, className }) => (
  <StyledButton
    onClick={onClick}
    disabled={disabled}
    type={type}
    size="lg"
    color={colors.primaryPurple}
    filled
    className={className}
  >
    {children}
  </StyledButton>
);

export default AuthButton;

const StyledButton = styled(Button)`
  margin: 20px auto 30px auto;
`;
