import React from 'react';
import { getEmojiDataFromNative, Emoji } from 'emoji-mart';
import data from 'emoji-mart/data/all.json';

type Props = {
  emoji: string;
  size?: number;
};

export const EmojiPreview = ({ emoji, size = 12 }: Props) => {
  const emojiData = getEmojiDataFromNative(emoji, 'apple', data);

  return <Emoji emoji={emojiData.id} set="apple" size={size} skin={emojiData.skin || 1} />;
};
