import colors from '../utils/colors';
import { darkenColor, increaseColorBrightness } from '../utils/colorUtils';
import { currentTheme } from '../utils/theme/appTheme';
import { rawColors } from '../utils/theme/rawColors';

export type Theme = 'dark' | 'light';

export const lightTheme = {
  name: 'light',
  primary: colors.primaryPurple,
  primaryLight: colors.primaryLight,
  primaryDark: colors.primaryDark,
  background: colors.white,
  backgroundDark: colors.whitesmoke,
  backgroundLight: colors.backgroundLight,
  borderColor: colors.lightgray,
  captures: colors.captures,
  textColor: colors.textDefault,
  textStrongColor: colors.darkGray,
  disabledTextColor: colors.gray,
  activitiesColor: colors.whitesmoke,
  courseColorFunction: increaseColorBrightness,
  commitmentBorderFunction: color => increaseColorBrightness(color, 70),
  commitmentColorFunction: color => increaseColorBrightness(color, 90),
  eventColorFunction: color => color,
  tooltipTextColor: colors.white,
  plannedTagColor: color =>
    color === colors.positive
      ? colors.lightPositive
      : color === colors.negative
      ? colors.lightestNegative
      : colors.primaryLight,
  plannedProgressColor: color => colors.lightNegative,
  taskProgressOpacity: 0.05,
  awakeEventColor: colors.whitesmoke,
  studyTimeColor: increaseColorBrightness(colors.primaryPurple, 97),
  passedStudyColor: colors.whitesmoke,
  planOverlayColor: darkenColor(colors.primaryLight, 8),
  calendarGrayColor: '#F2F3F5',
  extraTimeColor: '#EFEFEF',
  extraTimeTextColor: '#BBBBBB',
  sleepTimeBackgroundColor: darkenColor(colors.lightgray, -5),
  boxShadow: 'rgba(63, 78, 97, 0.2)',
  largeBoxShadow: 'rgba(63, 78, 97, 0.3)',
  modalOverlayColor: 'rgba(0, 0, 0, 0.5)',
  displayAuthImage: true,
  tippyThemeName: 'shovel',
  threeDotsTippyTheme: 'three-dots',
  sidebarCollapsedColor: colors.primaryLight,
  sidebarHandleColor: colors.primaryLight,
  sidebarHandleIconColor: colors.primaryPurple,
  verticalLinesThemeName: 'light-theme-vertical-line',
  horizontalLinesThemeName: 'light-theme-horizontal-line',
  onboardingBarBackground: colors.darkGray,
  placeholder: colors.placeholder,
  onboardingBarTextColor: colors.captures,
  onboardingBarBadgesBgColor: '#13181D',
  onboardingBarBadgesColor: colors.darkGray,
  onBoardingStepColor: colors.captures,
  onBoardingStepActiveColor: colors.white,
  hintColor: 'rgba(209, 241, 229, 0.6)',
  negative: colors.negative,
  themeIconCircle: colors.white,
  randomTaskColor: colors.randomTaskColor
};

export const darkTheme = {
  name: 'dark',
  primary: colors.primaryPurple,
  primaryLight: colors.primaryLight,
  primaryDark: colors.primaryDark,
  background: rawColors.gray2,
  backgroundDark: darkenColor(rawColors.gray2, -15),
  backgroundLight: darkenColor(rawColors.gray2, -15),
  studyTimeColor: darkenColor(rawColors.gray2, -15),
  borderColor: colors.darkGray,
  captures: colors.darkGray,
  textColor: darkenColor(colors.captures, -10),
  textStrongColor: darkenColor(colors.white, -15),
  disabledTextColor: colors.gray,
  activitiesColor: colors.darkGray,
  courseColorFunction: color => increaseColorBrightness(darkenColor(color, -70), 10),
  commitmentBorderFunction: color => darkenColor(color, -45),
  commitmentColorFunction: color => increaseColorBrightness(darkenColor(color, -80), 10),
  eventColorFunction: color => darkenColor(color, -30),
  tooltipTextColor: colors.darkGray,
  plannedTagColor: color => `${color}23`,
  plannedProgressColor: color => `${color}33`,
  taskProgressOpacity: 0.1,
  awakeEventColor: `${currentTheme.primaryDark}2E`,
  passedStudyColor: rawColors.gray2,
  planOverlayColor: `${currentTheme.primary}1A`,
  calendarGrayColor: colors.black,
  extraTimeColor: darkenColor('#EFEFEF', -70),
  extraTimeTextColor: darkenColor('#BBBBBB', -40),
  sleepTimeBackgroundColor: '#292e3c',
  boxShadow: 'rgba(0, 0, 0, 0.4)',
  largeBoxShadow: 'rgba(0, 0, 0, 0.4)',
  modalOverlayColor: 'rgba(0, 0, 0, 0.4)',
  displayAuthImage: false,
  tippyThemeName: 'shovel-dark',
  threeDotsTippyTheme: 'three-dots-dark',
  sidebarCollapsedColor: colors.darkGray,
  sidebarHandleColor: colors.darkGray,
  sidebarHandleIconColor: colors.white,
  verticalLinesThemeName: 'dark-theme-vertical-line',
  horizontalLinesThemeName: 'dark-theme-horizontal-line',
  onboardingBarBackground: colors.backgroundLight,
  placeholder: darkenColor(colors.gray, -20),
  onboardingBarTextColor: colors.darkGray,
  onboardingBarBadgesBgColor: colors.whitesmoke,
  onboardingBarBadgesColor: colors.lightgray,
  onBoardingStepColor: colors.gray,
  onBoardingStepActiveColor: colors.darkGray,
  hintColor: `${colors.positive}23`,
  negative: colors.negative,
  themeIconCircle: darkenColor(colors.white, -15),
  randomTaskColor: colors.randomTaskColor
};
