import moment from 'moment';
import en from 'date-fns/locale/en-GB';
import { registerLocale } from 'react-datepicker';
import { MembershipType, Formats, PersonalizationSettings, PriceTier } from 'shovel-lib/types';
import t from '../i18n/t';

export const isSubscription = type =>
  type === MembershipType.MONTHLY || type === MembershipType.SEMI_ANNUAL || type === MembershipType.ANNUAL;

const toDatepickerFormat = (format: string) => {
  return format.replace(/D/g, 'd').replace(/Y/g, 'y');
};

export const updateMomentLocale = (settings: PersonalizationSettings) => {
  const shortDate = settings.dateFormat!.replace(/^YYYY | YYYY$/g, '');
  const longDate = settings.dateFormat;
  const time = settings.time24Format ? 'HH:mm' : 'h:mma';
  const dayOfWeekShort = 'ddd';

  const momentFormats = {
    parentLocale: 'en',
    week: {
      dow: settings.firstDayOfWeek,
      doy: 7 + settings.firstDayOfWeek! - 1
    },
    longDateFormat: {
      [Formats.MONTH_DAY_FORMAT]: shortDate,
      [Formats.LONG_DATE]: longDate,
      [Formats.TIME]: time,
      [Formats.LOWERCASE_TIME]: time,
      [Formats.DATE_TIME_FORMAT]: `${shortDate}, ${time}`,
      [Formats.TASK_DATE_FORMAT]: `${shortDate}, ${time}`,
      [Formats.DAY_DATE_TIME_FORMAT]: `${dayOfWeekShort} ${shortDate}, ${time}`
    }
  };
  //@ts-ignore
  moment.locale('custom', momentFormats);
};

export const registerDatepickerLocale = (settings: PersonalizationSettings) => {
  const timeFormat = settings.time24Format ? 'HH:mm' : 'h:mmaaa';
  const dateFormat = toDatepickerFormat(settings.dateFormat!);
  const shortDateFormat = dateFormat.replace(/^yyyy | yyyy$/g, '');
  const mediumDateFormat = dateFormat.replace(/^yyyy|yyyy$/g, "''yy");
  const formats = {
    short: shortDateFormat,
    medium: dateFormat,
    long: `${shortDateFormat}, ${timeFormat}`,
    full: `${mediumDateFormat}, ${timeFormat}`
  };
  const customLocale = {
    ...en,
    formatLong: {
      date: ({ width }) => formats[width],
      time: () => timeFormat,
      dateTime: () => `${shortDateFormat}, ${timeFormat}`
    },
    options: {
      weekStartsOn: settings.firstDayOfWeek
    }
  };

  registerLocale('custom', customLocale);
};

export const deletingReasons = [
  { value: 1, label: t.DELETING_REASON1 },
  { value: 2, label: t.DELETING_REASON2 },
  { value: 3, label: t.DELETING_REASON3 },
  { value: 4, label: t.DELETING_REASON4 },
  { value: 5, label: t.DELETING_REASON5 },
  { value: 6, label: t.DELETING_REASON6 },
  { value: 7, label: t.DELETING_REASON7 },
  { value: 8, label: t.DELETING_REASON8 }
];

export const pricePerPlan = {
  [PriceTier.TIER_ONE]: {
    [MembershipType.MONTHLY]: 4,
    [MembershipType.ANNUAL]: 24,
    [MembershipType.LIFETIME]: 49.99
  },
  [PriceTier.TIER_TWO]: {
    [MembershipType.MONTHLY]: 2,
    [MembershipType.ANNUAL]: 9,
    [MembershipType.LIFETIME]: 19.99
  }
};

export const dealTextPerPlan = {
  [PriceTier.TIER_ONE]: {
    [MembershipType.MONTHLY]: '',
    [MembershipType.ANNUAL]: `50% ${t.LESS_THAN_MONTHLY}`,
    [MembershipType.LIFETIME]: t.MOST_POPULAR
  },
  [PriceTier.TIER_TWO]: {
    [MembershipType.MONTHLY]: '',
    [MembershipType.ANNUAL]: `63% ${t.LESS_THAN_MONTHLY}`,
    [MembershipType.LIFETIME]: t.MOST_POPULAR
  }
};

export function toPriceText(price: number) {
  return `$${price}`;
}

export function getPaymentSuccessLink(isParent: boolean, tier: PriceTier, type: MembershipType) {
  return `${isParent ? 'parent' : 'student'}-${tier === PriceTier.TIER_ONE ? 'reg' : 'pp'}-${type.toLowerCase()}`;
}
