import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import TippyTooltip from '@components/common/tooltip/TippyTooltip';
import MaterialIcon from '@components/common/icons/MaterialIcon';
import { menuPopupStyle } from '@utils/popupUtils';
import { Column } from '@components/common/layoutUtils';
import { NavLink } from 'react-router-dom';

type OptionType = {
  linkTo: any;
  title: string;
};

type Props = {
  options: OptionType[];
  icon: string;
  tooltip?: string;
  outlineIcon?: boolean;
  iconSize?: number;
};

const Menu: FC<Props> = ({ options, icon, tooltip, outlineIcon, iconSize }) => {
  return (
    <Popup
      trigger={
        <div>
          <TippyTooltip
            target={
              <Item>
                <MaterialIcon className={'navigation-icon'} name={icon} size={iconSize || 20} outline={outlineIcon} />
              </Item>
            }
            position={'right'}
            content={tooltip}
          />
        </div>
      }
      arrow={false}
      position="right bottom"
      on="click"
      closeOnDocumentClick
      contentStyle={menuPopupStyle}
    >
      {close => (
        <MenuPopupContainer>
          {options.map(opt => (
            <MenuLinkItem to={opt.linkTo} onClick={() => close()}>
              {opt.title}
            </MenuLinkItem>
          ))}
        </MenuPopupContainer>
      )}
    </Popup>
  );
};

export default Menu;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  background-color: inherit;
  &:hover {
    background: ${({ theme }) => theme.backgroundDark};
    .navigation-icon {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

const MenuPopupContainer = styled(Column)`
  background-color: ${({ theme }) => theme.background};
  border-radius: 5px;
  padding: 5px 0;
`;

const MenuLinkItem = styled(NavLink)`
  padding: 5px 10px;
  cursor: pointer;
  background-color: inherit;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.textColor};

  &:hover {
    color: ${({ theme }) => theme.textStrongColor};
    background-color: ${({ theme }) => theme.backgroundLight};
  }
`;
