import { all, fork, put, select, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as selectors from './selectors';
import * as semesterSelectors from '../semester/selectors';
import { apiCall } from '../common/operations';
import { eventServiceApi } from '../../api';
import { alert } from '@components/common/snackbarNotifications/Notifications';
import { getErrorMessage } from '../../api/network';
import t from '../../i18n/t';
import gaAnalytics, { AnalyticsEvent } from '../../config/gaAnalytics';
import moment from 'moment';

function* sourceEvents() {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(eventServiceApi.getEventsList, semesterId);
  if (ok) {
    yield put(actions.sourceEvents.success(response.data));
  } else {
    yield put(actions.sourceEvents.failure(response));
  }
}

function* getEventsList(action: ReturnType<typeof actions.getEventsList.request>) {
  const { startDate, endDate } = action.payload;

  const start = startDate ? moment(startDate) : null;
  const end = endDate ? moment(endDate) : null;

  const events = yield select(selectors.unpackEventsInRange, { start, end });
  yield put(actions.getEventsList.success({ events, start, end }));
}

function* createEvent(action: ReturnType<typeof actions.createEvent.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  //@ts-ignore
  const { callback, ...payload } = action.payload;
  const { ok, ...response } = yield apiCall(eventServiceApi.createEvent, semesterId, payload);

  if (ok) {
    const sourceEvents = [response.data];
    const events = yield select(selectors.unpackEventsInRange, { nonStateEvents: sourceEvents });
    yield put(actions.createEvent.success({ sourceEvents, events, type: payload.info.type }));
    if (callback) callback();
    yield fork(gaAnalytics.track, AnalyticsEvent.CreateEvent.name);
  } else {
    yield put(actions.createEvent.failure(response));
    alert(t[getErrorMessage(response)]);
  }
}

function* deleteEvent(action: ReturnType<typeof actions.deleteEvent.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { eventId, query } = action.payload;
  const { ok, ...response } = yield apiCall(eventServiceApi.deleteEvent, semesterId, eventId, query);

  if (ok) {
    yield put(actions.deleteEvent.success({ data: response.data, id: eventId }));
    yield put(actions.getEventsList.request({}));
  } else {
    yield put(actions.deleteEvent.failure(response));
  }
}

function* updateEvent(action: ReturnType<typeof actions.updateEvent.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  //@ts-ignore
  const { callback, revert, ...payload } = action.payload;
  const { ok, ...response } = yield apiCall(eventServiceApi.updateEvent, semesterId, payload.event.id!, payload);

  if (ok) {
    yield put(actions.updateEvent.success({ data: response.data, id: payload.event.id! }));
    yield put(actions.getEventsList.request({}));
    if (callback) callback();
  } else {
    yield put(actions.updateEvent.failure(response));
    // Revert the event instance in the calendar to the previous state if the update was triggered by dragging on the calendar (move or resize)
    if (revert) revert();
    alert(t[getErrorMessage(response)]);
  }
}

function* getSemesterHolidays() {
  const semesterId = yield select(semesterSelectors.getId);
  const correlationId = yield select(semesterSelectors.getHolidaysCorrelationId);
  const { ok, ...response } = yield apiCall(eventServiceApi.getSemesterHolidays, semesterId, correlationId);
  if (ok) {
    yield put(actions.getSemesterHolidays.success(response.data));
  } else {
    yield put(actions.getSemesterHolidays.failure(response));
  }
}

function* updateSemesterHolidays(action: ReturnType<typeof actions.updateSemesterHolidays.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const correlationId = yield select(semesterSelectors.getHolidaysCorrelationId);
  const { ok, ...response } = yield apiCall(
    eventServiceApi.recreateEventsWithCorrelationId,
    semesterId,
    correlationId,
    action.payload
  );
  if (ok) {
    yield put(actions.updateSemesterHolidays.success(response.data));
  } else {
    yield put(actions.updateSemesterHolidays.failure(response));
    alert(t[getErrorMessage(response)]);
  }
}

export default function*() {
  yield all([
    takeLatest(actions.sourceEvents.request, sourceEvents),
    takeLatest(actions.getEventsList.request, getEventsList),
    takeLatest(actions.createEvent.request, createEvent),
    takeLatest(actions.deleteEvent.request, deleteEvent),
    takeLatest(actions.updateEvent.request, updateEvent),
    takeLatest(actions.getSemesterHolidays.request, getSemesterHolidays),
    takeLatest(actions.updateSemesterHolidays.request, updateSemesterHolidays)
  ]);
}
