import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import styled, { withTheme } from 'styled-components';

import { PopoverType, TooltipPosition } from '../../utils/popupUtils';
import colors from '../../utils/colors';
import { SmallText } from '../../utils/typography';
import MaterialIcon from './icons/MaterialIcon';

type Props = {
  target?: React.ReactElement;
  content: string;
  position?: TooltipPosition;
  iconStyle?: React.CSSProperties;
  iconSize?: number;
  width?: string;
  type?: PopoverType;
  isHtmlContent?: boolean;
  className?: string;
  theme: any;
  outline?: boolean;
  iconColor?: string;
};

export const arrowStyle = (backgroundColor: string) => ({
  boxShadow: 'none',
  borderRadius: '1px',
  backgroundColor: backgroundColor
});

export const contentStyle = (backgroundColor: string, width?: string) => ({
  border: 0,
  borderRadius: 5,
  backgroundColor: backgroundColor,
  maxWidth: width || '260px',
  padding: '16px',
  lineHeight: '11px',
  zIndex: 11
});

const Popover: FC<Props> = ({
  target,
  content,
  position = 'right center',
  iconStyle = {},
  iconSize = 16,
  width,
  type = PopoverType.TOOLTIP,
  isHtmlContent = false,
  className,
  theme,
  outline = true,
  iconColor
}) => {
  const backgroundColor =
    type === PopoverType.INFO ? colors.primaryPurple : type === PopoverType.TOOLTIP ? theme.textStrongColor : colors.warning;
  return (
    <Container width={width}>
      <Popup
        trigger={
          target || (
            <PopoverIcon style={iconStyle} className={className}>
              <MaterialIcon name={outline ? 'help_outline' : 'help'} size={iconSize} color={iconColor} />
            </PopoverIcon>
          )
        }
        position={position}
        on="hover"
        arrowStyle={arrowStyle(backgroundColor)}
        contentStyle={contentStyle(backgroundColor, width)}
      >
        <Content>{isHtmlContent ? <HtmlContent dangerouslySetInnerHTML={{ __html: content }} /> : content}</Content>
      </Popup>
    </Container>
  );
};

export default withTheme(Popover);

const Container = styled.div<{ width?: string }>`
  .popup-content {
    ${props =>
      props.width
        ? `width: ${props.width} !important;`
        : `
            width: intrinsic !important;           /* Safari/WebKit uses a non-standard name */
            width: -moz-max-content !important;    /* Firefox/Gecko */
            width: -webkit-max-content !important; /* Chrome */
            width: max-content !important;
        `}}
  }
`;

const Content = styled(SmallText)`
  color: ${({ theme }) => theme.tooltipTextColor} !important;
  white-space: pre-wrap;
`;

const HtmlContent = styled.div`
  color: ${({ theme }) => theme.tooltipTextColor};
`;

const PopoverIcon = styled.div`
  padding: 3px;
  margin-left: 2px;
  height: 16px;
  width: 16px;

  svg {
    height: 100%;
    width: 100%;
  }

  path {
    fill: ${({ theme }) => theme.textColor};
    transition: fill 0.2s ease;
  }

  &:hover path {
    fill: ${({ theme }) => theme.textStrongColor};
  }
`;
