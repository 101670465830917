import { RootState } from '../rootReducer';
import { groupByDate } from '../../utils/taskUtils';
import { PileStateOptions } from 'shovel-lib/types';
import { initialUserSettings } from '../settings/reducers';
import { CalendarState } from '../calendar/types';
import { getType } from 'typesafe-actions';
import { calculateCushion, getAllToDoTasks, getCompletedTasks, getWithinStartDateTasks } from './actions';
import { PileSections, SortOption, TaskForVirtualization, VirtualizationSection } from './types';
import { extractActionNameWithoutSuffix } from '../common/utils';
import t from '../../i18n/t';
import { isFilterActive } from '../../components/pile/components/utils';

const getPileFilterState = (state: RootState) => {
  const { filter, search, sort } = state.pile;
  return {
    filter,
    courses: (state.calendar as CalendarState).courses,
    activities: (state.calendar as CalendarState).activities,
    search,
    sort,
    activeTab: getActivePileTab(state)
  };
};

const getActivePileTab = ({ settings }: RootState) => (settings.userSettings || initialUserSettings).pileState;

const getAllTasks = (state: RootState) => {
  const noDueDate = state.pile[PileSections.NO_DUE_DATE];
  return Object.values(state.calendar.datesContainingTasks || [])
    .reduce((acc, events) => [...acc, ...events], [])
    .concat(noDueDate.tasks);
};

const getAllToDoState = ({ pile }: RootState) => {
  const { content, last, totalPages, page, totalElements } = pile.allToDo;
  return {
    last,
    totalPages,
    page,
    totalElements,
    loadedElements: content.length
  };
};

const NO_DUE_DATE_CARD_HEIGHT = 82;
const NO_START_AHEAD_CARD_HEIGHT = 84;
const FULL_CARD_HEIGHT = 99;
const EXPANDABLE_SECTION_COLLAPSED_HEIGHT = 52;
const EXPANDABLE_SECTION_EXPANDED_HEIGHT = 41;
const NON_EXPANDABLE_SECTION_TITLE_HEIGHT = 29;
const NO_LABEL_SECTION_TITLE_HEIGHT = 11;
const PADDING_AND_BORDER_BOTTOM = 21;
const MARK_ALL_COMPLETE_BUTTON_HEIGHT = 26;
const CREATED_AT_TEXT_HEIGHT = 16;

const getPileState = (state: RootState) => {
  const { allToDo, withinStartDate, completed, filter, search, sort } = state.pile;
  const recentlyCreated = state.pile[PileSections.RECENTLY_CREATED];
  const overdue = state.pile[PileSections.OVERDUE];
  const noDueDate = state.pile[PileSections.NO_DUE_DATE];
  const activeTab = getActivePileTab(state);
  const isCompleted = activeTab === PileStateOptions.COMPLETED;
  const tasks: (TaskForVirtualization | VirtualizationSection)[] = [];

  if (!isCompleted) {
    if (recentlyCreated.tasks.length > 0 && !recentlyCreated.expanded) {
      tasks.push({
        isLast: true,
        height: EXPANDABLE_SECTION_COLLAPSED_HEIGHT,
        section: PileSections.RECENTLY_CREATED,
        size: recentlyCreated.tasks.length,
        expanded: recentlyCreated.expanded
      });
    } else {
      recentlyCreated.tasks.forEach((task, i) => {
        if (i === 0) {
          tasks.push({
            height: EXPANDABLE_SECTION_EXPANDED_HEIGHT,
            section: PileSections.RECENTLY_CREATED,
            size: recentlyCreated.tasks.length,
            expanded: recentlyCreated.expanded
          });
        }
        let height = !task.due
          ? NO_DUE_DATE_CARD_HEIGHT
          : !task.daysToStartAhead
          ? NO_START_AHEAD_CARD_HEIGHT
          : FULL_CARD_HEIGHT;
        height += CREATED_AT_TEXT_HEIGHT;
        const isLast = i === recentlyCreated.tasks.length - 1;
        if (isLast) {
          height += PADDING_AND_BORDER_BOTTOM;
        }
        tasks.push({ isLast, task, height, section: PileSections.RECENTLY_CREATED });
      });
    }

    if (overdue.tasks.length > 0 && !overdue.expanded) {
      tasks.push({
        isLast: true,
        height: EXPANDABLE_SECTION_COLLAPSED_HEIGHT,
        section: PileSections.OVERDUE,
        size: overdue.tasks.length,
        expanded: overdue.expanded
      });
    } else {
      overdue.tasks.forEach((task, i) => {
        if (i === 0) {
          tasks.push({
            height: EXPANDABLE_SECTION_EXPANDED_HEIGHT,
            section: PileSections.OVERDUE,
            size: overdue.tasks.length,
            expanded: overdue.expanded
          });
        }
        let height = task.daysToStartAhead ? FULL_CARD_HEIGHT : NO_START_AHEAD_CARD_HEIGHT;
        const isLast = i === overdue.tasks.length - 1;
        if (isLast) {
          height += PADDING_AND_BORDER_BOTTOM + MARK_ALL_COMPLETE_BUTTON_HEIGHT;
        }
        tasks.push({ isLast, task, height, section: PileSections.OVERDUE });
      });
    }

    if (noDueDate.tasks.length > 0 && !noDueDate.expanded) {
      tasks.push({
        isLast: true,
        height: EXPANDABLE_SECTION_COLLAPSED_HEIGHT,
        section: PileSections.NO_DUE_DATE,
        size: noDueDate.tasks.length,
        expanded: noDueDate.expanded
      });
    } else {
      noDueDate.tasks.forEach((task, i) => {
        if (i === 0) {
          tasks.push({
            height: EXPANDABLE_SECTION_EXPANDED_HEIGHT,
            section: PileSections.NO_DUE_DATE,
            size: noDueDate.tasks.length,
            expanded: noDueDate.expanded
          });
        }
        let height = NO_DUE_DATE_CARD_HEIGHT;
        const isLast = i === noDueDate.tasks.length - 1;
        if (isLast) {
          height += PADDING_AND_BORDER_BOTTOM;
        }
        tasks.push({ isLast, task, height, section: PileSections.NO_DUE_DATE });
      });
    }
  }

  let content: { [key: string]: any[] } = {};
  let emptyMessage: string = '';
  let loading: boolean = false;
  const sortByDueDate = (isCompleted ? sort.completed : sort.todo).sortField === SortOption.DUE_DATE;
  const filterOrSearchActive = !!search || isFilterActive(filter, activeTab);
  switch (activeTab) {
    case PileStateOptions.UPCOMING:
      content = sortByDueDate ? groupByDate(withinStartDate.tasks) : { '': withinStartDate.tasks };
      emptyMessage = filterOrSearchActive
        ? t.WITHIN_START_DATE_EMPTY_MESSAGE_WITH_FILTER
        : t.WITHIN_START_DATE_EMPTY_MESSAGE;
      loading = state.common.loaders[extractActionNameWithoutSuffix(getWithinStartDateTasks.request)];
      break;
    case PileStateOptions.ACTIVE:
      content = sortByDueDate ? groupByDate(allToDo.content) : { '': allToDo.content };
      emptyMessage = filterOrSearchActive ? t.ALL_TO_DO_EMPTY_MESSAGE_WITH_FILTER : t.ALL_TO_DO_EMPTY_MESSAGE;
      loading = state.common.loaders[extractActionNameWithoutSuffix(getAllToDoTasks.request)];
      break;
    case PileStateOptions.COMPLETED:
      content = sortByDueDate ? groupByDate(completed.tasks) : { '': completed.tasks };
      emptyMessage = filterOrSearchActive ? t.COMPLETED_EMPTY_MESSAGE_WITH_FILTER : t.COMPLETED_EMPTY_MESSAGE;
      loading = state.common.loaders[extractActionNameWithoutSuffix(getCompletedTasks.request)];
      break;
    default:
    // invalid case
  }

  Object.entries(content).forEach(([key, sectionTasks]) => {
    sectionTasks.forEach((task, i) => {
      if (i === 0) {
        tasks.push({
          height: sortByDueDate ? NON_EXPANDABLE_SECTION_TITLE_HEIGHT : NO_LABEL_SECTION_TITLE_HEIGHT,
          section: key,
          isCompleted
        });
      }
      let height = task.daysToStartAhead && !isCompleted ? FULL_CARD_HEIGHT : NO_START_AHEAD_CARD_HEIGHT;
      const isLast = i === sectionTasks.length - 1;
      if (isLast) {
        height += PADDING_AND_BORDER_BOTTOM;
      }
      tasks.push({ isLast, task, height, section: key, isCompleted });
    });
  });

  return {
    filter,
    search,
    sort,
    activeTab,
    allToDo,
    overdue,
    tasks,
    loading,
    emptyMessage,
    selectedSectionCount: Object.keys(content).length
  };
};

const getDisplayedActiveTasks = state => {
  const activeTab = getActivePileTab(state);
  return activeTab === PileStateOptions.ACTIVE ? state.pile.allToDo.content : state.pile.withinStartDate.tasks;
};

const getMoveToDoDialogState = ({ pile }: RootState) => {
  return pile.moveToDoDialog;
};

const isCalculatingCushion = ({ common }: RootState) => {
  const calculateCushionAction = getType(calculateCushion.request).split('_REQUEST')[0];
  return common.loaders[calculateCushionAction];
};

export {
  getPileFilterState,
  getActivePileTab,
  getAllTasks,
  getAllToDoState,
  getPileState,
  getMoveToDoDialogState,
  isCalculatingCushion,
  getDisplayedActiveTasks
};
