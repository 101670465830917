export enum GradeUnitType {
  ASSIGNMENT = 'ASSIGNMENT',
  TEST = 'TEST',
  PARTICIPATION = 'PARTICIPATION'
}

export enum ReadingSourceType {
  BOOK = 'BOOK',
  PDF = 'PDF'
}

export enum GradeWeightType {
  PERCENTAGES = 'PERCENTAGES',
  POINTS = 'POINTS'
}

export enum CommitmentType {
  CLASS = 'CLASS',
  ACTIVITY = 'ACTIVITY',
  MEAL = 'MEAL',
  ME_TIME = 'ME_TIME'
}

/**
 * Types of entries/entities that can have timer attached for measuring.
 * */
export type TimerEntryType = 'TASK';

export enum CourseDroppableId {
  WORKLOAD = 'workload',
  AGENDA = 'agenda',
  AGENDA_PLANNED_BLOCK = 'agendaPlannedBlock'
}

export enum CourseDraggableId {
  GRADE_UNITS_TESTS = 'gradeUnits-TEST',
  GRADE_UNITS_ASSIGNMENTS = 'gradeUnits-ASSIGNMENT',
  GRADE_UNITS_PARTICIPATION = 'gradeUnits-PARTICIPATION',
  READING_SOURCES_BOOKS = 'readingSources-BOOK',
  READING_SOURCES_ARTICLES = 'readingSources-ARTICLE_TYPE',
  UNGRADED = 'ungradedTaskCategories-UNGRADED'
}

export enum FormFieldName {
  NAME = 'name',
  DESCRIPTION = 'details',
  COURSE = 'courseId',
  SUBTASKS = 'subtasks',
  CATEGORY = 'taskCategoryId',
  READING_SOURCE = 'readingSourceId',
  DUE_DATE = 'dueDate',
  DURATION = 'duration',
  START_AHEAD = 'daysToStartAhead',
  ESTIMATE = 'timeNeed',
  PAGE_RANGES = 'pageRanges',
  AUTO_ESTIMATE = 'automaticEstimation',
  PRIORITY = 'priority'
}
