import { SubscriptionInfo, UserSettings as slUserSettings, MonthFilterT as slMonthFilterT } from 'shovel-lib/types';
import { Substeps } from '@components/common/onboardingBar/helpers';

// TEMP (todo: make shovel-lib a package with monorepo and come back to this)
export type MonthFilterT = Omit<slMonthFilterT, 'studyTimeEvents' | 'courseEvents'> & {
  courseCorrelationIds: string[];
  exams?: boolean;
};
export type UserSettings = Omit<slUserSettings, 'monthFilter'> & {
  monthFilter: MonthFilterT;
  courseEventStyle: {
    style: calendarStylingType;
    sidebar: boolean;
  };
  activityEventStyle: {
    style: calendarStylingType;
    sidebar: boolean;
  };
  googleEventStyle: {
    style: calendarStylingType;
    sidebar: boolean;
  };
  plannedTaskEventStyle: {
    style: calendarStylingType;
    sidebar: boolean;
  };
};

export const initialMonthFilterState: MonthFilterT = {
  tasks: true,
  courseCorrelationIds: [],
  activityCorrelationIds: [],
  customEvents: false,
  /*TODO: remove*/
  googleEvents: false
};

export const resetMonthFilterState: MonthFilterT = {
  tasks: false,
  courseCorrelationIds: [],
  activityCorrelationIds: [],
  customEvents: false,
  /*TODO: remove*/
  googleEvents: false
};

export type BadgesInfo = {
  badges: string[];
  steps: string[];
  substeps: Substeps[];
  completed: boolean;
};

export type SettingsState = {
  subscription?: SubscriptionInfo;
  userSettings?: UserSettings;
  helpUsDialog: {
    open: boolean;
    confirmAction: any;
    options: any;
    message?: string;
    loadingAction?: any;
  };
  badgesInfo: BadgesInfo | null;
  badgesObviousMike: boolean;
  showOnboardingBar: boolean;
  stepDialogInfo: {
    open: boolean;
    step?: string;
  };
  personalizationDialog: {
    open: boolean;
    callback?: any;
  };
};

export enum calendarStylingType {
  WHITE = 'WHITE',
  GRAY = 'GRAY',
  LIGHT = 'LIGHT',
  FULL = 'FULL'
}
