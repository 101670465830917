import React, { FC, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { DatepickerFormats } from 'shovel-lib/types';

import t from '@i18n/t';
import styled from 'styled-components';
import DatePickerCustomHeader from './DatePickerCustomHeader';
import { Portal } from 'react-overlays';
import { toMomentDate, toSemesterLocalDateTime } from 'shovel-lib/utils/timeUtils';
import { RegularText } from '@utils/typography';
import { minutesToDurationInHours } from '@utils/filters';
import { isMobileApp } from '@utils/screenUtils';

export const TIME_INTERVAL = 15;

type Props = {
  name?: string;
  value?: Date | string | null;
  onChange: (value: Date, timeClicked?: boolean) => void;
  minDate?: Date | string;
  maxDate?: Date | string;
  required?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClose?: () => void;
  onOpen?: () => void;
  className?: string;
  disabled?: boolean;
  inlineInput?: boolean;
  showTimeOnly?: boolean;
  dateFormatFn?: (showYear?: boolean) => string;
  timeIntervals?: number;
  disableTyping?: boolean;
  openToDate?: Date;
  placeholder?: string;
  clearable?: boolean;
  popperPlacement?: string;
  isOpen?: boolean;
  customDayRender?: any;
  footer?: any;
  addMidnight?: boolean;
  startTimesFromIndex?: number;
  hideTime?: boolean;
  inputRef?: any;
  autosizeFormat?: (showYear?: boolean) => string;
  firstTimeDiff?: number;
  popperContainerId?: string;
};

const DatetimePicker: FC<Props> = ({
  name,
  value,
  onBlur,
  onChange,
  minDate,
  maxDate,
  className,
  onClose,
  onOpen,
  required = false,
  disabled,
  inlineInput,
  showTimeOnly,
  dateFormatFn,
  timeIntervals = TIME_INTERVAL,
  openToDate,
  placeholder,
  disableTyping,
  clearable,
  isOpen,
  customDayRender,
  popperPlacement,
  footer,
  addMidnight,
  startTimesFromIndex,
  hideTime,
  autosizeFormat,
  firstTimeDiff,
  popperContainerId = 'root'
}) => {
  const pickerRef = useRef<any>(null);

  const [dateTyping, setDateTyping] = useState<Date | undefined>(undefined);

  useEffect(() => {
    // exclude typing in mobile app on inputs
    if (isMobileApp && pickerRef?.current?.input) {
      pickerRef.current.input.readOnly = true;
    }
  }, [pickerRef]);

  const reorderTimeItems = () => {
    const timeList = document.getElementsByClassName('react-datepicker__time-list')[0];
    if (!timeList) return;
    const timeItems = Array.from(timeList.childNodes);
    const removedElements = timeItems.splice(0, startTimesFromIndex);
    timeList.innerHTML = '';
    if (value && !isNaN(firstTimeDiff!)) {
      // show time difference from selected start date
      let timeDiff = firstTimeDiff || 0;
      [...timeItems, ...removedElements].forEach(t => {
        //@ts-ignore
        t.innerText = `${t.innerText} (${minutesToDurationInHours(timeDiff)})`;
        timeDiff += timeIntervals;
      });
      //@ts-ignore
      timeList.parentNode?.classList?.add('extended-time');
    }
    //@ts-ignore
    timeList.append(...timeItems, ...removedElements);
    const selected = document.getElementsByClassName('react-datepicker__time-list-item--selected')[0];
    if (selected) {
      selected.scrollIntoView();
    }
  };

  const onKeyDown = e => {
    if (e.key === 'Tab' && pickerRef.current) {
      pickerRef.current.setOpen(false);
    }
  };

  const blurInput = e => {
    if (dateTyping) {
      handleChange(dateTyping);
      setDateTyping(undefined);
    }
    onBlur?.(e);
  };

  const handleChange = (date: Date, e?: any) => {
    if (e?.type === 'change') {
      // don't trigger change on every key down, save value to state and trigger change on blur
      setDateTyping(date);
      return;
    }

    if (!date) {
      onChange(date, !e);
      return;
    }

    const utcDateTime = toMomentDate(date);
    utcDateTime.subtract(date.getTimezoneOffset(), 'minutes');

    const localDateTime = toMomentDate(utcDateTime.toISOString().slice(0, -1));
    onChange(localDateTime.toDate(), !e);
    if (e?.code === 'Enter') onClose?.();
  };

  const selected = value ? toSemesterLocalDateTime(value) : null;
  const showYear = !!selected && new Date().getFullYear() !== selected.getFullYear();
  const format = dateFormatFn ? dateFormatFn(showYear) : undefined;
  let defaultFormat = showYear ? DatepickerFormats.FULL : DatepickerFormats.LONG;
  if (showTimeOnly) {
    defaultFormat = DatepickerFormats.TIME;
  }

  const onChangeRaw = e => {
    // prevent exception - don't allow parsing an empty string
    if (!e.target.value) {
      e.target.value = ' ';
    } else if (e.target.value !== ' ' && e.target.value.startsWith(' ')) {
      e.target.value = e.target.value.trimLeft();
    }
  };

  const picker = (
    <Picker
      ref={pickerRef}
      autoComplete={'off'}
      locale={'custom'}
      inlineInput={inlineInput}
      name={name}
      selected={dateTyping || selected}
      onChange={handleChange}
      onChangeRaw={disableTyping ? e => e.preventDefault() : onChangeRaw}
      onCalendarClose={onClose}
      onInputClick={() => {
        onOpen?.();
        if (
          !disableTyping &&
          pickerRef.current &&
          pickerRef.current.input.selectionEnd === pickerRef.current.input.selectionStart
        ) {
          pickerRef.current.input.setSelectionRange(0, pickerRef.current.input.value.length);
        }
      }}
      onCalendarOpen={
        showTimeOnly && startTimesFromIndex
          ? () => {
              reorderTimeItems();
              onOpen?.();
            }
          : onOpen
      }
      onKeyDown={onKeyDown}
      timeIntervals={timeIntervals}
      timeCaption={t.TIME}
      dateFormat={format || defaultFormat}
      minDate={minDate ? toSemesterLocalDateTime(minDate) : undefined}
      maxDate={maxDate ? toSemesterLocalDateTime(maxDate) : undefined}
      required={required}
      openToDate={openToDate}
      placeholderText={placeholder}
      showTimeSelect={!hideTime}
      open={isOpen}
      onClickOutside={isOpen ? onClose : undefined}
      onBlur={blurInput}
      className={className}
      calendarClassName={className}
      renderDayContents={customDayRender}
      showTimeSelectOnly={showTimeOnly}
      formatWeekDay={date => date.substring(0, 1)}
      injectTimes={addMidnight ? [new Date('1900-01-01T23:59:00')] : undefined}
      popperClassName={`date-time-picker${showTimeOnly ? ' time-picker' : ''}`}
      renderCustomHeader={({ date, prevMonthButtonDisabled, decreaseMonth, nextMonthButtonDisabled, increaseMonth }) => (
        <DatePickerCustomHeader
          date={date}
          prevMonthButtonDisabled={prevMonthButtonDisabled}
          decreaseMonth={decreaseMonth}
          nextMonthButtonDisabled={nextMonthButtonDisabled}
          increaseMonth={increaseMonth}
        />
      )}
      popperPlacement={popperPlacement}
      disabled={disabled}
      isClearable={!disabled && clearable}
      disabledKeyboardNavigation={true}
      popperContainer={props => <Portal container={document.getElementById(popperContainerId)}>{props.children}</Portal>}
    >
      {footer}
    </Picker>
  );

  return !autosizeFormat ? (
    picker
  ) : (
    <ValueSizeWrapper>
      <RegularText className={'size-placeholder'}>
        {value ? toMomentDate(value).format(autosizeFormat(showYear)) : '&nbsp;'}
      </RegularText>
      {picker}
    </ValueSizeWrapper>
  );
};

export default DatetimePicker;

const Picker = styled(ReactDatePicker)<{ inlineInput?: boolean }>`
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 1;
  box-sizing: border-box;
  width: 100%;
  ${props => props.inlineInput && 'width: 140px !important'};
  background-color: transparent;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.placeholder};
  }

  &:disabled {
    color: ${props => props.theme.disabledTextColor} !important;
    -webkit-text-fill-color: ${props => props.theme.disabledTextColor};
  }
`;

export const ValueSizeWrapper = styled.div`
  position: relative;
  align-self: flex-start;
  .size-placeholder {
    visibility: hidden;
    white-space: nowrap;
    display: block;
  }

  > *:nth-child(2) {
    // second child is date picker
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    div,
    input {
      width: 100% !important;
      box-sizing: border-box;
      line-height: 1;
      font-size: 13px;
    }
  }
`;
