import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';

import {
  auth,
  semester,
  timer,
  calendar,
  pile,
  event,
  common,
  task,
  terms,
  course,
  settings,
  gpaCalculator,
  notifications,
  workload,
  ics,
  weekCalendar
} from './index';
import { connectRouter } from 'connected-react-router';
import history from '../utils/history';

const reducers = combineReducers({
  router: connectRouter(history),
  auth,
  timer,
  semester,
  calendar,
  pile,
  task,
  course,
  common,
  terms,
  event,
  settings,
  gpaCalculator,
  notifications,
  workload,
  ics,
  weekCalendar
});

export type RootState = StateType<typeof reducers>;

export default reducers;
