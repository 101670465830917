import { Moment } from 'moment';
import {
  ApplyOption,
  CalendarEvent,
  CalendarEvents,
  CalendarEventType,
  CourseDto,
  DatesContainingTasks,
  PileToDoTask,
  SemesterCalendarInfo,
  TaskDto,
  TaskInfoDto
} from 'shovel-lib/types';
import { GoogleCalendar } from '../googleCalendar/types';
import { ExportCourseResponse } from '../../api/lambdaApi';

export enum CourseMode {
  CALENDAR,
  WORKLOAD
}

export enum CalendarView {
  CYCLIC = 'cyclicWeek',
  MONTH = 'dayGridMonth',
  WEEK = 'timeGridWeek',
  LIST = 'listWeek',
  AGENDA = 'listDay'
}

export interface CalendarState {
  readonly startDate: Date | null;
  readonly endDate: Date | null;
  readonly dateFromMobile?: Date;
  readonly scrollToTime?: string;
  readonly view: CalendarView;
  readonly cyclicDayCount: number;
  readonly courses: CourseDto[];
  readonly activities: CourseDto[];
  readonly eventColor: string;
  // readonly selectedCourseId?: number;
  readonly semesterInfo: SemesterCalendarInfo | null;
  readonly plannedEvents: CalendarEvents;
  readonly datesContainingTasks: DatesContainingTasks;
  readonly isDragging: boolean;
  readonly draggingTask: DraggingTask;
  readonly dialog: CalendarDialogState;
  readonly slatHeight: number;
  readonly tasksOnDay: TaskInfoDto[];
  readonly closeActivePopup?: Function;
  readonly googleCalendars: GoogleCalendar[];
  readonly googleEvents: CalendarEvent[];
  readonly eventApplyDialog: { open: boolean; data: ApplyDialogData };
  readonly planBlockDialog: PlanBlockDialogType;
  readonly importCourseDialog: {
    opened: boolean;
    courseId?: number;
    courseCorrelationId?: string;
    courseName?: string;
    courseColorHex?: string;
    data?: ExportCourseResponse;
  };
  readonly planSidebarCollapsed: boolean;
  readonly revertLastAction: number;
}

export enum RepeatFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  CUSTOM_DAYS = 'CUSTOM_DAYS'
}

export type DraggingTask = { taskId: number; dueDate: Moment } | null;

export type CalendarDialogState = {
  opened: boolean;
  hidden?: boolean;
  data: CalendarDialogData;
};

export type CalendarDialogData = {
  eventId?: string;
  eventInfo?: (Event | TaskDto | { start: Date; end: Date } | { id: number }) & {
    colorHex?: string;
    allDay?: boolean;
    backgroundColor?: string;
    textColor?: string;
    title?: string;
    correlationId?: string;
    dayOfWeek?: boolean;
    location?: string;
    description?: string;
    busy?: boolean;
    isRecurring?: boolean;
    commuteBefore?: number;
    commuteAfter?: number;
    repeat?: any;
  };
  eventType?: CalendarEventType;
};

export type StartDraggingTaskActionType = {
  taskId: number;
  dueDate: Moment;
};

export type ChangeCalendarViewActionType = {
  view: CalendarView;
  startDate: Date;
  endDate: Date;
};

export type ApplyDialogData = {
  action: (apply: ApplyOption) => void;
  title: string;
  onCancel?: () => void;
  warning?: boolean;
  dayOfWeek?: string;
  applyPattern?: boolean;
  customOptions?: { label: string; value?: any; strong?: boolean }[];
};

export type GetTasksForPlanningRequest = {
  from: string;
  drop: number;
  plannedEventId?: number;
};

export type GetTasksForPlanningResponse = {
  tasks: Map<Date, PileToDoTask[]>;
};

export type PlanBlockDialogType = {
  opened: boolean;
  event?: any;
  callback?: any;
  plannedEventId?: number;
};
