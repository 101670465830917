export const SUBTITLE_MAX_LENGTH = 2048;
export const SUBTITLE_BE_MAX_LENGTH = 5000;
export const SUBTASK_MAX_LENGTH = 255;
export const DAYS_TO_START_AHEAD_MAX = 100;
export const SHOW_TODO_TASKS_LIMIT = 300;
export const TODO_TASK_PER_PAGE = 10;
export const TITLE_MAX_LENGTH = 255;
export const DEFAULT_TASK_CATEGORY_EMOJI = '📝';
export const DEFAULT_SECONDS_PER_PAGE = 300;
export const PLANNED_TASK_NOTES_MAX_LENGTH = 2048;
export const EMOJI_PICKER = '🥑';

// mark complete animation constants
export const MARK_COMPLETE_BTN_DURATION = 500; //in milliseconds
export const FADE_IN_MOVE_TO_DO_DURATION = 0; // in seconds, must be less than MARK_COMPLETE_BTN_DURATION
export const MARK_COMPLETE_CONFETTI_DURATION = 600; //in milliseconds
export const MARK_COMPLETE_PROGRESS_BAR_DURATION = 0.5; //in seconds (it is best to sum of duration and delay be greater than confetti duration)
export const MARK_COMPLETE_PROGRESS_BAR_DELAY = 0.2; //in seconds

export const BUTTON_ANIMATION_PROPS_ONE = {
  direction: 'right',
  size: 4,
  duration: 1000,
  easing: 'easeInCubic',
  particlesAmountCoefficient: 8,
  speed: 0.5,
  oscillationCoefficient: 1
}; //PAUSE button from example
export const BUTTON_ANIMATION_PROPS_TWO = {
  easing: 'easeOutQuad',
  speed: 1,
  particlesAmountCoefficient: 10,
  oscillationCoefficient: 80
}; //ADD TO CART
