import React, { PureComponent } from 'react';
import ProgressBar from '../../../common/progressBar/ProgressBar';
import { LargeText, MediumText } from '../../../../utils/typography';
import { minutesToDurationInHours, minutesToDurationInHoursNoNegative } from '../../../../utils/filters';
import t from '../../../../i18n/t';
import styled from 'styled-components';
import { Column, Row } from '../../../common/layoutUtils';
import { MINUTES_IN_HOUR } from 'shovel-lib/utils/timeUtils';
import DurationInput from '../../../common/inputs/duration/DurationInput';
import colors from '../../../../utils/colors';
import { screenSizes } from '../../../../utils/screenUtils';
import UpdateTaskState from '@components/task/manage/partials/UpdateTaskState';

type Props = {
  task: any;
  disabled?: boolean;
  error?: boolean;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  addTimeNeed?: (data: { timeNeed: number }) => void;
  totalPagesRead: number;
};

class TaskProgressBar extends PureComponent<Props> {
  state = {
    timeNeedInputValue: null
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (
      this.props.task.timeSpent !== prevProps.task.timeSpent ||
      this.props.task.timeNeed !== prevProps.task.timeNeed ||
      this.props.error
    ) {
      this.setState({ timeNeedInputValue: null });
    }
  }

  saveTimeNeed = (newTimeNeed: number = 0) => {
    if (newTimeNeed === this.props.task.timeNeed) {
      this.setState({ timeNeedInputValue: null });
      return;
    }
    this.props.addTimeNeed?.({ timeNeed: newTimeNeed });
  };

  handleChange = (timeNeedInputValue?: number) => {
    this.setState({ timeNeedInputValue });
  };

  getPagesRead = (pages: number, totalPagesRead: number) => {
    if (pages === 0) {
      return <span className={'aqua-color'}>{totalPagesRead}</span>;
    }

    return (
      <>
        <span className={'aqua-color'}>{totalPagesRead}</span>
        {`/${pages}`}
      </>
    );
  };

  getLeftComponentAndPercentage = () => {
    const { task, disabled, onKeyUp, totalPagesRead } = this.props;
    let label;
    let secondLabel;
    let component;
    let percentage;
    if (!!task.reading) {
      const {
        pages,
        reading: { secondsPerPage }
      } = task;
      const timeNeed = (pages - totalPagesRead) * secondsPerPage;
      const timeSpent = totalPagesRead * secondsPerPage || 0;

      percentage = this.toPercentage(totalPagesRead, pages);
      label = (
        <LabelWrapper>
          <MediumText strong>{t.PAGES_READ.toUpperCase()}</MediumText>
          <LargeText className={'value-component'}>{this.getPagesRead(pages, totalPagesRead)} </LargeText>
        </LabelWrapper>
      );
      secondLabel = (
        <LabelWrapper>
          <MediumText strong>{t.TIME_USED.toUpperCase()}</MediumText>
          <LargeText className={'value-component'}>{minutesToDurationInHours(timeSpent / MINUTES_IN_HOUR)} </LargeText>
        </LabelWrapper>
      );
      component = (
        <LabelWrapper>
          <MediumText strong>{t.STILL_NEEDED.toUpperCase()}</MediumText>
          <LargeText className={'value-component'}>
            {minutesToDurationInHoursNoNegative(Math.ceil(timeNeed / MINUTES_IN_HOUR))}{' '}
          </LargeText>
        </LabelWrapper>
      );
    } else {
      const { timeNeed, timeSpent } = task;
      const { timeNeedInputValue } = this.state;
      const timeSpentValue = timeSpent || 0;
      const timeNeedValue = (timeNeedInputValue !== null ? timeNeedInputValue : timeNeed) || 0;
      percentage = ((timeSpentValue * 100) / ((timeSpent || 0) + timeNeed)).toFixed(2);
      label = (
        <LabelWrapper>
          <MediumText strong>{t.TIME_USED.toUpperCase()}</MediumText>
          <LargeText className={'value-component'}>
            <span className={'aqua-color'}>{minutesToDurationInHours(timeSpentValue)}</span>
          </LargeText>
        </LabelWrapper>
      );
      component = (
        <LabelWrapper>
          <MediumText strong>{t.STILL_NEEDED.toUpperCase()}</MediumText>
          <TimeNeedInput
            name={'timeNeed'}
            noErrorMargin
            onBlur={(newTimeNeed?: number) => {
              newTimeNeed !== timeNeed && this.saveTimeNeed(newTimeNeed);
            }}
            onChange={this.handleChange}
            value={timeNeedValue!}
            onKeyUp={onKeyUp}
            required={false}
            inline
            disabled={disabled}
            big
          />
        </LabelWrapper>
      );
    }
    const leftComponent = (
      <Component left>
        {label}
        {secondLabel}
        {component}
      </Component>
    );
    return { leftComponent, percentage };
  };

  toPercentage = (pagesRead: number, totalPages: number) => ((pagesRead * 100) / totalPages).toFixed(2);

  renderRightComponent = () => {
    const { task } = this.props;

    return <UpdateTaskState task={task} />;
  };

  render() {
    const { leftComponent, percentage } = this.getLeftComponentAndPercentage();
    const rightComponent = this.renderRightComponent();
    return (
      <CustomProgressBar
        leftComponent={leftComponent}
        rightComponent={rightComponent}
        percentage={percentage}
        percentageIncludingSession={'0'}
        className={'task-progress-bar'}
        progressColor={colors.aqua}
        noBorderRadius
      />
    );
  }
}

export default TaskProgressBar;

const CustomProgressBar = styled(ProgressBar)`
  width: 100%;
  height: 65px;
`;
const TimeNeedInput = styled(DurationInput)`
  span {
    font-size: 16px;
    font-weight: bold !important;
  }
`;
const Component = styled(Row)<{ left?: boolean }>`
  ${props => (props.left ? 'padding-left: 20px' : 'padding-right: 60px')};
  @media (max-width: ${screenSizes.medium}) {
    ${props => !props.left && 'padding-right: 20px'};
  }
  box-sizing: border-box;
  cursor: default;
`;
const LabelWrapper = styled(Column)`
  align-items: center;
  margin-right: 20px;
  > span:first-of-type {
    color: ${props => props.theme.textColor};
  }
  .value-component {
    padding: 4px 0px;
    &:not(.aqua-color) {
      color: ${props => props.theme.textStrongColor};
    }
    .aqua-color {
      color: ${colors.aqua};
    }
  }
`;
