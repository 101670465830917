import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { RegularTextInput } from '../../utils/typography';
import { FlexRow } from '../common/layoutUtils';
import { isMobileApp } from '../../utils/screenUtils';
import { SidebarItemType } from '@state/workload/types';

type Props = {
  id?: number;
  form: {
    values: any;
    isValid: boolean;
    dirty: boolean;
    handleChange: any;
    handleReset: any;
    handleSubmit: any;
    handleBlur?: any;
    setFieldValue: any;
    errors?: any;
    touched?: any;
  };
  type: SidebarItemType;
  textColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  showBorder?: boolean;
  placeholder?: string;
  theme: any;
  switchToDragMode: () => void;
  delete: () => void;
  isTaskList?: boolean;
};

class InputItem extends Component<Props> {
  saveIfValid = (
    isValid: boolean,
    handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void,
    handleReset: (e?: React.FormEvent<HTMLFormElement>) => void
  ) => {
    if (isValid) {
      handleSubmit();
      this.props.switchToDragMode();
    } else {
      if (this.props.id) {
        handleReset();
        this.props.switchToDragMode();
      } else {
        this.props.delete();
      }
    }
  };

  getBackgroundColor = () => {
    const {
      backgroundColor,
      form: { values },
      type,
      theme
    } = this.props;
    if (backgroundColor) {
      return backgroundColor;
    }
    if (type === SidebarItemType.TASK_CATEGORY) {
      return theme.background;
    }

    return theme.courseColorFunction(values.colorHex);
  };

  render() {
    const { id, form, textColor, borderColor, showBorder, placeholder, switchToDragMode, isTaskList, type } = this.props;

    const { values, dirty, isValid, handleChange, handleSubmit, handleReset } = form;

    const Wrapper = !id ? NewItemCreationWrapper : React.Fragment;

    return (
      <Wrapper>
        <NameInputWrapper
          backgroundColor={this.getBackgroundColor()}
          showThinBorder={showBorder || type === SidebarItemType.TASK_CATEGORY}
          borderColor={borderColor || values.colorHex}
          style={id ? { marginLeft: 20 } : {}}
        >
          <NameInput
            name="name"
            isTaskList={isTaskList}
            value={values.name}
            onChange={handleChange}
            color={isTaskList ? values.colorHex : textColor || values.colorHex}
            onBlur={() => {
              if (!id && values.name === '') {
                this.props.delete();
                return;
              }
              if (dirty) {
                this.saveIfValid(isValid, handleSubmit, handleReset);
              } else {
                switchToDragMode();
              }
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                if (dirty) {
                  this.saveIfValid(isValid, handleSubmit, handleReset);
                } else {
                  handleReset();
                  switchToDragMode();
                }
                e.preventDefault();
              }
              if ((e.key === 'Backspace' || e.key === 'Escape') && !id && values.name === '') {
                this.props.delete();
              }
            }}
            autoFocus
            placeholder={placeholder}
          />
        </NameInputWrapper>
      </Wrapper>
    );
  }
}

export default withTheme(InputItem);

export const NameInputWrapper = styled(FlexRow)<{
  backgroundColor: string;
  showThinBorder?: boolean;
  showLeftBorder?: boolean;
  borderColor?: string;
}>`
  border-radius: 5px;
  background-color: ${props => props.backgroundColor};
  padding-left: 10px;

  ${props =>
    props.showLeftBorder &&
    `
     border-left: 3px solid ${props.borderColor};
     border-width: 0 0 0 3px !important;
  `}
  ${props =>
    props.showThinBorder &&
    `
      border: 1px solid ${props.borderColor};
  `}
`;

export const NameInput = styled(RegularTextInput)<{
  color: string;
  onBlur: any;
  isTaskList?: boolean;
}>`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  border-width: 0;
  border-radius: 5px;
  background-color: transparent;
  outline: none;
  padding: ${props => (!props.isTaskList ? '4px 5px 4px 0' : '6px 5px 6px 0')};
  color: ${props => props.color};
  font-size: 13px;
  overflow: hidden;

  &::placeholder {
    color: ${props => props.color};
    opacity: 0.5;
  }
  ${isMobileApp &&
    `font-size: 14px;
     padding: 7px 5px 7px 0;
    `}
`;

export const NewItemCreationWrapper = styled.div`
  width: 100%;
  margin-left: 20px;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.primary};
  ${({ theme }) => `box-shadow: 0 0 2px ${theme.boxShadow}, 0 2px 2px ${theme.boxShadow};`}
`;
