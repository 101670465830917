import { actionCreator, asyncActionsCreator } from '../common/utils';
import {
  ChangeTermRequest,
  CountryResponse,
  CreateBatchActivitiesRequest,
  SemesterStatus,
  SetAwakeTimesRequest,
  SetupTermRequest,
  TermResponse,
  ThinActivityDto,
  ThinCourseDto,
  TimezoneResponse
} from 'shovel-lib/types';
import { PlatformEvent } from '@state/googleCalendar/types';

const asyncActions = asyncActionsCreator('@@term');
const action = actionCreator('@@term');

type ParsedCourse = {
  id?: number;
  parsedName: string;
  url: string;
  type: string;
};

type OnboardingCourseDto = {
  id: number;
  name: string;
  colorHex: string;
  correlationId?: string;
  ordinalNumber?: number;
  parsedCourses: ParsedCourse[];
  events: PlatformEvent[];
};

type BatchOnboardingCoursesRequest = {
  courses: OnboardingCourseDto[];
  triggerUpdate: boolean;
};

export const increaseTermStep = action<undefined>('INCREASE_TERM_STEP');

export const findCountries = asyncActions<void, CountryResponse, string>('FIND_COUNTRIES');

export const findTimezonesByCountry = asyncActions<any, TimezoneResponse, string>('FIND_TIMEZONES_BY_COUNTRY');

export const setupTerm = asyncActions<SetupTermRequest & { countryCode?: string }, TermResponse, string>('SETUP_TERM');

export const updateTerm = asyncActions<SetupTermRequest, TermResponse, string>('UPDATE_TERM');

export const changeTermState = asyncActions<ChangeTermRequest & { isFirstSemester?: boolean }, SemesterStatus, string>(
  'CHANGE_STATE'
);

export const possibleTermStateChange = action<{
  fromState: SemesterStatus;
  toState: SemesterStatus;
  isDemo?: boolean;
  isFirstSemester?: boolean;
}>('POSSIBLE_TERM_STATE_CHANGE');

export const updateMinimumStudyTimeBlock = asyncActions<number, number, string>('UPDATE_MINIMUM_STUDY_BLOCK');

export const deleteTerm = asyncActions<number, number, string>('DELETE_TERM');

export const setAwakeTimes = asyncActions<SetAwakeTimesRequest, any, string>('SET_AWAKE_TIMES');

export const createBatchCourses = asyncActions<BatchOnboardingCoursesRequest, ThinCourseDto[], string>(
  'CREATE_BATCH_COURSES'
);

export const createBatchActivities = asyncActions<CreateBatchActivitiesRequest, ThinActivityDto[], string>(
  'CREATE_BATCH_ACTIVITIES'
);

export const createDefaultActivities = asyncActions<void, ThinActivityDto[], string>('CREATE_DEFAULT_ACTIVITIES');

export const copyActivitiesFromPreviousSemester = asyncActions<boolean, ThinActivityDto[], string>(
  'COPY_ACTIVITIES_FROM_PREVIOUS_SEMESTER'
);
