import React, { FC, PureComponent, useState } from 'react';
import $ from 'jquery';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import { MediumText, MiniText, SmallText } from '../../../utils/typography';
import { Column, Row, TextOverflowDiv } from '../../common/layoutUtils';
import { TextareaInput } from '../../common/inputs';
import DurationInput from '../../common/inputs/duration/DurationInput';
import t from '../../../i18n/t';
import MaterialIcon from '../../common/icons/MaterialIcon';
import { MARK_COMPLETE_CONFETTI_DURATION, TITLE_MAX_LENGTH } from '../../../utils/constants/task';
import { minutesToDurationInHoursNoNegative } from '../../../utils/filters';
import { TaskState, WorkloadTask } from 'shovel-lib/types';
import Tooltip from '../../common/tooltip/Tooltip';
import { ManagePageRanges, ManageSubtasks } from '../../task/manage/partials';
import Popup from 'reactjs-popup';
import { popupContentStyle } from '../../../utils/popupUtils';
import { pageRangesToAdditionalText } from '../../../utils/taskUtils';
import TippyTooltip from '../../common/tooltip/TippyTooltip';
import CheckBoxConfetti from '../../common/animations/CheckBoxConfetti';
import StartAhead from '../../task/manage/partials/StartAhead';
import { EmojiPreview } from '@components/common/emoji/EmojiPreview';

type RowProps = {
  openTask?: any;
  value?: any;
  save: (data: any, taskId?: number) => void;
  theme?: any;
} & WorkloadTask;

const CellPopup = ({
  trigger,
  content,
  contentStyle,
  disabled
}: {
  className?: string;
  trigger: any;
  content: any;
  contentStyle?: any;
  disabled?: boolean;
}) => {
  return (
    <Popup
      className={`cell-popup`}
      trigger={trigger}
      arrow={false}
      overlayStyle={{ zIndex: 4 }}
      contentStyle={{ ...popupContentStyle, padding: 5, ...contentStyle, zIndex: 4 }}
      position={'bottom left'}
      disabled={disabled}
    >
      {close => (content instanceof Function ? content(close) : content)}
    </Popup>
  );
};

const DaysAheadCell: FC<RowProps> = props => {
  const { taskId, save, dueDate } = props;
  const value = props.value || props.daysToStartAhead;

  const update = (e: any) => {
    const daysToStartAhead: any = parseInt(e.target.value);
    if (daysToStartAhead >= 0 && value !== daysToStartAhead) {
      save({ daysToStartAhead }, taskId!);
    }
  };

  return (
    <StartAhead
      workload
      calendarClassName={'workload-due-date'}
      dueDate={dueDate}
      startAhead={value}
      save={data => save(data, taskId!)}
      activeComponent={
        <TextOverflowDiv>
          {value} {value! >= 0 && (value === 1 ? t.DAY : t.DAYS)}
        </TextOverflowDiv>
      }
    />
  );
};

const PageRangesCell: FC<RowProps> = ({ pageRanges, taskId, save }) => {
  return (
    <CellPopup
      contentStyle={{ width: 160, paddingBottom: 5 }}
      trigger={<TextOverflowDiv>{pageRangesToAdditionalText(pageRanges) || <SmallText>-</SmallText>}</TextOverflowDiv>}
      content={<ManagePageRanges pageRanges={pageRanges} taskId={taskId} autofocus save={save} />}
    />
  );
};

const TimeDurationCell: FC<RowProps & { fieldName: string; isDisabled?: boolean; tooltip?: string }> = props => {
  const { save, taskId, fieldName, isDisabled, tooltip } = props;
  const value = props.value || props.timeNeed;

  const [inputValue, setInputValue] = useState<number | null | undefined>(null);

  const handleChange = (inputValue?: number) => {
    setInputValue(inputValue);
  };

  const setTimeNeed = (newValue: number = 0) => {
    if (newValue === value) {
      setInputValue(null);
      return;
    }
    save({ [fieldName]: newValue }, taskId);
  };

  const valueText = minutesToDurationInHoursNoNegative(value);
  const el = <TextOverflowDiv isDisabled={isDisabled}>{valueText}</TextOverflowDiv>;
  return (
    <CellPopup
      contentStyle={{ width: '100%', minWidth: 80, padding: 0, display: 'flex' }}
      trigger={tooltip ? <TippyTooltip target={el} content={tooltip} preformattedText /> : el}
      disabled={isDisabled}
      content={close => (
        <TimeNeedInput
          noErrorMargin
          autofocus
          onBlur={setTimeNeed}
          onChange={handleChange}
          value={inputValue !== null ? inputValue : value}
          inline
          onKeyUp={e => {
            if (e.key === 'Enter') {
              $(e.target).blur();
              close();
            }
          }}
          disabled={isDisabled}
        />
      )}
    />
  );
};

const MarkCompleteCell: FC<RowProps & { label?: string; setCompletedTaskId: any }> = ({
  save,
  taskId,
  state,
  daysToStartAhead,
  dueDate,
  label,
  setCompletedTaskId
}) => {
  const completed = state === TaskState.COMPLETED;
  return (
    <Tooltip
      target={
        <CompleteIconRow>
          <CheckBoxConfetti
            onClick={() => {
              if (completed) {
                save({ taskId, data: { daysAhead: daysToStartAhead, dueDate } });
              } else {
                setCompletedTaskId(taskId);
                save({ taskId, data: { state: TaskState.COMPLETED }, callback: () => setCompletedTaskId(null) });
              }
            }}
            isChecked={completed}
            animationDuration={MARK_COMPLETE_CONFETTI_DURATION}
          />
          {label && <MediumText style={{ marginLeft: 5 }}>{label}</MediumText>}
        </CompleteIconRow>
      }
      position={'top right'}
      content={completed ? t.REVERT_TO_INCOMPLETE : t.MARK_COMPLETE}
    />
  );
};

class NameCell extends PureComponent<RowProps & { error?: string; touched?: boolean; isRepeatTasks }> {
  save = name => {
    const value = this.props.value || this.props.title;
    return name !== value && this.props.save({ title: name }, this.props.id!);
  };

  addOrUpdateSubtask = (data, oldData) => {
    const subtaskIndex = this.props.subtasks.findIndex(s => s === oldData);
    const subtasks = [...this.props.subtasks];
    subtasks.splice(subtaskIndex !== -1 ? subtaskIndex : subtasks.length, 1, data);
    this.props.save({ subtasks });
  };

  deleteSubtask = oldData => {
    this.props.save({ subtasks: this.props.subtasks.filter(s => s !== oldData) });
  };

  render() {
    const { title, taskId, subtasks = [], error, touched, openTask, state, isRepeatTasks } = this.props;
    const value = this.props.value || title;
    const active = subtasks.filter(s => !s.completed);
    const completed = subtasks.filter(s => s.completed);
    const hasActive = active.length > 0;
    const hasCompleted = completed.length > 0;

    return (
      <NameWrapper hasError={error && touched} hasCompleted={hasCompleted} hasActive={hasActive || isRepeatTasks}>
        <CellPopup
          contentStyle={{ padding: 0 }}
          trigger={
            <TextOverflowDiv title={value} taskList>
              {state === TaskState.DO_LATER && <EmojiPreview emoji={'🔥'} />}
              {value}
            </TextOverflowDiv>
          }
          content={close => (
            <NameInput
              bare
              autofocus
              oneLine
              minRows={1}
              maxRows={4}
              placeholder={t.ADD_NAME}
              value={value}
              maxLength={TITLE_MAX_LENGTH}
              onBlur={this.save}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  $(e.target).blur();
                  close();
                }
              }}
              onFocus={() => this.setState({ showCompleted: false, showActive: false })}
            />
          )}
        />
        {openTask && taskId && (
          <OpenTask onClick={openTask} className={'open-task'}>
            <MiniText>{t.OPEN}</MiniText>
            <MaterialIcon name={'launch'} size={14} />
          </OpenTask>
        )}
        {!hasActive && (
          <CellPopup
            contentStyle={{ paddingLeft: 15, width: 250 }}
            content={
              <ManageSubtasks
                taskId={taskId}
                inline
                subtasks={active}
                autoFocus
                customAddOrUpdate={taskId ? undefined : this.addOrUpdateSubtask}
                customDelete={taskId ? undefined : this.deleteSubtask}
                noScroll
              />
            }
            trigger={
              <Column>
                <TippyTooltip
                  content={t.ADD_A_SUBTASK}
                  target={
                    <AddSubtask>
                      <MaterialIcon color={colors.primaryPurple} name={'add_circle_outline'} size={16} />
                    </AddSubtask>
                  }
                />
              </Column>
            }
          />
        )}
        {hasCompleted && (
          <CellPopup
            contentStyle={{ paddingLeft: 15, width: 250 }}
            content={
              <ManageSubtasks
                taskId={taskId}
                inline
                noInput
                subtasks={completed}
                customAddOrUpdate={taskId ? undefined : this.addOrUpdateSubtask}
                customDelete={taskId ? undefined : this.deleteSubtask}
              />
            }
            trigger={
              <Column>
                <TippyTooltip
                  content={t.VIEW_COMPLETED_SUBTASKS}
                  target={<SubtasksCount color={colors.white}>{completed.length}</SubtasksCount>}
                />
              </Column>
            }
          />
        )}
        {hasActive && (
          <CellPopup
            contentStyle={{ paddingLeft: 15, width: 250 }}
            content={
              <ManageSubtasks
                taskId={taskId}
                inline
                subtasks={active}
                autoFocus
                customAddOrUpdate={taskId ? undefined : this.addOrUpdateSubtask}
                customDelete={taskId ? undefined : this.deleteSubtask}
                noScroll
              />
            }
            trigger={
              <Column>
                <TippyTooltip
                  content={t.VIEW_ACTIVE_SUBTASKS}
                  target={
                    <SubtasksCount active color={colors.white}>
                      {active.length}
                    </SubtasksCount>
                  }
                />
              </Column>
            }
          />
        )}
      </NameWrapper>
    );
  }
}

const DuplicateCell: FC<{ task: WorkloadTask; courses: any; createTasks: any; resetWorkload?: any }> = ({
  task,
  courses,
  createTasks,
  resetWorkload
}) => {
  if (task.state !== TaskState.COMPLETED) {
    return (
      <TippyTooltip
        target={
          <div>
            <DuplicateIcon
              className={'duplicate_icon'}
              name={'content_copy'}
              size={14}
              onClick={() => {
                const taskCourse = courses.find(c => c.id === task.courseId);
                const tasks = [
                  {
                    name: task.title,
                    details: task.details,
                    courseId: task.courseId,
                    subtasks: task.subtasks.map(s => ({
                      content: s.content,
                      completed: s.completed,
                      ordinalNumber: s.ordinalNumber
                    })),
                    taskCategoryId: task.taskCategory?.id,
                    readingSourceId: task.reading?.id,
                    dueDate: task.dueDate,
                    daysToStartAhead: task.daysToStartAhead,
                    timeNeed: task.reading ? task.timeNeed : task.timeNeed + task.timeSpent,
                    pageRanges: task.pageRanges,
                    automaticEstimation: !!task.reading
                  }
                ];
                resetWorkload();
                createTasks({
                  tasks,
                  // @ts-ignore
                  colorHex: taskCourse?.colorHex,
                  courseCorrelationId: taskCourse?.correlationId,
                  title: taskCourse?.name
                });
              }}
              outline
            />
          </div>
        }
        content={t.DUPLICATE}
      />
    );
  }
  return null;
};

export { DaysAheadCell, PageRangesCell, TimeDurationCell, MarkCompleteCell, NameCell, DuplicateCell };

const OpenTask = styled(Row)`
  visibility: hidden;
  padding: 4px;
  cursor: pointer;
  ${props => `box-shadow: 0px 0px 2px ${props.theme.boxShadow}, 0px 2px 2px ${props.theme.boxShadow};`};
  ${MiniText} {
    margin-right: 6px;
    word-break: normal;
  }
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 5px;
  transition: color 0.2s ease;
  &:hover {
    > span,
    i {
      color: ${props => props.theme.primary};
    }
  }
`;

const NameInput = styled(TextareaInput)`
  pre,
  textarea {
    padding: 8px 9px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: none !important;
  }
`;

export const AddSubtask = styled(Column)`
  margin-left: 2px;
  visibility: hidden;
  &:hover {
    opacity: 0.75;
  }
  transition: opacity 0.2s ease;
`;

export const NameWrapper = styled(Row)<{ hasError?: any; hasActive?: boolean; hasCompleted?: boolean }>`
  background: inherit;
  padding-right: ${props => (props.hasActive ? 6 : 5)}px;
  justify-content: flex-end;
  position: relative;
  min-height: ${props => (props.hasError ? 'calc(100% - 1px)' : '100%')};
  height: ${props => (props.hasError ? 'calc(100% - 1px)' : '100%')};
  ${props => props.hasError && `border: 1px solid ${colors.negative};`};
  > ${TextOverflowDiv} {
    flex: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${props => (props.hasActive && props.hasCompleted ? 30 : props.hasActive || props.hasCompleted ? 15 : 0)}px;
    left: 0;
    &:hover {
      z-index: 1;
    }
  }
  .cell-popup-content {
    * {
      background-color: transparent !important;
    }
  }
`;

const SubtasksCount = styled(MiniText)<{ active?: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: inline-block;
  margin-left: 2px;
  &:last-of-type:not(:first-of-type) {
    margin-left: 5px;
  }
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: unset;
  background: ${props => (props.active ? colors.gray : colors.primaryPurple)};
`;

const TimeNeedInput = styled(DurationInput)`
  flex: 1;
  * {
    font-size: 12px !important;
    line-height: 1.5 !important;
    background-color: transparent !important;
  }
  input {
    box-shadow: none !important;
  }
`;

const CompleteIconRow = styled(Row)`
  max-height: 20px;
  position: relative;
  .hidden-check-icon {
    position: absolute;
    visibility: hidden;
  }
  &:hover .hidden-check-icon {
    visibility: visible;
  }

  .mark-complete-icon:hover {
    color: ${colors.positive};
  }
`;

const DuplicateIcon = styled(MaterialIcon)`
  visibility: hidden;
  transition: color 0.2s ease;
  :hover {
    color: ${props => props.theme.primary};
  }
`;
