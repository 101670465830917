import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import colors from '../../utils/colors';
import { RegularText } from '../../utils/typography';
import { Row } from './layoutUtils';
import MaterialIcon from './icons/MaterialIcon';

type Props = {
  name?: string;
  label?: string;
  color?: string;
  checked?: boolean;
  onMouseDown?: any;
  onChange?: (clickData?: any, event?: any) => void;
  clickData?: any;
  disabled?: boolean;
  className?: string;
  labelStyle?: any;
  theme: any;
  iconTextColor?: string;
  breakLine?: boolean;
  indeterminate?: boolean;
};

class CheckBox extends Component<Props> {
  handleClick = e => {
    const { onChange, disabled, clickData } = this.props;
    if (onChange && !disabled) onChange(clickData, e);
  };

  render() {
    const {
      name,
      label,
      color,
      checked,
      disabled,
      className,
      labelStyle,
      onMouseDown,
      theme = {},
      iconTextColor,
      breakLine,
      indeterminate
    } = this.props;

    return (
      <Container
        data-cy={name}
        className={className}
        disabled={disabled}
        onClick={this.handleClick}
        onMouseDown={onMouseDown}
      >
        <Wrapper color={indeterminate || checked ? iconTextColor : ''}>
          <MaterialIcon
            name={checked ? 'check_box' : indeterminate ? 'indeterminate_check_box' : 'check_box_outline_blank'}
            color={disabled ? theme.captures : !checked && !indeterminate ? theme.textColor : color || colors.primaryPurple}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          />
        </Wrapper>
        {label && (
          <Label
            style={labelStyle}
            color={disabled ? theme.captures : undefined}
            strong={indeterminate || checked}
            breakLine={breakLine}
            className={'checkbox-label'}
          >
            {label}
          </Label>
        )}
      </Container>
    );
  }
}

const Label = styled(RegularText)<{ breakLine?: boolean }>`
  flex: 1;
  transition: all 0.2s ease;
  margin-left: 8px;
  text-overflow: ellipsis;
  ${props => !props.breakLine && 'white-space: nowrap;'};
  overflow: hidden;
  line-height: 1.4;
`;

const Container = styled(Row)<{ disabled?: boolean }>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  overflow: hidden;
  min-width: 20px;
  align-items: flex-start;
  &:hover {
    ${props =>
      !props.disabled &&
      `${Label} {
          opacity: 0.75;
      }`}
  }
  transition: all 0.2s ease;
`;

const Wrapper = styled.div<{ color?: string }>`
  ${props => props.color && `background-color: ${props.color}`};
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-left: 1px;
  margin-top: 1px;
  i {
    position: absolute;
    top: -2px;
    left: -2px;
  }
`;

export default withTheme(CheckBox);
