import React, { FC, useState } from 'react';
import { TaskState } from 'shovel-lib/types';
import colors from '../../utils/colors';
import t from '../../i18n/t';
import { MiniText } from '../../utils/typography';
import { Row } from '../common/layoutUtils';
import MaterialIcon from '../common/icons/MaterialIcon';
import TippyTooltip from '../common/tooltip/TippyTooltip';
import { CushionIcon } from './TaskCard.styles';
import { getCushionItemColor } from '../../utils/taskUtils';
import { minutesToDurationInHours } from '../../utils/filters';
import storage from '../../utils/storage';
import CompletedOnTag from './partials/CompletedOnTag';
import { hasValue } from '../../utils/validators';
import { Moment } from 'moment';
import { now } from 'shovel-lib/utils/timeUtils';
import { EmojiPreview } from '@components/common/emoji/EmojiPreview';

type Props = {
  state: TaskState;
  timeNeed: number;
  completedOn?: string;
  cushion?: number;
  isPile?: boolean;
  due?: Moment;
  isGraph?: boolean;
  theme?: any;
  noTooltip?: boolean;
};

const TaskStatus: FC<Props> = ({ state, completedOn, timeNeed, cushion, isPile, isGraph, theme, due, noTooltip }) => {
  const [cushionPercentage] = useState(storage.getCushionWarningPercentage);

  if (state === TaskState.COMPLETED) {
    if (completedOn) {
      return <CompletedOnTag completedOn={completedOn} />;
    } else
      return (
        <Row>
          <MaterialIcon name={'check'} color={colors.positive} size={13} />
          <MiniText color={colors.positive}>{t.COMPLETED}</MiniText>
        </Row>
      );
  }

  let target;
  let content;
  let width;

  if (hasValue(cushion)) {
    const color = getCushionItemColor(cushion!, timeNeed, cushionPercentage / 100);
    content = t.CUSHION;
    target = (
      <Row>
        <CushionIcon
          name={'insights'}
          size={13}
          color={color}
          backgroundColor={theme ? theme.courseColorFunction(color) : undefined}
        />
        <MiniText color={color} style={{ marginLeft: 2 }}>
          {minutesToDurationInHours(cushion!)}
        </MiniText>
      </Row>
    );
  }

  if (state === TaskState.ACTIVE && isPile && !!due && !hasValue(cushion)) {
    content = t.CALCULATE_CUSHION_MESSAGE;
    width = '180px';
    target = (
      <span>
        <CushionIcon name={'insights'} size={13} color={colors.gray} />
      </span>
    );
  }

  if (state === TaskState.DO_LATER) {
    target = <EmojiPreview emoji={'🔥'} size={10} />;
    content = `${now().from(due, true)} ${t.OVERDUE.toLowerCase()}`;
  }

  if (!target) return null;
  if (noTooltip) return target;

  return <TippyTooltip target={target} content={content} width={width} />;
};

export default TaskStatus;
