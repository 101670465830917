import { createReducer } from 'typesafe-actions';

import * as actions from '../course/actions';
import { CourseState } from './types';
import { reduceArrayToObjectById, sortByOrdinalNumberAsc } from '../../utils/reducerHelpers';
import { CourseGradeResponse, GradeDto, GradeWeightType } from 'shovel-lib/types';
import { deleteReadingSourceFilter, deleteTaskCategoryFilter } from '../../utils/taskUtils';
import { calendarActions } from '../calendar';
import * as gradeActions from './grade/actions';
import * as teacherActions from './teacher/actions';
import { fixOrdering } from '../common/utils';
import { workloadActions } from '../workload';

const initialState: CourseState = {
  taskCategories: [],
  readingSources: [],
  grade: {} as CourseGradeResponse,
  teachers: {},
  courseDetailsDialog: { open: false }
};

const reducer = createReducer(initialState)
  .handleAction(calendarActions.updateCourse.success, (state, action) => {
    if (state.id !== action.payload.id) return state;
    return {
      ...state,
      name: action.payload.name,
      colorHex: action.payload.colorHex
    };
  })
  .handleAction(actions.getCourse.success, (state, action) => ({
    ...state,
    ...action.payload
  }))
  .handleAction(calendarActions.createCourse.success, (state, action) => {
    return {
      ...state,
      ...action.payload
    };
  })
  .handleAction(actions.createReadingSource.success, (state, action) => ({
    ...state,
    readingSources: [...state.readingSources, action.payload]
  }))
  .handleAction(actions.createTaskCategory.success, (state, action) => ({
    ...state,
    taskCategories: [...state.taskCategories, action.payload]
  }))
  .handleAction(actions.updateReadingSource.success, (state, action) => ({
    ...state,
    readingSources: sortByOrdinalNumberAsc([
      ...state.readingSources.filter(tc => tc.id !== action.payload.id),
      action.payload
    ])
  }))
  .handleAction(actions.updateTaskCategory.success, (state, action) => ({
    ...state,
    taskCategories: sortByOrdinalNumberAsc([
      ...state.taskCategories.filter(tc => tc.id !== action.payload.id),
      action.payload
    ])
  }))
  .handleAction(actions.deleteReadingSource.success, (state, action) => {
    const filterFn = deleteReadingSourceFilter(action.payload.id);
    const readingSources = state.readingSources.filter(filterFn);
    fixOrdering(readingSources);
    return {
      ...state,
      readingSources
    };
  })
  .handleAction(actions.deleteTaskCategory.success, (state, action) => {
    const filterFn = deleteTaskCategoryFilter(action.payload.id);
    const taskCategories = state.taskCategories.filter(filterFn);
    fixOrdering(taskCategories);
    return {
      ...state,
      taskCategories
    };
  })
  .handleAction(gradeActions.getGrades.success, (state, action) => {
    return {
      ...state,
      grade: action.payload
    };
  })
  .handleAction(gradeActions.createGrade.success, (state, action) => {
    const { gradeId, gradeUnitId, percentage, overallGrade } = action.payload;

    const updatedGradeUnits = [...state.grade.gradeUnits];

    const index = updatedGradeUnits.map(gu => gu.id).indexOf(gradeUnitId);

    updatedGradeUnits[index].grades = [
      { id: gradeId, gradeUnitId, percentage } as GradeDto,
      ...updatedGradeUnits[index].grades.map(g => ({ ...g, percentage: g.dropped ? 0 : percentage }))
    ];

    return {
      ...state,
      grade: {
        ...state.grade,
        overallGrade,
        gradeUnits: updatedGradeUnits
      }
    };
  })
  .handleAction(gradeActions.updateGrade.success, (state, action) => {
    const { payload } = action;
    const updatedGradeUnits = [...state.grade.gradeUnits];

    for (const gu of updatedGradeUnits) {
      const grade = gu.grades.find(g => g.id === payload.id);
      if (grade) {
        grade.achievedPercentage = payload.achievedPercentage;
        grade.achievedPoints = payload.achievedPoints;
        grade.points = payload.points;
        break;
      }
    }

    return {
      ...state,
      grade: {
        ...state.grade,
        overallGrade: payload.overallGrade,
        gradeUnits: updatedGradeUnits
      }
    };
  })
  .handleAction(gradeActions.updateGradeInfo.success, (state, action) => {
    const { id, name, date } = action.payload;

    const updatedGradeUnits = [...state.grade.gradeUnits];

    for (const gu of updatedGradeUnits) {
      const grade = gu.grades.find(g => g.id === id);
      if (grade) {
        grade.name = name;
        grade.date = date;
        break;
      }
    }

    return {
      ...state,
      grade: {
        ...state.grade,
        gradeUnits: updatedGradeUnits
      }
    };
  })
  .handleAction(gradeActions.toggleGradeDrop.success, (state, action) => {
    const { gradeId, percentage, overallGrade } = action.payload;

    const updatedGradeUnits = [...state.grade.gradeUnits];

    for (const gu of updatedGradeUnits) {
      const grade = gu.grades.find(g => g.id === gradeId);
      if (grade) {
        grade.dropped = !grade.dropped;
        gu.grades = gu.grades.map(g => ({ ...g, percentage: g.dropped ? 0 : percentage }));
        break;
      }
    }

    return {
      ...state,
      grade: {
        ...state.grade,
        overallGrade: overallGrade,
        gradeUnits: updatedGradeUnits
      }
    };
  })
  .handleAction(gradeActions.deleteGrade.success, (state, action) => {
    const { gradeId, percentage, overallGrade } = action.payload;

    const updatedGradeUnits = [...state.grade.gradeUnits];

    for (const gu of updatedGradeUnits) {
      const index = gu.grades.map(g => g.id).indexOf(gradeId);
      if (index !== -1) {
        gu.grades = gu.grades
          .filter(g => g.id !== gradeId)
          .map(g => ({
            ...g,
            percentage: g.dropped ? 0 : percentage
          }));
        break;
      }
    }

    return {
      ...state,
      grade: {
        ...state.grade,
        overallGrade: overallGrade,
        gradeUnits: updatedGradeUnits
      }
    };
  })
  .handleAction(actions.toggleGradeWeightType.success, (state, actions) => {
    return {
      ...state,
      gradeWeightType:
        state.gradeWeightType === GradeWeightType.PERCENTAGES ? GradeWeightType.POINTS : GradeWeightType.PERCENTAGES
    };
  })
  .handleAction(teacherActions.getTeachers.success, (state, action) => {
    return {
      ...state,
      teachers: reduceArrayToObjectById(action.payload)
    };
  })
  .handleAction(teacherActions.createTeacher.success, (state, action) => {
    return {
      ...state,
      teachers: {
        ...state.teachers,
        [action.payload.id]: action.payload
      }
    };
  })
  .handleAction(teacherActions.updateTeacher.success, (state, action) => {
    return {
      ...state,
      teachers: {
        ...state.teachers,
        [action.payload.id]: action.payload
      }
    };
  })
  .handleAction(teacherActions.deleteTeacher.success, (state, action) => {
    return {
      ...state,
      teachers: reduceArrayToObjectById(Object.values(state.teachers).filter(t => t.id !== action.payload))
    };
  })
  .handleAction(calendarActions.createCourse.success, (state, action) => ({
    ...state,
    id: action.payload.id,
    correlationId: action.payload.correlationId,
    name: action.payload.name,
    googleCalendarId: action.payload.googleCalendarId,
    colorHex: action.payload.colorHex
  }))
  .handleAction(calendarActions.deleteCourse.success, (state, action) => {
    if (!state.id || state.id !== action.payload.id) return state;
    return {
      ...initialState,
      id: undefined,
      correlationId: undefined
    };
  })
  .handleAction(workloadActions.selectCommitment, (state, action) => {
    if (!action.payload?.selectedCourseId && !action.payload?.selectedSectionId) {
      return {
        ...initialState,
        id: undefined,
        correlationId: undefined
      };
    } else {
      return {
        ...state,
        id: action.payload.selectedCourseId,
        name: action.payload.name,
        colorHex: action.payload.colorHex!
      };
    }
  })
  .handleAction(actions.reorderCategoriesInsideCourse.success, (state, action) => {
    const { taskCategories } = action.payload;
    return {
      ...state,
      taskCategories
    };
  })
  .handleAction(actions.openCourseDetailsDialog, (state, action) => {
    return {
      ...state,
      courseDetailsDialog: { open: true, courseId: action.payload }
    };
  })
  .handleAction(actions.closeCourseDetailsDialog, state => {
    return {
      ...state,
      courseDetailsDialog: initialState.courseDetailsDialog
    };
  })
  .handleAction(actions.getCategoriesByCourse.success, (state, action) => {
    return {
      ...state,
      taskCategories: action.payload
    };
  })
  .handleAction(actions.getReadingSourcesByCourse.success, (state, action) => {
    return {
      ...state,
      readingSources: action.payload
    };
  });

export default reducer;
