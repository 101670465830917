import styled from 'styled-components';
import colors from '../../utils/colors';
import { screenSizes } from '../../utils/screenUtils';
import { CALENDAR_SIDEBAR_TOGGLE_TRANSITION } from '../../utils/calendar/calendarConstants';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled(Row)`
  flex: 1;
  min-height: 0;
  min-width: 0;
`;

export const FlexColumn = styled(Column)`
  flex: 1;
  min-height: 0;
  min-width: 0;
`;

export const ConfirmAndCancelButtons = styled.div<{ center?: boolean }>`
  display: flex;
  justify-content: ${props => (props.center ? 'center' : 'flex-end')};

  button,
  .button-loader-wrapper {
    margin-left: 10px;
  }
`;

export const FieldPair = styled(Row)`
  display: flex;
  flex: 1;

  & > div {
    display: flex;
    flex: 1;
    flex-basis: 50%;
    margin-left: 10px;
  }

  & > div:first-of-type {
    margin-left: 0;
  }
`;

export const ExpandSidebarHandler = styled.div`
  display: flex;
  position: absolute;
  top: 15px;
  right: -10px;
  background: ${props => props.theme.sidebarHandleColor};
  border-radius: 5px;
  border-right: 2px solid ${props => props.theme.backgroundDark};
  z-index: 1;
  > i {
    color: ${props => props.theme.sidebarHandleIconColor};
  }
`;

export const CalendarSidebarContent = styled(FlexColumn)`
  transition: transform ${CALENDAR_SIDEBAR_TOGGLE_TRANSITION}ms cubic-bezier(0.38, 0.01, 0.09, 0.98),
    width ${CALENDAR_SIDEBAR_TOGGLE_TRANSITION}ms cubic-bezier(0.38, 0.01, 0.09, 0.98),
    min-width ${CALENDAR_SIDEBAR_TOGGLE_TRANSITION}ms cubic-bezier(0.38, 0.01, 0.09, 0.98);
  //padding: 0 10px;
  box-sizing: border-box;
`;

export const PlanSidebarContent = styled(FlexColumn)`
  transition: transform ${CALENDAR_SIDEBAR_TOGGLE_TRANSITION}ms cubic-bezier(0.38, 0.01, 0.09, 0.98),
    width ${CALENDAR_SIDEBAR_TOGGLE_TRANSITION}ms cubic-bezier(0.38, 0.01, 0.09, 0.98),
    min-width ${CALENDAR_SIDEBAR_TOGGLE_TRANSITION}ms cubic-bezier(0.38, 0.01, 0.09, 0.98);
  width: 320px;
  min-width: 320px;
  padding: 0 5px;
  box-sizing: border-box;
`;

export const displayNoneDuringPrint = `
 @media print {
    display: none !important;
  }
`;

export const hideDuringPrint = `
 @media print {
    visibility: hidden !important;
  }
`;

export const Sidebar = styled.div<{ collapsed?: boolean; isTransition?: boolean; isMobile?: boolean }>`
  ${displayNoneDuringPrint};

  > ${CalendarSidebarContent} {
    ${props => props.collapsed && 'transform: translate(-240px, 0px);'}
  }
  display: flex;
  flex-direction: column;
  min-width: 240px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '240px')};
  background-color: ${({ theme }) => theme.background};
  position: relative;
  margin-right: 2px;
  box-sizing: border-box;
  padding-top: 10px;
  transition: ${props =>
    `width ${CALENDAR_SIDEBAR_TOGGLE_TRANSITION}ms cubic-bezier(0.38, 0.01, 0.09, 0.98), min-width ${CALENDAR_SIDEBAR_TOGGLE_TRANSITION}ms cubic-bezier(0.38, 0.01, 0.09, 0.98) ${
      props.isTransition
        ? `,background-color ${CALENDAR_SIDEBAR_TOGGLE_TRANSITION}ms cubic-bezier(0.38, 0.01, 0.09, 0.98)`
        : ''
    }`};
  ${props =>
    props.collapsed &&
    `
    width: 10px;
    min-width: 10px;
    background-color: ${props.theme.sidebarCollapsedColor};
  `};
`;

export const PlanSidebar = styled.div<{ collapsed?: boolean; isTransition?: boolean }>`
  ${displayNoneDuringPrint};

  > ${PlanSidebarContent} {
    ${props => props.collapsed && 'transform: translate(320px, 0px); !important'}
  }
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: 320px;
  background-color: ${({ theme }) => theme.background};
  position: relative;
  box-sizing: border-box;
  transition: ${props =>
    `width ${CALENDAR_SIDEBAR_TOGGLE_TRANSITION}ms cubic-bezier(0.38, 0.01, 0.09, 0.98), min-width ${CALENDAR_SIDEBAR_TOGGLE_TRANSITION}ms cubic-bezier(0.38, 0.01, 0.09, 0.98) ${
      props.isTransition
        ? `,background-color ${CALENDAR_SIDEBAR_TOGGLE_TRANSITION}ms cubic-bezier(0.38, 0.01, 0.09, 0.98)`
        : ''
    }`};
  ${props =>
    props.collapsed &&
    `
    width: 0px;
    min-width: 0px;
  `}
`;

export const PlanSidebarShadow = styled.div`
  width: 5px;
  min-width: 5px;
  ${({ theme }) => `background: linear-gradient(270deg, ${theme.boxShadow} 21.88%, rgba(255, 255, 255, 0) 100%)`};
`;

export const textOverflowStyle = `
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TextOverflowDiv = styled.div<{ isDisabled?: boolean; taskList?: boolean }>`
  ${textOverflowStyle};
  ${props =>
    props.taskList &&
    `display: flex;
  align-items: center;`}
  ${props => props.isDisabled && `color: ${props.theme.textColor};`};
  .emoji-mart-emoji {
    display: flex;
    justify-content: center;
    margin-right: 3px;
  }
`;

export const TextOverflowSpan = styled.span`
  ${textOverflowStyle};
`;

export const EventTitle = styled(TextOverflowDiv)<{ bgColor?: string }>`
  ${props => `background-color: ${props.bgColor}`};
  transition: background-color 0.2s ease;
`;

export const DraggableTask = styled.div`
  width: 24px;
  height: 24px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.background};
  border-radius: 50%;
  box-shadow: 0 2px 2px ${props => props.theme.boxShadow}, 0 0 2px ${props => props.theme.boxShadow};
  cursor: ${props => (props.draggable ? 'grab' : 'pointer')};
  transition: box-shadow 0.2s ease;

  &:active {
    cursor: grabbing;
  }

  &:hover {
    box-shadow: 0px 1px 1px ${props => props.theme.boxShadow}, 0px 3px 8px ${props => props.theme.boxShadow},
      0px 3px 3px ${props => props.theme.boxShadow};
  }
`;

export const popupTriggerColor = (theme: any, disabled: boolean, open: boolean, active: boolean) => {
  if (disabled) {
    return theme.captures;
  } else if (active) {
    return colors.primaryPurple;
  } else if (open) {
    return theme.textStrongColor;
  } else {
    return theme.textColor;
  }
};

export const AGENDA_SMALL_ROW_HEIGHT = 36;
export const AGENDA_BIG_ROW_HEIGHT = 64;

export const getEventTitleStyle = backgroundColor => {
  return { backgroundColor, transition: 'background-color 0.2s ease ' };
};

export const PlanTaskLine = styled.div`
  display: flex;
  width: 4px;
  background-color: ${({ theme }) => theme.primary};
  position: relative;
  box-sizing: border-box;
  justify-content: center;
`;
