import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import { registerLocale } from 'react-datepicker';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import './index.css';
import './assets/css/colors.css';
import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';
import configureStore from './config/configureStore';
import history from './utils/history';
import './config/firebaseInitializer';

// This makes all datepickers start from Monday
import enGB from 'date-fns/locale/en-GB';
import ConditionalLoading from './utils/theme/ConditionalLoading';
import NewVersionChecker from './components/NewVersionChecker';

registerLocale('en-GB', enGB);

const store = configureStore({});

WebFont.load({
  google: {
    families: ['Quicksand:400,500,600,700', 'sans-serif']
  },
  custom: {
    families: ['shovel']
  }
});

//TODO: maybe move NewVersionChecker somewhere else, just make sure it always gets rendered and it has access to redux
ReactDOM.render(
  <Provider store={store}>
    <NewVersionChecker />
    <ConnectedRouter history={history}>
      <ConditionalLoading>
        <App />
      </ConditionalLoading>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register({ dispatch: store.dispatch });

// trigger deploy
