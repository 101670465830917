import { createReducer } from 'typesafe-actions';

import * as actions from './actions';
import { reduceCalendarEventsToListObject } from '../../utils/reducerHelpers';
import * as calendarActions from '../calendar/actions';
import { icsActions } from '../ics';
import { EventsState } from './types';

const initialState: EventsState = { events: {}, sourceEvents: [], start: null, end: null };

// TODO : check the bug with updating multiday event that is ranged over 2 different weeks (ex. Sunday to Monday)

const reducer = createReducer(initialState)
  .handleAction(actions.sourceEvents.success, (state, action) => {
    return {
      ...state,
      sourceEvents: action.payload
    };
  })
  .handleAction(actions.getEventsList.success, (state, action) => {
    const { events: _events, start, end } = action.payload;
    const events = reduceCalendarEventsToListObject(_events);

    return {
      ...state,
      events,
      start: start || state.start,
      end: end || state.end
    };
  })
  .handleAction(actions.createEvent.success, (state, action) => {
    const { sourceEvents, events } = action.payload;
    const createdEvents = reduceCalendarEventsToListObject(events);
    return {
      ...state,
      events: { ...state.events, ...createdEvents },
      sourceEvents: [...state.sourceEvents, ...sourceEvents]
    };
  })
  .handleAction(actions.deleteEvent.success, (state, action) => {
    const { id, data } = action.payload;
    const oldSourceEvents = state.sourceEvents;
    const sourceEvents = [...oldSourceEvents.filter(event => event.id !== id), ...data];

    return {
      ...state,
      sourceEvents
    };
  })
  .handleAction(actions.updateEvent.success, (state, action) => {
    const { id, data } = action.payload;
    const oldSourceEvents = state.sourceEvents;
    const sourceEvents = [...oldSourceEvents.filter(event => event.id !== id), ...data];

    return {
      ...state,
      sourceEvents
    };
  })
  .handleAction([calendarActions.updateActivity.success, calendarActions.updateCourse.success], (state, action) => {
    const { colorHex, name: title, correlationId } = action.payload;
    const sourceEvents = state.sourceEvents.map(e => (e.correlationId === correlationId ? { ...e, colorHex, title } : e));
    return { ...state, sourceEvents };
  })
  .handleAction([calendarActions.deleteActivity.success, calendarActions.deleteCourse.success], (state, action) => {
    const { correlationId } = action.payload;
    const sourceEvents = state.sourceEvents.filter(e => e.correlationId !== correlationId);
    return { ...state, sourceEvents };
  })
  .handleAction(icsActions.unsyncCourseFromLMS.success, (state, action) => {
    const { correlationId, platforms } = action.payload;
    const sourceEvents = state.sourceEvents.filter(e => e.correlationId !== correlationId && platforms.includes(e.type));
    return { ...state, sourceEvents };
  })
  .handleAction(actions.clearEvents, () => initialState);

export default reducer;
