import React, { Component } from 'react';
import styled from 'styled-components';
import Confetti from 'react-dom-confetti';
import { Column, Row } from '../layoutUtils';
import ModalDialog, { DialogActions } from '../ModalDialog';
import { allBadges, badgeNames, CONFETTI_CONFIG } from './helpers';
import { ExtraLargeText, LargeText, RegularText } from '@utils/typography';
import { Badge } from './Badge';
import TextLink from '../TextLink';
import t from '@i18n/t';
import onboardingBarPng from '@assets/images/onboarding-bar.png';
import { PaymentProcessor } from 'shovel-lib/types';
import Button from '@components/common/buttons/Button';

type Props = {
  onClose: () => void;
  name?: string;
  overviewGifSequence?: boolean;
  final?: boolean;
  open: boolean;
  freeTrial: boolean;
  paymentProcessor?: PaymentProcessor;
};

type State = { confetti: boolean; visible: boolean; buttonDisabled?: boolean; sequenceStep: SequenceStep };

enum SequenceStep {
  FINISH
}

const Gif = styled.img`
  width: 100%;
  height: auto;
  margin-top: 10px;
`;

const SequenceContent = {
  [SequenceStep.FINISH]: (
    <>
      {t.FOLLOW_ONBOARDING}
      <Gif src={onboardingBarPng} />
    </>
  )
};

class BadgeDialog extends Component<Props, State> {
  state = { confetti: false, visible: false, buttonDisabled: false, sequenceStep: SequenceStep.FINISH };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.open !== this.props.open) {
      setTimeout(
        () => this.setState({ visible: this.props.open, buttonDisabled: this.props.overviewGifSequence }),
        this.props.open ? 500 : 0
      );
    }
    if (prevState.visible !== this.state.visible) {
      setTimeout(() => this.setState({ confetti: this.state.visible }), 10);
    }
  }

  render() {
    const { onClose, overviewGifSequence, final } = this.props;
    const { visible } = this.state;
    const title = final ? t.ALL_BADGES_ACHIEVED_TITLE : '';

    return (
      <ModalDialog
        open={visible}
        onClose={onClose}
        title={title}
        fullWidth={overviewGifSequence}
        verticalCenter={overviewGifSequence}
        closeOnEscape={false}
        closeOnDocumentClick={false}
        hideCloseButton
      >
        {overviewGifSequence
          ? this.renderOverviewGifSequence()
          : final
          ? this.renderFinalDialog()
          : this.renderBadgeDialog()}
      </ModalDialog>
    );
  }

  renderOverviewGifSequence = () => {
    const { onClose } = this.props;
    const { sequenceStep } = this.state;
    const isFinish = sequenceStep === SequenceStep.FINISH;
    return (
      <Container style={{ padding: '20px 50px' }}>
        <SequenceText strong>{SequenceContent[sequenceStep]}</SequenceText>
        <DialogActions position={'center'} style={{ padding: '0 50px' }}>
          <Button filled onClick={isFinish ? onClose : () => this.setState({ sequenceStep: sequenceStep + 1 })}>
            {isFinish ? t.GOT_IT : t.NEXT}
          </Button>
        </DialogActions>
      </Container>
    );
  };

  renderBadgeDialog = () => {
    const { onClose, name, freeTrial, paymentProcessor } = this.props;
    const badge = name && allBadges.find(b => b.name === name);
    if (!badge) return null;

    const includeFreeTrial = freeTrial && badge.givesFreeTrial && paymentProcessor !== PaymentProcessor.BRAINTREE;

    return (
      <Container>
        <Badge {...badge} completed={true} big />
        <Title>
          <LargeText strong>{t.CONGRATULATIONS}</LargeText>
          <LargeText strong>{includeFreeTrial ? t.EARN_FREE_TRIAL_DAY : `${badge.name} ${t.BADGE_UNLOCKED}`}</LargeText>
        </Title>
        {includeFreeTrial && (
          <RegularText strong style={{ marginBottom: 20 }}>
            {badge.name} {t.BADGE_UNLOCKED}
          </RegularText>
        )}
        <Quote>{badge.quote}</Quote>
        {badge.key === badgeNames.ROCKSTAR_ROOKIE && (
          <>
            <RegularText strong>{t.FOLLOW_ONBOARDING}</RegularText>
            <Gif src={onboardingBarPng} />
          </>
        )}
        {/*<TextLink onClick={console.log}>{t.SHARE_WITH_OTHERS}</TextLink>*/}
        <DialogActions position={'center'}>
          <Button onClick={onClose} filled>
            {t.GOT_IT}
          </Button>
          <Confetti active={this.state.confetti} config={CONFETTI_CONFIG} />
        </DialogActions>
      </Container>
    );
  };

  renderFinalDialog = () => {
    const { onClose } = this.props;
    return (
      <Container>
        <RegularText style={{ textAlign: 'center' }}>
          {t.ALL_BADGES_ACHIEVED_TEXT} {t.ALL_BADGES_ACHIEVED_ADDITIONAL_TEXT}
        </RegularText>
        <BadgesContainer>
          {allBadges.map(b => (
            <BadgeWrapper>
              <Badge {...b} completed={true} big />
              <BadgeName>{b.name}</BadgeName>
            </BadgeWrapper>
          ))}
        </BadgesContainer>
        <DialogActions position={'center'}>
          <Button onClick={onClose} filled>
            {t.GOT_IT}
          </Button>
          <Confetti active={this.state.confetti} config={CONFETTI_CONFIG} />
        </DialogActions>
      </Container>
    );
  };
}

export default BadgeDialog;

const Container = styled(Column)`
  align-items: center;
  ${DialogActions} > div {
    position: absolute !important;
    bottom: -50px;
  }
`;

const Title = styled(Column)`
  margin: 20px 0 15px;
  align-items: center;
`;

const Quote = styled(RegularText)`
  text-align: center;
  width: 330px;
  margin-bottom: 30px;
`;

const BadgesContainer = styled(Row)`
  flex-wrap: wrap;
  justify-content: center;
  width: 500px;
`;

const BadgeName = styled(RegularText)`
  margin-top: 10px;
`;

const BadgeWrapper = styled(Column)`
  flex-basis: 33.3%;
  align-items: center;
  margin-top: 30px;
`;

const Link = styled(TextLink)`
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
`;

const SequenceText = styled(ExtraLargeText)`
  white-space: pre-wrap;
  text-align: center;
`;
