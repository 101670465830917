import { network } from './network';

// todo: remove after updating shovel-lib
async function getCoursesWebsiteJwt() {
  return network.get(`/courses-website-jwt`);
}

export default {
  getCoursesWebsiteJwt
};
