import { createAction } from 'typesafe-actions';
import { actionCreator, asyncActionsCreator } from '../common/utils';
import { IdWrapper, Semester, SemesterListDto } from 'shovel-lib/types';
import { TimelineColorMode } from './types';

const asyncActions = asyncActionsCreator('@@semester');

const action = actionCreator('@@semester');

export const getCurrent = asyncActions<void, Semester, string>('GET_CURRENT');

export const getDetailedSemester = asyncActions<void, any, string>('GET_DETAILED');

export const getUserSemesters = asyncActions<void, SemesterListDto[], string>('GET_USER_SEMESTERS');

export const changeCurrentSemester = asyncActions<IdWrapper, IdWrapper, string>('CHANGE_CURRENT_SEMESTER');

export const removeCurrentSemester = createAction('REMOVE_CURRENT_SEMESTER');

export const createSemester = asyncActions<{ skipIntroPage: boolean } | void, IdWrapper, string>('CREATE_SEMESTER');

export const getOverallCushion = asyncActions<undefined, any, string>('GET_OVERALL_CUSHION');

export const startCushionListener = action<undefined>('START_CUSHION_LISTENER');

export const stopCushionListener = action<undefined>('STOP_CUSHION_LISTENER');

export const changeTimelineColorMode = action<TimelineColorMode>('CHANGE_TIMELINE_COLOR_MODE');

export const toggleCushionSidebar = action<undefined>('TOGGLE_CUSHION_SIDEBAR');
