import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { courseActions } from '../../../state/course';
import { GoogleCalendar } from '../../../state/googleCalendar/types';
import LinkGoogleCalendarModal from './LinkGoogleCalendarModal';
import { getLinkToGoogleCalDialogParams } from '../../../state/common/selectors';
import { getType } from 'typesafe-actions';
import { toggleLinkToGoogleCalendarDialog } from '../../../state/common/actions';
import { getCommitments } from '../../../state/calendar/selectors';

type Props = {};

function LinkGoogleToCourseDialog({}: Props) {
  const dialogParams = useSelector(getLinkToGoogleCalDialogParams);
  const { opened, multiselect, courseId } = dialogParams;

  const { courses, activities } = useSelector(getCommitments);

  const dispatch = useDispatch();

  const onImport = useCallback((calendars: GoogleCalendar[], courseId?: number) => {
    if (calendars.length > 0) {
      dispatch({
        type: getType(courseActions.updateCalendarId.request),
        payload: {
          id: courseId,
          googleCalendarId: calendars[0].googleCalendarId
        }
      });
    }
  }, []);

  const onHide = useCallback(() => {
    dispatch({
      type: getType(toggleLinkToGoogleCalendarDialog),
      payload: {}
    });
  }, []);

  return (
    <LinkGoogleCalendarModal
      courses={courses}
      activities={activities}
      connectedCalendarIds={[
        ...courses.map(c => c.googleCalendarId!).filterNonEmpty(),
        ...activities.map(c => c.googleCalendarId!).filterNonEmpty()
      ]}
      visible={opened}
      multiselect={multiselect}
      courseId={courseId}
      onClickImport={onImport}
      onHide={onHide}
    />
  );
}

export default LinkGoogleToCourseDialog;
