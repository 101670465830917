import React, { PureComponent } from 'react';
import TippyTooltip from './tooltip/TippyTooltip';
import { Column } from './layoutUtils';
import styled from 'styled-components';
import { MediumText } from '../../utils/typography';
import { MenuIcon, MenuItem } from './ContextMenu';
import MaterialIcon from './icons/MaterialIcon';
import colors from '../../utils/colors';

type Option = {
  name?: string;
  icon?: string;
  action?: any;
  customItem?: any;
  //for material icon
  outlinedIcon?: boolean;
};

type Props = {
  options: Option[];
  tooltip?: string;
  target?: any;
  controlled?: boolean;
  open?: boolean;
  onClick?: any;
  onClickOutside?: any;
  disabled?: boolean;
  themeProps?: any;
  renderItemPopup?: any;
};

class EditMenu extends PureComponent<Props> {
  popup;

  renderContent = () => {
    const itemEl = (
      <Container {...this.props.themeProps}>
        {this.props.options.map((o, index) =>
          o.customItem ? (
            <o.customItem key={index} />
          ) : (
            <MenuItem
              key={index}
              onClick={e => {
                o.action!();
                e.stopPropagation();
                this.popup && this.popup.hide();
              }}
              {...this.props.themeProps}
            >
              {o.icon && <MenuIcon name={o.icon} size={14} outline={o.outlinedIcon} />}
              <MediumText strong>{o.name!}</MediumText>
            </MenuItem>
          )
        )}
      </Container>
    );
    return this.props.renderItemPopup ? this.props.renderItemPopup(itemEl) : itemEl;
  };

  onCreatePopup = popup => {
    this.popup = popup;
  };

  render() {
    const { tooltip, controlled = false, open, onClick, onClickOutside, disabled = false, themeProps } = this.props;
    const el = (
      <EditIcon
        className={'edit-menu'}
        onClick={e => {
          if (!disabled) {
            onClick?.();
            e.stopPropagation();
          }
        }}
        isOpen={open}
      >
        <MaterialIcon name={'more_horiz'} size={22} />
      </EditIcon>
    );
    return (
      <TippyTooltip
        onCreate={this.onCreatePopup}
        target={
          tooltip ? (
            <div>
              <TippyTooltip target={el} content={tooltip} />
            </div>
          ) : (
            el
          )
        }
        content={this.renderContent()}
        interactive
        controlled={controlled}
        onClickOutside={onClickOutside}
        visible={open}
        position={'bottom-start'}
        arrow={false}
        trigger={'click'}
        lightTheme
        noPadding
        {...themeProps}
      />
    );
  }
}

export default EditMenu;

const Container = styled(Column)`
  background-color: ${props => props.theme.background};
  border-radius: 5px;
  padding: 5px 0;
  min-width: 130px;
`;

const EditIcon = styled.div<{ isOpen?: boolean }>`
  opacity: ${props => (props.isOpen ? 1 : 0)};
  display: flex;
  &:hover {
    > i {
      color: ${colors.primaryPurple};
    }
  }
`;
