import { IdWrapper, CourseGpaDto, GpaDto, SemesterGpas, SemesterGpaDto } from 'shovel-lib/types';
import { asyncActionsCreator } from '../common/utils';

const asyncActions = asyncActionsCreator('@@gpaCalculator');

export const readSemesterGpas = asyncActions<void, SemesterGpas, string>('READ_SEMESTER_GPAS');
export const createSemesterGpa = asyncActions<void, IdWrapper, string>('CREATE_SEMESTER_GPA');
export const updateSemesterGpa = asyncActions<SemesterGpaDto, IdWrapper & GpaDto, string>('UPDATE_SEMESTER_GPA');
export const deleteSemesterGpa = asyncActions<IdWrapper, IdWrapper & GpaDto, string>('DELETE_SEMESTER_GPA');
export const createCourseGpa = asyncActions<CourseGpaDto, CourseGpaDto & { cumulativeGpa: number }, string>(
  'CREATE_COURSE_GPA'
);
export const updateCourseGpa = asyncActions<CourseGpaDto, CourseGpaDto & GpaDto, string>('UPDATE_COURSE_GPA');
export const deleteCourseGpa = asyncActions<
  { id: number; semesterId: number },
  GpaDto & { id: number; semesterId: number },
  string
>('DELETE_COURSE_GPA');
export const toggleGpaScale = asyncActions<void, SemesterGpas, string>('TOGGLE_GPA_SCALE');
