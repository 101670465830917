import { createReducer } from 'typesafe-actions';
import { now, toMomentDate } from 'shovel-lib/utils/timeUtils';
import { ManageTask, TaskState } from 'shovel-lib/types';
import * as actions from './actions';
import { VisibleTaskState } from './types';
import { pageRangesToAdditionalText, parseTaskMetadata, sumPagesForUnusedPlannedTasks } from '../../utils/taskUtils';
import { fixOrdering } from '../common/utils';
import { calendarActions } from '../calendar';
import * as courseActions from '../course/actions';
import { timerActions } from '../timer';

const initialState: VisibleTaskState = {
  manage: { isOpen: false },
  tasksModal: { isOpen: false, tasks: [] },
  courses: [],
  gradeUnits: [],
  readingSources: [],
  classAppearancesOnDate: [],
  loading: false,
  saving: false,
  error: '',
  newCreateTaskDialog: { open: false },
  repeatPatternDialog: { open: false, data: undefined },
  newEstDialog: {
    open: false,
    data: undefined
  }
};

// @ts-ignore
const reducer = createReducer(initialState)
  .handleAction(actions.closeManageTaskDialog, state => ({
    ...state,
    manage: {
      isOpen: false,
      task: undefined
    },
    loading: false
  }))
  .handleAction(actions.openManageTaskDialog, (state, action) => {
    const task = action.payload;
    task.plannedTasks = [];
    task.pageRanges = task.pageRanges || [];
    task.pages = 0;

    // Pile task, cushion and tasks on day cards have different property names
    // (they all have category and reading unpacked so we use it ro recognize if mapping is necessary)
    const taskProps = Object.keys(action.payload);
    if (taskProps.includes('categoryId')) {
      task.reading = task.readingSourceName ? { id: task.readingSourceId, name: task.readingSourceName } : undefined;
      task.taskCategory = task.categoryName
        ? { id: task.categoryId, name: task.categoryName, emoji: task.emoji }
        : undefined;
      if (taskProps.includes('due')) {
        task.dueDate = task.due?.toISOString ? task.due.toISOString() : task.due;
      }
    }
    return {
      ...state,
      manage: { isOpen: true, task },
      loading: true
    };
  })
  .handleAction([actions.getManageTask.success], (state, action) => {
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...action.payload,
          metadata: action.payload.metadata ? parseTaskMetadata(action.payload.metadata) : null
        }
      },
      loading: false
    };
  })
  .handleAction(actions.saveManageTask.success, (state, { payload }) => {
    const { task } = state.manage;
    if (!task) return state;

    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          ...payload.response,
          previousState: task.state
        }
      }
    };
  })
  .handleAction(actions.setTasksPriority.success, (state, action) => {
    const { task } = state.manage;
    const { taskIds, ...payload } = action.payload;
    if (!task || !task.taskId || (taskIds && task.taskId !== taskIds[0])) return state;

    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...task,
          priority: payload.priority,
          previousState: task.state
        }
      }
    };
  })
  .handleAction(actions.getPageRanges.success, (state, action) => {
    const { taskId, pageRanges } = action.payload;
    if (!state.manage.task || state.manage.task.taskId !== taskId) return state;
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          pageRanges
        }
      }
    };
  })
  .handleAction(actions.savePageRanges.success, (state, action) => {
    if (!state.manage.task || state.manage.task.taskId !== action.payload.taskId) return state;

    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          ...action.payload.data,
          additionalText: pageRangesToAdditionalText(action.payload.data.pageRanges)
        }
      }
    };
  })

  .handleAction(actions.addOrUpdateSubtask.success, (state, action) => {
    if (!state.manage.task) return state;
    const task = state.manage.task as ManageTask;
    const subtasks = [...task.subtasks];
    const subtaskIndex = subtasks.findIndex(s => s.id === action.payload.data.id);
    if (subtaskIndex !== -1) {
      subtasks[subtaskIndex] = action.payload.data;
    } else {
      subtasks.push(action.payload.data);
    }
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          subtasks
        }
      }
    };
  })
  .handleAction(actions.deleteSubtask.success, (state, action) => {
    if (!state.manage.task) return state;
    const task = state.manage.task as ManageTask;
    const subtasks = task.subtasks.filter(s => s.id !== action.payload.id);
    fixOrdering(subtasks);
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          subtasks
        }
      }
    };
  })
  .handleAction(actions.updateTaskStatus.success, (state, action) => {
    if (!state.manage.task) return state;

    const { payload } = action;
    const timePlanned = payload.state === TaskState.COMPLETED ? 0 : state.manage.task.timePlanned;

    const plannedTasks =
      payload.state === TaskState.COMPLETED ? state.manage.task.plannedTasks.filter(t => t.hasPassed || t.isUsed) : [];

    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          timePlanned,
          plannedTasks,
          previousState: state.manage.task.state,
          state: payload.state,
          completedOn: payload.completedOn
        }
      }
    };
  })
  .handleAction(actions.moveToTodo.success, (state, action) => {
    if (!state.manage.task) return state;

    const taskState = now().isAfter(action.payload.dueDate) ? TaskState.DO_LATER : TaskState.ACTIVE;

    return {
      ...state,
      saving: false,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          previousState: state.manage.task.state,
          state: taskState,
          dueDate: action.payload.dueDate,
          completedOn: action.payload.completedOn,
          daysToStartAhead: action.payload.daysAhead
        }
      }
    };
  })
  .handleAction(actions.addPlannedTask.success, (state, action) => {
    if (!state.manage.task) return state;
    const { taskId, courseName, textColor, title, ...data } = action.payload;
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          // @ts-ignore
          plannedTasks: [...state.manage?.task?.plannedTasks, data],
          timePlanned: state.manage.task.timePlanned + data.plannedMinutes
        }
      }
    };
  })
  .handleAction(calendarActions.duplicatePlannedTask.success, (state, action) => {
    if (!state.manage.task) return state;
    const { id, start, end, notes, timePlanned, isUsed, pages } = action.payload;
    const data = {
      id: Number(id),
      hasPassed: now().isAfter(end),
      plannedMinutes: timePlanned,
      start,
      end,
      isUsed,
      notes,
      pages,
      used: 0
    };
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          // @ts-ignore
          plannedTasks: [...state.manage?.task?.plannedTasks, data],
          timePlanned: state.manage.task.timePlanned + data.plannedMinutes
        }
      }
    };
  })
  .handleAction([calendarActions.markAsUsed.success, timerActions.start.success], (state, action) => {
    if (!state.manage.task) return state;
    const plannedTask = state.manage.task.plannedTasks;
    const { timeNeed, timeSpent, plannedTaskDto, timePlanned } = action.payload;
    if (!plannedTaskDto) return state;
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          timeNeed,
          timeSpent,
          timePlanned,
          plannedTasks: plannedTask.map(pt => {
            return pt.id === plannedTaskDto.id ? plannedTaskDto : pt;
          })
        }
      }
    };
  })
  .handleAction(timerActions.cancel.success, (state, action) => {
    if (!state.manage.task || state.manage.task.taskId != action.payload.activeTimer.masterTaskId) return state;
    const plannedTask = state.manage.task.plannedTasks;
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          plannedTasks: plannedTask.map(pt => {
            return pt.id === action.payload.activeTimer.plannedTaskId ? { ...pt, used: 0 } : pt;
          })
        }
      }
    };
  })
  .handleAction(calendarActions.resizePlannedTask.success, (state, action) => {
    if (!state.manage.task) return state;
    const { oldEvent, newEvent } = action.payload;
    const today = now();
    const oldPlannedMinutes = toMomentDate(oldEvent.end).diff(oldEvent.start, 'minutes');
    const newPlannedMinutes = toMomentDate(newEvent.end).diff(newEvent.start, 'minutes');
    const timePlannedDiff =
      (today.isSameOrBefore(newEvent.end) ? newPlannedMinutes : 0) -
      (today.isSameOrBefore(oldEvent.end) ? oldPlannedMinutes : 0);

    const plannedTasks = state.manage.task.plannedTasks.map(pt => {
      if (pt.id.toString() === newEvent.id) {
        return {
          ...pt,
          plannedMinutes: !newEvent.isUsed ? newPlannedMinutes : pt.plannedMinutes,
          used: newEvent.isUsed ? newPlannedMinutes : pt.used,
          start: newEvent.start,
          end: newEvent.end
        };
      }
      return pt;
    });
    const { timeNeed, timeSpent, isUsed } = newEvent;
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          timePlanned: !isUsed
            ? Math.max(state.manage.task.timePlanned + timePlannedDiff, 0)
            : state.manage.task.timePlanned,
          plannedTasks,
          timeNeed: isUsed ? timeNeed : state.manage.task.timeNeed,
          timeSpent: isUsed ? timeSpent : state.manage.task.timeSpent
        }
      }
    };
  })
  .handleAction(calendarActions.stopTimerForPlannedTask.success, (state, action) => {
    if (!state.manage.task) return state;
    const { plannedTaskDto, timeNeed, timeSpent, timePlanned } = action.payload;
    const plannedTasks = state.manage.task.plannedTasks;
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          timeNeed: plannedTaskDto.isUsed ? timeNeed : state.manage.task.timeNeed,
          timeSpent: plannedTaskDto.isUsed ? timeSpent : state.manage.task.timeSpent,
          timePlanned: plannedTaskDto.isUsed ? timePlanned : state.manage.task.timePlanned,
          plannedTasks: plannedTasks.map(pt => {
            return pt.id === plannedTaskDto.id ? plannedTaskDto : pt;
          })
        }
      }
    };
  })
  .handleAction(actions.openTasksModal, (state, action) => {
    return {
      ...state,
      tasksModal: {
        isOpen: true,
        tasks: action.payload.map(t => ({ ...t, due: toMomentDate(t.dueDate) }))
      }
    };
  })
  .handleAction(actions.closeTasksModal, state => {
    return {
      ...state,
      tasksModal: {
        isOpen: false,
        tasks: []
      }
    };
  })
  .handleAction(actions.openNewCreateTaskDialog, (state, action) => {
    return {
      ...state,
      newCreateTaskDialog: { open: true, data: action.payload }
    };
  })
  .handleAction(actions.closeNewCreateTaskDialog, state => {
    return {
      ...state,
      newCreateTaskDialog: { open: false }
    };
  })
  .handleAction(actions.openRepeatPatternDialog, (state, action) => {
    return {
      ...state,
      repeatPatternDialog: { open: true, data: action.payload }
    };
  })
  .handleAction(actions.closeRepeatPatternDialog, state => {
    return {
      ...state,
      repeatPatternDialog: initialState.repeatPatternDialog
    };
  })
  .handleAction(actions.changeTaskCategory.success, (state, action) => {
    if (!state.manage?.task) return state;
    return {
      ...state,
      manage: {
        ...state.manage,
        task: { ...state.manage.task, taskCategory: action.payload.category }
      }
    };
  })
  .handleAction(actions.changeReadingSource.success, (state, action) => {
    const { task } = state.manage;
    if (!task) return state;
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          ...action.payload[0],
          pageRanges: action.payload[0].pageRanges || task.pageRanges,
          timeEntries: action.payload[0].timeEntries || task.timeEntries
        }
      }
    };
  })
  .handleAction(actions.changeCourse.success, (state, action) => {
    const { task } = state.manage;
    if (!task) return state;

    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          ...action.payload[0],
          timeEntries: task.reading ? [] : task.timeEntries
        } as ManageTask
      }
    };
  })
  .handleAction(actions.reorderSubtasks.success, (state, action) => {
    if (!state.manage.task) return state;
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          subtasks: action.payload.subtasks
        }
      }
    };
  })
  .handleAction(calendarActions.updatePlannedTask.success, (state, action) => {
    const { plannedTaskDto, timeNeed, timeSpent } = action.payload.data;
    if (!state.manage.task) return state;
    const plannedTasks = state.manage.task.plannedTasks;
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          timeSpent,
          timeNeed,
          plannedTasks: plannedTasks.map(pt => {
            return pt.id === plannedTaskDto.id ? plannedTaskDto : pt;
          })
        }
      }
    };
  })
  .handleAction(calendarActions.deletePlannedTask.success, (state, action) => {
    if (!state.manage.task) return state;
    const {
      event,
      data: { timeNeed, timeSpent, timePlanned }
    } = action.payload;
    const plannedTasks = state.manage.task.plannedTasks.filter(pt => pt.id !== Number(event.id));
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          plannedTasks,
          timeNeed,
          timeSpent,
          timePlanned
        }
      }
    };
  })
  .handleAction(courseActions.updateReadingSource.success, (state, action) => {
    const { task } = state.manage;
    if (!task || !task.reading || task.reading.id !== action.payload.id) {
      return state;
    } else {
      const pagesRead = sumPagesForUnusedPlannedTasks(task.plannedTasks);
      const pagesLeft = Math.max(task.pages - pagesRead, 0);
      const timeNeed = Math.ceil((pagesLeft * action.payload.secondsPerPage) / 60);
      return {
        ...state,
        manage: {
          ...state.manage,
          task: {
            ...task,
            reading: action.payload,
            timeNeed
          }
        }
      };
    }
  })
  .handleAction(actions.openNewEstimateDialog, (state, action) => {
    return {
      ...state,
      newEstDialog: {
        open: true,
        data: action.payload
      }
    };
  })
  .handleAction(actions.closeNewEstimateDialog, (state, action) => {
    return {
      ...state,
      newEstDialog: initialState.newEstDialog
    };
  })
  .handleAction(calendarActions.movePlannedTask.success, (state, action) => {
    if (!state.manage.task) return state;
    const { oldEvent, newEvent } = action.payload;

    const today = now();
    const oldPlannedMinutes = toMomentDate(oldEvent.end).diff(oldEvent.start, 'minutes');
    const newPlannedMinutes = toMomentDate(newEvent.end).diff(newEvent.start, 'minutes');
    const timePlannedDiff =
      (today.isSameOrBefore(newEvent.end) ? newPlannedMinutes : 0) -
      (today.isSameOrBefore(oldEvent.end) ? oldPlannedMinutes : 0);
    const plannedTasks = state.manage.task.plannedTasks.map(pt => {
      if (pt.id.toString() === newEvent.id) {
        return {
          ...pt,
          start: newEvent.start,
          end: newEvent.end
        };
      }
      return pt;
    });
    return {
      ...state,
      manage: {
        ...state.manage,
        task: {
          ...state.manage.task,
          plannedTasks,
          timePlanned: newEvent.isUsed
            ? state.manage.task.timePlanned
            : Math.max(state.manage.task.timePlanned + timePlannedDiff, 0)
        }
      }
    };
  });
//     case getType(actions.getReadingTask.request):
//     case getType(actions.getExtraTask.request): {
//       return getDefaultRequestObj(state, action);
//     }
//     case getType(actions.getGradeUnitTask.success):
//     case getType(actions.getReadingTask.success):
//     case getType(actions.getExtraTask.success): {
//       return {
//         ...state,
//         edit: {
//           ...action.payload,
//           dueDate: toJSUtcDate(action.payload.dueDate)
//         }
//       };
//     }
//     case getType(actions.getGradeUnitTask.failure):
//     case getType(actions.getReadingTask.failure):
//     case getType(actions.getExtraTask.failure): {
//       return getDefaultErrorObj(state, action);
//     }
//     case getType(actions.deleteGradeUnitTask.request):
//     case getType(actions.deleteReadingTask.request):
//     case getType(actions.deleteExtraTask.request): {
//       return getDefaultRequestObj(state, action);
//     }
//     case getType(actions.deleteGradeUnitTask.success):
//     case getType(actions.deleteReadingTask.success):
//     case getType(actions.deleteExtraTask.success): {
//       return {
//         ...state,
//         edit: {},
//         manage: {},
//         loading: false
//       };
//     }
//     case getType(actions.deleteGradeUnitTask.failure):
//     case getType(actions.deleteReadingTask.failure):
//     case getType(actions.deleteExtraTask.failure): {
//       return getDefaultErrorObj(state, action);
//     }
//     // GET COURSES
//     case getType(actions.getCourses.success): {
//       return {
//         ...state,
//         courses: action.payload
//       };
//     }
//     case getType(actions.getGradeUnits.success): {
//       return {
//         ...state,
//         gradeUnits: action.payload
//       };
//     }
//     case getType(actions.getReadingSources.success): {
//       return {
//         ...state,
//         readingSources: action.payload
//       };
//     }
//     case getType(actions.getClassAppearancesOnDate.success): {
//       return {
//         ...state,
//         classAppearancesOnDate: action.payload
//       };
//     }
//     default:
//       return state;
//   }
// };

export default reducer;
