import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { MediumText } from '../../utils/typography';
import colors from '@utils/colors';

export type SwitchOptionType = {
  label: string;
  value: any;
  disabled?: boolean;
  className?: string;
  color?: string;
  onClick?: any;
};

type Props = {
  wrapped?: boolean;
  options: Array<SwitchOptionType>;
  active: any;
  onChange: any;
  className?: string;
  background?: string;
};

const Switch: FC<Props> = ({ options, active, onChange, className, wrapped, background }) => {
  return (
    <Container className={className} wrapped={wrapped} background={background}>
      <SwitchOptions>
        {options.map(option => renderOption({ ...option, active: active === option.value, onChange }))}
      </SwitchOptions>
    </Container>
  );
};

const renderOption = ({
  label,
  value,
  active,
  color,
  disabled,
  onClick,
  onChange,
  className
}: SwitchOptionType & { active: boolean; onChange: any }) => {
  return (
    <OptionWrapper key={value}>
      <SwitchOption
        key={value}
        color={color}
        active={active}
        onClick={() => {
          if (disabled) return;
          if (onClick) {
            onClick();
          }
          if (!active) {
            onChange(value);
          }
        }}
        disabled={disabled}
        className={`${active ? 'active ' : ''}${className}`}
      >
        {label}
      </SwitchOption>
    </OptionWrapper>
  );
};

export default Switch;

const SwitchOptions = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
`;

const OptionWrapper = styled.div``;

const SwitchOption = styled(MediumText)<{ active: boolean; disabled?: boolean; color?: string }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;
  border: 0;
  white-space: nowrap;
  color: ${props => props.theme.textStrongColor};
  background-color: ${props => (props.active ? props.color || props.theme.backgroundDark : 'transparent')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.7' : '1')};
  ${props => props.active && 'font-weight: bold'};
  border-radius: 5px;
  transition: color 0.2s ease, background-color 0.2s ease;
  &:hover {
    ${props => !props.active && props.color && `color: ${props.color}`};
  }
`;

const wrappedStyles = css<{ background?: string }>`
  border-radius: 5px;
  background: ${props => props.background || props.theme.backgroundDark};
  padding: 5px;
  ${OptionWrapper} {
    flex: 1;
  }
  ${SwitchOption} {
    padding: 4px 12px;
    &.active {
      color: ${colors.white};
    }
  }
`;

const Container = styled.div<{ wrapped?: boolean; background?: string }>`
  display: flex;
  ${props => props.wrapped && wrappedStyles};
`;
