export const isPositiveInteger = (value: string) => {
  const DIGITS_REG = new RegExp('^\\d*$');
  return DIGITS_REG.test(value);
};

export const isPositiveIntegerNotEmpty = (value: string) => {
  const DIGITS_REG = new RegExp('^\\d+$');
  return DIGITS_REG.test(value);
};

export const validateMin = (value: number, minValue?: number) => minValue === undefined || value >= minValue;

export const validateMax = (value: number, maxValue?: number) => maxValue === undefined || value <= maxValue;

export const hasValue = (value: any) => value !== undefined && value !== null;

export const isNumber = (input: any) => hasValue(input) && input !== '' && !isNaN(input);
