import { actionCreator, asyncActionsCreator } from '../common/utils';
import {
  HelpUsDialogData,
  OnboardingStep,
  PaymentProcessor,
  PileStateOptions,
  SubscriptionInfo,
  UpdateAccountRequest,
  UpdateEmailRequest,
  UpdatePasswordRequest
} from 'shovel-lib/types';
import { BadgesInfo, MonthFilterT, UserSettings } from './types';
import { BadgeStep, Substeps } from '@components/common/onboardingBar/helpers';

const asyncActions = asyncActionsCreator('@@settings');
const action = actionCreator('@@settings');

export const getUserSubscription = asyncActions<void, SubscriptionInfo, string>('GET_USER_SUBSCRIPTION');

export const updateAccount = asyncActions<UpdateAccountRequest, UpdateAccountRequest, string>('UPDATE_ACCOUNT');

export const updatePassword = asyncActions<UpdatePasswordRequest, UpdatePasswordRequest, string>('UPDATE_PASSWORD');

export const updateEmail = asyncActions<UpdateEmailRequest & { onSuccess: Function }, UpdateEmailRequest, string>(
  'UPDATE_EMAIL'
);

export const deleteAccount = asyncActions<any, void, string>('DELETE_ACCOUNT');

export const cancelSubscription = asyncActions<
  { cancellationReasons: string; paymentProcessor: PaymentProcessor },
  SubscriptionInfo,
  string
>('CANCEL_SUBSCRIPTION');

export const reactivateSubscription = asyncActions<{ paymentProcessor: PaymentProcessor }, SubscriptionInfo, string>(
  'REACTIVATE_SUBSCRIPTION'
);

export const getUserSettings = asyncActions<void, UserSettings | null, string>('GET_USER_SETTINGS');

export const saveUserSettings = asyncActions<any, UserSettings, string>('SAVE_USER_SETTINGS');

export const applyMonthFilter = action<MonthFilterT>('APPLY_MONTH_FILTER');

export const savePileState = action<PileStateOptions>('SAVE_PILE_STATE');

export const changeOnboardingStatus = action<OnboardingStep>('CHANGE_ONBOARDING_STATUS');

export const resetOnboarding = action<void>('RESET_ONBOARDING');

export const skipOnboarding = action<void>('SKIP_ONBOARDING');

export const openHelpUsDialog = action<HelpUsDialogData>('OPEN_HELP_US_DIALOG');

export const closeHelpUsDialog = action<undefined>('CLOSE_HELP_US_DIALOG');

export const updatePlannedInCushion = asyncActions<boolean, boolean, string>('UPDATE_PLANNED_IN_CUSHION');

export const startOnboardingListener = action<void>('START_ONBOARDING_LISTENER');

export const stopOnboardingListener = action<void>('STOP_ONBOARDING_LISTENER');

export const checkStep = action<BadgeStep>('CHECK_STEP');

export const checkSubstep = action<{ substep: Substeps; step: BadgeStep }>('CHECK_SUBSTEP');

export const getBadgesInfo = asyncActions<void, BadgesInfo, string>('GET_BADGES_INFO');

export const updateBadgesInfo = asyncActions<BadgesInfo, BadgesInfo, string>('UPDATE_BADGES_INFO');

export const addFreeTrialDay = asyncActions<BadgesInfo & {}, SubscriptionInfo, string>('ADD_FREE_TRIAL_DAY');

export const openStepDialog = action<string>('OPEN_STEP_DIALOG');
export const closeStepDialog = action<void>('CLOSE_STEP_DIALOG');

export const cancelBadgesObviousMike = action<void>('CANCEL_BADGES_OBVIOUS_MIKE');

export const toggleOnboardingBar = action<boolean | void>('TOGGLE_ONBOARDING_BAR');

export const openPersonalizationDialog = action<any>('OPEN_PERSONALIZATION_DIALOG');

export const closePersonalizationDialog = action<undefined>('CLOSE_PERSONALIZATION_DIALOG');
