import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
  name: string;
  color?: string;
  size?: number;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  cursor?: string;
  strong?: boolean;
  outline?: boolean;
  noClass?: boolean;
};

const MaterialIcon: FC<Props> = ({
  name,
  onMouseDown,
  style,
  color,
  size = 20,
  onClick,
  className,
  strong,
  disabled = false,
  cursor,
  outline,
  noClass
}) => (
  <Icon
    onMouseDown={onMouseDown}
    color={color}
    style={style}
    size={size}
    className={`${className || ''} material-icons${noClass ? '' : outline ? '-outlined' : '-round'}`}
    onClick={onClick}
    disabled={disabled}
    cursor={cursor}
    strong={strong}
  >
    {name}
  </Icon>
);

export default MaterialIcon;

const Icon = styled.i<{ color?: string; size: number; disabled?: boolean; strong?: boolean; cursor?: string }>`
  color: ${props =>
    props.disabled
      ? props.theme.borderColor
      : props.color || (props.strong ? props.theme.textStrongColor : props.theme.textColor)};
  font-size: ${props => props.size}px;
  cursor: ${props => (props.disabled ? 'not-allowed' : props.cursor || 'pointer')};
`;
