import React, { ChangeEvent, PureComponent } from 'react';
import Linkify from 'linkifyjs/react';

import InputWrapper from '../InputWrapper';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import InlineInputWrapper from '../InlineInputWrapper';
import {
  BareWrapper,
  INLINE_TEXT_AREA_PADDING,
  INPUT_HORIZONTAL_PADDING,
  INPUT_LABEL_PADDING,
  INPUT_VERTICAL_PADDING
} from '../Input.styles';
import colors from '@utils/colors';
import { Row } from '../../layoutUtils';

type Props = {
  value: string;
  onChange?: (value: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (value: string) => void;
  onFocus?: () => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  minRows?: number;
  maxRows?: number;
  label?: string;
  name?: string;
  icon?: string;
  placeholder?: string;
  className?: string;
  error?: string;
  autofocus?: boolean;
  maxLength?: number;
  disabled?: boolean;
  inline?: boolean;
  bare?: boolean;
  required?: boolean;
  fontStyle?: any;
  expandsInfinitely?: boolean;
  touched?: boolean;
  oneLine?: boolean;
  disableLink?: boolean;
  noErrorMargin?: boolean;
};

class TextareaInput extends PureComponent<Props> {
  state = {
    inputValue: this.props.value || '',
    selectionInProgress: false
  };

  inputRef = React.createRef<HTMLTextAreaElement>();
  previewRef = React.createRef<HTMLPreElement>();

  componentDidMount(): void {
    if (!this.props.disableLink) {
      document.addEventListener('mouseup', this.calculateSelection);
    }
    if (this.inputRef.current && this.props.autofocus) {
      const textLength = this.props.value?.length || 0;
      this.inputRef.current.setSelectionRange(textLength, textLength);
      this.inputRef.current.focus();
    }
  }

  componentWillUnmount(): void {
    if (!this.props.disableLink) {
      document.removeEventListener('mouseup', this.calculateSelection);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.value !== this.props.value) {
      this.setState({ inputValue: this.props.value || '' });
    }
  }

  handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (this.props.oneLine && e.target.value) {
      e.target.value = e.target.value.replace(/\n/g, '');
    }
    this.setState({ inputValue: e.target.value || '' });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  handleBlur = (e: any) => {
    const { value, onBlur } = this.props;
    const valueChanged = value !== this.state.inputValue;
    if (onBlur && valueChanged) {
      onBlur(this.state.inputValue);
    }
    if (this.inputRef.current) {
      this.inputRef.current.scrollTop = 0;
    }
  };

  handleScroll = () => {
    if (!this.inputRef.current || !this.previewRef.current || document.activeElement === this.inputRef.current) return;
    this.inputRef.current.scrollTop = this.previewRef.current.scrollTop;
  };

  handleInputScroll = () => {
    if (!this.inputRef.current || !this.previewRef.current) return;
    this.previewRef.current.scrollTop = this.inputRef.current.scrollTop;
  };

  calculateSelection = (e: any) => {
    if (!this.state.selectionInProgress || !this.inputRef.current) return;
    // reset the state and user select attribute
    this.setState({ selectionInProgress: false });
    document.body.setAttribute('style', 'user-select: text; -webkit-user-select: text;');

    const selection = window.getSelection();
    if (!selection || !selection.anchorNode || !selection.anchorNode.parentNode) return;

    // Get elements where selection started and ended
    // anchor element is where mouse down happened
    // focus element is where mouse up happened
    const anchorNode =
      selection.anchorNode.parentNode?.parentNode instanceof HTMLPreElement
        ? selection.anchorNode
        : selection.anchorNode.parentNode;
    const focusNode =
      selection.focusNode?.parentNode?.parentNode instanceof HTMLPreElement
        ? selection.focusNode
        : selection.focusNode?.parentNode;

    // there was no selection, link was clicked
    if (
      e.target instanceof HTMLAnchorElement &&
      anchorNode === focusNode &&
      selection.anchorOffset === selection.focusOffset
    )
      return;

    // Get the containing <pre> element
    const parent = anchorNode.parentNode;
    if (!parent) return;

    let selectionStartNodeFound = false;
    let selectionStartIndex = 0;

    // Iterate the child elements to het the index of the first selected character
    // Selection could have started from a bigger to smaller index so the first character can either be in anchor or in focus node, so we check both
    parent.childNodes.forEach((child: any) => {
      if (!selectionStartNodeFound) {
        if (child === anchorNode) {
          if (anchorNode === focusNode) {
            selectionStartIndex += Math.min(selection.anchorOffset, selection.focusOffset);
          } else {
            selectionStartIndex += selection.anchorOffset;
          }
          selectionStartNodeFound = true;
        } else if (child === focusNode) {
          if (anchorNode === focusNode) {
            selectionStartIndex += Math.min(selection.anchorOffset, selection.focusOffset);
          } else {
            selectionStartNodeFound = true;
          }
          selectionStartIndex += selection.focusOffset;
        } else {
          selectionStartIndex += (child.innerText || child).length;
        }
      }
    });
    const selectionEndIndex = selectionStartIndex + selection.toString().length;

    // focus input and select the text that was selected from the preview element based on the indexes
    this.inputRef.current.focus({ preventScroll: true });
    this.inputRef.current.selectionStart = selectionStartIndex;
    this.inputRef.current.selectionEnd = selectionEndIndex;
    if (this.props.onFocus) this.props.onFocus();
  };

  render() {
    const {
      name,
      label = '',
      maxLength,
      minRows = 2,
      maxRows = 4,
      placeholder = '',
      icon,
      error,
      className,
      onKeyUp,
      disabled,
      inline,
      bare,
      noErrorMargin,
      required = true,
      fontStyle,
      expandsInfinitely = false,
      touched,
      disableLink
    } = this.props;

    const Wrapper = bare ? BareWrapper : inline ? InlineTextareaInputWrapper : TextareaInputWrapper;
    return (
      // @ts-ignore
      <Wrapper
        noErrorMargin={noErrorMargin}
        label={label}
        icon={icon}
        error={error}
        className={className}
        disabled={disabled}
        required={required}
        touched={touched}
      >
        <InnerWrapper hasLabel={!!label} inline={inline}>
          <Input
            inputRef={this.inputRef}
            name={name}
            onScroll={this.handleInputScroll}
            value={this.state.inputValue}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            placeholder={placeholder}
            minRows={minRows}
            maxRows={expandsInfinitely ? undefined : maxRows}
            maxLength={maxLength}
            onKeyUp={onKeyUp}
            disabled={disabled}
            style={fontStyle}
          />
          {!disableLink && !!this.state.inputValue && (
            <Preview
              className={'textareaPreview'}
              empty={!this.state.inputValue}
              onDragStart={() => this.setState({ selectionInProgress: false })}
              onMouseDown={e => {
                if (e.target instanceof HTMLTextAreaElement) return;
                this.setState({ selectionInProgress: true });
                if (this.previewRef.current) {
                  document.body.setAttribute('style', 'user-select: none; -webkit-user-select: none;');
                  this.previewRef.current.children[0].setAttribute('style', 'user-select: text; -webkit-user-select: text;');
                }
              }}
              fontStyle={fontStyle}
            >
              <pre style={fontStyle} onScroll={this.handleScroll} ref={this.previewRef}>
                <Linkify options={{ target: { url: '_blank' } }}>{this.state.inputValue}</Linkify>
              </pre>
            </Preview>
          )}
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default TextareaInput;

const Preview = styled(Row)<{ empty: boolean; fontStyle?: any }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: visible;
  opacity: 1;
  cursor: text;
  border-radius: 5px;
  a {
    text-decoration: none;
    color: ${colors.primaryPurple};
  }
  pre {
    box-sizing: border-box;
    background: ${props => (props.empty ? 'transparent !important' : props.theme.background)};
    margin: 0;
    padding: 0;
    overflow: auto;
    white-space: pre-wrap;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    line-height: 1.4;
    word-break: break-word;
    color: ${props => props.theme.textStrongColor};
    ${props => props.fontStyle && { ...props.fontStyle }};
    flex: 1;
    height: 100%;
    > span {
      display: block;
      width: 100%;
    }
  }
`;

const InnerWrapper = styled(Row)<{ hasLabel?: boolean; inline?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  &,
  ${Preview} {
    padding: ${props =>
      props.inline
        ? INLINE_TEXT_AREA_PADDING
        : props.hasLabel
        ? INPUT_LABEL_PADDING
        : `${INPUT_VERTICAL_PADDING}px ${INPUT_HORIZONTAL_PADDING}px`};
    ${props => props.hasLabel && 'padding-top: 24px;'};
  }
`;

const InlineTextareaInputWrapper = styled(InlineInputWrapper)`
  align-items: flex-start;

  & > div {
    align-items: flex-start;
    width: 100%;
  }

  & > div > div {
    width: 100%;
    padding: 0;
    textarea {
      padding: ${INLINE_TEXT_AREA_PADDING};
    }
  }

  span {
    left: ${INPUT_HORIZONTAL_PADDING}px;
  }
`;

const TextareaInputWrapper = styled(InputWrapper)`
  align-items: flex-start;
  padding: 0;
`;

//commented transition because of scroller jumping on focus
const Input = styled(TextareaAutosize)`
  color: ${({ theme }) => theme.textStrongColor};
  flex: 1;
  height: 50px;
  resize: none;
  outline: none;
  font-size: 13px;
  font-weight: 600;
  //transition: all 0.2s ease;
  line-height: 1.4;
  padding: 0;
  border: 0;
  width: 100%;
  font-family: 'Quicksand', sans-serif;
  background-color: transparent;

  &:disabled {
    background-color: ${({ theme }) => theme.backgroundLight} !important;
    cursor: not-allowed;
    &::placeholder {
      color: ${({ theme }) => theme.placeholder} !important;
    }
  }

  &::placeholder {
    color: ${({ theme }) => theme.placeholder};
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.placeholder};
  }
  &:focus + .textareaPreview {
    visibility: hidden;
    opacity: 0;
  }
`;
