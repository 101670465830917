import React, { PureComponent } from 'react';
import StepDialog from '../StepDialog';
import { BadgeStep, ComponentProps, QuizQuestion, submitStepAnswer, Substeps } from '../helpers';
import RadioButton from '../../radioButton/RadioButton';
import t from '@i18n/t';
import styled from 'styled-components';
import { Column } from '../../layoutUtils';
import colors from '@utils/colors';

enum QuizOption {
  SEMESTER_START = 'SEMESTER_START',
  TASK_START = 'TASK_START'
}

const correctAnswer = QuizOption.TASK_START;

type Props = ComponentProps;

type State = {
  selectedAnswer?: QuizOption;
  submitting: boolean;
};

class StartDateStepDialog extends PureComponent<Props, State> {
  readonly state = { selectedAnswer: undefined, submitting: false };

  renderDescription = completed => (
    <>
      <Column>
        <QuizQuestion>{t.TIME_NEEDED_STEP_QUIZ}</QuizQuestion>
        <QuizQuestion>
          {t.START_AHEAD_STEP_QUIZ_QUESTION}
          <span style={{ color: colors.positive }}> {completed && `(${t.COMPLETED})`}</span>
        </QuizQuestion>
        {Object.entries(QuizOption).map(([key, value]) => (
          <Radio
            key={key}
            label={t[value]}
            checked={value === (completed ? correctAnswer : this.state.selectedAnswer)}
            onChange={completed ? undefined : () => this.setState({ selectedAnswer: value })}
            disabled={completed}
          />
        ))}
      </Column>
    </>
  );

  submitAnswer = () => {
    const { selectedAnswer } = this.state;
    const answerCorrect = selectedAnswer === correctAnswer;
    submitStepAnswer(
      Substeps.START_AHEAD_QUIZ,
      BadgeStep.START_AHEAD_STEP,
      submitting => this.setState({ submitting }),
      answerCorrect,
      this.props.checkSubstep
    );
  };

  render() {
    const { onClose, completed: stepCompleted, userProgress } = this.props;
    const { submitting, selectedAnswer } = this.state;
    const quizCompleted = stepCompleted || (userProgress && userProgress.substeps.includes(Substeps.START_AHEAD_QUIZ));
    return (
      <StepDialog
        {...this.props}
        renderDescription={() => this.renderDescription(quizCompleted)}
        action={quizCompleted ? onClose : this.submitAnswer}
        quiz
        quizCompleted={quizCompleted}
        correct={correctAnswer === selectedAnswer}
        submitting={submitting}
      />
    );
  }
}

export default StartDateStepDialog;

const Radio = styled(RadioButton)<{ disabled: boolean; checked }>`
  margin-bottom: 5px;
  > span {
    color: ${props => props.disabled && (props.checked ? props.theme.textStrongColor : props.theme.textColor)};
  }
`;
