import React, { PureComponent } from 'react';
import styled from 'styled-components';
import t from '../../../i18n/t';
import { MembershipType, PaymentProcessor, PriceTier } from 'shovel-lib/types';
import { membershipApi } from 'shovel-lib';
import { alert, NotificationMode } from '../../common/snackbarNotifications/Notifications';
import ReactDOM from 'react-dom';
import { getErrorMessage } from '../../../api/network';
import Loader from 'react-loader-spinner';

import braintree from 'braintree-web';
import colors from '../../../utils/colors';
import gaAnalytics, { AnalyticsEvent } from '../../../config/gaAnalytics';
import { getPaymentSuccessLink } from '../../../utils/settingsUtils';

type Props = {
  selectedPlan: MembershipType | null;
  coupon: string;
  userId?: number;
  tier: PriceTier;
  executeRecaptcha?: any;
  onSuccess: any;
};

type State = {
  paypalCheckoutInstance?: any;
  paypalCheckoutProcessing: boolean;
};

class SubscribeToPlan extends PureComponent<Props, State> {
  state: State = {
    paypalCheckoutInstance: undefined,
    paypalCheckoutProcessing: false
  };

  async componentDidMount() {
    let res;
    if (this.props.userId) {
      const reCaptchaResponse = await this.props.executeRecaptcha('payment');
      res = await membershipApi.parentBraintreeClientToken(reCaptchaResponse);
    } else {
      res = await membershipApi.braintreeClientToken();
    }

    const clientInstance = await braintree.client.create({
      authorization: res.data
    });

    const paypalCheckoutInstance = await braintree.paypalCheckout.create({
      client: clientInstance
    });

    await paypalCheckoutInstance.loadPayPalSDK({
      vault: true
    });

    this.setState({ paypalCheckoutInstance });
  }

  async createBillingAgreement(data, actions) {
    // let discount = 0;
    const { coupon, selectedPlan, userId } = this.props;
    if (coupon) {
      const request = { coupon };
      let res;
      if (userId) {
        const reCaptchaResponse = await this.props.executeRecaptcha('payment');
        res = await membershipApi.parentBraintreeCheckCoupon(reCaptchaResponse, request);
      } else {
        res = await membershipApi.braintreeCheckCoupon(request);
      }
      if (!res.ok) {
        alert(t[getErrorMessage(res)]);
        return;
      }
      // discount = res.data!;
    }

    gaAnalytics.track(
      AnalyticsEvent.Subscribe.name,
      AnalyticsEvent.Subscribe.params(PaymentProcessor.BRAINTREE, selectedPlan!, coupon)
    );

    return this.state.paypalCheckoutInstance.createPayment({
      flow: 'vault'
      // amount: Number(pricePerPlan[this.props.selectedPlan!] * (100 - discount) / 100).toFixed(2),
      // currency: 'USD'
    });
  }

  async onApprove(data, actions) {
    this.setState({ paypalCheckoutProcessing: true });
    const { selectedPlan, coupon, userId } = this.props;
    const tokenRes = await this.state.paypalCheckoutInstance?.tokenizePayment(data);
    const request = {
      nonce: tokenRes.nonce,
      type: selectedPlan!,
      coupon: coupon
    };

    let res;
    if (userId) {
      const reCaptchaResponse = await this.props.executeRecaptcha('payment');
      res = await membershipApi.parentBraintreeCheckout({ userId, reCaptchaResponse }, request);
    } else {
      res = await membershipApi.braintreeCheckout(request);
    }

    if (res.ok) {
      const location = `/payment-success?type=${getPaymentSuccessLink(!!userId, this.props.tier, selectedPlan!)}`;
      this.props.onSuccess(location);
    } else {
      alert(t[getErrorMessage(res)]);
    }
    this.setState({ paypalCheckoutProcessing: false });
  }

  render() {
    if (!this.state.paypalCheckoutInstance) {
      return (
        <LoaderWrapper>
          <Loader type="Oval" color={colors.primaryPurple} height={22} width={22} />
        </LoaderWrapper>
      );
    }

    // @ts-ignore
    const PayPalButton = paypal.Buttons.driver('react', { React, ReactDOM });

    if (this.state.paypalCheckoutProcessing) {
      return (
        <LoaderWrapper>
          <Loader type="Oval" color={colors.primaryPurple} height={22} width={22} />
        </LoaderWrapper>
      );
    }

    return !this.props.selectedPlan ? (
      <DisabledPayPalButton>
        <PayPalButton
          onInit={(data, actions) => {
            actions.disable();
          }}
          style={{
            layout: 'horizontal',
            height: 37,
            tagline: false
          }}
        />
      </DisabledPayPalButton>
    ) : (
      <PayPalButton
        createBillingAgreement={(data, actions) => this.createBillingAgreement(data, actions)}
        onApprove={(data, actions) => this.onApprove(data, actions)}
        style={{
          layout: 'horizontal',
          height: 37,
          tagline: false
        }}
      />
    );
  }
}

export default SubscribeToPlan;

const DisabledPayPalButton = styled.div`
  opacity: 0.4;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 37px;
`;
