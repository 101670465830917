import React, { PureComponent } from 'react';
import Popup from 'reactjs-popup';
import Button from '../../common/buttons/Button';
import t from '../../../i18n/t';
import { FlexColumn, FlexRow, Row } from '../../common/layoutUtils';
import styled from 'styled-components';
import { RepeatPatternDialogStyle } from '../../../utils/popupUtils';
import { MediumText, RegularText } from '../../../utils/typography';
import CheckBox from '../../common/CheckBox';
import RepeatingDays from './RepeatingDays';
import { DatepickerFormats, Formats, SemesterRangeInfo, UntilOption } from 'shovel-lib/types';
import { RootState } from '../../../state/rootReducer';
import { bindActionCreators, Dispatch } from 'redux';
import { taskActions } from '../../../state/task';
import { connect } from 'react-redux';
import { getRepeatPatternDialogState } from '../../../state/task/selectors';
import { CreateRepeatPatternDialogType } from '../../../state/task/types';
import { getSemesterRangeInfo } from '../../../state/semester/selectors';
import { WeekdayStr } from 'rrule';
import { DateInput, NumberInput } from '../../common/inputs';
import { toJSUtcDate, toMomentDate, toMomentUtcDate } from 'shovel-lib/utils/timeUtils';
import RadioButton from '../../common/radioButton/RadioButton';

type PropsFromDispatch = {
  close: typeof taskActions.closeRepeatPatternDialog;
};

type PropsFromState = {
  open: boolean;
  data?: CreateRepeatPatternDialogType;
  semesterInfo: SemesterRangeInfo | null;
};

type Props = PropsFromDispatch & PropsFromState;

type State = {
  name: boolean;
  continueNumbering: boolean;
  description: boolean;
  subtasks: boolean;
  startAhead: boolean;
  pages: boolean;
  estimate: boolean;
  interval: number;
  onDays: WeekdayStr[];
  until?: Date;
  untilOption: any;
};

class RepeatPatternDialog extends PureComponent<Props> {
  setUntilOption = () => {
    const { data, semesterInfo } = this.props;
    if (data?.repeatOptions.untilOption === UntilOption.CLASSES) {
      return toMomentDate(semesterInfo?.classesEnds).isBefore(data?.date) ? UntilOption.EXAMS : UntilOption.CLASSES;
    }
    return data?.repeatOptions.untilOption;
  };

  getInitialState = () => {
    return {
      name: this.props.data?.repeatOptions.copyFields.name,
      continueNumbering: this.props.data?.repeatOptions.copyFields.continueNumbering,
      description: this.props.data?.repeatOptions.copyFields.description,
      subtasks: this.props.data?.repeatOptions.copyFields.subtasks,
      startAhead: this.props.data?.repeatOptions.copyFields.startAhead,
      pages: this.props.data?.repeatOptions.copyFields.pages,
      estimate: this.props.data?.repeatOptions.copyFields.estimate,
      interval: this.props.data?.repeatOptions.interval!,
      onDays: this.props.data?.repeatOptions.onDays!,
      until: this.props.data?.repeatOptions.until!,
      untilOption: this.setUntilOption()
    };
  };
  //@ts-ignore
  state: State = this.getInitialState();

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.data !== this.props.data) {
      this.setState(this.getInitialState());
    }
  }

  toggle = (name: string) => this.setState({ [name]: !this.state[name] });

  setDays = value => this.setState({ onDays: value });

  setInterval = value => this.setState({ interval: value });

  setUntil = (untilOption, until) => this.setState({ until, untilOption });

  cancel = () => {
    this.setState(this.getInitialState());
  };

  getCopyOptions = () => {
    const defaultOptions = [
      {
        name: 'name',
        label: t.TASK_NAME
      },
      //TODO: v1 without desc
      // {
      //   name: 'description',
      //   label: t.DESCRIPTION
      // },
      ...(this.props.data?.isSubtasksOptionHidden
        ? []
        : [
            {
              name: 'subtasks',
              label: t.SUBTASKS
            }
          ]),
      {
        name: 'startAhead',
        label: t.START_AHEAD
      }
    ];

    if (this.props.data?.isReading) {
      return [
        ...defaultOptions,
        {
          name: 'pages',
          label: t.PAGES
        }
      ];
    }

    if (!this.props.data?.isPageBasedEstEnabled) {
      return [
        ...defaultOptions,
        {
          name: 'estimate',
          label: t.TASK_ESTIMATE
        }
      ];
    }

    return defaultOptions;
  };

  render() {
    const { close, semesterInfo, data } = this.props;
    const { interval, onDays, untilOption, until } = this.state;
    const classesDisabled = toMomentUtcDate(semesterInfo?.classesEnds!).isBefore(data?.date);
    const options = this.getCopyOptions();
    if (!data) return null;
    return (
      <Dialog
        open={true}
        contentStyle={RepeatPatternDialogStyle}
        overlayStyle={data.zIndex ? { zIndex: data.zIndex } : undefined}
        onClose={close}
        lockScroll={false}
        closeOnEscape={false}
        closeOnDocumentClick={false}
      >
        <Container>
          <Content>
            <PatternColumn>
              <Title>{t.REPEAT_PATTERN}:</Title>
              <RepeatIntervalWrapper>
                <MediumText>{t.REPEATS_EVERY}</MediumText>
                <IntervalInput
                  inline
                  min={1}
                  value={interval}
                  placeholder={'1'}
                  autosize
                  onChange={value => this.setInterval(value.target.value)}
                />
                {interval > 1 ? t.WEEKS.toLowerCase() : t.WEEK.toLowerCase()}
              </RepeatIntervalWrapper>
              <RepeatingDays
                initialDate={data.date}
                weekdays={onDays}
                onWeekdayClick={(days?: Array<any>) => this.setDays(days)}
                fontSize={12}
              />
              <div>
                <MediumText>{`${t.ENDS_REPEATING}:`}</MediumText>
                <RepeatRadioButton
                  label={`${t.END_OF_CLASSES} ${toMomentDate(semesterInfo?.classesEnds).format(Formats.MONTH_DAY_FORMAT)}`}
                  checked={untilOption === UntilOption.CLASSES}
                  onChange={() => this.setUntil(UntilOption.CLASSES, semesterInfo?.classesEnds)}
                  disabled={classesDisabled}
                  fontSize={12}
                />
                <RepeatRadioButton
                  label={`${t.END_EXAM} ${toMomentDate(semesterInfo?.ends).format(Formats.MONTH_DAY_FORMAT)}`}
                  checked={untilOption === UntilOption.EXAMS}
                  onChange={() => this.setUntil(UntilOption.EXAMS, semesterInfo?.ends)}
                  fontSize={12}
                />
                <CustomRadioButton>
                  <RepeatRadioButton
                    label={t.ENDS_ON_CUSTOM_DATE}
                    checked={!untilOption}
                    onChange={() => this.setUntil(undefined, until)}
                    fontSize={12}
                  />
                  {!untilOption && (
                    <UntilInput
                      noErrorMargin
                      value={!untilOption ? until || data.date : null}
                      onChange={customDate => this.setUntil(undefined, customDate)}
                      disabled={!!untilOption}
                      minDate={data.date}
                      maxDate={toJSUtcDate(semesterInfo?.ends!)}
                      inline
                      dateFormatFn={() => DatepickerFormats.SHORT}
                      fontSize={12}
                    />
                  )}
                </CustomRadioButton>
              </div>
            </PatternColumn>
            <CopyOptionsColumn>
              <Title>{t.COPY_FOR_EACH_TASK}</Title>
              {options.map((option, index) => (
                <CustomCheckBox
                  key={index}
                  label={option.label}
                  onChange={() => this.toggle(option.name)}
                  checked={this.state[option.name]}
                />
              ))}
              {/*TODO: for now without this option*/}
              {/*<ToggleWrapper>*/}
              {/*  <CustomToggle*/}
              {/*    onChange={() => this.toggle('continueNumbering')}*/}
              {/*    active={this.state.continueNumbering}*/}
              {/*    label={t.CONTINUE_NUMBERING}*/}
              {/*    reverse*/}
              {/*  />*/}
              {/*  <TippyTooltip*/}
              {/*    target={*/}
              {/*      <Row>*/}
              {/*        <MaterialIcon name={'help'} color={colors.primaryPurple} size={20} />*/}
              {/*      </Row>*/}
              {/*    }*/}
              {/*    content={t.CONTINUE_NUMBERING_TOOLTIP}*/}
              {/*    preformattedText*/}
              {/*  />*/}
              {/*</ToggleWrapper>*/}
            </CopyOptionsColumn>
          </Content>
          <ButtonsWrapper>
            <Button
              size={'sm'}
              onClick={() => {
                this.cancel();
                close();
              }}
              withoutBorder
            >
              {t.CANCEL}
            </Button>
            <Button
              size={'sm'}
              onClick={() => {
                if (!this.state.until) {
                  const until =
                    this.state.untilOption === UntilOption.CLASSES
                      ? semesterInfo?.classesEnds
                      : this.state.untilOption === UntilOption.EXAMS
                      ? semesterInfo?.ends
                      : data?.date;
                  data?.save(this.state, until);
                } else {
                  data?.save(this.state, null);
                }
                close();
              }}
              filled
            >
              {t.SET_DATES}
            </Button>
          </ButtonsWrapper>
        </Container>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  ...getRepeatPatternDialogState(state),
  semesterInfo: getSemesterRangeInfo(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: taskActions.closeRepeatPatternDialog
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RepeatPatternDialog);

const Dialog = styled(Popup)`
  &-content {
    background: ${({ theme }) => theme.background} !important;
  }

  &-overlay {
    background: ${({ theme }) => theme.modalOverlayColor} !important;
  }
`;

const Container = styled(FlexColumn)`
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
`;

const Content = styled(FlexRow)`
  border-bottom: 1px solid ${props => props.theme.borderColor};
`;

const PatternColumn = styled(FlexColumn)`
  flex: 0.55;
  height: 100%;
  padding: 0 20px;
  border-right: 1px solid ${props => props.theme.borderColor};
`;

const CopyOptionsColumn = styled(FlexColumn)`
  flex: 0.45;
  height: 100%;
  padding: 0 20px;
`;

const ButtonsWrapper = styled(Row)`
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;
  > button:first-child {
    margin-right: 10px;
  }
`;

const Title = styled(MediumText)`
  padding: 20px 0 2px 0;
`;

const CustomCheckBox = styled(CheckBox)`
  margin-top: 8px;
`;

const RepeatIntervalWrapper = styled(RegularText)`
  display: flex;
  line-height: 1;
  height: 22px;
  align-items: center;
  margin: 20px 0;
`;

const IntervalInput = styled(NumberInput)`
  min-width: unset !important;
  max-width: 110px;
  margin: 0 3px;
  overflow: hidden;
`;

const RepeatRadioButton = styled(RadioButton)`
  margin: 10px 0 0;
`;

const CustomRadioButton = styled(Row)`
  align-items: center;
  ${RepeatRadioButton} > span {
    color: ${props => props.theme.textColor};
  }
`;

const UntilInput = styled(DateInput)<{ fontSize?: number }>`
  margin-left: 2px;
  margin-top: 9px;
  ${props => props.fontSize && `span { font-size: ${props.fontSize}px !important;}`}
`;
