import React, { FC, useState } from 'react';
import { ExtraLargeText, LargeText, RegularText } from '../../../utils/typography';
import colors from '../../../utils/colors';
import styled from 'styled-components';
import { Column, Row } from '../../common/layoutUtils';
import t from '../../../i18n/t';
import { MembershipType, PaymentProcessor, PriceTier } from 'shovel-lib/types';
import { TextInput } from '../../common/inputs';
import BraintreeSubscribe from './BraintreeSubscribe';
import StripeSubscribe from './StripeSubscribe';
import { connect } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { getFirstName } from '../../../state/semester/selectors';
import RadioButton from '../../common/radioButton/RadioButton';
import BraintreeCheckout from './BraintreeCheckout';
import { dealTextPerPlan, pricePerPlan, toPriceText } from '../../../utils/settingsUtils';
import Button from '../../common/buttons/Button';
import { capitalize } from '../../../utils/textUtils';
import ParentLink, { BottomSection } from './ParentLink';
import { screenSizes } from '../../../utils/screenUtils';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { bindActionCreators, Dispatch } from 'redux';
import { commonActions } from '../../../state/common';

type Props = {
  firstName?: string;
  tier?: PriceTier;
  //props to know is it user came from parent payment page
  parentUserId?: number;
  goToSuccessPaymentPage: typeof commonActions.goToSuccessPaymentPage;
};

const renderSection = (title: string, text: any, type: MembershipType, tier: PriceTier, selectedPlan, setSelectedPlan) => {
  const selected = type === selectedPlan;
  const isFirstTier = tier === PriceTier.TIER_ONE;
  return (
    <Subscription selected={selected} onClick={() => setSelectedPlan(type)}>
      <ExtraLargeText strong>{title}</ExtraLargeText>
      <Dash isFirstTier={isFirstTier} />
      {!isFirstTier && <OldPrice strong>{toPriceText(pricePerPlan[PriceTier.TIER_ONE][type])}</OldPrice>}
      <Row>
        <Price isFirstTier={isFirstTier} style={{ fontWeight: 'normal', marginTop: -10 }}>
          $
        </Price>
        <Price isFirstTier={isFirstTier} style={{ fontSize: 48 }}>
          {pricePerPlan[tier][type]}
        </Price>
      </Row>
      <Description>
        <Deal inverted={type === MembershipType.LIFETIME}>{dealTextPerPlan[tier][type]}</Deal>
        <RegularText strong>{text}</RegularText>
      </Description>
      <SelectButton size={'lg'} filled color={`${colors.primaryPurple}1A`} textColor={colors.primaryPurple}>
        {selected ? capitalize(t.SELECTED) : t.SELECT}
      </SelectButton>
    </Subscription>
  );
};

const renderResponsivePrice = (
  title: string,
  text: any,
  type: MembershipType,
  tier: PriceTier,
  selectedPlan,
  setSelectedPlan
) => {
  const selected = type === selectedPlan;
  const isFirstTier = tier === PriceTier.TIER_ONE;
  return (
    <Row>
      <RadioPrice label={title} checked={selected} onChange={() => setSelectedPlan(type)} />
      {!isFirstTier && <OldPrice strong>{toPriceText(pricePerPlan[PriceTier.TIER_ONE][type])}</OldPrice>}
      <Row>
        <Price isFirstTier={isFirstTier} style={{ fontWeight: 'normal', marginTop: -10 }}>
          $
        </Price>
        <Price isFirstTier={isFirstTier} style={{ fontSize: 48 }}>
          {pricePerPlan[tier][type]}
        </Price>
      </Row>
    </Row>
  );
};

const SubscribeToPlan: FC<Props> = ({ tier = PriceTier.TIER_ONE, parentUserId, firstName, goToSuccessPaymentPage }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentProcessor, setPaymentProcessor] = useState(PaymentProcessor.STRIPE);
  const [coupon, setCoupon] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <Container>
      {!parentUserId && (
        <Title>
          <ExtraLargeText strong>{t.PRICING_OPTIONS_TITLE}</ExtraLargeText>
          <LargeText className={'pricing-options-text'}>{t.PRICING_OPTIONS_TEXT}</LargeText>
        </Title>
      )}
      <Pricing>
        {renderSection(t.MONTHLY, t.UNLIMITED_MONTH, MembershipType.MONTHLY, tier, selectedPlan, setSelectedPlan)}
        {renderSection(t.LIFETIME, t.UNLIMITED_LIFETIME, MembershipType.LIFETIME, tier, selectedPlan, setSelectedPlan)}
        {renderSection(t.ANNUAL, t.UNLIMITED_YEAR, MembershipType.ANNUAL, tier, selectedPlan, setSelectedPlan)}
      </Pricing>
      <ResponsivePricing withCoupon={tier === PriceTier.TIER_ONE}>
        <LargeText strong className={'payment-plan-responsive'}>
          {`1. ${t.SELECT_PAY_PLAN}`}
        </LargeText>
        {renderResponsivePrice(t.MONTHLY, t.UNLIMITED_MONTH, MembershipType.MONTHLY, tier, selectedPlan, setSelectedPlan)}
        {renderResponsivePrice(
          t.LIFETIME,
          t.UNLIMITED_LIFETIME,
          MembershipType.LIFETIME,
          tier,
          selectedPlan,
          setSelectedPlan
        )}
        {renderResponsivePrice(t.ANNUAL, t.UNLIMITED_YEAR, MembershipType.ANNUAL, tier, selectedPlan, setSelectedPlan)}
      </ResponsivePricing>
      {!parentUserId && <ParentLink />}
      <BottomSection>
        <Column className={'payment-method'}>
          {tier === PriceTier.TIER_ONE && (
            <>
              <RegularText>{t.HAVE_A_COUPON}</RegularText>
              <DiscountCouponInput placeholder={t.ENTER_IT_HERE} value={coupon} onChange={e => setCoupon(e.target.value)} />
            </>
          )}
          <LargeText strong className={'payment-option-responsive-title'}>
            {`2. ${t.SELECT_PAYMENT_METHOD}`}
          </LargeText>
          <RegularText className={'payment-option-title'}>{t.CONTINUE_WITH}:</RegularText>
          <Radio
            checked={paymentProcessor === PaymentProcessor.STRIPE}
            label={t.CARD_OR_APPLE_GOOGLE_PAY}
            onChange={() => setPaymentProcessor(PaymentProcessor.STRIPE)}
          />
          <Radio
            checked={paymentProcessor === PaymentProcessor.BRAINTREE}
            label={t.PAYPAL}
            onChange={() => setPaymentProcessor(PaymentProcessor.BRAINTREE)}
          />
          {paymentProcessor === PaymentProcessor.STRIPE ? (
            <StripeSubscribe
              selectedPlan={selectedPlan}
              coupon={coupon}
              userId={parentUserId}
              executeRecaptcha={executeRecaptcha}
            />
          ) : selectedPlan === MembershipType.LIFETIME ? (
            <BraintreeCheckout
              coupon={coupon}
              tier={tier}
              userId={parentUserId}
              executeRecaptcha={executeRecaptcha}
              onSuccess={location => goToSuccessPaymentPage(location)}
            />
          ) : (
            <BraintreeSubscribe
              selectedPlan={selectedPlan}
              coupon={coupon}
              userId={parentUserId}
              tier={tier}
              executeRecaptcha={executeRecaptcha}
              onSuccess={location => goToSuccessPaymentPage(location)}
            />
          )}
        </Column>
        <Column className={'coupon-extra-text'}>
          <RegularText style={{ whiteSpace: 'pre-wrap', maxWidth: 330, marginLeft: 20 }}>
            {parentUserId ? t.PARENT_SUBSCRIBING_TEXT : t.SUBSCRIBING_TEXT(firstName)}
          </RegularText>
        </Column>
      </BottomSection>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  firstName: getFirstName(state)
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      goToSuccessPaymentPage: commonActions.goToSuccessPaymentPage
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeToPlan);

const Container = styled(Column)`
  max-width: 800px;
  margin: 10px auto 0;
`;

const Pricing = styled(Row)`
  align-items: stretch;
  @media (max-width: ${screenSizes.xsmall}) {
    display: none;
  }
`;

const ResponsivePricing = styled(Column)<{ withCoupon?: boolean }>`
  width: fit-content;
  margin: 0 auto;
  padding: 15px;
  box-sizing: border-box;
  border-top: 1px solid ${props => props.theme.borderColor};
  ${props => !props.withCoupon && `border-bottom: 1px solid ${props => props.theme.borderColor}; padding-bottom: 10px;`};
  .payment-plan-responsive {
    display: none;
  }
  @media (max-width: ${screenSizes.xsmall}) {
    .payment-plan-responsive {
      display: block;
    }
  }
  @media (min-width: calc(${screenSizes.xsmall} + 1px)) {
    display: none;
  }
`;

const Title = styled(Column)`
  text-align: center;
  margin-bottom: 30px;
  @media (max-width: ${screenSizes.xsmall}) {
    .pricing-options-text {
      display: none;
    }
  }
`;

const Subscription = styled(Column)<{ selected: boolean }>`
  position: relative;
  align-items: center;
  width: 280px;
  flex: 1;
  box-sizing: border-box;
  border: 1px solid ${({ theme, selected }) => (selected ? colors.primaryPurple : theme.borderColor)};
  box-shadow: ${({ theme, selected }) => (selected ? `0 6px 25px ${theme.largeBoxShadow}` : 'none')};
  border-radius: 5px;
  cursor: pointer;
  padding: 30px 20px;
  margin: 0 10px;
  background-color: ${({ theme }) => theme.background};
  transition: all 0.2s ease;

  &:hover:not(.selected) {
    ${props => !props.selected && 'transform: scale(1.02)'};
  }

  @media (max-width: ${screenSizes.small}) {
    padding: 15px 10px;
    width: unset;
  }
`;

const Dash = styled.div<{ isFirstTier?: boolean }>`
  margin: ${props => `10px 0 ${props.isFirstTier ? 40 : 20}px`};
  flex-shrink: 0;
  height: 1px;
  width: 40px;
  background: ${props => props.theme.textColor};
  @media (max-width: ${screenSizes.small}) {
    margin-bottom: 20px;
  }
`;

const OldPrice = styled(LargeText)`
  text-decoration: line-through;
  margin-bottom: 5px;
  @media (max-width: ${screenSizes.xsmall}) {
    margin-bottom: 0;
    margin-right: 15px;
  }
`;

const Price = styled(ExtraLargeText)<{ isFirstTier?: boolean }>`
  color: ${colors.positive};
  margin-bottom: ${props => (props.isFirstTier ? 25 : 15)}px;
  line-height: 1;
  @media (max-width: ${screenSizes.small}) {
    margin-bottom: 5px;
    line-height: 1.3;
  }
  @media (max-width: 320px) {
    font-size: 32px !important;
  }
`;

const Deal = styled(LargeText)<{ inverted?: boolean }>`
  width: 100%;
  text-align: center;
  color: ${props => (props.inverted ? colors.white : colors.positive)};
  background: ${props => (props.inverted ? colors.positive : 'transparent')};
  margin-bottom: 5px;
`;

const Description = styled(Column)`
  margin-bottom: 30px;
  text-align: center;
  flex: 1;
  justify-content: flex-end;
  @media (max-width: ${screenSizes.small}) {
    margin-bottom: 10px;
  }
`;

const DiscountCouponInput = styled(TextInput)`
  border-radius: 0 !important;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};

  &:active {
    border-color: ${colors.primaryPurple};
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    color: ${props => props.theme.placeholder};
    -webkit-text-fill-color: transparent;
  }
`;

const Radio = styled(RadioButton)`
  margin-bottom: 10px;
  @media (max-width: ${screenSizes.xsmall}) {
    margin-bottom: 20px;
  }
`;

const SelectButton = styled(Button)<{ selected?: boolean }>`
  max-width: 165px;
  width: 100%;
  border-color: ${props => (props.selected ? colors.primaryPurple : 'transparent')};
`;

const RadioPrice = styled(RadioButton)<{ checked?: boolean }>`
  margin-bottom: 0;
  flex-shrink: 0;
  width: 135px;
  border-right: 1px solid ${props => props.theme.textColor};
  margin-right: 15px;
  > span {
    font-size: 21px;
    font-weight: bold;
    color: ${props => (props.checked ? colors.primaryPurple : props.theme.textStrongColor)};
  }
  @media (max-width: 320px) {
    width: 100px;
    > span {
      font-size: 16px;
    }
  }
`;
