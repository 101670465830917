import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import CountryLanguage from 'country-language';

import * as actions from './actions';
import { callApi } from '../common/operations';
import { getErrorMessage } from '../../api/network';
import { accountApi, membershipApi, settingsApi } from '../../api';
import { alert, NotificationMode } from '@components/common/snackbarNotifications/Notifications';
import t from '../../i18n/t';
import { logout } from '../auth/actions';
import { registerDatepickerLocale, updateMomentLocale } from '../../utils/settingsUtils';
import { settingsSelectors } from './index';
import { initialOnboardingStatus } from './reducers';
import { CalendarEventType, OnboardingStep, PaymentProcessor, SemesterStatus } from 'shovel-lib/types';
import { getOverallCushion } from '../semester/actions';
import { allBadges, allSteps, BadgeStep, Substeps } from '@components/common/onboardingBar/helpers';
import * as taskActions from '../task/actions';
import { eventActions } from '../events';
import { planTask } from '../calendar/actions';
import * as termActions from '../terms/actions';
import { getType } from 'typesafe-actions';
import { badgesApi } from '../../api/badgesApi';
import { authenticationApi } from 'shovel-lib';
import { BadgesInfo, initialMonthFilterState } from './types';
import { courseActions } from '../course';
import { semesterSelectors } from '../semester';
import { hasSettings } from './selectors';
import gaAnalytics, { AnalyticsEvent } from '../../config/gaAnalytics';
import { calendarActions } from '../../state/calendar';
import storage from '@utils/storage';

function* getUserSubscription() {
  const apiCall = call(membershipApi.getMyMembershipInfo);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getUserSubscription.success(response.data));
    // if (isSubscription(response.data.type)) {
    //   yield put(actions.getCard.request());
    // }
  } else {
    yield put(actions.getUserSubscription.failure(getErrorMessage(response)));
  }
}

function* updateAccount(action: ReturnType<typeof actions.updateAccount.request>) {
  const apiCall = call(accountApi.updateAccount, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.updateAccount.success(action.payload));
  } else {
    yield put(actions.updateAccount.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* updateEmail(action: ReturnType<typeof actions.updateEmail.request>) {
  const { onSuccess, ...request } = action.payload;
  const apiCall = call(authenticationApi.updateEmail, request);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.updateEmail.success(action.payload));
    onSuccess();
  } else {
    yield put(actions.updateEmail.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* updatePassword(action: ReturnType<typeof actions.updatePassword.request>) {
  const apiCall = call(accountApi.updatePassword, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.updatePassword.success(action.payload));
  } else {
    yield put(actions.updatePassword.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* deleteAccount(action: ReturnType<typeof actions.deleteAccount.request>) {
  const apiCall = call(accountApi.deleteAccount, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.deleteAccount.success());
    yield put(logout());
    alert(t.ACCOUNT_SUCCESSFULLY_DELETED, NotificationMode.SUCCESS);

    const trialLeft = yield select(settingsSelectors.getDaysTrialLeft);
    yield fork(gaAnalytics.track, AnalyticsEvent.DeleteAccount.name, AnalyticsEvent.DeleteAccount.params(trialLeft || 0));
  } else {
    yield put(actions.deleteAccount.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* cancelSubscription(action: ReturnType<typeof actions.cancelSubscription.request>) {
  const api =
    action.payload.paymentProcessor === PaymentProcessor.STRIPE
      ? membershipApi.stripeCancelSubscription
      : membershipApi.braintreeCancelSubscription;
  // @ts-ignore
  const apiCall = call(api, action.payload.cancellationReasons);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.cancelSubscription.success(response.data));
    yield call(gaAnalytics.track, AnalyticsEvent.CancelSubscription.name);
  } else {
    yield put(actions.cancelSubscription.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* reactivateSubscription(action: ReturnType<typeof actions.reactivateSubscription.request>) {
  const api =
    action.payload.paymentProcessor === PaymentProcessor.STRIPE
      ? membershipApi.stripeReactivateSubscription
      : membershipApi.braintreeReactivateSubscription;
  const apiCall = call(api);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.reactivateSubscription.success(response.data));
  } else {
    yield put(actions.reactivateSubscription.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

// @ts-ignore
const importMomentLocales = () => import('moment/min/locales');

export function* automaticUserSettings(settings, countryCode) {
  yield call(importMomentLocales);
  const checkLocales: string[] = [];
  CountryLanguage.getCountryLanguages(countryCode).forEach(l => {
    if (l.iso639_1) {
      checkLocales.push(`${l.iso639_1}-latn`, `${l.iso639_1}-${countryCode.toLowerCase()}`, l.iso639_1);
    }
  });
  checkLocales.push('en-gb'); // will be selected if no other is found
  const locale = moment.localeData(checkLocales);
  settings.firstDayOfWeek = locale.firstDayOfWeek();
  settings.time24Format = !locale
    .longDateFormat('LT')
    .toLowerCase()
    .endsWith('a');
  yield put(actions.saveUserSettings.request(settings));
}

function* getUserSettings() {
  const apiCall = call(settingsApi.getUserSettings);
  const { ok, ...response } = yield call(callApi, apiCall);
  let settings = yield select(settingsSelectors.getUserSettings);
  if (ok) {
    const monthFilter = storage.getMonthFilter();
    settings = { ...settings, ...response.data, monthFilter };
    yield put(actions.getUserSettings.success(response.data ? settings : null));
  } else {
    yield put(actions.getUserSettings.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
  const { startOfDay, time24Format, dateFormat, firstDayOfWeek } = settings;
  const personalizationSettings = { startOfDay, time24Format, dateFormat, firstDayOfWeek };
  const countryCode = yield select(semesterSelectors.getCountryCode);
  if (!response.data && !!countryCode) {
    yield call(automaticUserSettings, personalizationSettings, countryCode);
  } else {
    updateMomentLocale(personalizationSettings);
    registerDatepickerLocale(personalizationSettings);
  }
}

function* saveUserSettings(action: ReturnType<typeof actions.saveUserSettings.request>) {
  const settings = yield select(settingsSelectors.getUserSettings);
  const { monthFilter = initialMonthFilterState, ...request } = { ...settings, ...action.payload };
  const apiCall = call(settingsApi.saveUserSettings, request);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    storage.saveMonthFilter(monthFilter);
    yield put(actions.saveUserSettings.success({ ...request, monthFilter }));
    const { startOfDay, time24Format, dateFormat, firstDayOfWeek } = request;
    const personalizationSettings = { startOfDay, time24Format, dateFormat, firstDayOfWeek };
    updateMomentLocale(personalizationSettings);
    registerDatepickerLocale(personalizationSettings);
  } else {
    yield put(actions.saveUserSettings.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* savePileState(action: ReturnType<typeof actions.savePileState>) {
  yield put(actions.saveUserSettings.request({ pileState: action.payload }));
}

function* changeOnboardingStatus(action: ReturnType<typeof actions.changeOnboardingStatus>) {
  const onboardingStatus = yield select(settingsSelectors.getOnboardingStatus);
  if (!onboardingStatus[action.payload]) {
    yield put(
      actions.saveUserSettings.request({
        onboardingStatus: {
          ...onboardingStatus,
          [action.payload]: true
        }
      })
    );
  }
}

function* resetOnboarding() {
  const shouldReset = yield select(hasSettings);
  if (shouldReset) {
    yield put(
      actions.saveUserSettings.request({
        onboardingStatus: initialOnboardingStatus
      })
    );
  }
}

function* skipOnboarding() {
  const onboardingStatus = {
    [OnboardingStep.HINTS]: true,
    [OnboardingStep.AWAKE_TIME]: true,
    [OnboardingStep.STUDY_TIME]: true,
    [OnboardingStep.SCHEDULE]: true,
    [OnboardingStep.COURSE]: true,
    [OnboardingStep.TASK_LIST_VIEW]: true,
    [OnboardingStep.PLANNER_NAVIGATION]: true,
    [OnboardingStep.PLANNER_DIALOG]: true,
    [OnboardingStep.PLANNER]: true,
    [OnboardingStep.TASK_DIALOG]: true,
    [OnboardingStep.EVENT_REPEATING_OPTIONS]: true
  };
  yield put(actions.saveUserSettings.request({ onboardingStatus }));
}

function* updatePlannedInCushion(action: ReturnType<typeof actions.updatePlannedInCushion.request>) {
  const apiCall = call(settingsApi.updatePlannedInCushion, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.updatePlannedInCushion.success(action.payload));
    yield put(getOverallCushion.request());
  } else {
    yield put(actions.updatePlannedInCushion.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* checkStep(action: ReturnType<typeof actions.checkStep>) {
  const userBadges = { ...(yield select(settingsSelectors.getUserBadges)) };
  if (userBadges.steps.includes(action.payload)) return;

  userBadges.steps = [...userBadges.steps, action.payload];

  const stepBadge = allBadges.find(badge => badge.steps.includes(action.payload));

  if (stepBadge && stepBadge.steps.every(s => userBadges.steps.includes(s)) && !userBadges.badges.includes(stepBadge.name)) {
    userBadges.badges = [...userBadges.badges, stepBadge.name];
    const trialLeft = yield select(settingsSelectors.getDaysTrialLeft);
    const paymentProcessor = yield select(settingsSelectors.getPaymentProcessor);
    if (trialLeft !== null && stepBadge.givesFreeTrial && paymentProcessor !== PaymentProcessor.BRAINTREE) {
      yield put(actions.addFreeTrialDay.request(userBadges));
      return;
    }
  }
  yield put(actions.updateBadgesInfo.request(userBadges));
}

function* checkSubstep(action: ReturnType<typeof actions.checkSubstep>) {
  const userBadges = yield select(settingsSelectors.getUserBadges);

  const step = allSteps[action.payload.step];
  if (!step || userBadges.substeps.includes(action.payload.substep) || userBadges.steps.includes(action.payload.step))
    return;

  userBadges.substeps = [...userBadges.substeps, action.payload.substep];

  if (Object.keys(step.substeps).every(substepName => userBadges.substeps.includes(substepName))) {
    yield put(actions.checkStep(action.payload.step));
    yield fork(gaAnalytics.track, `tourstage${step.number}`, { title: step.title });
    return;
  }
  yield put(actions.updateBadgesInfo.request({ ...userBadges }));
}

function* getBadgesInfo() {
  const apiCall = call(badgesApi.getUserBadges);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    if (response.data && !response.data.completed) {
      yield put(actions.startOnboardingListener());
      const dataChanged = checkIfBadgesInfoNeedsUpdate(response.data);
      if (dataChanged) {
        yield put(actions.updateBadgesInfo.request(dataChanged));
      }
    }
    yield put(actions.getBadgesInfo.success(response.data));
  } else {
    yield put(actions.getBadgesInfo.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* updateBadgesInfo(action: ReturnType<typeof actions.updateBadgesInfo.request>) {
  const apiCall = call(badgesApi.saveUserBadges, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.updateBadgesInfo.success(action.payload));
    if (action.payload.completed) {
      yield put(actions.stopOnboardingListener());
      yield put(actions.toggleOnboardingBar(false));
    }
  } else {
    yield put(actions.updateBadgesInfo.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* addFreeTrialDay(action: ReturnType<typeof actions.addFreeTrialDay.request>) {
  const apiCall = call(badgesApi.addFreeTrialDay);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(actions.addFreeTrialDay.success(response.data));
    yield put(actions.updateBadgesInfo.request(action.payload));
  } else {
    yield put(actions.addFreeTrialDay.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

const checkIfBadgesInfoNeedsUpdate = (data: BadgesInfo) => {
  const { badges, steps, substeps } = data;
  let { completed } = data;
  let needsUpdate = false;
  Object.entries(allSteps).forEach(([key, step]) => {
    if (
      !steps.includes(key) &&
      Object.entries(step.substeps).every(([substepName]) => substeps.includes(substepName as Substeps))
    ) {
      steps.push(key);
      needsUpdate = true;
    }
  });
  allBadges.forEach(badge => {
    if (!badges.includes(badge.name) && badge.steps.every(stepName => steps.includes(stepName))) {
      badges.push(badge.name);
      needsUpdate = true;
    }
  });
  if (!completed && allBadges.every(badge => !!badges.find(b => b === badge.key))) {
    completed = true;
    needsUpdate = true;
  }
  return needsUpdate ? { badges, steps, substeps, completed } : null;
};

function* onStartOnboardingListener() {
  while (true) {
    const { type, payload } = yield take([
      termActions.changeTermState.success,
      eventActions.createEvent.success,
      eventActions.updateEvent.success,
      termActions.updateMinimumStudyTimeBlock.success,
      courseActions.createGradeUnit.success,
      courseActions.createReadingSource.success,
      courseActions.createTaskCategory.success,
      taskActions.createTasks.success,
      planTask.success,
      calendarActions.markAsUsed.success,
      taskActions.updateTaskStatus.success,
      actions.stopOnboardingListener
    ]);

    if (type === getType(actions.stopOnboardingListener)) {
      break;
    }

    switch (type) {
      case getType(termActions.changeTermState.success):
        if (payload === SemesterStatus.QUICK_SETUP_DONE) {
          yield put(actions.checkStep(BadgeStep.QUICK_SETUP_STEP));
        }
        break;
      case getType(eventActions.createEvent.success):
        if (!payload || !payload.sourceEvents?.[0]) break;
        switch (payload.type) {
          case CalendarEventType.COURSE:
            yield put(actions.checkSubstep({ substep: Substeps.SCHEDULE_CLASS, step: BadgeStep.SCHEDULE_STEP }));
            break;
          case CalendarEventType.ACTIVITY:
            yield put(actions.checkSubstep({ substep: Substeps.SCHEDULE_ACTIVITY, step: BadgeStep.SCHEDULE_STEP }));
            break;
        }
        break;
      case getType(eventActions.updateEvent.success):
        if (payload.data.length > 0 && payload.data[0].type === CalendarEventType.AWAKE_TIME) {
          yield put(actions.checkSubstep({ step: BadgeStep.AWAKE_TIME_STEP, substep: Substeps.SET_AWAKE_TIME }));
        }
        break;
      case getType(termActions.updateMinimumStudyTimeBlock.success):
        yield put(actions.checkSubstep({ step: BadgeStep.STUDY_TIME_STEP, substep: Substeps.SET_STUDY_TIME }));
        break;
      case getType(courseActions.createGradeUnit.success):
      case getType(courseActions.createReadingSource.success):
      case getType(courseActions.createTaskCategory.success):
        yield put(actions.checkSubstep({ step: BadgeStep.CREATE_TASK_STEP, substep: Substeps.CREATE_TASK_CATEGORY }));
        break;
      case getType(taskActions.createTasks.success):
        yield put(actions.checkSubstep({ step: BadgeStep.CREATE_TASK_STEP, substep: Substeps.CREATE_TASK }));
        break;
      case getType(planTask.success):
        yield put(actions.checkSubstep({ step: BadgeStep.PLAN_TASK_STEP, substep: Substeps.PLAN_TASK }));
        break;
      case getType(calendarActions.markAsUsed.success):
        if (payload.plannedTaskDto && payload.plannedTaskDto.isUsed) {
          yield put(actions.checkSubstep({ substep: Substeps.ADD_PROGRESS, step: BadgeStep.TRACK_PROGRESS_STEP }));
        }
        break;
      case getType(taskActions.updateTaskStatus.success):
        yield put(actions.checkSubstep({ substep: Substeps.COMPLETE_TASK, step: BadgeStep.TRACK_PROGRESS_STEP }));
        break;
      default:
        break;
    }
  }
}

export default function*() {
  yield all([
    takeLatest(actions.getUserSubscription.request, getUserSubscription),
    takeLatest(actions.updateAccount.request, updateAccount),
    takeLatest(actions.updatePassword.request, updatePassword),
    takeLatest(actions.updateEmail.request, updateEmail),
    takeLatest(actions.deleteAccount.request, deleteAccount),
    takeLatest(actions.cancelSubscription.request, cancelSubscription),
    takeLatest(actions.reactivateSubscription.request, reactivateSubscription),
    takeLatest(actions.getUserSettings.request, getUserSettings),
    takeLatest(actions.saveUserSettings.request, saveUserSettings),
    takeLatest(actions.savePileState, savePileState),
    takeLatest(actions.changeOnboardingStatus, changeOnboardingStatus),
    takeLatest(actions.resetOnboarding, resetOnboarding),
    takeLatest(actions.skipOnboarding, skipOnboarding),
    takeLatest(actions.updatePlannedInCushion.request, updatePlannedInCushion),
    takeLatest(actions.checkSubstep, checkSubstep),
    takeLatest(actions.checkStep, checkStep),
    takeLatest(actions.getBadgesInfo.request, getBadgesInfo),
    takeLatest(actions.updateBadgesInfo.request, updateBadgesInfo),
    takeLatest(actions.addFreeTrialDay.request, addFreeTrialDay),
    takeLatest(actions.startOnboardingListener, onStartOnboardingListener)
  ]);
}
