import { all, put, select, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as semesterSelectors from '../semester/selectors';
import { apiCall } from '../common/operations';
import { alert, NotificationMode } from '@components/common/snackbarNotifications/Notifications';
import { getErrorMessage } from '../../api/network';
import t from '../../i18n/t';
import { icsApi } from '../../api/icsApi';
import { eventActions } from '../events';
import { getAllCourses } from '../calendar/selectors';
import { getPersonalizationSettings } from '../settings/selectors';

function* parseIcsFeed(action: ReturnType<typeof actions.parseIcsFeed.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const timezone = yield select(semesterSelectors.getTimeZone);
  const { ok, ...response } = yield apiCall(icsApi.parseIcsFeed, semesterId, { url: action.payload, timezone });
  if (ok) {
    yield put(actions.parseIcsFeed.success(response.data));
    alert(`${Object.keys(response.data).length} courses found`, NotificationMode.SUCCESS, undefined, true);
  } else {
    yield put(actions.parseIcsFeed.failure(response));
    alert(t[getErrorMessage(response)]);
  }
}

function* checkForIcsUpdates() {
  const courses = yield select(getAllCourses);
  // if no courses connected to ics files don't do anything
  if (!courses.find(c => c.parsedCourses?.length > 0)) return;
  yield put(actions.triggerIcsUpdates.request());
}

function* callIcsUpdates() {
  const semesterId = yield select(semesterSelectors.getId);
  const settings = yield select(getPersonalizationSettings);
  const { ok, ...response } = yield apiCall(icsApi.checkForIcsUpdates, semesterId, settings.daysAhead);
  if (ok) {
    yield put(actions.triggerIcsUpdates.success(response.data));
    if (response.data.timestamp) {
      yield put(eventActions.getEventsList.request({}));
    }
  } else {
    yield put(actions.triggerIcsUpdates.failure(response));
    alert(t[getErrorMessage(response)]);
  }
}

function* unsyncCourseFromLMS(action: ReturnType<typeof actions.unsyncCourseFromLMS.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(icsApi.unsyncCourseFromLMS, semesterId, action.payload.id);
  if (ok) {
    yield put(actions.unsyncCourseFromLMS.success(action.payload));
  } else {
    yield put(actions.unsyncCourseFromLMS.failure(response));
    alert(t[getErrorMessage(response)]);
  }
}

export default function*() {
  yield all([
    takeLatest(actions.parseIcsFeed.request, parseIcsFeed),
    takeLatest(actions.checkForIcsUpdates, checkForIcsUpdates),
    takeLatest(actions.triggerIcsUpdates.request, callIcsUpdates),
    takeLatest(actions.unsyncCourseFromLMS.request, unsyncCourseFromLMS)
  ]);
}
