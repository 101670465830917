import { CalendarEvent, CalendarEventType } from 'shovel-lib/types';
import { actionCreator, asyncActionsCreator } from '../common/utils';
import { CreateEventDto, DeleteEventRequest, UpdateEventRequest } from 'shovel-lib/types';
import { Moment } from 'moment';
import { SourceEvent } from './types';

const asyncActions = asyncActionsCreator('@@events');
const action = actionCreator('@@events');

export const sourceEvents = asyncActions<void, SourceEvent[], string>('SOURCE_EVENTS');

export const getEventsList = asyncActions<
  { startDate?: Moment | Date | string | null; endDate?: Moment | Date | string | null },
  { events: CalendarEvent[]; start?: Moment | null; end?: Moment | null },
  string
>('GET_EVENTS_LIST');

export const createEvent = asyncActions<
  CreateEventDto,
  { events: CalendarEvent[]; sourceEvents: SourceEvent[]; type: CalendarEventType },
  string
>('CREATE_EVENT');

export const updateEvent = asyncActions<
  UpdateEventRequest & { callback?: any; revert?: any },
  { id: string; data: SourceEvent[] },
  string
>('UPDATE_EVENT');

export const getSemesterHolidays = asyncActions<void, any[], string>('FIND_SEMESTER_HOLIDAYS');

export const updateSemesterHolidays = asyncActions<any[], any[], string>('UPDATE_SEMESTER_HOLIDAYS');

export const deleteEvent = asyncActions<
  { eventId: string; query?: DeleteEventRequest },
  { id: string; data: SourceEvent[] },
  string
>('DELETE_EVENT');

export const clearEvents = action<undefined>('CLEAR_EVENTS');
