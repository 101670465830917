import { createReducer } from 'typesafe-actions';
import { SemesterState, TimelineColorMode } from './types';
import * as actions from './actions';
import { changeTimelineColorMode, getOverallCushion } from './actions';
import { getSemesterHolidays, updateSemesterHolidays } from '../events/actions';
import { updateAccount, updateEmail, updatePlannedInCushion } from '../settings/actions';
import { changeTermState, createBatchCourses, deleteTerm, setAwakeTimes } from '../terms/actions';
import { toMomentDate } from 'shovel-lib/utils/timeUtils';
import { getGoogleAccess, unsyncGoogleCalendar } from '../googleCalendar/actions';
import { CalendarEvent, SemesterStatus } from 'shovel-lib/types';
import storage, { CUSHION_SIDEBAR_COLLAPSED } from '../../utils/storage';

const initialState: SemesterState = {
  userSemesters: [],
  loading: false,
  cushion: {
    tasks: [],
    semesterTimezone: null,
    timelineColorMode: TimelineColorMode.CUSHION
  },
  cushionSidebarCollapsed: localStorage.getItem(CUSHION_SIDEBAR_COLLAPSED) === 'true',
  error: '',
  countSemesters: 1
};

const reducer = createReducer(initialState)
  .handleAction(actions.getCurrent.success, (state, action) => {
    const details = action.payload.details
      ? {
          ...action.payload.details,
          ends: toMomentDate(`${action.payload.details.ends} 23:59:59`).toISOString(),
          examPeriodEnds: toMomentDate(`${action.payload.details.examPeriodEnds} 23:59:59`).toISOString()
        }
      : undefined;

    const countSemesters = action.payload.countSemesters || 1;

    return {
      ...state,
      info: {
        ...state.info,
        ...action.payload,
        details
      },
      countSemesters
    };
  })
  .handleAction(changeTermState.success, (state, action) => {
    if (!state.info) return state;
    return {
      ...state,
      info: {
        ...state.info,
        state: action.payload
      }
    };
  })
  .handleAction(actions.getDetailedSemester.success, (state, action) => {
    if (!state.info) return state;
    return {
      ...state,
      info: {
        ...state.info,
        detailedInfo: action.payload.details
      }
    };
  })
  .handleAction(actions.getUserSemesters.success, (state, action) => ({
    ...state,
    userSemesters: action.payload
  }))
  .handleAction(actions.removeCurrentSemester, (state, action) => ({
    ...state,
    info: initialState.info
  }))
  .handleAction([getSemesterHolidays.success, updateSemesterHolidays.success], (state, action) => {
    if (!state.info) return state;
    return {
      ...state,
      info: { ...state.info, holidays: action.payload }
    };
  })
  .handleAction(updateAccount.success, (state, action) => {
    if (!state.info) return state;
    return {
      ...state,
      info: {
        ...state.info,
        user: {
          ...state.info.user,
          firstName: action.payload.firstName
        }
      }
    };
  })
  .handleAction(updateEmail.success, (state, action) => {
    if (!state.info) return state;
    return {
      ...state,
      info: {
        ...state.info,
        user: {
          ...state.info.user,
          email: action.payload.newEmail,
          emailVerified: false
        }
      }
    };
  })
  .handleAction(deleteTerm.success, (state, action) => ({
    ...state,
    userSemesters: state.userSemesters.filter(semester => semester.semesterId !== action.payload)
  }))
  .handleAction(getOverallCushion.request, (state, action) => {
    return {
      ...state,
      loading: true
    };
  })
  .handleAction(getOverallCushion.success, (state, action) => {
    return {
      ...state,
      loading: false,
      cushion: { ...state.cushion, ...action.payload }
    };
  })
  .handleAction(getOverallCushion.failure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.payload
    };
  })
  .handleAction(unsyncGoogleCalendar.success, state => {
    if (!state.info) return state;
    return { ...state, info: { ...state.info, user: { ...state.info.user, hasCalendars: false } } };
  })
  .handleAction(getGoogleAccess.success, state => {
    if (!state.info) return state;
    return { ...state, info: { ...state.info, user: { ...state.info.user, hasCalendars: true } } };
  })
  .handleAction(updatePlannedInCushion.success, (state, action) => {
    if (!state.info) return state;
    return { ...state, info: { ...state.info, user: { ...state.info.user, includePlannedInCushion: action.payload } } };
  })
  .handleAction(setAwakeTimes.success, state => {
    if (!state.info || state.info.state !== SemesterStatus.SEMESTER_SETUP_DONE) return state;
    return { ...state, info: { ...state.info, state: SemesterStatus.AWAKE_TIMES_SETUP_DONE } };
  })
  .handleAction(createBatchCourses.success, state => {
    if (!state.info || state.info.state !== SemesterStatus.AWAKE_TIMES_SETUP_DONE) return state;
    return { ...state, info: { ...state.info, state: SemesterStatus.COURSE_SETUP_DONE } };
  })
  .handleAction(changeTimelineColorMode, (state, action) => {
    return { ...state, cushion: { ...state.cushion, timelineColorMode: action.payload } };
  })
  .handleAction(actions.changeCurrentSemester.success, state => {
    return { ...state, info: state.info ? { ...state.info, holidays: [] as CalendarEvent[] } : undefined };
  })
  .handleAction(actions.toggleCushionSidebar, state => {
    const cushionSidebarCollapsed = !state.cushionSidebarCollapsed;
    storage.setCushionSidebarCollapsed(cushionSidebarCollapsed);
    return {
      ...state,
      cushionSidebarCollapsed
    };
  });

export default reducer;
