import React, { FC } from 'react';
import styled from 'styled-components';

import { FlexRow } from '../../layoutUtils';
import InputWrapper from '../InputWrapper';
import InlineInputWrapper from '../InlineInputWrapper';

import colors from '@utils/colors';
import DateInput from './DateInput';
import { DatepickerFormats, Formats } from 'shovel-lib/types';
import { RegularText } from '@utils/typography';

type Props = {
  valueFrom: any;
  valueTo: any;
  nameFrom?: string;
  nameTo?: string;
  onChangeFrom: (value: string) => void;
  onChangeTo: (value: string) => void;
  onBlurTo?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlurFrom?: (event: React.FocusEvent<HTMLInputElement>) => void;
  minDate?: Date | string;
  maxDate?: Date | string;
  required?: boolean;
  label?: string;
  className?: string;
  placeholder?: string;
  inline?: boolean;
  disabled?: boolean;
  errorFrom?: string;
  errorTo?: string;
  touched?: boolean;
  popperContainerId?: string;
  noErrorMargin?: boolean;
};

const DateRangeInput: FC<Props> = ({
  valueFrom,
  valueTo,
  nameFrom,
  nameTo,
  onChangeFrom,
  onChangeTo,
  minDate,
  maxDate,
  required = true,
  label,
  className,
  placeholder,
  inline,
  disabled,
  errorFrom,
  errorTo,
  onBlurTo,
  onBlurFrom,
  noErrorMargin,
  popperContainerId
}) => {
  const Wrapper = inline ? InlineInputWrapper : InputWrapper;

  return (
    <Wrapper
      placeholder={placeholder}
      disabled
      className={className}
      label={label}
      required={required}
      noErrorMargin={noErrorMargin}
    >
      <DatetimeRangeWrapper disabled={disabled}>
        <DateInput
          noErrorMargin
          name={nameFrom}
          value={valueFrom}
          onChange={onChangeFrom}
          minDate={minDate}
          maxDate={maxDate}
          required={required}
          disabled={disabled}
          inline={inline}
          bare={!inline}
          onBlur={onBlurFrom}
          popperContainerId={popperContainerId}
          touched={!!errorFrom}
          error={errorFrom}
          autosizeFormat={showYear => `ddd, ${Formats.MONTH_DAY_FORMAT}${showYear ? " 'YY" : ''}`}
          dateFormatFn={showYear => `EEE, ${DatepickerFormats.SHORT}${showYear ? " ''yy" : ''}`}
        />
        <Separator>{'-'}</Separator>
        <DateInput
          noErrorMargin
          name={nameTo}
          value={valueTo}
          onChange={onChangeTo}
          minDate={valueFrom}
          maxDate={maxDate}
          required={required}
          disabled={disabled}
          inline={inline}
          bare={!inline}
          popperContainerId={popperContainerId}
          onBlur={onBlurTo}
          touched={!!errorTo}
          error={errorTo}
          autosizeFormat={showYear => `ddd, ${Formats.MONTH_DAY_FORMAT}${showYear ? " 'YY" : ''}`}
          dateFormatFn={showYear => `EEE, ${DatepickerFormats.SHORT}${showYear ? " ''yy" : ''}`}
        />
      </DatetimeRangeWrapper>
    </Wrapper>
  );
};

export default DateRangeInput;

const DatetimeRangeWrapper = styled(FlexRow)<{ disabled?: boolean }>`
  justify-content: space-between;
  line-height: 17px;
  ${props => props.disabled && `${Separator} { color: ${props.theme.disabledTextColor}}`}
`;

const Separator = styled(RegularText)`
  margin: 0 5px;
`;
