import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';

import ValueWithLabel from '../common/ValueWithLabel';
import { Container, CourseRow, DueAndPlannedWrapper, HiddenRow, TitleWrapper } from './TaskCard.styles';
import { TaskType } from 'shovel-lib/types';
import { MiniText, SmallText } from '../../utils/typography';
import { Column, Row } from '../common/layoutUtils';
import { lightTheme } from '../../assets/themes';
import { DEFAULT_TASK_CATEGORY_EMOJI } from '../../utils/constants/task';
import t from '../../i18n/t';
import PlannedTag from './partials/PlannedTag';
import { minutesToDurationInHours } from '../../utils/filters';
import colors from '../../utils/colors';
import { EmojiPreview } from '@components/common/emoji/EmojiPreview';
import TaskPriority from '@components/pile/components/TaskPriority';

type Props = {
  backgroundColor?: string;
  due?: string;
  start?: string;
  colorHex: string;
  type: TaskType;
  cushionComponent: React.ReactNode;
  progressComponent?: React.ReactNode;
  cushion?: number;
  course: string;
  title: string;
  priority?: number;
  subtitle?: string;
  styles?: object;
  iconSize?: number;
  timePlanned?: number;
  onClick?: (clickData: any) => void;
  className?: string;
  categoryName?: string;
  categoryEmoji?: string;
  readingSourceName?: string;
  subtitleWidth?: string;
  theme?: any;
  parentTheme?: any;
  isPile?: any;
  delete?: any;
  isCompleted?: any;
  dragDisabled?: boolean;
  renderMarkBtn?: any;
  renderDeleteBtn?: any;
  duration?: number;
  timeNeed?: number;
  timeSpent?: number;
  hasNoActions?: boolean;
  noPlannedTooltip?: boolean;
  hasSidebar?: boolean;
  changePriority?: (priority) => void;
};

class TaskCard extends Component<Props> {
  renderPlannedTag = () => {
    const { isCompleted, timeNeed, timePlanned, isPile, timeSpent, theme, parentTheme, noPlannedTooltip } = this.props;
    if (isCompleted && isPile && !isNaN(timeSpent!)) {
      return <ValueWithLabel value={minutesToDurationInHours(timeSpent!)} label={t.TIME_SPENT} font={10} />;
    }
    if (isCompleted || timeNeed === undefined || timePlanned === undefined) return null;

    return (
      <PlannedTag
        {...{ timeNeed, timePlanned }}
        isTaskCard
        bare
        font={10}
        parentTheme={theme || parentTheme}
        noTooltip={noPlannedTooltip}
      />
    );
  };

  /**
   * timeSpent : timeNeed = x : (100 - x)
   * x = timeSpent * 100 / (timeNeed + timeSpent)
   */
  renderProgressOverlay = () => {
    const { colorHex, timeSpent, timeNeed = 0, isCompleted } = this.props;
    if (!timeSpent || isCompleted) return null;
    const percents = timeNeed > 0 ? Math.floor((timeSpent * 100) / (timeNeed + timeSpent)) : 0;
    return <ProgressOverlay color={colorHex} percents={percents} />;
  };

  renderHiddenRow = () => {
    const { isPile, colorHex, renderMarkBtn, renderDeleteBtn, backgroundColor, hasSidebar } = this.props;
    if (!isPile) return null;

    return (
      <HiddenRow borderColor={colorHex} backgroundColor={backgroundColor} hasSidebar={hasSidebar}>
        {renderMarkBtn()}
        {renderDeleteBtn()}
      </HiddenRow>
    );
  };

  render() {
    const {
      due,
      start,
      cushionComponent,
      colorHex,
      course,
      title,
      styles,
      hasSidebar,
      onClick,
      className,
      isCompleted,
      categoryName,
      categoryEmoji,
      readingSourceName,
      hasNoActions,
      backgroundColor,
      dragDisabled,
      priority = 0,
      changePriority
    } = this.props;
    const theme = this.props.theme || this.props.parentTheme || lightTheme;

    return (
      // cardTheme added because of cushion tooltip
      <Container
        hasSidebar={hasSidebar}
        style={styles}
        onClick={e => {
          if (!onClick || e.defaultPrevented) return;
          onClick(e);
        }}
        className={className}
        colorHex={colorHex || colors.randomTaskColor}
        isDraggable={!dragDisabled && !isCompleted}
        hasNoActions={hasNoActions}
        backgroundColor={backgroundColor}
        cardTheme={theme}
      >
        <Row>
          <CourseRow color={theme.textColor}>
            <SmallText color={colorHex}>{course || t.RANDOM}</SmallText>
            {categoryName && (
              <MiniText className={'task-category'}>
                <EmojiPreview emoji={categoryEmoji || DEFAULT_TASK_CATEGORY_EMOJI} />
                {categoryName}
              </MiniText>
            )}
            {readingSourceName && <MiniText>{readingSourceName}</MiniText>}
          </CourseRow>
          {/* height: 16 - Make card height independent from cushionComponent height */}
          <Row style={{ flexShrink: 0, height: 16 }}>{cushionComponent}</Row>
        </Row>
        <Row>
          <TitleWrapper color={theme.textStrongColor}>{title}</TitleWrapper>
          {priority >= 0 && (
            <TaskPriority
              priority={priority}
              onChange={
                !changePriority
                  ? undefined
                  : (value, e) => {
                      e.preventDefault();
                      changePriority(value);
                    }
              }
              fontSize={13}
              right
              theme={theme}
            />
          )}
        </Row>
        <DueAndPlannedWrapper>
          <Column>
            {start && (
              //valueStyle and labelStyle are here because of cushion tooltip card (it renders outside theme provider)
              <ValueWithLabel
                font={10}
                label={t.START}
                value={start}
                valueStyle={{ color: theme.textStrongColor }}
                labelStyle={{ color: theme.textColor }}
              />
            )}
            {due && (
              <ValueWithLabel
                font={10}
                label={t.DUE}
                value={due}
                valueStyle={{ color: theme.textStrongColor }}
                labelStyle={{ color: theme.textColor }}
              />
            )}
          </Column>
          {this.renderPlannedTag()}
        </DueAndPlannedWrapper>
        {this.renderHiddenRow()}
        {this.renderProgressOverlay()}
      </Container>
    );
  }
}

export default withTheme(TaskCard);

const ProgressOverlay = styled.div<{ color: string; percents: number }>`
  background-color: ${props => props.color};
  opacity: ${({ theme }) => theme.taskProgressOpacity};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${props => props.percents}%;
  z-index: 0;
`;
