import moment from 'moment';
import { RRuleSet, rrulestr } from 'rrule';
import { CalendarEvent } from 'shovel-lib/types';
import { SourceEvent, SourceEventType } from '@state/events/types';
import { DATE_FORMAT, toMomentDate } from 'shovel-lib/utils/timeUtils';

const ONE_YEAR_IN_MINS = 365 * 24 * 60;
const LOCAL_DATE_TIME = 'YYYY-MM-DDTHH:mm:ss';

const getCorrectedEventEndAndDuration = (
  type: SourceEventType,
  semesterId: number,
  _start: Date,
  _end: Date,
  _durationInMinutes: number
) => {
  if (type === SourceEventType.AWAKE_TIME && _durationInMinutes > ONE_YEAR_IN_MINS) {
    const start = moment.utc(_start);
    const sequenceEnds = moment.utc(_end);

    const end = start.clone().set({
      hour: sequenceEnds.get('hour'),
      minute: sequenceEnds.get('minute') + 1,
      seconds: 0,
      milliseconds: 0
    });
    end.add(end.isBefore(start) ? 24 : 0, 'hours');
    const durationInMinutes = end.diff(start, 'minutes');

    console.log(
      `DURATION_IN_MINUTES_ERR: ${semesterId} ${_start} ${_end} ${_durationInMinutes} || corrected: ${durationInMinutes}`
    );
    return { durationInMinutes, end: end.toDate() };
  }

  return {
    durationInMinutes: _durationInMinutes,
    end: new Date(_start.getTime() + _durationInMinutes * 60000)
  };
};

const toUtcDateTime = (value: Date, timezone: string) => moment.tz(value.toISOString().slice(0, -1), timezone).toDate();
const toRuleDateTime = (value: Date | string, timezone: string) =>
  new Date(
    moment(value)
      .tz(timezone)
      .format(LOCAL_DATE_TIME)
  );
const RuleDateFormatter = (year: number, month: string, day: number) => `${month.substr(0, 3)} ${day}, ${year}`;

const sourceEventToCalendarEvent = (event: SourceEvent, instanceStart?: Date, recurrenceText?: string): CalendarEvent => {
  let start: string | Date = event.start;
  if (instanceStart) {
    start = new Date(
      instanceStart.getUTCFullYear(),
      instanceStart.getUTCMonth(),
      instanceStart.getUTCDate(),
      instanceStart.getUTCHours(),
      instanceStart.getUTCMinutes(),
      instanceStart.getUTCSeconds()
    );
  }
  const { durationInMinutes, end } = getCorrectedEventEndAndDuration(
    event.type,
    event.semesterId,
    moment(start).toDate(),
    moment(event.end).toDate(),
    event.durationInMinutes!
  );
  let repeat;
  if (event.RRuleSet) {
    const rule = rrulestr(event.RRuleSet, { forceset: true }) as RRuleSet;
    const ruleOptions = rule._rrule[0].origOptions;
    repeat = {
      dtStart: toUtcDateTime(ruleOptions.dtstart!, ruleOptions.tzid!),
      interval: ruleOptions.interval,
      onDays: ruleOptions.byweekday?.toString()?.split(','),
      until: ruleOptions.interval === 0 ? undefined : toUtcDateTime(ruleOptions.until!, ruleOptions.tzid!),
      timezone: ruleOptions.tzid,
      untilOption: event.untilOption,
      includeDays: rule.rdates().map(d => {
        const utcString = d.toISOString();
        return toMomentDate(utcString.substring(0, utcString.length - 1)).format(DATE_FORMAT);
      })
    };
  }

  return {
    ...event,
    start,
    end,
    // @ts-ignore
    durationInMinutes,
    passed: moment.utc().diff(start, 'minutes') >= 0,
    recurrenceText,
    // @ts-ignore
    semesterId: undefined,
    RRuleSet: undefined,
    untilOption: undefined,
    repeat
  };
};

const unpackEvent = (event: SourceEvent, holidays: CalendarEvent[] = [], start?: Date, end?: Date): CalendarEvent[] => {
  if (!event.isRecurring || !event.RRuleSet) {
    return event.type === SourceEventType.CLASS &&
      holidays.find(holiday => moment(event.start).isBetween(holiday.start, holiday.end))
      ? []
      : [sourceEventToCalendarEvent(event)];
  }

  const rule = rrulestr(event.RRuleSet!, { forceset: true }) as RRuleSet;
  if (event.type === SourceEventType.CLASS) {
    holidays.forEach(holiday => {
      rule
        .between(new Date(holiday.start), new Date(holiday.end), true)
        .forEach(exception => rule.exdate(toRuleDateTime(exception, rule.tzid())));
    });
  }

  let instances;
  if (start && end) {
    // Shift the start and end of the range by the browser's timezone offset
    // RRule messes up the timezones, generates instances that are shifted for the browsers timezone offset
    // - so the range need to be shifted as well to capture correct instances
    //
    // eg. event is in 18:00(+11:00) - rrule will generate instances that are at 18:00(UTC+0) which will translate
    // to 05:00(+11) the following day - which will cause the last instance to be outside the range
    //
    // the incorrect times are fixed in the sourceEventToCalendarEvent function

    const unpackUntil = toMomentDate(end)
      .subtract(end.getTimezoneOffset(), 'minutes')
      .toDate();

    // make sure we capture repeating events that have started but not finished
    const unpackFrom = moment(start)
      .subtract(start.getTimezoneOffset(), 'minutes')
      .subtract(event.durationInMinutes, 'minutes')
      .toDate();

    instances = rule.between(unpackFrom, unpackUntil, true);
  } else {
    instances = rule.all();
  }

  return instances.map(instance =>
    sourceEventToCalendarEvent(event, instance, rule._rrule[0].toText(undefined, undefined, RuleDateFormatter))
  );
};

export default unpackEvent;
