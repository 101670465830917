import { all, delay, put, select, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import { closeCalendarDialog } from './actions';
import * as semesterSelectors from '../semester/selectors';
import * as timerSelectors from '../timer/selectors';
import { apiCall } from '../common/operations';
import { getErrorMessage } from '../../api/network';
import { activityApi, calendarApi, courseApi, plannerApi } from '../../api';
import storage from '../../utils/storage';
import { alert, NotificationMode } from '@components/common/snackbarNotifications/Notifications';
import t from '../../i18n/t';
import { GetTasksForPlanningRequest } from './types';
import { taskApi } from 'shovel-lib';
import { PILE_TODO_TAKE_PARAM } from '../../utils/constants/pile';
import { getPlannedEvent } from './selectors';
import { getSelectedCourseId } from '../workload/selectors';
import { workloadActions } from '../workload';
import { selectCommitment } from '../workload/actions';
import { SidebarItemType } from '../workload/types';
import { timerActions } from '../timer';
import { taskActions } from '../task';
import monthApi from '../../api/monthApi';
import moment from 'moment';
import { DATE_FORMAT } from 'shovel-lib/utils/timeUtils';
import { eventActions } from '@state/events';
import weekApi from '@api/weekApi';

function* getCalendarData() {
  const semesterId = yield select(semesterSelectors.getId);

  const { ok, ...response } = yield apiCall(calendarApi.calendarEvents, semesterId);

  if (ok) {
    yield put(actions.calendarData.success(response.data));
  } else {
    yield put(actions.calendarData.failure(response));
  }
}

function* getDatesContainingTasks(action: ReturnType<typeof actions.getDatesContainingTasks.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { startDate: start, endDate: end } = action.payload;

  const startDate = moment(start);
  const endDate = moment(end);

  const { ok, ...response } = yield apiCall(
    monthApi.getDatesContainingTasks,
    semesterId,
    startDate.format(DATE_FORMAT),
    endDate.format(DATE_FORMAT)
  );

  if (ok) {
    yield put(actions.getDatesContainingTasks.success({ ...response.data, startDate, endDate }));
  } else {
    yield put(actions.getDatesContainingTasks.failure(response));
  }
}

function* getPlannedTasks() {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(calendarApi.calendarEvents, semesterId);
  if (ok) {
    yield put(actions.getPlannedTasks.success(response.data));
  } else {
    yield put(actions.getPlannedTasks.failure(getErrorMessage(response)));
  }
}

function* getCommitmentsData() {
  const semesterId = yield select(semesterSelectors.getId);

  const [coursesResponse, activitiesResponse] = yield all([
    apiCall(courseApi.getAll, semesterId),
    apiCall(activityApi.getAll, semesterId)
  ]);

  if (!coursesResponse.ok) {
    yield put(actions.commitmentsData.failure(getErrorMessage(coursesResponse)));
    return;
  }

  if (!activitiesResponse.ok) {
    yield put(actions.commitmentsData.failure(getErrorMessage(activitiesResponse)));
    return;
  }

  yield put(
    actions.commitmentsData.success({
      courses: coursesResponse.data,
      activities: activitiesResponse.data,
      usedColors: []
    })
  );
}

function* movePlannedTask(action: ReturnType<typeof actions.movePlannedTask.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { id, callback, ...request } = action.payload;
  const event = yield select(getPlannedEvent, id);
  const { ok, ...response } = yield apiCall(plannerApi.movePlannedTask, semesterId, id, request);
  if (ok) {
    yield put(actions.movePlannedTask.success({ oldEvent: event, newEvent: response.data }));
    if (callback) callback();
  } else {
    const errorMessage = getErrorMessage(response);
    yield put(actions.movePlannedTask.failure(errorMessage));
    alert(t[errorMessage] || errorMessage);
  }
}

function* resizePlannedTask(action: ReturnType<typeof actions.resizePlannedTask.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { id, callback, revert, ...request } = action.payload;
  const event = yield select(getPlannedEvent, id);
  const { ok, ...response } = yield apiCall(plannerApi.resizePlannedTask, semesterId, id, request);
  if (ok) {
    yield put(actions.resizePlannedTask.success({ oldEvent: event, newEvent: response.data }));
    if (callback) callback();
  } else {
    const errorMessage = getErrorMessage(response);
    yield put(actions.resizePlannedTask.failure(errorMessage));
    // Revert the event instance in the calendar if the resize was triggered by dragging on the calendar
    if (revert) revert();
    alert(t[errorMessage] || errorMessage);
  }
}

function* deletePlannedTask(action: ReturnType<typeof actions.deletePlannedTask.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const activeTimer = yield select(timerSelectors.getActiveTimer);
  const event = yield select(getPlannedEvent, action.payload);
  const { ok, ...response } = yield apiCall(plannerApi.deletePlannedTask, semesterId, action.payload);
  if (ok) {
    yield put(actions.deletePlannedTask.success({ event, data: response.data }));
    if (activeTimer && activeTimer.plannedTaskId === Number(action.payload)) {
      yield put(timerActions.stopCurrentTimer(response.data.timerDto));
    }
  } else {
    const errorMessage = getErrorMessage(response);
    yield put(actions.deletePlannedTask.failure(errorMessage));
    alert(t[errorMessage] || errorMessage);
  }
}

function* getTasksOnDate(action: ReturnType<typeof actions.getTasksOnDate.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(calendarApi.tasksOnDate, semesterId, action.payload);
  if (ok) {
    yield put(actions.getTasksOnDate.success({ tasks: response.data, date: action.payload }));
  } else {
    yield put(actions.getTasksOnDate.failure(getErrorMessage(response)));
  }
}

function* createCourse(action: ReturnType<typeof actions.createCourse.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { isTaskList, ...payload } = action.payload;
  const { ok, ...response } = yield apiCall(courseApi.createCourse, semesterId, payload);
  if (ok) {
    yield put(actions.createCourse.success(response.data));
    if (isTaskList) {
      yield put(
        selectCommitment({
          id: response.data.id,
          type: SidebarItemType.COURSE,
          name: response.data.name,
          colorHex: response.data.colorHex
        })
      );
    }
  } else {
    yield put(actions.createCourse.failure(getErrorMessage(response)));
  }
}

function* updateCourse(action: ReturnType<typeof actions.updateCourse.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { id, ...request } = action.payload;
  const { ok, ...response } = yield apiCall(courseApi.updateCourse, semesterId, id, request);
  if (ok) {
    yield put(actions.updateCourse.success(response.data));
    yield put(eventActions.getEventsList.request({}));
  } else {
    yield put(actions.updateCourse.failure(getErrorMessage(response)));
  }
}

function* deleteCourse(action: ReturnType<typeof actions.deleteCourse.request>) {
  const id = action.payload;
  if (!id) {
    yield put(actions.deleteCourse.success({}));
    return;
  }
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(courseApi.deleteCourse, semesterId, id);
  if (ok) {
    const courseId = yield select(getSelectedCourseId);
    // refresh only if mode is ViewEverything
    yield put(actions.deleteCourse.success({ ...response.data, refreshTaskList: !courseId }));
    yield put(eventActions.getEventsList.request({}));
    yield put(actions.calendarData.request());
  } else {
    yield put(actions.deleteCourse.failure(getErrorMessage(response)));
  }
}

function* createActivity(action: ReturnType<typeof actions.createActivity.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { isTaskList, ...payload } = action.payload;
  const { ok, ...response } = yield apiCall(activityApi.createActivity, semesterId, payload);
  if (ok) {
    yield put(actions.createActivity.success(response.data));
    if (isTaskList) {
      yield put(
        selectCommitment({
          id: response.data.id,
          type: SidebarItemType.ACTIVITY,
          name: response.data.name,
          colorHex: response.data.colorHex
        })
      );
    }
  } else {
    yield put(actions.createActivity.failure(getErrorMessage(response)));
  }
}

function* updateActivity(action: ReturnType<typeof actions.updateActivity.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { id, ...request } = action.payload;
  const { ok, ...response } = yield apiCall(activityApi.updateActivity, semesterId, id, request);
  if (ok) {
    yield put(actions.updateActivity.success(response.data));
    yield put(eventActions.getEventsList.request({}));
  } else {
    yield put(actions.updateActivity.failure(getErrorMessage(response)));
  }
}

function* deleteActivity(action: ReturnType<typeof actions.deleteActivity.request>) {
  const id = action.payload;
  if (!id) {
    yield put(actions.deleteActivity.success({}));
    return;
  }
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(activityApi.deleteActivity, semesterId, id);
  if (ok) {
    yield put(actions.deleteActivity.success(response.data));
    yield put(eventActions.getEventsList.request({}));
  } else {
    yield put(actions.deleteActivity.failure(getErrorMessage(response)));
  }
}

// TODO : implement deleting of activity and course

function* planTask({ payload }: ReturnType<typeof actions.planTask.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { request, infoRef } = payload;
  const { ok, ...response } = yield apiCall(plannerApi.createPlannedTask, semesterId, request);
  if (ok) {
    // dispatching this action causes refetching of to_do and upcoming tasks
    // if we change implementation of fetching to clear the tasks list, this should go after analytics part
    yield put(closeCalendarDialog());
    if (infoRef) {
      infoRef.setEnd(response.data.end);
      yield delay(200);
      yield put(actions.planTask.success(response.data));
      infoRef.remove();
    } else {
      yield put(actions.planTask.success(response.data));
    }
  } else {
    yield put(actions.planTask.failure(getErrorMessage(response)));
    if (infoRef?.remove) {
      infoRef.remove();
    }
  }
}

function* updateCalendarSlatHeight(action: ReturnType<typeof actions.updateCalendarSlatHeight>) {
  yield storage.setCalendarSlatHeightConfig(action.payload);
}

function* getTasksForPlanning({ payload }: { payload: GetTasksForPlanningRequest }) {
  const semesterId = yield select(semesterSelectors.getId);
  const { from, drop } = payload;

  const { ok, ...response } = yield apiCall(taskApi.todoTasksFromViewMore, semesterId, from, drop, PILE_TODO_TAKE_PARAM);
  if (ok) {
    yield put(actions.getTasksForPlanning.success(response.data));
  } else {
    yield put(actions.getTasksForPlanning.failure(getErrorMessage(response)));
  }
}

function* planTasks({ payload }: { payload: any }) {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(plannerApi.createPlannedTasks, semesterId, payload);
  if (ok) {
    yield put(actions.planTasks.success(response.data));
    yield put(actions.closePlanBlockDialog());
  } else {
    yield put(actions.planTasks.failure(getErrorMessage(response)));
  }
}

function* reorderCourse(action: ReturnType<typeof actions.reorderCourses.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(courseApi.reorderCourses, semesterId, {
    items: action.payload.map(c => {
      return {
        id: c.id,
        ordinalNumber: c.ordinalNumber!
      };
    })
  });
  if (ok) {
    yield put(actions.reorderCourses.success(action.payload));
  } else {
    yield put(actions.reorderCourses.failure(getErrorMessage(response)));
  }
}

function* reorderActivities(action: ReturnType<typeof actions.reorderActivities.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(activityApi.reorderActivities, semesterId, {
    items: action.payload.map(a => {
      return {
        id: a.id,
        ordinalNumber: a.ordinalNumber!
      };
    })
  });
  if (ok) {
    yield put(actions.reorderActivities.success(action.payload));
  } else {
    yield put(actions.reorderActivities.failure(getErrorMessage(response)));
  }
}

function* resetNavigation() {
  yield put(workloadActions.selectCommitment());
}

function* updatePlannedTask(action: ReturnType<typeof actions.updatePlannedTask.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { id, mutate, request } = action.payload;
  const { ok, ...response } = yield apiCall(plannerApi.updatePlannedTask, semesterId, id, request);
  if (ok) {
    yield put(actions.updatePlannedTask.success({ data: response.data, mutate }));
  } else {
    yield put(actions.updatePlannedTask.failure(getErrorMessage(response)));
  }
}
function* markAsUsed(action: ReturnType<typeof actions.markAsUsed.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const activeTimer = yield select(timerSelectors.getActiveTimer);
  const { data, plannedTaskId, reading, courseId } = action.payload;
  const { ok, ...response } = yield apiCall(plannerApi.markAsUsed, semesterId, plannedTaskId, data);
  if (ok) {
    if (data.isUsed) {
      yield delay(700);
      alert(t.TASK_STATUS_SAVED, NotificationMode.SUCCESS);
    }
    yield put(actions.markAsUsed.success(response.data));
    // stop if there is active timer for that planned task
    if (activeTimer && activeTimer.plannedTaskId === plannedTaskId && response.data.plannedTaskDto.isUsed) {
      yield put(timerActions.stopCurrentTimer(response.data.timerDto));
    }

    // open new estimate dialog if new time per page is diff from old one and planned task is marked as used
    if (response.data.plannedTaskDto.isUsed && reading && response.data.plannedTaskDto.pages !== 0) {
      const newTimePerPage = Math.floor((response.data.plannedTaskDto.used * 60) / response.data.plannedTaskDto.pages);
      if (newTimePerPage !== reading.secondsPerPage) {
        yield put(
          taskActions.openNewEstimateDialog({ reading, newTimePerPage, taskId: response.data.taskId, courseId: courseId! })
        );
      }
    }
  } else {
    yield put(actions.markAsUsed.failure(getErrorMessage(response)));
  }
}

function* stopTimerForPlannedTask(action: ReturnType<typeof actions.stopTimerForPlannedTask.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(plannerApi.stopTimerForTask, semesterId, action.payload);
  if (ok) {
    yield put(actions.stopTimerForPlannedTask.success(response.data));
    yield put(timerActions.stopCurrentTimer(response.data.timerDto));
  } else {
    yield put(actions.stopTimerForPlannedTask.failure(getErrorMessage(response)));
  }
}

function* duplicatePlannedTask({ payload }: ReturnType<typeof actions.duplicatePlannedTask.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(weekApi.duplicatePlannedTask, semesterId, payload);
  if (ok) {
    yield put(actions.duplicatePlannedTask.success(response.data));
  } else {
    yield put(actions.duplicatePlannedTask.failure(getErrorMessage(response)));
  }
}

function* changeEventDefaultColor({ payload }: ReturnType<typeof actions.changeEventDefaultColor.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { ok, ...response } = yield apiCall(weekApi.changeEventDefaultColor, semesterId, payload);
  if (ok) {
    yield put(actions.changeEventDefaultColor.success(payload));
  } else {
    yield put(actions.changeEventDefaultColor.failure(getErrorMessage(response)));
  }
}

export default function*() {
  yield all([
    takeLatest(actions.calendarData.request, getCalendarData),
    takeLatest(actions.getDatesContainingTasks.request, getDatesContainingTasks),
    takeLatest(actions.getPlannedTasks.request, getPlannedTasks),
    takeLatest(actions.commitmentsData.request, getCommitmentsData),
    takeLatest(actions.movePlannedTask.request, movePlannedTask),
    takeLatest(actions.resizePlannedTask.request, resizePlannedTask),
    takeLatest(actions.deletePlannedTask.request, deletePlannedTask),
    takeLatest(actions.getTasksOnDate.request, getTasksOnDate),
    takeLatest(actions.createCourse.request, createCourse),
    takeLatest(actions.updateCourse.request, updateCourse),
    takeLatest(actions.deleteCourse.request, deleteCourse),
    takeLatest(actions.createActivity.request, createActivity),
    takeLatest(actions.updateActivity.request, updateActivity),
    takeLatest(actions.deleteActivity.request, deleteActivity),
    takeLatest(actions.planTask.request, planTask),
    takeLatest(actions.updateCalendarSlatHeight, updateCalendarSlatHeight),
    takeLatest(actions.getTasksForPlanning.request, getTasksForPlanning),
    takeLatest(actions.planTasks.request, planTasks),
    takeLatest(actions.resetNavigation, resetNavigation),
    takeLatest(actions.reorderCourses.request, reorderCourse),
    takeLatest(actions.reorderActivities.request, reorderActivities),
    takeLatest(actions.updatePlannedTask.request, updatePlannedTask),
    takeLatest(actions.markAsUsed.request, markAsUsed),
    takeLatest(actions.stopTimerForPlannedTask.request, stopTimerForPlannedTask),
    takeLatest(actions.duplicatePlannedTask.request, duplicatePlannedTask),
    takeLatest(actions.changeEventDefaultColor.request, changeEventDefaultColor)
  ]);
}
