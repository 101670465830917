import { StartTimerDto, TimerDto, UpdatePlannedTaskResponse, UpdateTaskTime } from 'shovel-lib/types';
import { actionCreator, asyncActionsCreator } from '../common/utils';

const asyncActions = asyncActionsCreator('@@timer');
const action = actionCreator('@@timer');

export const start = asyncActions<StartTimerDto, UpdatePlannedTaskResponse, string>('START');

export const toggle = asyncActions<void, TimerDto, string>('TOGGLE');

export const cancel = asyncActions<void, { activeTimer: any; response: TimerDto }, string>('CANCEL');

export const syncTicking = action<undefined>('SYNC_TICKING');

export const tick = action<void>('TICK');
export const startTicking = action<void>('START_TICKING');
export const stopTicking = action<void>('STOP_TICKING');

export const getCurrentTimer = asyncActions<void, TimerDto, string>('GET_CURRENT_TIMER');

export const clearSession = action<void>('CLEAR_SESSION');

export const updateTimerForTask = asyncActions<UpdateTaskTime, TimerDto, string>('UPDATE_TIMER_FOR_TASK');

// actions for stopping timer for planned task on click on timer icon, on click mark as used or click delete planned task
// it calls only if deleted/marked as used task is the one from active timer
export const stopCurrentTimer = action<TimerDto>('STOP_CURRENT_TIMER');
