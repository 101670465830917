import React, { FC } from 'react';
import styled from 'styled-components';

import {
  DATE_INPUT_PADDING,
  Error,
  INPUT_BOTTOM_MARGIN,
  INPUT_HORIZONTAL_PADDING,
  INPUT_LABEL_HEIGHT,
  INPUT_VERTICAL_PADDING,
  NO_LABEL_INPUT_VERTICAL_PADDING
} from './Input.styles';
import { MiniText } from '../../../utils/typography';
import colors from '../../../utils/colors';
import { Row } from '../layoutUtils';
import { AlignType } from '../../../state/common/utils';
import Popover from '../Popover';
import { PopoverType, TooltipPosition } from '../../../utils/popupUtils';
import MaterialIcon from '../icons/MaterialIcon';

type Props = {
  label?: string;
  icon?: any;
  iconOutline?: boolean;
  error?: string;
  className?: string;
  autosize?: boolean;
  align?: AlignType;
  searchInput?: boolean;
  disabled?: boolean;
  name?: string;
  placeholder?: string;
  touched?: boolean;
  tooltip?: string;
  noErrorMargin?: boolean;
  tooltipPosition?: TooltipPosition;
  required?: boolean;
  iconOnClick?: any;
  labelOnClick?: any;
};

const InputWrapper: FC<Props> = ({
  label = '',
  icon,
  iconOutline,
  error,
  children,
  autosize,
  align,
  className,
  disabled,
  name,
  touched,
  tooltip,
  noErrorMargin,
  searchInput,
  tooltipPosition = 'right center',
  required,
  iconOnClick,
  labelOnClick
}) => {
  const hasError = !!error && !!touched && !disabled;
  return (
    <Container
      className={className}
      error={hasError}
      autosize={autosize}
      withLabel={!!label}
      disabled={disabled}
      icon={!!icon}
      data-cy={name}
      searchInput={searchInput}
      noErrorMargin={noErrorMargin}
    >
      {icon && searchInput && (
        <Icon onClick={iconOnClick} name={icon} disabled={disabled} outline={iconOutline} searchInput />
      )}
      {label && (
        <InputLabel align={align} error={hasError} disabled={disabled} onClick={disabled ? undefined : labelOnClick}>
          {`${label}${required ? ' *' : ''}`}
          {tooltip && (
            <Popover
              content={tooltip}
              position={tooltipPosition}
              type={PopoverType.TOOLTIP}
              iconStyle={{
                padding: 0,
                width: 'auto',
                height: 'auto',
                position: 'absolute',
                top: -5,
                right: -5,
                zIndex: 2
              }}
              iconSize={12}
            />
          )}
        </InputLabel>
      )}
      {children}
      {icon && !searchInput && <Icon onClick={iconOnClick} name={icon} disabled={disabled} outline={iconOutline} />}
      {error && touched && !disabled && <Error align={align}>{error}</Error>}
    </Container>
  );
};

export default InputWrapper;

const Icon = styled(MaterialIcon)<{ disabled?: boolean; searchInput?: boolean; onClick?: any }>`
  transition: color 0.2s ease;
  position: absolute;
  ${props => (props.searchInput ? 'left' : 'right')}: ${INPUT_HORIZONTAL_PADDING}px;
  color: ${({ theme, disabled }) => (disabled ? theme.disabledTextColor : theme.textColor)};
  ${props => props.onClick && 'z-index: 1'};
`;

export const InputLabel = styled(MiniText)<{ align?: AlignType; error?: boolean; disabled?: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.disabledTextColor : theme.textColor)};
  line-height: 1;
  position: absolute;
  text-align: ${props => props.align || 'left'};
  left: ${INPUT_HORIZONTAL_PADDING}px;
  right: ${INPUT_HORIZONTAL_PADDING}px;
  top: ${INPUT_VERTICAL_PADDING}px;
  ${props => props.error && `color: ${colors.negative} !important`};

  & + ${Icon} {
    margin-top: 0 !important;
  }
`;

const Container = styled(Row)<{
  searchInput?: boolean;
  autosize?: boolean;
  withLabel?: boolean;
  error?: boolean;
  disabled?: boolean;
  icon?: boolean;
  noErrorMargin?: boolean;
}>`
  box-sizing: border-box;
  margin-bottom: ${props => (props.noErrorMargin ? 0 : INPUT_BOTTOM_MARGIN)};
  position: relative;
  justify-content: space-between;
  min-width: ${props => (props.withLabel ? `120px` : `90px`)};
  border-radius: 5px !important;
  border: 1px solid ${({ theme }) => theme.captures};
  font-size: ${props => (props.withLabel ? 13 : 12)}px;
  cursor: default;
  input {
    font-size: inherit;
    padding-top: ${props =>
      props.withLabel ? INPUT_VERTICAL_PADDING + INPUT_LABEL_HEIGHT : NO_LABEL_INPUT_VERTICAL_PADDING}px;
    padding-bottom: ${props => (props.withLabel ? INPUT_VERTICAL_PADDING : NO_LABEL_INPUT_VERTICAL_PADDING)}px;
    padding-right: ${props =>
      INPUT_HORIZONTAL_PADDING + (props.icon || props.searchInput ? (props.withLabel ? 30 : 20) : 0)}px;
    padding-left: ${props => INPUT_HORIZONTAL_PADDING + (props.searchInput && props.icon ? 25 : 0)}px;
    border-radius: 5px;
  }

  ${props => props.error && `border-color: ${colors.negative} !important`};
  ${props =>
    props.disabled &&
    `background-color: ${props.theme.backgroundLight} !important;
     border-color: ${props.theme.captures} !important;
     & ${Icon} {
      color: ${props.theme.disabledTextColor};
      }`};
  transition: all 0.2s ease;
  .size-placeholder {
    line-height: 1;
    padding: ${DATE_INPUT_PADDING};
  }

  &:hover {
    border-color: ${({ theme }) => theme.textColor};
  }

  &:focus-within {
    border-color: ${colors.primaryPurple};

    ${InputLabel} {
      color: ${colors.primaryPurple};
    }

    & ${Icon} {
      color: ${colors.primaryPurple};
    }
  }
`;
