import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { timerApi } from '../../api';
import * as actions from './actions';
import { getErrorMessage } from '../../api/network';
import { timerActions } from './index';
import { callApi } from '../common/operations';
import { semesterSelectors } from '../semester';
import { TimerStatus } from 'shovel-lib/types';
import { Dispatch } from 'redux';
import gaAnalytics, { AnalyticsEvent } from '../../config/gaAnalytics';
import * as timerSelectors from './selectors';

function* start({ payload }: ReturnType<typeof actions.start.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(timerApi.start, semesterId, {
    uid: payload.uid,
    entryType: payload.entryType,
    previousTimePassedInSeconds: payload.previousTimePassedInSeconds
  });
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    //@ts-ignore
    yield put(actions.start.success({ ...response.data, taskId: payload.taskId }));
    yield put(actions.syncTicking());
    yield fork(gaAnalytics.track, AnalyticsEvent.StartTimer.name);
  } else {
    yield put(actions.start.failure(getErrorMessage(response)));
  }
}

function* toggle() {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(timerApi.toggle, semesterId);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.toggle.success(response.data));
    if (response.data.state === TimerStatus.ACTIVE) {
      yield put(actions.syncTicking());
    }
  } else {
    const errorMessage = getErrorMessage(response);
    yield call(handleTimerError, errorMessage);
    yield put(actions.toggle.failure(errorMessage));
  }
}

function* cancel() {
  const semesterId = yield select(semesterSelectors.getId);
  const activeTimer = yield select(timerSelectors.getActiveTimer);
  const apiCall = call(timerApi.cancel, semesterId);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.cancel.success({ activeTimer, response: response.data }));
  } else {
    const errorMessage = getErrorMessage(response);
    yield call(handleTimerError, errorMessage);
    yield put(actions.cancel.failure(errorMessage));
  }
}

export function* getCurrentTimer() {
  const semesterId = yield select(semesterSelectors.getId);
  const activeTimer = yield select(timerSelectors.getActiveTimer);
  const currentTimerState = activeTimer?.status || TimerStatus.INITIAL;

  if (!semesterId) return;
  const apiCall = call(timerApi.getBySemesterId, semesterId);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getCurrentTimer.success(response.data));
    if (response.data.state === TimerStatus.ACTIVE) {
      yield put(actions.syncTicking());
    }
    if (currentTimerState !== response.data.state && response.data.state === TimerStatus.PAUSED) {
      yield put(actions.toggle.success(response.data));
    }
    if (currentTimerState !== response.data.state && response.data.state === TimerStatus.INITIAL) {
      yield put(actions.cancel.success({ activeTimer, response: response.data }));
    }
  } else {
    yield put(actions.getCurrentTimer.failure(getErrorMessage(response)));
  }
}

/**
 * Manually changing active timer time
 */
function* updateTimerForTask(action: ReturnType<typeof timerActions.updateTimerForTask.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(timerApi.updateTimerForTask, semesterId, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.updateTimerForTask.success(response.data));
  } else {
    const errorMessage = getErrorMessage(response);
    yield call(handleTimerError, errorMessage);
    yield put(actions.updateTimerForTask.failure(errorMessage));
  }
}

function* handleTimerError(error: string) {
  if (error === 'STARTED_STOPWATCH_DOES_NOT_EXIST') {
    yield put(timerActions.clearSession());
  }
}

export function* watchTimerTicking(dispatch: Dispatch) {
  while (true) {
    yield take(getType(actions.syncTicking));

    // no need to tick every second since we display only minutes
    const listener = setInterval(() => dispatch({ type: getType(actions.tick) }), 1000);

    yield take([actions.toggle.success, actions.cancel.success, actions.stopCurrentTimer]);

    yield put(actions.stopTicking());
    yield call(clearInterval, listener);
  }
}

export default function*(dispatch: Dispatch) {
  yield all([
    watchTimerTicking(dispatch),
    takeLatest(actions.start.request, start),
    takeLatest(actions.toggle.request, toggle),
    takeLatest(actions.cancel.request, cancel),
    takeLatest(actions.getCurrentTimer.request, getCurrentTimer),
    takeLatest(actions.updateTimerForTask.request, updateTimerForTask)
  ]);
}
