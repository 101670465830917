import { RegularText } from '../../../utils/typography';
import { Column } from '../../common/layoutUtils';
import React, { useState } from 'react';
import { MembershipType, PaymentProcessor, PriceTier } from 'shovel-lib/types';
import styled from 'styled-components';
import { TextInput } from '../../common/inputs';
import colors from '../../../utils/colors';
import StripeSubscribe from './StripeSubscribe';
import BraintreeCheckout from './BraintreeCheckout';
import t from '../../../i18n/t';
import { pricePerPlan, toPriceText } from '../../../utils/settingsUtils';
import { bindActionCreators, Dispatch } from 'redux';
import { commonActions } from '../../../state/common';
import { connect } from 'react-redux';

type Props = {
  tier?: PriceTier;
  paymentProcessor: PaymentProcessor;
  goToSuccessPaymentPage: typeof commonActions.goToSuccessPaymentPage;
};

function SwitchToLifetimeForm({ paymentProcessor, tier = PriceTier.TIER_ONE, goToSuccessPaymentPage }: Props) {
  const [coupon, setCoupon] = useState('');

  const isFirstTier = tier === PriceTier.TIER_ONE;
  return (
    <Container>
      <Title strong>
        {t.SWITCH_TO_LIFETIME}
        {!isFirstTier && (
          <span style={{ textDecoration: 'line-through' }}>
            {toPriceText(pricePerPlan[PriceTier.TIER_ONE][MembershipType.LIFETIME])}
          </span>
        )}
        &nbsp;
        <span style={{ color: colors.positive }}>{toPriceText(pricePerPlan[tier][MembershipType.LIFETIME])}</span>
      </Title>
      {tier === PriceTier.TIER_ONE && (
        <>
          <RegularText>{t.HAVE_A_COUPON}</RegularText>
          <DiscountCouponInput placeholder={t.ENTER_IT_HERE} value={coupon} onChange={e => setCoupon(e.target.value)} />
        </>
      )}
      {paymentProcessor === PaymentProcessor.STRIPE ? (
        <StripeSubscribe selectedPlan={MembershipType.LIFETIME} coupon={coupon} buttonText={t.CONTINUE_TO_LIFETIME} />
      ) : (
        <>
          <RegularText style={{ marginBottom: 20 }}>{t.CONTINUE_TO_LIFETIME_PAYPAL}</RegularText>
          <BraintreeCheckout coupon={coupon} tier={tier} onSuccess={location => goToSuccessPaymentPage(location)} />
        </>
      )}
    </Container>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      goToSuccessPaymentPage: commonActions.goToSuccessPaymentPage
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(SwitchToLifetimeForm);

const Container = styled(Column)`
  width: 100%;
  margin-top: 30px;
  padding: 30px 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${props => props.theme.borderColor};
`;

const DiscountCouponInput = styled(TextInput)`
  border-radius: 0 !important;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  max-width: 150px;
  margin-bottom: 25px;

  &:active {
    border-color: ${colors.primaryPurple};
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    color: ${props => props.theme.placeholder};
    -webkit-text-fill-color: transparent;
  }
`;

const Title = styled(RegularText)`
  display: block;
  margin-bottom: 20px;
  text-transform: uppercase;
`;
