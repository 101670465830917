import { createReducer, getType } from 'typesafe-actions';

import { PileSections, PileState, SortOption } from './types';
import * as actions from './actions';
import { toCompletedTask, toDoLaterTask, toNoDueDateTask, toToDoTask, toRecentlyCreatedTask } from './converters';
import { PileFilterType, PileStateOptions } from 'shovel-lib/types';
import * as calendarActions from '../calendar/actions';
import { deleteTaskCategory, updateTaskCategory } from '../course/actions';
import * as taskActions from '../task/actions';
import { getUserSettings, saveUserSettings } from '../settings/actions';
import { now, toMomentDate } from 'shovel-lib/utils/timeUtils';
import { timerActions } from '../timer';
import storage from '@utils/storage';

export const initialFilterState: PileFilterType = {
  //TODO: for now on BE types are ignored
  types: [],
  courseIds: [],
  priorities: [],
  negativeCushionMarked: false,
  hidePlannedMarked: false,
  examMarked: false,
  next7Days: false,
  nextWeek: false,
  thisWeek: false,
  lastWeek: false,
  last30Days: false
};

export const initialSortState = {
  todo: {
    sortField: SortOption.DUE_DATE,
    desc: false
  },
  completed: {
    sortField: SortOption.DATE_COMPLETED,
    desc: true
  }
};

const initialState: PileState = {
  activeTab: PileStateOptions.ACTIVE,
  courses: [],
  filter: storage.getPileFilter() || initialFilterState,
  sort: storage.getPileSort() || initialSortState,
  search: '',
  allToDo: {
    content: [],
    last: true,
    totalPages: 0,
    totalElements: 0,
    size: 0,
    sort: null,
    first: true,
    numberOfElements: 0,
    page: 0
  },
  withinStartDate: {
    tasks: []
  },
  completed: {
    tasks: []
  },
  [PileSections.OVERDUE]: {
    expanded: false,
    tasks: []
  },
  [PileSections.NO_DUE_DATE]: {
    expanded: false,
    tasks: []
  },
  moveToDoDialog: {
    open: false,
    data: {
      dueDate: '',
      daysToStartAhead: -1,
      taskId: -1
    }
  },
  [PileSections.RECENTLY_CREATED]: {
    tasks: [],
    expanded: false
  }
};

const reducer = createReducer(initialState)
  .handleAction(actions.getCourses.success, (state, action) => ({ ...state, courses: action.payload }))

  .handleAction(actions.applyFilter, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      filter: payload.filter,
      sort: payload.sort
    };
  })
  .handleAction(actions.applySearch, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      search: payload,
      [PileSections.OVERDUE]: {
        ...state[PileSections.OVERDUE],
        expanded: !!payload
      },
      [PileSections.NO_DUE_DATE]: {
        ...state[PileSections.NO_DUE_DATE],
        expanded: !!payload
      },
      [PileSections.RECENTLY_CREATED]: {
        ...state[PileSections.RECENTLY_CREATED],
        expanded: !!payload
      }
    };
  })

  .handleAction(actions.clearState, () => initialState)

  .handleAction(actions.clearStateButKeepFilterAndSort, state => ({
    ...initialState,
    filter: state.filter,
    sort: state.sort
  }))

  .handleAction(actions.getAllToDoTasks.success, (state, action) => {
    const { isRefresh, ...payload } = action.payload;
    const tasks = payload.content.map(t => toToDoTask(t));
    return {
      ...state,
      allToDo: {
        ...(isRefresh ? { ...state.allToDo, last: payload.last } : payload),
        content: payload.page === 0 ? tasks : state.allToDo.content.concat(tasks)
      }
    };
  })

  .handleAction(actions.getWithinStartDateTasks.success, (state, action) => ({
    ...state,
    withinStartDate: {
      ...state.withinStartDate,
      tasks: action.payload.map(t => toToDoTask(t))
    }
  }))

  .handleAction(actions.getOverdueTasks.success, (state, action) => ({
    ...state,
    [PileSections.OVERDUE]: {
      ...state[PileSections.OVERDUE],
      tasks: action.payload.map(t => toDoLaterTask(t))
    }
  }))

  .handleAction(actions.getCompletedTasks.success, (state, action) => ({
    ...state,
    completed: {
      ...state.completed,
      tasks: action.payload.map(t => toCompletedTask(t))
    }
  }))
  .handleAction(actions.openMoveToDoDialog, (state, action) => ({
    ...state,
    moveToDoDialog: {
      open: true,
      data: action.payload
    }
  }))
  .handleAction(actions.closeMoveToDoDialog, (state, action) => ({
    ...state,
    moveToDoDialog: initialState.moveToDoDialog
  }))
  .handleAction(actions.getNoDueDateTasks.success, (state, action) => ({
    ...state,
    [PileSections.NO_DUE_DATE]: {
      ...state[PileSections.NO_DUE_DATE],
      tasks: action.payload.map(t => toNoDueDateTask(t))
    }
  }))
  .handleAction(calendarActions.updateCourse.success, (state, action) => {
    const { id, colorHex, name } = action.payload;
    const mapFn = task => {
      if (task.courseId !== id) return task;
      return { ...task, colorHex, courseName: name };
    };
    return newPileStateMap(state, mapFn);
  })
  .handleAction(updateTaskCategory.success, (state, action) => {
    const { id, name, emoji } = action.payload;
    const mapFn = task => {
      if (task.categoryId !== id) return task;
      return { ...task, emoji, categoryName: name };
    };
    return newPileStateMap(state, mapFn);
  })
  .handleAction(deleteTaskCategory.success, (state, action) => {
    const mapFn = task => {
      if (task.categoryId !== action.payload.id) return task;
      return { ...task, categoryId: null, emoji: null, categoryName: null };
    };
    return newPileStateMap(state, mapFn);
  })
  .handleAction(taskActions.deleteTask.success, (state, action) => {
    const filterFn = task => task.taskId !== action.payload.id;
    return newPileStateFilter(state, filterFn);
  })
  .handleAction(taskActions.deleteTasks.success, (state, action) => {
    const filterFn = task => action.payload.ids.includes(task.taskId);
    return newPileStateFilter(state, filterFn);
  })
  .handleAction(taskActions.setTimeNeeded.success, (state, action) => {
    const { taskIds, timeNeeded } = action.payload;
    const mapFn = task => {
      return taskIds.includes(task.taskId)
        ? {
            ...task,
            timeNeed: timeNeeded
          }
        : task;
    };
    return newPileStateMap(state, mapFn);
  })
  .handleAction(taskActions.saveManageTask.success, (state, action) => {
    const {
      taskId,
      request,
      response: { timeEntries, ...taskProps }
    } = action.payload;
    // pile will reload in this case
    if (Object(request).hasOwnProperty('dueDate') || Object(request).hasOwnProperty('daysToStartAhead')) {
      return state;
    }
    const mapFn = task => {
      return taskId === task.taskId
        ? {
            ...task,
            ...taskProps,
            subtitle: taskProps.details
          }
        : task;
    };
    return newPileStateMap(state, mapFn);
  })
  .handleAction(calendarActions.updatePlannedTask.success, (state, action) => {
    const { taskId, timeSpent, timeNeed, timePlanned } = action.payload.data;
    const mapFn = task => {
      return taskId === task.taskId
        ? {
            ...task,
            timeNeed,
            timeSpent,
            timePlanned
          }
        : task;
    };
    return newPileStateMap(state, mapFn);
  })
  .handleAction([calendarActions.markAsUsed.success, timerActions.start.success], (state, action) => {
    const { taskId, timeSpent, timeNeed, timePlanned, plannedTaskDto } = action.payload;
    if (!plannedTaskDto) return state;
    const mapFn = task => {
      return taskId === task.taskId
        ? {
            ...task,
            timeNeed,
            timeSpent,
            timePlanned
          }
        : task;
    };
    return newPileStateMap(state, mapFn);
  })
  .handleAction(actions.calculateCushion.success, (state, action) => {
    const cushions = action.payload;
    const allToDo =
      state.activeTab === PileStateOptions.ACTIVE
        ? {
            ...state.allToDo,
            content: state.allToDo.content.map(t => ({ ...t, cushion: cushions[t.taskId] }))
          }
        : state.allToDo;
    const withinStartDate =
      state.activeTab === PileStateOptions.UPCOMING
        ? {
            tasks: state.withinStartDate.tasks.map(t => ({ ...t, cushion: cushions[t.taskId] }))
          }
        : state.withinStartDate;
    return {
      ...state,
      allToDo,
      withinStartDate
    };
  })
  .handleAction(actions.clearCushion, state => ({
    ...state,
    allToDo: {
      ...state.allToDo,
      // @ts-ignore
      content: state.allToDo.content.map(t => ({ ...t, cushion: null }))
    },
    withinStartDate: {
      tasks: state.withinStartDate.tasks.map(t => ({ ...t, cushion: null }))
    }
  }))
  .handleAction(taskActions.changeTaskCategory.success, (state, action) => {
    const { taskIds, category } = action.payload;
    const taskCategory = { categoryId: category?.id, categoryName: category?.name, emoji: category?.emoji };
    const mapFunction = t => (taskIds.includes(t.taskId) ? { ...t, ...taskCategory } : t);
    return newPileStateMap(state, mapFunction);
  })
  .handleAction([getUserSettings.success, saveUserSettings.success], (state, action) => {
    if (action.payload) {
      return {
        ...state,
        activeTab: action.payload.pileState
      };
    }
    return state;
  })
  .handleAction([calendarActions.planTask.success, calendarActions.planTasks.success], (state, action) => {
    if (state.activeTab === PileStateOptions.COMPLETED) {
      return state; // cannot plan completed tasks so there's no need to refresh completed pile
    }

    const plannedTasks = action.type === getType(calendarActions.planTask.success) ? [action.payload] : action.payload;
    const mapFunction = t => {
      const plannedTask = plannedTasks.find(pt => pt.taskId === t.taskId);
      if (!plannedTask || now().isAfter(plannedTask.end)) return t;
      const timePlanned = (t.timePlanned || 0) + toMomentDate(plannedTask.end).diff(plannedTask.start, 'minutes');
      return { ...t, timePlanned };
    };
    return newPileStateMap(state, mapFunction);
  })
  .handleAction(calendarActions.deletePlannedTask.success, (state, action) => {
    const {
      event: { taskId, start, end, isUsed },
      data: { timeNeed, timeSpent, timePlanned }
    } = action.payload;

    if (state.activeTab === PileStateOptions.COMPLETED || now().isAfter(end)) {
      return state;
    }
    let mapFunction;
    if (isUsed) {
      mapFunction = t =>
        t.taskId === taskId
          ? {
              ...t,
              timeNeed,
              timeSpent,
              timePlanned
            }
          : t;
    } else {
      const plannedMinutes = toMomentDate(end).diff(start, 'minutes');
      mapFunction = t =>
        t.taskId === taskId
          ? {
              ...t,
              timePlanned: Math.max(t.timePlanned - plannedMinutes, 0)
            }
          : t;
    }

    return newPileStateMap(state, mapFunction);
  })
  .handleAction(calendarActions.movePlannedTask.success, (state, action) => {
    const { oldEvent, newEvent } = action.payload;
    if (state.activeTab === PileStateOptions.COMPLETED) {
      return state;
    }

    const today = now();
    const oldPlannedMinutes = toMomentDate(oldEvent.end).diff(oldEvent.start, 'minutes');
    const newPlannedMinutes = toMomentDate(newEvent.end).diff(newEvent.start, 'minutes');
    const timePlannedDiff =
      (today.isSameOrBefore(newEvent.end) ? newPlannedMinutes : 0) -
      (today.isSameOrBefore(oldEvent.end) ? oldPlannedMinutes : 0);

    const mapFunction = t =>
      t.taskId === newEvent.taskId
        ? {
            ...t,
            timePlanned: newEvent.isUsed ? t.timePlanned : Math.max(t.timePlanned + timePlannedDiff, 0)
          }
        : t;

    return newPileStateMap(state, mapFunction);
  })
  .handleAction(calendarActions.resizePlannedTask.success, (state, action) => {
    const { oldEvent, newEvent } = action.payload;
    if (state.activeTab === PileStateOptions.COMPLETED) {
      return state;
    }

    const today = now();
    const oldPlannedMinutes = toMomentDate(oldEvent.end).diff(oldEvent.start, 'minutes');
    const newPlannedMinutes = toMomentDate(newEvent.end).diff(newEvent.start, 'minutes');
    const timePlannedDiff =
      (today.isSameOrBefore(newEvent.end) ? newPlannedMinutes : 0) -
      (today.isSameOrBefore(oldEvent.end) ? oldPlannedMinutes : 0);

    const { timeNeed, timeSpent, isUsed } = newEvent;
    const mapFunction = t =>
      t.taskId === newEvent.taskId
        ? {
            ...t,
            timePlanned: isUsed ? t.timePlanned : Math.max(t.timePlanned + timePlannedDiff, 0),
            timeNeed: isUsed ? timeNeed : t.timeNeed,
            timeSpent: isUsed ? timeSpent : t.timeSpent
          }
        : t;

    return newPileStateMap(state, mapFunction);
  })
  .handleAction(taskActions.addPlannedTask.success, (state, action) => {
    const { taskId, timePlanned } = action.payload;
    const mapFunction = t =>
      t.taskId === taskId
        ? {
            ...t,
            timePlanned: Math.max(t.timePlanned + timePlanned, 0)
          }
        : t;

    return newPileStateMap(state, mapFunction);
  })
  .handleAction(actions.getRecentlyCreated.success, (state, action) => ({
    ...state,
    [PileSections.RECENTLY_CREATED]: {
      ...state[PileSections.RECENTLY_CREATED],
      tasks: action.payload.map(t => toRecentlyCreatedTask(t))
    }
  }))
  .handleAction(taskActions.createTasks.success, (state, action) => ({
    ...state,
    [PileSections.RECENTLY_CREATED]: {
      ...state[PileSections.RECENTLY_CREATED],
      expanded: action.payload.isFromSidebar || state[PileSections.RECENTLY_CREATED].expanded
    }
  }))
  .handleAction(actions.toggleExpandPileSection, (state, action) => ({
    ...state,
    [action.payload]: {
      ...state[action.payload],
      expanded: !state[action.payload].expanded
    }
  }));

const newPileStateMap = (state, mapFn) => {
  const completedActive = state.activeTab === PileStateOptions.COMPLETED;
  const allTodoActive = !completedActive && state.activeTab === PileStateOptions.ACTIVE;
  const upcomingActive = !allTodoActive && state.activeTab === PileStateOptions.UPCOMING;

  return {
    ...state,
    allToDo: allTodoActive
      ? {
          ...state.allToDo,
          content: state.allToDo.content.map(mapFn)
        }
      : state.allToDo,
    withinStartDate: upcomingActive ? { tasks: state.withinStartDate.tasks.map(mapFn) } : state.withinStartDate,
    completed: completedActive ? { tasks: state.completed.tasks.map(mapFn) } : state.completed,
    [PileSections.OVERDUE]: !completedActive
      ? { ...state[PileSections.OVERDUE], tasks: state[PileSections.OVERDUE].tasks.map(mapFn) }
      : state[PileSections.OVERDUE],
    [PileSections.NO_DUE_DATE]: !completedActive
      ? { ...state[PileSections.NO_DUE_DATE], tasks: state[PileSections.NO_DUE_DATE].tasks.map(mapFn) }
      : state[PileSections.NO_DUE_DATE],
    [PileSections.RECENTLY_CREATED]: !completedActive
      ? { ...state[PileSections.RECENTLY_CREATED], tasks: state[PileSections.RECENTLY_CREATED].tasks.map(mapFn) }
      : state[PileSections.RECENTLY_CREATED]
  };
};

const newPileStateFilter = (state, filterFn) => ({
  ...state,
  allToDo: {
    ...state.allToDo,
    content: state.allToDo.content.filter(filterFn)
  },
  withinStartDate: { tasks: state.withinStartDate.tasks.filter(filterFn) },
  completed: { tasks: state.completed.tasks.filter(filterFn) },
  [PileSections.OVERDUE]: {
    ...state[PileSections.OVERDUE],
    tasks: state[PileSections.OVERDUE].tasks.filter(filterFn)
  },
  [PileSections.NO_DUE_DATE]: {
    ...state[PileSections.NO_DUE_DATE],
    tasks: state[PileSections.NO_DUE_DATE].tasks.filter(filterFn)
  },
  [PileSections.RECENTLY_CREATED]: {
    ...state[PileSections.RECENTLY_CREATED],
    tasks: state[PileSections.RECENTLY_CREATED].tasks.filter(filterFn)
  }
});

export default reducer;
