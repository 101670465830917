// @ts-nocheck
import { PaymentProcessor } from 'shovel-lib/types';
import { gtmId } from './configConstants';
import { roundNumber } from '@components/common/onboardingBar/helpers';

const USE_GTM = !!gtmId;

let loaded = false;

const initializeAnalytics = () => {
  if (USE_GTM && !loaded) {
    includeGtmScript();
    loaded = true;
  } else {
    console.log('.setupAnalytics()');
  }
};

const identifyUser = (email: string, organizationId?: number) => {
  if (USE_GTM) {
    window.dataLayer.push({
      userId: base64Encode(email),
      organizationId
    });
  } else {
    console.log(`.identifyUser(email: ${email}, organizationId: ${organizationId})`);
  }
};

const track = (event: string, params?: any) => {
  const props = params ? { eventProps: params } : {};
  if (USE_GTM) {
    window.dataLayer.push({
      event: event,
      ...props
    });
  } else {
    console.log(`.track(event: ${event}, params: ${JSON.stringify(props)})`);
  }
};

const base64Encode = (value: string) => btoa(value);

// <!-- Google Tag Manager -->
const includeGtmScript = () => {
  (function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', gtmId);

  window.dataLayer = window.dataLayer || [];
};

export const AnalyticsEvent = {
  Registration: {
    name: 'sign_up',
    params: (organizationId?: number) => ({
      organizationId
    })
  },
  MobileRegistration: {
    name: 'sign_up_mobile',
    params: (organizationId?: number) => ({
      organizationId
    })
  },
  CancelSubscription: {
    name: 'cancel_subscription'
  },
  DeleteAccount: {
    name: 'delete_account',
    params: (trialLeft: number) => ({
      trialLeft
    })
  },
  Subscribe: {
    name: 'begin_checkout',
    params: (processor: PaymentProcessor, plan: string, coupon?: string) => ({ processor, plan, coupon })
  },
  CompleteTask: {
    name: 'complete_task',
    params: ({
      completedOn,
      due,
      estimatedTime,
      timePlanned,
      timeStillNeed
    }: {
      completedOn: string;
      due?: string;
      timePlanned: number;
      timeStillNeed: number;
      estimatedTime: number;
    }) => ({
      completedOn,
      due,
      timePlanned,
      estimatedTime,
      timeStillNeed
    })
  },
  PlanTask: {
    name: 'plan_task',
    params: (days_before_due: number, days_after_start: number) => ({
      daysBeforeDue: roundNumber(days_before_due, 0),
      daysFromStart: roundNumber(days_after_start, 0)
    })
  },
  CreateTask: {
    name: 'create_task',
    params: (total: number) => ({ total })
  },
  StartTimer: {
    name: 'start_timer'
  },
  CreateEvent: {
    name: 'create_event'
  },
  CompleteDemo: {
    name: 'complete_demo'
  },
  QuickSetup: {
    TimeZone: {
      name: 'complete_setup_time_zone'
    },
    TermDates: {
      name: 'complete_setup_term_dates'
    },
    Holidays: {
      name: 'complete_setup_holidays'
    },
    AwakeTime: {
      name: 'complete_setup_awake_time'
    },
    Courses: {
      name: 'complete_setup_courses'
    },
    Activities: {
      name: 'complete_setup_activities'
    }
  },
  ResetTrial: {
    name: 'reset_trial'
  },
  RedeemAccess: {
    name: 'redeem_access'
  }
};

export default {
  initializeAnalytics,
  identifyUser,
  track
};
