import { createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { TermState } from './types';
import { changeCurrentSemester } from '../semester/actions';
import storage from '../../utils/storage';

export const DEFAULT_AWAKE_TIME_DURATION = 1020;
const initialState: TermState = {
  termStep: 1,
  loading: false,
  error: '',
  countries: [],
  timezones: [],
  awakeTime: storage.getAwakeTimeValues() || {
    uptime: '08:00',
    awakeDurationInMinutes: DEFAULT_AWAKE_TIME_DURATION // bedtime at 01:00
  }
};

const reducer = createReducer(initialState)
  .handleAction(actions.increaseTermStep, state => {
    return {
      ...state,
      termStep: state.termStep + 1
    };
  })
  .handleAction(actions.findCountries.request, state => ({ ...state, loading: true, error: '' }))
  .handleAction(actions.findCountries.success, (state, action) => {
    // @ts-ignore
    const payload = action.payload.map(item => {
      return { label: item.name, value: item.id, code: item.code };
    });
    return {
      ...state,
      loading: false,
      countries: payload
    };
  })
  .handleAction(actions.findCountries.failure, (state, action) => {
    // @ts-ignore
    const payload = action.payload;
    return {
      ...state,
      loading: false,
      error: payload
    };
  })
  .handleAction(actions.findTimezonesByCountry.request, state => ({ ...state, loading: true, error: '' }))
  .handleAction(actions.findTimezonesByCountry.success, (state, action) => {
    // @ts-ignore
    const payload = action.payload.map(item => {
      return { label: item.label, value: item.id };
    });
    return {
      ...state,
      loading: false,
      timezones: payload
    };
  })
  .handleAction(changeCurrentSemester.success, (state, action) => ({
    ...state,
    loading: false,
    timezones: []
  }))
  .handleAction(actions.findTimezonesByCountry.failure, (state, action) => {
    const payload = action.payload;
    return {
      ...state,
      loading: false,
      error: payload
    };
  })
  .handleAction(actions.setupTerm.request, state => ({ ...state, loading: true, error: '' }))
  .handleAction(actions.setupTerm.success, (state, action) => {
    return {
      ...state,
      loading: false
    };
  })
  .handleAction(actions.setupTerm.failure, (state, action) => {
    const payload = action.payload;
    return {
      ...state,
      loading: false,
      error: payload
    };
  })
  .handleAction(actions.setAwakeTimes.success, (state, action) => {
    return {
      ...state,
      awakeTime: action.payload
    };
  });

export default reducer;
