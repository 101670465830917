import { currentTheme } from './theme/appTheme';

const colors = {
  white: '#FFFFFF',
  primaryPurple: currentTheme.primary,
  primaryLight: currentTheme.primaryLight,
  primaryDark: currentTheme.primaryDark,
  positive: '#18BC9B',
  lightPositive: '#D1F1E5',
  // timer: '#00D4B4',
  warning: '#FBB028',
  lightWarning: '#FEEFD4',
  red: '#e1402a',
  negative: '#FF6473',
  lightNegative: '#FFE1E4',
  lightestNegative: '#FFEFF1',
  ultrablack: '#2D2D2D',
  black: '#273443',
  /**
   * input dropdown option and selected value (note that selected will be bold)
   * input label
   * */
  darkGray: '#3F4E61', // main + study time
  textDefault: '#647080',
  gray: '#8492A5',
  /**
   * - input placeholder
   * - icons
   * - input border
   * */
  backgroundLight: '#F9FAFC',
  whitesmoke: '#F5F7FA',
  lightgray: '#E0E6ED',
  captures: '#D3DCE6',
  placeholder: '#AFBED7',
  randomTaskColor: '#8492A5',
  customEventColor: '#5186EC',

  /*TODO: rename */
  warningAxisColor: '#FADE7D',
  warningBulletColor: '#FFD63F',
  pink: '#ff63b6',
  aqua: '#56CCF2'
};

export default colors;

export const colorShade = (colorHex, lighteningFactor) => {
  colorHex = colorHex.replace(/^#/, '');
  if (colorHex.length === 3) colorHex = colorHex[0] + colorHex[0] + colorHex[1] + colorHex[1] + colorHex[2] + colorHex[2];

  let [r, g, b] = colorHex.match(/.{2}/g);
  [r, g, b] = [parseInt(r, 16) + lighteningFactor, parseInt(g, 16) + lighteningFactor, parseInt(b, 16) + lighteningFactor];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? '0' : '') + r;
  const gg = (g.length < 2 ? '0' : '') + g;
  const bb = (b.length < 2 ? '0' : '') + b;

  return `#${rr}${gg}${bb}`;
};
