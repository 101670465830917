import colors from '../colors';

export const CUSTOM_EVENT_CLASS_NAME = 'custom-event';
export const COMMITMENT_EVENT_CLASS_NAME = 'commitment-event';

export const SLEEP_TIME_BACKGROUND_COLOR = '#E0E6ED'; // '#EAEEF4'; // with background event opacity of 0.3 gives #F9FAFC
export const SLEEP_TIME_DARK_BACKGROUND_COLOR = colors.darkGray;

export const CALENDAR_START_HOURS = 4;
export const PLANNABLE_EVENT_GROUP_ID = 'plannable-event';
export const PLANNABLE_EVENT_CLASS_NAME = 'plannable';
export const PLANNABLE_OVERDUE_EVENT_CLASS_NAME = 'plannable-overdue';
export const AWAKE_TIME_EVENT_TITLE = 'Awake';
export const CALENDAR_DEFAULT_SCROLL_TIME = '06:00:00';

export const EVENT_WARNING_CLASS = 'warning';
export const EVENT_GRAYED_CLASS = 'grayed';
export const EVENT_AWAKE_TIME_WARNING_CLASS = 'warning-awake';
export const EVENT_PLANNED_STUDY_WARNING_CLASS = 'warning-planned-study';

export const IS_PLANNABLE = 'IS_PLANNABLE';
export const IS_PLANNABLE_OVERDUE = 'IS_PLANNABLE_OVERDUE';

export const CALENDAR_SIDEBAR_TOGGLE_TRANSITION = 400;
export const CALENDAR_WIDTH_REFRESH_RATE = 50;
export const FC_CALENDAR_CHUNK_WIDTH = 85;

export const TASK_HEIGHT_LIMIT = 45; //limit in px for displaying course name
export const MAX_CALENDAR_HEIGHT = 4.5;
export const MIN_CALENDAR_HEIGHT = 0.5;
export const CALENDAR_HEIGHT_STEP = 0.05;
