import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as ShovelLogo } from '@assets/images/shovel-logo.svg';
import { Column } from '../layoutUtils';
import Tooltip from '../tooltip/Tooltip';
import { ExtraLargeText } from '@utils/typography';

type Props = {
  name: string;
  icon: string;
  color: string;
  completed: boolean;
  big?: boolean;
  tooltip?: string;
};

export const Badge: FC<Props> = ({ name, icon, color, completed, big, tooltip }: Props) => {
  const badge = (
    <Wrapper color={completed ? color : undefined} big={big} tooltip={!!tooltip}>
      {completed && icon ? <ExtraLargeText>{icon}</ExtraLargeText> : <ShovelLogo />}
    </Wrapper>
  );
  return !tooltip ? badge : <Tooltip target={badge} content={completed ? name : tooltip} position={'top center'} />;
};

const Wrapper = styled(Column)<{ color?: string; big?: boolean; tooltip?: boolean }>`
  width: ${props => (props.big ? 72 : 28)}px;
  height: ${props => (props.big ? 72 : 28)}px;
  border-radius: 50%;
  background: ${props => props.color || props.theme.onboardingBarBadgesBgColor};
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  margin-right: 10px;
  user-select: none;

  svg {
    width: ${props => (props.big ? 44 : 22)}px;
    height: ${props => (props.big ? 44 : 22)}px;
  }
  path {
    fill: ${props => props.theme.onboardingBarBadgesColor};
  }

  &:hover {
    ${props => props.tooltip && `border-color: ${props.theme.onboardingBarBadgesColor};`}
  }

  ${ExtraLargeText} {
    cursor: default;
    font-size: ${props => (props.big ? '36px' : '18px')};
  }
`;
