import { ActionType, createAsyncAction, createStandardAction, getType } from 'typesafe-actions';
import React, { Component } from 'react';
import WithLoadingComponent from '../../components/common/WithLoadingComponent';
import {
  ActivityDto,
  CourseDto,
  ReadingSourceInfoDto,
  Subtask,
  TaskCategoryDto,
  ThinActivityDto,
  ThinCourseDto
} from 'shovel-lib/types';
import t from '../../i18n/t';

export const extractActionNameWithoutSuffix = (action: ActionType<any>) => {
  const actionName = getType(action);
  return actionName.substring(0, actionName.indexOf('_REQUEST'));
};

export const withLoading = <T,>(WrappedComponent: React.ComponentType<T>, actions: ActionType<any>[]) => {
  return class extends Component<T> {
    render() {
      return (
        <WithLoadingComponent actions={actions.map(action => extractActionNameWithoutSuffix(action))}>
          <WrappedComponent {...this.props} />
        </WithLoadingComponent>
      );
    }
  };
};

export const asyncActionsCreator = (prefix: string) => <T, U, V>(type: string) =>
  createAsyncAction(`${prefix}/${type}_REQUEST`, `${prefix}/${type}_SUCCESS`, `${prefix}/${type}_FAILURE`)<T, U, V>();

export const actionCreator = (prefix: string) => <T,>(type: string) => createStandardAction(`${prefix}/${type}`)<T>();

export enum AlignType {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const MORE_OPTIONS_CLASS_NAME = 'more-options';

export const checkIfMoreOptionsIsClicked = element => {
  if (!element) {
    return false;
  }
  if (element.className.includes(MORE_OPTIONS_CLASS_NAME)) {
    return true;
  }
  return checkIfMoreOptionsIsClicked(element.parentElement);
};

export const fixOrdering = (items: (TaskCategoryDto | ReadingSourceInfoDto | CourseDto | ActivityDto | Subtask)[]) => {
  for (let i = 1; i <= items.length; i++) {
    items[i - 1].ordinalNumber = i;
  }
};

export const mapCoursesAndActivitiesToDropdownOptions = (
  courses: (ThinCourseDto | CourseDto)[],
  activities: (ThinActivityDto | ActivityDto)[],
  includeRandom?: boolean
) => {
  const values = [
    {
      label: t.COURSES,
      value: 'courses',
      options: courses.map((course: ThinCourseDto) => ({
        label: course.name,
        value: course.id,
        color: course.colorHex
      }))
    },
    {
      label: t.ACTIVITIES,
      value: 'activities',
      options: activities.map((activity: ThinActivityDto) => ({
        label: activity.name,
        value: activity.id,
        color: activity.colorHex
      }))
    }
  ];
  return includeRandom ? [{ value: t.RANDOM, label: '', options: [{ value: 0, label: t.RANDOM }] }].concat(values) : values;
};
