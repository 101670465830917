import { ApiResponse } from 'apisauce';
import { network } from './network';
import { GoogleCalendar, PlatformEvent } from '../state/googleCalendar/types';
import { ThinActivityDto } from 'shovel-lib/types';

const basePath = `/google-calendars`;

export interface GoogleCalendarApi {
  authorize: (code: string, state: number) => Promise<ApiResponse<string>>;
  getSavedCalendars: () => Promise<ApiResponse<GoogleCalendar[]>>;
  refreshGoogleCalendars: () => Promise<ApiResponse<GoogleCalendar[]>>;
  toggleGoogleCalendar: (id: number) => Promise<ApiResponse<void>>;
  unsyncGoogleCalendar: () => Promise<ApiResponse<void>>;
  getGoogleEvents: () => Promise<ApiResponse<PlatformEvent[]>>;
  disconnect: () => Promise<ApiResponse<void>>;
}

const authorize = (code: string, state: number) =>
  network.get<string>(`${basePath}/authorize`, {
    code,
    state
  });

const getSavedCalendars = () => network.get<GoogleCalendar[]>(basePath);

const refreshGoogleCalendars = () => network.post<GoogleCalendar[]>(`${basePath}/refresh`);

const toggleGoogleCalendar = (id: number) => network.post<void>(`${basePath}/${id}`);

const unsyncGoogleCalendar = () => network.delete<void>(basePath);

const getGoogleEvents = () => network.get<PlatformEvent[]>(`${basePath}/events`);

const disconnect = () => network.post<void>(`${basePath}/disconnect`);

export const googleCalendarApi: GoogleCalendarApi = {
  authorize,
  getSavedCalendars,
  refreshGoogleCalendars,
  toggleGoogleCalendar,
  unsyncGoogleCalendar,
  getGoogleEvents,
  disconnect
};
