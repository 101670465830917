import { createReducer } from 'typesafe-actions';
import { DATE_FORMAT, now, toMomentDate } from 'shovel-lib/utils/timeUtils';
import {
  CalendarEvent,
  CalendarEventType,
  DatesContainingTasks,
  DatesContainingTasksItem,
  ReadingSourceInfoDto,
  TaskCategoryDto,
  TasksByDate,
  TaskState,
  WorkloadTask
} from 'shovel-lib/types';

import * as semesterActions from '../semester/actions';
import * as actions from './actions';
import colors from '../../utils/colors';
import {
  applyOnTasksByDate,
  reduceCalendarEventsToObject,
  removeFromTasksByDate,
  sortByOrdinalNumberAsc,
  updateTasksByDate
} from '../../utils/reducerHelpers';
import storage from '../../utils/storage';
import { getRandomPickerColor } from '../../utils/colorUtils';
import {
  copyActivitiesFromPreviousSemester,
  createBatchActivities,
  createBatchCourses,
  createDefaultActivities,
  updateMinimumStudyTimeBlock
} from '../terms/actions';
import { CalendarState, CalendarView } from './types';
import {
  getGoogleEvents,
  getSavedCalendars,
  refreshGoogleCalendars,
  toggleGoogleCalendar,
  unsyncGoogleCalendar
} from '../googleCalendar/actions';
import t from '../../i18n/t';
import * as courseActions from '../course/actions';
import { fixOrdering } from '../common/utils';
import { isMobileApp } from '../../utils/screenUtils';
import * as taskActions from '../task/actions';
import { timerActions } from '../timer';
import { triggerIcsUpdates, unsyncCourseFromLMS } from '../ics/actions';
import { DEFAULT_TASK_CATEGORY_EMOJI } from '@utils/constants/task';

const initialApplyDialogState = {
  open: false,
  data: {
    action: () => {},
    title: t.UPDATE_APPLY_DIALOG_TITLE,
    onCancel: () => {},
    warning: false,
    dayOfWeek: ''
  }
};

const calendarView = storage.getCalendarView() ? storage.getCalendarView().split('-') : undefined;

const initialState: CalendarState = {
  startDate: null,
  endDate: null,
  view: isMobileApp ? CalendarView.CYCLIC : calendarView ? calendarView[0] : CalendarView.WEEK,
  cyclicDayCount: isMobileApp ? 1 : calendarView ? (calendarView.length > 1 ? calendarView[1] : 7) : 7,
  courses: [],
  activities: [],
  eventColor: colors.customEventColor,
  semesterInfo: null,
  plannedEvents: {},
  datesContainingTasks: {},
  isDragging: false,
  draggingTask: null,
  dialog: {
    opened: false,
    data: {}
  },
  importCourseDialog: {
    opened: false
  },
  slatHeight: storage.getCalendarSlatHeightConfig(),
  tasksOnDay: [],
  googleCalendars: [],
  googleEvents: [],
  eventApplyDialog: initialApplyDialogState,
  planBlockDialog: {
    opened: false,
    event: undefined,
    callback: undefined
  },
  planSidebarCollapsed: storage.getPlanSidebarCollapsed(),
  revertLastAction: 0
};

const reducer = createReducer(initialState)
  .handleAction(actions.calendarData.success, (state, action) => {
    const { plannedStudyTimeEvents } = action.payload;

    return {
      ...state,
      plannedEvents: reduceCalendarEventsToObject(plannedStudyTimeEvents)
    };
  })
  .handleAction(actions.getDatesContainingTasks.success, (state, action) => {
    const { markers, startDate, endDate } = action.payload;

    return {
      ...state,
      datesContainingTasks: markers,
      startDate,
      endDate
    };
  })
  .handleAction(actions.getPlannedTasks.success, (state, action) => {
    const { plannedStudyTimeEvents } = action.payload;

    return {
      ...state,
      plannedEvents: reduceCalendarEventsToObject(plannedStudyTimeEvents)
    };
  })
  .handleAction(actions.markAsUsed.success, (state, action) => {
    let dialog;
    // @ts-ignore
    if (!state.dialog.data || action.payload.plannedTaskDto.id !== Number(state.dialog.data.eventId)) {
      dialog = state.dialog;
    } else {
      dialog = { ...state.dialog };
      dialog.data.eventInfo.isUsed = action.payload.plannedTaskDto.isUsed;
    }

    return {
      ...state,
      dialog,
      plannedEvents: {
        ...state.plannedEvents,
        [action.payload.plannedTaskDto.id]: {
          ...state.plannedEvents[action.payload.plannedTaskDto.id],
          isUsed: action.payload.plannedTaskDto.isUsed,
          end: action.payload.plannedTaskDto.end
        }
      }
    };
  })
  .handleAction(taskActions.createTasks.success, (state, action) => {
    if (state.view !== CalendarView.MONTH) {
      return state;
    }

    const datesContainingTasks = { ...state.datesContainingTasks };
    const { title, courseCorrelationId, colorHex, tasks } = action.payload;

    tasks.forEach((task: WorkloadTask) => {
      //task without dueDate should not be added to calendar
      if (task.dueDate) {
        const due = toMomentDate(task.dueDate).format(DATE_FORMAT);
        const taskList = datesContainingTasks[due];
        const taskInfo = {
          taskId: task.taskId!,
          dueDate: task.dueDate,
          title: title || task.title,
          colorHex: colorHex || task.colorHex,
          courseCorrelationId,
          courseId: task.courseId,
          type: task.type
        };

        datesContainingTasks[due] = taskList ? [...taskList, taskInfo] : [taskInfo];
      }
    });

    return {
      ...state,
      datesContainingTasks
    };
  })
  .handleAction(actions.commitmentsData.success, (state, action) => {
    const { courses, activities } = action.payload;
    return {
      ...state,
      courses,
      activities
    };
  })
  .handleAction(courseActions.reorderCategoriesInsideCourse.success, (state, action) => {
    const { courseId, taskCategories } = action.payload;
    return {
      ...state,
      courses: state.courses.map(c => {
        if (c.id === courseId) {
          return { ...c, taskCategories };
        }
        return c;
      })
    };
  })
  .handleAction(actions.changeCalendarView, (state, action) => ({
    ...state,
    ...action.payload
  }))
  .handleAction(actions.getTasksOnDate.success, (state, action) => {
    const { tasks } = action.payload;
    return {
      ...state,
      tasksOnDay: tasks
    };
  })
  .handleAction(taskActions.setTasksPriority.success, (state, action) => {
    const { taskIds, priority } = action.payload;
    const task = state.tasksOnDay.find(t => t.taskId === taskIds[0]);
    let plannedEvents = state.plannedEvents;
    let dialog = state.dialog;
    //@ts-ignore
    const hasPlannedTasks = Object.values(plannedEvents).find(pe => pe.taskId !== taskIds[0]);
    //@ts-ignore
    const isDialogOpened = dialog.data?.eventInfo?.taskId === taskIds[0];
    const isInTaskHeader = Object.entries(state.datesContainingTasks).find(([date, tasks]) =>
      tasks.find(t => t.taskId === taskIds[0])
    );

    if (!task && !hasPlannedTasks && !isDialogOpened && !isInTaskHeader) return state;

    if (isDialogOpened) {
      dialog = {
        ...state.dialog,
        data: {
          ...state.dialog.data,
          eventInfo: {
            ...(state.dialog.data?.eventInfo as any),
            taskPriority: priority
          }
        }
      };
    }

    if (hasPlannedTasks) {
      plannedEvents = { ...state.plannedEvents };
      Object.entries(plannedEvents).forEach(([key, pe]) => {
        //@ts-ignore
        if (pe.taskId !== taskIds[0]) return;
        //@ts-ignore
        plannedEvents[key] = { ...pe, priority };
      });
    }

    return {
      ...state,
      plannedEvents,
      dialog,
      datesContainingTasks: isInTaskHeader
        ? {
            ...state.datesContainingTasks,
            [isInTaskHeader[0]]: isInTaskHeader[1].map(t => (t.taskId === taskIds[0] ? { ...t, priority } : t))
          }
        : state.datesContainingTasks,
      tasksOnDay: task ? state.tasksOnDay.map(t => (t.taskId === taskIds[0] ? { ...t, priority } : t)) : state.tasksOnDay
    };
  })
  .handleAction(actions.clearTasksOnDate, state => {
    return {
      ...state,
      tasksOnDay: []
    };
  })
  .handleAction(actions.createCourse.success, (state, action) => {
    return {
      ...state,
      courses: [...state.courses.filter(c => c.id), action.payload]
    };
  })
  .handleAction(actions.createActivity.success, (state, action) => {
    return {
      ...state,
      activities: [...state.activities.filter(c => c.id), action.payload]
    };
  })
  .handleAction(actions.updateCourse.success, (state, action) => {
    const { id, colorHex, correlationId } = action.payload;

    const updateFunc = (task: DatesContainingTasksItem | TasksByDate) =>
      task.courseId === action.payload.courseId
        ? {
            ...task,
            colorHex
          }
        : task;

    const courses = sortByOrdinalNumberAsc(
      state.courses.map(c =>
        id === c.id
          ? {
              ...c,
              ...action.payload
            }
          : c
      )
    );
    const oldColor = state.courses.find(course => course.id === id)?.colorHex;
    const plannedEvents = { ...state.plannedEvents };
    Object.entries(plannedEvents).forEach(([key, pe]) => {
      if (pe.correlationId !== correlationId) return;
      plannedEvents[key] = { ...pe, textColor: colorHex };
    });

    return {
      ...state,
      courses,
      datesContainingTasks: applyOnTasksByDate(state.datesContainingTasks, updateFunc),
      plannedEvents
    };
  })
  .handleAction(actions.updateActivity.success, (state, action) => {
    const { id } = action.payload;

    const activities = sortByOrdinalNumberAsc(
      state.activities.map(a =>
        id === a.id
          ? {
              ...a,
              ...action.payload
            }
          : a
      )
    );
    const oldColor = state.activities.find(course => course.id === id)?.colorHex;

    return {
      ...state,
      activities
    };
  })
  .handleAction(actions.deleteCourse.success, (state, action) => {
    const { id } = action.payload;

    if (id) {
      const oldColor = state.courses.find(course => course.id === id)?.colorHex;
      const courses = state.courses.filter(c => id !== c.id);
      fixOrdering(courses);

      return {
        ...state,
        courses
      };
    }

    return {
      ...state,
      courses: state.courses.filter(c => c.id)
    };
  })
  .handleAction(actions.deleteActivity.success, (state, action) => {
    const { id } = action.payload;

    if (id) {
      const oldColor = state.activities.find(course => course.id === id)?.colorHex;
      const activities = state.activities.filter(a => id !== a.id);
      fixOrdering(activities);

      return {
        ...state,
        activities
      };
    }

    return {
      ...state,
      activities: state.activities.filter(c => c.id)
    };
  })
  .handleAction(actions.addActivity, (state, action) => {
    return {
      ...state,
      activities: [
        ...state.activities,
        {
          id: 0,
          name: '',
          colorHex: getRandomPickerColor(),
          taskCategories: [],
          readingSources: []
        }
      ]
    };
  })
  .handleAction(actions.addCourse, state => {
    return {
      ...state,
      courses: [
        ...state.courses,
        {
          id: 0,
          name: '',
          colorHex: getRandomPickerColor(),
          taskCategories: [] as TaskCategoryDto[],
          readingSources: [] as ReadingSourceInfoDto[],
          parsedCourses: [] as any[]
        }
      ]
    };
  })
  .handleAction([actions.movePlannedTask.success, actions.resizePlannedTask.success], (state, action) => {
    const { newEvent } = action.payload;
    if (!state.plannedEvents[newEvent.id]) {
      return state;
    }

    return {
      ...state,
      plannedEvents: {
        ...state.plannedEvents,
        [newEvent.id]: newEvent
      }
    };
  })
  .handleAction([actions.deletePlannedTask.success], (state, action) => {
    const { event } = action.payload;
    const deletedEvent = state.plannedEvents[event.id];

    if (!deletedEvent) {
      return state;
    }

    const plannedEvents = { ...state.plannedEvents };
    delete plannedEvents[event.id.toString()];

    return {
      ...state,
      plannedEvents
    };
  })
  .handleAction(actions.changeEventDefaultColor.success, (state, action) => ({
    ...state,
    eventColor: action.payload
  }))
  .handleAction(semesterActions.getCurrent.success, (state, action) => {
    const {
      minimumStudyBlock,
      utcOffsetInSeconds,
      colorHexForStudyTime,
      colorHexForExtraTime,
      colorHexForCustomEvents,
      awakeCorrelationId,
      details
    } = action.payload;

    let calendarRange;

    if (details) {
      const { starts: semesterStarts, examPeriodEnds } = details;
      calendarRange = {
        calendarMinDate: toMomentDate(semesterStarts),
        calendarMaxDate: toMomentDate(`${examPeriodEnds} 23:59:59`)
      };
    } else {
      const date = now();
      calendarRange = {
        calendarMinDate: date,
        calendarMaxDate: date.clone()
      };
    }

    return {
      ...state,
      eventColor: colorHexForCustomEvents,
      semesterInfo: {
        awakeCorrelationId,
        minimumStudyBlock,
        utcOffsetInSeconds,
        colorHexForStudyTime,
        colorHexForExtraTime,
        ...calendarRange
      }
    };
  })
  .handleAction(actions.planTask.success, (state, action) => {
    return {
      ...state,
      plannedEvents: {
        ...state.plannedEvents,
        [`${action.payload.id}`]: action.payload
      }
    };
  })
  .handleAction(actions.duplicatePlannedTask.success, (state, action) => {
    return {
      ...state,
      plannedEvents: {
        ...state.plannedEvents,
        [`${action.payload.id}`]: action.payload
      }
    };
  })
  .handleAction(taskActions.addPlannedTask.success, (state, action) => {
    const { id, start, end, notes, taskId, courseName, textColor, title } = action.payload;
    const plannedEvent = {
      id,
      start,
      end,
      commuteAfter: 0,
      commuteBefore: 0,
      description: notes,
      taskId,
      title,
      courseName,
      textColor,
      type: CalendarEventType.PLANNED_TASK
    };
    return {
      ...state,
      plannedEvents: {
        ...state.plannedEvents,
        [`${plannedEvent.id}`]: plannedEvent
      }
    };
  })
  .handleAction(actions.startDraggingTask, (state, action) => {
    const { taskId, dueDate } = action.payload;

    return {
      ...state,
      isDragging: true,
      draggingTask: {
        taskId,
        dueDate
      }
    };
  })
  .handleAction(actions.stopDraggingTask, state => {
    return {
      ...state,
      isDragging: false,
      draggingTask: null
    };
  })
  .handleAction(actions.openCalendarDialog, (state, action) => {
    return {
      ...state,
      dialog: {
        ...state.dialog,
        opened: true,
        data: action.payload
      }
    };
  })
  //@ts-ignore
  .handleAction([taskActions.updateTaskStatus.success, taskActions.moveToTodo.success], (state, action) => {
    const taskState = action.payload.state || TaskState.ACTIVE;
    const plannedEvents =
      taskState !== TaskState.ACTIVE
        ? state.plannedEvents
        : reduceCalendarEventsToObject(
            Object.values(state.plannedEvents).map((pe: CalendarEvent) =>
              // @ts-ignore
              pe?.taskId === action.payload.taskId
                ? {
                    ...pe,
                    isTaskCompleted: false
                  }
                : pe
            ) as CalendarEvent[]
          );

    // @ts-ignore
    if (!state.dialog.data || action.payload.taskId !== state.dialog.data.eventInfo?.taskId)
      return { ...state, plannedEvents };

    return {
      ...state,
      plannedEvents,
      dialog: {
        ...state.dialog,
        data: {
          ...state.dialog.data,
          eventInfo: {
            ...state.dialog.data.eventInfo,
            taskState,
            isUsed:
              taskState === TaskState.COMPLETED &&
              action.payload.plannedTaskId &&
              action.payload.plannedTaskId === state.dialog.data.eventId
                ? true
                : //@ts-ignore
                  state.dialog.data.eventInfo?.isUsed
          }
        }
      }
    };
  })
  .handleAction(timerActions.start.success, (state, action) => {
    const { timerDto, plannedTaskDto } = action.payload;
    const pt = state.plannedEvents[timerDto.uid];
    // @ts-ignore
    if (!pt || !pt.isUsed || !state.dialog.data || pt.id != state.dialog.data.eventId) return state;

    pt.end = toMomentDate(pt.start)
      .add(plannedTaskDto.plannedMinutes, 'minutes')
      .toDate();
    return {
      ...state,
      dialog: {
        ...state.dialog,
        data: {
          ...state.dialog.data,
          eventInfo: {
            ...(state.dialog.data.eventInfo as any),
            isUsed: false
          }
        }
      }
    };
  })
  .handleAction(timerActions.cancel.success, (state, action) => {
    // @ts-ignore
    if (!state.dialog.data || action.payload.activeTimer?.plannedTaskId != state.dialog.data.eventId) return state;

    return {
      ...state,
      dialog: {
        ...state.dialog,
        data: {
          ...state.dialog.data,
          eventInfo: {
            ...(state.dialog.data.eventInfo as any),
            used: 0
          }
        }
      }
    };
  })
  .handleAction(actions.closeCalendarDialog, (state, action) => {
    return {
      ...state,
      dialog: {
        opened: false,
        data: {}
      }
    };
  })
  .handleAction(actions.showHideCalendarDialog, (state, action) => {
    return {
      ...state,
      dialog: {
        ...state.dialog,
        hidden: !action.payload
      }
    };
  })
  .handleAction(taskActions.saveManageTask.success, (state, action) => {
    const { request, response, task } = action.payload;

    const { taskId } = action.payload.response;
    const { title: newTitle } = action.payload.request;

    let plannedEvents = state.plannedEvents;

    if (newTitle) {
      plannedEvents = reduceCalendarEventsToObject(
        Object.values(state.plannedEvents).map((pe: CalendarEvent) =>
          // @ts-ignore
          pe?.taskId === taskId
            ? {
                ...pe,
                title: newTitle
              }
            : pe
        ) as CalendarEvent[]
      );
    }
    return {
      ...state,
      datesContainingTasks:
        state.view === CalendarView.MONTH || request.duration !== undefined
          ? (updateTasksByDate(state.datesContainingTasks, response.taskId, request, {
              ...task,
              ...response
            }) as DatesContainingTasks)
          : state.datesContainingTasks,
      plannedEvents
    };
  })
  .handleAction(taskActions.deleteTask.success, (state, action) => {
    const taskId = action.payload.id;

    return {
      ...state,
      datesContainingTasks: removeFromTasksByDate(state.datesContainingTasks, taskId)
    };
  })
  .handleAction(actions.updateCalendarSlatHeight, (state, action) => {
    return {
      ...state,
      slatHeight: action.payload
    };
  })
  .handleAction(actions.openPopup, (state, action) => ({
    ...state,
    closeActivePopup: action.payload
  }))
  .handleAction(actions.closePopup, state => ({
    ...state,
    closeActivePopup: undefined
  }))
  .handleAction(updateMinimumStudyTimeBlock.success, (state, action) => ({
    ...state,
    semesterInfo: {
      ...state.semesterInfo!,
      minimumStudyBlock: action.payload
    }
  }))
  .handleAction(actions.clearState, () => initialState)
  .handleAction(getSavedCalendars.success, (state, action) => ({ ...state, googleCalendars: action.payload }))
  .handleAction(toggleGoogleCalendar.success, (state, action) => ({
    ...state,
    googleCalendars: state.googleCalendars.map(c => (c.id === action.payload ? { ...c, selected: !c.selected } : c))
  }))
  .handleAction(refreshGoogleCalendars.success, (state, action) => ({ ...state, googleCalendars: action.payload }))
  .handleAction(unsyncGoogleCalendar.success, state => ({ ...state, googleCalendars: [] }))
  .handleAction(getGoogleEvents.success, (state, action) => ({
    ...state,
    googleEvents: action.payload
  }))
  .handleAction(actions.reorderPlannedTasksAgenda.failure, (state, action) => {
    const { eventId, tasks, error } = action.payload;
    const plannedEvent = state.plannedEvents[eventId];
    return {
      ...state,
      plannedEvents: {
        ...state.plannedEvents,
        [eventId]: { ...plannedEvent, plannedTasks: tasks }
      }
    };
  })
  .handleAction(actions.openEventApplyDialog, (state, action) => {
    return {
      ...state,
      eventApplyDialog: {
        open: true,
        data: action.payload
      }
    };
  })
  .handleAction(actions.closeEventApplyDialog, state => {
    return {
      ...state,
      eventApplyDialog: initialApplyDialogState
    };
  })
  .handleAction(createBatchCourses.success, (state, action) => {
    return {
      ...state,
      // @ts-ignore
      courses: action.payload.map(c => {
        const course = state.courses.find(course => c.id === course.id);
        return {
          ...c,
          readingSources: course?.readingSources || [],
          taskCategories: course?.taskCategories || []
        };
      })
    };
  })
  .handleAction(
    [createBatchActivities.success, createDefaultActivities.success, copyActivitiesFromPreviousSemester.success],
    (state, action) => {
      return {
        ...state,
        activities: action.payload
      };
    }
  )
  .handleAction(unsyncCourseFromLMS.success, (state, action) => ({
    ...state,
    courses: state.courses.map(c =>
      c.id !== action.payload.id ? c : { ...c, parsedCourses: [], googleCalendarId: undefined }
    ),
    activities: state.activities.map(c =>
      c.id !== action.payload.id ? c : { ...c, parsedCourses: [], googleCalendarId: undefined }
    ),
    googleCalendars: state.googleCalendars.map(gc => {
      if (gc.googleCalendarId === action.payload.unlinkCalendarId) {
        return { ...gc, selected: false };
      }
      return gc;
    })
  }))
  .handleAction(triggerIcsUpdates.success, (state, action) => {
    if (!action.payload?.deletedCourses || action.payload.deletedCourses.length === 0) return state;

    const deleteMap = action.paylod.deletedCourses.reduce((map, c) => {
      if (!map[c.courseId]) map[c.courseId] = [];
      map[c.courseId].push(c.parsedCourseId);
      return map;
    }, {});
    return {
      ...state,
      courses: state.courses.map(c =>
        //@ts-ignore
        !deleteMap[c.id]
          ? c
          : {
              ...c,
              //@ts-ignore
              parsedCourses: (c.parsedCourses || []).filter(pc => !deleteMap[c.id].includes(pc.id))
            }
      )
    };
  })
  .handleAction(actions.openPlanBlockDialog, (state, action) => {
    return {
      ...state,
      planBlockDialog: { ...state.planBlockDialog, opened: true, ...action.payload }
    };
  })
  .handleAction(actions.closePlanBlockDialog, (state, action) => {
    return {
      ...state,
      planBlockDialog: initialState.planBlockDialog
    };
  })
  .handleAction(actions.planTasks.success, (state, action) => {
    return {
      ...state,
      plannedEvents: {
        ...state.plannedEvents,
        ...reduceCalendarEventsToObject(action.payload)
      }
    };
  })
  .handleAction(courseActions.openImportCourseDialog, (state, action) => {
    const { courseId, courseCorrelationId, courseName, courseColorHex } = action.payload;
    return {
      ...state,
      importCourseDialog: {
        opened: true,
        courseId,
        courseCorrelationId,
        courseName,
        courseColorHex
      }
    };
  })
  .handleAction(courseActions.closeImportCourseDialog, state => {
    return {
      ...state,
      importCourseDialog: {
        opened: false
      }
    };
  })
  .handleAction(actions.reorderCourses.success, (state, action) => {
    return {
      ...state,
      courses: action.payload
    };
  })
  .handleAction(actions.reorderActivities.success, (state, action) => {
    return {
      ...state,
      activities: action.payload
    };
  })
  .handleAction(actions.togglePlanSidebar, state => {
    storage.setPlanSidebarCollapsed(!state.planSidebarCollapsed);
    return { ...state, planSidebarCollapsed: !state.planSidebarCollapsed };
  })
  .handleAction(actions.navigateFromMobile, (state, action) => {
    return {
      ...state,
      // falback to support late mobile app updates
      dateFromMobile: action.payload.date ?? action.payload,
      scrollToTime: action.payload.scrollToTime
    };
  })
  .handleAction(courseActions.createTaskCategory.success, (state, action) => ({
    ...state,
    courses: state.courses.map(c =>
      c.id === action.payload.courseId
        ? { ...c, taskCategories: [...c.taskCategories.filter(tc => tc.id), action.payload] }
        : c
    ),
    activities: state.activities.map(c =>
      c.id === action.payload.courseId
        ? { ...c, taskCategories: [...c.taskCategories.filter(tc => tc.id), action.payload] }
        : c
    )
  }))
  .handleAction(courseActions.updateTaskCategory.success, (state, action) => ({
    ...state,
    courses: state.courses.map(c =>
      c.id === action.payload.courseId
        ? { ...c, taskCategories: c.taskCategories.map(tc => (tc.id === action.payload.id ? action.payload : tc)) }
        : c
    ),
    activities: state.activities.map(c =>
      c.id === action.payload.courseId
        ? { ...c, taskCategories: c.taskCategories.map(tc => (tc.id === action.payload.id ? action.payload : tc)) }
        : c
    )
  }))
  .handleAction(courseActions.deleteTaskCategory.success, (state, action) => ({
    ...state,
    courses: state.courses.map(c =>
      c.id === action.payload.courseId
        ? //@ts-ignore
          { ...c, taskCategories: c.taskCategories.filter(rs => rs.id !== action.payload.id) }
        : c
    ),
    activities: state.activities.map(c =>
      c.id === action.payload.courseId
        ? //@ts-ignore
          { ...c, taskCategories: c.taskCategories.filter(rs => rs.id !== action.payload.id) }
        : c
    )
  }))
  .handleAction(courseActions.deleteTaskCategory.success, (state, action) => ({
    ...state,
    courses: state.courses.map(c =>
      c.id === action.payload.courseId
        ? //@ts-ignore
          { ...c, taskCategories: c.taskCategories.filter(rs => rs.id !== action.payload.id) }
        : c
    )
  }))
  .handleAction(courseActions.createReadingSource.success, (state, action) => ({
    ...state,
    courses: state.courses.map(c =>
      c.id === action.payload.courseId ? { ...c, readingSources: [...c.readingSources, action.payload] } : c
    ),
    activities: state.activities.map(c =>
      c.id === action.payload.courseId ? { ...c, readingSources: [...c.readingSources, action.payload] } : c
    )
  }))
  .handleAction(courseActions.updateReadingSource.success, (state, action) => ({
    ...state,
    courses: state.courses.map(c =>
      c.id === action.payload.courseId
        ? { ...c, readingSources: c.readingSources.map(rs => (rs.id === action.payload.id ? action.payload : rs)) }
        : c
    ),
    activities: state.activities.map(c =>
      c.id === action.payload.courseId
        ? { ...c, readingSources: c.readingSources.map(rs => (rs.id === action.payload.id ? action.payload : rs)) }
        : c
    )
  }))
  .handleAction(courseActions.deleteReadingSource.success, (state, action) => ({
    ...state,
    courses: state.courses.map(c =>
      c.id === action.payload.courseId
        ? { ...c, readingSources: c.readingSources.filter(rs => rs.id !== action.payload.id) }
        : c
    ),
    activities: state.activities.map(c =>
      c.id === action.payload.courseId
        ? { ...c, readingSources: c.readingSources.filter(rs => rs.id !== action.payload.id) }
        : c
    )
  }))
  .handleAction(
    courseActions.updateCalendarId.success,
    (state, { payload: { id, googleCalendarId, unlinkCalendarId } }) => ({
      ...state,
      courses: state.courses.mapAndReplaceById(id, c => ({ ...c, googleCalendarId })),
      activities: state.activities.mapAndReplaceById(id, c => ({ ...c, googleCalendarId })),
      googleCalendars: state.googleCalendars.map(gc => {
        if (gc.googleCalendarId === unlinkCalendarId) {
          return { ...gc, selected: false };
        }
        return gc;
      })
    })
  )
  .handleAction(actions.revertLastAction, state => ({
    ...state,
    revertLastAction: state.revertLastAction + 1
  }))
  .handleAction(actions.updatePlannedTask.success, (state, action) => {
    const {
      plannedTaskDto: { id, notes, used }
    } = action.payload.data;
    if (!state.plannedEvents[id]) return state;
    if (action.payload.mutate) {
      state.plannedEvents[id].description = notes;
      return state;
    } else {
      const dialog =
        !state.dialog.data || id != state.dialog.data.eventId
          ? state.dialog
          : {
              ...state.dialog,
              data: {
                ...state.dialog.data,
                eventInfo: {
                  ...(state.dialog.data.eventInfo as any),
                  used
                }
              }
            };

      return {
        ...state,
        dialog,
        plannedEvents: {
          ...state.plannedEvents,
          [id]: {
            ...state.plannedEvents[id],
            description: action.payload.data.notes
          }
        }
      };
    }
  })
  .handleAction(taskActions.changeCourse.success, (state, action) => {
    if (action.payload.length === 0) return state;
    const task = action.payload[0];
    const datesContainingTasksPair = Object.entries(state.datesContainingTasks).find(
      ([key, tasks]) => !!tasks.find(t => t.taskId === task.id)
    );
    //@ts-ignore
    const plannedTasks = Object.values(state.plannedEvents).filter(pe => pe.taskId === task.id);

    if (!datesContainingTasksPair && plannedTasks.length === 0) return state;

    let { datesContainingTasks, plannedEvents } = state;

    if (datesContainingTasksPair) {
      datesContainingTasks = {
        ...state.datesContainingTasks,
        [datesContainingTasksPair[0]]: datesContainingTasksPair[1].map(t =>
          t.taskId !== task.id
            ? t
            : {
                ...t,
                colorHex: task.colorHex,
                courseId: task.courseId,
                // @ts-ignore
                courseName: task.courseName
              }
        )
      };
    }

    if (plannedTasks.length > 0) {
      plannedEvents = {
        ...plannedEvents,
        ...plannedTasks.reduce(
          (acc, e) => ({
            ...acc,
            [e.id!.toString()]: {
              ...e,
              textColor: task.colorHex,
              courseName: task.courseName
            }
          }),
          {}
        )
      };
    }

    return {
      ...state,
      datesContainingTasks,
      plannedEvents
    };
  })
  .handleAction(actions.addTaskCategory, (state, action) => {
    return {
      ...state,
      courses: state.courses.map(c =>
        c.id === action.payload
          ? { ...c, taskCategories: [...c.taskCategories, { id: 0, name: '', emoji: DEFAULT_TASK_CATEGORY_EMOJI }] }
          : c
      )
    };
  });
export default reducer;
