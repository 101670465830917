import React from 'react';
import ParticleEffectButton from 'react-particle-effect-button';

import colors from '../../../../utils/colors';
import t from '../../../../i18n/t';
import { TaskState } from 'shovel-lib/types';
import Button from '../../../common/buttons/Button';
import styled from 'styled-components';
import { BUTTON_ANIMATION_PROPS_TWO, MARK_COMPLETE_BTN_DURATION } from '../../../../utils/constants/task';

type Props = {
  label?: string;
  save: (state: TaskState) => void;
};
//TODO: remove if Petr is ok with the new animation
class MarkComplete extends React.PureComponent<Props> {
  state = {
    animationActive: false,
    // in order to hide MarkComplete button after animation and before state changes to Completed in PlannedTaskDialog
    hideButton: false
  };

  handleClick = () => {
    this.setState({ animationActive: true });
    this.props.save(TaskState.COMPLETED);
  };

  render() {
    const animationProps = { ...BUTTON_ANIMATION_PROPS_TWO, duration: MARK_COMPLETE_BTN_DURATION };
    return (
      <Container data-cy={'markCompleteButton'}>
        <ParticleEffectButton
          color={colors.positive}
          hidden={this.state.animationActive}
          onComplete={() => this.setState({ hideButton: true })}
          {...animationProps}
        >
          {!this.state.hideButton && (
            <Button onClick={this.handleClick} color={colors.positive} size={'sm'}>
              {this.props.label || t.MARK_COMPLETE}
            </Button>
          )}
        </ParticleEffectButton>
      </Container>
    );
  }
}

export default MarkComplete;

// div first child is particle button
const Container = styled.div`
  margin-right: 10px;
  > div:first-child {
    padding-top: 5px;
  }
`;
