import { createReducer } from 'typesafe-actions';
import { GpaScale, SemesterGpas } from 'shovel-lib/types';
import * as actions from './actions';

const initialState: SemesterGpas = { scale: GpaScale.SCALE_4_0, semesters: [], cumulativeGpa: 0 };

const reducer = createReducer(initialState)
  .handleAction([actions.readSemesterGpas.success, actions.toggleGpaScale.success], (state, action) => {
    return action.payload;
  })
  .handleAction(actions.createSemesterGpa.success, (state, action) => {
    return {
      ...state,
      semesters: [...state.semesters, action.payload]
    };
  })
  .handleAction(actions.updateSemesterGpa.success, (state, action) => {
    const { id, semesterGpa: gpa, semesterCredit: credit, cumulativeGpa, name } = action.payload;
    return {
      ...state,
      cumulativeGpa,
      semesters: state.semesters.map(semester => (semester.id === id ? { ...semester, gpa, credit, name } : semester))
    };
  })
  .handleAction(actions.deleteSemesterGpa.success, (state, action) => {
    const { id, cumulativeGpa } = action.payload;

    return {
      ...state,
      cumulativeGpa,
      semesters: state.semesters.filter(semester => semester.id !== id)
    };
  })
  .handleAction(actions.createCourseGpa.success, (state, action) => {
    const { cumulativeGpa, ...courseGpa } = action.payload;
    const newGrade = { ...courseGpa, grade: courseGpa.grade || null };
    return {
      ...state,
      cumulativeGpa,
      semesters: state.semesters.map(semester =>
        semester.id === courseGpa.semesterId
          ? { ...semester, courses: semester.courses ? [...semester.courses, newGrade] : [newGrade] }
          : semester
      )
    };
  })
  .handleAction(actions.updateCourseGpa.success, (state, action) => {
    const { cumulativeGpa, semesterGpa: gpa, semesterCredit: credit, ...courseGpa } = action.payload;
    return {
      ...state,
      cumulativeGpa,
      semesters: state.semesters.map(semester =>
        semester.id === courseGpa.semesterId
          ? {
              ...semester,
              gpa,
              credit,
              courses: semester.courses.map(course => (course.id === courseGpa.id ? courseGpa : course))
            }
          : semester
      )
    };
  })
  .handleAction(actions.deleteCourseGpa.success, (state, action) => {
    const { cumulativeGpa, semesterGpa: gpa, semesterCredit: credit, semesterId, id } = action.payload;
    return {
      ...state,
      cumulativeGpa,
      semesters: state.semesters.map(semester =>
        semester.id === semesterId
          ? { ...semester, gpa, credit, courses: semester.courses.filter(course => course.id !== id) }
          : semester
      )
    };
  });

export default reducer;
