import React from 'react';
import styled, { withTheme } from 'styled-components';
import colors from '../../../utils/colors';
import { RegularText } from '../../../utils/typography';
import MaterialIcon from '../icons/MaterialIcon';
import { Row } from '../layoutUtils';

type Props = {
  label?: string;
  checked?: boolean;
  onChange?: (clickData?: any) => void;
  clickData?: any;
  disabled?: boolean;
  className?: string;
  theme: any;
  fontSize?: any;
  color?: string;
};

const RadioButton = (props: Props) => {
  const { label, checked, onChange, clickData, disabled, className, theme, fontSize, color } = props;

  return (
    <Container
      className={className}
      onClick={() => {
        if (!disabled) {
          !checked && onChange && onChange(clickData);
        }
      }}
      disabled={disabled}
    >
      <MaterialIcon
        size={22}
        name={checked ? 'radio_button_checked' : 'radio_button_unchecked'}
        color={disabled ? theme.captures : checked ? colors.primaryPurple : color || theme.textColor}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      />
      {label && (
        <Label color={disabled ? theme.captures : undefined} strong={checked} fontSize={fontSize}>
          {label}
        </Label>
      )}
    </Container>
  );
};

const Label = styled(RegularText)<{ fontSize?: number }>`
  margin-left: 6px;
  transition: all 0.2s ease;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${props =>
    props.fontSize &&
    `font-size: ${props.fontSize}px;
     font-weight: ${props.fontSize === 13 ? '600' : 'bold'}
    `};
`;

const Container = styled(Row)<{ disabled?: boolean }>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  width: fit-content;
  width: -moz-fit-content;
  transition: all 0.2s ease;
  &:hover {
    ${props =>
      !props.disabled &&
      `${Label} {
        opacity: 0.75;
      }
    `}
`;

export default withTheme(RadioButton);
