import {
  ApplyDialogData,
  CalendarDialogData,
  ChangeCalendarViewActionType,
  GetTasksForPlanningRequest,
  GetTasksForPlanningResponse,
  StartDraggingTaskActionType
} from './types';
import { actionCreator, asyncActionsCreator } from '../common/utils';
import {
  CalendarEvent,
  CalendarEventsResponse,
  CommitmentsDataResponse,
  CreateOrUpdateActivityRequest,
  CreateOrUpdateCourseRequest,
  DatesContainingTasksResponse,
  DeleteActivityResponse,
  DeleteCourseResponse,
  DeletePlannedTaskResponse,
  MarkAsUsedRequest,
  MovePlannedTaskRequest,
  PlannedTask,
  PlanTaskRequest,
  PlanTasksRequest,
  ReadingSourceInfoDto,
  ResizePlannedTaskRequest,
  TaskInfoDto,
  ThinActivityDto,
  ThinCourseDto,
  UpdatePlannedTaskRequest,
  UpdatePlannedTaskResponse
} from 'shovel-lib/types';
import { Moment } from 'moment';

const asyncActions = asyncActionsCreator('@@calendar');
const action = actionCreator('@@calendar');

export const calendarData = asyncActions<undefined, CalendarEventsResponse, string>('CALENDAR_DATA');

export const getDatesContainingTasks = asyncActions<
  { startDate: Moment | Date | string; endDate: Moment | Date | string },
  DatesContainingTasksResponse,
  string
>('DATES_CONTAINING_TASKS');

export const getPlannedTasks = asyncActions<undefined, CalendarEventsResponse, string>('PLANNED_TASKS');

export const commitmentsData = asyncActions<void, CommitmentsDataResponse, string>('COMMITMENTS_DATA');

export const changeCalendarView = action<ChangeCalendarViewActionType>('CHANGE_CALENDAR_VIEW');

export const resizePlannedTask = asyncActions<
  ResizePlannedTaskRequest & { id: number; callback?: any; revert?: any },
  { oldEvent: CalendarEvent; newEvent: CalendarEvent },
  string
>('RESIZE_PLANNED_TASK');

export const movePlannedTask = asyncActions<
  MovePlannedTaskRequest & { id: number; callback?: any },
  { oldEvent: CalendarEvent; newEvent: CalendarEvent },
  string
>('MOVE_PLANNED_TASK');

export const deletePlannedTask = asyncActions<number, { event: CalendarEvent; data: DeletePlannedTaskResponse }, string>(
  'DELETE_PLANNED_TASK'
);

export const getTasksOnDate = asyncActions<string, { tasks: TaskInfoDto[]; date: string }, string>('GET_TASKS_ON_DATE');

export const clearTasksOnDate = action<undefined>('CLEAR_TASKS_ON_DATE');

export const addCourse = action<undefined>('ADD_COURSE');

export const addActivity = action<undefined>('ADD_ACTIVITY');

export const changeEventDefaultColor = asyncActions<string, string, string>('CHANGE_EVENT_DEFAULT_COLOR');

export const createCourse = asyncActions<CreateOrUpdateCourseRequest & { isTaskList?: boolean }, ThinCourseDto, string>(
  'CREATE_COURSE'
);

export const updateCourse = asyncActions<ThinCourseDto, ThinCourseDto, string>('UPDATE_COURSE');

export const deleteCourse = asyncActions<number, (DeleteCourseResponse & { refreshTaskList: boolean }) | {}, string>(
  'DELETE_COURSE'
);

export const createActivity = asyncActions<
  CreateOrUpdateActivityRequest & { isTaskList?: boolean },
  ThinActivityDto,
  string
>('CREATE_ACTIVITY');

export const updateActivity = asyncActions<ThinActivityDto, ThinActivityDto, string>('UPDATE_ACTIVITY');

export const deleteActivity = asyncActions<number, DeleteActivityResponse | {}, string>('DELETE_ACTIVITY');

export const planTask = asyncActions<{ request: PlanTaskRequest; infoRef?: any }, CalendarEvent, string>('PLAN_TASK');

export const startDraggingTask = action<StartDraggingTaskActionType>('START_DRAGGING_TASK');

export const stopDraggingTask = action<undefined>('STOP_DRAGGING_TASK');

export const openCalendarDialog = action<CalendarDialogData>('OPEN_CALENDAR_DIALOG');

export const closeCalendarDialog = action<undefined>('CLOSE_CALENDAR_DIALOG');

export const showHideCalendarDialog = action<boolean>('SHOW_HIDE_CALENDAR_DIALOG');

export const updateCalendarSlatHeight = action<number>('UPDATE_CALENDAR_SLAT_HEIGHT');

export const openPopup = action<Function>('OPEN_POPUP');

export const closePopup = action<undefined>('CLOSE_POPUP');

export const clearState = action<undefined>('CLEAR_STATE');

export const toggleSidebar = action<undefined>('TOGGLE_SIDEBAR');

export const reorderPlannedTasksAgenda = asyncActions<
  { ordinalNumber: number; id: number; eventId: number; currentTasksOrder: any },
  { eventId: number; tasks: PlannedTask[] },
  { eventId: number; tasks: PlannedTask[]; error: string }
>('REORDER_PLANNED_TASKS_AGENDA');

export const startAgendaListener = action<undefined>('START_AGENDA_LISTENER');

export const stopAgendaListener = action<undefined>('STOP_AGENDA_LISTENER');

export const openEventApplyDialog = action<ApplyDialogData>('OPEN_EVENT_APPLY_DIALOG');

export const closeEventApplyDialog = action<undefined>('CLOSE_EVENT_APPLY_DIALOG');

export const openPlanBlockDialog = action<{ event: CalendarEvent; callback?: any }>('OPEN_PLAN_BLOCK_DIALOG');

export const closePlanBlockDialog = action<undefined>('CLOSE_PLAN_BLOCK_DIALOG');

export const getTasksForPlanning = asyncActions<GetTasksForPlanningRequest, GetTasksForPlanningResponse, string>(
  'GET_TASKS_FOR_PLANNING'
);

export const planTasks = asyncActions<PlanTasksRequest, CalendarEvent[], string>('PLAN_TASKS');

export const resetNavigation = action<undefined>('RESET_NAVIGATION');

export const reorderCourses = asyncActions<ThinCourseDto[], ThinCourseDto[], string>('REORDER_COURSES');

export const reorderActivities = asyncActions<ThinActivityDto[], ThinActivityDto[], string>('REORDER_ACTIVITIES');

export const togglePlanSidebar = action<undefined>('TOGGLE_PLAN_SIDEBAR');

export const navigateFromMobile = action<Date>('NAVIGATE_FROM_MOBILE');

export const revertLastAction = action<undefined>('REVERT_LAST_ACTION');

export const updatePlannedTask = asyncActions<
  { id: number; request: UpdatePlannedTaskRequest; mutate?: boolean },
  { data: UpdatePlannedTaskResponse; mutate?: boolean },
  string
>('UPDATE_PLANNED_TASK');

export const openPlannedTaskDialog = action<any | undefined>('OPEN_PLANNED_TASK_DAILOG');

export const markAsUsed = asyncActions<
  { plannedTaskId: number; data: MarkAsUsedRequest; reading?: ReadingSourceInfoDto; courseId?: number },
  UpdatePlannedTaskResponse,
  string
>('MARK_AS_USED');

export const stopTimerForPlannedTask = asyncActions<number, UpdatePlannedTaskResponse, string>(
  'STOP_TIMER_FOR_PLANNED_TASK'
);

export const duplicatePlannedTask = asyncActions<number, CalendarEvent & { notes: string }, string>(
  'DUPLICATE_PLANNED_TASK'
);

export const addTaskCategory = action<number>('ADD_TASK_CATEGORY');
