import React, { FC, PureComponent, useState } from 'react';
import Popup from 'reactjs-popup';
import styled, { withTheme } from 'styled-components';
import colors from '@utils/colors';
import { commonPopupCSS, DropDownArrow, dropDownInputStyle, getTextComponent } from '@utils/popupUtils';
import MaterialIcon from '../../icons/MaterialIcon';
import { RegularText, SmallText } from '@utils/typography';
import { Column, FlexRow, Row } from '../../layoutUtils';
import t from '@i18n/t';
import TextInput from '../text/TextInput';
import { Error } from '../Input.styles';
import EditMenu from '../../EditMenu';
import TippyTooltip from '../../tooltip/TippyTooltip';
import { RootState } from '@state/rootReducer';
import { extractActionNameWithoutSuffix } from '@state/common/utils';
import { getActionLoaders } from '@state/common/selectors';
import { connect } from 'react-redux';
import { EmojiPreview } from '@components/common/emoji/EmojiPreview';

type Option = {
  label: string;
  value: any;
  isDefaultValue?: boolean;
  color?: string;
  icon?: any;
  tooltip?: string;
  onHover?: any;
  // in order to have different color for label extra content
  labelExtraContent?: any;
};

type OptionCategory = { label: string; value: any; options: Option[] };

type OwnProps = {
  className?: string;
  initialValue?: any;
  disabled?: boolean;
  options: Array<Option>;
  onChange: Function;
  label?: string;
  remove?: any;
  add?: any;
  fontSize?: number;
  placeholder?: any;
  addPlaceholder?: string;
  addData?: any;
  error?: string;
  touched?: string;
  tooltip?: string;
  editOptions?: any;
  edit?: any;
  editIndex?: any;
  editTooltip?: string;
  renderPopup?: any;
  onClose?: any;
  addAction?: any;
  customTarget?: React.ReactElement;
  small?: boolean;
  dropdownPosition?: any;
} & ({ categorized?: false; options: Array<Option> } | { categorized: true; options: Array<OptionCategory> });

type PropsFromState = {
  loading?: boolean;
  theme: any;
};

type Props = OwnProps & PropsFromState;

type State = {
  showAddInput: boolean;
  name: string;
  editItem: any;
};

class DropDownInputEditable extends PureComponent<Props, State> {
  state: State = {
    showAddInput: false,
    name: '',
    editItem: null
  };

  getTextColor = (initialOption?: Option) => {
    const { theme, disabled } = this.props;

    if (disabled) return theme.captures;

    if (!initialOption) return theme.primary;

    return initialOption.color || theme.textStrongColor;
  };

  hideAddInput = () => this.setState({ showAddInput: false });

  setEditItem = (editItem: any) => this.setState({ editItem });

  onEnter = (e: any) => {
    if (e.key === 'Enter') {
      this.onSave();
    }
  };

  onSave = () => {
    if (this.state.editItem) {
      this.props.edit(this.state.name, this.state.editItem, this.props.addData);
    } else {
      this.props.add(this.state.name, this.props.addData, this.props.onChange);
    }
    this.setState({ name: '', editItem: null, showAddInput: false });
  };

  addItem = () => this.setState({ showAddInput: true });

  renderEditItem = item => {
    return (
      <AddItemInput
        name={'name'}
        value={item.label}
        inline
        bare
        autofocus
        onChange={(e: any) => this.setState({ name: e.target.value })}
        onKeyUp={e => this.onEnter(e)}
      />
    );
  };

  renderAddItem = () => {
    const { addPlaceholder, options } = this.props;
    return (
      <>
        {this.state.showAddInput ? (
          <AddItemInput
            name={'name'}
            value={this.state.name}
            inline
            bare
            autofocus
            onChange={(e: any) => this.setState({ name: e.target.value })}
            onKeyUp={e => this.onEnter(e)}
          />
        ) : (
          <AddInput onClick={this.addItem} disabled={false} isEmpty={options.length === 0}>
            <RegularText>{`+ ${addPlaceholder}`}</RegularText>
          </AddInput>
        )}
      </>
    );
  };

  handleClose = () => {
    if (this.state.name) {
      this.onSave();
    }
    this.setState({ name: '', editItem: null, showAddInput: false });
    this.props.onClose && this.props.onClose();
  };

  render() {
    const {
      disabled,
      theme,
      initialValue,
      options,
      fontSize = 13,
      label,
      placeholder,
      add,
      error,
      touched,
      remove,
      tooltip,
      className,
      customTarget,
      small,
      dropdownPosition = 'bottom left',
      categorized
    } = this.props;

    const TextComponent = getTextComponent(fontSize);

    let initialOption;
    if (categorized) {
      for (const category of options as OptionCategory[]) {
        initialOption = category.options.find(o => o.value === initialValue);
        if (initialOption) {
          break;
        }
      }
    } else {
      initialOption = options.find(o => o.value === initialValue);
    }

    return (
      <Container className={className}>
        <Popup
          trigger={open => {
            const iconColor = disabled ? theme.captures : open ? colors.primaryPurple : theme.textStrongColor;
            const textColor = this.getTextColor(initialOption);
            const target = (
              <MenuWrapper small={small}>
                {customTarget ? (
                  customTarget
                ) : (
                  <>
                    {label && (
                      <TextComponent
                        style={{ color: theme.textColor, marginRight: 5, flexShrink: 0 }}
                      >{`${label}:`}</TextComponent>
                    )}
                    <SelectedValue
                      disabled={disabled}
                      isPlaceholder={!initialOption}
                      fontWeight={fontSize <= 13 ? 'bold' : '600'}
                      color={textColor}
                    >
                      <TextComponent color={textColor} className={'label-wrapper'}>
                        {initialOption?.icon && (
                          <span>
                            <EmojiPreview emoji={initialOption.icon} />
                          </span>
                        )}
                        {initialOption?.label || placeholder}
                        {initialOption?.labelExtraContent && (
                          <span
                            style={{ color: theme.textColor, whiteSpace: 'pre' }}
                          >{` (${initialOption.labelExtraContent})`}</span>
                        )}
                      </TextComponent>
                      {!disabled && (
                        <ClearIcon
                          name={'cancel'}
                          size={16}
                          onClick={e => {
                            if (remove) {
                              remove();
                            }
                            e.stopPropagation();
                          }}
                        />
                      )}
                    </SelectedValue>
                    <DropDownArrow name={'keyboard_arrow_down'} size={fontSize + 3} open={open} color={iconColor} />
                    {error && touched && <Error inline>{error}</Error>}
                  </>
                )}
              </MenuWrapper>
            );
            return tooltip ? <TippyTooltip target={target} content={tooltip} /> : target;
          }}
          position={dropdownPosition}
          on="click"
          // z-index is important for task list and sticky course names
          contentStyle={{ ...dropDownInputStyle, zIndex: 4 }}
          overlayStyle={{ zIndex: 4 }}
          arrowStyle={{ display: 'none' }}
          disabled={disabled}
          onClose={this.handleClose}
        >
          {close => (
            <OptionsWrapper data-cy={'options'}>
              <div>
                {categorized
                  ? this.renderCategorizedOptions(close, options as Array<OptionCategory>, initialOption)
                  : this.renderOptions(close, options as Array<Option>, initialOption)}
              </div>
              {add && this.renderAddItem()}
            </OptionsWrapper>
          )}
        </Popup>
      </Container>
    );
  }

  renderOptions = (close: Function, options: Array<Option>, initialOption?: Option) => {
    const { onChange, renderPopup, editIndex, editTooltip, editOptions, edit, addData } = this.props;

    return options.map((item, index) => (
      <DropDownItem
        key={index}
        item={item}
        close={close}
        handleClick={onChange}
        renderPopup={renderPopup}
        editOptions={editOptions}
        editTooltip={editTooltip}
        editIndex={editIndex}
        edit={edit}
        selectedOption={initialOption}
        hideAddInput={this.hideAddInput}
        renderEditItem={this.renderEditItem}
        setEditItem={this.setEditItem}
        addData={addData}
      />
    ));
  };

  renderCategorizedOptions = (close: Function, options: Array<OptionCategory>, initialOption?: Option) => {
    const { onChange, renderPopup, editIndex, editTooltip, editOptions, edit, addData } = this.props;

    return options.map(category => (
      <Category key={category.label}>
        {category.label && <SmallText>{category.label}</SmallText>}
        {category.options.map(item => (
          <DropDownItem
            key={item.value}
            item={item}
            close={close}
            handleClick={onChange}
            renderPopup={renderPopup}
            editOptions={editOptions}
            editTooltip={editTooltip}
            editIndex={editIndex}
            edit={edit}
            selectedOption={initialOption}
            hideAddInput={this.hideAddInput}
            renderEditItem={this.renderEditItem}
            setEditItem={this.setEditItem}
            addData={addData}
          />
        ))}
      </Category>
    ));
  };
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
  const actionName = props.addAction && extractActionNameWithoutSuffix(props.addAction.request);
  const loaders = getActionLoaders(state);
  return {
    loading: loaders[actionName]
  };
};
export default connect(mapStateToProps, null)(withTheme(DropDownInputEditable));

type DDProps = {
  item: Option;
  close: any;
  handleClick: any;
  selectedOption: any;
  editIndex: number;
  editTooltip?: string;
  editOptions: any;
  renderPopup: any;
  hideAddInput: any;
  renderEditItem: any;
  edit: any;
  addData: any;
  setEditItem: any;
};

const DropDownItem: FC<DDProps> = ({
  item,
  close,
  handleClick,
  renderPopup,
  editOptions,
  editTooltip,
  editIndex,
  selectedOption,
  hideAddInput,
  renderEditItem,
  edit,
  addData,
  setEditItem
}) => {
  const [emoji, setEmoji] = useState(item.icon);
  const isSelected = selectedOption === item;

  if (item.value === editIndex) {
    setEditItem(item);
    return renderEditItem(item);
  }
  const dropdownEl = (
    <Item
      key={item.value}
      onClick={() => {
        if (item.icon && item.icon !== emoji) {
          edit(emoji, item, addData, true);
        }
        handleClick(item.value, item);
        hideAddInput();
        close();
      }}
    >
      <FlexRow>
        {item.icon && <EmojiPreview emoji={emoji || item.icon} />}
        <Label color={item.color}>
          {item.label}
          {item.labelExtraContent && <span>{` (${item.labelExtraContent})`}</span>}
        </Label>
        {item.isDefaultValue && <DefaultOption>{` (${t.DEFAULT})`}</DefaultOption>}
      </FlexRow>
      <>
        {isSelected && <MaterialIcon name={'done'} size={16} color={colors.primaryPurple} />}
        {editOptions && <EditMenu options={editOptions(item)} tooltip={editTooltip} />}
      </>
    </Item>
  );
  return renderPopup ? renderPopup(dropdownEl, item, setEmoji, emoji) : dropdownEl;
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  .popup-content {
    ${commonPopupCSS}
  }
`;

export const MenuWrapper = styled.div<{ small?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${props => (props.small ? 0 : 11)}px;
  box-sizing: border-box;
  position: relative;
`;

const ClearIcon = styled(MaterialIcon)`
  visibility: hidden;
  &:hover {
    color: ${colors.negative};
  }
  position: absolute;
  right: 0;
`;

export const SelectedValue = styled.div<{ isPlaceholder?: boolean; fontWeight: any; color: any; disabled?: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  min-width: 60px;
  > span {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: ${props => props.fontWeight};
    .emoji-mart-emoji {
      margin-right: 3px;
      display: flex;
    }
  }

  ${props =>
    !props.isPlaceholder && !props.disabled
      ? `&:hover {
        ${ClearIcon} {
          visibility: visible;
        }
        > span {
          width: calc(100% - 18px);
          overflow: hidden;
          white-space: nowrap;
          font-weight: bold;
        }
    }`
      : `&:hover {
        > span {
          color: ${props.color}BF;
        }
      }`}
  .label-wrapper {
    display: flex;
    align-items: center;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 8px 10px;
  cursor: pointer;
  .edit-menu {
    opacity: 1;
  }
   .emoji-mart-emoji {
      margin-right: 3px;
   }

  // &:hover .edit-menu {
  //   opacity: 1;
  // }

  &:hover {
    background-color: ${props => props.theme.backgroundDark};
    // color: ${props => (props.color ? `${props.color}BF` : props.theme.textStrongColor)};
  }
`;

const Label = styled(RegularText)<{ color?: string }>`
  color: ${props => props.color || props.theme.textStrongColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  > span {
    color: ${props => props.theme.textColor};
  }
`;

const DefaultOption = styled(SmallText)`
  white-space: pre;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > div:first-child {
    max-height: 350px;
    overflow: auto;
  }
`;

const AddInput = styled(Item)<{ disabled?: boolean; isEmpty?: boolean }>`
  ${props => !props.isEmpty && `border-top: 1px solid ${props.theme.borderColor}`};
  box-sizing: border-box;
  &:hover {
    background-color: ${props => props.theme.background};
    > span {
      color: ${props => props.theme.primary};
    }
  }
`;

const AddItemInput = styled(TextInput)`
  margin: 1px;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.primary};
  ${({ theme }) => `box-shadow: 0 0 2px ${theme.boxShadow}, 0 2px 2px ${theme.boxShadow}`};
  height: 34px;
  width: calc(100% - 2px);
  > input {
    padding: 8px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: ${props => props.theme.backgroundDark};
    -webkit-box-shadow: none !important;
  }
`;

// const IconWrapper = styled(Row)`
//   //font size of label * line height
//   height: 19px;
//   margin-right: 5px;
// `;

const Category = styled(Column)`
  flex-shrink: 0;
  ${SmallText} {
    padding: 5px 10px;
    text-align: left;
    text-transform: uppercase;
  }
  ${Item} {
    padding: 5px 10px 5px 24px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }
`;
