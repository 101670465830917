import React, { FC, SyntheticEvent, useEffect, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { toMomentDate, toSemesterLocalDateTime } from 'shovel-lib/utils/timeUtils';
import { DatepickerFormats } from 'shovel-lib/types';
import { Portal } from 'react-overlays';
import styled from 'styled-components';
import DatePickerCustomHeader from './DatePickerCustomHeader';
import { RegularText } from '@utils/typography';
import { toDateInRequest } from '@utils/calendar/calendarUtils';
import { ValueSizeWrapper } from './DatetimePicker';
import { isMobileApp } from '@utils/screenUtils';
import { Row } from '../../layoutUtils';

type Props = {
  name?: string;
  value?: Date | string | null;
  onChange: (value: string, event: SyntheticEvent) => void;
  onClose?: () => void;
  onOpen?: () => void;
  isOpen?: boolean;
  minDate?: Date | string;
  maxDate?: Date | string;
  required?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  inlineInput?: boolean;
  customDayRender?: any;
  dateFormatFn?: (showYear?: boolean) => string;
  autosizeFormat?: (showYear?: boolean) => string;
  footer?: any;
  popperContainerId?: string;
  clearable?: boolean;
  popperPlacement?: string;
  inputComponent?: any;
  closeOnSelect?: boolean;
  highlightDates?: any[];
  inline?: boolean;
  openToDate?: Date;
};

const DatePicker: FC<Props> = ({
  name,
  value,
  onBlur,
  onChange,
  onClose,
  onOpen,
  isOpen,
  minDate,
  maxDate,
  className,
  required = false,
  disabled = false,
  inlineInput,
  inline,
  dateFormatFn,
  autosizeFormat,
  footer,
  popperPlacement,
  customDayRender,
  popperContainerId = 'root',
  clearable = false,
  inputComponent,
  highlightDates,
  closeOnSelect = true,
  openToDate
}) => {
  const pickerRef = useRef<any>(null);
  useEffect(() => {
    // exclude typing in mobile app on inputs
    if (isMobileApp && pickerRef?.current?.input) {
      pickerRef.current.input.readOnly = true;
    }
  }, [pickerRef]);

  const handleChange = (date: Date, event: SyntheticEvent) => {
    if (!date) return;

    const utcMidnight = toMomentDate(date);
    utcMidnight.subtract(date.getTimezoneOffset(), 'minutes');

    const localMidnight = utcMidnight.toISOString().slice(0, -1);
    onChange(toDateInRequest(localMidnight), event);
  };

  const handleDateChangeRaw = e => {
    e.preventDefault();
  };

  const onKeyDown = e => {
    if (e.key === 'Tab' && pickerRef.current) {
      pickerRef.current.setOpen(false);
    }
  };

  const selected = value ? toSemesterLocalDateTime(value) : undefined;
  const showYear = !!selected && new Date().getFullYear() !== selected.getFullYear();
  const format = dateFormatFn ? dateFormatFn(showYear) : undefined;

  const picker = (
    <Picker
      customInput={inputComponent}
      ref={pickerRef}
      autoComplete={'off'}
      locale={'custom'}
      isClearable={clearable}
      inline={inline}
      inlineInput={inlineInput}
      name={name}
      openToDate={openToDate}
      highlightDates={highlightDates}
      selected={selected}
      shouldCloseOnSelect={closeOnSelect}
      onChange={handleChange}
      onChangeRaw={handleDateChangeRaw}
      dateFormat={format || DatepickerFormats.MEDIUM}
      minDate={minDate ? toSemesterLocalDateTime(minDate) : undefined}
      maxDate={maxDate ? toSemesterLocalDateTime(maxDate) : undefined}
      required={required}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      renderDayContents={customDayRender}
      popperPlacement={popperPlacement}
      onCalendarClose={onClose}
      onCalendarOpen={onOpen}
      onInputClick={onOpen}
      open={isOpen}
      className={className}
      calendarClassName={className}
      formatWeekDay={date => date.substring(0, 1)}
      popperClassName={'date-picker'}
      disabled={disabled}
      renderCustomHeader={({ date, prevMonthButtonDisabled, decreaseMonth, nextMonthButtonDisabled, increaseMonth }) => (
        <DatePickerCustomHeader
          date={date}
          prevMonthButtonDisabled={prevMonthButtonDisabled}
          decreaseMonth={decreaseMonth}
          nextMonthButtonDisabled={nextMonthButtonDisabled}
          increaseMonth={increaseMonth}
        />
      )}
      popperContainer={props => <Portal container={document.getElementById(popperContainerId)}>{props.children}</Portal>}
      disabledKeyboardNavigation
    >
      {footer}
    </Picker>
  );

  return (
    <Wrapper>
      {!autosizeFormat ? (
        picker
      ) : (
        <ValueSizeWrapper>
          <RegularText className={'size-placeholder'}>
            {value ? toMomentDate(value).format(autosizeFormat(showYear)) : '&nbsp;'}
          </RegularText>
          {picker}
        </ValueSizeWrapper>
      )}
    </Wrapper>
  );
};
//TODO: see how to change today in picker
export default DatePicker;

const Wrapper = styled(RegularText)`
  display: block;
  width: 100%;
  > div {
    width: 100%;
  }
`;

const Picker = styled(ReactDatePicker)<{ inlineInput?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;

  &:disabled {
    color: ${props => props.theme.disabledTextColor} !important;
    -webkit-text-fill-color: ${props => props.theme.disabledTextColor};
  }
`;

export const FooterRow = styled(Row)`
  width: 100%;
  max-width: 330px;
  padding: 15px 10px;
  border-top: 1px solid ${props => props.theme.borderColor};
  flex-wrap: wrap;
  box-sizing: border-box;
  > * {
    margin-right: 10px;
  }
`;
