import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import * as actions from './actions';
import * as taskActions from '../task/actions';
import { courseApi } from '../../api';
import { callApi } from '../common/operations';
import { getErrorMessage } from '../../api/network';
import { semesterSelectors } from '../semester';
import { createEvent, deleteEvent, updateEvent } from '../events/actions';
import { calendarActions } from '../calendar';
import { ApplyFilter, PileStateOptions } from './types';
import { TODO_TASK_PER_PAGE } from '../../utils/constants/task';
import { taskApi } from 'shovel-lib';
import { courseActions } from '../course';
import * as pileSelectors from './selectors';
import { googleCalendarActions } from '../googleCalendar';
import { icsActions } from '../ics';
import { termActions } from '../terms';
import { getCompletedFilterDateRange, getTodoFilterDateRange } from '@components/pile/components/utils';
import storage from '@utils/storage';

function* getCourses() {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(courseApi.getAllNames, semesterId);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getCourses.success(response.data));
  } else {
    yield put(actions.getCourses.failure(getErrorMessage(response)));
  }
}

function* applyFilter({ payload }: { payload: ApplyFilter }) {
  const { activeTab, filter: filterValues, search, isRefresh, sort } = payload;
  storage.savePileFilter(filterValues);
  storage.savePileSort(sort);
  //@ts-ignore
  const { last30Days, lastWeek, thisWeek, next7Days, nextWeek, ...filter } = filterValues;
  const { from, to } =
    activeTab === PileStateOptions.COMPLETED
      ? getCompletedFilterDateRange({ last30Days, lastWeek, thisWeek })
      : getTodoFilterDateRange({ thisWeek, next7Days, nextWeek });

  const sortValues = activeTab === PileStateOptions.COMPLETED ? sort.completed : sort.todo;
  const pageProps = { page: 0, size: TODO_TASK_PER_PAGE };
  const request = {
    filter: { ...filter, from, to, ...sortValues },
    search
  };
  if (isRefresh) {
    const toDoPile = yield select(pileSelectors.getAllToDoState);
    pageProps.size = Math.max(toDoPile.loadedElements, TODO_TASK_PER_PAGE);
  }
  switch (activeTab) {
    case PileStateOptions.UPCOMING:
      yield put(actions.getWithinStartDateTasks.request(request));
      yield put(actions.getOverdueTasks.request(request));
      yield put(actions.getNoDueDateTasks.request(request));
      yield put(actions.getRecentlyCreated.request());

      break;
    case PileStateOptions.ACTIVE:
      yield put(actions.getAllToDoTasks.request({ ...request, ...pageProps, isRefresh }));
      yield put(actions.getOverdueTasks.request(request));
      yield put(actions.getNoDueDateTasks.request(request));
      yield put(actions.getRecentlyCreated.request());

      break;
    case PileStateOptions.COMPLETED:
      yield put(actions.getCompletedTasks.request(request));
      break;
  }
}

function* getAllToDoTasks({ payload }: ReturnType<typeof actions.getAllToDoTasks.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { filter, page, size, search, isRefresh } = payload;
  const apiCall = call(taskApi.allToDoTasks, semesterId, filter, page, size, search);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getAllToDoTasks.success({ ...response.data, page, isRefresh }));
  } else {
    yield put(actions.getAllToDoTasks.failure(getErrorMessage(response)));
  }
}

function* getWithinStartDateTasks(action: ReturnType<typeof actions.getWithinStartDateTasks.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { filter, search } = action.payload;
  const apiCall = call(taskApi.withinStartDateTasks, semesterId, filter, search);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getWithinStartDateTasks.success(response.data));
  } else {
    yield put(actions.getWithinStartDateTasks.failure(getErrorMessage(response)));
  }
}

function* getOverdueTasks(action: ReturnType<typeof actions.getOverdueTasks.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { filter, search } = action.payload;
  const apiCall = call(taskApi.overdueTasks, semesterId, filter, search);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getOverdueTasks.success(response.data));
  } else {
    yield put(actions.getOverdueTasks.failure(getErrorMessage(response)));
  }
}

function* getCompletedTasks(action: ReturnType<typeof actions.getCompletedTasks.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { filter, search } = action.payload;
  const apiCall = call(taskApi.allCompletedTasks, semesterId, filter, search);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getCompletedTasks.success(response.data));
  } else {
    yield put(actions.getCompletedTasks.failure(getErrorMessage(response)));
  }
}

function* getNoDueDateTasks(action: ReturnType<typeof actions.getNoDueDateTasks.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const { filter, search } = action.payload;
  const apiCall = call(taskApi.getNoDueDateTasks, semesterId, filter, search);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getNoDueDateTasks.success(response.data));
  } else {
    yield put(actions.getNoDueDateTasks.failure(getErrorMessage(response)));
  }
}

function* calculateCushion() {
  const semesterId = yield select(semesterSelectors.getId);
  const tasks = yield select(pileSelectors.getDisplayedActiveTasks);
  // todo: check are these always sorted by date
  if (tasks.length === 0) {
    return yield put(actions.calculateCushion.success({}));
  }
  const until = tasks[tasks.length - 1].due.toDate();
  const apiCall = call(taskApi.calculateCushion, semesterId, until);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.calculateCushion.success(response.data));
  } else {
    yield put(actions.calculateCushion.failure(getErrorMessage(response)));
  }
}

function* getRecentlyCreated(action: ReturnType<typeof actions.getRecentlyCreated.request>) {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(taskApi.recentlyCreated, semesterId);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getRecentlyCreated.success(response.data));
  } else {
    yield put(actions.getRecentlyCreated.failure(getErrorMessage(response)));
  }
}

const reloadingActions = [
  // task actions
  taskActions.savePageRanges.success,
  taskActions.moveToTodo.success,
  taskActions.updateTaskStatus.success,
  taskActions.createTasks.success,
  taskActions.completeTasks.success,
  taskActions.moveTasksToTodo.success,
  taskActions.setTasksStartAhead.success,
  taskActions.setTasksPriority.success,
  taskActions.pushTasksDueDate.success,
  taskActions.changeReadingSource.success,
  taskActions.changeCourse.success,
  // course actions
  calendarActions.deleteCourse.success,
  courseActions.importCourse.success,
  // activity actions
  calendarActions.deleteActivity.success,
  // reading source actions
  courseActions.updateReadingSource.success,
  courseActions.deleteReadingSource.success,
  // google calendar actions
  googleCalendarActions.toggleGoogleCalendar.success,
  googleCalendarActions.unsyncGoogleCalendar.success,
  googleCalendarActions.refreshGoogleCalendars.success,
  // course actions
  courseActions.updateCalendarId.success,
  icsActions.triggerIcsUpdates.success,
  icsActions.unsyncCourseFromLMS.success,
  termActions.createBatchCourses.success
];

// reloading actions are excluded since cushion will be reset as well
const cushionClearingActions = [
  // event actions
  createEvent.success,
  updateEvent.success,
  deleteEvent.success,
  // task actions
  taskActions.deleteTask.success,
  taskActions.deleteTasks.success,
  taskActions.restoreTasks.success
];

const planningActions = [
  calendarActions.planTask.success,
  calendarActions.planTasks.success,
  calendarActions.deletePlannedTask.success,
  calendarActions.movePlannedTask.success,
  calendarActions.resizePlannedTask.success,
  calendarActions.markAsUsed.success
];

const planningActionsTypes = planningActions.map(getType);

const cushionClearingActionTypes = cushionClearingActions.map(getType);

function* onStartPileListener() {
  while (true) {
    const { type, payload } = yield take([
      ...reloadingActions,
      ...cushionClearingActions,
      ...planningActions,
      taskActions.saveManageTask.success,
      actions.stopPileListener
    ]);

    if (type === getType(actions.stopPileListener)) {
      break;
    }

    // delete course that hasn't yet been created
    if (type === getType(calendarActions.deleteCourse.success) && !payload.id) {
      continue;
    }

    const { activeTab, search, filter, sort } = yield select(pileSelectors.getPileFilterState);

    // if cushion clearing action
    if (cushionClearingActionTypes.includes(type)) {
      if (activeTab !== PileStateOptions.COMPLETED) {
        yield put(actions.clearCushion());
      }
      continue;
    }

    // if save manage but not due date or days ahead changed
    if (
      type === getType(taskActions.saveManageTask.success) &&
      !(
        Object(payload.request).hasOwnProperty('dueDate') ||
        Object(payload.request).hasOwnProperty('daysToStartAhead') ||
        Object(payload.request).hasOwnProperty('timeNeed') ||
        Object(payload.request).hasOwnProperty('duration')
      )
    ) {
      continue;
    }

    // reloading action
    yield fork(applyFilter, {
      payload: {
        filter,
        activeTab,
        search,
        sort,
        isRefresh: true
      }
    });
  }
}

export default function*() {
  yield all([
    takeLatest(actions.getCourses.request, getCourses),
    takeLatest(actions.applyFilter, applyFilter),
    takeLatest(actions.startPileListener, onStartPileListener),
    takeLatest(actions.getAllToDoTasks.request, getAllToDoTasks),
    takeLatest(actions.getCompletedTasks.request, getCompletedTasks),
    takeLatest(actions.getWithinStartDateTasks.request, getWithinStartDateTasks),
    takeLatest(actions.getOverdueTasks.request, getOverdueTasks),
    takeLatest(actions.getNoDueDateTasks.request, getNoDueDateTasks),
    takeLatest(actions.calculateCushion.request, calculateCushion),
    takeLatest(actions.getRecentlyCreated.request, getRecentlyCreated)
  ]);
}
