// packages
import React, { FC } from 'react';
import styled from 'styled-components';
// other
import colors from '../../../utils/colors';
import { RegularText } from '../../../utils/typography';
import { getOpacityCode } from '../../../utils/colorUtils';
import MaterialIcon from '../icons/MaterialIcon';
import { isTouchScreen } from '../../../utils/screenUtils';

export type Props = {
  color?: string;
  textColor?: string;
  onClick?: (clickData?: any) => void;
  clickData?: any;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  filled?: boolean;
  borderRadius?: string;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  withoutBorder?: boolean;
  icon?: string | React.ReactElement;
  customIcon?: any;
  customIconAfter?: boolean;
  iconColor?: string;
  iconAfter?: boolean;
  style?: any;
  buttonRef?: any;
  theme?: any;
  noMinWidth?: boolean;
  renderAdditionalContent?: any;
};

const Button: FC<Props> = ({
  color = colors.primaryPurple,
  textColor = colors.white,
  onClick = () => {},
  children,
  clickData,
  className,
  disabled = false,
  filled = false,
  type = 'button',
  size = 'md',
  withoutBorder = false,
  icon,
  customIcon,
  customIconAfter,
  iconColor,
  iconAfter = false,
  style,
  buttonRef,
  theme,
  noMinWidth = false,
  renderAdditionalContent
}) => {
  const Button = filled ? FilledContainer : Container;
  return (
    <Button
      onClick={() => onClick && onClick(clickData)}
      color={color || `${colors.primaryPurple}`}
      filled={filled}
      disabled={disabled}
      className={className}
      type={type}
      size={size}
      withoutBorder={withoutBorder}
      icon={icon}
      iconBtn={!!icon && !children}
      style={style}
      tabIndex={buttonRef ? 1 : undefined}
      ref={buttonRef}
      textColor={textColor}
      theme={theme}
      noMinWidth={noMinWidth}
    >
      {icon && !iconAfter && <ButtonIcon icon={icon} size={size} color={filled ? iconColor || colors.white : color} />}
      {!customIconAfter && customIcon}
      {children && (
        <ButtonText size={size} style={customIcon ? { marginLeft: 5 } : {}}>
          {children}
        </ButtonText>
      )}
      {customIconAfter && customIcon}
      {icon && iconAfter && <ButtonIcon icon={icon} size={size} color={filled ? iconColor || colors.white : color} />}
      {renderAdditionalContent?.()}
    </Button>
  );
};

const ButtonIcon: FC<{ icon: string | React.ReactElement; color: string; size: string }> = ({ icon, color, size }) => {
  if (typeof icon === 'string') {
    return <Icon name={icon} color={color} size={size === 'sm' ? 13 : undefined} />;
  }
  return <span style={{ marginRight: '5px' }}>{icon}</span>;
};

const Icon = styled(MaterialIcon)`
  font-weight: inherit;
  transition: color 0.2s ease;
`;

const Container = styled.button<{
  disabled?: boolean;
  color: string;
  filled?: boolean;
  size?: string;
  withoutBorder?: boolean;
  icon?: string | React.ReactElement;
  iconBtn?: boolean;
  textColor: string;
  noMinWidth?: boolean;
}>`
  display: flex;
  text-align: center;
  background-color: ${({ theme }) => theme.background};
  color: ${props => props.color};
  border: 1px solid;
  border-color: ${props => props.withoutBorder && props.theme.background};
  align-items: center;
  justify-content: center;
  padding: ${props => !props.iconBtn && (props.size === 'sm' ? '6px 10px' : props.size === 'md' ? '8px 15px' : '15px 30px')};
  ${props => props.withoutBorder && props.icon && 'padding: 0;'};
  outline: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 5px;
  min-width: ${props => (props.noMinWidth ? 'unset' : '76px')};

  &:hover {
    opacity: ${props => !props.disabled && !props.withoutBorder && '0.8'};
    background-color: ${props =>
      props.withoutBorder && !props.icon
        ? `${props.color}${getOpacityCode('20')}`
        : props.withoutBorder && props.icon && props.theme.background};
    color: ${props => props.withoutBorder && `${props.color}${getOpacityCode('80')}`};
    ${!isTouchScreen && `transition: 0.2s;`};
    ${Icon} {
      color: ${props => props.withoutBorder && `${props.color}${getOpacityCode('80')}`};
    }
  }

  &:active {
    opacity: ${props => !props.disabled && !props.withoutBorder && '1'};
    background-color: ${props =>
      props.withoutBorder && !props.icon
        ? `${props.color}${getOpacityCode('20')}`
        : props.withoutBorder && props.icon && props.theme.background};
    color: ${props => props.withoutBorder && `${props.color}`};
    ${!isTouchScreen && `transition: 0.2s;`};
    ${Icon} {
      color: ${props => props.withoutBorder && `${props.color}`};
    }
  }

  &:disabled {
    border-color: ${props =>
      props.withoutBorder ? props.theme.background : props.filled ? props.theme.backgroundDark : props.theme.captures};
    background-color: ${props => (props.filled ? props.theme.backgroundDark : props.theme.background)};
    color: ${({ theme }) => theme.captures} !important;
    ${Icon} {
      color: ${({ theme }) => theme.captures};
    }
  }
`;

const FilledContainer = styled(Container)`
  border-color: ${props => props.color};
  background-color: ${props => props.color};
  color: ${props => props.textColor};
`;

const ButtonText = styled(RegularText)<{ size?: string }>`
  color: inherit;
  ${props => props.size === 'sm' && 'line-height: 1'};
  font-weight: 700;
`;

export default Button;
