import React, { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Field, Formik, FormikProps } from 'formik';
import { object as yupObject, string as yupString } from 'yup';

import t from '../../i18n/t';
import TextInput from '../common/inputs/text/TextInput';

import { authActions } from '../../state/auth';
import { AuthContainer, AuthForm, AuthText } from './Auth.styles';
import { AuthButton, AuthHeading } from './partials';
import { isMobile } from '../../utils/screenUtils';
import { MediumText } from '../../utils/typography';

type PropsFromState = {
  history: any;
};

type PropsFromDispatch = {
  resendActivationEmail: typeof authActions.resendActivationEmail.request;
};

type Props = PropsFromState & PropsFromDispatch;

type FormValues = {
  email: string;
};

const validationSchema = yupObject().shape({
  email: yupString()
    .email()
    .required(t.REQUIRED)
});

const handleKeyUp = (handleSubmit: Function, e: any) => {
  if (e.key === 'Enter') handleSubmit();
};

const ResendActivationEmail: FC<Props> = ({ history, resendActivationEmail }) => {
  return (
    <AuthContainer mobile={isMobile}>
      <AuthHeading title={t.RESEND_ACTIVATION_EMAIL} />
      <>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ email: '' }}
          enableReinitialize={false}
          onSubmit={(values, { resetForm }) => {
            resendActivationEmail({ email: values.email });
            resetForm({ values });
          }}
        >
          {({ handleSubmit }: FormikProps<FormValues>) => {
            return (
              <AuthForm>
                <Field
                  name={'email'}
                  component={TextInput}
                  label={t.EMAIL}
                  placeholder={t.ENTER_EMAIL}
                  onKeyUp={(e: any) => handleKeyUp(handleSubmit, e)}
                />
                <AuthButton onClick={() => handleSubmit()}>{t.RESEND}</AuthButton>
              </AuthForm>
            );
          }}
        </Formik>
        <AuthText mobile={isMobile}>
          <MediumText>{t.RESEND_ACTIVATION_EMAIL_TEXT}</MediumText>
        </AuthText>
      </>
    </AuthContainer>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resendActivationEmail: authActions.resendActivationEmail.request
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ResendActivationEmail);
