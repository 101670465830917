import { now } from 'shovel-lib/utils/timeUtils';

export const secondsToStopwatchTime = (timeInSeconds: number) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const [minutes, seconds] = now()
    // @ts-ignore
    .startOf('day')
    .seconds(timeInSeconds)
    .format('mm:ss')
    .split(':');
  return { hours, minutes, seconds };
};

export const countPagesForTimeSpent = (secondsPerPage: number, timeSpent: number) =>
  secondsPerPage ? Math.floor(timeSpent / secondsPerPage) : 0;

export const calculateNewAvgTimePerPage = (timeSpent: number, pagesRead: number) => Math.floor((timeSpent * 60) / pagesRead);

export const secondsPassedFrom = (from: Date) => (new Date().getTime() - new Date(from).getTime()) / 1000;
