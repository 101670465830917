import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Popup from 'reactjs-popup';

import t from '../../../../i18n/t';
import { Column, Row } from '../../../common/layoutUtils';
import { LargeText, RegularText } from '../../../../utils/typography';
import styled from 'styled-components';
import Button from '../../../common/buttons/Button';
import { newEstimateModalStyle } from '../../../../utils/popupUtils';
import { RootState } from '../../../../state/rootReducer';
import { getNewEstDialogState } from '../../../../state/task/selectors';
import { closeNewEstimateDialog } from '../../../../state/task/actions';
import { secondsToDurationInMinutes } from '../../../../utils/filters';
import { updateReadingSource } from '../../../../state/course/actions';
import { ReadingSourceInfoDto } from 'shovel-lib/types';

type Props = {
  estimationInfo: {
    open: boolean;
    data?: {
      reading: ReadingSourceInfoDto;
      newTimePerPage: number;
      taskId: number;
      courseId: number;
    };
  };
  close: typeof closeNewEstimateDialog;
  updateReadingSource: typeof updateReadingSource.request;
};

class NewEstimateDialog extends PureComponent<Props> {
  handleClick = () => {
    const {
      updateReadingSource,
      estimationInfo: { data },
      close
    } = this.props;
    if (data) {
      const { id, name } = data.reading;
      updateReadingSource({ id, name, secondsPerPage: data.newTimePerPage, courseId: data.courseId });
      close();
    }
  };
  render() {
    const { estimationInfo, close } = this.props;
    return (
      <Dialog
        open={estimationInfo.open}
        modal
        closeOnDocumentClick={false}
        closeOnEscape={false}
        contentStyle={newEstimateModalStyle}
      >
        <Content>
          <Title strong>{t.TIME_PER_PAGE}</Title>
          {estimationInfo.data && (
            <>
              <Row className={'estimate-wrapper'}>
                <Column className={'estimate'}>
                  <RegularText>{`${t.PREVIOUS_ESTIMATE}:`}</RegularText>
                  <RegularText>{secondsToDurationInMinutes(estimationInfo.data.reading.secondsPerPage)}</RegularText>
                </Column>
                <Column className={'estimate'}>
                  <RegularText>{`${t.TODAY_AVERAGE}:`}</RegularText>
                  <RegularText>{secondsToDurationInMinutes(estimationInfo.data.newTimePerPage)}</RegularText>
                </Column>
              </Row>
              <Button filled onClick={this.handleClick}>
                {t.USE_TODAY_AVG_AS_NEW_EST}
              </Button>
              <Button className={'custom-button'} withoutBorder onClick={close}>
                {t.KEEP_OLD_EST}
              </Button>
            </>
          )}
        </Content>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  estimationInfo: getNewEstDialogState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: closeNewEstimateDialog,
      updateReadingSource: updateReadingSource.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NewEstimateDialog);

const Dialog = styled(Popup)`
  &-content {
    background: ${({ theme }) => theme.background} !important;
    overflow: hidden;
  }

  &-overlay {
    background: ${({ theme }) => theme.modalOverlayColor} !important;
  }
`;

const Content = styled(Column)`
  width: 100%;
  .estimate-wrapper {
    justify-content: space-between;
    margin-bottom: 25px;
    .estimate {
      align-items: center;
    }
  }
  .custom-button {
    margin-top: 10px;
    background: ${props => `${props.theme.primary}1F`};
  }
`;

const Title = styled(LargeText)`
  margin-top: 0;
  text-align: center;
  display: block;
  margin-bottom: 20px;
`;
