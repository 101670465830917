import { Quill } from 'react-quill';
import { isTouchScreen } from './screenUtils';

const Link = Quill.import('formats/link');

export class ClickableLink extends Link {
  public static create(value: string): ClickableLink {
    const node = super.create(value);
    value = Link.sanitize(value);
    if (!value.startsWith('http')) {
      value = 'https://' + value;
    }
    node.setAttribute('href', value);
    node.setAttribute('target', '_blank');
    if (isTouchScreen) {
      node.setAttribute('contenteditable', 'false');
    }

    return node;
  }
}

export enum editorFormats {
  HEADER = 'header',
  ALIGN = 'align',
  COLOR = 'color',
  BACKGROUND = 'background',
  LINK = 'link'
}

export enum headerStyle {
  HEADER1 = '1',
  HEADER2 = '2',
  HEADER3 = '3',
  NORMAL = ''
}

export enum alignStyle {
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify',
  LEFT = ''
}
