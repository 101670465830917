import React, { PureComponent } from 'react';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import { Placement } from 'tippy.js';

type Props = {
  target: React.ReactElement;
  content: any;
  preformattedText?: boolean;
  position?: Placement;
  width?: string;
  arrow?: boolean;
  lightTheme?: boolean;
  interactive?: boolean;
  trigger?: string;
  colorPicker?: boolean;
  customPicker?: boolean;
  visible?: boolean;
  controlled?: boolean;
  onClickOutside?: any;
  className?: any;
  lazy?: boolean;
  noPadding?: boolean;
  onCreate?: any;
  reference?: any;
  disabled?: boolean;
  popperPositionElement?: any;
  zIndex?: number;
  withoutDelay?: boolean;
  theme?: any;
  noTransition?: boolean;
  minWidth?: number;
  appendTo?: 'parent' | Element | ((ref: Element) => Element);
  onShow?: any;
  onHidden?: any;
};

type State = { mounted: boolean };

export const DEFAULT_TIPPY_Z_INDEX = 9999;

class TippyTooltip extends PureComponent<Props, State> {
  readonly state = { mounted: false };

  lazyPlugin = {
    fn: () => ({
      onShow: () => {
        // mimic onShown callback behaviour with the timeout - onShown is too slow
        setTimeout(() => this.setState({ mounted: true }), 1);
        if (this.props.onShow) {
          this.props.onShow();
        }
      },
      onHidden: () => {
        this.setState({ mounted: false });
        if (this.props.onHidden) {
          this.props.onHidden();
        }
      }
    })
  };

  render() {
    const {
      target,
      content,
      preformattedText,
      position = 'top',
      width,
      arrow = true,
      lightTheme,
      interactive = false,
      trigger = 'mouseenter focus',
      colorPicker = false,
      visible,
      controlled = false,
      onClickOutside,
      className,
      lazy,
      appendTo,
      noPadding,
      onCreate,
      reference,
      popperPositionElement,
      zIndex,
      disabled,
      noTransition,
      minWidth,
      withoutDelay = false,
      theme,
      onShow,
      onHidden
    } = this.props;
    const extraProps: any = onClickOutside ? { onClickOutside: onClickOutside } : {};
    if (!lazy) {
      if (onShow) {
        extraProps.onShow = onShow;
      }
      if (onHidden) {
        extraProps.onHidden = onHidden;
      }
    }
    if (popperPositionElement) {
      extraProps.getReferenceClientRect = () => popperPositionElement.getBoundingClientRect();
    }

    return (
      <Wrapper
        minWidth={minWidth}
        reference={reference}
        moveTransition={noTransition ? undefined : 'transform 0.2s ease-out'}
        onCreate={onCreate || undefined}
        content={!lazy || this.state.mounted ? content : null}
        preformattedText={preformattedText}
        placement={position}
        maxWidth={width}
        disabled={disabled}
        delay={withoutDelay ? undefined : [200, 0]}
        plugins={lazy ? [this.lazyPlugin] : undefined}
        arrow={arrow}
        light={lightTheme}
        interactive={interactive}
        trigger={controlled ? undefined : trigger}
        appendTo={appendTo || document.getElementById('root') || document.body}
        colorPicker={colorPicker}
        visible={controlled ? visible : undefined}
        className={className}
        noPadding={noPadding}
        zIndex={zIndex}
        theme={theme}
        {...extraProps}
      >
        {target}
      </Wrapper>
    );
  }
}

export default TippyTooltip;

const Wrapper = styled(Tippy)<{
  preformattedText?: boolean;
  light?: boolean;
  minWidth?: number;
  colorPicker?: boolean;
  noPadding?: boolean;
}>`
  font-size: 12px;
  font-weight: 600;
  color: ${props => (props.light ? props.theme.textStrongColor : props.theme.background)};
  background-color: ${props => (props.light ? props.theme.background : props.theme.textStrongColor)};
  ${({ theme }) => `box-shadow: 0 0 2px ${theme.boxShadow}, 0 2px 2px ${theme.boxShadow};`};
  ${props => props.minWidth && `min-width:  ${props.minWidth}px;`};
  box-sizing: border-box;
  padding: ${props => (props.noPadding ? '0px' : '4px 8px')};
  ${({ preformattedText }) => preformattedText && 'white-space: pre;'}
  ${props =>
    props.colorPicker &&
    `> .tippy-content {
        padding: 5px 2px;
     }`};
  ${props =>
    props.noPadding &&
    `> .tippy-content {
        padding: 0px;
     }`};
  &[data-placement^='top'] > .tippy-arrow::before {
    border-top-color: ${({ theme }) => theme.textStrongColor};
  }
  &[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: ${({ theme }) => theme.textStrongColor};
  }
  &[data-placement^='right'] > .tippy-arrow::before {
    border-right-color: ${({ theme }) => theme.textStrongColor};
  }
  &[data-placement^='left'] > .tippy-arrow::before {
    border-left-color: ${({ theme }) => theme.textStrongColor};
  }
`;
