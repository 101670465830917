import React, { ChangeEvent, PureComponent } from 'react';

import { AutosizeFormInput, BareWrapper, FormInput } from '../Input.styles';
import InputWrapper from '../InputWrapper';
import { AlignType } from '@state/common/utils';
import { hasValue } from '@utils/validators';
import InlineInputWrapper from '../InlineInputWrapper';
import { TooltipPosition } from '@utils/popupUtils';

type Props = {
  value?: number;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>, value: number | null) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  name?: string;
  field?: {
    name?: string;
  };
  label?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  required?: boolean;
  error?: string;
  touched?: boolean;
  icon?: string;
  align?: AlignType;
  autosize?: boolean;
  autofocus?: boolean;
  className?: string;
  regex?: RegExp;
  inputRef?: React.RefObject<HTMLInputElement>;
  disabled?: boolean;
  inline?: boolean;
  bare?: boolean;
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
  extraWidth?: number;
  positive?: boolean;
  noErrorMargin?: boolean;
};

type State = {
  inputValue: string;
};

class NumberInput extends PureComponent<Props, State> {
  state: State = {
    inputValue: hasValue(this.props.value) ? this.props.value!.toString() : ''
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.value !== this.props.value) {
      this.setState({ inputValue: hasValue(this.props.value) ? this.props.value!.toString() : '' });
    }
  }

  onKeyDown = e => {
    if (this.props.positive && e.key === '-') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  };

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (this.props.regex && !this.props.regex.test(e.target.value)) return;

    // Transforms values like 02, 00, 001 to 2, 0, 1
    const inputValue = isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value).toString();
    this.setState({ inputValue });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;
    const { inputValue } = this.state;
    if (onBlur) {
      onBlur(e, inputValue !== '' ? parseInt(inputValue) : null);
    }
  };

  render() {
    const {
      name: inputName,
      label,
      placeholder,
      min,
      max,
      error,
      touched,
      icon,
      align,
      autosize,
      autofocus,
      className,
      onKeyUp,
      disabled,
      inline,
      field,
      noErrorMargin,
      inputRef,
      bare = false,
      required = true,
      tooltip,
      tooltipPosition,
      extraWidth = 0
    } = this.props;

    const Wrapper = bare ? BareWrapper : inline ? InlineInputWrapper : InputWrapper;
    const Input: any = autosize ? AutosizeFormInput : FormInput;
    const ref = autosize ? { inputRef } : { ref: inputRef };
    const name = inputName || (field && field.name);

    return (
      <Wrapper
        icon={icon}
        error={error}
        label={label}
        autosize={autosize}
        align={align}
        className={className}
        disabled={disabled}
        inline={inline}
        touched={touched}
        noErrorMargin={noErrorMargin}
        tooltip={tooltip}
        tooltipPosition={tooltipPosition}
        required={required}
      >
        <Input
          {...ref}
          name={name}
          type="number"
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyUp={onKeyUp}
          onKeyDown={this.onKeyDown}
          value={this.state.inputValue}
          placeholder={placeholder}
          min={min}
          max={max}
          autoFocus={autofocus}
          align={align}
          disabled={disabled}
          extraWidth={extraWidth}
        />
      </Wrapper>
    );
  }
}

export default NumberInput;
