import React from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import {
  CalendarEvent,
  CalendarEventType,
  CushionTaskDto,
  Formats,
  PageRange,
  PersonalizationSettings,
  ReadingSourceInfoDto,
  Subtask,
  TaskCategoryDto,
  TaskState,
  UntilOption,
  ViewPlannedTask,
  WorkloadTask
} from 'shovel-lib/types';
import { DATE_TIME_FORMAT, formatMomentDateAs, now, toMomentDate, toMomentUtcDate } from 'shovel-lib/utils/timeUtils';
import { secondsToDurationInMinutes } from './filters';
import { calculateNewAvgTimePerPage } from './timer';
import t from '../i18n/t';
import colors from './colors';
import { ResizeTaskType } from '../state/task/types';
import { timeUtils } from 'shovel-lib';
import { capitalize } from './textUtils';
import { PileSections } from '../state/pile/types';
import linkifyHtml from 'linkifyjs/html';
import RRule, { WeekdayStr } from 'rrule';
import { FormFieldName } from './enums';

export const calculateDatesDiffPreview = (date1: Moment, date2: Moment) => {
  const date2StartOfDay = date2.clone().startOf('day');
  const date1StartOfDay = date1.clone().startOf('day');
  const daysDiff = date2StartOfDay.diff(date1StartOfDay, 'days');

  if (daysDiff === 0) {
    // Use moment.from only for differences of 1 hour or less (for 22/23 hours difference moment.from displays 'in a day' even if the dates are same)
    const hoursDiff = Math.round(date2.diff(date1, 'hours', true));
    return `${t.DUE} ${t.IN} ${hoursDiff > 1 ? `${hoursDiff} ${t.HOURS}` : date2.from(date1, true)}`;
  }
  if (daysDiff === 1) {
    return `${t.DUE} ${t.TOMORROW.toLowerCase()}`;
  }

  return `${t.DUE} ${t.IN} ${date2StartOfDay.from(date1StartOfDay, true)}`;
};

export const dueDatePreview = (date: any, state: TaskState, planned?: boolean): string => {
  // if (planned) {
  //   return `${t.DUE}: ${formatMomentDateAs(toMomentDate(date), Formats.MONTH_DAY_FORMAT)}`;
  // }
  // const format = state === TaskState.ACTIVE ? Formats.TIME : Formats.DATE_TIME_FORMAT;
  return formatMomentDateAs(toMomentDate(date), Formats.DATE_TIME_FORMAT);
};

export const pageRangesToAdditionalText = (pageRanges: PageRange[]) =>
  pageRanges
    .map(pageRange => (pageRange.from && pageRange.to ? `${pageRange.from}-${pageRange.to}` : pageRange.pages))
    .join(', ');

export const sumPageRanges = (pageRanges: { from?: number; to?: number }[]) =>
  pageRanges
    .map(range => (range.to && range.from ? Math.abs(range.to - range.from) + 1 : 0))
    .reduce((sum, pages) => sum + pages, 0);

export const calculateNewAvgPerPage = (timeSpent?: number, pagesRead?: number) => {
  if (!timeSpent || !pagesRead) {
    return undefined;
  }
  return secondsToDurationInMinutes(calculateNewAvgTimePerPage(timeSpent, pagesRead));
};

export const ObviousMikeAnimation = styled.div<{ left?: number }>`
  position: absolute;
  top: -20px;
  left: calc(50% - ${props => `${props.left || 0}px`});
  animation: obviousMike 1s infinite;

  @keyframes obviousMike {
    0% {
      top: -20px;
    }
    50% {
      top: -25px;
    }
    100% {
      top: -20px;
    }
  }
`;

export const ObviousMike = styled.div`
  position: relative;
  &:hover {
    ${ObviousMikeAnimation} {
      animation: none;
    }
  }
`;

export const AUTOSAVE_INDICATOR_TIMEOUT = 800;

export const MARK_COMPLETE_ANIMATION_DURATION = 100;

export const ERROR_TIMEOUT = 3000;

export const CUSHION_CRITICAL_WARNING_BREAKPOINT_HOURS = 0;

export const getCushionItemColor = (cushion: number, timeNeed: number, warningPercentage: number) => {
  if (cushion < CUSHION_CRITICAL_WARNING_BREAKPOINT_HOURS) {
    return colors.negative;
  }
  if (cushion > CUSHION_CRITICAL_WARNING_BREAKPOINT_HOURS && cushion <= warningPercentage * timeNeed) {
    return colors.warningBulletColor;
  }

  return colors.positive;
};

export const timelineGroups = [{ id: 1, title: 'group' }];

export const mapCushionTask2TimelineItem = (
  tasks: Array<CushionTaskDto>,
  warningPercentage: number,
  resizeType: ResizeTaskType,
  colorByCushion: boolean
) => {
  return tasks.map(task => ({
    id: task.taskId.toString(),
    group: 1,
    name: task.title,
    start_time: toMomentDate(task.dueDate)
      .add(-task.daysToStartAhead, 'day')
      .startOf('day'),
    end_time: toMomentDate(task.dueDate),
    bgColor: getCushionItemColor(task.cushion, task.timeNeed, warningPercentage),
    cushion: task.cushion,
    type: task.type,
    canMove: false,
    canResize: resizeType,
    colorHex: task.colorHex,
    state: task.state,
    course: task.courseName,
    taskTitle: task.title,
    subtitle: task.subtitle,
    colorByCushion: colorByCushion,
    task
  }));
};
// only for future due dates
export const sectionDueDate = (dateString: any) => {
  const DIFF_IN_DAYS_BORDER_CASE = 6;
  const date = timeUtils.toMomentDate(dateString);
  const now = timeUtils.now();
  const diffInDays = timeUtils.timeDiffInDays(now.toDate(), date.toDate());

  if (timeUtils.isSameDate(date, now)) {
    return t.TODAY;
  }
  if (timeUtils.isSameDate(date, now.clone().add(1, 'day'))) {
    return t.TOMORROW;
  }
  if (diffInDays > DIFF_IN_DAYS_BORDER_CASE) {
    return `${t.IN} ${Math.ceil(diffInDays)} days`;
  }
  return timeUtils.formatStringDateAs(dateString, `dddd`);
};

export const groupByDate = tasks => {
  const groups = { [PileSections.NO_DUE_DATE]: [] };
  tasks.forEach(tt => {
    //TODO: check this
    const date = tt.due
      ? moment(tt.due)
          .startOf('day')
          .toISOString()
      : PileSections.NO_DUE_DATE;
    if (date in groups) {
      groups[date].push(tt);
    } else {
      groups[date] = new Array(tt);
    }
  });
  if (groups[PileSections.NO_DUE_DATE].length === 0) {
    // @ts-ignore
    delete groups[PileSections.NO_DUE_DATE];
  }
  return groups;
};

export const formatTaskCardDate = (date: Date | Moment, settings: PersonalizationSettings) => {
  const shortDate = settings.dateFormat!.replace(/^YYYY | YYYY$/g, '');

  if (settings.time24Format) {
    return timeUtils.formatStringDateAs(date.toString(), `ddd, ${shortDate}, HH:mm`);
  }

  return timeUtils.formatStringDateAs(date.toString(), `ddd, ${shortDate}, h:mma`).slice(0, -1);
};

export const deleteTaskCategoryFilter = (id: number) => {
  return id ? (c: TaskCategoryDto) => c.id !== id : (c: TaskCategoryDto) => c.id;
};

export const deleteReadingSourceFilter = (id: number) => {
  return id ? (c: ReadingSourceInfoDto) => c.id !== id : (c: ReadingSourceInfoDto) => c.id;
};

export const getMultipleTaskActionConfirmationMessage = (
  taskLength: number,
  prefix: string,
  suffix: string,
  numberFirst?: boolean
) =>
  `${prefix} ${numberFirst ? `${taskLength} ` : ''}${numberFirst ? t.SELECTED : capitalize(t.SELECTED)}${
    !numberFirst ? ` ${taskLength}` : ''
  } ${taskLength === 1 ? t.TASK?.toLowerCase() : t.TASKS?.toLowerCase()} ${suffix}`;

export const groupByDueDate = (tasks: CalendarEvent[]) => {
  const groups = {};
  tasks.forEach(task => {
    if (task.type === CalendarEventType.TASK) {
      const date = toMomentDate(task.start)
        .seconds(0)
        .milliseconds(0)
        .toISOString();
      if (date in groups) {
        groups[date].push(task);
      } else {
        groups[date] = new Array(task);
      }
    } else {
      groups[task.id!] = [task];
    }
  });
  return groups;
};

export const filterTasksBySearchText = (tasks?: WorkloadTask[], searchText?: string) => {
  if (!tasks) return [];
  if (!searchText) return tasks;
  return tasks.filter(
    task =>
      task.title.toLowerCase().includes(searchText.toLowerCase()) ||
      (task.details || '').toLowerCase().includes(searchText.toLowerCase()) ||
      (task.courseName || '').toLowerCase().includes(searchText.toLowerCase()) ||
      (task.taskCategory?.name || '').toLowerCase().includes(searchText.toLowerCase()) ||
      (task.reading?.name || '').toLowerCase().includes(searchText.toLowerCase())
  );
};

export const getDDPosition = (id: string) => {
  const el = document.getElementById(id);
  const section = document.getElementById('addTaskSection');
  if (section && el) {
    const { width, left } = section.getBoundingClientRect();
    const { left: elLeft } = el.getBoundingClientRect();
    return left - elLeft > width / 2 ? 'bottom left' : 'bottom right';
  }
  return undefined;
};

export const parseTaskMetadata = (metadataString: string) => {
  return JSON.parse(metadataString.replace(/\n/g, '<br />'));
};

export const getTaskStatus = (dueDate, noDueDate, overdue) => {
  if (noDueDate) {
    return TaskState.ACTIVE;
  }
  if (overdue) {
    return TaskState.DO_LATER;
  }
  return dueDate ? (now().isBefore(dueDate) ? TaskState.ACTIVE : TaskState.DO_LATER) : TaskState.ACTIVE;
};

export const renderGoogleEventDescription = (descriptionString: string) => {
  if (!descriptionString) return null;
  return (
    <GoogleEventDescriptionSection>
      <div dangerouslySetInnerHTML={{ __html: linkifyHtml(descriptionString, { target: { url: '_blank' } }) }} />
    </GoogleEventDescriptionSection>
  );
};

const GoogleEventDescriptionSection = styled.div`
  margin-bottom: 15px;
  font-family: 'Quicksand', sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: ${props => props.theme.textColor};

  a {
    color: #4285f4;
    text-decoration: none;
  }
`;

export const sumPagesForUnusedPlannedTasks = (plannedTasks: ViewPlannedTask[]) => {
  return plannedTasks.reduce(
    (acc, te) => acc + ((te.isUsed || toMomentDate(te.end).isBefore(now()) ? 0 : te.pages) || 0),
    0
  );
};

export type AddTaskState = {
  step: number;
  isRepeatSet: boolean;
  repeatOptions: {
    interval: number;
    onDays?: WeekdayStr[];
    until?: Date;
    untilOption: any;
    copyFields: {
      name: boolean;
      continueNumbering: boolean;
      description: boolean;
      subtasks: boolean;
      startAhead: boolean;
      pages: boolean;
      estimate: boolean;
    };
  };
  tasks: Array<any>;
  course: any;
  category: any;
  readingSource: any;
  task: {
    [FormFieldName.NAME]: string;
    [FormFieldName.DESCRIPTION]: string;
    [FormFieldName.COURSE]: any;
    [FormFieldName.SUBTASKS]: Subtask[];
    [FormFieldName.CATEGORY]: any;
    [FormFieldName.READING_SOURCE]: any;
    [FormFieldName.DUE_DATE]?: Date;
    [FormFieldName.START_AHEAD]: number;
    [FormFieldName.ESTIMATE]: number;
    [FormFieldName.PAGE_RANGES]: any;
    [FormFieldName.AUTO_ESTIMATE]: boolean;
  };
};

export const initialAddTaskState = {
  step: 1,
  isRepeatSet: false,
  repeatOptions: {
    interval: 1,
    onDays: [],
    until: undefined,
    untilOption: UntilOption.CLASSES,
    copyFields: {
      name: true,
      continueNumbering: true,
      description: false,
      subtasks: false,
      startAhead: true,
      pages: true,
      estimate: true
    }
  },
  tasks: [],
  course: null,
  category: null,
  readingSource: null,
  task: {
    [FormFieldName.NAME]: '',
    [FormFieldName.DESCRIPTION]: '',
    [FormFieldName.COURSE]: null,
    [FormFieldName.SUBTASKS]: [],
    [FormFieldName.CATEGORY]: null,
    [FormFieldName.READING_SOURCE]: null,
    [FormFieldName.DUE_DATE]: undefined,
    [FormFieldName.START_AHEAD]: 0,
    [FormFieldName.ESTIMATE]: 0,
    [FormFieldName.PAGE_RANGES]: [],
    [FormFieldName.AUTO_ESTIMATE]: false
  }
};

export const defaultRepeatOptions = {
  isRepeatSet: false,
  repeatOptions: {
    interval: 1,
    onDays: [],
    until: undefined,
    untilOption: UntilOption.CLASSES,
    copyFields: {
      name: true,
      continueNumbering: true,
      description: false,
      subtasks: false,
      startAhead: true,
      pages: true,
      estimate: true
    }
  }
};

export function getRepeatTaskOptions(options: any, defaultUntil: any) {
  const { interval, onDays, until, untilOption, course, category, ...copyOptions } = options;
  const repeatOptions = {
    interval,
    onDays,
    until: until || defaultUntil,
    course,
    category,
    untilOption,
    copyFields: copyOptions
  };
  return { repeatOptions, isRepeatSet: true };
}

export function isDateInHoliday(date: Date, holidays: any[] | undefined) {
  const holiday = holidays?.find(holiday => date >= new Date(holiday.start) && date <= new Date(holiday.end));
  return holiday?.title;
}

export function generateTasksForRepeat(
  values: any,
  { repeatOptions: { until, interval, onDays, copyFields } }: any,
  { timezone: tzid, courses, activities, categories, readingSources }: any,
  isInHolidayFun: (Date) => boolean
) {
  if (!values[FormFieldName.DUE_DATE] || !until) return;

  const dtstart = toMomentUtcDate(moment(values[FormFieldName.DUE_DATE]).format(DATE_TIME_FORMAT)).toDate();

  const untilDate = toMomentUtcDate(formatMomentDateAs(toMomentDate(until).endOf('day'), DATE_TIME_FORMAT)).toDate();

  //@ts-ignore
  const rule = new RRule({
    freq: RRule.WEEKLY,
    interval: interval,
    tzid,
    dtstart,
    byweekday: onDays?.length! > 0 ? onDays : undefined,
    until: untilDate
  });

  const tasks = rule.all().map((date, index) => {
    const dueDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    const isInHoliday = isInHolidayFun(dueDate);

    const isFirstTask = index === 0;
    const task = {
      [FormFieldName.NAME]: copyFields.name || isFirstTask ? values[FormFieldName.NAME] : '',
      [FormFieldName.DESCRIPTION]: copyFields.description || isFirstTask ? values[FormFieldName.DESCRIPTION] : '',
      [FormFieldName.START_AHEAD]: copyFields.startAhead || isFirstTask ? values[FormFieldName.START_AHEAD] : 0,
      [FormFieldName.SUBTASKS]: copyFields.subtasks || isFirstTask ? values[FormFieldName.SUBTASKS] : [],
      [FormFieldName.CATEGORY]: values[FormFieldName.CATEGORY],
      [FormFieldName.COURSE]: values[FormFieldName.COURSE],
      [FormFieldName.PRIORITY]: values[FormFieldName.PRIORITY],
      [FormFieldName.DUE_DATE]: dueDate,
      [FormFieldName.ESTIMATE]: copyFields.estimate || isFirstTask ? values[FormFieldName.ESTIMATE] : 0,
      [FormFieldName.AUTO_ESTIMATE]: values[FormFieldName.AUTO_ESTIMATE],
      [FormFieldName.DURATION]: values[FormFieldName.DURATION],
      holiday: isInHoliday,
      selected: !isInHoliday
    };
    return values[FormFieldName.READING_SOURCE]
      ? {
          ...task,
          [FormFieldName.READING_SOURCE]: values[FormFieldName.READING_SOURCE],
          [FormFieldName.PAGE_RANGES]: copyFields.pages || isFirstTask ? values[FormFieldName.PAGE_RANGES] : [],
          [FormFieldName.ESTIMATE]: copyFields.pages || isFirstTask ? values[FormFieldName.ESTIMATE] : 0
        }
      : task;
  });
  const course = [...courses, ...activities].find(c => c.id === values[FormFieldName.COURSE]);
  const taskCategories = categories || (course ? course.taskCategories : []);
  const readings = readingSources || (course ? course.readingSources : []);
  const category = taskCategories.find(c => c.id === values[FormFieldName.CATEGORY]);
  const readingSource = readings.find(rs => rs.id === values[FormFieldName.READING_SOURCE]);

  return {
    tasks,
    course,
    category,
    // step: this.state.step + 1,
    task: values,
    readingSource
  };
}
