import React, { PureComponent } from 'react';
import Popup from 'reactjs-popup';
import { RootState } from '../../state/rootReducer';
import { getNewCreateTaskDialogState } from '../../state/task/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { taskActions } from '../../state/task';
import styled from 'styled-components';
import { FlexColumn, Row } from '../common/layoutUtils';

import { CalendarEvent, ThinActivityDto, ThinCourseDto } from 'shovel-lib/types';
import { createRepeatTaskOverlayStyle, createTaskOverlayStyle } from '../../utils/popupUtils';

import CreateTaskDefault, { FormValues } from './createTask/CreateTaskDefault';
import DialogCloseButton from '../common/dialog/DialogCloseButton';
import { getTimeZone } from '../../state/semester/selectors';
import { getAllActivities, getAllCourses, getHolidays } from '../../state/calendar/selectors';
import CreateRepeatTasks from './createTask/CreateRepeatTasks';
import { FormFieldName } from '../../utils/enums';
import { screenSizes } from '../../utils/screenUtils';
import {
  AddTaskState,
  defaultRepeatOptions,
  generateTasksForRepeat,
  getRepeatTaskOptions,
  initialAddTaskState,
  isDateInHoliday
} from '../../utils/taskUtils';

type PropsFromState = {
  open: boolean;
  data?: { courseId: number; taskCategoryId?: number; readingSourceId?: number };
  timezone: string;
  holidays: CalendarEvent[];
  courses: ThinCourseDto[];
  activities: ThinActivityDto[];
};

type PropsFromDispatch = {
  close: typeof taskActions.closeNewCreateTaskDialog;
  openRepeatPatternDialog: typeof taskActions.openRepeatPatternDialog;
};

type Props = PropsFromDispatch & PropsFromState;

//Rename after deleting old one
class CreateTaskOverlay extends PureComponent<Props, AddTaskState> {
  state: AddTaskState = initialAddTaskState;

  previous = () => this.setState({ step: this.state.step - 1 });

  handleClose = () => {
    this.setState(initialAddTaskState);
    this.props.close();
  };

  setRepeatOptions = (options: any, defaultUntil: any) => this.setState(getRepeatTaskOptions(options, defaultUntil));

  clearRepeatOptions = () => this.setState(defaultRepeatOptions);

  isInHoliday = (dueDate: Date) => isDateInHoliday(dueDate, this.props.holidays);

  openRepeatPatternDialog = (values: FormValues) => {
    this.props.openRepeatPatternDialog({
      date: values[FormFieldName.DUE_DATE]!,
      isReading: !!values[FormFieldName.READING_SOURCE],
      isPageBasedEstEnabled: values[FormFieldName.AUTO_ESTIMATE],
      save: this.setRepeatOptions,
      repeatOptions: this.state.repeatOptions,
      isSubtasksOptionHidden: false
    });
  };

  generateTasks = (values: FormValues) => {
    const generated: any = generateTasksForRepeat(values, this.state, this.props, this.isInHoliday);
    this.setState({
      ...generated,
      step: this.state.step + 1
    });
  };

  render() {
    const { open, courses, activities, data } = this.props;
    const {
      step,
      isRepeatSet,
      tasks,
      course,
      category,
      task,
      repeatOptions: { copyFields },
      readingSource
    } = this.state;
    if (!open) return null;

    return (
      <Dialog
        open={open}
        contentStyle={step === 1 ? { ...createTaskOverlayStyle, width: 900 } : createRepeatTaskOverlayStyle}
        overlayStyle={{ overflow: 'scroll' }}
        onClose={this.handleClose}
        lockScroll={false}
        closeOnEscape={false}
        closeOnDocumentClick={false}
      >
        <Container>
          <DialogCloseButton onClick={() => this.handleClose()} />
          {step === 1 ? (
            <CreateTaskDefault
              clearRepeatOptions={this.clearRepeatOptions}
              isRepeatSet={isRepeatSet}
              courses={courses}
              activities={activities}
              generateTasks={this.generateTasks}
              openCreatePatternDialog={this.openRepeatPatternDialog}
              close={this.handleClose}
              initial={{
                ...task,
                [FormFieldName.COURSE]: data?.courseId || task[FormFieldName.COURSE],
                [FormFieldName.CATEGORY]: data?.taskCategoryId || task[FormFieldName.CATEGORY],
                [FormFieldName.READING_SOURCE]: data?.readingSourceId || task[FormFieldName.READING_SOURCE]
              }}
            />
          ) : (
            <CreateRepeatTasks
              tasks={tasks}
              course={course!}
              category={category}
              readingSource={readingSource}
              previous={this.previous}
              taskNamesCopied={copyFields.name}
              close={this.handleClose}
            />
          )}
        </Container>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  ...getNewCreateTaskDialogState(state),
  timezone: getTimeZone(state),
  holidays: getHolidays(state),
  courses: getAllCourses(state),
  activities: getAllActivities(state)
});

const mapStateToDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: taskActions.closeNewCreateTaskDialog,
      openRepeatPatternDialog: taskActions.openRepeatPatternDialog
    },
    dispatch
  );

export default connect(mapStateToProps, mapStateToDispatch)(CreateTaskOverlay);

const Container = styled(FlexColumn)`
  width: 100%;
  padding-top: 30px;
  box-sizing: border-box;
  justify-content: space-between;
`;

export const DialogButtons = styled(Row)`
  justify-content: flex-end;
`;

export const Dialog = styled(Popup)`
  &-content {
    background: ${({ theme }) => theme.background} !important;
    @media (max-width: ${screenSizes.small}) {
      width: 750px !important;
      min-width: 750px !important;
    }
  }

  &-overlay {
    background: ${({ theme }) => theme.modalOverlayColor} !important;
  }
`;
