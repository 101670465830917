import React, { PureComponent } from 'react';
import colors from '../../../utils/colors';
import styled from 'styled-components';
import t from '../../../i18n/t';
import Button from '../../common/buttons/Button';
import { MembershipType, PaymentProcessor } from 'shovel-lib/types';
import { membershipApi } from 'shovel-lib';
import { alert } from '../../common/snackbarNotifications/Notifications';
import { stripeApiKey } from '../../../config/configConstants';

import { loadStripe } from '@stripe/stripe-js';
import { getErrorMessage } from '../../../api/network';
import gaAnalytics, { AnalyticsEvent } from '../../../config/gaAnalytics';

const stripePromise = loadStripe(stripeApiKey);

type Props = {
  selectedPlan: MembershipType | null;
  coupon: string;
  buttonText?: string;
  userId?: number;
  executeRecaptcha?: any;
};

class StripeSubscribe extends PureComponent<Props> {
  onSubmit = async () => {
    if (!this.props.selectedPlan) return;

    const request = {
      type: this.props.selectedPlan!,
      coupon: this.props.coupon || undefined
    };
    let response;
    if (this.props.userId) {
      const reCaptchaResponse = await this.props.executeRecaptcha('payment');
      response = await membershipApi.parentStripeCreateCheckoutSession(
        { userId: this.props.userId, reCaptchaResponse },
        request
      );
    } else {
      response = await membershipApi.stripeCreateCheckoutSession(request);
    }

    if (response.ok) {
      gaAnalytics.track(
        AnalyticsEvent.Subscribe.name,
        AnalyticsEvent.Subscribe.params(PaymentProcessor.STRIPE, request.type, request.coupon)
      );
      const stripe = await stripePromise;
      const result = await stripe!.redirectToCheckout({ sessionId: response.data!.id.toString() });
      if (result.error) {
        alert(result.error.message);
      }
    } else {
      alert(t[getErrorMessage(response)]);
    }
  };

  render() {
    return (
      <SubscribeButton
        size={'lg'}
        filled
        color={colors.positive}
        onClick={this.onSubmit}
        disabled={!this.props.selectedPlan}
      >
        {this.props.buttonText || t.CONTINUE}
      </SubscribeButton>
    );
  }
}

export default StripeSubscribe;

const SubscribeButton = styled(Button)`
  width: 100%;
  max-width: 240px;
  padding: 15px;
`;
