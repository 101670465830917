import React, { FC } from 'react';
import { LargeText, MediumText, RegularText } from '../../../utils/typography';
import TippyTooltip from '../tooltip/TippyTooltip';
import styled from 'styled-components';
import { isMobileApp } from '../../../utils/screenUtils';

type Props = {
  onClick: (param?: any) => void;
  label: string;
  disabled?: boolean;
  tooltip?: string;
  tooltipWidth?: number;
  className?: string;
  topSpacing?: boolean;
  size?: 'sm' | 'md' | 'lg';
};

const AddButton: FC<Props> = ({
  onClick,
  disabled = false,
  tooltip,
  label,
  className,
  tooltipWidth,
  size = 'sm',
  topSpacing = false
}) => {
  const content = (
    <Wrapper isDisabled={disabled} onClick={e => !disabled && onClick(e)} className={className} topSpacing={topSpacing}>
      {
        <Label disabled={disabled} size={size}>
          + {label}
        </Label>
      }
    </Wrapper>
  );

  if (tooltip) {
    return <TippyTooltip target={content} content={tooltip} width={`${tooltipWidth}px`} />;
  }

  return content;
};

export default AddButton;

const Wrapper = styled.button<{ isDisabled?: boolean; topSpacing?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};

  &:hover {
    ${props => !props.isDisabled && 'opacity: 0.7; '};
  }

  * {
    cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')} !important;
  }

  position: relative;

  ${props => props.topSpacing && `margin-top: 20px;`}
`;

const Label = styled(props => {
  const TextComponent = props.size === 'sm' ? MediumText : props.size === 'md' ? RegularText : LargeText;
  return <TextComponent {...props} />;
})<{ disabled?: boolean; size: any }>`
  color: ${props => (!props.disabled ? props.theme.primary : props.theme.captures)};
  cursor: pointer;
  transition: all 0.2s ease;
  ${isMobileApp && 'font-size: 14px;'}
`;
