import React, { FC } from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import MaterialIcon from './icons/MaterialIcon';
import { MediumText } from '../../utils/typography';
import { Column, Row } from './layoutUtils';
import { commonPopupCSS, contextMenuPopupStyle } from '../../utils/popupUtils';
import TippyTooltip from './tooltip/TippyTooltip';
import { Placement } from 'tippy.js';
import { isMobileApp } from '../../utils/screenUtils';

export type ContextMenuOption = {
  icon?: string;
  name?: string;
  action?: Function;
  Item?: any;
  style?: any;
};

type Props = {
  options: ContextMenuOption[];
  tooltip?: string;
  icon?: string;
  color?: string;
  onOpen?: Function;
  onClose?: Function;
  defaultOpen?: boolean;
  width?: string;
  style?: object;
  customTarget?: any;
  tooltipPosition?: any;
};

const renderTarget = (open: boolean, color?: string, icon?: string, customTarget?: any) => {
  if (customTarget) {
    return customTarget;
  }
  return (
    <Container className={'context-menu'} data-cy={'menu'}>
      <MaterialIcon name={icon || 'more_horiz'} size={22} strong={open} color={color} />
    </Container>
  );
};

const ContextMenu: FC<Props> = ({
  options,
  icon,
  tooltip,
  color,
  onOpen,
  onClose,
  defaultOpen,
  width,
  style,
  customTarget,
  tooltipPosition = 'bottom right'
}) => {
  return (
    <PopupWrapper>
      <Popup
        defaultOpen={defaultOpen}
        trigger={open =>
          tooltip ? (
            <div>
              <TippyTooltip target={renderTarget(open, color, icon, customTarget)} content={tooltip} />
            </div>
          ) : (
            renderTarget(open, color, icon, customTarget)
          )
        }
        arrow={false}
        position={tooltipPosition}
        on="click"
        closeOnDocumentClick
        contentStyle={{ ...contextMenuPopupStyle, ...style, width: width || '135px' }}
        {...(onOpen ? { onOpen: () => onOpen() } : {})}
        {...(onClose ? { onClose: () => onClose() } : {})}
      >
        {close => <ContextMenuPopup options={options} close={close} />}
      </Popup>
    </PopupWrapper>
  );
};

export const ContextMenuPopup: FC<{ options: ContextMenuOption[]; close: Function }> = ({ options, close }) => {
  return (
    <MenuPopupContainer>
      {options.map((o, i) =>
        !!o.Item ? (
          <o.Item key={i} closeMenu={close} />
        ) : (
          <MenuItem
            key={i}
            onClick={() => {
              o.action!();
              close();
            }}
            style={o.style}
          >
            {o.icon && <MenuIcon name={o.icon} size={14} />}
            <MediumText strong>{o.name!}</MediumText>
          </MenuItem>
        )
      )}
    </MenuPopupContainer>
  );
};

export default ContextMenu;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PopupWrapper = styled(Column)`
  > .popup-content {
    ${commonPopupCSS}
  }
`;

const MenuPopupContainer = styled(Column)`
  background-color: ${({ theme }) => theme.background};
  border-radius: 5px;
  padding: 5px 0;
`;

export const MenuItem = styled(Row)`
  padding: ${isMobileApp ? '10px' : '5px 10px'};
  cursor: pointer;
  background-color: inherit;
  text-decoration: none;
  font-weight: bold;
  color: ${({ theme }) => theme.textColor};
  ${isMobileApp && `>span { font-size: 14px; }`}

  &:hover {
    color: ${({ theme }) => theme.textStrongColor};
    background-color: ${({ theme }) => theme.backgroundLight};
  }
`;

export const MenuIcon = styled(MaterialIcon)`
  color: ${({ theme }) => theme.textStrongColor};
  margin-right: 6px;
`;
