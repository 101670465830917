import React, { RefObject } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import t from '../../../../i18n/t';
import colors from '../../../../utils/colors';
import { RegularText, RegularTextInput } from '../../../../utils/typography';
import { Row } from '../../../common/layoutUtils';
import { Subtask } from 'shovel-lib/types';
import CheckBox from '../../../common/CheckBox';
import { SUBTASK_MAX_LENGTH } from '../../../../utils/constants/task';
import { Field, FieldArray, Formik, FormikProps } from 'formik';
import MaterialIcon from '../../../common/icons/MaterialIcon';
import { DeleteButton } from '../../../common/buttons';
import { isTouchScreen } from '../../../../utils/screenUtils';
import ScrollGradient from '../../../common/ScrollGradient';
import { taskActions } from '../../../../state/task';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import TippyTooltip from '../../../common/tooltip/TippyTooltip';
import Skeleton from '../../../common/Skeleton';

type PropsFromDispatch = {
  addOrUpdateSubtask: typeof taskActions.addOrUpdateSubtask.request;
  deleteSubtask: typeof taskActions.deleteSubtask.request;
  reorderSubtasks: typeof taskActions.reorderSubtasks.request;
};

type Props = {
  inline?: boolean;
  taskId?: number;
  subtasks?: Subtask[];
  customAddOrUpdate?: (data: Subtask, oldData?: Subtask) => void;
  customDelete?: (subtask: Subtask) => void;
  maxHeight?: number;
  hasFilter?: boolean;
  noInput?: boolean;
  autoFocus?: boolean;
  noScroll?: boolean;
  noMaxHeight?: boolean;
  reorder?: boolean;
  loading?: boolean;
} & PropsFromDispatch;

type State = { value: string; scroll: boolean; hideCompleted: boolean };

class ManageSubtasks extends React.PureComponent<Props, State> {
  state: State = { value: '', scroll: false, hideCompleted: false };

  containerEnd: RefObject<HTMLDivElement> = React.createRef();

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    //@ts-ignore
    if (this.props.subtasks?.length > prevProps.subtasks?.length && !this.props.noScroll) {
      this.setState({ scroll: true });
    }
    if (this.state.scroll && this.containerEnd.current) {
      this.containerEnd.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      this.setState({ scroll: false });
    }
  }

  addOrUpdateSubtask = (data: Subtask, oldData?: Subtask) => {
    const { taskId, customAddOrUpdate, addOrUpdateSubtask } = this.props;
    if (customAddOrUpdate) {
      customAddOrUpdate(data, oldData);
    } else if (taskId) {
      addOrUpdateSubtask({ taskId, data });
    }
  };

  deleteSubtask = (subtask: Subtask) => {
    const { taskId, deleteSubtask, customDelete } = this.props;
    if (customDelete) {
      customDelete(subtask);
    } else if (taskId && subtask.id) {
      deleteSubtask({ taskId, id: subtask.id });
    }
  };

  handleBlur = (e: any, index: number) => {
    if (index === -1) {
      if (e.target.value) {
        const data = { content: e.target.value, completed: false };
        this.addOrUpdateSubtask(data);
        this.setState({ value: '' });
      }
    } else {
      const { subtasks } = this.props;
      const oldData = subtasks?.[index];
      if (!oldData || oldData.content === e.target.value) return;
      const data = { ...oldData, content: e.target.value };
      this.addOrUpdateSubtask(data, oldData);
    }
  };

  handleKeyUp = (e: any) => {
    if (e.key === 'Enter' && this.state.value) {
      const data = { content: this.state.value, completed: false };
      this.addOrUpdateSubtask(data);
      this.setState({ value: '' });
    }
  };

  updateOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    (e.target as HTMLInputElement).blur();
  };

  onDragEnd = (result: DropResult, values: any) => {
    const { destination, source } = result;
    const { reorderSubtasks, taskId } = this.props;

    if (!destination) {
      return;
    }

    const [removed] = values.subtasks.splice(source.index, 1);
    values.subtasks.splice(destination.index, 0, removed);
    if (taskId) {
      const data = {
        id: taskId!,
        subtasks: values.subtasks.map((subtask, index) => ({ ...subtask, ordinalNumber: index + 1 }))
      };
      reorderSubtasks(data);
    }
  };

  renderReorderContent = (values: any) => {
    const { subtasks } = this.props;
    const { hideCompleted } = this.state;
    return (
      <DragDropContext onDragEnd={result => this.onDragEnd(result, values)}>
        <Droppable droppableId={'reorder-subtask'}>
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {values.subtasks.map((subtask, index) =>
                hideCompleted && subtask.completed ? null : (
                  <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={false}>
                    {(provided1, snapshot) => (
                      <SubtaskWrapper
                        key={`${subtask.id}-${index}`}
                        data-cy={'singleSubtask'}
                        ref={provided1.innerRef}
                        {...provided1.draggableProps}
                        reorder
                      >
                        <TippyTooltip
                          target={
                            <DragIconWrapper {...provided1.dragHandleProps} className={'drag-icon'}>
                              <MaterialIcon name={'drag_indicator'} cursor={'grab'} size={18} />
                            </DragIconWrapper>
                          }
                          content={t.REORDER}
                        />
                        <CheckBox
                          name={'checkbox'}
                          checked={subtask.completed}
                          className={'checkbox'}
                          onChange={() =>
                            this.addOrUpdateSubtask({ ...subtask, completed: !subtask.completed }, subtasks?.[index])
                          }
                        />
                        <Field name={`subtasks.${index}.content`}>
                          {({ field }: any) => (
                            <Input
                              {...field}
                              placeholder={t.SUBTASK_PLACEHOLDER}
                              maxLength={SUBTASK_MAX_LENGTH}
                              onBlur={(value: any) => this.handleBlur(value, index)}
                              completed={subtask.completed}
                              onKeyUp={this.updateOnEnter}
                            />
                          )}
                        </Field>
                        <DeleteButton onClick={this.deleteSubtask} clickData={subtask} />
                      </SubtaskWrapper>
                    )}
                  </Draggable>
                )
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  renderContent = (values: any) => {
    const { subtasks } = this.props;
    const { hideCompleted } = this.state;
    return (
      <>
        {values.subtasks.map((subtask, index) =>
          hideCompleted && subtask.completed ? null : (
            <SubtaskWrapper key={`${subtask.id}-${index}`} data-cy={'singleSubtask'}>
              <CheckBox
                name={'checkbox'}
                checked={subtask.completed}
                className={'checkbox'}
                onChange={() => this.addOrUpdateSubtask({ ...subtask, completed: !subtask.completed }, subtasks?.[index])}
              />
              <Field name={`subtasks.${index}.content`}>
                {({ field }: any) => (
                  <Input
                    {...field}
                    placeholder={t.SUBTASK_PLACEHOLDER}
                    maxLength={SUBTASK_MAX_LENGTH}
                    onBlur={(value: any) => this.handleBlur(value, index)}
                    completed={subtask.completed}
                    onKeyUp={this.updateOnEnter}
                  />
                )}
              </Field>
              <DeleteButton onClick={this.deleteSubtask} clickData={subtask} />
            </SubtaskWrapper>
          )
        )}
      </>
    );
  };

  render() {
    const {
      subtasks,
      maxHeight = 100,
      hasFilter,
      inline,
      noInput,
      autoFocus,
      noMaxHeight,
      reorder = false,
      loading
    } = this.props;
    const { hideCompleted } = this.state;

    return (
      <Container>
        {!inline && (
          <Row style={{ justifyContent: 'space-between', paddingLeft: 10 }}>
            <RegularText>{t.SUBTASKS}</RegularText>
            {hasFilter && (
              <FilterButton onClick={() => this.setState({ hideCompleted: !hideCompleted })}>
                {hideCompleted ? t.SHOW_COMPLETED : t.HIDE_COMPLETED}
              </FilterButton>
            )}
          </Row>
        )}
        {!subtasks && loading ? (
          <Skeleton width={'calc(100% - 10px)'} height={'25px'} style={{ marginLeft: 10 }} amount={3} />
        ) : (
          subtasks && (
            <Formik initialValues={{ subtasks }} onSubmit={() => {}} enableReinitialize={true}>
              {({ values, resetForm }: FormikProps<{ subtasks: Subtask[] }>) => {
                const visibleSubtasks = hideCompleted
                  ? values.subtasks.filter(subtask => !subtask.completed)
                  : values.subtasks;
                const height = visibleSubtasks.length * (inline ? 20 : 28) + 4;
                const formMaxHeight = noMaxHeight ? undefined : Math.min(height, maxHeight);
                return (
                  <SubtasksForm maxHeight={formMaxHeight} inline={inline}>
                    <FieldArray
                      name="subtasks"
                      render={() => {
                        if (reorder) {
                          return this.renderReorderContent(values);
                        }

                        return this.renderContent(values);
                      }}
                    />
                    <div ref={this.containerEnd} />
                  </SubtasksForm>
                );
              }}
            </Formik>
          )
        )}
        {!noInput && (
          <NewSubtaskWrapper inline={inline}>
            <MaterialIcon
              name={'subdirectory_arrow_right'}
              style={{ paddingRight: 5 }}
              color={colors.primaryPurple}
              cursor={'auto'}
            />
            <Input
              value={this.state.value}
              onChange={({ target: { value } }) => this.setState({ value })}
              onBlur={(e: any) => this.handleBlur(e, -1)}
              placeholder={t.SUBTASK_PLACEHOLDER}
              onKeyUp={this.handleKeyUp}
              maxLength={SUBTASK_MAX_LENGTH}
              margin={2}
              autoFocus={autoFocus}
              //only in task dialogs input should have padding, just like reorder (that is why this props is used)
              padding={reorder}
            />
          </NewSubtaskWrapper>
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addOrUpdateSubtask: taskActions.addOrUpdateSubtask.request,
      deleteSubtask: taskActions.deleteSubtask.request,
      reorderSubtasks: taskActions.reorderSubtasks.request
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ManageSubtasks);

const Container = styled.div`
  width: 100%;
  margin-left: -10px;
  padding-left: 7px;
`;

const SubtaskWrapper = styled(Row)<{ reorder?: boolean }>`
  margin: 1px 0;
  background-color: transparent;
  transition: background-color 0.2s ease;
  ${props => props.reorder && `.checkbox { margin-left: 5px; }`};
  .delete-button,
  .drag-icon {
    ${!isTouchScreen && 'visibility: hidden;'}
  }
  &:hover,
  &:focus-within {
    background-color: ${({ theme }) => theme.backgroundDark};
    .delete-button,
    .drag-icon {
      visibility: visible;
    }
  }
`;

const SubtasksForm = styled(ScrollGradient)<{ maxHeight?: number; inline?: boolean }>`
  transition: height 0.2s ease;
  ${props => props.maxHeight && `height: ${props.maxHeight}px;`};
  overflow: auto;
  ${SubtaskWrapper} {
    position: relative;
    padding: ${props => (props.inline ? 0 : 4)}px 10px;
  }
`;

const NewSubtaskWrapper = styled(Row)<{ inline?: boolean }>`
  align-items: center;
  padding: ${props => (props.inline ? '0px 10px' : '5px 10px 15px 10px')};
`;

const Input = styled(RegularTextInput)<{ completed?: boolean; margin?: number; padding?: boolean }>`
  box-sizing: border-box;
  outline: none;
  width: 100%;
  border: none;
  padding: ${props => (props.padding ? 2 : 0)}px;
  color: ${({ theme }) => theme.textStrongColor};
  background-color: transparent;
  margin-left: ${props => props.margin || 10}px;
  ${props => props.completed && 'text-decoration: line-through;'};
  &::placeholder,
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.placeholder};
    line-height: 1.35 !important;
    opacity: 1;
  }
  &:disabled {
    background: transparent;
    /* required on iOS */
    -webkit-text-fill-color: ${({ theme }) => theme.textColor};
    opacity: 1;
  }
  &:hover {
    background-color: ${props => props.theme.backgroundDark};
  }
`;

const FilterButton = styled(RegularText)`
  cursor: pointer;
`;

//TODO: Check with Igor about drag icon position and style
const DragIconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 5px;
  left: 0px;
  &:hover,&:active {
    > i { color: ${props => props.theme.primary}
  }
`;
